import React, {useEffect, useState} from "react";
import {Col, Container, Row} from "react-bootstrap";
import {routes} from "../../routes";
import {useSelector} from "react-redux";
import {RootState} from "../../redux/store";
import appRoutes from "../../routes/appRoutes";
import {appRoutesSmSiteForText, Constatnts} from "../../util/Constants";
import SidebarItem from "../../components/common/SidebarItem";
import {getLoginUser} from "../../helper/SecurityHelper";
import appRoutesSmSite from "../../routes/appRoutesSmSite";
import { RouteType } from "../../routes/config";


export default function SubMenuTitle({title, parentMenu}:any) {
    const defaultUser: any = getLoginUser();

    const [menuList, setMenuList] = useState<any>([]);

    useEffect(() => {
        let href = window.location.href;
        let isSmSite = href.indexOf('smSite') > -1
        let lastSlashIdx = href.lastIndexOf('/');
        const curPath = href.substring(lastSlashIdx);
        href = href.substring(0, lastSlashIdx);
        lastSlashIdx = href.lastIndexOf('/');
        const parentPath= href.substring(lastSlashIdx);

        let menuList: any = [];

        let routes = appRoutes;

        if(isSmSite){
            routes = appRoutesSmSiteForText;
        }

        routes.map((route, index) => {
            if(route.child){
                route.child.map((childData: any, idx: number) => {
                    if(childData.path.indexOf(parentPath) > -1){

                        menuList.push({displayText: childData.sidebarProps.displayText, isCur:(childData.path.indexOf(parentPath + curPath) > -1)});
                    }
                })
            }
        })
        setMenuList(menuList);

    }, []);
    return(
        <>
        {(defaultUser.authGrpId == 'HEAD' && !defaultUser.blngSiteId)?
                <Container fluid className="bmc-bg-primary px-0">
                    <Row>
                        <Col>
                            <div className="d-flex justify-content-between border-bottom px-4 pt-3">
                                <div className="bmc-txt-size-h4">{title}</div>
                                <div className="d-flex">
                                    <div className="bmc-text-menutitle active">{title}</div>
                                    <div className="bmc-text-menutitle">{parentMenu}</div>
                                </div>
                            </div>
                        </Col>
                    </Row>
                </Container> :
                <>
                    <Container fluid className="bmc-bg-primary px-0">
                        <Row>
                            <Col>
                                <div className="d-flex justify-content-between border-bottom px-4 pt-3">

                                    <div className="font-medium text-[#0e254c]">{parentMenu}</div>
                                    <div className="d-flex">
                                        {menuList.map((data: any, idx: number) => (
                                            <div key={idx} className={"bmc-text-menutitle" + (data.isCur ? ' active' : '')}>{data.displayText}</div>
                                        ))}

                                    </div>
                                </div>
                            </Col>
                        </Row>
                    </Container>
                    <Container fluid className="px-0">
                        <Row>
                            <Col>
                                <div className="d-flex justify-content-between px-4 pt-3">
                                    <div className="bmc-txt-size-h4">{title}</div>

                                </div>
                            </Col>
                        </Row>
                    </Container>
                </>
        }
        </>


    );
}
