import {Modal, Table} from "react-bootstrap";
import {IoMdClose} from "react-icons/io";
import iconCalendar from "../../../images/icon-calendar-black.svg";
import CustomDatePickerRange from "../../component/ui/DatePickerRange";
import React, {Dispatch, SetStateAction, useContext, useEffect, useRef, useState} from "react";
import {fn_getCurrentDate} from "../../../helper/CmmnHelper";
import {getLoginUser} from "../../../helper/SecurityHelper";
import {Col, Row} from "react-bootstrap";
import {Container} from "@mui/material";
import {COMMON_MODAL_STYLE} from "../../../helper/StyleHelper";
import BMCInput from "../../../components/common/BMCInput";
import BMCTextarea from "../../../components/common/BMCTextarea";
import axios from "axios";
import {toast} from "react-toastify";
import messageCodeDic from "../../../util/messageCodeDic";
import errorCodeDic from "../../../util/errorCodeDic";
import BMCSelect from "../../../components/common/BMCSelect";
import SiteCardList from "../../../components/site/SiteCardList";
import {ChangeMeta, NumberFormatBase} from "react-number-format";
import {Formatter} from "../../../helper/FormatHelper";
import BMCIconButton from "../../../components/common/BMCIconButton";
import {ProgressContext} from "../../../context/Progress";


interface IProps {
    setModalIsOpen: Dispatch<SetStateAction<boolean>>;
    modalIsOpen: boolean;
    detailData: any;
    setIsSaveSuccess: Dispatch<SetStateAction<boolean>>;
    siteId: number;
}

const SiteCmpnyMngPage = (props: IProps) => {

    const currentDate = fn_getCurrentDate();
    const user = getLoginUser();
    const [isSitePopOpend, setIsSitePopOpend] = React.useState<boolean>(false);

    const [data, setData] = useState<any>()
    const [blngSiteList, setBlngSiteList] = useState<object[]>([])
    const [isDupleChked, setIsDupleChked] = useState<boolean>(false);
    const {spinner} = useContext(ProgressContext);

    const fn_setFormData = (e: any) => {

        if (e.target.name == 'cnstrPrice') {
            e.target.value = parseInt(e.target.value.replaceAll(',', '')).toLocaleString()
        }
        setData({
            ...data,
            [e.target.name]: e.target.value
        })

    }

    useEffect(() => {
        if (blngSiteList.length > 0) {
            console.log('blngSiteList: ', blngSiteList);
        }
    }, [blngSiteList])

    const fn_onAfterOpen = () => {
        if (!props.detailData) {
            setData({companyGbCd:'C001005'})
            setBlngSiteList([])
            setIsDupleChked(false);
            // 상세
        } else {
            console.log('props.detailData: ', props.detailData)
            setData(props.detailData);
            setIsDupleChked(true);
            fn_retrieveCmpnyBlngSiteList(props.detailData.companySeq).then((result: any) => {
                setBlngSiteList(result);
            })
        }
    }

    function fn_retrieveCmpnyBlngSiteList(companySeq:string) {
        return new Promise((resolve, reject) => {
            axios.post('/admin/api/company/retrieveCmpnyBlngSiteList', {companySeq:companySeq}).then((result) => {
                resolve(result.data);
            })
        })

    }

    function fn_closeModal() {
        props.setModalIsOpen(false);
    }

    const submit = (e: any) => {
        e.preventDefault()

    }

    const fn_chkDupl = () => {

        if(!data){
            toast.warn('사업자 등록번호를 입력해 주십시오.');
            return;
        }

        if(data.companyRegNum){
            const companyRegNum = data.companyRegNum.replaceAll('-', '');
            if(companyRegNum.length < 10){
                toast.warn('사업자 등록번호가 형식에 어긋납니다.')
                return;
            }

            axios.post('/admin/api/company/retrieveCmpnyRegNumCnt', {companyRegNum:companyRegNum}).then((result) => {
              if(result.data > 0){
                  toast.warn('이미 존재하는 사업자 등록번호 입니다.')

              }else{
                  toast.success('사용 가능한 사업자 등록번호 입니다.')
                  setIsDupleChked(true);
              }
            })
        }

    }

    const fn_openAddSitePop = () => {
        setIsSitePopOpend(true);
    }


    const fn_registData = () => {

        if (!data) {
            toast.warn('데이터를 입력해 주십시오.')
            return;
        }

        if (!data.companyGbCd) {
            toast.warn('업체구분을 선택해 주십시오.')
            return;
        }

        if(data.companyRegNum && !isDupleChked){
            toast.warn('사업자등록번호 중복확인을 해주십시오.');
            return;
        }

        if (!data.representativeName) {
            toast.warn('대표자를 입력해 주십시오.');
            return;
        }

        if (!data.sectorCd) {
            toast.warn('대표공종을 입력해 주십시오.')
            return;
        }

        /*if (blngSiteList.length == 0) {
            toast.warn('소속현장을 입력해 주십시오.')
            return;
        }*/

        console.log('data: ', data);

        let param = data;
        param.siteId = props.siteId;
        param.regSeq = user.userSeq;
        param.updateSeq = user.userSeq;
        if(param.companyRegNum){
            param.companyRegNum = param.companyRegNum.replaceAll('-', '');
        }
        let blngSiteIdArray:number[] = []

        blngSiteList.map((data: any, idx) => {
            blngSiteIdArray.push(data.siteId)
        })

        param.blngSiteIdArray = blngSiteIdArray;
        console.log('param: ', param);

        if (!window.confirm('저장 하시겠습니까?')) {
            return;
        }

        spinner.start();
        axios.post('/admin/api/company/saveSubCmpnyData', param).then((result) => {
                if (result.data > 0) {
                    toast.success(messageCodeDic['001'])
                    props.setModalIsOpen(false);
                    props.setIsSaveSuccess(true);
                } else {
                    toast.error(errorCodeDic['003']);
                    props.setIsSaveSuccess(false);
                }
            }
        ).catch(error => {
            toast.error(errorCodeDic['003']);
            console.log('error: ', error);
        })
            .finally(() => {
                spinner.stop();
            });


    }



    return (
        <div>
            <Modal
                className="bmc-modal" size="xl"
                show={props.modalIsOpen}
                onShow={fn_onAfterOpen}
                onHide={fn_closeModal}
            >

                <Modal.Header>
                    <div className="d-flex justify-content-between align-items-center w-100 bmc-font-SD">
                        협력업체 등록/수정
                        <IoMdClose className='hover:cursor-pointer' onClick={fn_closeModal}/>
                    </div>
                </Modal.Header>
                <Modal.Body className="px-4">
                <form name={'form'}
                      onSubmit={submit}>
                    <div className="bmc-modal-div">
                        <div className="div-header">
                            업체정보
                        </div>
                        <div className="div-body px-3">
                            <Table className="my-2 align-middle">
                                <tbody>
                                <tr className="border-top align-middle">
                                    <th className="bmc-bg-th" style={{width: '15%'}}>업체구분</th>
                                    <td style={{width: '35%'}}>
                                        <BMCSelect initOptionGb={'none'} name={'companyGbCd'} className={"w-100"} codeGrpId={'C001'} codes={['C001005']}
                                                  onChange={fn_setFormData} value={data?.companyGbCd}></BMCSelect>
                                    </td>
                                    <th className="bmc-bg-th" style={{width: '15%'}}>업체명</th>
                                    <td style={{width: '35%'}}>
                                        <BMCInput type="text" className="form-control" name={'companyName'}
                                                 onChange={fn_setFormData} value={data?.companyName}/>
                                    </td>
                                </tr>
                                <tr className="border-top align-middle">
                                    <th className="bmc-bg-th" style={{width: '15%'}}>사업자등록번호</th>
                                    <td style={{width: '35%'}}>
                                        <div className="d-flex align-items-center w-100">
                                            {
                                                //@ts-ignore
                                                <NumberFormatBase onChange={fn_setFormData} name={'companyRegNum'} disabled={isDupleChked? true: false}
                                                                  className={'form-control w-75'} value={data?.companyRegNum || ''}
                                                                  format={Formatter.regNumFormat}
                                                />
                                            }
                                            <button className="bmc-btn btn-secondary min-w-[70px] ml-5 text-nowrap" type="button" onClick={fn_chkDupl}>중복확인
                                            </button>
                                        </div>
                                    </td>
                                    <th className="bmc-bg-th" style={{width: '15%'}}>대표자</th>
                                    <td style={{width: '35%'}}>
                                        <BMCInput type="text" name={'representativeName'} value={data?.representativeName}
                                                 onChange={fn_setFormData} className="form-control"
                                        />
                                    </td>
                                </tr>
                                <tr className="border-top align-middle">
                                    <th className="bmc-bg-th"  style={{width: '15%'}}>대표공종</th>
                                    <td style={{width: '35%'}}>
                                        <BMCSelect initOptionGb={'sel'} className={"w-100"} name={'sectorCd'} codeGrpId={'C002'}
                                                  onChange={fn_setFormData} value={data?.sectorCd}></BMCSelect>
                                    </td>
                                    <th className="bmc-bg-th" style={{width: '15%'}}>연락처</th>
                                    <td style={{width: '35%'}}>
                                        {
                                            //@ts-ignore
                                            <NumberFormatBase onChange={fn_setFormData} name={'contactPhoneNo'}
                                                              className={'form-control'} value={data?.contactPhoneNo || ''}
                                                              format={Formatter.phoneNumFormat}
                                            />
                                        }
                                    </td>
                                </tr>
                                </tbody>
                            </Table>
                        </div>
                    </div>

                    <div className="bmc-modal-div">
                        <div className="div-header">
                            작성정보
                        </div>
                        <div className="div-body px-3">
                            <Table className="my-2 align-middle">
                                <tbody>
                                <tr className="border-top align-middle">
                                    <th className="bmc-bg-th" style={{width: '15%'}}>작성일</th>
                                    <td style={{width: '35%'}}>
                                        <BMCInput value={currentDate} name={'rgstDt'} onChange={fn_setFormData}
                                                 className={"form-control"}
                                                 disabled={true}/>
                                    </td>
                                    <th className="bmc-bg-th" style={{width: '15%'}}>작성자</th>
                                    <td style={{width: '35%'}}>
                                        <BMCInput className={"form-control"} name={'rgstrNm'}
                                                 value={data?.rgstrNm || user.userName}
                                                 onChange={fn_setFormData}
                                                 disabled={true}/>
                                    </td>
                                </tr>
                                </tbody>
                            </Table>
                        </div>
                    </div>


                </form>
                </Modal.Body>

                <Modal.Footer className="py-2">
                    <div className='flex justify-center w-100'>
                        <button
                            className='bmc-btn pill l white me-3'
                            onClick={fn_closeModal}>닫기
                        </button>
                        <button onClick={fn_registData}
                                className='bmc-btn pill p'>등록/수정

                        </button>
                    </div>
                </Modal.Footer>

            </Modal>
        </div>
    )
}

export default SiteCmpnyMngPage;

