export const COMMON_MODAL_STYLE = {
    content: {
        top: '50%',
        left: '50%',
        right: 'auto',
        bottom: 'auto',
        marginRight: '-50%',
        transform: 'translate(-50%, -50%)',
    },
    overlay: {zIndex: 9999},
};

export const COMMON_MODAL_STYLE_SCROLL = {
    content: {
        top: '50%',
        left: '50%',
        right: 'auto',
        bottom: 'auto',
        marginRight: '-50%',
        transform: 'translate(-50%, -50%)',
        height:'calc(100% - 1rem)',

    },
    overlay: {zIndex: 9999},
}

export const COMMON_MULTISEL_STRINGS = {
    selectSomeItems: "선택해 주십시오",
    allItemsAreSelected: "전체 선택 되었습니다",
    selectAll: "전체 선택",
    search: "검색",
    selectAllFiltered: "전체 선택 (조회된 항목)",
    noOptions: "조회된 데이터가 없습니다",
}
