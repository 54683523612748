import React, {Dispatch, SetStateAction, useEffect, useRef, useState} from "react";
import {Modal, Table} from "react-bootstrap";
import {IoMdClose} from "react-icons/io";
import axios from "axios";
import {toast} from "react-toastify";
import {getLoginUser} from "../../../helper/SecurityHelper";
import BMCSelect from "../../../components/common/BMCSelect";

interface IProps {
    setModalIsOpen: Dispatch<SetStateAction<boolean>>;
    modalIsOpen: boolean;
    detailData?: any;
    slctdEmployeeSiteAuthData: object;
    setSlctdEmployeeSiteAuthData: Dispatch<SetStateAction<object>>;
    isSingleCheck?: boolean
    siteNm?: string
    blngCmpnySeq: number
    userName: string
    employeeSiteAuthDataList: object[]
}

const SlctEmployeeSiteAuthModal = (props: IProps) => {
    const [siteCodeList, setSiteCodeList] = useState<any>([]);
    const [cmpnyCodeList, setCmpnyCodeList] = useState<any>([]);
    const [workTypeCdList, setwWrkTypeCdList] = useState<any>([]);
    const [ocptCdList, setOcptCdList] = useState<any>([]);
    const user = getLoginUser();
    const [siteId, setSiteId] = useState<any>();
    const [cmpnySeq, setCmpnySeq] = useState<any>();
    const [wrkTpCd, setWrkTpCd] = useState<any>();
    const [ocptCd, setOcptCd] = useState<any>();
    const siteIdRef = useRef<any>();
    const cmpnySeqRef = useRef<any>();
    const workTypeCdRef = useRef<any>();
    const ocptCdRef = useRef<any>();
    const [data, setData] = useState<any>({});


    function fn_closeModal() {
        props.setModalIsOpen(false);
    }

    const fn_select = () => {

        if(!siteId){
            toast.warn('현장을 선택해 주십시오')
            return
        }

        if(!cmpnySeq){
            toast.warn('업체를 선택해 주십시오')
            return;
        }

        if(!wrkTpCd){
            toast.warn('공종을 선택해 주십시오')
            return
        }

        if(!ocptCd){
            toast.warn('직종을 선택해 주십시오')
            return
        }

        let isValid = true;
        props.employeeSiteAuthDataList.map((data:any) => {
            if(data.siteId == siteId){
                isValid = false;
            }
        });

        if(!isValid){
            toast.warn('이미 등록된 현장 입니다.');
            return;
        }

        console.log('employeeSiteAuthDataList: ', props.employeeSiteAuthDataList);

        props.setSlctdEmployeeSiteAuthData({siteId:siteId, cmpnySeq:cmpnySeq, wrkTpCd:wrkTpCd, ocptCd:ocptCd, isRep:false});
        props.setModalIsOpen(false);
    }



    /**
     * 현장 조회
     */
    const fn_retrieveSiteCodeList = async () => {
        return new Promise(function (resolve, reject) {
            let param:any = {}
            console.log("user ", user);
            if(user.authGrpId == 'SITE'){
                let siteIdList: any = [];
                const authChrgDataList = user.authChrgDataList;
                authChrgDataList.map((data: any) => {
                    siteIdList.push(data.siteId);
                })
                param.siteIdList = siteIdList;
                axios.post('/admin/api/site/retrieveSiteCodeList', param).then((result) => {
                    setSiteCodeList(result.data);
                })
            }



        });
    }

    const fn_onAfterShow = () => {
        fn_retrieveSiteCodeList();
    }

    /**
     * 현장 선택 onClick event
     */
    const fn_onSiteChange = (e: any) => {
        let siteId = e.target.value;
        console.log('siteId: ', siteId);
        setSiteId(siteId);

        //업체조회
        fn_retrieveCmpnySiteDataList(siteId).then((rsltData:any) => {
            setCmpnyCodeList([].concat(rsltData));
        });
        //공종조회
        fn_retrievesetConstructionItemList(siteId).then((rlstData:any) => {
            setwWrkTypeCdList([].concat(rlstData));
        })
        //직종조회
        fn_retrievesetOccupationItemList(siteId).then((rlstData:any) => {
            setOcptCdList([].concat(rlstData));
        })

        cmpnySeqRef.current.value = '';
        workTypeCdRef.current.value = '';
        ocptCdRef.current.value = '';
    }

    /**
     * 업체 선택 onClick event
     */
    const fn_onCmpnyChange = (e: any) => {
        setCmpnySeq(e.target.value);
    }

    /**
     * 공종 선택 onClick event
     */
    const fn_onConstructionChange = (e: any) => {
        setWrkTpCd(e.target.value);
    }

    /**
     * 직종 선택 onClick event
     */
    const fn_onOccupationChange = (e: any) => {
        setOcptCd(e.target.value);
    }

    /**
     * 업체 콥보박스 조회
     */
    const fn_retrieveCmpnySiteDataList = async (siteId: string) => {
        return new Promise(function (resolve, reject) {
            axios.post('/admin/api/company/retrieveCmpnySiteDataList', {
                siteId: siteId
            }).then((result) => {
                resolve(result.data);
            })
        });
    }

    /**
     * 공종 콤보박스 조회
     */
    const fn_retrievesetConstructionItemList = (siteId: string) => {
        return new Promise(function (resolve, reject) {
            axios.post('/admin/api/manage/retrieveSiteManageItemList', {
                siteId: siteId,
                manageCode: 'M001001'
            }).then((result) => {
                resolve(result.data);
            })
        });
    }

    /**
     * 직종 콤보박스 조회
     */
    const fn_retrievesetOccupationItemList = (siteId: string) => {
        return new Promise(function (resolve, reject) {
            axios.post('/admin/api/manage/retrieveSiteManageItemList', {
                siteId: siteId,
                manageCode: 'M001002'
            }).then((result) => {
                resolve(result.data);
            })
        });
    }

    return (
        <div>
            <Modal
                className="bmc-modal  sub" size="lg"
                show={props.modalIsOpen}
                onShow={fn_onAfterShow}
                onHide={fn_closeModal}
            >
                <Modal.Header>
                    <div className="d-flex justify-content-between align-items-center w-100 bmc-font-SD">
                        {props.userName}님 현장정보 입력
                        <IoMdClose className='hover:cursor-pointer' onClick={fn_closeModal}/>
                    </div>
                </Modal.Header>


                <Modal.Body className="px-4">
                    <form name={'form'}>
                        <div className="bmc-modal-div">
                            <div className="div-header">
                                현장정보
                            </div>
                            <div className="div-body px-3">
                                <Table className="my-2 align-middle">
                                    <tbody>
                                    <tr className="border-top align-middle">
                                        <th className="bmc-bg-th" style={{width: '15%'}}>현장</th>
                                        <td style={{width: '35%'}}>
                                            <select className='form-select' name={'siteId'} ref={siteIdRef}
                                                    onChange={fn_onSiteChange} value={data.siteId}>
                                                <option value={''}>선택해주세요.</option>
                                                {siteCodeList.map((data: any, idx: number) => (
                                                    <option key={idx} value={data.siteId}>{data.siteName}</option>
                                                ))}
                                            </select>
                                        </td>
                                    </tr>
                                    <tr>
                                        <th className="bmc-bg-th" style={{width:'6%'}}>업체</th>
                                        <td style={{width:'30%'}}>
                                            <select className='form-select' name={'cmpnySeq'}  ref={cmpnySeqRef}
                                                    onChange={fn_onCmpnyChange}>
                                                <option value=''>선택해주세요.</option>
                                                {cmpnyCodeList.map((data: any, idx: number) => (
                                                    <option key={idx} value={data.companySeq}>{data.companyName}</option>
                                                ))}
                                            </select>
                                        </td>
                                    </tr>
                                    <tr>
                                        <th className="bmc-bg-th" style={{width:'6%'}}>공종</th>
                                        <td style={{width:'30%'}}>
                                            <select className='form-select' name={'workTypeCd'} ref={workTypeCdRef}
                                                    onChange={fn_onConstructionChange}>
                                                <option value=''>선택해주세요.</option>
                                                {workTypeCdList.map((data: any, idx: number) => (
                                                    <option key={idx} value={data.manageSn}>{data.manageName}</option>
                                                ))}
                                            </select>
                                        </td>
                                    </tr>
                                    <tr>
                                        <th className="bmc-bg-th" style={{width:'6%'}}>직종</th>
                                        <td style={{width:'30%'}}>
                                            <select className='form-select' name={'ocptCd'} ref={ocptCdRef}
                                                    onChange={fn_onOccupationChange}>
                                                <option value=''>선택해주세요.</option>
                                                {ocptCdList.map((data: any, idx: number) => (
                                                    <option key={idx} value={data.manageSn}>{data.manageName}</option>
                                                ))}
                                            </select>
                                        </td>
                                    </tr>
                                    </tbody>
                                </Table>
                            </div>
                        </div>
                    </form>
                </Modal.Body>

                <Modal.Footer className="py-2">
                    <div className='flex justify-center w-100'>
                        <button
                            className='bmc-btn pill l white me-3'
                            onClick={fn_closeModal}>닫기
                        </button>
                        <button onClick={fn_select}
                                className='bmc-btn pill p'>선택
                        </button>

                    </div>
                </Modal.Footer>
            </Modal>
        </div>
    )
}

export default SlctEmployeeSiteAuthModal;
