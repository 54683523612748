import {
    Collapse,
    createTheme,
    List,
    ListItemButton,
    ListItemIcon,
    ListItemText,
    ThemeProvider,
    Typography
} from "@mui/material";
import { useEffect, useState } from "react";
import colorConfigs from "../configs/colorConfigs";
import { RouteType } from "../../routes/config";
import ExpandLessOutlinedIcon from '@mui/icons-material/ExpandLessOutlined';
import ExpandMoreOutlinedIcon from '@mui/icons-material/ExpandMoreOutlined';
import SidebarItem from "./SidebarItem";
import { useSelector } from "react-redux";
import { RootState } from "../../redux/store";

type Props = {
    item: RouteType;
};

const typoTheme = createTheme({

});

const SidebarItemCollapse = ({ item}: Props) => {
    const [open, setOpen] = useState(false);

    const { appState } = useSelector((state: RootState) => state.appState);

    useEffect(() => {
        if (appState.includes(item.state)) {
            setOpen(true);
        }
    }, [appState, item]);

    return (
        item.sidebarProps ? (
            <>
                <ListItemButton className={"site-sidebar-btn-top"}
                                onClick={() => setOpen(!open)}
                >
                    <ListItemIcon className={"bmc-left-menu-icon "}
                    >
                        {item.sidebarProps.icon && item.sidebarProps.icon}
                    </ListItemIcon>
                    <ListItemText
                        disableTypography
                        primary={
                            <ThemeProvider theme={typoTheme}>
                                <Typography>
                                    {item.sidebarProps.displayText}
                                </Typography>
                            </ThemeProvider>
                        }
                    />
                    {open ? <ExpandLessOutlinedIcon /> : <ExpandMoreOutlinedIcon />}
                </ListItemButton>
                <Collapse in={open} timeout="auto">
                    <List>
                        {item.child?.map((route, index) => (
                            <SidebarItem item={route} key={index} isTopLevel={false}/>
                        ))}
                    </List>
                </Collapse>
            </>
        ) : null
    );
};

export default SidebarItemCollapse;
