import React, {Dispatch, SetStateAction, useContext, useState} from "react";
import {Modal, Table} from "react-bootstrap";
import {IoMdClose} from "react-icons/io";
import BMCInput from "../../../components/common/BMCInput";
import BMCTextarea from "../../../components/common/BMCTextarea";
import BMCFile from "../../../components/common/BMCFile";
import {getLoginUser} from "../../../helper/SecurityHelper";
import {validFormData} from "../../../helper/FormHelper";
import axios from "axios";
import {toast} from "react-toastify";
import messageCodeDic from "../../../util/messageCodeDic";
import errorCodeDic from "../../../util/errorCodeDic";
import {ProgressContext} from "../../../context/Progress";
import restapi from "../../../helper/restAPIHelper";

interface IProps {
    setModalIsOpen: Dispatch<SetStateAction<boolean>>;
    modalIsOpen: boolean;
    detailData: any;
}

const EnvrntCnfgModal = (props: IProps) => {

    const [data, setData] = useState<any>();
    const [repFileSn, setRepFileSn] = useState<any>(null);
    const [fileList, setFileList] = useState([]);
    const formRef = React.createRef<HTMLFormElement>();
    const {spinner} = useContext(ProgressContext);
    const user: any = getLoginUser();

    const fn_updateData = (e: any) => {
        if (e.target.type == 'checkbox') {
            setData({
                ...data,
                [e.target.name]: e.target.checked
            })
            console.log(e.target.checked)
        } else {
            setData({
                ...data,
                [e.target.name]: e.target.value
            })
        }
    }

    const fn_registData = () => {


        if(!data){
            toast.warn('데이터를 입력해 주십시오.');
            return;
        }

        if(!data.key){
            toast.warn('키를 입력해 주십시오.');
            return;
        }

        if(!data.value){
            toast.warn('값을 입력해 주십시오.');
            return;
        }

        if(!data.description){
            toast.warn('설명을 입력해 주십시오.');
            return;
        }

        if (!window.confirm('저장 하시겠습니까?')) {
            return;
        }

        spinner.start();
        axios.post('/admin/api/system/saveSystemInfo', data).then((result) => {

            if (result.data > 0) {
                toast.success(messageCodeDic['001'])
                props.setModalIsOpen(false);
            } else {
                toast.error(errorCodeDic['003']);
            }
        }).catch(error => {
            toast.error(errorCodeDic['003']);
            console.log('error: ', error);
        }).finally(() => {
            spinner.stop();
        });

    }

    const fn_openModal = () => {
        if(!props.detailData){
            setFileList([]);
            setData(null);
        }else{
            setData(props.detailData);
            if(props.detailData.atchFileId){
                restapi.retrieveAttachInfoList({attachSeq: props.detailData.atchFileId}).then((rsltData: any) => {
                    if (props.detailData?.repfileSn !== null && props.detailData?.repfileSn !== undefined) {
                        rsltData.map((data: any, idx: number) => {
                            if (data.sn == props.detailData.repfileSn) {
                                data.isRep = true
                                setRepFileSn(idx);
                            }
                        })
                    }

                    setFileList([].concat(rsltData));
                })
            }else{
                setFileList([]);
            }
        }
    }

    const fn_closeModal = () => {
        props.setModalIsOpen(false);
    }

    const submit = (e: any) => {
        e.preventDefault()

    }

    return (
        <div>
            <Modal
                className="bmc-modal" size="xl"
                show={props.modalIsOpen}
                onShow={fn_openModal}
                onHide={fn_closeModal}
            >

                <Modal.Header>
                    <div className="d-flex justify-content-between align-items-center w-100 bmc-font-SD">
                        환경설정 상세
                        <IoMdClose className='hover:cursor-pointer' onClick={fn_closeModal}/>
                    </div>
                </Modal.Header>
                <Modal.Body className="px-4">

                        <div className="bmc-modal-div">
                            <div className="div-header">
                                환경설정 정보
                            </div>
                            <div className="div-body px-3">
                                <Table className="my-2 align-middle">
                                    <tbody>
                                    <tr className="border-top align-middle">
                                        <th className="bmc-bg-th" style={{width: '15%'}}>키</th>
                                        <td style={{width: '85%'}}>
                                            <BMCInput type="text" className="form-control" name={'key'} readOnly={true}
                                                      onChange={fn_updateData} value={data?.key}/>
                                        </td>
                                    </tr>
                                    <tr className="border-top align-middle">
                                        <th className="bmc-bg-th" style={{width: '15%'}}>값</th>
                                        <td style={{width: '85%'}}>
                                            <BMCTextarea rows={5} onChange={fn_updateData} name={'value'}
                                                         value={data?.value}
                                                         className={"form-control"}
                                            />

                                        </td>
                                    </tr>
                                    <tr className="border-top align-middle">
                                        <th className="bmc-bg-th" style={{width: '15%'}}>설명</th>
                                        <td style={{width: '85%'}}>
                                            <BMCInput type="text" className="form-control" name={'description'}
                                                      onChange={fn_updateData} value={data?.description}/>
                                        </td>
                                    </tr>

                                    </tbody>
                                </Table>
                            </div>
                        </div>
                </Modal.Body>

                <Modal.Footer className="py-2">
                    <div className='flex justify-center w-100'>
                        <button
                            className='bmc-btn pill l white me-3'
                            onClick={fn_closeModal}>닫기
                        </button>
                        {user.authCode != 'headRead' &&
                            <button onClick={fn_registData}
                                    className='bmc-btn pill p'>저장
                            </button>
                        }
                    </div>
                </Modal.Footer>

            </Modal>
        </div>
    )
}

export default EnvrntCnfgModal;
