import React, {useContext, useEffect, useState} from 'react';
import {Col, Container, Row} from "react-bootstrap";
import {ReactComponent as IconTotal} from "../../images/icon_stack.svg";
import {ReactComponent as IconWorking} from "../../images/icon_barricade.svg";
import {ReactComponent as IconWorkDone} from "../../images/icon_square_check.svg";
import restapi from "../../helper/restAPIHelper";
import {CmmnFn} from "../../helper/CmmnHelper";
import {UserContext} from "../../context/User";
import {getLoginUser} from "../../helper/SecurityHelper";
import WrkPrmtInfoListModal from "../headoffice/modal/WrkPrmtInfoListModal";




const SSDashWorkPermitDoc = () => {
    const {user} = useContext<any>(UserContext);
    const defaultUser = getLoginUser();
    const [wrkPrmtDataList, setWrkPrmtDataList] = useState<any>([]);
    const [dataList, setDataList] = useState<any>([]);
    const [ingDataList, setIngDataList] = useState<any>([]);
    const [cmpltdDataList, setCmpltdDataList] = useState<any>([]);
    const [statData, setStatData] = useState({totalCnt: 0, ingCnt: 0, cmpltdCnt: 0, wrkWaitCnt: 0});
    const [modalIsOpen, setModalIsOpen] = useState<boolean>(false);
    const [wrkWaitDataList, setWrkWaitDataList] = useState<any>([]);

    useEffect(() => {
        const siteId = user?.blngSiteId || defaultUser.blngSiteId;
        restapi.retrieveWrkPrmtDataTotList({siteId: siteId, wrkDt: CmmnFn.fn_getCurrentDate(), prcsStatCd: 'S001004', isForMonitoring: true}).then((rsltData: any) => {
            setWrkPrmtDataList(rsltData);

            const totalCnt = rsltData.length;
            const ingDataList = rsltData.filter((data: any) => (data.wrkStatNm == '작업중'));
            const cmpltdDataList = rsltData.filter((data: any) => (data.wrkStatNm == '작업완료'));
            const wrkWaitDataList = rsltData.filter((data: any) => (data.wrkStatNm == '작업대기'));
            setIngDataList(ingDataList);
            setCmpltdDataList(cmpltdDataList);
            setWrkWaitDataList(wrkWaitDataList);

            setStatData({totalCnt:totalCnt, ingCnt:ingDataList.length, cmpltdCnt:cmpltdDataList.length, wrkWaitCnt:wrkWaitDataList.length});
        })
    }, [user])

    const fn_openWrkPrmtInfoListModal = (srchGb: string) => {
        if(srchGb == 'tot'){
            setDataList(wrkPrmtDataList);
        }else if(srchGb == 'ing'){
            setDataList(ingDataList);
        }else if(srchGb == 'cmpltd'){
            setDataList(cmpltdDataList);
        }else if(srchGb == 'wait'){
            setDataList(wrkWaitDataList);
        }

        setModalIsOpen(true);
    }

    return(
        <>
            <WrkPrmtInfoListModal setModalIsOpen={setModalIsOpen} modalIsOpen={modalIsOpen} dataList={dataList}></WrkPrmtInfoListModal>
            <Container fluid className="bmc-site-bg-pane">
                <Row className={"mb-2"}>
                    <Col>
                        <div className="d-flex flex-column justify-content-between">
                            <div className="w-100 d-flex align-items-center justify-content-between pb-1 border-bottom-v2">
                                <span className="bmc-site-area-title">유지보수 작업지시 진행 현황</span>
                            </div>
                        </div>
                    </Col>
                </Row>
                <Row className="mb-2">
                    <Col>
                        <div className="d-flex justify-content-between">
                            <div style={{width: '24%'}}>
                                <div className="cursor-pointer d-flex flex-column justify-content-between p-3 h-[160px]"
                                     style={{backgroundColor: '#EFF3FF', borderRadius: 16}}
                                     onClick={() => {fn_openWrkPrmtInfoListModal('tot')}}
                                >
                                    <div className="bmc-txt-color-primary">
                                        <div className="bmc-txt-size-h2">{statData.totalCnt}</div>
                                        <div className="bmc-txt-color-gray-01 bmc-txt-size-b5">전체</div>
                                    </div>
                                    <div className="d-flex justify-content-end align-items-center">
                                        <IconTotal width={35} height={35}/>
                                    </div>
                                </div>
                            </div>
                            <div style={{width: '24%'}}>
                                <div className="cursor-pointer d-flex flex-column justify-content-between p-3 h-[160px]"
                                     style={{backgroundColor: '#ECF8FF', borderRadius: 16}}
                                     onClick={() => {fn_openWrkPrmtInfoListModal('wait')}}
                                >
                                    <div className="bmc-txt-color-primary">
                                        <div className="bmc-txt-size-h2">{statData.wrkWaitCnt}</div>
                                        <div className="bmc-txt-color-gray-01 bmc-txt-size-b5">작업대기</div>
                                    </div>
                                    <div className="d-flex justify-content-end align-items-center">
                                        <IconWorking width={35} height={35}/>
                                    </div>
                                </div>
                            </div>
                            <div style={{width: '24%'}}>
                                <div className="cursor-pointer d-flex flex-column justify-content-between p-3 h-[160px]"
                                     style={{backgroundColor: '#ECF8FF', borderRadius: 16}}
                                     onClick={() => {fn_openWrkPrmtInfoListModal('ing')}}
                                >
                                    <div className="bmc-txt-color-primary">
                                        <div className="bmc-txt-size-h2">{statData.ingCnt}</div>
                                        <div className="bmc-txt-color-gray-01 bmc-txt-size-b5">작업중</div>
                                    </div>
                                    <div className="d-flex justify-content-end align-items-center">
                                        <IconWorking width={35} height={35}/>
                                    </div>
                                </div>
                            </div>
                            <div style={{width: '24%'}}>
                                <div className="cursor-pointer d-flex flex-column justify-content-between p-3 h-[160px]"
                                     style={{backgroundColor: '#FFF5F6', borderRadius: 16}}
                                     onClick={() => {fn_openWrkPrmtInfoListModal('cmpltd')}}
                                >
                                    <div className="bmc-txt-color-primary">
                                        <div className="bmc-txt-size-h2">{statData.cmpltdCnt}</div>
                                        <div className="bmc-txt-color-gray-01 bmc-txt-size-b5">작업완료</div>
                                    </div>
                                    <div className="d-flex justify-content-end align-items-center">
                                        <IconWorkDone width={35} height={35}/>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </Col>
                </Row>
            </Container>
        </>
    );
}

export default SSDashWorkPermitDoc;
