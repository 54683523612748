import {Outlet, useNavigate} from "react-router-dom";
import {Box, Toolbar} from "@mui/material";
import colorConfigs from "../configs/colorConfigs";
import sizeConfigs from "../configs/sizeConfigs";
import React, {createContext, Dispatch, SetStateAction, useContext, useEffect} from "react";
import {getLoginUser} from "../../helper/SecurityHelper";
import {ToastContainer} from "react-toastify";
import 'react-toastify/dist/ReactToastify.css';
import Menubar from "../common/Menubar";
import ClipLoader from 'react-spinners/ClipLoader';
import {ProgressContext} from "../../context/Progress";
import {RouteType} from "../../routes/config";
import SmSiteMenubar from "../common/SmSiteMenubar";
import {UserContext} from "../../context/User";
import HeadMenubar from "../common/HeadMenubar";
import BMCHeadMenubarV2 from "../common/BMCHeadMenubarV2";
import IvstSiteMenubar from "../common/IvstSiteMenubar";
import FcltySiteMenubar from "../common/FcltySiteMenubar";

interface IProps {
    isSmSite: boolean;
    isHead: boolean;
    isFclty?: boolean;
    isIvst?: boolean;
}


const MainLayout = (props: IProps) => {
    const navigate = useNavigate();
    const user = getLoginUser()
    const {inProgress} = useContext(ProgressContext);
    const {user:userCntxt}: any = useContext(UserContext);

    useEffect(() => {
        if (!user) {
            navigate('/login');
        }
    }, [])

    return (

        <Box sx={{display: "flex"}}>

            <ToastContainer
                position="top-center"
                autoClose={3000}
                hideProgressBar={false}
                newestOnTop={false}
                closeOnClick
                rtl={false}
                pauseOnFocusLoss
                draggable
                pauseOnHover
                theme="light"
                style={{zIndex: 11000, width:'400px'}}
            />
            {props.isHead? ( props.isIvst ? <IvstSiteMenubar/> : props.isFclty ? <FcltySiteMenubar /> :  <BMCHeadMenubarV2 /> ) : (userCntxt?.isSmSite || props.isSmSite) ? <SmSiteMenubar /> : <Menubar />}
            {inProgress && <div id="bmc-loader-wrapper" style={{zIndex: 11001}}><span className={"loading"}></span></div>}
            {
                !props.isSmSite ? <Box
                    component="main"
                    className={"bmc-empty-screen"}
                    sx={{
                        marginTop: props.isHead ? "100px" : "64px",
                        flexGrow: 1,
                        minHeight: props.isHead ? "calc(100vh - 100px)" : "calc(100vh - 64px)",
                        maxHeight: props.isHead ? "calc(100vh - 100px)" : "calc(100vh - 64px)",
                        maxWidth: (props.isHead || props.isSmSite) ? "100vw" : "100vw",
                        marginLeft: props.isHead ? "0px" : "0px",
                        backgroundColor: "#050E63",
                    }}
                >
                    <Outlet/>

                </Box>
                    :
                    <Box
                        component="main"
                        className={"bmc-empty-screen"}
                        sx={{
                            marginTop: props.isHead ? "100px" : "64px",
                            flexGrow: 1,
                            minHeight: props.isHead ? "calc(100vh - 100px)" : "calc(100vh - 64px)",
                            maxHeight: props.isHead ? "calc(100vh - 100px)" : "calc(100vh - 64px)",
                            maxWidth: (props.isHead || props.isSmSite) ? "100vw" : "100vw",
                            marginLeft: props.isHead ? "0px" : "0px",
                            backgroundColor: "#050E63",
                        }}
                    >
                        <Outlet/>

                    </Box>
            }


        </Box>
    );
};

export default MainLayout;
