import React, {useContext, useEffect} from 'react';
import {Col, Container, Row} from "react-bootstrap";
import {TopMenuContext, TopMenuProvider} from "../../context/TopMenu";
import DashboardWeather from "../home/DashWeather";
import DashboardRiskAssessment from "../home/DashRiskAssessment";
import DashboardNotice from "../home/DashNotice";
import SSDashInOut from "./SSDashInOut";
import SSDashWorkPermitDoc from "./SSDashWorkPermitDoc";
import SSDashSOSCall from "./SSDashSOSCall";
import SSDashStopWork from "./SSDashStopWork";
import SSDashSiteStatus from "./SSDashSiteStatus";
import SSDashRiskAssessment from "./SSDashRiskAssessment";
import {SiteContext} from "../../context/Site";
import HOLocalFieldStatus from "../headoffice/HOLocalFieldStatus";
import HOConstructionSummary from "../headoffice/HOConstructionSummary";
import HOMonthlyManagementCalendar from "../headoffice/HOMonthlyManagementCalendar";
import HOWeeklySafetyCheck from "../headoffice/HOWeeklySafetyCheck";
import DashboardTotalDepartureStatus from "../home/DashTotalDepartureStatus";
import DashPartDepartureStatus from "../home/DashPartDepartureStatus";
import DashWorkPermitDoc from "../home/DashWorkPermitDoc";
import DashEquipmentStatus from "../home/DashEquipmentStatus";
import DashTodayWork from "../home/DashTodayWork";
import DashRiskAssessment from "../home/DashRiskAssessment";
import DashboardNonconformityState from "../home/DashNonconformityState";
import DashZoneInformation from "../home/DashZoneInformation";
import DashSiteCCTV from "../home/DashSiteCCTV";
import HOCRTrafficLight from "../headoffice/HOCRTrafficLight";
import SSDashboardNotice from "./SSDashNotice";
import HOTotalWeatherInfoV2 from "../headoffice/HOTotalWeatherInfoV2";
import SiteRiskStatus from "../home/SiteRiskStatus";
import HOEtcInfoV2 from "../headoffice/HOEtcInfoV2";
import DashRiskAssessmentV2 from "../home/DashRiskAssessmentV2";

type Props = {};

const SSMonitoringPage = (props: Props) => {

    return (
        <>
            <Container fluid style={{backgroundColor:'#050E63', paddingLeft: '0px', paddingRight: '0px'}}>
                <div className="d-flex w-100 overflow-hidden" style={{height: 'calc(100vh - 64px)'}}>
                    <div className="d-flex flex-grow-1 ss-monitoring-main-left-area">
                        <div className="d-flex w-100">
                            <div className="d-flex w-100 gap-3">
                                <div className="left-area-total d-flex flex-column gap-3 " style={{height: 'calc(100vh - 90px)', minWidth:'450px', overflowY:"auto", overflowX:"hidden"}}>
                                    <div className="d-flex flex-column gap-3">
                                        <div>
                                            <HOTotalWeatherInfoV2 />
                                        </div>
                                        <div>
                                            <DashRiskAssessmentV2 />
                                        </div>
                                        <div style={{minHeight:"290px"}}>
                                            <SSDashboardNotice />
                                        </div>
                                    </div>
                                </div>
                                <div className="d-flex flex-column flex-grow-1 gap-3">
                                    <div className="d-flex flex-row gap-3 w-100" style={{minHeight:"232px"}}>
                                        <div className="d-flex" style={{minWidth:"430px"}}>
                                            <SiteRiskStatus/>
                                        </div>
                                        <div className="d-flex" style={{minWidth:"200px"}}>
                                            <SSDashInOut />
                                        </div>
                                        <div className="d-flex flex-grow-1">
                                            <SSDashWorkPermitDoc />
                                        </div>
                                    </div>
                                    <div className="d-flex flex-grow-1">
                                       <SSDashSiteStatus />
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>

                {/*<Row className="pt-4 px-3">
                    <Col bsPrefix="col-3" style={{height: 'calc(100vh - 170px)', overflowY:"auto" }}>
                        <Container fluid className="px-0">
                            <Row>
                                <Col>
                                    <DashboardWeather/>
                                    <HOTotalWeatherInfoV2 />
                                </Col>
                            </Row>
                            <Row className="pt-4">
                                <Col>
                                    <SSDashRiskAssessment />
                                </Col>
                            </Row>
                            <Row className="pt-4">
                                <Col>
                                    <SSDashboardNotice />
                                </Col>
                            </Row>
                        </Container>
                    </Col>
                    <Col bsPrefix="col-9">
                        <Container fluid className="px-0">
                            <Row>
                                <Col bsPrefix="col-3">
                                    <SSDashInOut />
                                </Col>
                                <Col bsPrefix="col-9">
                                    <SSDashWorkPermitDoc />
                                </Col>
                            </Row>
                            <Row className="pt-4">
                                <Col>
                                    <SSDashSiteStatus />
                                </Col>
                            </Row>
                        </Container>
                    </Col>
                </Row>*/}
            </Container>
        </>
    );
}
export default SSMonitoringPage;

