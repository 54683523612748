import {RouteType} from "./config";

/* SVG Icon */
import {ReactComponent as IconMonitoring} from '../assets/images/icon/icon-Union.svg';
import {ReactComponent as IconSiteConstruction} from '../assets/images/icon/icon-Browsers.svg';

import React, {useEffect} from "react";
import {useNavigate} from "react-router-dom";

import IvstMonitoringPage from "../pages/ivst/IvstMonitoringPage";
import IvstSiteListPage from "../pages/ivst/IvstSiteListPage";
import IvstSftyChkPage from "../pages/ivst/IvstSftyChkPage";
import IvstSiteChkPage from "../pages/ivst/IvstSiteChkPage";


const ToPage = ({ link }: any) => {
  const navigate = useNavigate();
  useEffect(()=>{
    navigate(link)
  },[])
  return (
      <>...</>
  );
};
const appRoutesIvst: RouteType[] = [
  {
    index: true,
    element: <ToPage link={"/ivst/monitoring"} />,
    state: "home"
  },
  {
    path: "/ivst/monitoring",
    element: <IvstMonitoringPage />,
    state: "ivst.monitoring",
    sidebarProps: {
      displayText: "출자토지관리 현황",
      icon: <IconMonitoring />
    }
  },
  {
    path: "/ivst/ivstSiteList",
    element: <IvstSiteListPage />,
    state: "ivst.ivstSiteList",
    sidebarProps: {
      displayText: "출자토지현장 목록",
      icon: <IconSiteConstruction />
    },
  },
  {
    path: "/ivst/ivstSiteChk",
    element: <IvstSiteChkPage />,
    state: "ivst.ivstSiteChk",
    sidebarProps: {
      displayText: "출자토지 현장점검",
      icon: <IconSiteConstruction />
    },
  },
  {
    path: "/ivst/ivstSftyChk",
    element: <IvstSftyChkPage />,
    state: "ivst.ivstSftyChk",
    sidebarProps: {
      displayText: "안전조치 대상 관리",
      icon: <IconSiteConstruction />
    },
  },

];

export default appRoutesIvst;
