import React, {LegacyRef, useContext, useEffect, useRef, useState} from "react";
import {Container, Row, Col} from "react-bootstrap";

import {styled} from "@mui/material/styles";
import Tabs from "@mui/material/Tabs";
import Tab from "@mui/material/Tab";
import InfoMap from "../headoffice/map/InfoMap";
import RealMap from "../headoffice/map/RealMap";

interface TabPanelProps {
    children?: React.ReactNode;
    index: number;
    value: number;
}

function CustomTabPanel(props: TabPanelProps) {
    const { children, value, index, ...other } = props;

    return (
        <div
            role="tabpanel"
            hidden={value !== index}
            id={`map-tabpanel-${index}`}
            aria-labelledby={`map-tab-${index}`}
            {...other}
        >
            {
                value === index &&
                (<div className="pt-2 w-100 position-relative bmc-fs-b4 bmc-font-SP bmc-fw-5" style={{height:"100%", borderRadius: "10px"}}>
                    {children}
                </div>)

            }
        </div>
    );
}

interface StyledTabsProps {
    children?: React.ReactNode;
    value: number;
    onChange: (event: React.SyntheticEvent, newValue: number) => void;
}

const StyledTabs = styled((props: StyledTabsProps) => (
    <Tabs
        {...props}
        TabIndicatorProps={{ children: <span className="MuiTabs-indicatorSpan" /> }}
    />
))({
    minHeight: '1.5rem',
    '& .MuiTabs-indicator': {
        position: 'absolute',
        display: 'flex',
        justifyContent: 'center',
        backgroundColor: 'transparent',

    },
    '& .MuiTabs-indicatorSpan': {
        maxWidth: 50,
        width: '100%',
        backgroundColor: '#0EC0DE',
    },
});

interface StyledTabProps {
    label: string;
}

const StyledTab = styled((props: StyledTabProps) => (
    <Tab disableRipple {...props} />
))(({ theme }) => ({
    textTransform: 'none',
    fontFamily: 'NanumSquareNeo',
    fontWeight: theme.typography.fontWeightRegular,
    fontSize: "16px",
    marginRight: theme.spacing(0),
    padding: '0px 0px 15px 0px',
    minHeight: '1.25rem',
    minWidth: 60,
    color: '#615E83',
    '&.Mui-selected': {
        color: '#0EC0DE',
        fontWeight: theme.typography.fontWeightBold,
    },
    '&.Mui-focusVisible': {
        backgroundColor: '#615E83',
    },
}));

export default function IvstMapField() {
    const [showInfoMap, setShowInfoMap] = useState<boolean>(false);
    const [tabVal, setTabVal] = useState<number>(0);

    function allyProps(index: any) {
        return {
            id: `map-tab-${index}`,
            'aria-controls': `map-tabpanel-${index}`,
        };

    }

    const handleChange = (event: any, newValue: React.SetStateAction<number>) => {
        setTabVal(newValue);
    };

    return(
        <>
            <Container fluid className="bmc-bg-pane w-100">
                <Row>
                    <Col>
                        <div className="d-flex flex-column justify-content-between ">
                            <div className="w-100 d-flex align-items-center justify-content-between border-bottom">
                                <span className="bmc-area-title-v2 align-self-start text-nowrap">지역별 출자토지 현황</span>
                                <div className="position-relative h-100">
                                    <StyledTabs
                                        value={tabVal}
                                        onChange={handleChange}
                                    >
                                        <StyledTab label="정보맵" {...allyProps(0)} />
                                        <StyledTab label="지도" {...allyProps(1)} />
                                        <StyledTab label="기상현황" {...allyProps(2)} />
                                    </StyledTabs>
                                </div>
                            </div>
                        </div>
                    </Col>
                </Row>
                <Row className="pt-2">
                    <Col>
                        <div className="d-flex monitoring-left-tab w-100">
                            <CustomTabPanel value={tabVal} index={0}>
                                <InfoMap siteType={'T002003'} height={'calc(100vh - 432px)'}/>
                            </CustomTabPanel>
                            <CustomTabPanel value={tabVal} index={1}>
                                <RealMap siteType={'T002003'} height={'calc(100vh - 432px)'}/>
                            </CustomTabPanel>
                            <CustomTabPanel value={tabVal} index={2}>
                                {/*<div style={{width: '100%', height: 'calc(100vh - 224px)'}}>*/}
                                <div style={{width: '100%', height: 'calc(100vh - 432px)'}}>
                                    <iframe  src={'https://www.weather.go.kr/w/image/synthesis.do'} width="100%" height="100%"/>
                                </div>
                            </CustomTabPanel>
                        </div>
                    </Col>
                </Row>
            </Container>
        </>
    );
}
