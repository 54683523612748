import React, {Dispatch, MutableRefObject, SetStateAction, useRef, useState} from "react";
import {ReactComponent as IconCalendar} from "../../images/icon-calendar-black.svg";

interface IProps {
    value?: any;
    name?: string;
    onChange?: Dispatch<SetStateAction<any>>;
    className?: string;
    onClick?: Dispatch<SetStateAction<any>>;
    readOnly?: boolean;
    type?: string;
    disabled?: boolean;
    onBlur?: Dispatch<SetStateAction<any>>;
    placeholder?:string;
    calendar?: Dispatch<SetStateAction<any>>;
    width?: string;
}

export default function BMCDatePicker(props: IProps) {
    const [defaultValue, setDefaultValue] = useState('');

    const fn_setDefaultValue = (e: any) => {
        setDefaultValue(e.target.value);
    }

    return (
        <div style={{width:props.width}} className={"bmc-box-input date px-2"+(props.type == 'hidden' ? ' d-none' : '')+(props.disabled ? ' disabled' : '')}>
            <input type={props.type || 'text'} name={props.name || ''}   className={'form-control w-[120px] '+props.className || 'form-control border-0'} value={props.value || defaultValue}
                   placeholder={props.placeholder || ''} onChange={props.onChange || fn_setDefaultValue} onClick={(e)=>
            {
                // @ts-ignore
                if(e.target.disabled !== true){
                    // @ts-ignore
                    props.onClick(e)
                }
            }
            } readOnly={props.readOnly||false} disabled={props.disabled} onBlur={props.onBlur}/>
            {
                props.calendar ?
                    <IconCalendar className='cursor-pointer' onClick={(e)=>
                    {
                        // @ts-ignore
                        if(props.disabled !== true){
                            // @ts-ignore
                            props.calendar(e)
                        }
                    }
                    } />
                : null
            }
        </div>
    )
}
