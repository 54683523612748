import React, {useContext, useEffect, useState} from "react";
import {Container} from "react-bootstrap";
import {ReactComponent as IconRight} from "../../assets/images/ico/icon-right.svg";

import BMCSelect from "../../components/common/BMCSelect";
import restapi from "../../helper/restAPIHelper";
import {transform} from "ol/proj";
import {SiteContext} from "../../context/Site";
import {useNavigate} from "react-router-dom";


export default function IvstStStts() {
    const [data, setData] = useState<any>({siteType: 'T002003'});
    const [siteData, setSiteData] = useState<any>({});
    const [rcntChkData, setRcntChkData] = useState<any>({});
    const navigate = useNavigate();

    useEffect(() => {
        if(data.siteId){
            fn_retrieveSiteCnstrInfo(data.siteId)
        }
    }, [data]);

    const fn_setData = (e: any) => {
        setData({
            ...data,
            [e.target.name]: e.target.value
        })
    }

    const fn_retrieveSiteCnstrInfo = (siteId: number) => {
        restapi.retrieveSiteDataListNoPaging({siteId: siteId, siteType: 'T002003'}).then((rsltData: any) => {
            if (rsltData.length > 0) {
                var item = rsltData[0]
                setSiteData(item);
                if (item.longitude && item.latitude) {
                    var center = transform([item.longitude, item.latitude], 'EPSG:4326', 'EPSG:3857')
                    console.log(center)
                    var duration = 1000
                    // @ts-ignore
                    var map = window.___dashboard_map
                    var currentCenter = map.getView().getCenter();
                    map.getView().animate({
                        center: [((center[0] + currentCenter[0]) / 2), ((center[1] + currentCenter[1]) / 2)],
                        zoom: 10,
                        duration: 200
                    }, {
                        center: center,
                        zoom: 16,
                        duration: duration
                    });
                }
                restapi.retrieveIvstSftyManageTotDataList({siteId: siteId, ivstSprvDprtGbList: ['C007002','C007003','C007004','C007005']}).then((rsltData: any) => {
                    if(rsltData.length > 0){
                        setRcntChkData(rsltData[0]);
                    }else{
                        setRcntChkData({});
                    }
                    console.log('chkData: ', rsltData);
                })

            }
        })
    }
    return (
        <Container fluid className="bmc-bg-pane ivst-st-stts">
            <div className="d-flex align-items-center justify-content-between pb-2 border-bottom-v2 mb-3">
                <span className="bmc-area-title-v2">출자 토지 현장 현황</span>
                <div className={'cursor-pointer'} onClick={() => navigate('/ivst/ivstSiteList')}>
                    <IconRight style={{width: '20px', marginBottom: '1px', stroke: '#1d1b39'}} />
                </div>
            </div>
            <div className="d-flex flex-row gap-2 mb-3">
                <div className="d-flex flex-column w-[200px] gap-1">
                    <span className="ivst-srch-text">지역</span>
                    <BMCSelect initOptionGb={'all'}  className={'form-select'} name={'guCd'} onChange={fn_setData} value={data.guCd}  codeGb={'bjd'} codeGrpId={'BUSAN'}></BMCSelect>
                </div>
                <div className="d-flex flex-column flex-grow-1 gap-1">
                    <span className="ivst-srch-text">현장</span>
                    <BMCSelect initOptionGb={'all'} className={'form-control'} name={'siteId'} onChange={fn_setData} value={data.siteId} selParam={data} codeGb={'site'}></BMCSelect>
                </div>
            </div>
            <div className="d-flex ivst-site-position gap-5 mb-3">
                <span className="fw-bold">현장위치</span>
                <span className="">{siteData.siteAddress}</span>
            </div>
            <div className="d-flex gap-4">
                <div className="d-flex flex-column w-50" style={{borderRadius: "10px", border: "1px solid #E5E5EF"}}>
                    <div className="h-[310px] w-100 bg-gray-400 align-self-center" style={{borderRadius: "10px 10px 0px 0px"}}>
                        {rcntChkData.chkFileId &&
                            <img className={'h-[310px] w-100'}  style={{borderRadius: "10px 10px 0px 0px"}} src={process.env.REACT_APP_FILE_URL + "/bmcss-data/fileDownload?attachSeq=" + rcntChkData.chkFileId + '&sn=' + '0' + '&isPdf=0&time=' + new Date().getTime()}/>
                        }
                    </div>
                    <div className="d-flex justify-content-between p-3">
                        <span className="ivst-site-text">조치전</span>
                        <span className="ivst-site-bf-date">{rcntChkData.regDate}</span>
                    </div>
                </div>
                <div className="d-flex flex-column w-50" style={{borderRadius: "10px", border: "1px solid #E5E5EF"}}>
                    <div className="h-[310px] w-100 bg-gray-400 align-self-center" style={{borderRadius: "10px 10px 0px 0px"}}>
                        {
                            rcntChkData.actFileId &&
                            <img className={'h-[310px] w-100'}  style={{borderRadius: "10px 10px 0px 0px"}} src={process.env.REACT_APP_FILE_URL + "/bmcss-data/fileDownload?attachSeq=" + rcntChkData.actFileId + '&sn=' + '0' + '&isPdf=0&time=' + new Date().getTime()}/>
                        }
                    </div>
                    <div className="d-flex justify-content-between p-3">
                        <span className="ivst-site-text">최근 점검 결과</span>
                        <span className="ivst-site-af-value" style={{color: rcntChkData.ivstSprvDprtGb == 'C007004' ?    '#FBEAEC'  : "#4AC6FF"}}>{rcntChkData.ivstSprvDprtGbNm}</span>
                    </div>
                </div>
            </div>
        </Container>
    )
}
