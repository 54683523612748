import React, {Dispatch, SetStateAction, useEffect, useRef, useState} from "react";
import {COMMON_MODAL_STYLE} from "../../../helper/StyleHelper";
import {Modal, Table} from "react-bootstrap";
import {IoMdClose} from "react-icons/io";
import Datatables from "../../component/Datatables";
import axios from "axios";
import {toast} from "react-toastify";
import messageCodeDic from "../../../util/messageCodeDic";
import Dropdown from 'react-bootstrap/Dropdown';
import {getLoginUser} from "../../../helper/SecurityHelper";

interface IProps {
    setModalIsOpen: Dispatch<SetStateAction<boolean>>;
    modalIsOpen: boolean;
    detailData?: any;
    slctdAuthChrgData: object;
    setSlctdAuthChrgData: Dispatch<SetStateAction<object>>;
    isSingleCheck?: boolean
    siteNm?: string
    blngCmpnySeq: number

}

const SlctAuthChrgModal = (props: IProps) => {

    const [authCodeList, setAuthCodeList] = useState<any>([]);
    const [siteDataList, setSiteDataList] = useState<any>([]);
    const [chrgCodeList, setChrgCodeList] = useState<any>([]);
    const user = getLoginUser();
    const [authCode, setAuthCode] = useState<any>();
    const [siteId, setSiteId] = useState<any>();
    const [chrgCode, setChrgCode] = useState<any>();


    useEffect(() => {
        fn_retrieveAuthCodeList();
    }, [])

    function fn_closeModal() {
        props.setModalIsOpen(false);
    }


    const fn_select = () => {
        if (!authCode) {
            toast.warn('권한을 선택해 주십시오')
            return
        }

        if (!siteId) {
            toast.warn('현장을 선택해 주십시오')
            return;
        }

        if (!chrgCode) {
            toast.warn('직책을 선택해 주십시오')
            return
        }

        props.setSlctdAuthChrgData({authCode: authCode, siteId: siteId, chrgCode: chrgCode, isRep: false});
        props.setModalIsOpen(false);
    }

    const fn_retrieveAuthCodeList = () => {
        axios.post('/admin/api/cmmn/retrieveAuthCodeList', {authGrpId: 'SITE'}).then((rsltData) => {
            setAuthCodeList(rsltData.data);
        })
    }

    const fn_retrieveCmpnySiteDataList = (authCode: string) => {

        let cstmAuthCodeList = authCodeList;
        cstmAuthCodeList.map((data: any) => {
            if (authCode == data.authCode) {
                data.isActive = true;
            } else {
                data.isActive = false;
            }
        })

        setAuthCodeList(cstmAuthCodeList);

        setAuthCode(authCode);
        setSiteId(null);
        setChrgCode(null);
        axios.post('/admin/api/company/retrieveCmpnySiteDataList', {companySeq: props.blngCmpnySeq}).then((rsltData) => {
            setSiteDataList(rsltData.data)
            setChrgCodeList([])
        })
    }


    const fn_retrieveSiteManageItemList = (siteId: string) => {

        let cstmSiteDataList = siteDataList;
        cstmSiteDataList.map((data: any) => {
            if (siteId == data.siteId) {
                data.isActive = true;
            } else {
                data.isActive = false;
            }
        })
        setSiteDataList(cstmSiteDataList);
        setSiteId(siteId);
        setChrgCode(null);

        axios.post('/admin/api/manage/retrieveSiteManageItemList', {
            siteId: siteId,
            manageCode: 'M001003'
        }).then((result) => {
            if(result.data.length == 0){
                setChrgCodeList([{manageSn:-1, manageName:'없음'}])
            }else{
                setChrgCodeList(result.data);
            }


        })


    }

    const fn_setChrgCode = (manageSn: number) => {

        let cstmChrgCodeList = chrgCodeList;
        cstmChrgCodeList.map((data: any) => {
            if (manageSn === data.manageSn) {
                data.isActive = true;
            } else {
                data.isActive = false;
            }
        })
        console.log('cstmChrgCodeList: ', cstmChrgCodeList);
        setChrgCode(manageSn)
        setChrgCodeList(cstmChrgCodeList);
    }

    const fn_onAfterOpen = () => {
        fn_retrieveAuthCodeList()
    }

    const fn_onAfterClose = () => {
        setAuthCodeList([])
        setSiteDataList([]);
        setChrgCodeList([]);
    }

    return (
        <div>
            <Modal
                className="bmc-modal sub" size="xl"
                show={props.modalIsOpen}
                onShow={fn_onAfterOpen}
                onHide={fn_onAfterClose}

            >

                <Modal.Header>
                    <div className="d-flex justify-content-between align-items-center w-100 bmc-font-SD">
                        현장별 권한 및 직책 선택하기
                        <IoMdClose className='hover:cursor-pointer' onClick={fn_closeModal}/>
                    </div>
                </Modal.Header>


                <Modal.Body className="px-4">
                    <div className={'ge-result'}>
                        <div className={'row'}>
                            <div className={'col-md-4'}>
                                <Dropdown.Menu show className={'relative min-w-[210px] min-h-[232px] h-100'}>
                                    <Dropdown.Header className={'text-center'}>권한</Dropdown.Header>
                                    <Dropdown.Divider/>
                                    {
                                        authCodeList?.map((data: any, idx: any) => (
                                            <Dropdown.Item key={idx} active={data.isActive} eventKey={data.authCode}
                                                           onClick={() => {
                                                               fn_retrieveCmpnySiteDataList(data.authCode)
                                                           }}>{data.authCodeNm}</Dropdown.Item>
                                        ))
                                    }

                                </Dropdown.Menu>
                            </div>
                            <div className={'col-md-4'}>
                                <Dropdown.Menu show className={'relative min-w-[210px] min-h-[232px] h-100'}>
                                    <Dropdown.Header className={'text-center'}>현장</Dropdown.Header>
                                    <Dropdown.Divider/>
                                    <div className='w-100 overflow-auto'>
                                    {
                                        siteDataList.map((data: any, idx: any) => (
                                            <Dropdown.Item
                                                className='w-100 pe-3'
                                                key={idx} active={data.isActive}
                                                onClick={() => {
                                                    fn_retrieveSiteManageItemList(data.siteId)
                                                }}
                                            >{data.siteName}</Dropdown.Item>
                                        ))
                                    }
                                    </div>
                                </Dropdown.Menu>
                            </div>
                            <div className={'col-md-4'}>
                                <Dropdown.Menu show className={'relative min-w-[210px] min-h-[232px] h-100'}>
                                    <Dropdown.Header className={'text-center'}>직책</Dropdown.Header>
                                    <Dropdown.Divider/>
                                    {
                                        chrgCodeList.map((data: any, idx: any) => {
                                            console.log(data.isActive)
                                            return (
                                                <Dropdown.Item key={idx} active={data.isActive} onClick={() => {
                                                    fn_setChrgCode(data.manageSn)
                                                }}>{data.manageName}</Dropdown.Item>)
                                        })
                                    }
                                </Dropdown.Menu>
                            </div>
                        </div>

                    </div>
                </Modal.Body>
                <Modal.Footer className="py-2">
                    <div className='flex justify-center w-100'>
                        <button
                            className='bmc-btn pill l white me-3'
                            onClick={fn_closeModal}>닫기
                        </button>
                        <button onClick={fn_select}
                                className='bmc-btn pill p'>선택

                        </button>
                    </div>
                </Modal.Footer>
            </Modal>
        </div>
    )
}

export default SlctAuthChrgModal;
