import {Modal, Table} from "react-bootstrap";
import {IoMdClose} from "react-icons/io";
import iconCalendar from "../../../images/icon-calendar-solid.svg";
import CustomDatePickerRange from "../../component/ui/DatePickerRange";
import React, {Dispatch, SetStateAction, useEffect, useRef, useState} from "react";
import {fn_getCurrentDate} from "../../../helper/CmmnHelper";
import {getLoginUser} from "../../../helper/SecurityHelper";
import {Col, Row} from "react-bootstrap";
import {Container} from "@mui/material";
import {COMMON_MODAL_STYLE, COMMON_MULTISEL_STRINGS} from "../../../helper/StyleHelper";
import Select from 'react-select'
import {MultiSelect} from "react-multi-select-component";
import axios from "axios";
import {toast} from "react-toastify";
import messageCodeDic from "../../../util/messageCodeDic";
import errorCodeDic from "../../../util/errorCodeDic";
import BMCInput from "../../../components/common/BMCInput";
import BMCTextarea from "../../../components/common/BMCTextarea";
import BMCSelect from "../../../components/common/BMCSelect";
import CustomDatePicker from "../../component/ui/DatePicker";
import iconTrashBean from "../../../images/icon_trashBean.png";
import iconUser from "../../../images/icon_user.svg";
import iconSave from "../../../images/icon_save.svg";
import SiteSearchModal from "../../companyManagement/modal/SiteSearchModal";
import AttndSrchModal from "./AttndSrchModal";
import restapi from "../../../helper/restAPIHelper";
import BMCDatePicker from "../../../components/common/BMCDatePicker";
import BMCIconButton from "../../../components/common/BMCIconButton";
import BMCFile from "../../../components/common/BMCFile";


interface IProps {
    setModalIsOpen: Dispatch<SetStateAction<boolean>>;
    modalIsOpen: boolean;
    detailData: any;
    setIsSaveSuccess: Dispatch<SetStateAction<boolean>>;
    siteId: number;
}

const SftyWrkMetRgstModal = (props: IProps) => {

    const prodRef = useRef<any>();
    const currentDate = fn_getCurrentDate();
    const user = getLoginUser();

    const [metDt, setMetDt] = useState<string>('');
    const [isDatePopupOpen, setIsDatePopupOpen] = useState(false);

    const [data, setData] = useState<any>()
    const [eduTimeList, setEduTimeList] = useState<any>([]);
    const [attndDataList, setAttndDataList] = useState<any[]>([]);
    const [isAttndPopOpend, setIsAttndPopOpend] = React.useState<boolean>(false);

    const formRef = React.createRef<HTMLFormElement>();
    const [fileList, setFileList] = useState([]);


    const fn_setFormData = (e: any) => {
        setData({
            ...data,
            [e.target.name]: e.target.value
        })
    }

    function afterOpenModal() {
        if (!props.detailData) {
            setFileList([]);
            setData(null);
            setData({siteId: props.siteId})
            setMetDt('')
            setAttndDataList([]);
            // 상세
        } else {
            setData(props.detailData);
            setMetDt(props.detailData.metDt)
            restapi.retrieveSftyWrkMetAttndInfoData(props.detailData.sftyWrkMetId).then((rsltData) => {
                setAttndDataList(rsltData);
            })

            if(props.detailData.atchFileId){
                restapi.retrieveAttachInfoList({attachSeq: props.detailData.atchFileId}).then((rsltData: any) => {
                    setFileList([].concat(rsltData));
                })
            }else{
                setFileList([]);
            }
        }
        fn_genEduTime();
    }

    function fn_closeModal() {
        props.setModalIsOpen(false);
    }

    const fn_datePopupOpen = () => {
        setIsDatePopupOpen(!isDatePopupOpen);
    };

    const submit = (e: any) => {
        e.preventDefault()
    }

    const fn_registData = () => {

        if (!data) {
            toast.warn('데이터를 입력해 주십시오.')
            return;
        }

        if (!metDt) {
            toast.warn('회의일자를 선택해 주십시오.');
            return;
        }

        if (!data.metStrtTime) {
            toast.warn('회의시작 시간을 입력해 주십시오.');
            return;
        }

        if (!data.metEndTime) {
            toast.warn('회의종료 시간을 입력해 주십시오.');
            return;
        }

        if (data.metStrtTime > data.metEndTime) {
            toast.warn('회의 종료시간이 회의시간 이전일 수 없습니다.');
            return;
        }

        if (!data.metKnd) {
            toast.warn('회의록 종류를 입력해 주십시오.');
            return;
        }

        if (!data.metCntnts) {
            toast.warn('회의내용을 입력해 주십시오.')
            return;
        }

        if (!data.dcsn) {
            toast.warn('의결사항을 입력해 주십시오.')
            return;
        }

        if (attndDataList.length == 0) {
            toast.warn('회의 참석자를 선택해 주십시오.')
            return;
        }

        if (!window.confirm('저장 하시겠습니까?')) {
            return;
        }

        let param = data;
        param.metDt = metDt;
        param.rgstrSeq = user.userSeq;
        param.updtrSeq = user.userSeq;
        param.sftyWrkMetAttndDataList = attndDataList;

        // @ts-ignore
        var formData: any = new FormData(formRef.current)

        if(fileList.length > 0){
            for(var i =0;i<fileList.length;i++){
                formData.append('atchFileList',fileList[i]);
            }
        }

        formData.set('sftyWrkMetVO', new Blob([JSON.stringify(param)], {type: "application/json"}))

        axios.post('/admin/api/sftyWrkMet/saveData', formData).then((result) => {

            if (result.data > 0) {
                toast.success(messageCodeDic['001'])
                props.setModalIsOpen(false);
                props.setIsSaveSuccess(true);
            } else {
                toast.error(errorCodeDic['003']);
                props.setIsSaveSuccess(false);
            }
        })
    }

    const fn_genEduTime = () => {

        let date = new Date(2023, 0, 1);
        let timeArr = ['00:00']
        let hr;
        let mi;

        for (var i = 0; i < 47; i++) {
            date.setMinutes(date.getMinutes() + 30);
            hr = date.getHours() + '';
            mi = date.getMinutes() + '';
            if (hr.length == 1) {
                hr = '0' + hr;
            }
            if (mi.length == 1) {
                mi = '0' + mi;
            }
            timeArr.push(hr + ':' + mi);
        }

        setEduTimeList(timeArr);


    }

    const fn_delRow = (idx: number) => {

        // @ts-ignore
        let cstmAttndDataList = [].concat(attndDataList);
        cstmAttndDataList.splice(idx, 1);
        setAttndDataList(cstmAttndDataList);
    }


    return (
        <div>
            <AttndSrchModal setModalIsOpen={setIsAttndPopOpend} modalIsOpen={isAttndPopOpend}
                            blngSiteList={attndDataList}
                            setBlngSiteList={setAttndDataList}/>
            <Modal
                className="bmc-modal" size="xl"
                show={props.modalIsOpen}
                onShow={afterOpenModal}
                onHide={fn_closeModal}
            >
                <Modal.Header>
                    <div className="d-flex justify-content-between align-items-center w-100 bmc-font-SD">
                        안전업무 회의록 등록/수정
                        <IoMdClose className='hover:cursor-pointer' onClick={fn_closeModal}/>
                    </div>
                </Modal.Header>

                <Modal.Body className="px-4">
                    <form name={'form'} ref={formRef} encType="multipart/form-data" method={"post"}
                          onSubmit={submit}>
                        <div className="bmc-modal-div">
                            <div className="div-header">
                                교육정보
                            </div>
                            <div className="div-body px-3">
                                <Table className="my-2 align-middle">
                                    <tbody>
                                    <tr className="border-top align-middle">
                                        <th className="bmc-bg-th" style={{width: '15%'}}>현장</th>
                                        <td style={{width: '35%'}}>
                                            <BMCSelect initOptionGb={'none'} disabled={true} codeGb={'site'}
                                                      onChange={fn_setFormData} className={'form-control'}
                                                      value={data?.siteId}></BMCSelect>
                                        </td>
                                        <th className="bmc-bg-th" style={{width: '15%'}}>회의일자</th>
                                        <td style={{width: '35%'}} ref={prodRef}>

                                                <BMCDatePicker
                                                    className={"text-center w-100"} onClick={fn_datePopupOpen} calendar={fn_datePopupOpen}
                                                    value={metDt} name={'metDt'} onChange={fn_setFormData}
                                                    readOnly={true}/>

                                                {isDatePopupOpen
                                                    ? <div className='absolute top-[17%] left-[65%]'>
                                                        <CustomDatePicker setIsDatePopupOpen={setIsDatePopupOpen}
                                                                          prodRef={prodRef}
                                                                          setDateView={setMetDt}
                                                        />
                                                    </div>
                                                    : null}
                                        </td>
                                    </tr>
                                    <tr className="border-top align-middle">
                                        <th className="bmc-bg-th" style={{width: '15%'}}>회의시간</th>
                                        <td style={{width: '35%'}}>
                                            <div className="d-flex align-items-center w-100 gap-1">
                                                <select className={'form-select'} value={data?.metStrtTime || ''}
                                                        onChange={fn_setFormData} name={'metStrtTime'}
                                                        placeholder={'시작시간'}>
                                                    <option className={'text-center'} value={''}>시작 시간</option>
                                                    {
                                                        eduTimeList.map((data: string, idx: number) => (
                                                            <option className={'text-center'} key={idx}
                                                                    value={data}>{data}</option>
                                                        ))
                                                    }

                                                </select>
                                                <select className={'form-select'} name={'metEndTime'}
                                                        onChange={fn_setFormData} value={data?.metEndTime || ''}
                                                        placeholder={'종료시간'}>
                                                    <option className={'text-center'} value={''}>종료 시간</option>
                                                    {
                                                        eduTimeList.map((data: string, idx: number) => (
                                                            <option className={'text-center'} key={idx}
                                                                    value={data}>{data}</option>
                                                        ))
                                                    }
                                                </select>
                                            </div>
                                        </td>
                                        <th className="bmc-bg-th" style={{width: '15%'}}>회의록 종류</th>
                                        <td style={{width: '35%'}}>
                                            <BMCInput type="text" className="form-control" name={'metKnd'}
                                                     onChange={fn_setFormData} value={data?.metKnd}/>
                                        </td>
                                    </tr>
                                    <tr className="border-top align-middle">
                                        <th className="bmc-bg-th" style={{width: '15%'}}>회의내용</th>
                                        <td style={{width: '85%'}} colSpan={3}>
                                            <BMCTextarea rows={5} onChange={fn_setFormData} name={'metCntnts'}
                                                        value={data?.metCntnts}
                                                        className={"form-control"}
                                                        placeholder='내용을 입력해주세요.'/>
                                        </td>
                                    </tr>
                                    <tr className="border-top align-middle">
                                        <th className="bmc-bg-th" style={{width: '15%'}}>의결사항</th>
                                        <td style={{width: '85%'}} colSpan={3}>
                                            <BMCTextarea rows={5} onChange={fn_setFormData} name={'dcsn'}
                                                        value={data?.dcsn}
                                                        className={"form-control"}
                                                        placeholder='내용을 입력해주세요.'/>
                                        </td>
                                    </tr>
                                    <tr className="align-middle" style={{width: '15%'}}>
                                        <th className="bmc-bg-th">첨부파일</th>
                                        <td colSpan={3}>
                                            <BMCFile fileName={'files'} fileList={fileList} setFileList={setFileList}></BMCFile>
                                        </td>
                                    </tr>
                                    </tbody>
                                </Table>
                            </div>
                        </div>
                        <div className="bmc-modal-div">
                            <div className="div-header">
                                <div className='flex justify-content-between align-items-center w-100'>
                                    <span>참석자</span>
                                    <BMCIconButton kind='add' onClick={() => {
                                        setIsAttndPopOpend(true)}}>추가</BMCIconButton>
                                </div>
                            </div>
                            <div className="div-body px-3">
                                <Table className="sub my-2 text-center">
                                    <thead>
                                    <tr className="table-secondary">
                                        <td className="align-middle w-[10%]">No</td>
                                        <td className="align-middle w-[40%]">업체</td>
                                        <td className="align-middle w-[40%]">성명</td>
                                        <td className="align-middle w-[10%]">삭제</td>
                                    </tr>
                                    </thead>
                                    <tbody>
                                    {
                                        attndDataList.length > 0 ?
                                            attndDataList.map((data: any, idx) => (
                                                <tr key={idx}>
                                                    <td>{idx + 1}
                                                    </td>
                                                    <td>
                                                        {data.companyName}
                                                    </td>
                                                    <td>
                                                        {data.userName}
                                                    </td>

                                                    <td className={'text-center'}><img
                                                        className={'w-[25px] cursor-pointer inline-block'}
                                                        src={iconTrashBean} onClick={() => fn_delRow(idx)}/>
                                                    </td>
                                                </tr>

                                            )) : <tr>
                                                <td colSpan={4}>회의 참석자를 등록해 주세요.</td>
                                            </tr>
                                    }

                                    </tbody>
                                </Table>
                            </div>
                        </div>
                        <div className="bmc-modal-div">
                            <div className="div-header">
                                작성정보
                            </div>
                            <div className="div-body px-3">
                                <Table className="my-2 align-middle">
                                    <tbody>
                                    <tr className="border-top align-middle">
                                        <th className="bmc-bg-th" style={{width: '15%'}}>작성일</th>
                                        <td style={{width: '35%'}}>
                                            <input value={data?.rgstDt || currentDate}
                                                   className={"form-control"}
                                                   disabled={true}/>
                                        </td>
                                        <th className="bmc-bg-th" style={{width: '15%'}}>작성자</th>
                                        <td style={{width: '35%'}}>
                                            <input className={"form-control"}
                                                   value={data?.rgstrNm || user.userName} disabled={true}/>
                                        </td>
                                    </tr>
                                    </tbody>
                                </Table>
                            </div>
                        </div>

                    </form>
                </Modal.Body>
                <Modal.Footer className="py-2">
                    <div className='flex justify-center w-100'>
                        <button
                            className='bmc-btn pill l white me-3'
                            onClick={fn_closeModal}>닫기
                        </button>
                        <button onClick={fn_registData}
                                className='bmc-btn pill p'>등록/수정
                        </button>

                    </div>
                </Modal.Footer>
            </Modal>
        </div>


    )
}

export default SftyWrkMetRgstModal;

