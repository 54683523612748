import React, {useContext, useEffect, useRef, useState} from 'react';
import {getLoginUser} from "../../helper/SecurityHelper";
import {UserContext} from "../../context/User";
import {DateHelper} from "../../helper/DateHelper";
import Datatables, {getCheckedItems} from "../component/Datatables";
import {toast} from "react-toastify";
import messageCodeDic from "../../util/messageCodeDic";
import axios from "axios";
import errorCodeDic from "../../util/errorCodeDic";
import {Container, Table} from "react-bootstrap";
import SubMenuTitle from "../component/SubMenuTitle";
import SafetyEducationContentsModal from "./modal/SafetyEducationContentsModal";
import SHSearch from "../component/BMCSearch";
import BMCDatePicker2 from "../../components/common/BMCDatePicker2";
import BMCIconButton from "../../components/common/BMCIconButton";
import SafetyManagementGeneralBulletinBoardModal from "./modal/SafetyManagementGeneralBulletinBoardModal";

type Props = {};

const SafetyManagementGeneralBulletinBoardPage = (props: Props) => {
    const [detailData, setDetailData] = useState(null);
    const [modalIsOpen, setModalIsOpen] = React.useState(false);
    const [datatable, setDatatable] = useState<any>();
    const searchFormRef = useRef<any>();
    const srchTxtRef = useRef<any>();
    const defaultUser = getLoginUser();
    const {user} = useContext<any>(UserContext);
    const [data, setData] = useState<any>();

    const [startDate, setStartDate] = useState<Date>(DateHelper.dateByMonth(-3));
    const [endDate, setEndDate] = useState<Date>(DateHelper.dateByMonth(0));
    const prodRef = useRef<any>();

    useEffect(() => {
        datatable?.draw();
    }, [modalIsOpen])

    const fn_setFormData = (e: any) => {
        setData({
            ...data,
            [e.target.name]: e.target.value
        })
    }

    const fn_registPopOpen = () => {
        setDetailData(null);
        setModalIsOpen(true);
    }

    const fn_search = () => {
        datatable.draw();
    }

    const fn_delete = ()=>{
        let checkedData = getCheckedItems(datatable, "sftyMngGnrlblltnBrdId")

        if (checkedData.length == 0) {
            toast.warn(messageCodeDic['005']);
            return;
        }

        if (!window.confirm(messageCodeDic['004'])) {
            return;
        }
        console.log("delList : " , checkedData)
        axios.post('/admin/api/sftyMngGnrlblltnBrd/deleteSftyMngGnrlblltnBrdData', {delList: checkedData}).then(rsltData => {
                if (rsltData.data > 0) {
                    toast.success(messageCodeDic['002'])
                    datatable?.draw();
                } else {
                    toast.error(errorCodeDic['003'])
                }
            }
        ).catch(() => {
                toast.error(errorCodeDic['003'])
            }
        )
    }

    const fn_updateViewCnt = (param: object) => {
        axios.post('/admin/api/sftyMngGnrlblltnBrd/updateViewCnt', param);
    }

    return (
        <Container fluid className="bg-white px-0 bmc-txt-color-primary">
            <SubMenuTitle title={'안전 관리 일반 게시판'} parentMenu={'안전 정보 게시판'} />
            <SafetyManagementGeneralBulletinBoardModal setModalIsOpen={setModalIsOpen} modalIsOpen={modalIsOpen} detailData={detailData} />

            <div className="p-4 overflow-y-auto" style={{height: 'calc(100vh - 180px)'}}>
                <SHSearch datatable={datatable} formRef={searchFormRef} btnType={'p'}>
                    <Table className="bmc-font-SP bmc-fs-b2 my-3">
                        <tbody>
                        <tr className="border-top border-bottom align-middle">
                            <th className="bmc-bg-th" style={{width:'15%'}}>업체</th>
                            <td style={{width:'35%'}}>
                                <div className="d-flex flex-row gap-2">
                                    <div className="d-flex align-items-center" ref={prodRef}>
                                        <BMCDatePicker2 name={'startRgstDt'} defaultValue={startDate} onChange={fn_setFormData} />
                                        <span>&nbsp;~&nbsp;</span>
                                        <BMCDatePicker2 name={'endRgstDt'} defaultValue={endDate} onChange={fn_setFormData} />
                                    </div>
                                    <div className="d-flex align-items-center gap-1" ref={prodRef}>
                                        <input className="form-check-input bmc-check" id={'isAll'} name={'isAll'} type="checkbox" onChange={fn_setFormData}/>
                                        <label className={'form-check-label text-black'} htmlFor={'isAll'}>전체기간</label>
                                    </div>
                                </div>
                            </td>
                            <th className="bmc-bg-th" style={{width:'15%'}}>검색어</th>
                            <td style={{width:'35%'}}>
                                <input type={'text'} name={'srchTxt'} className='form-control' ref={srchTxtRef}/>
                            </td>
                        </tr>
                        </tbody>
                    </Table>
                </SHSearch>

                <div className='w-100 d-flex justify-content-end align-items-center mb-2'>
                    <div className='d-flex justify-content-end'>
                        <BMCIconButton kind={'submit'} marginRight={'5px'} onClick={fn_registPopOpen}>등록</BMCIconButton>
                        <BMCIconButton kind={'delete'} marginRight={'0'} onClick={fn_delete}>삭제</BMCIconButton>
                    </div>
                </div>

                <div className={'ge-result'}>
                    <Datatables
                        dataUrl={'/admin/api/sftyMngGnrlblltnBrd/retrieveSftyMngGnrlblltnBrdDataList'}
                        parameterFormRef={searchFormRef}
                        columns={[
                            {data: "title", title: "제목", textAlign: 'center'},
                            {data: "userName", title: "작성자", textAlign: 'center'},
                            {data: "rgstDt", title: "작성일시", textAlign: 'center'},
                            {data: "viewCnt", title: "조회수", textAlign: 'center'},
                        ]}

                        fixedTop={"isFixedTop"}
                        checked={true}
                        index={true}

                        onLoad={(dt: any) => {
                            setDatatable(dt)
                        }}

                        onClick={(data) => {
                            setDetailData(data)
                            setModalIsOpen(true);
                        }}
                    >
                    </Datatables>
                </div>
            </div>
        </Container>
    );
};

export default SafetyManagementGeneralBulletinBoardPage;
