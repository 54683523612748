import React, {useContext, useEffect, useState} from 'react';
import {Col, Container, Row, Table} from "react-bootstrap";
import {ReactComponent as IconRight} from "../../assets/images/ico/icon-right.svg";
import { Chart as ChartJS, ArcElement, Tooltip } from 'chart.js';
import {Doughnut} from 'react-chartjs-2';
import {UserContext} from "../../context/User";
import {getLoginUser} from "../../helper/SecurityHelper";
import restapi from "../../helper/restAPIHelper";
import {useNavigate} from "react-router-dom";
import IncgrtyInfoListModal from "./modal/IncgrtyInfoListModal";

ChartJS.register(ArcElement, Tooltip);

const DashboardNonconformityState = () => {
    const {user} = useContext<any>(UserContext);
    const defaultUser = getLoginUser();
    const navigate = useNavigate();
    const [modalIsOpen, setModalIsOpen] = useState<boolean>(false);
    const [incgrtyDataList, setIncgrtyDataList] = useState<any>([]);
    const [data, setData] = useState<any>({totCnt: 0, cmpltCnt: 0, nCmpltCnt: 0});
    const [prcsStatCd, setPrcsStatCd] = useState<string>('');
    const [cData, setCData] = useState<any>({
        labels: ['미조치', '조치완료'],
        datasets: [{
            data: [0, 0],
            backgroundColor: [
                'rgba(86, 133, 247, 1)',
                'rgba(74, 198, 255, 1)',
            ],
            borderColor: [
                'rgba(255, 255, 255, 1)',
                'rgba(255, 255, 255, 1)',
            ],
            borderWidth: 1,
            borderRadius: 5,
        }],
    });
    const plugin = {
        id: 'emptyDoughnut',
        afterDraw(chart: any, args: any, options: any) {
            const {datasets} = chart.data;
            const {color, width, radiusDecrease} = options;
            let hasData = false;
            for (let i = 0; i < datasets[0].data.length; i += 1) {
                const dataset = datasets[0].data[i];
                hasData = dataset > 0;
                if(hasData === true){
                    break;
                }
            }
            if (!hasData) {
                const {chartArea: {left, top, right, bottom}, ctx} = chart;
                const centerX = (left + right) / 2;
                const centerY = (top + bottom) / 2;
                const r = Math.min(right - left, bottom - top) / 2;

                ctx.beginPath();
                ctx.lineWidth = width || 2;
                ctx.strokeStyle = color || 'rgba(255, 128, 0, 0.5)';
                ctx.arc(centerX, centerY, (r - radiusDecrease || 0), 0, 2 * Math.PI);
                ctx.stroke();
            }
        }
    };
    const options = {
        responsive: true,
        plugins: {
            legend: {
                display: false,
            },
            emptyDoughnut: {
                color: '#cccccc55',
                width: 20,
                radiusDecrease: 10
            }
        },
        cutout: '80%'
    };

    useEffect(() => {
        const siteId = user?.blngSiteId || defaultUser.blngSiteId;
        restapi.retrieveIncgrtyDataTotList({siteId:siteId}).then((rsltData: any) => {
            const totCnt = rsltData.length;
            let cmpltCnt = 0;
            let nCmpltCnt = 0;
            console.log('totCnt: ', totCnt);

            rsltData.map((data: any) => {
                if(data.prcsStatCd == 'I001001'){
                    nCmpltCnt ++;
                }else if(data.prcsStatCd == 'I001002'){
                    cmpltCnt ++;
                }
            })

            setIncgrtyDataList(rsltData);

            console.log('cmpltCnt: ', cmpltCnt);
            console.log('nCmpltCnt: ', nCmpltCnt);
            setData({totCnt: totCnt, cmpltCnt: cmpltCnt, nCmpltCnt: nCmpltCnt})

            setCData(
                {
                    labels: ['미조치', '조치완료'],
                    datasets: [{
                        data: [nCmpltCnt, cmpltCnt],
                        backgroundColor: [
                            'rgba(86, 133, 247, 1)',
                            'rgba(74, 198, 255, 1)',
                        ],
                        borderColor: [
                            'rgba(255, 255, 255, 1)',
                            'rgba(255, 255, 255, 1)',
                        ],
                        borderWidth: 1,
                        borderRadius: 5,
                    }],
                }
            )

        })
    }, [user])

    const fn_openIncgrtyInfoModal = (prcsStatCd: string) => {
        setPrcsStatCd(prcsStatCd);
        setModalIsOpen(true);
    }

    return(
        <>
            <Container fluid className="bmc-bg-pane rounded-[8px] h-100">
                <IncgrtyInfoListModal prcsStatCd={prcsStatCd} setModalIsOpen={setModalIsOpen}  modalIsOpen={modalIsOpen} incgrtyDataList={incgrtyDataList} siteId={user?.blngSiteId || defaultUser.blngSiteId} ></IncgrtyInfoListModal>
                <Row className="px-0">
                    <Col bsPrefix="col-7" className="w-100">
                        <div className="w-100 d-flex align-items-center justify-content-between pb-2 border-bottom">
                            <span className="pe-2 bmc-txt-size-h4">부적합 현황</span>
                            <div className={'cursor-pointer'} onClick={() => navigate('/siteSafetyManagement/incongruityManagement')}>
                                <IconRight style={{width: '20px', marginBottom: '1px', stroke: '#1d1b39'}} />
                            </div>
                        </div>
                    </Col>
                </Row>
                <Row className="px-0 pt-3">
                    <Col>
                        <div className="d-flex w-100 position-relative justify-content-center" style={{height:'13vh'}}>
                            <Doughnut className="position-relative z-50" options={options} data={cData} plugins={[plugin]} width="100%" height="13vh" />
                            <div className="position-absolute w-100 text-center start-50 top-50 z-10" style={{transform: 'translate(-50%, -50%)'}}>
                                <div className="bmc-fs-b3 bmc-text-font_secondary02">전체</div>
                                <div>
                                    <span className="bmc-fw-5 bmc-fs-h2">{data.totCnt}</span>
                                    <span className="bmc-fs-b3">건</span>
                                </div>
                            </div>
                        </div>
                    </Col>
                </Row>
                <Row className="px-0 pt-3 bmc-txt-size-b7">
                    <Col className="d-flex align-items-center justify-content-between">
                        <div className={'cursor-pointer text-center'} onClick={() => fn_openIncgrtyInfoModal('I001001')}>
                            <div>
                                <span className="bmc-txt-size-h5">{data.nCmpltCnt}</span>
                            </div>
                            <div>
                                <span className="bmc-txt-color-01">●</span>
                                <span className="px-1">미조치</span>
                            </div>
                        </div>
                    </Col>
                    <Col className="border-start border-end">
                        <div className={'cursor-pointer text-center'} onClick={() => fn_openIncgrtyInfoModal('I001002')}>
                            <div>
                                <span className="bmc-txt-size-h5">{data.cmpltCnt}</span>
                            </div>
                            <div>
                                <span className="bmc-txt-color-02">●</span>
                                <span className="px-1">조치완료</span>
                            </div>
                        </div>
                    </Col>
                </Row>
            </Container>
        </>
    );
}

export default DashboardNonconformityState;
