import React, {Dispatch, SetStateAction, useContext, useEffect, useRef, useState} from "react";
import {COMMON_MODAL_STYLE} from "../../../helper/StyleHelper";
import {Col, Modal, Row, Tab, Table, Tabs} from "react-bootstrap";
import {IoMdClose} from "react-icons/io";
import Datatables, {getCheckedItems} from "../../component/Datatables";
import SHSearch from "../../component/BMCSearch";
import BMCInput from "../../../components/common/BMCInput";
import restapi from "../../../helper/restAPIHelper";
import {toast} from "react-toastify";
import errorCodeDic from "../../../util/errorCodeDic";
import {ProgressContext} from "../../../context/Progress";
import BMCSelect from "../../../components/common/BMCSelect";
import BMCIconButton from "../../../components/common/BMCIconButton";
import axios from "axios";
import NoSbmtSiteListModal from "./NoSbmtSiteListModal";
import SvrAcdntPnshmntRgstModal from "../../siteSafetyManagement/modal/SvrAcdntPnshmntRgstModal";
import SvrAcdntPnshmntByDatePage from "../subPage/SvrAcdntPnshmntByDatePage";
import SvrAcdntPnshmntByDprtPage from "../subPage/SvrAcdntPnshmntByDprtPage";
import SvrAcdntSiteKndModalPage from "../subPage/SvrAcdntSiteKndModalPage";

interface IProps {
    setModalIsOpen: Dispatch<SetStateAction<boolean>>;
    modalIsOpen: boolean;
    tabGb: string;
    chkDt: string;
    dprtCd: string;
    selData: any;
}

const $ = require("jquery")

const SvrAcdntPnshmntChkListModal = (props: IProps) => {

    const [tabParam, setTabParam] = useState<any>({chkDt:props.chkDt, dprtCd:props.dprtCd, siteType:'T002001'});

    function fn_closeModal() {
        props.setModalIsOpen(false);
    }



    const fn_onShow = () => {
        setTabParam({chkDt:props.chkDt, dprtCd:props.dprtCd, siteType:'T002001'});
    }

    return (
        <div>
            <Modal
                className="bmc-modal" size="xl"
                show={props.modalIsOpen}
                onShow={() => {
                    fn_onShow()

                }}
                onHide={fn_closeModal}
            >


                <Modal.Header>
                    <div className="d-flex justify-content-between align-items-center w-100 bmc-font-SD">
                        {props.chkDt} 중대재해처벌법 체크리스트 전체 현황
                        <IoMdClose className='hover:cursor-pointer' onClick={fn_closeModal}/>
                    </div>
                </Modal.Header>

                <Modal.Body className="px-4">
                    <Tabs
                        transition={true}
                        activeKey={tabParam.siteType}
                        onSelect={(k: any) => {setTabParam(Object.assign({}, {chkDt:props.chkDt, dprtCd:props.dprtCd, siteType:k}))}}
                        id="siteType"
                        className="mb-3 mt-2"
                    >
                        <Tab eventKey="T002001" title="일반현장">
                            <SvrAcdntSiteKndModalPage chkDt={props.chkDt} tabParam={tabParam} />
                        </Tab>
                        <Tab eventKey="T002004" title="시설관리">
                            <SvrAcdntSiteKndModalPage chkDt={props.chkDt} tabParam={tabParam} />
                        </Tab>
                        <Tab eventKey="T002002" title="유지보수">
                            <SvrAcdntSiteKndModalPage chkDt={props.chkDt} tabParam={tabParam} />
                        </Tab>

                    </Tabs>
                </Modal.Body>
                <Modal.Footer className="py-2">
                    <div className='flex justify-center w-100'>
                        <button
                            className='bmc-btn pill l white me-3'
                            onClick={fn_closeModal}>닫기
                        </button>

                    </div>
                </Modal.Footer>


            </Modal>
        </div>
    )
}

export default SvrAcdntPnshmntChkListModal;
