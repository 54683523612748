import axios from "axios";
import {IS_SM_SITE, USER_SESSION} from "../helper/SecurityHelper";

const $ = require("jquery")



export const fn_loginHandlerCallback = (user: any, navigate: any, isFromSignUp?: any) => {

    console.log('logined user: ', user)
    if(user.userData?.isSmSite || user.isSmSite){
        navigate('/smSite');
    }else{
        navigate('/');
    }
}

export const fn_search = (e?: any, datatable?: any) => {
    e.preventDefault();
    datatable?.draw();
}

/**
 * 현재 날짜 가져오기
 */
export const fn_getCurrentDate = () => {
    let today = new Date();

    let year = today.getFullYear(); // 년도
    let month: any = today.getMonth() + 1;  // 월
    let date: any = today.getDate();  // 날짜
    let day = today.getDay();  // 요일

    month = (month + '').length == 1 ? '0' + month : month + ''
    date = (date + '').length == 1 ? '0' + date : date + ''

    return year + '-' + month + '-' + date
}



export const CmmnFn = {


    loadCmmnCdData: (codeGrpId: string, codes?: string[]) => {
        return new Promise((resolve, reject) => {
            axios.post('/admin/api/cmmn/retrieveCmmnCodeList', {codeGrpId: codeGrpId, codes: codes}).then((result) => {
                resolve(result.data);
            })
        })

    },

    setFormData: (e: any, data: any, setData: any) => {
        var val
        if (e.target.type == 'checkbox') {
            val = e.target.checked

        } else if (e.target.type == 'radio') {
            var form = $(e.target).parents("form")
            if (form.length === 0) {
                console.warn("form tag 가 존재하지 않습니다.")
                return
            }
            val = form.find("[name='" + e.target.name + "']:checked").val()
            if (val === "true" || val === "false") {
                val = eval(val)
            }
        } else {
            val = e.target.value
        }

        setData({
            ...data,
            [e.target.name]: val
        })

    },
    /**
     * 현재 날짜 가져오기
     */
    fn_getCurrentDate: (delemiter?: string) => {
        let today = new Date();

        let year = today.getFullYear(); // 년도
        let month: any = today.getMonth() + 1;  // 월
        let date: any = today.getDate();  // 날짜
        let day = today.getDay();  // 요일

        month = (month + '').length == 1 ? '0' + month : month + ''
        date = (date + '').length == 1 ? '0' + date : date + ''

        let rtrnStr = year + '-' + month + '-' + date;
        if(delemiter){
            rtrnStr = year + delemiter + month + delemiter + date
        }

        return rtrnStr
    },

    fn_getCurrentDateTime: () => {
        let today = new Date();

        let year = today.getFullYear(); // 년도
        let month: any = today.getMonth() + 1;  // 월
        let date: any = today.getDate();  // 날짜
        let day = today.getDay();  // 요일

        let hours: any = today.getHours(); // 시
        let minutes: any = today.getMinutes();  // 분

        month = (month + '').length == 1 ? '0' + month : month + ''
        date = (date + '').length == 1 ? '0' + date : date + ''
        hours = (hours + '').length == 1 ? '0' + hours : hours + ''
        minutes = (minutes + '').length == 1 ? '0' + minutes : minutes + ''

        return year + '-' + month + '-' + date + ' ' + hours + ':' + minutes;
    },

    dateToString: (param: Date, delimeter?: string) => {

        if (typeof param == "string") {
            param = new Date(param);
        }

        let today = param;
        
        let year = today.getFullYear(); // 년도
        let month: any = today.getMonth() + 1;  // 월
        let date: any = today.getDate();  // 날짜
        let day = today.getDay();  // 요일

        month = (month + '').length == 1 ? '0' + month : month + ''
        date = (date + '').length == 1 ? '0' + date : date + ''

        let rtrnData = '';

        if(delimeter){
            if(delimeter == 'empty'){
                delimeter = '';
            }
            rtrnData = year + delimeter + month + delimeter + date;
        }else{
            rtrnData = year + '-' + month + '-' + date;
        }

        return rtrnData
    },

    /**
     * 한달 전 날짜 가져오기
     */
    getAmonthAgoDate: () => {
        var now = new Date();

        let today = new Date(now.setMonth(now.getMonth() - 1));

        let year = today.getFullYear(); // 년도
        let month: any = today.getMonth() + 1;  // 월
        let date: any = today.getDate();  // 날짜
        let day = today.getDay();  // 요일

        month = (month + '').length == 1 ? '0' + month : month + ''
        date = (date + '').length == 1 ? '0' + date : date + ''

        return year + '-' + month + '-' + date
    },

    fn_getAuthForSite: (user: any, siteId: number) => {

        const authChrgDataList = user.authChrgDataList;
        let auth = '';

        console.log('siteId: ', siteId);

        if (user.authGrpId == 'SITE') {
            authChrgDataList.map((data: any) => {
                if (data.siteId == siteId) {
                    auth = data.authCode;
                }
            })
        } else if (user.authGrpId == 'HEAD') {
            auth = user.authCode;
        }

        console.log('auth: ', auth);
        return auth;

    },

    isValidEqmntOrgPhoto: (orgEqmntCd: number, chkSeq?: number) => {

        let isValid = false;
        if(orgEqmntCd >= 1 && orgEqmntCd <= 32){
            isValid = true;
        }

        if(orgEqmntCd == 10 && chkSeq == 10){
            isValid = false;
        }
        return isValid;

    },
    dateFormat: (addMonth: number) => {
    let today = new Date();
    let month = today.getMonth() + addMonth;
    let day = today.getDate();
    let monthFormat = month >= 10 ? month : '0' + month;
    let dayFormat = day >= 10 ? day : '0' + day;

    return today.getFullYear() + '-' + monthFormat + '-' + dayFormat ;


    },
    isSmSite: () => {
        const isSmSite = localStorage.getItem(IS_SM_SITE);

        var isTrueSet = (isSmSite === 'true');

        return isTrueSet;
    },

    getAge: (birthDay: string) => {
        var today = new Date();
        var birthDate = new Date(birthDay);
        var age = today.getFullYear() - birthDate.getFullYear();
        var m = today.getMonth() - birthDate.getMonth();
        if (m < 0 || (m === 0 && today.getDate() < birthDate.getDate())) {
            age--;
        }
        return age;
    }

}






