import React, {Dispatch, SetStateAction, useState} from "react";

interface IProps {
    items: object[]
    setItems: Dispatch<SetStateAction<object[]>>;
    isReadOnly?: boolean;
}

export default function SiteCardList(props: IProps) {

    const fn_delete = (siteId: number) => {
       const filteredData = props.items.filter((data:any) => {
            return data.siteId !== siteId
        })

        props.setItems(filteredData);
    }


    return (
        <div className="container-fluid px-0">
            <div className={"row overflow-auto " + (props.items.length > 0 && 'h-[200px]')}  id="ga-company-sites">
                {
                    props.items.map((item: any, idx) => {
                        return (
                            <div key={idx} className="col-md-6 mb-4 ">
                                <div className="card ga-card small"><input type="hidden" name="siteSeqs"
                                                                           value={item.siteId}/>
                                    <div className="card-header">{item.cnstrProd}</div>
                                    <div className="card-body"><h5 className="card-title">{item.siteName}</h5><p
                                        className="card-text">{item.siteAddress}</p>
                                        <div className="text-end">
                                            {!props.isReadOnly &&
                                                <button type="button" className="btn btn-sm btn-ga-danger mt-2"
                                                        onClick={() => fn_delete(item.siteId)}>삭제
                                                </button>
                                            }


                                        </div>
                                    </div>
                                </div>
                            </div>
                        )
                    })
                }


            </div>
        </div>
    )
}
