import SHSearch from "../../component/BMCSearch";
import {Col, Row, Table} from "react-bootstrap";
import React, {Dispatch, SetStateAction, useEffect, useRef, useState} from "react";
import Datatables from "../../component/Datatables";
import BMCDatePicker from "../../../components/common/BMCDatePicker";
import CustomDatePickerRange from "../../component/ui/DatePickerRange";
import axios from "axios";
import {toast} from "react-toastify";
import errorCodeDic from "../../../util/errorCodeDic";
import CustomDatePicker from "../../component/ui/DatePicker";
import BMCDatePicker2 from "../../../components/common/BMCDatePicker2";
import {DateHelper} from "../../../helper/DateHelper";
import BMCIconButton from "../../../components/common/BMCIconButton";

interface IProps {
   dataList: any;
   setSelData: Dispatch<SetStateAction<any>>;
   srchData: any;
   setSrchData: Dispatch<SetStateAction<boolean>>;
   isDatePopupOpen: boolean;
   setIsDatePopupOpen: Dispatch<SetStateAction<boolean>>;
   onSearch: any;
}

const FcltySftyHistPage = (props: IProps) => {
    const prodRef = useRef<any>();
    const [chkDt, setChkDt] = useState<Date>(DateHelper.dateByMonth(0));
    const [startDate, setStartDate] = useState<Date>(DateHelper.dateByMonth(-1));
    const [endDate, setEndDate] = useState<Date>(DateHelper.dateByMonth(0));

    const fn_setSrchData = (e: any) => {

        props.setSrchData({
            ...props.srchData,
            [e.target.name]: e.target.value
        })


    }

    return(
        <>

            <SHSearch btnType={'p'} onSearch={props.onSearch}>
                <Row>
                    <Col>
                        <Table className="bmc-font-SP bmc-fs-b2 my-3">
                            <tbody>
                            <tr className="align-middle border-bottom">
                                <th className="bmc-bg-th">기간</th>
                                <td colSpan={2}>
                                    <div className="d-flex flex-row gap-2">
                                        <div className="d-flex align-items-center" ref={prodRef}>
                                            <BMCDatePicker2 name={'strtDt'} readOnly={true} defaultValue={startDate} value={props.srchData.strtDt}  onChange={fn_setSrchData} />
                                            <span>&nbsp;~&nbsp;</span>
                                            <BMCDatePicker2 name={'endDt'} readOnly={true} defaultValue={endDate} value={props.srchData.endDt} onChange={fn_setSrchData} />
                                        </div>

                                    </div>
                                </td>
                            </tr>
                            </tbody>
                        </Table>
                    </Col>
                </Row>
            </SHSearch>

            <div className={'ge-result'}>
                <Datatables
                    datas={props.dataList}
                    scrollY="550px"
                    className="bmc-fs-b4"
                    columns={[
                        {data: "", title: "점검기간", render: function (data: any, type: any, row: any, meta: any) {
                            return row.sftyChkRsltRgstDt + ' ~ ' + row.sftyChkRsltEndDt;
                        }
                        },
                        {data: "sftyDgnsGbNm", title: "구분"},
                        {data: "efctDt", title: "시행일"},
                        {data: "budget", title: '예산', render: function (data: any) {
                                return data?.toLocaleString() + ' (천원)';
                            }},
                        {data: "ivstSprvDprtGbNm", title: "현장점검", textAlign: 'center'},
                        {data: 'sftyChkRsltRgstrNm', title: '점검자'}
                    ]}
                    index={true}
                    checked={false}
                    onLoad={(dt: any) => {
                    }}

                    onClick={(data) => {
                        console.log('selData: ', data);
                        props.setSelData(Object.assign({}, data));
                    }
                    }
                />


            </div>
        </>
        )

}

export default FcltySftyHistPage;
