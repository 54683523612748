import React, {useContext, useEffect, useRef, useState} from 'react';
import restapi from "../../../helper/restAPIHelper";
import {get, Projection, transform, transformExtent} from "ol/proj";
import {Feature, Overlay} from "ol";
import {Point} from "ol/geom";
import VectorLayer from "ol/layer/Vector";
import VectorSource from "ol/source/Vector";
import Marker00 from "../../../images/map/icon_marker_idx_00.png";
import Marker01 from "../../../images/map/icon_marker_idx_01.png";
import Marker02 from "../../../images/map/icon_marker_idx_02.png";
import Marker03 from "../../../images/map/icon_marker_idx_03.png";
import Marker04 from "../../../images/map/icon_marker_idx_04.png";
import {Circle, Fill, Icon, Stroke, Style, Text} from "ol/style";
import TileLayer from "ol/layer/Tile";
import {XYZ} from "ol/source";
import proj4 from "proj4";
import {register} from "ol/proj/proj4";
import Map from "ol/Map";
import View from "ol/View";
import LegendImage from "../../../images/map/map-legend.png";
import TileGrid from "ol/tilegrid/TileGrid";
import {GeoJSON} from "ol/format";
import {SiteContext} from "../../../context/Site";
import { CmmnFn } from '../../../helper/CmmnHelper';
import {ReactComponent as IconMapLabelEtc} from "../../../assets/images/icon/icon-map-label-etc.svg";
import {ReactComponent as IconMapLabelFacility} from "../../../assets/images/icon/icon-map-label-facility.svg";
import {ReactComponent as IconMapLabelConstruct} from "../../../assets/images/icon/icon-map-label-construct.svg";
import {ReactComponent as IconMapLabelLand} from "../../../assets/images/icon/icon-map-label-land.svg";
import {ReactComponent as IconMapLabelRepair} from "../../../assets/images/icon/icon-map-label-repair.svg";
import SiteInfoListModal from "../modal/SiteInfoListModal";
import {getLoginUser, setItemWithExpireTime, USER_SESSION} from "../../../helper/SecurityHelper";
import {toast} from "react-toastify";
import errorCodeDic from "../../../util/errorCodeDic";
import {ProgressContext} from "../../../context/Progress";
import {UserContext} from "../../../context/User";
import {useNavigate} from "react-router-dom";
import SmSiteWrkListModal from "../modal/SmSiteWrkListModal";
const $ = require("jquery")

interface IProps {
    siteType?: string;
    height?: string;
}

export default function RealMap(props: IProps) {
    const [countT002001, setCountT002001] = useState<number>(0);
    const [countT002002, setCountT002002] = useState<number>(0);
    const [countT002003, setCountT002003] = useState<number>(0);
    const [countT002004, setCountT002004] = useState<number>(0);
    const [countT002005, setCountT002005] = useState<number>(0);
    const [activeButtonIdx, setActiveButtonIdx] = useState<number>();
    const [siteDataAll, setSiteDataAll] = useState<any>([]);
    const [siteData, setSiteData] = useState<any>([]);
    const [siteDataFilter, setSiteDataFilter] = useState<any>([]);
    const [dataList, setDataList] = useState<any>([]);
    const [modalIsOpen, setModalIsOpen] = useState<boolean>(false);

    const [siteLayer, setSiteLayer] = useState<any>();
    const mapRef = useRef<HTMLDivElement>(null);
    const overlayRef = useRef<HTMLDivElement>(null);
    const {setSite} = useContext<any>(SiteContext);
    const [siteType, setSiteType] = useState<string>('');
    const {spinner} = useContext(ProgressContext);
    const defaultUser: any = getLoginUser();
    const {user,setUser} = useContext<any>(UserContext);
    const navigate = useNavigate();
    const [total, setTotal] = useState<number>();
    const [smWrkDataList, setSmWrkDataList] = useState<any>([]);
    const [isSmWrkModalOpen, setIsSmWrkModalOpen] = useState<boolean>(false);
    const [smSiteId, setSmSiteId] = useState<number>(0);

    useEffect(() => {
        if(siteDataAll.length > 0){
            fn_select(0);
            var count1 = 0
            var count2 = 0
            var count3 = 0
            var count4 = 0
            var count5 = 0
            for(var i in siteDataAll){
                var item : any = siteDataAll[i]
                var typeCode = item.siteType
                if(typeCode === 'T002001'){
                    // 택지조성공사
                    count1++
                }else if(typeCode === 'T002002'){
                    // 기반시설공사
                    count2++
                }else if(typeCode === 'T002003'){
                    // 공동주택건축공사
                    count3++
                }else if(typeCode === 'T002004'){
                    // 소규모공사
                    count4++
                }else if(typeCode === 'T002005'){
                    // 해체공사
                    count5++
                }
            }
            setTotal(siteDataAll.length)
            setCountT002001(count1)
            setCountT002003(count3)
            setCountT002004(count4)
            setCountT002005(count5)

            restapi.retrieveSmSiteInfoData().then((smSiteInfo: any) => {
                let param: any = {};
                const siteId = smSiteInfo.siteId;
                setSmSiteId(siteId);
                param.prcsStatCd = 'S001004';
                param.siteId = siteId;
                param.wrkDt = CmmnFn.fn_getCurrentDate();

                restapi.retrieveWrkPrmtDataTotList(param).then((rsltData: any) => {
                    setCountT002002(rsltData.length);
                    setSmWrkDataList(rsltData);
                })
            })
        }
    },[siteData])

    const fn_moveToSiteWeb = (siteId: any) => {
        /* nhk temp */
        // if(![449,437,131].includes(siteId)){
        //     return;
        // }

        spinner.start();
        restapi.changeUserSiteHeadWebStat({blngSiteId: siteId, userSeq: defaultUser.userSeq}).then((rsltCnt: any) => {
            if (rsltCnt > 0) {
                restapi.getUserSession(defaultUser.userSeq).then((data: any) => {
                    setUser(data);
                    setItemWithExpireTime(USER_SESSION, data);
                    navigate('/')
                });
            }else{
                toast.error(errorCodeDic['003']);
            }
        }).catch(error => {
            toast.error(errorCodeDic['003']);
            console.log('error: ', error);
        })
            .finally(() => {
                spinner.stop();
            });
    }

    function pick_site(siteId: any){
        /* nhk temp */
        // if(![449,437,131].includes(siteId)){
        //     return;
        // }

        console.log('pick siteId: ', siteId);
        if(!window.confirm('선택한 현장으로 이동 하시겠습니까?')){
            return;
        }

        fn_moveToSiteWeb(siteId);


    }
    function load_site(){
        restapi.retrieveSiteDataListForMap({isSmSite: false, yyyymmdd: CmmnFn.fn_getCurrentDate().replace(/-/gi, ""), siteType: props.siteType}).then((rsltData: any) => {
            if(rsltData.length > 0){

                restapi.retrieveSmSiteInfoData().then((smSiteInfo: any) => {
                    let param: any = {};
                    const siteId = smSiteInfo.siteId;
                    setSmSiteId(siteId);
                    param.prcsStatCd = 'S001004';
                    param.siteId = siteId;
                    param.wrkDt = CmmnFn.fn_getCurrentDate();

                    restapi.retrieveWrkPrmtDataTotList(param).then((smRsltData: any) => {

                        smRsltData.map((data: any, idx: number) => {
                            data.siteType = 'T002002'
                            data.isShowOnMap = true

                        })

                        rsltData = rsltData.concat(smRsltData);
                        setActiveButtonIdx(-1)
                        var features = []
                        var lst = []
                        setSiteDataAll(rsltData)
                        for(var i in rsltData){
                            if(rsltData[i].isShowOnMap === true){
                                if(rsltData[i].latitude && rsltData[i].longitude){
                                    var item = rsltData[i]
                                    lst.push(item)
                                    var center = transform([item.longitude, item.latitude], 'EPSG:4326', 'EPSG:3857')
                                    var feature1 = new Feature({
                                        geometry: new Point(center),
                                    })
                                    feature1.set("LABEL", item.siteName)
                                    feature1.set("TYPE_CODE", item.siteType)
                                    feature1.set("SITE_ID", item.siteId)
                                    features.push(feature1)
                                }
                            }
                        }

                        setSiteData(lst)
                        setSiteDataFilter(lst)

                    })
                })





            }
        })
    }
    useEffect(() => {
        if(siteDataFilter.length > -1){
            if(siteLayer){
                siteLayer.getSource().clear()
                var features = []
                for(var i in siteDataFilter){
                    var item : any = siteDataFilter[i]
                    var center = transform([item.longitude, item.latitude], 'EPSG:4326', 'EPSG:3857')
                    var feature1 = new Feature({
                        geometry: new Point(center),
                    })
                    feature1.set("LABEL", item.siteName)
                    feature1.set("TYPE_CODE", item.siteType)
                    feature1.set("SITE_ID", item.siteId)
                    feature1.set("SOS_ID", item.sosId)
                    feature1.set("WRK_DISRUPT_ID", item.wrkDisruptId)
                    features.push(feature1)
                }
                siteLayer.getSource().addFeatures(features)
            }
        }
    },[siteDataFilter])
    useEffect(() => {

        const siteLayer = new VectorLayer({
            source: new VectorSource({wrapX: false}),
            style: function(f){
                //console.log(f)
                var text = f.get("LABEL")
                var typeCode = f.get("TYPE_CODE")
                if(text?.length > 20){
                    //label = label.substring(0,9) + "\n" + label.substring(10,100)
                }
                var label
                var icon = Marker04
                var color = '00000055'
                if(typeCode === 'T002001'){
                    label = '일반공사'
                    color = '#6269FF'
                    icon = Marker00
                }else if(typeCode === 'T002002'){
                    label = '유지보수'
                    color = '#5F8BFB'
                    icon = Marker01
                }else if(typeCode === 'T002003'){
                    label = '출자토지'
                    color = '#FF8384'
                    icon = Marker02
                }else if(typeCode === 'T002004'){
                    label = '관리시설'
                    color = '#F883FF'
                    icon = Marker03
                }else if(typeCode === 'T002005'){
                    label = '기타'
                    color = '#8CF88E'
                    icon = Marker04
                }
                return [new Style({
                    image: new Icon({
                        anchor: [0.5, 120],
                        anchorXUnits: 'fraction',
                        anchorYUnits: 'pixels',
                        src: icon,
                        width: 39,
                        height: 45,
                    }),
                    text: new Text({
                        text: text,
                        overflow : true,
                        offsetY: 14,
                        fill: new Fill({color: color}),
                        stroke: new Stroke({
                            color: "#fff",
                            width: 5
                        }),
                        font: "bold 14px Arial "
                    }),
                })]
            },
            declutter:true
        });
        siteLayer.setVisible(true)
        //
        var NAVER_AIR_LAYER = new TileLayer({visible : true, opacity: 1})
        NAVER_AIR_LAYER.setSource(new XYZ({
            projection: new Projection({code: 'EPSG:5179',extent: [90112, 1192896, 2187264, 2765760],units: 'm'}),
            cacheSize : 256,
            minZoom: 0,
            maxZoom: [2048, 1024, 512, 256, 128, 64, 32, 16, 8, 4, 2, 1, 0.5, 0.25].length - 1,
            tileGrid: new TileGrid({
                origin: [90112, 1192896],
                resolutions: [2048, 1024, 512, 256, 128, 64, 32, 16, 8, 4, 2, 1, 0.5, 0.25]
            }),
            tileUrlFunction : function(tileCoord, pixelRatio, projection) {
                if (tileCoord == null) return undefined;
                var s = Math.floor(Math.random() * 3) + 1;  // 1 ~ 4
                var z = tileCoord[0] + 1;
                var x = tileCoord[1];
                var y = -tileCoord[2] - 1;
                //
                var  path = '203/0/1'
                var path2 = 'bl_st_bg'
                var url = 'https://simg.pstatic.net/onetile/get/' + path + '/' + z + '/' + x + '/' + y + '/' + path2 + ''
                return url
            },
            crossOrigin: null
        }))
        var NAVER_LABEL_LAYER = new TileLayer({visible : true, opacity: 1})
        NAVER_LABEL_LAYER.setSource(new XYZ({
            projection: new Projection({code: 'EPSG:5179',extent: [90112, 1192896, 2187264, 2765760],units: 'm'}),
            cacheSize : 256,
            minZoom: 0,
            maxZoom: [2048, 1024, 512, 256, 128, 64, 32, 16, 8, 4, 2, 1, 0.5, 0.25].length - 1,
            tileGrid: new TileGrid({
                origin: [90112, 1192896],
                resolutions: [2048, 1024, 512, 256, 128, 64, 32, 16, 8, 4, 2, 1, 0.5, 0.25]
            }),
            tileUrlFunction : function(tileCoord, pixelRatio, projection) {
                if (tileCoord == null) return undefined;
                var s = Math.floor(Math.random() * 3) + 1;  // 1 ~ 4
                var z = tileCoord[0] + 1;
                var x = tileCoord[1];
                var y = -tileCoord[2] - 1;
                //
                var  path = '203/0/0'
                var path2 = 'bl_vc_bg/ol_vc_an'
                var url = 'https://simg.pstatic.net/onetile/get/' + path + '/' + z + '/' + x + '/' + y + '/' + path2 + ''
                return url
            },
            crossOrigin: null
        }))
        var BASE1_LAYER = new TileLayer({visible : true, opacity: 0.9})
        BASE1_LAYER.setSource(new XYZ({
            projection: "EPSG:900913",
            tileUrlFunction : function(tileCoord, pixelRatio, projection){
                var z = tileCoord[0]
                var x = tileCoord[1]
                var y = tileCoord[2]
                //
                var type = "2D영상"
                var path = null
                var ext = 'png'
                if(type == '2D지도'){
                    path = '2d/Base/service'
                }else if(type == '2D회색'){
                    path = '2d/gray/service'
                }else if(type == '2D야간'){
                    path = '2d/midnight/service'
                }else if(type == '2D하이브리드'){
                    path = '2d/Hybrid/service'
                }else if(type == '2D영상'){
                    path = '2d/Satellite/service'
                    ext = 'jpeg'
                }
                return 'https://xdworld.vworld.kr/' + path + '/' + z + '/' + x + '/' + y + '.' + ext
            },
            crossOrigin: "Anonymous"
        }))
        var BASE2_LAYER = new TileLayer({visible : true, opacity: 0.8})
        //this.Daum_Resolutions = [2048, 1024, 512, 256, 128, 64, 32, 16, 8, 4, 2, 1, 0.5, 0.25]
        //this.Daum_Extent = [-30000, -60000, 494288, 988576]
        BASE2_LAYER.setSource(new XYZ({
            projection: new Projection({code: 'EPSG:5181',extent: [-30000, -60000, 494288, 988576],units: 'm'}),
            cacheSize : 256,
            minZoom: 0,
            maxZoom: [2048, 1024, 512, 256, 128, 64, 32, 16, 8, 4, 2, 1, 0.5, 0.25].length - 1,
            tileGrid: new TileGrid({
                origin: [-30000, -60000],
                resolutions: [2048, 1024, 512, 256, 128, 64, 32, 16, 8, 4, 2, 1, 0.5, 0.25]
            }),
            tileUrlFunction : function(tileCoord, pixelRatio, projection) {
                if (tileCoord == null) return undefined;
                var s = Math.floor(Math.random() * 4);  // 0 ~ 3
                var z = [2048, 1024, 512, 256, 128, 64, 32, 16, 8, 4, 2, 1, 0.5, 0.25].length - tileCoord[0]
                var x = tileCoord[1];
                var y = -tileCoord[2] - 1;
                //
                var  path = 'map_hybrid/1906plw'
                var ext = 'png'
                var url = 'http://map' + s + '.daumcdn.net/' + path + '/L' + z + '/' + y + '/' + x + '.'+ ext
                return url
            },
            crossOrigin: null
        }))
        var DAUM_LAYER = new TileLayer({visible : true, opacity: 1})
        DAUM_LAYER.setSource(new XYZ({
            projection: new Projection({code: 'EPSG:5181',extent: [-30000, -60000, 494288, 988576],units: 'm'}),
            cacheSize : 256,
            minZoom: 0,
            maxZoom: [2048, 1024, 512, 256, 128, 64, 32, 16, 8, 4, 2, 1, 0.5, 0.25].length - 1,
            tileGrid: new TileGrid({
                origin: [-30000, -60000],
                resolutions: [2048, 1024, 512, 256, 128, 64, 32, 16, 8, 4, 2, 1, 0.5, 0.25]
            }),
            tileUrlFunction : function(tileCoord, pixelRatio, projection) {
                if (tileCoord == null) return undefined;
                var s = Math.floor(Math.random() * 4);  // 0 ~ 3
                var z = [2048, 1024, 512, 256, 128, 64, 32, 16, 8, 4, 2, 1, 0.5, 0.25].length - tileCoord[0]
                var x = tileCoord[1];
                var y = -tileCoord[2] - 1;
                //
                var path = 'map_2d/1906plw'
                var ext = 'png'
                //https://map.daumcdn.net/map_k3f_prod/bakery/image_map_png/PNG01/v22_galhg/3/1537/1010.png
                var url = 'http://map' + s + '.daumcdn.net/' + path + '/L' + z + '/' + y + '/' + x + '.'+ ext
                return url
            },
            crossOrigin: null
        }))
        var boundryLayer = new VectorLayer({
            source: new VectorSource({
                url: '/geojson/busan_boundry.geojson',
                format: new GeoJSON(),
                wrapX: false
            }),
            style: function(feature, resolution) {
                return [new Style({
                    stroke : new Stroke({color : 'rgba(255,0,0,1)',width : 4})
                })]
            },
            opacity: 0.6
        });
        var busanLayer = new VectorLayer({
            source: new VectorSource({
                url: '/geojson/busan.geojson',
                format: new GeoJSON(),
                wrapX: false
            }),
            style: function(feature, resolution) {
                var label = feature.get("SIG_KOR_NM");
                return [new Style({
                    stroke : new Stroke({color : '#00000022',width : 3}),
                    text :  new Text({
                        text: label,
                        fill: new Fill({color: '#000000'}),
                        stroke: new Stroke({
                            color: 'rgba(255,255,255,1)',
                            width: 4
                        }),
                        font: "bold 14px Arial"
                    })
                })]
            },
            opacity: 0.8
        });
        proj4.defs('EPSG:5179', '+title=Korea 2000 / Unified CS +proj=tmerc +lat_0=38 +lon_0=127.5 +k=0.9996 +x_0=1000000 +y_0=2000000 +ellps=GRS80 +units=m +no_defs')
        proj4.defs('EPSG:5181', '+proj=tmerc +lat_0=38 +lon_0=127 +k=1 +x_0=200000 +y_0=500000 +ellps=GRS80 +units=m +no_defs')
        register(proj4)
        get('EPSG:5179')?.setExtent([90112, 1192896, 1990673, 2761664]);
        get('EPSG:5181')?.setExtent([-30000, -60000, 494288, 988576]);
        // @ts-ignore
        window.___dangerLayer = new VectorLayer({
            source: new VectorSource({wrapX: false}),
            style: function(){
                // @ts-ignore
                if(window.gifCtx){
                    return new Style({
                        image: new Icon({
                            // @ts-ignore
                            img: window.gifCtx.canvas,
                            // @ts-ignore
                            imgSize: [window.gifFrame.width, window.gifFrame.height],
                            anchor: [0.5, 1.2],
                            offset: [0,0],
                        })
                    })
                }
            }
        });
        // @ts-ignore
        window.___overlay = new Overlay({
            // @ts-ignore
            element: overlayRef.current,
            autoPan: false,
            positioning: "bottom-left",
            offset: [5,-10],
            autoPanAnimation: {
                duration: 250
            }
        })
        // @ts-ignore
        const map = new Map({
            // @ts-ignore
            target: mapRef.current,
            layers: [
                // @ts-ignore
                DAUM_LAYER, boundryLayer, busanLayer, siteLayer, window.___dangerLayer
            ],
            // @ts-ignore
            overlays: [window.___overlay],
            view: new View({
                center: [0,0],
                maxZoom : 19.4,
                minZoom : 7
            }),
        });
        map.once('postrender', function(e) {
            var map = e.map
            // @ts-ignore
            window.___dashboard_map = map
            var extent3857
            // @ts-ignore
            if(window.___last_map_extent){
                // @ts-ignore
                extent3857 = window.___last_map_extent
            }else{
                var extent = [128.8, 35.02, 129.3, 35.36]
                extent3857 = transformExtent(extent, 'EPSG:4326', 'EPSG:3857')
            }
            // @ts-ignore
            map.getView().fit(extent3857 , map.getSize())
            setTimeout(function(){
                map.updateSize()
            },200)
            setSiteLayer(siteLayer)
            load_site()
        })
        map.on('singleclick', function(e) {
            var map = e.map
            var finds: any = []
            map.forEachFeatureAtPixel(e.pixel,function(feature) {
                finds.push(feature)
            },{hitTolerance: 2, layerFilter: function(layer) {
                    // @ts-ignore
                    return layer === siteLayer || layer === window.___dangerLayer;
                }})
            if(finds.length > 0) {
                var feature = finds[0]
                var siteId: string = feature.get("SITE_ID")
                var featureType: string = feature.get("FEATURE_TYPE")
                if(featureType === 'DANGER'){
                    if(window.confirm('선택한 경고 현장으로 이동 하시겠습니까?')){
                        fn_moveToSiteWeb(siteId);
                    }
                }else{
                    pick_site(siteId)
                }
            }
        })
        map.on('pointermove', function(e) {
            var map = e.map
            var finds: any = []
            map.forEachFeatureAtPixel(e.pixel,function(feature) {
                finds.push(feature)
            },{hitTolerance: 2, layerFilter: function(layer) {
                    // @ts-ignore
                    return layer === window.___dangerLayer;
                }})
            if(finds.length > 0) {
                var feature = finds[0]
                var siteName: string = feature.get("SITE_NAME")
                var point = feature.getGeometry().getCoordinates()
                // @ts-ignore
                $(window.___overlay.element).find("#bmc-ol-modal-layer").html("<div class='layer-wrap'>" + siteName + "</div>")// @ts-ignore
                window.___overlay.setPosition(point)
            }else{
                // @ts-ignore
                $(window.___overlay.element).find("#bmc-ol-modal-layer").empty();
            }
        })
        map.on("moveend", function(e){
            var extent = e.map.getView().getViewStateAndExtent().extent
            console.log(extent)
            // @ts-ignore
            window.___last_map_extent = extent
        });
        return () => {
            // @ts-ignore
            if(mapRef.current){
                mapRef.current.innerHTML = ''
            }
            // @ts-ignore
            if(overlayRef.current){
                overlayRef.current.innerHTML = ''
            }
        }
    }, [mapRef.current]);

    const fn_openSiteInfoList = (idx: number) => {
        var typeCode = ''
        if(idx === 0){
            typeCode = 'T002001'
        }else if(idx === 1){
            typeCode = 'T002002'
        }else if(idx === 2){
            typeCode = 'T002003'
        }else if(idx === 3){
            typeCode = 'T002004'
        }else if(idx === 4){
            typeCode = 'T002005'
        }
        setSiteType(typeCode);
        var filter = []
        for(var i in siteDataAll){
            var item: any = siteDataAll[i]
            if(typeCode){
                if(typeCode === item.siteType){
                    filter.push(item)
                }
            }else{
                filter.push(item)
            }
        }

        setSiteDataFilter(filter)
        if(filter.length > 0){
            setDataList(filter);
            setModalIsOpen(true);
        }

    }
    function fn_select(idx: number){
        setActiveButtonIdx(idx)
        var typeCode = '';
        if(idx === 0){
            typeCode = 'T002001'
        }else if(idx === 1){
            typeCode = 'T002002'
        }else if(idx === 2){
            typeCode = 'T002003'
        }else if(idx === 3){
            typeCode = 'T002004'
        }else if(idx === 4){
            typeCode = 'T002005'
        }else{
            typeCode = ''
        }
        setSiteType(typeCode);
        var filter = []
        for(var i in siteData){
            var item: any = siteData[i]
            if(typeCode){
                if(typeCode === item.siteType){
                    filter.push(item)
                }
            }else{
                filter.push(item)
            }
        }
        setSiteDataFilter(filter)
    }

    return (
        <div className="rounded-3 border-1 w-100 overflow-hidden position-relative">
            <SmSiteWrkListModal setModalIsOpen={setIsSmWrkModalOpen} modalIsOpen={isSmWrkModalOpen} dataList={smWrkDataList} siteId={smSiteId} />
            <SiteInfoListModal setModalIsOpen={setModalIsOpen} modalIsOpen={modalIsOpen} dataList={dataList} siteType={siteType}></SiteInfoListModal>
            {!props.siteType &&
            <div className="d-flex position-absolute z-[1000]" style={{left: "10px", top:"10px"}}>
                <div className="d-flex flex-column gap-2">
                    <div className="in-map-label-v2 text-nowrap p-2 gap-2" onClick={() => fn_select(-1)}>
                        <span>전체</span>&nbsp;<span className={'cursor-pointer small'} onClick={() => fn_openSiteInfoList(-1)}>({total ? total : '-'}개 현장)</span>
                    </div>
                    <div className="in-map-label-v2 d-flex flex-row text-nowrap p-2 gap-2 align-items-center" style={{borderWidth:"4px", borderColor: activeButtonIdx == 0 ? '#00ccff' : '#ffffff'}}>
                        <IconMapLabelConstruct width={30} height={30} onClick={() => fn_select(0)}/>
                        <span className="w-[60px]" onClick={() => fn_select(0)}>일반공사</span>
                        <span>|</span>
                        <span className={'cursor-pointer'} onClick={() => fn_openSiteInfoList(0)}>{countT002001}</span>
                    </div>
                    <div className="in-map-label-v2 d-flex flex-row text-nowrap p-2 gap-2 align-items-center" style={{borderWidth:"4px", borderColor: activeButtonIdx == 1 ? '#00ccff' : '#ffffff'}}>
                        <IconMapLabelRepair width={30} height={30} onClick={() => fn_select(1)}/>
                        <span className="w-[60px]" onClick={() => fn_select(1)}>유지보수</span>
                        <span>|</span>
                        <span className={'cursor-pointer'} onClick={() => setIsSmWrkModalOpen(true)}>{countT002002}</span>
                    </div>
                    <div className="in-map-label-v2 d-flex flex-row text-nowrap p-2 gap-2 align-items-center" style={{borderWidth:"4px", borderColor: activeButtonIdx == 2 ? '#00ccff' : '#ffffff'}}>
                        <IconMapLabelLand width={30} height={30} onClick={() => fn_select(2)}/>
                        <span className="w-[60px]" onClick={() => fn_select(2)}>출자토지</span>
                        <span>|</span>
                        <span className={'cursor-pointer'} onClick={() => fn_openSiteInfoList(2)}>{countT002003}</span>
                    </div>
                    <div className="in-map-label-v2 d-flex flex-row text-nowrap p-2 gap-2 align-items-center" style={{borderWidth:"4px", borderColor: activeButtonIdx == 3 ? '#00ccff' : '#ffffff'}}>
                        <IconMapLabelFacility width={30} height={30} onClick={() => fn_select(3)}/>
                        <span className="w-[60px]" onClick={() => fn_select(3)}>관리시설</span>
                        <span>|</span>
                        <span className={'cursor-pointer'} onClick={() => fn_openSiteInfoList(3)}>{countT002004}</span>
                    </div>
                    <div className="in-map-label-v2 d-flex flex-row text-nowrap p-2 gap-2 align-items-center" style={{borderWidth:"4px", borderColor: activeButtonIdx == 4 ? '#00ccff' : '#ffffff'}}>
                        <IconMapLabelEtc width={30} height={30} onClick={() => fn_select(4)}/>
                        <span className="w-[60px]" onClick={() => fn_select(4)}>기타</span>
                        <span>|</span>
                        <span className={'cursor-pointer'} onClick={() => fn_openSiteInfoList(4)}>{countT002005}</span>
                    </div>
                </div>
            </div>
            }
            <div id={"map"} ref={mapRef} style={{width: '100%', minWidth:"530px", height: props.height ? props.height : 'calc(100vh - 227px)', backgroundColor:'rgb(14, 24, 65)'}}></div>
            <div id="bmc-ol-modal-layer" ref={overlayRef}>
                <div className="layer-wrap" style={{zIndex:"999999"}}></div>
            </div>
        </div>
    );
}
