import React, {Dispatch, SetStateAction, useContext, useEffect, useRef, useState} from "react";
import {ProgressContext} from "../../../context/Progress";
import {getLoginUser} from "../../../helper/SecurityHelper";
import {Col, Modal, Row, Table} from "react-bootstrap";
import {IoMdClose} from "react-icons/io";
import BMCInput from "../../../components/common/BMCInput";
import {toast} from "react-toastify";
import axios from "axios";
import messageCodeDic from "../../../util/messageCodeDic";
import errorCodeDic from "../../../util/errorCodeDic";
import IvstSiteSearchModal from "./IvstSiteSearchModal";

import BMCDatePicker2 from "../../../components/common/BMCDatePicker2";

import IvstSftyChkRgstrSrchModal from "./IvstSftyChkRgstrSrchModal";
import BMCIconButton from "../../../components/common/BMCIconButton";

import BMCFile from "../../../components/common/BMCFile";
import BMCPicModal from "../../../components/common/BMCPicModal";
import iconTrashBean from "../../../images/icon_remove.png";
import {DateHelper} from "../../../helper/DateHelper";

interface IProps {
    setModalIsOpen: Dispatch<SetStateAction<boolean>>;
    modalIsOpen: boolean;
}

export default function IvstSftyChkRsltRgstModal(props:IProps){

    const [data, setData] = useState<any>();
    const formRef = React.createRef<HTMLFormElement>();
    const {spinner} = useContext(ProgressContext);
    const user: any = getLoginUser();
    const [isIvstSitePopOpen, setIsIvstSitePopOpen] = useState<any>(false);
    const [blngSite, setBlngSite] = useState<any>()
    const [sftyChkDt, setSftyChkDt] = useState<Date|null>();
    const [sftyChkRsltRgstDt, setSftyChkRsltRgstDt] = useState<Date|null>(DateHelper.dateByMonth(0));
    const [hourList, setHourList] = useState<any>([]);
    const [minList, setMinList] = useState<any>([]);
    const [chkRgstrModalIsOpen, setChkRgstrModalIsOpen] = React.useState(false);
    const [chkRgstrData, setChkRgstrData] = useState<any>(null);

    const [siteSftyMeasuresDataList, setSiteSftyMeasuresDataList] = useState<any>([]);
    const [imgTarget, setImgTarget] = useState<any>(null);
    const fileRef = useRef() as React.MutableRefObject<any>;
    const [chkFileDataList, setChkFileDataList] = useState<any>([]);
    const [actFileDataList, setActFileDataList] = useState<any>([]);
    const [photoData, setPhotoData] = useState<any>({});
    const [isPhotoModalOpen, setIsPhotoModalOpen] = useState<boolean>(false);
    const [picModalTitle, setPicModalTitle] = useState<string>('');
    const [chkRsltDataList, setChkRsltDataList] = useState<any>([]);
    const [chkRsltDataIdx, setChkRsltDataIdx] = useState<number>(0);
    const [fileList1, setFileList1] = useState<any>([]);
    const [fileList2, setFileList2] = useState<any>([]);
    const [fileList3, setFileList3] = useState<any>([]);
    const [fileList4, setFileList4] = useState<any>([]);
    const [fileList5, setFileList5] = useState<any>([]);


    useEffect(()=>{
        if(blngSite){
            let tempArr = [...chkRsltDataList];
            tempArr[chkRsltDataIdx]['siteId'] = blngSite.siteId;
            tempArr[chkRsltDataIdx]['siteName'] = blngSite.dongNm.substring(blngSite.dongNm.indexOf(' ') + 1) +
                ' ' +
                blngSite.jibun;
            setChkRsltDataList(tempArr);

            setData(Object.assign({}, data, blngSite));
        }
    },[blngSite]);




    useEffect(()=>{
        let tempData = {};
        if(chkRgstrData){
            tempData = {
                sftyChkRsltRgstrNm : chkRgstrData?.userName,
                sftyChkRsltRgstrSeq : chkRgstrData?.userSeq
            }
        }
        setData(Object.assign({}, data, tempData))
    }, [chkRgstrData])

    const fn_updateData = (e: any) => {
        if (e.target.type == 'checkbox') {
            setData({
                ...data,
                [e.target.name]: e.target.checked
            })
            console.log(e.target.checked)
        } else {
            setData({
                ...data,
                [e.target.name]: e.target.value
            })
        }
    }

    const fn_openModal = () => {
        setSftyChkDt(DateHelper.dateByMonth(0))
        setFileList1([]);
        setFileList2([]);
        setFileList3([]);
        setFileList4([]);
        setFileList5([]);
        setChkRsltDataList([]);

    }

    const fn_closeModal = () => {
        props.setModalIsOpen(false);
    }

    const submit = (e: any) => {
        e.preventDefault()

    }

    const fn_registData = () => {


        if (!sftyChkRsltRgstDt) {
            toast.warn('점검일자를 등록해 주십시오.');
            return;
        }

        if(chkRsltDataList.length == 0){
            toast.warn('점검내용을 등록해 주십시오.');
            return;
        }

        let isValid = true;

        chkRsltDataList.map((data: any, idx: number) => {
            if(!data.siteId){
                toast.warn((idx + 1) + ' 번째 필지를 등록해 주십시오.')
                isValid = false;
            }

            if(!data.sftyChkComments){
                toast.warn((idx + 1) + ' 번째 점검내용을 등록해 주십시오.')
                isValid = false;
            }

            if(idx == 0 && fileList1.length == 0){
                toast.warn( '1 번째 점검사진을 등록해 주십시오.');
                isValid = false;
            }
            if(idx == 1 && fileList1.length == 1){
                toast.warn( '2 번째 점검사진을 등록해 주십시오.');
                isValid = false;
            }
            if(idx == 2 && fileList1.length == 2){
                toast.warn( '3 번째 점검사진을 등록해 주십시오.');
                isValid = false;
            }
            if(idx == 3 && fileList1.length == 3){
                toast.warn( '4 번째 점검사진을 등록해 주십시오.');
                isValid = false;
            }
            if(idx == 4 && fileList1.length == 4){
                toast.warn( '5 번째 점검사진을 등록해 주십시오.');
                isValid = false;
            }

        });

        if(!isValid){
            return;
        }


        let param: any = {sftyChkRsltRgstDt:sftyChkRsltRgstDt};
        param.rgstrSeq = user.userSeq;
        param.updtrSeq = user.userSeq;
        param.sftyChkRsltRgstDt = sftyChkRsltRgstDt;
        param.workTypeCd = 'T002003';
        let today = new Date();
        let hours: any = today.getHours();
        let minutes: any = today.getMinutes();

        hours = (hours + '').length == 1 ? '0' + hours : hours + '';
        minutes = (minutes + '').length == 1 ? '0' + minutes : minutes + '';

        param.sftyChkDt = today;
        param.sftyChkHour = hours;
        param.sftyChkMin = minutes;
        param.siteSftyMeasuresDataList = chkRsltDataList;


        // @ts-ignore
        var formData: any = new FormData(formRef.current);

        if (fileList1.length > 0) {
            for (var i = 0; i < fileList1.length; i++) {
                formData.append('fileList1', fileList1[i]);
            }
        }
        if (fileList2.length > 0) {
            for (var i = 0; i < fileList2.length; i++) {
                formData.append('fileList2', fileList2[i]);
            }
        }
        if (fileList3.length > 0) {
            for (var i = 0; i < fileList3.length; i++) {
                formData.append('fileList3', fileList3[i]);
            }
        }
        if (fileList4.length > 0) {
            for (var i = 0; i < fileList4.length; i++) {
                formData.append('fileList4', fileList4[i]);
            }
        }
        if (fileList5.length > 0) {
            for (var i = 0; i < fileList5.length; i++) {
                formData.append('fileList5', fileList5[i]);
            }
        }

        formData.set('ivstSftyChkData', new Blob([JSON.stringify(param)], {type: "application/json"}))

        if(!window.confirm('저장 하시겠습니까?')){
            return;
        }
        spinner.start();
        axios.post('/admin/api/siteSftyManage/saveIvstSftyChkRsltData', formData).then((result) => {
            if (result.data > 0) {
                toast.success(messageCodeDic['001'])
                props.setModalIsOpen(false);
            } else {
                toast.error(errorCodeDic['003']);
            }
        }).catch(error => {
            toast.error(errorCodeDic['003']);
            console.log('error: ', error);
        })
        .finally(() => {
            spinner.stop();
        });
    }

    const fn_selectSite = ()=>{
        setIsIvstSitePopOpen(true)
    }

    const fn_genHour = () => {
        let hours = [];
        let hr;

        for (var i = 1; i <= 24; i++) {
            if (i < 10) {
                hr = '0' + i;
            } else {
                hr = i;
            }
            hours.push(hr);
        }
        setHourList(hours);
    }


    const fn_onfileChange = (e: any) => {
        console.log('e.target.files: ', e.target.files);
        let cstmSiteSftyMeasuresDataList = siteSftyMeasuresDataList;

        if (e.target.files.length > 0) {
            const file = e.target.files[0];
            const imgSrc = URL.createObjectURL(file);
            if (imgTarget.gb == 'bf') {
                cstmSiteSftyMeasuresDataList[imgTarget.idx].fileTmpSrc = imgSrc;
                cstmSiteSftyMeasuresDataList[imgTarget.idx].bfActFile = file;
                cstmSiteSftyMeasuresDataList[imgTarget.idx].isPhotoUpload = true;
                setSiteSftyMeasuresDataList([].concat(cstmSiteSftyMeasuresDataList));
            } else if (imgTarget.gb == 'af') {
                cstmSiteSftyMeasuresDataList[imgTarget.idx].fileAfTmpSrc = imgSrc;
                cstmSiteSftyMeasuresDataList[imgTarget.idx].afActFile = file;
                cstmSiteSftyMeasuresDataList[imgTarget.idx].isPhotoUpload = true;

                setSiteSftyMeasuresDataList([].concat(cstmSiteSftyMeasuresDataList));

                console.log('cstmSiteSftyMeasuresDataList: ', cstmSiteSftyMeasuresDataList);
            }
        }

    }


    const fn_addChk = () => {

        if(chkRsltDataList.length >= 5){
            toast.warn('최대 5개 까지 등록 가능 합니다.');
            return;
        }
        chkRsltDataList.push({});
        setChkRsltDataList([].concat(chkRsltDataList));
    }

    const fn_updateChkListData = (e:any, idx:number) =>{
        let tempArr = [...chkRsltDataList];
        tempArr[idx][e.target.name] = e.target.value;
        setChkRsltDataList(tempArr);
    }

    const fn_openSiteSrchModal = (idx:number) =>{
        setChkRsltDataIdx(idx);
        setIsIvstSitePopOpen(true);

    }
    const fn_delRow = (idx: number) => {
        let tempArr: any = [].concat(chkRsltDataList);
        tempArr.splice(idx, 1);
        setChkRsltDataList(tempArr);
    }

    return(
        <div>
            <IvstSftyChkRgstrSrchModal setModalIsOpen={setChkRgstrModalIsOpen} modalIsOpen={chkRgstrModalIsOpen} setChkRgstrData={setChkRgstrData}></IvstSftyChkRgstrSrchModal>
            <IvstSiteSearchModal setModalIsOpen={setIsIvstSitePopOpen} modalIsOpen={isIvstSitePopOpen} setBlngSite={setBlngSite}/>
            <BMCPicModal detailData={photoData} setModalIsOpen={setIsPhotoModalOpen} modalIsOpen={isPhotoModalOpen} title={picModalTitle} />
            <Modal
                className="bmc-modal" size="xl"
                show={props.modalIsOpen}
                onShow={fn_openModal}
                onHide={fn_closeModal}
            >

                <Modal.Header>
                    <div className="d-flex justify-content-between align-items-center w-100 bmc-font-SD">
                        점검현장 결과 등록
                        <IoMdClose className='hover:cursor-pointer' onClick={fn_closeModal}/>
                    </div>
                </Modal.Header>
                <Modal.Body className="px-4">
                    <input hidden type={'file'} ref={fileRef} onChange={fn_onfileChange}/>
                    <form name={'detailForm'} ref={formRef} encType="multipart/form-data" method={"post"} onSubmit={submit}>
                        <BMCInput type={'hidden'} value={data?.atchFileId} name={'atchFileId'}></BMCInput>
                        <BMCInput type={'hidden'} value={data?.sftyMngBrdId} name={'sftyMngBrdId'}></BMCInput>
                        <div className="bmc-modal-div">
                            <div className="div-header justify-content-between">
                                <span>기본정보</span>
                            </div>
                            <div className="div-body px-3">
                                <Table className="my-2 align-middle">
                                    <tbody>
                                    <tr className="border-top align-middle">
                                        <th className="bmc-bg-th">점검일자</th>
                                        <td>
                                            <BMCDatePicker2 style={{width: '200px'}}  name={'sftyChkRsltRgstDt'} readOnly={true} defaultValue={sftyChkRsltRgstDt} onChange={fn_updateData} />
                                        </td>

                                    </tr>
                                    </tbody>
                                </Table>
                            </div>
                        </div>

                        <div className="bmc-modal-div">
                            <div className="div-header">
                                <div className='flex justify-content-between align-items-center w-100'>
                                    <span>점검 내용</span>
                                    <BMCIconButton kind='add' onClick={fn_addChk}>추가</BMCIconButton>
                                </div>
                            </div>
                            <div className="div-body px-3 pt-3">


                                {
                                    chkRsltDataList.length > 0 ?
                                    chkRsltDataList.map((data: any, idx: number) => (
                                        <Table className="mb-3 align-middle" key={idx}>
                                            <tbody>
                                            <tr className="border-top align-middle">

                                                <th className="bmc-bg-th">No</th>
                                                <td>
                                                    <BMCInput className={'form-control w-[50px] text-right'} disabled={true} value={idx + 1}></BMCInput>
                                                </td>
                                                <th className="bmc-bg-th">필지</th>
                                                <td>
                                                    <div className="d-inline-flex gap-1">
                                                        <div className="d-flex">
                                                            <BMCInput type={'hidden'} name={'siteId'} value={data.siteId}></BMCInput>
                                                            <BMCInput type={'text'} readOnly={true}
                                                                     name={'siteName'}
                                                                     className={'form-control'}
                                                                     placeholder="필지 선택"
                                                                     value={data.siteName}/>
                                                        </div>
                                                        <div className="d-flex">
                                                            <button className="bmc-btn btn-secondary min-w-[70px]"
                                                                    onClick={() => fn_openSiteSrchModal(idx)} type="button">검색</button>
                                                        </div>
                                                    </div>

                                                </td>
                                                <th className="bmc-bg-th">점검내용</th>
                                                <td>
                                                    <div className="d-flex justify-content-between align-items-center w-100 sh-font-SD">

                                                        <BMCInput type="text" className="form-control" name={'sftyChkComments'} onChange={(e)=>{
                                                            fn_updateChkListData(e,idx);
                                                        }} value={data.sftyChkComments}  disabled={false}/>
                                                        <img
                                                            className={'w-[25px] cursor-pointer inline-block ml-5'}
                                                            src={iconTrashBean} onClick={() => fn_delRow(idx)}/>
                                                    </div>
                                                </td>


                                            </tr>
                                            <tr className="border-top align-middle">
                                                <th className="bmc-bg-th">점검사진</th>
                                                <td colSpan={5}>
                                                    <BMCFile fileName={'chkPhoto_' + idx} fileId={'chkPhoto_' + idx} fileList={idx == 0 ? fileList1 : idx == 1 ? fileList2 : idx == 2 ? fileList3 : idx == 3 ? fileList4 : idx == 4 && fileList5}
                                                             setFileList={idx == 0 ? setFileList1 : idx == 1 ? setFileList2 : idx == 2 ? setFileList3 : idx == 3 ? setFileList4 : idx == 4 && setFileList5} />
                                                </td>
                                            </tr>
                                            </tbody>
                                        </Table>
                                    )) : <span>점검 내용을 등록해 주십시오.</span>

                                }


                            </div>
                        </div>

                    </form>
                </Modal.Body>

                <Modal.Footer className="py-2">
                    <div className='flex justify-center w-100'>
                        <button
                            className='bmc-btn pill l white me-3'
                            onClick={fn_closeModal}>닫기
                        </button>
                        {
                            !data?.actFileId && <button onClick={fn_registData}
                                                        className='bmc-btn pill p'>등록
                            </button>
                        }

                    </div>
                </Modal.Footer>

            </Modal>
        </div>
    )
}
