import React from "react";
import {Col, Container, Row} from "react-bootstrap";
import DashWeather from "../home/DashWeather";
import HOMonthlyManagementCalendar from "./HOMonthlyManagementCalendar";
import HOWeeklySafetyCheck from "./HOWeeklySafetyCheck";
import HOLocalFieldStatus from "./HOLocalFieldStatus";
import HOConstructionSummary from "./HOConstructionSummary";
import HOCRTrafficLightMini from "./HOTrafficLightMini";
import HOThisWeeklySafetyCheck from "./HOTWeeklySafetyCheck";
import HOTotalWeatherInfoV2 from "./HOTotalWeatherInfoV2";
import HOCRTrafficLightMiniV2 from "./HOCRTrafficLightMiniV2";
import HOEtcInfoV2 from "./HOEtcInfoV2";
import SiteRiskStatus from "../home/SiteRiskStatus";

export default function HOTotalSafetyStatus() {
    return(
        <Container fluid className="px-0" style={{backgroundColor:'#0E1841'}}>
            <div className="d-flex w-100 overflow-hidden" style={{height: 'calc(100vh - 100px)'}}>
                <div className="d-flex flex-grow-1 ho-monitoring-main-left-area">
                    <div className="d-flex w-100">
                        <div className="d-flex w-100 gap-3">
                            <div className="left-area-total d-flex flex-column gap-3 " style={{height: 'calc(100vh - 145px)', overflowY:"auto", overflowX:"hidden"}}>
                                <div className="d-flex flex-column gap-3">
                                    <div>
                                        <HOTotalWeatherInfoV2 />
                                    </div>
                                    <div>
                                        <SiteRiskStatus />
                                        {/*<HOCRTrafficLightMiniV2 />*/}
                                    </div>
                                    <div>
                                        <HOEtcInfoV2 />
                                    </div>
                                </div>
                            </div>
                            <div className="flex-grow-1">
                                <HOLocalFieldStatus />
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </Container>
    );
}
