import React, {Dispatch, SetStateAction, useEffect, useRef, useState} from "react";
import {Col, Modal, Row, Table} from "react-bootstrap";
import {IoMdClose} from "react-icons/io";
import Datatables, {getCheckedItems} from "../../component/Datatables";
import {toast} from "react-toastify";
import SHSearch from "../../component/BMCSearch";
import BMCDatePicker from "../../../components/common/BMCDatePicker";
import CustomDatePickerRange from "../../component/ui/DatePickerRange";
import {CmmnFn, fn_getCurrentDate} from "../../../helper/CmmnHelper";
import RskAsmntRgstModal from "../../siteSafetyManagement/modal/RskAsmntRgstModal";
import BMCInput from "../../../components/common/BMCInput";
import BMCSelect from "../../../components/common/BMCSelect";


interface IProps {
    setModalIsOpen: Dispatch<SetStateAction<boolean>>;
    modalIsOpen: boolean;
    siteId:number
    prcsStatGb:string

}

const RskAsmntInfoListModal = (props: IProps) => {
    const [datatable, setDatatable] = useState<any>();
    const searchFormRef = useRef<any>();

    const [data, setData] = useState<any>()
    const [modalIsOpen, setModalIsOpen] = React.useState(false);
    const [detailData, setDetailData] = useState<any>(null);



    function fn_closeModal() {
        props.setModalIsOpen(false);
    }

    const fn_search = (e?: any) => {
        e?.preventDefault()
        datatable?.draw()
    }

    const fn_setFormData = (e: any) => {

        setData({
            ...data,
            [e.target.name]: e.target.value
        })

    }

    const fn_onShow = () => {

        fn_search()
    }
    return (
        <div>
            <RskAsmntRgstModal setModalIsOpen={setModalIsOpen} modalIsOpen={modalIsOpen} siteId={props.siteId}
                               detailData={detailData} isReadOnly={true}/>
            <Modal
                className="bmc-modal" size="lg"
                show={props.modalIsOpen}
                onShow={()=>{fn_onShow()}}
                onHide={fn_closeModal}
            >

                <Modal.Header>
                    <div className="d-flex justify-content-between align-items-center w-100 bmc-font-SD">
                        위험성평가서 목록 조회 [{props.prcsStatGb == 'cmpltd' ? '완료' : props.prcsStatGb == 'ing' ? '진행' : props.prcsStatGb == 'rjct' && '반려'}]
                        <IoMdClose className='hover:cursor-pointer' onClick={fn_closeModal}/>
                    </div>
                </Modal.Header>

                <Modal.Body className="px-4">

                        <SHSearch datatable={datatable} formRef={searchFormRef} btnType={'p'}>
                            <input type={'hidden'} name={'prcsStatGb'} value={props.prcsStatGb}/>
                            <input type={'hidden'} name={'siteId'} value={props.siteId}/>
                            <Row>
                                <Col>
                                    <Table className="bmc-font-SP bmc-fs-b2 my-3">
                                        <tbody>
                                        <tr className="border-top align-middle">
                                            <th className="bmc-bg-th">업체명</th>
                                            <td>
                                                <BMCInput className={'form-control w-100'} name={'companyName'}
                                                         value={data?.companyName} onChange={fn_setFormData}/>
                                            </td>
                                            <th className="bmc-bg-th">공종</th>
                                            <td>
                                                <BMCSelect initOptionGb={'sel'} name={'workTypeCd'} className={"form-select"}
                                                          selParam={{siteId: props.siteId, manageCode: 'M001001'}}
                                                          codeGb={'siteMngCd'}
                                                          onChange={fn_setFormData}
                                                          value={data?.workTypeCd}></BMCSelect>
                                            </td>
                                        </tr>
                                        </tbody>
                                    </Table>
                                </Col>
                            </Row>
                        </SHSearch>

                    <div className={'ge-result'}>
                        <Datatables
                            dataUrl={'/admin/api/rskAsmnt/retrieveRskAsmntDataList'}
                            parameterFormRef={searchFormRef}
                            columns={[
                                {data: "rgstrNm", title: "작성자", textAlign: 'center'},
                                {data: "prcsStatNm", title: "상태", textAlign: 'center', render: (data: any) => {
                                        switch(data) {
                                            case '작성중':
                                                return "<div class='bmc-box-status writing'>작성중</div>";
                                            case '결재요청':
                                                return "<div class='bmc-box-status request'>결재요청</div>";
                                            case '결재중':
                                                return "<div class='bmc-box-status approval'>결재중</div>";
                                            case '결재완료':
                                                return "<div class='bmc-box-status approvaldone'>결재완료</div>";
                                            case '반려':
                                                return "<div class='bmc-box-status refuse'>반려</div>";
                                            default:
                                                return "";
                                        }
                                    }},
                                {data: "cmpnyNm", title: "업체", textAlign: 'center'},
                                {data: "raProd", title: "평가기간", textAlign: 'center'},
                                {data: "rgstDt", title: "작성일자", textAlign: 'center'},
                            ]}
                            checked={true}
                            index={true}

                            onLoad={(dt: any) => {
                                setDatatable(dt)
                            }}
                            onClick={(data) => {
                                setDetailData(data)
                                setModalIsOpen(true);
                            }
                            }
                        />
                    </div>
                </Modal.Body>
                <Modal.Footer className="py-2">
                    <div className='flex justify-center w-100'>

                        <button
                            className='bmc-btn pill l white me-3'
                            onClick={fn_closeModal}>닫기
                        </button>

                    </div>
                </Modal.Footer>


            </Modal>
        </div>
    )
}

export default RskAsmntInfoListModal;
