import React, {useContext, useEffect, useRef, useState} from 'react';
import Datatables, {getCheckedItems} from "../component/Datatables";
import axios from "axios";
import {toast, ToastContainer} from "react-toastify";
import messageCodeDic from "../../util/messageCodeDic";
import errorCodeDic from "../../util/errorCodeDic";
import BMCSelect from "../../components/common/BMCSelect";
import BMCInput from "../../components/common/BMCInput";
import SiteSearchModal from "../companyManagement/modal/SiteSearchModal";
import BMCSearch from '../component/BMCSearch';
import SubMenuTitle from "../component/SubMenuTitle";
import {getLoginUser, IS_SM_SITE} from "../../helper/SecurityHelper";
import {Col, Container, Row, Table} from "react-bootstrap";
import BMCIconButton from "../../components/common/BMCIconButton";
import {CmmnFn} from "../../helper/CmmnHelper";
import {UserContext} from "../../context/User";
import BMCDatePicker2 from "../../components/common/BMCDatePicker2";
import {DateHelper} from "../../helper/DateHelper";
import SvrAcdntPnshmntRgstModal from "./modal/SvrAcdntPnshmntRgstModal";

type Props = {};

const SvrAcdntPnshmntMngPage = (props: Props) => {
    const [modalRgstIsOpen, setModalRgstIsOpen] = React.useState(false);
    const [wrkrModalIsOpen, setWrkrModalIsOpen] = React.useState(false);
    const [detailData, setDetailData] = useState<any>(null);
    const [isSitePopOpend, setIsSitePopOpend] = React.useState<boolean>(false);
    const [blngSiteList, setBlngSiteList] = useState<any[]>([]);
    const prodRef = useRef<any>();
    const [isDatePopupOpen, setIsDatePopupOpen] = useState(false);
    const [startDate, setStartDate] = useState<any>(DateHelper.dateByMonth(-3));
    const [endDate, setEndDate] = useState<any>(DateHelper.dateByMonth(0));
    const [data, setData] = useState<any>({})
    const searchFormRef = useRef<any>();
    const [datatable, setDatatable] = useState<any>();
    const defaultUser = getLoginUser();
    const {user} = useContext<any>(UserContext);
    const [isCodeDataLoaded] = useState<boolean>(false);
    const [isCopy, setIsCopy] = useState<boolean>(false);


    useEffect(() => {

        if (datatable) {
            datatable.draw();
        }

    }, [user, datatable])

    useEffect(() => {
        if (!modalRgstIsOpen && !wrkrModalIsOpen) {
            setIsCopy(false);
            datatable?.draw();
        }

    }, [modalRgstIsOpen, wrkrModalIsOpen])

    useEffect(() => {
        if(isCodeDataLoaded){
            datatable?.draw();
        }

    }, [isCodeDataLoaded])


    const fn_search = () => {
        datatable.draw();
    }

    /**
     * 등록 버튼 클릭
     */
    const fn_registPopOpen = () => {


        setDetailData(null)
        setModalRgstIsOpen(true);
    }

    const fn_delete = () => {

        var checkedData = getCheckedItems(datatable);
        let delList: number[] = [];

        if (checkedData.length == 0) {
            toast.warn(messageCodeDic['005']);
            return;
        }

        checkedData.map((data: any) => {
            delList.push((data.acdntPnshmntSeq));
        })

        console.log('delList: ', delList);

        if (!window.confirm(messageCodeDic['004'])) {
            return;
        }

        axios.post('/admin/api/svrAcdntPnshmnt/deleteSvrAcdntPnshmntData', {delList: delList}).then(rsltData => {
                if (rsltData.data > 0) {
                    toast.success(messageCodeDic['002'])
                    fn_search();
                } else {
                    toast.error(errorCodeDic['003'])
                }
            }
        ).catch(() => {
                toast.error(errorCodeDic['003'])
            }
        )
    }


    const fn_setFormData = (e: any) => {

        setData({
            ...data,
            [e.target.name]: e.target.value
        })

    }

    const fn_onChangeToAllVal = (e: any) => {
        const checked = e.target.checked;

        if (e.target.checked) {
            setStartDate('');
            setEndDate('');
        }else{
            setStartDate(DateHelper.dateByMonth(-3));
            setEndDate(DateHelper.dateByMonth(0));
        }

    }

    return (
        <Container fluid className="bg-white px-0 pb-4 bmc-text-font_primary">
            <SvrAcdntPnshmntRgstModal setModalIsOpen={setModalRgstIsOpen} modalIsOpen={modalRgstIsOpen} detailData={detailData} siteId={user?.blngSiteId || defaultUser.blngSiteId}/>

            <SubMenuTitle title={'중대재해처벌법 관리'} parentMenu={'현장안전관리'}/>
            <div className="p-4 overflow-y-auto" style={{height: 'calc(100vh - 180px)'}}>
            <BMCSearch datatable={datatable} formRef={searchFormRef} btnType={'p'}>
                <Row>
                    <Col>
                        <Table className="bmc-font-SP bmc-fs-b2 my-3">
                            <tbody>
                            <tr className="border-top align-middle border-bottom">
                                <th className="bmc-bg-th">상태</th>
                                <td colSpan={3}>
                                    <BMCInput type={'hidden'} name={'siteId'}
                                              value={user?.blngSiteId || defaultUser.blngSiteId}></BMCInput>
                                    <BMCSelect className={'form-select'} initOptionGb={'all'} name={'prcsStatCd'}
                                               onChange={fn_setFormData} value={data?.prcsStatCd} codeGrpId={'S001'}></BMCSelect>
                                </td>
                                <th className="bmc-bg-th">기간</th>
                                <td colSpan={3}>
                                    <div className="d-flex align-items-center" ref={prodRef}>
                                        <BMCDatePicker2 name={'strtDt'} defaultValue={startDate} onChange={fn_setFormData} />
                                        <span>&nbsp;~&nbsp;</span>
                                        <BMCDatePicker2 name={'endDt'} defaultValue={endDate} onChange={fn_setFormData} />
                                        <div className={'form-check ml-5'}>
                                            <input className="form-check-input bmc-check" id={'reception_toAll'}
                                                   onChange={fn_onChangeToAllVal} type="checkbox"/>
                                            <label className={'form-check-label text-black'}
                                                   htmlFor={'reception_toAll'}>전체기간</label>
                                        </div>
                                    </div>
                                </td>
                                <th className="bmc-bg-th">검색어</th>
                                <td colSpan={3}>
                                    <div className="d-flex align-items-center">

                                        <BMCInput className={'form-control w-100'} name={'srchTxt'}
                                                  onChange={fn_setFormData}/>
                                    </div>
                                </td>
                            </tr>

                            </tbody>
                        </Table>
                    </Col>
                </Row>
            </BMCSearch>

            <div className='w-100 d-flex justify-content-end align-items-center mb-2'>
                <BMCIconButton kind={'submit'} marginRight={'5px'} onClick={fn_registPopOpen}> 등록
                </BMCIconButton>

                <BMCIconButton kind={'delete'} marginRight={'5px'} onClick={fn_delete}> 삭제
                </BMCIconButton>
            </div>
            <div className={'ge-result'}>

                {
                    <Datatables
                        dataUrl={'/admin/api/svrAcdntPnshmnt/retrieveSvrAcdntPnshmntDataList'}
                        parameterFormRef={searchFormRef}
                        columns={

                            [
                                {data: "mngGbNm", title: "구분", textAlign: 'center'},
                                {
                                    data: "prcsStatNm", title: "상태", textAlign: 'center', render: (data: any) => {
                                        switch (data) {
                                            case '작성중':
                                                return "<div class='bmc-box-status writing'>작성중</div>";
                                            case '결재요청':
                                                return "<div class='bmc-box-status request'>결재요청</div>";
                                            case '결재중':
                                                return "<div class='bmc-box-status approval'>결재중</div>";
                                            case '결재완료':
                                                return "<div class='bmc-box-status approvaldone'>결재완료</div>";
                                            case '반려':
                                                return "<div class='bmc-box-status refuse'>반려</div>";
                                            default:
                                                return "";
                                        }
                                    }
                                },
                                {data: "chkDt", title: "점검일자", textAlign: 'center'},
                                {data: "title", title: "제목", textAlign: 'center'},
                                {data: "rgstrNm", title: "작성자", textAlign: 'center'},
                            ]
                        }
                        checked={true}
                        index={true}

                        onLoad={(dt: any) => {
                            setDatatable(dt)
                        }}
                        onClick={(data) => {
                            setDetailData(data)
                            setModalRgstIsOpen(true);
                        }
                        }
                    />}
            </div>
            </div>

        </Container>
    );
};

export default SvrAcdntPnshmntMngPage;
