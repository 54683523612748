import {RouteType} from "./config";
import AppsOutlinedIcon from '@mui/icons-material/AppsOutlined';

import NoticeManagementPageLayout from "../pages/noticeManagement/NoticeManagementPageLayout";
import NoticeRegistrationPage from "../pages/noticeManagement/NoticeRegistrationPage";
import ManPowerManagementPageLayout from "../pages/manpowerManagement/ManPowerManagementPageLayout";
import EmployeeManagementPage from "../pages/manpowerManagement/EmployeeManagementPage";
import ManagerManagementPage from "../pages/manpowerManagement/ManagerManagementPage";
import SiteSafetyManagementPageLayout from "../pages/siteSafetyManagement/SiteSafetyManagementPageLayout";
import RiskAssessmentManagementPage from "../pages/siteSafetyManagement/RiskAssessmentManagementPage";
import WorkPermitManagementPage from "../pages/siteSafetyManagement/WorkPermitManagementPage";
import SmartTBMManagementPage from "../pages/siteSafetyManagement/SmartTBMManagementPage";
import SafetyEducationContentsPage from "../pages/safetyInformationBoard/SafetyEducationContentsPage";
import SiteManagementAdminPage from "../pages/siteManagementAdmin/SiteManagementAdminPage";
import MyPageLayout from "../pages/myPage/MyPageLayout";
import MyInformationManagementPage from "../pages/myPage/MyInformationManagementPage";
import ApprovalStatusManagementPage from "../pages/myPage/ApprovalStatusManagementPage";
import CompanyManagementPage from "../pages/companyManagement/CompanyManagementPage";
import SSMonitoringPage from "../pages/smallsite/SSMonitoringPage";

/* SVG Icon */
import {ReactComponent as IconManpower} from '../assets/images/ico/icon-manpower.svg';
import {ReactComponent as IconSiteSafety} from '../assets/images/ico/icon-sitesafety.svg';
import {ReactComponent as IconSiteManagement} from '../assets/images/ico/icon-sitemanagement.svg';


import { ReactComponent as IconSidebarPie} from '../assets/images/icon/icon-sidebar-pie.svg';
import { ReactComponent as IconSidebarMicrophone} from '../assets/images/icon/icon-sidebar-microphone.svg';
import { ReactComponent as IconSidebarCone} from '../assets/images/icon/icon-sidebar-cone.svg';
import { ReactComponent as IconSidebarFolder} from '../assets/images/icon/icon-sidebar-folder.svg';
import { ReactComponent as IconSidebarUser} from '../assets/images/icon/icon-sidebar-user.svg';



import DngrEvalMngPage from "../pages/dngrEvalMng/DngrEvalMngPage";
import SosRqstHisPage from "../pages/noticeManagement/SosRqstHisPage";
import WrkDisruptRqstHisPage from "../pages/noticeManagement/WrkDisruptRqstHisPage";
import SiteAdminPageLayout from "../pages/siteManagementAdmin/SiteAdminPageLayout";
import React, {useEffect} from "react";
import {useNavigate} from "react-router-dom";
import SiteCmpnyMngPage from "../pages/siteManagementAdmin/SiteCmpnyMngPage";
import MainSiteInfoManagePage from "../pages/siteConstructionManagement/MainSiteInfoManagePage";
import WrkPrmtProdMngPage from "../pages/siteSafetyManagement/WrkPrmtProdMngPage";
import SvrAcdntPnshmntMngPage from "../pages/siteSafetyManagement/SvrAcdntPnshmntMngPage";
import DailySftyRprtPage from "../pages/safetyWorkReportManagement/DailySftyRprtPage";

const ToPage = ({ link }: any) => {
  const navigate = useNavigate();
  useEffect(()=>{
    navigate(link)
  },[])
  return (
      <>...</>
  );
};
const appRoutesSmSite: RouteType[] = [
  {
    index: true,
    element: <ToPage link={"/smSite/monitoring"} />,
    state: "home"
  },
  {
    path: "/smSite/monitoring",
    element: <SSMonitoringPage />,
    /*element: <MonitoringPage />,*/
    state: "smSite.monitoring",
    sidebarProps: {
      displayText: "모니터링",
      icon: <IconSidebarPie />
    }
  },
  {
    path: "noticeManagement",
    element: <NoticeManagementPageLayout />,
    state: "noticeManagement",
    sidebarProps: {
      displayText: "알림/공지사항 관리",
      icon: <IconSidebarMicrophone />
    },
    child: [
      {
        path: "/smSite/noticeManagement/WrkDisruptRqstHis",
        element: <WrkDisruptRqstHisPage />,
        state: "noticeManagement.WrkDisruptRqstHis",
        sidebarProps: {
          displayText: "작업중지요청 관리"
        },
      },
      {
        path: "/smSite/noticeManagement/SosRqstHis",
        element: <SosRqstHisPage />,
        state: "noticeManagement.SosRqstHis",
        sidebarProps: {
          displayText: "SOS응급콜 관리"
        },
      },
      {
        path: "/smSite/noticeManagement/noticeRegistration",
        element: <NoticeRegistrationPage />,
        state: "noticeManagement.noticeRegistration",
        sidebarProps: {
          displayText: "공지사항"
        },
      },

    ]
  },

  {
    path: "siteSafetyManagement",
    element: <SiteSafetyManagementPageLayout />,
    state: "siteSafetyManagement",
    sidebarProps: {
      displayText: "현장안전관리",
      icon: <IconSidebarCone />
    },
    child: [
      {
        path: "/smSite/siteSafetyManagement/SmartTBMManagement",
        element: <SmartTBMManagementPage />,
        state: "siteSafetyManagement.SmartTBMManagement",
        sidebarProps: {
          displayText: "TBM 관리"
        }
      },
      {
        path: "/smSite/siteSafetyManagement/wrkPrmtProdMng",
        element: <WrkPrmtProdMngPage />,
        state: "siteSafetyManagement.wrkPrmtProdMng",
        sidebarProps: {
          displayText: "작업지시서 관리"
        }
      },
      {
        path: "/smSite/siteSafetyManagement/riskAssessmentManagement",
        element: <RiskAssessmentManagementPage />,
        state: "siteSafetyManagement.riskAssessmentManagement",
        sidebarProps: {
          displayText: "위험성평가 관리"
        },
      },
      {
        path: "/smSite/siteSafetyManagement/safetyEducationContents",
        element: <SafetyEducationContentsPage />,
        state: "siteSafetyManagement.safetyEducationContents",
        sidebarProps: {
          displayText: "안전교육컨텐츠"
        },
      },
      {
        path: "/smSite/siteSafetyManagement/svrAcdntPnshmntMng",
        element: <SvrAcdntPnshmntMngPage />,
        state: "siteSafetyManagement.svrAcdntPnshmntMng",
        sidebarProps: {
          displayText: "중대재해처벌법 관리"
        }
      },
      {
        path: "/smSite/siteSafetyManagement/dailySftyRprt",
        element: <DailySftyRprtPage />,
        state: "siteSafetyManagement.dailySftyRprt",
        sidebarProps: {
          displayText: "일일안전일보"
        },
      },
    ]
  },

  {
    path: "siteManagementAdminPage",
    element: <SiteAdminPageLayout />,
    state: "siteManagementAdminPage",
    sidebarProps: {
      displayText: "현장 Admin",
      icon: <IconSidebarCone />
    },
    child: [
      {
        path: "/smSite/siteManagementAdminPage/dngrEvalMng",
        element: <DngrEvalMngPage />,
        state: "siteManagementAdminPage.dngrEvalMng",
        sidebarProps: {
          displayText: "위험성평가설정"
        },
      },
      {
        path: "/smSite/siteManagementAdminPage/siteManagementAdmin",
        element: <SiteManagementAdminPage />,
        state: "siteManagementAdminPage.siteManagementAdmin",
        sidebarProps: {
          displayText: "현장세부정보설정"
        },
      },
      {
        path: "/smSite/siteManagementAdminPage/cmpnyManage",
        element: <SiteCmpnyMngPage />,
        state: "siteManagementAdminPage.cmpnyManage",
        sidebarProps: {
          displayText: "협력업체 관리"
        },
      },
      {
        path: "/smSite/siteManagementAdminPage/mainSiteInfoManagePage",
        element: <MainSiteInfoManagePage />,
        state: "siteManagementAdminPage.mainSiteInfoManagePage",
        sidebarProps: {
          displayText: "공사기본정보관리"
        },
      },
    ]
  },

  {
    path: "manPowerManagement",
    element: <ManPowerManagementPageLayout />,
    state: "manPowerManagement",
    sidebarProps: {
      displayText: "인력 관리",
      icon: <IconSidebarFolder />
    },
    child: [

      {
        path: "/smSite/manPowerManagement/managerManagement",
        element: <ManagerManagementPage />,
        state: "manPowerManagement.managerManagement",
        sidebarProps: {
          displayText: "관리자 관리"
        }
      },
      {
        path: "/smSite/manPowerManagement/employeeManagement",
        element: <EmployeeManagementPage />,
        state: "manPowerManagement.employeeManagement",
        sidebarProps: {
          displayText: "근로자 관리"
        },
      },
    ]
  },

  {
    path: "myPage",
    element: <MyPageLayout />,
    state: "myPage",
    sidebarProps: {
      displayText: "My Page",
      icon: <IconSidebarUser />
    },
    child: [
      {
        path: "/smSite/myPage/myInformationManagement",
        element: <MyInformationManagementPage />,
        state: "myPage.myInformationManagement",
        sidebarProps: {
          displayText: "나의 정보 관리"
        },
      },
      {
        path: "/smSite/myPage/companyManagement",
        element: <CompanyManagementPage />,
        state: "myPage.companyManagement",
        sidebarProps: {
          displayText: "소속 업체 관리"
        },
      },
      {
        path: "/smSite/myPage/approvalStatusManagementPage",
        element: <ApprovalStatusManagementPage />,
        state: "myPage.approvalStatusManagementPage",
        sidebarProps: {
          displayText: "결재 현황 관리"
        },
      },
    ]
  },
];

export default appRoutesSmSite;
