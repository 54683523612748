import React, {useContext, useEffect, useRef, useState} from 'react';
import axios from "axios";
import Datatables, {getCheckedItems} from "../component/Datatables";
import Modal from 'react-modal';
import {getLoginUser} from "../../helper/SecurityHelper";
import {toast} from "react-toastify";
import messageCodeDic from "../../util/messageCodeDic";
import errorCodeDic from "../../util/errorCodeDic";
import BMCSelect from "../../components/common/BMCSelect";
import {ProgressContext} from "../../context/Progress";
import restapi from "../../helper/restAPIHelper";
import SafetyEduRgstModal from "../safetyEducationManagement/modal/SafetyEduRgstModal";
import EqmntRgstModal from "./modal/EqmntRgstModal";
import SHSearch from '../component/BMCSearch';
import {Col, Container, Row, Table } from 'react-bootstrap';
import SubMenuTitle from "../component/SubMenuTitle";
import BMCIconButton from "../../components/common/BMCIconButton";
import {UserContext} from "../../context/User";
import BMCInput from "../../components/common/BMCInput";

type Props = {};

Modal.setAppElement('#root');
const EquipmentManagementBeforeArrivalPage = (props: Props) => {
    const [modalIsOpen, setModalIsOpen] = React.useState(false);
    const [detailData, setDetailData] = useState(null);
    const [isSaveSuccess, setIsSaveSuccess] = useState(false);
    const defaultUser = getLoginUser();
    const srchTxtRef = useRef<any>();
    const [srchData, setSrchData] = useState<any>({})
    const {spinner} = useContext(ProgressContext);
    const [cmpnyDataList, setCmpnyDataList] = useState<any>([]);
    const searchFormRef = useRef<any>();
    const [datatable, setDatatable] = useState<any>();
    const {user} = useContext<any>(UserContext);
    const [isCodeDataLoaded, setIsCodeDataLoaded] = useState<boolean>(false);

    useEffect(()=>{

        if(datatable){
            datatable.draw();
        }

    }, [user, datatable])


    useEffect(() => {
        if (!modalIsOpen) {
            datatable?.draw();
        }
    }, [modalIsOpen])

    useEffect(() => {
        if(isCodeDataLoaded){
            datatable?.draw();
        }

    }, [isCodeDataLoaded])

    const fn_setSearchData = (e: any) => {

        setSrchData({
            ...srchData,
            [e.target.name]: e.target.value
        })

    }

    const fn_search = (e?: any) => {
        if (e) {
            e.preventDefault()
        }

        datatable.draw();
        setIsSaveSuccess(false);
    }

    const fn_delete = () => {
        let checkedData = getCheckedItems(datatable);

        let delList:number[] = [];

        if (checkedData.length == 0) {
            toast.warn(messageCodeDic['005']);
            return;
        }

        checkedData.map((data) => {
            delList.push(data.eqmntId);
        })



        if (!window.confirm(messageCodeDic['004'])) {
            return;
        }

        axios.post('/admin/api/eqmnt/deleteEqmntData', {delList: delList}).then(rsltData => {
                if (rsltData.data > 0) {
                    toast.success(messageCodeDic['002'])
                    fn_search();
                } else {
                    toast.error(errorCodeDic['003'])
                }

            }
        ).catch(() => {
                toast.error(errorCodeDic['003'])
            }
        )
    }

    const fn_registPopOpen = () => {
        setDetailData(null);
        setModalIsOpen(true);
    }



    return (
        <Container fluid className="bg-white px-0 bmc-txt-color-primary">
            <SubMenuTitle title={'반입 전 장비관리'} parentMenu={'현장장비관리'}/>
            <EqmntRgstModal setModalIsOpen={setModalIsOpen} modalIsOpen={modalIsOpen} siteId={user?.blngSiteId || defaultUser.blngSiteId}
                                detailData={detailData}/>

            <div className="p-4 overflow-y-auto" style={{height: 'calc(100vh - 180px)'}}>
                <SHSearch datatable={datatable} formRef={searchFormRef} btnType={'p'}>
                    <Row>
                        <Col>
                            <Table className="bmc-font-SP bmc-fs-b2 my-3">
                                <tbody>
                                <tr className="border-top border-bottom align-middle">
                                    <th className="bmc-bg-th">업체</th>
                                    <td>
                                        <BMCInput type={'hidden'} name={'siteId'} value={user?.blngSiteId || defaultUser.blngSiteId}></BMCInput>
                                        <BMCSelect name={'cmpnySeq'} onChange={fn_setSearchData} value={srchData.cmpnySeq} setIsCodeDataLoaded={setIsCodeDataLoaded} initOptionGb={'all'} codeGb={'siteCmpny'} selParam={{siteId:user?.blngSiteId || defaultUser.blngSiteId}}></BMCSelect>

                                    </td>
                                    <th className="bmc-bg-th">검색어</th>
                                    <td>
                                        <input type={'text'} className='form-control' ref={srchTxtRef} />
                                    </td>
                                </tr>
                                </tbody>
                            </Table>
                        </Col>
                    </Row>
                </SHSearch>




                <div className='w-100 d-flex justify-content-end align-items-center mb-2'>
                    <BMCIconButton kind={'submit'} marginRight={'5px'} onClick={fn_registPopOpen}>등록</BMCIconButton>
                    <BMCIconButton kind={'delete'} marginRight={'0'} onClick={fn_delete}>삭제</BMCIconButton>
                </div>

                <div>
                    <Datatables
                        dataUrl={'/admin/api/eqmnt/retrieveEqmntDataList'}
                        parameterFormRef={searchFormRef}
                        columns={[
                            {data: "cmpnyNm", title: "업체"},
                            {data: "eqmntNm", title: "장비명"},
                            {data: "eqmntRgstNo", title: "장비등록번호"},
                            {data: "driverNm", title: "운전원"},
                            {data: "rgstrNm", title: "작성자"}
                        ]}
                        checked={true}
                        index={true}

                        onLoad={(dt: any) => {
                            setDatatable(dt)
                        }}
                        onClick={(data) => {
                            setDetailData(data)
                            setModalIsOpen(true);
                        }
                        }
                    />
                </div>
            </div>
        </Container>


    );
};

export default EquipmentManagementBeforeArrivalPage;
