import SHSearch from "../../component/BMCSearch";
import {Col, Row, Table} from "react-bootstrap";
import React, {Dispatch, SetStateAction, useEffect, useRef, useState} from "react";
import Datatables from "../../component/Datatables";
import BMCDatePicker from "../../../components/common/BMCDatePicker";
import CustomDatePickerRange from "../../component/ui/DatePickerRange";
import axios from "axios";
import {toast} from "react-toastify";
import errorCodeDic from "../../../util/errorCodeDic";
import BMCDatePicker2 from "../../../components/common/BMCDatePicker2";
import {DateHelper} from "../../../helper/DateHelper";

interface IProps {
   dataList: any;
   setSelData: Dispatch<SetStateAction<any>>;
   srchData: any;
   setSrchData: Dispatch<SetStateAction<boolean>>;
   isDatePopupOpen: boolean;
   setIsDatePopupOpen: Dispatch<SetStateAction<boolean>>;
   onSearch: any;
}

const DailySftyRprtByDatePage = (props: IProps) => {
    const prodRef = useRef<any>();
    const [startDate, setStartDate] = useState<Date>(DateHelper.dateByMonth(-1));
    const [endDate, setEndDate] = useState<Date>(DateHelper.dateByMonth(0));

    const fn_print = (e: any) =>{

        let param ={
            chkDt:e.chkDt,
            chkCnt: e.chkCnt,
            sbmtSiteCnt: e.sbmtSiteCnt,
            title:e.title,
            targetSiteCnt:e.targetSiteCnt
        }
        axios.post('/admin/api/dailySftyRprt/createDailySftyRprtPrint', param).then(rsltData => {
            window.open(process.env.REACT_APP_FILE_URL + "/bmcss-data/fileDownloadByFileName?fileName="+rsltData.data, "_blank")
            //window.open('/sample/dailyRprt.pdf', "_blank")
            }
        ).catch(() => {
                toast.error(errorCodeDic['003'])
            }
        )
    }

    const fn_setSrchData = (e: any) => {
        if(e.target.name == 'isAll'){
            let strtDt: any = DateHelper.dateByMonth(-1);
            let endDt: any = DateHelper.dateByMonth(0);
            console.log('e.target.checked: ', e.target.checked);
            if(e.target.checked){
                strtDt = null;
                endDt = null;
            }

            props.setSrchData({
                ...props.srchData,
                ['strtDt']: strtDt,
                ['endDt']: endDt,
            })
        } else {
            props.setSrchData({
                ...props.srchData,
                [e.target.name]: e.target.value
            })
        }


    }
    return(
        <>
            <SHSearch btnType={'p'} onSearch={props.onSearch}>
                <Row>
                    <Col>
                        <Table className="bmc-font-SP bmc-fs-b2 my-3">
                            <tbody>
                            <tr className="align-middle border-bottom">
                                <th className="bmc-bg-th">기간</th>
                                <td colSpan={2}>
                                    <div className="d-flex flex-row gap-2">
                                        <div className="d-flex align-items-center" ref={prodRef}>
                                            <BMCDatePicker2 name={'strtDt'} readOnly={true} defaultValue={startDate} value={props.srchData.strtDt}  onChange={fn_setSrchData} />
                                            <span>&nbsp;~&nbsp;</span>
                                            <BMCDatePicker2 name={'endDt'} readOnly={true} defaultValue={endDate} value={props.srchData.endDt} onChange={fn_setSrchData} />
                                        </div>

                                    </div>
                                </td>
                            </tr>
                            </tbody>
                        </Table>
                    </Col>
                </Row>
            </SHSearch>

            <div className={'ge-result'}>
                <Datatables
                    datas={props.dataList}
                    scrollY="550px"
                    className="bmc-fs-b4"
                    columns={[
                        {data: "chkDt", title: "점검일자"},
                        {data: "title", title: "내용"},
                        {
                            data: "", title: "특이사항<br/>(재해유무)", textAlign: 'center', render: (data: any, type: any, row: any) => {
                               console.log('row: ', row)
                                let rslt = '없음'
                                if(row.chkCnt === 0){
                                    rslt = '없음'
                                }else{
                                    rslt = '있음 (' + row.chkCnt + ')'
                                }
                                return rslt
                            }
                        },
                        {
                            data: "", title: "제출현황<br/>(제출 사업장/전체)", textAlign: 'center', render: (data: any, type: any, row: any) => {
                                console.log('row: ', row)

                                return row.sbmtSiteCnt + '/' + row.targetSiteCnt;
                            }
                        },
                        {
                            title: "기능",
                            type: "button",
                            className: "text-center",
                            buttons: [{
                                label: "<img style='height: 20px' class='cursor-pointer' src='/icon_print.svg' />",
                                onClick: (v: any) => {
                                    fn_print(v);
                                },
                            }],
                        },
                    ]}
                    index={true}
                    checked={false}
                    onLoad={(dt: any) => {
                        //setDatatable(dt)
                    }}

                    onClick={(data) => {
                        props.setSelData(Object.assign({}, data));
                    }
                    }
                />


            </div>
        </>
        )

}

export default DailySftyRprtByDatePage;
