import {Col, Modal, Row, Table} from "react-bootstrap";
import {IoMdClose} from "react-icons/io";
import React, {Dispatch, SetStateAction, useContext, useEffect, useRef, useState} from "react";
import {fn_getCurrentDate} from "../../../helper/CmmnHelper";
import {getLoginUser} from "../../../helper/SecurityHelper";
import BMCInput from "../../../components/common/BMCInput";
import axios from "axios";
import {toast} from "react-toastify";
import messageCodeDic from "../../../util/messageCodeDic";
import errorCodeDic from "../../../util/errorCodeDic";
import BMCSelect from "../../../components/common/BMCSelect";
import {ChangeMeta, NumberFormatBase} from "react-number-format";
import {Formatter} from "../../../helper/FormatHelper";
import SlctEmployeeSiteAuthModal from "./SlctEmployeeSiteAuthModal";
import iconTrashBean from "../../../images/icon_remove.png";
import restapi from "../../../helper/restAPIHelper";
import BMCDatePicker from "../../../components/common/BMCDatePicker";
import CustomDatePicker from "../../component/ui/DatePicker";
import {AxiosResponse} from "axios/index";
import BMCCheckbox from "../../../components/common/BMCCheckbox";
import {ProgressContext} from "../../../context/Progress";
import BMCFile from "../../../components/common/BMCFile";
import Datatables from "../../component/Datatables";


interface IProps {
    setModalIsOpen: Dispatch<SetStateAction<boolean>>;
    modalIsOpen: boolean;
    detailData: any;
    setIsSaveSuccess: Dispatch<SetStateAction<boolean>>;
    siteId: number;
}

const responseBody = (response: AxiosResponse) => response.data;
const requests = {
    get: (url: string) => axios.get(url).then(responseBody),
    post: (url: string, body: any, option: any) =>
        axios.post(url, body, option).then(responseBody),
    put: (url: string, body: object, option: any) =>
        axios.put(url, body, option).then(responseBody),
    delete: (url: string) => axios.delete(url).then(responseBody),
};

const EmployeeRgstModal = (props: IProps) => {

    const currentDate = fn_getCurrentDate();
    const user = getLoginUser();
    const [isEmployeeSiteAuthPopOpend, setIsEmployeeSiteAuthPopOpend] = React.useState<boolean>(false);
    const [data, setData] = useState<any>()
    const [slctdEmployeeSiteAuthData, setSlctdEmployeeSiteAuthData] = useState<any>();
    const [employeeSiteAuthDataList, setEmployeeSiteAuthDataList] = useState<any>([]);
    const [isDatePopupOpen, setIsDatePopupOpen] = useState(false);
    const [userBirthday, setUserBirthday] = useState<string>('');
    const prodRef = useRef<any>();
    const [isDuplChCked, setIsDuplChCked] = useState<boolean>(false);
    const {spinner, setProcess} = useContext(ProgressContext);
    const fileRef = useRef() as React.MutableRefObject<any>;
    const formRef = React.createRef<HTMLFormElement>();
    const [fileList, setFileList] = useState([]);
    const [eduHistDataList, setEduHistDataList] = useState<any>([]);
    const [hlthFileList, setHlthFileList] = useState([]);

    const fn_setFormData = (e: any) => {


        if (e.target.type == 'checkbox') {
            setData({
                ...data,
                [e.target.name]: e.target.checked
            })
        } else {
            if (e.target.name == 'cnstrPrice') {
                e.target.value = parseInt(e.target.value.replaceAll(',', '')).toLocaleString()
            }
            setData({
                ...data,
                [e.target.name]: e.target.value
            })
        }


    }

    useEffect(() => {
        if (slctdEmployeeSiteAuthData) {
            let isDupl = false;
            console.log("employeeSiteAuthDataList", employeeSiteAuthDataList);
            employeeSiteAuthDataList.map((data: any) => {
                if (data.siteId == slctdEmployeeSiteAuthData.siteId
                    && data.cmpnySeq == slctdEmployeeSiteAuthData.cmpnySeq
                    && data.wrkTpCd == slctdEmployeeSiteAuthData.wrkTpCd
                    && data.ocptCd == slctdEmployeeSiteAuthData.ocptCd) {
                    isDupl = true;
                }
            })

            if (isDupl) {
                toast.warn('중복입력 입니다.');
            } else {
                let cstmEmployeeSiteAuthDataList: any = employeeSiteAuthDataList;
                if (cstmEmployeeSiteAuthDataList.length == 0) {
                    slctdEmployeeSiteAuthData.isRep = true
                }
                console.log('slctdEmployeeSiteAuthData: ', slctdEmployeeSiteAuthData);
                cstmEmployeeSiteAuthDataList.push(slctdEmployeeSiteAuthData);
                setEmployeeSiteAuthDataList(cstmEmployeeSiteAuthDataList);
            }
            setSlctdEmployeeSiteAuthData(null);
        }
    }, [slctdEmployeeSiteAuthData])

    useEffect(() => {
        console.log('data: ', data);
    }, [data])

    const fn_onAfterOpen = () => {

        // 신규
        if (props.detailData == null) {
            setData(null);
            setIsDuplChCked(false);
            setEmployeeSiteAuthDataList([]);
            setUserBirthday('');
            setFileList([]);
            setHlthFileList([]);
            setEduHistDataList([]);
            // 상세
        } else {
            setUserBirthday(props.detailData.userBirthday);
            setData(props.detailData);
            setIsDuplChCked(true);
            callApi.retrieveEmployeeSiteAuthDataList(props.detailData.userSeq).then((rsltData) => {
                setEmployeeSiteAuthDataList(rsltData);
            })
            setSlctdEmployeeSiteAuthData(null)

            if(props.detailData.eduCrtfFileId){
                restapi.retrieveAttachInfoList({attachSeq: props.detailData.eduCrtfFileId}).then((rsltData: any) => {
                    setFileList([].concat(rsltData));
                })
            }else{
                setFileList([]);
            }

            if(props.detailData.hlthFileId){
                restapi.retrieveAttachInfoList({attachSeq: props.detailData.hlthFileId}).then((rsltData: any) => {
                    setHlthFileList([].concat(rsltData));
                })
            }else{
                setHlthFileList([]);
            }

            restapi.retrieveUserEduPtcptHistDataList({userSeq: props.detailData.userSeq}).then((rsltData: any) => {
                setEduHistDataList(rsltData);
            })
        }

    }

    function fn_closeModal() {
        props.setModalIsOpen(false);
    }

    const submit = (e: any) => {
        e.preventDefault()
    }

    const fn_openEmployeeSiteAuthPop = () => {
        /*
        if (!data || !data.cmpnySeq) {
            toast.warn('소속업체를 선택해 주십시오.')
            return;
        }
        */
        setIsEmployeeSiteAuthPopOpend(true);
    }

    /**
     * 현장정보 목록 조회
     * @param userSeq
     */
    const callApi = {
        retrieveEmployeeSiteAuthDataList: (userSeq: number) =>
            requests.post('/admin/api/employee/retrieveEmployeeSiteAuthDataList',
                {userSeq: userSeq},
                null
            ),
    };

    const fn_registData = () => {
        if (!data) {
            toast.warn('데이터를 입력해 주십시오.')
            return;
        }

        if (!data.userId) {
            toast.warn('아이디를 입력해 주십시오.');
            return;
        }

        if (!isDuplChCked) {
            toast.warn('사용자 ID 중복확인을 해 주십시오.');
            return;
        }

        if (!props.detailData) {
            if (!data.userPassword) {
                toast.warn('비밀번호를 입력해 주십시오.')
                return;
            }
            if (!data.userPasswordCnfrm) {
                toast.warn('비밀번호를 확인해 주십시오.')
                return;
            }

            if (data.userPassword != data.userPasswordCnfrm) {
                toast.warn('비밀번호가 일치하지 않습니다.');
                return;
            }
        }

        if (!data.isDriver) {
            data.driverLcncNo = null;
        }

        if (!data.isSgnfcnt) {
            data.sgnfcnt = null;
        }

        if (!data.userName) {
            toast.warn('성명을 입력해 주십시오.')
            return;
        }

        if (!data.userPhone) {
            toast.warn('휴대폰 번호를 입력해 주십시오.');
            return;
        }
        if (employeeSiteAuthDataList.length == 0) {
            toast.warn('현장정보를 입력해 주십시오.');
            return;
        }




        let param = data;
        param.userPhone = param.userPhone.replaceAll('-', '');
        if (param.userPhone.length != 11) {
            toast.warn('휴대폰 번호가 형식에 어긋납니다')
            return;
        }

        if(employeeSiteAuthDataList.filter((data: any) => data.isRep).length == 0){
            toast.warn('대표 현장을 선택해 주십시오.');
            return;
        }


        param.userBirthday = userBirthday;
        param.rgstrSeq = user.userSeq;
        param.updtrSeq = user.userSeq
        param.employeeSiteAuthDataList = employeeSiteAuthDataList;

        // @ts-ignore
        var formData: any = new FormData(formRef.current);
        if(param.photoFile){
            formData.set('photoFile', param.photoFile);
            delete param.photoFile;
        }
        formData.set('emplyRgstInfo', new Blob([JSON.stringify(param)], {type: "application/json"}))

        if(fileList.length > 0){
            for(var i =0;i<fileList.length;i++){
                formData.append('atchFileList',fileList[i]);
            }
        }

        if(hlthFileList.length > 0){
            for(var i = 0; i<hlthFileList.length; i++){
                formData.append('hlthFileList', hlthFileList[i]);
            }
        }





        if (!window.confirm('저장 하시겠습니까?')) {
            return;
        }

        spinner.start();
        axios.post('/admin/api/employee/saveEmployeeData', formData).then((result) => {
                if (result.data > 0) {
                    toast.success(messageCodeDic['001'])
                    props.setModalIsOpen(false);
                    props.setIsSaveSuccess(true)
                    setProcess({time: new Date().getTime()});
                } else {
                    if (result.data == -13) {
                        toast.error('중복되는 핸드폰 번호는 등록 할 수 없습니다.');
                    } else {
                        toast.error(errorCodeDic['003']);
                    }
                    props.setIsSaveSuccess(false);
                }
            }
        ).catch(error => {
            toast.error(errorCodeDic['003']);
            console.log('error: ', error);
        })
            .finally(() => {
                spinner.stop();
            });
    }

    const fn_delRow = (userSeq: number, siteId: number, idx: number) => {

        if (!window.confirm('해당 사용자 권한을 삭제 하시겠습니까?')) {
            return;
        }

        spinner.start();
        restapi.deleteUserSiteAuthData({userSeq: userSeq, siteId: siteId}).then((rsltData) => {
            if (rsltData > 0) {
                toast.success('정상 처리 되었습니다.');
                let cstmEmployeeSiteAuthDataList: any = [].concat(employeeSiteAuthDataList);
                cstmEmployeeSiteAuthDataList.splice(idx, 1);
                setEmployeeSiteAuthDataList(cstmEmployeeSiteAuthDataList);
            } else {
                if (rsltData == -14) {
                    toast.warn('현재 사용중인 사용자의 권한은 삭제 할 수 없습니다.')
                    return;
                } else {
                    toast.error(errorCodeDic['003']);
                }
            }
        }).catch(error => {
            toast.error(errorCodeDic['003']);
            console.log('error: ', error);
        })
            .finally(() => {
                spinner.stop();
            });


    }

    const fn_chkIsRep = (idx: number) => {
        let cstmEmployeeSiteAuthDataList: any = [].concat(employeeSiteAuthDataList);
        cstmEmployeeSiteAuthDataList.map((data: any, dataIdx: number) => {
            if (idx == dataIdx) {
                data.isRep = true
            } else {
                data.isRep = false
            }
        })
        setEmployeeSiteAuthDataList(cstmEmployeeSiteAuthDataList);
    }

    const fn_datePopupOpen = () => {
        setIsDatePopupOpen(!isDatePopupOpen);
    };

    const fn_openAddrPop = () => {
        // @ts-ignore
        new daum.Postcode({
            oncomplete: function (rtrnData: any) {

                setData({
                    ...data,
                    homeAddress: rtrnData.address,
                })
            }
        }).open();
    }

    const fn_chkDupl = () => {

        if (!data || !data.userId) {
            toast.warn('사용자 ID를 입력해 주십시오.');
            return;
        }

        if (data.userId) {
            restapi.retrieveUserInfoDtl({userId: data.userId}).then((rsltData: any) => {
                console.log('rsltData: ', rsltData);
                if (rsltData) {
                    toast.warn('이미 존재하는 사용자 ID 입니다.')
                } else {
                    toast.success('사용 가능한 사용자 ID 입니다.')
                    setIsDuplChCked(true);
                }

            })

        }

    }

    const fn_uploadImg = () => {
        fileRef.current.click();
    }

    const fn_onfileChange = (e: any) => {
        let cstmData = {...data};

        if (e.target.files.length > 0) {
            const file = e.target.files[0];
            const imgSrc = URL.createObjectURL(file);

            cstmData.fileTmpSrc = imgSrc;
            cstmData.photoFile = file;
            cstmData.isPhotoUpload = true;
            setData(Object.assign({}, cstmData));
        }
    }

    const fn_initPassword = () => {
        if(!window.confirm('해당 사용자의 비밀번호를 초기화(\'0000\') 하시겠습니까?')){
            return;
        }

        axios.post('/admin/api/user/updateInitPassword', {userSeq:props.detailData.userSeq}).then((rsltData: any) => {
            if(rsltData.data > 0){
                toast.success(messageCodeDic['006']);
            }else{
                toast.error(errorCodeDic['003']);
            }
        }).catch(error => {
            toast.error(errorCodeDic['003']);
            console.log('error: ', error);
        })
            .finally(() => {
                spinner.stop();
            });
    }

    const fn_updateEmployeeSiteAuthDataList = (e: any, paramIdx: number) => {
        const name = e.target.name;
        employeeSiteAuthDataList.map((data: any, idx: number) => {
            if (idx == paramIdx) {
                data[name] = e.target.value;
            }
        })

        setEmployeeSiteAuthDataList([].concat(employeeSiteAuthDataList));
    }

    return (
        <div>
            <SlctEmployeeSiteAuthModal setModalIsOpen={setIsEmployeeSiteAuthPopOpend}
                                       modalIsOpen={isEmployeeSiteAuthPopOpend}
                                       slctdEmployeeSiteAuthData={slctdEmployeeSiteAuthData}
                                       setSlctdEmployeeSiteAuthData={setSlctdEmployeeSiteAuthData}
                                       blngCmpnySeq={data?.blngCmpnySeq}
                                       userName={data?.userName}
                                       employeeSiteAuthDataList={employeeSiteAuthDataList}
            />
            <Modal
                className="bmc-modal" size="xl"
                show={props.modalIsOpen}
                onShow={fn_onAfterOpen}
                onHide={fn_closeModal}
            >
                <Modal.Header>
                    <div className="d-flex justify-content-between align-items-center w-100 bmc-font-SD">
                        근로자 상세보기
                        <IoMdClose className='hover:cursor-pointer' onClick={fn_closeModal}/>
                    </div>
                </Modal.Header>
                <Modal.Body className="px-4">
                    <input hidden type={'file'} ref={fileRef} onChange={fn_onfileChange}/>
                    <form name={'form'} ref={formRef} encType="multipart/form-data" method={"post"}
                          onSubmit={submit}>
                        <div className="bmc-modal-div">
                            <div className="div-header">
                                <div className='flex justify-content-between w-100 align-items-center'>
                                    <span>근로자 정보</span>
                                    <button className="bmc-btn btn-secondary" type="button"
                                            onClick={fn_initPassword}>비밀번호 초기화
                                    </button>
                                </div>

                            </div>
                            <div className="div-body px-3">
                                <div className="row">
                                    <div className="col-3 pe-0">
                                        {data?.fileTmpSrc ?
                                            <img className={'cursor-pointer max-h-[230px]'}
                                                 onClick={() => fn_uploadImg()}
                                                 src={data?.fileTmpSrc}/>
                                            : data?.prflAtchId ?
                                                <img
                                                    className={'cursor-pointer max-h-[230px]'}
                                                    onClick={() => fn_uploadImg()}
                                                    src={process.env.REACT_APP_FILE_URL + "/bmcss-data/fileDownload?attachSeq=" + data?.prflAtchId + '&sn=0&isPdf=0&time=' + new Date().getTime()}/>
                                                :
                                                <label htmlFor="input-file"
                                                       className={'bmc-btn btn-secondary w-[100px] cursor-pointer text-nowrap'}
                                                       onClick={() => fn_uploadImg()}>
                                                    사진 업로드
                                                </label>
                                        }
                                    </div>
                                    <div className="col-9 pe-0">
                                    <Table className="my-2 align-middle">
                                        <colgroup>
                                            <col width='15%'/>
                                            <col width='35%'/>
                                            <col width='15%'/>
                                            <col width='35%'/>
                                        </colgroup>
                                        <tbody>
                                        <tr className="border-top align-middle">
                                            <th className="bmc-bg-th">사용자 ID</th>
                                            <td>
                                                <div
                                                    className="d-flex align-items-center w-100 justify-content-between">
                                                    <BMCInput type="text" disabled={isDuplChCked}
                                                             className="form-control"
                                                             name={'userId'}
                                                             onChange={fn_setFormData} value={data?.userId}/>

                                                    {!props.detailData &&
                                                        <button className="bmc-btn btn-secondary min-w-[70px] ml-5 text-nowrap"
                                                                type="button" onClick={fn_chkDupl}>중복확인
                                                        </button>
                                                    }
                                                </div>

                                            </td>
                                            <th className="bmc-bg-th">일용직 근로자 여부</th>
                                            <td>
                                                <div
                                                    className="d-flex align-items-center w-100 justify-content-between">
                                                    <BMCCheckbox name={'isDayLbrr'} onChange={fn_setFormData}
                                                                 checked={data?.isDayLbrr} className={'ml-3 w-[110px]'}></BMCCheckbox>
                                                </div>

                                            </td>
                                        </tr>
                                        {!props.detailData &&
                                            <tr className="border-top align-middle">
                                                <th className="bmc-bg-th">비밀번호</th>
                                                <td>
                                                    <BMCInput type="password" className="form-control"
                                                             name={'userPassword'}
                                                             onChange={fn_setFormData} value={data?.userPassword}/>
                                                </td>
                                                <th className="bmc-bg-th">비밀번호 확인</th>
                                                <td>
                                                    <BMCInput type="password" className="form-control"
                                                             name={'userPasswordCnfrm'}
                                                             onChange={fn_setFormData} value={data?.userPasswordCnfrm}/>
                                                </td>
                                            </tr>
                                        }
                                        <tr className="border-top align-middle">
                                            <th className="bmc-bg-th">성명</th>
                                            <td>
                                                <BMCInput type="text" className="form-control" name={'userName'}
                                                         onChange={fn_setFormData} value={data?.userName}/>
                                            </td>
                                            <th className="bmc-bg-th">휴대폰 번호</th>
                                            <td>
                                                {
                                                    //@ts-ignore
                                                    <NumberFormatBase onChange={fn_setFormData} name={'userPhone'}
                                                                      className={'form-control'}
                                                                      value={data?.userPhone || ''}
                                                                      format={Formatter.phoneNumFormat}
                                                    />
                                                }
                                            </td>
                                        </tr>

                                        <tr>
                                            <th className="bmc-bg-th">생년월일</th>
                                            <td ref={prodRef}>
                                                <BMCDatePicker
                                                    className={"text-center w-100"} onClick={fn_datePopupOpen}
                                                    value={userBirthday} name={'userBirthday'} onChange={fn_setFormData}
                                                    calendar={fn_datePopupOpen}
                                                    readOnly={true}/>
                                                {isDatePopupOpen
                                                    ? <CustomDatePicker setIsDatePopupOpen={setIsDatePopupOpen}
                                                                        prodRef={prodRef}
                                                                        setDateView={setUserBirthday}
                                                    />
                                                    : null}
                                            </td>
                                            <th className="bmc-bg-th">혈액형</th>
                                            <td>
                                                <BMCSelect initOptionGb={'sel'} className={'form-select'} codeGb={'cmmn'}
                                                          codeGrpId={'B001'} name={'bldTp'} value={data?.bldTp}
                                                          onChange={fn_setFormData}/>
                                            </td>
                                        </tr>
                                        <tr className="border-top align-middle">

                                            <th className="bmc-bg-th" style={{width: '15%'}}>비상 연락망</th>
                                            <td style={{width: '35%'}}>

                                                {   //@ts-ignore
                                                    <NumberFormatBase onChange={fn_setFormData} name={'emrgncyPhone'}
                                                                      className={'form-control'}
                                                                      value={data?.emrgncyPhone || ''}
                                                                      format={Formatter.phoneNumFormat}
                                                    />}

                                            </td>
                                            <th className="bmc-bg-th" style={{width: '15%'}}>면허번호</th>
                                            <td style={{width: '35%'}}>
                                                <div
                                                    className="d-flex align-items-center justify-content-between w-100 p-0 bmc-fs-b3">
                                                    <BMCInput type="text" className="form-control w-75 me-2"
                                                             name={'driverLcncNo'} disabled={!data?.isDriver}
                                                             onChange={fn_setFormData}
                                                             value={data?.isDriver ? data?.driverLcncNo : ''}/>
                                                    <BMCCheckbox name={'isDriver'} onChange={fn_setFormData}
                                                                checked={data?.isDriver} className={'ml-3 w-[110px]'}>장비
                                                        운전자</BMCCheckbox>
                                                </div>
                                            </td>
                                        </tr>
                                        </tbody>
                                    </Table>
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div className="bmc-modal-div">
                            <div className="div-header">
                                <div className='flex justify-content-between w-100 align-items-center'>
                                    <span>현장 정보 입력</span>
                                    <button className="bmc-btn btn-secondary" type="button"
                                            onClick={fn_openEmployeeSiteAuthPop}>추가
                                    </button>
                                </div>
                            </div>
                            <div className="div-body px-3 pt-3">
                                <table className="table sub">
                                    <colgroup>
                                        <col width='5%'/>
                                        <col width='20%'/>
                                        <col width='15%'/>
                                        <col width='10%'/>
                                        <col width='10%'/>
                                        <col width='10%'/>
                                        <col width='10%'/>
                                        <col width='10%'/>
                                        <col width='5%'/>
                                    </colgroup>
                                    <thead>
                                    <tr className="table-secondary">
                                        <th className="align-middle">대표</th>
                                        <th className="align-middle">현장</th>
                                        <th className="align-middle">업체</th>
                                        <th className="align-middle">공종</th>
                                        <th className="align-middle">직종</th>
                                        <th className="align-middle">최초추가일자</th>
                                        <th className="align-middle">출역일</th>
                                        <th className="align-middle">마지막 출근일</th>
                                        <th className="align-middle">삭제</th>
                                    </tr>
                                    </thead>
                                    <tbody id="wrkDclrRgst_siteTBody">
                                    {
                                        employeeSiteAuthDataList.length > 0 ?
                                            employeeSiteAuthDataList.map((employeeSiteAuthData: any, idx: number) => (
                                                <tr key={idx}>
                                                    <td className={'text-center'}><input
                                                        type={"checkbox"}
                                                        className={'form-check'}
                                                        onChange={fn_setFormData}
                                                        onClick={() => {
                                                            fn_chkIsRep(idx)
                                                        }}
                                                        checked={employeeSiteAuthData.isRep || false}/>
                                                    </td>
                                                    <td>
                                                        <BMCSelect initOptionGb={'none'}
                                                                  disabled={true}
                                                                  codeGb={'site'}
                                                                  onChange={fn_setFormData}
                                                                  className={'form-control'}
                                                                  value={employeeSiteAuthData.siteId}></BMCSelect>
                                                    </td>
                                                    <td>
                                                        <BMCSelect initOptionGb={'none'}
                                                                  disabled={false}
                                                                  name={'cmpnySeq'}
                                                                  codeGb={'siteCmpny'}
                                                                  className={'form-control'}
                                                                  onChange={(e) => fn_updateEmployeeSiteAuthDataList(e, idx)}
                                                                  selParam={{siteId: employeeSiteAuthData.siteId}}
                                                                  value={employeeSiteAuthData.cmpnySeq}></BMCSelect>
                                                    </td>
                                                    <td>
                                                        <BMCSelect initOptionGb={'none'}
                                                                  disabled={false}
                                                                  codeGb={'siteMngCd'}
                                                                  className={'form-control'}
                                                                  name={'wrkTpCd'}
                                                                  onChange={(e) => fn_updateEmployeeSiteAuthDataList(e, idx)}
                                                                  selParam={{
                                                                      siteId: employeeSiteAuthData.siteId,
                                                                      manageCode: 'M001001'
                                                                  }}
                                                                  value={employeeSiteAuthData.wrkTpCd}></BMCSelect>
                                                    </td>
                                                    <td>
                                                        <BMCSelect initOptionGb={'none'}
                                                                  disabled={false}
                                                                  codeGb={'siteMngCd'}
                                                                  className={'form-control'}
                                                                  name={'ocptCd'}
                                                                  onChange={(e) => fn_updateEmployeeSiteAuthDataList(e, idx)}
                                                                  selParam={{
                                                                      siteId: employeeSiteAuthData.siteId,
                                                                      manageCode: 'M001002'
                                                                  }}
                                                                  value={employeeSiteAuthData.ocptCd}></BMCSelect>
                                                    </td>
                                                    <td>
                                                        <BMCInput type="text" className="form-control"
                                                                 name={'rgstDt'} disabled={true}
                                                                 onChange={fn_setFormData}
                                                                 value={employeeSiteAuthData.rgstDt}/>
                                                    </td>
                                                    <td>
                                                        <BMCInput type="text" className="form-control" name={'a'}
                                                                 disabled={true}
                                                                 onChange={fn_setFormData} value={""}/>
                                                    </td>
                                                    <td>
                                                        <BMCInput type="text" className="form-control" name={'b'}
                                                                 disabled={true}
                                                                 onChange={fn_setFormData} value={""}/>
                                                    </td>
                                                    <td className={'text-center'}>
                                                        <img
                                                            className={'w-[25px] cursor-pointer inline-block'}
                                                            src={iconTrashBean}
                                                            onClick={() => fn_delRow(employeeSiteAuthData.userSeq, employeeSiteAuthData.siteId, idx)}/>
                                                    </td>
                                                </tr>

                                            )) : <tr>
                                                <td colSpan={9} className={'text-center'}>현장 정보를 등록해 주세요.</td>
                                            </tr>
                                    }
                                    </tbody>
                                </table>
                            </div>
                        </div>
                        <div className="bmc-modal-div">
                            <div className="div-header">
                                추가정보 입력
                            </div>
                            <div className="div-body px-3">
                                <Table className="my-2 align-middle">
                                    <tbody>
                                    <tr className="border-top align-middle">
                                        <th className="bmc-bg-th">
                                            주소
                                        </th>
                                        <td>
                                            <div
                                                className="d-flex align-items-center justify-content-between w-100 p-0 bmc-fs-b3">
                                                <BMCInput type="text" name={'homeAddress'} value={data?.homeAddress}
                                                         className="form-control" readOnly/>
                                                <button className="bmc-btn btn-secondary min-w-[70px] ml-5 mr-10"
                                                        type="button" onClick={fn_openAddrPop}>검색
                                                </button>


                                            </div>
                                        </td>
                                        <th className="bmc-bg-th">
                                            상세주소
                                        </th>
                                        <td>
                                            <div className="d-flex align-items-center px-0">
                                                <BMCInput type="text" className={'form-control'}
                                                         onChange={fn_setFormData} name={'homeDtlAddress'}
                                                         value={data?.homeDtlAddress}/>
                                            </div>
                                        </td>
                                    </tr>
                                    <tr className="border-top align-middle">
                                        <th className="bmc-bg-th" style={{width: '15%'}}>국적</th>
                                        <td style={{width: '35%'}}>
                                            <BMCSelect initOptionGb={'all'} name={'nationId'} className={'form-select'}
                                                      codeGb={'nation'} value={data?.nationId}
                                                      onChange={fn_setFormData}
                                            ></BMCSelect>
                                        </td>
                                        <th className="bmc-bg-th" style={{width: '15%'}}>특이사항</th>
                                        <td style={{width: '35%'}}>
                                            <div
                                                className="d-flex align-items-center justify-content-between w-100 p-0 bmc-fs-b3">
                                                <BMCInput type="text" className="form-control"
                                                         name={'sgnfcnt'} disabled={!data?.isSgnfcnt}
                                                         onChange={fn_setFormData}
                                                         value={data?.isSgnfcnt ? data?.sgnfcnt : ''}/>
                                                <BMCCheckbox name={'isSgnfcnt'} onChange={fn_setFormData}
                                                            checked={data?.isSgnfcnt}
                                                            className={'ml-3 w-[110px]'}>특이사항</BMCCheckbox>
                                            </div>
                                        </td>
                                    </tr>
                                    <tr className="border-top align-middle">
                                        <th className="bmc-bg-th">
                                            건강상담기록
                                        </th>
                                        <td>
                                            <div className="d-flex align-items-center px-0">
                                                <BMCInput type="text" className={'form-control'}
                                                         onChange={fn_setFormData} name={'htlthRec'}
                                                         value={data?.htlthRec}/>
                                            </div>
                                        </td>
                                    </tr>
                                    <tr className="align-middle">
                                        <th className="bmc-bg-th">기본 안전 교육 수료증 첨부</th>
                                        <td>
                                            <BMCFile maxFileCnt={1} fileName={'files'} fileList={fileList} setFileList={setFileList}></BMCFile>
                                        </td>
                                        <th className="bmc-bg-th">근로자 건강 보건 기록 첨부</th>
                                        <td>
                                            <BMCFile maxFileCnt={1} fileName={'hlthFiles'} fileId={'hlthFiles'} fileList={hlthFileList} setFileList={setHlthFileList}></BMCFile>
                                        </td>
                                    </tr>
                                    </tbody>
                                </Table>
                            </div>
                        </div>

                        {props.detailData &&
                            <div className="bmc-modal-div">
                                <div className="div-header">
                                    교육 수료 이력 정보
                                </div>
                                <div className="div-body px-3">
                                    <Datatables
                                        datas={eduHistDataList}
                                        isHideTotCnt={true}
                                        scrollY="550px"
                                        className="bmc-fs-b4"
                                        columns={[
                                            {data: "eduTitle", title: "교육명", textAlign: 'center'},
                                            {data: "eduDt", title: "교육 일자", textAlign: 'center'},
                                        ]}
                                        checked={false}
                                        onLoad={(dt: any) => {
                                        }}

                                        onClick={(data) => {

                                        }
                                        }
                                    />
                                </div>
                            </div>
                        }

                        <div className="bmc-modal-div">
                            <div className="div-header">
                                작성정보
                            </div>
                            <div className="div-body px-3">
                                <Table className="my-2 align-middle">
                                    <tbody>
                                    <tr className="border-top align-middle">
                                        <th className="bmc-bg-th" style={{width: '15%'}}>작성일</th>
                                        <td style={{width: '35%'}}>
                                            <BMCInput value={data?.regDate || currentDate} name={'rgstDt'}
                                                     onChange={fn_setFormData}
                                                     className={"form-control"}
                                                     disabled={true}/>
                                        </td>
                                        <th className="bmc-bg-th" style={{width: '15%'}}>작성자</th>
                                        <td style={{width: '35%'}}>
                                            <BMCInput className={"form-control"} name={'rgstrNm'}
                                                     value={data?.rgstrNm || user.userName}
                                                     onChange={fn_setFormData}
                                                     disabled={true}/>
                                        </td>
                                    </tr>
                                    </tbody>
                                </Table>
                            </div>
                        </div>
                    </form>
                </Modal.Body>
                <Modal.Footer className="py-2">
                    <div className='flex justify-center w-100'>
                        <button
                            className='bmc-btn pill l white me-3'
                            onClick={fn_closeModal}>닫기
                        </button>
                        <button onClick={fn_registData}
                                className='bmc-btn pill p'>등록/수정
                        </button>
                    </div>
                </Modal.Footer>
            </Modal>
        </div>
    )
}

export default EmployeeRgstModal;

