import SubMenuTitle from "../component/SubMenuTitle";
import SafetyManagementBoardModal from "../safetyManagementBoard/modal/SafetyManagementBoardModal";
import {Button, Container, ListGroup, Overlay, Table} from "react-bootstrap";
import React, {useContext, useEffect, useRef, useState} from "react";
import SHSearch from "../component/BMCSearch";
import BMCDatePicker2 from "../../components/common/BMCDatePicker2";
import BMCIconButton from "../../components/common/BMCIconButton";
import Datatables from "../component/Datatables";
import {DateHelper} from "../../helper/DateHelper";
import BMCInput from "../../components/common/BMCInput";
import BMCSelect from "../../components/common/BMCSelect";
import {TopMenuContext} from "../../context/TopMenu";
import SafetyNewspaperModal from "./modal/SafetyNewspaperModal";
import {getLoginUser} from "../../helper/SecurityHelper";


type Props = {};

const SafetyNewspaperPage = (props: Props) => {
    const [modalIsOpen, setModalIsOpen] = React.useState(false);
    const [detailData, setDetailData] = useState(null);
    const searchFormRef = useRef<any>();
    const [datatable, setDatatable] = useState<any>();
    const prodRef = useRef<any>();
    const [startDate, setStartDate] = useState<Date>(DateHelper.dateByMonth(-3));
    const [endDate, setEndDate] = useState<Date>(DateHelper.dateByMonth(0));
    const [data, setData] = useState<any>();
    const srchTxtRef = useRef<any>();
    const [isCommentMode, setIsCommentMode] = useState<boolean>(false);
    const defaultUser: any = getLoginUser();
    const user = getLoginUser();

    useEffect(() => {

    }, [])

    useEffect(() => {
        datatable?.draw();
    }, [modalIsOpen])

    const fn_setSrchData = (e: any) => {
        setData((item: any) => {
            return {...item, [e.target.name]: e.target.value}
        });
    }

    const fn_registPopOpen = () => {
        setDetailData(null);
        setModalIsOpen(true);
        setIsCommentMode(false);
    }

    const fn_delete = () => {
        console.log("사용안함")
    }

    const fn_comment = () =>{
        alert("코멘트");
    }

    return (
        <Container fluid className="bg-white px-0 bmc-txt-color-primary">
            <SubMenuTitle title={'안전 신문고'} parentMenu={'안전 정보 관리'}/>
            <SafetyNewspaperModal setModalIsOpen={setModalIsOpen} modalIsOpen={modalIsOpen} detailData={detailData} isCommentMode={isCommentMode}/>

            <div className="p-4 overflow-y-auto" style={{height: 'calc(100vh - 197px)'}}>
                <SHSearch datatable={datatable} formRef={searchFormRef} btnType={'p'}>
                    <Table className="bmc-font-SP bmc-fs-b2 my-3">
                        <tbody>
                        <tr className="border-top border-bottom align-middle">
                            <th className="bmc-bg-th" style={{width: '15%'}}>상태</th>
                            <td style={{width: '35%'}}>
                                <BMCSelect initOptionGb={'all'} className={"w-100"} name={'statusCd'} codeGrpId={'S005'}
                                           onChange={fn_setSrchData} value={data?.statusCd}></BMCSelect>
                            </td>
                            <th className="bmc-bg-th" style={{width: '15%'}}>현장</th>
                            <td>
                                <BMCSelect initOptionGb={'all'} className={'form-control'} name={'siteId'} codeGb={'site'}
                                           value={data?.siteId} onChange={fn_setSrchData}></BMCSelect>
                            </td>
                        </tr>
                        <tr className="border-top border-bottom align-middle">
                            <th className="bmc-bg-th" style={{width: '15%'}}>기간</th>
                            <td style={{width: '35%'}}>
                                <div className="d-flex flex-row gap-2">
                                    <div className="d-flex align-items-center" ref={prodRef}>
                                        <BMCDatePicker2 name={'startRgstDt'} defaultValue={startDate}
                                                        onChange={fn_setSrchData}/>
                                        <span>&nbsp;~&nbsp;</span>
                                        <BMCDatePicker2 name={'endRgstDt'} defaultValue={endDate} onChange={fn_setSrchData}/>
                                    </div>
                                    <div className="d-flex align-items-center gap-1" ref={prodRef}>
                                        <input className="form-check-input bmc-check" id={'isAll'} name={'isAll'}
                                               type="checkbox" onChange={fn_setSrchData}/>
                                        <label className={'form-check-label text-black'} htmlFor={'isAll'}>전체기간</label>
                                    </div>
                                </div>
                            </td>
                            <th className="bmc-bg-th" style={{width: '15%'}}>검색어</th>
                            <td style={{width: '35%'}}>
                                <input type={'text'} name={'srchTxt'} className='form-control' ref={srchTxtRef}/>
                            </td>
                        </tr>
                        </tbody>
                    </Table>
                </SHSearch>

                {/*<div className='w-100 d-flex justify-content-end align-items-center mb-2'>
                {user.authCode != 'headRead' &&
                    <BMCIconButton kind={'submit'} marginRight={'5px'} onClick={fn_registPopOpen}>등록</BMCIconButton>
                }
                <BMCIconButton kind={'delete'} marginRight={'0'} onClick={fn_delete}>삭제</BMCIconButton>
            </div>*/}

                <div className={'ge-result'}>
                    <Datatables
                        dataUrl={'/admin/api/sftyNwsppr/retrieveSftyNwspprDataList'}
                        parameterFormRef={searchFormRef}
                        columns={[
                            {data: "statusNm", title: "상태", textAlign: 'center'},
                            {data: "siteNm", title: "현장", textAlign: 'center'},
                            {data: "title", title: "제목", textAlign: 'center'},
                            {data: "rgstDt", title: "작성일", textAlign: 'center'},
                            {data: "cnfrmNm", title: "확인자", textAlign: 'center'},
                            {data: '', title: '코멘트', render: (data: any, type: any, row: any) => {
                                    if(row.cnfrmSeq == null && row.rgstrSeq != defaultUser.userSeq) {
                                        return "<button class='btn btn-sm btn-secondary'>등록</button>";
                                    }else if(row.cnfrmSeq == null && row.rgstrSeq == defaultUser.userSeq){
                                        return "<span>미등록</span>";
                                    }else{
                                        return "<span>등록완료</span>";
                                    }

                                }},
                        ]}
                        fixedTop={"isFixedTop"}
                        checked={false}
                        index={true}
                        onLoad={(dt: any) => {
                            setDatatable(dt)
                        }}
                        onClick={(data,gb) => {
                            if(gb == 'btn') {
                                setIsCommentMode(true);
                            }else {
                                setIsCommentMode(false);
                            }
                            setDetailData(data);
                            setModalIsOpen(true);
                        }}
                    />
                </div>
            </div>
        </Container>
    )
}

export default SafetyNewspaperPage;
