import SHSearch from "../../component/BMCSearch";
import {Col, Row, Table} from "react-bootstrap";
import React, {Dispatch, SetStateAction, useEffect, useRef, useState} from "react";
import Datatables from "../../component/Datatables";
import BMCDatePicker from "../../../components/common/BMCDatePicker";
import CustomDatePickerRange from "../../component/ui/DatePickerRange";
import axios from "axios";
import {toast} from "react-toastify";
import errorCodeDic from "../../../util/errorCodeDic";
import CustomDatePicker from "../../component/ui/DatePicker";
import BMCDatePicker2 from "../../../components/common/BMCDatePicker2";
import {DateHelper} from "../../../helper/DateHelper";

interface IProps {
   dataList: any;
   setSelData: Dispatch<SetStateAction<any>>;
   srchData: any;
   setSrchData: Dispatch<SetStateAction<boolean>>;
   isDatePopupOpen: boolean;
   setIsDatePopupOpen: Dispatch<SetStateAction<boolean>>;
   onSearch: any;
}

const DailySftyRprtByDprtPage = (props: IProps) => {
    const prodRef = useRef<any>();
    const [chkDt, setChkDt] = useState<Date>(DateHelper.dateByMonth(0));

    const fn_setSrchData = (e: any) => {

        props.setSrchData({
            ...props.srchData,
            [e.target.name]: e.target.value
        })


    }

    return(
        <>
            <SHSearch btnType={'p'} onSearch={props.onSearch}>
                <Row>
                    <Col>
                        <Table className="bmc-font-SP bmc-fs-b2 my-3">
                            <tbody>
                            <tr className="align-middle border-bottom">
                                <th className="bmc-bg-th">일자</th>
                                <td colSpan={2}>
                                    <div className="d-flex align-items-center w-100 gap-2" ref={prodRef}>
                                        <BMCDatePicker2 readOnly={true} name={'chkDt'} defaultValue={chkDt} onChange={fn_setSrchData} />
                                    </div>
                                </td>
                            </tr>
                            </tbody>
                        </Table>
                    </Col>
                </Row>
            </SHSearch>

            <div className={'ge-result'}>
                <Datatables
                    datas={props.dataList}
                    scrollY="550px"
                    className="bmc-fs-b4"
                    columns={[
                        {data: "dprtNm", title: "관리부서"},
                        {data: 'chkDt', title: '점검일자'},
                        {data: "title", title: "내용"},
                        {
                            data: "", title: "특이사항<br/>(재해유무)", textAlign: 'center', render: (data: any, type: any, row: any) => {
                               console.log('row: ', row)
                                let rslt = '없음'
                                if(row.chkCnt === 0){
                                    rslt = '없음'
                                }else{
                                    rslt = '있음 (' + row.chkCnt + ')'
                                }
                                return rslt
                            }
                        },
                        {
                            data: "", title: "제출현황<br/>(제출 사업장/전체)", textAlign: 'center', render: (data: any, type: any, row: any) => {
                                console.log('row: ', row)

                                return row.sbmtSiteCnt + '/' + row.targetSiteCnt;
                            }
                        },

                    ]}
                    index={true}
                    checked={false}
                    onLoad={(dt: any) => {
                        //setDatatable(dt)
                    }}

                    onClick={(data) => {
                        props.setSelData(Object.assign({}, data));
                    }
                    }
                />


            </div>
        </>
        )

}

export default DailySftyRprtByDprtPage;
