import React, {useEffect, useRef, useState} from 'react';
import {Col, Container, Row} from "react-bootstrap";
import {ReactComponent as IconBuilding} from "../../assets/images/icon/icon_building.svg";
import {ReactComponent as IconDocument} from "../../assets/images/icon/icon_document.svg";
import restapi from "../../helper/restAPIHelper";
import SiteInfoListModal from "./modal/SiteInfoListModal";
import SftyChkInfoListModal from "./modal/SftyChkInfoListModal";
import moment from "moment/moment";
import SiteSftyChkInfoListModal from "./modal/SiteSftyChkInfoListModal";

const HOCRRiskStatus = () => {
    const [actNoCmpltCnt, setActNoCmpltCnt] = useState<number>(0);
    const [actNoCmpltSiteCnt, setActNoCmpltSiteCnt] = useState<number>(0);
    const [modalIsOpen, setModalIsOpen] = useState<boolean>(false);
    const [sftyChkModalIsOpen, setSftyChkModalIsOpen] = useState<boolean>(false);
    const [dataList, setDataList] = useState<any>([]);
    const [actNoCmpltSiteDataList, setActNoCmpltSiteDataList] = useState<any>([]);
    const [sftyChkDataList, setSftyChkDataList] = useState<any>([]);
    const timerRef = useRef<any>();

    useEffect(() => {
        fn_retrieveData();
        //timerRef.current = setInterval(fn_retrieveData, 5000);

        return () => {
            //clearInterval(timerRef.current);
        };
    }, []);
    const fn_retrieveData = () => {
        restapi.retrieveSftyChkActStatData({}).then((rsltData: any) => {
            setActNoCmpltCnt(rsltData.actNoCmpltCnt);

        })

        restapi.retrieveActNoCmpltSiteDataList().then((rsltData: any) => {
            setActNoCmpltSiteDataList(rsltData);
            setActNoCmpltSiteCnt(rsltData.length);
        })
    }


    function fn_openSiteList(){

        setDataList(actNoCmpltSiteDataList)
        setModalIsOpen(true);
    }

    const fn_openSftyChkInfoModal = () => {

        restapi.retrieveSiteSftyManageDataTotList({actStatGbCd: 'C005002'}).then((rsltData: any) => {
            setSftyChkDataList(rsltData);
            setSftyChkModalIsOpen(true);
        })

    }


    return(
        <>
            <SiteSftyChkInfoListModal setModalIsOpen={setModalIsOpen} modalIsOpen={modalIsOpen} dataList={dataList} />
            <SftyChkInfoListModal setModalIsOpen={setSftyChkModalIsOpen} modalIsOpen={sftyChkModalIsOpen} dataList={sftyChkDataList} ></SftyChkInfoListModal>
            <Container fluid className="bmc-bg-pane">
                <Row>
                    <Col>
                        <div className="d-flex flex-column justify-content-between">
                            <div className="w-100 d-flex align-items-center justify-content-between pb-2 border-bottom">
                                <span className="bmc-txt-size-h4">위험 관리 현황</span>
                            </div>
                        </div>
                    </Col>
                </Row>
                <Row className="px-0 pt-2">
                    <Col>
                        <div onClick={fn_openSiteList} className="rounded-3 px-3 py-2 cursor-pointer bmc-bg-color-02" style={{lineHeight: '20px'}}>
                            <div>
                                <span className="bmc-txt-size-h3">{actNoCmpltSiteCnt}</span>
                            </div>
                            <div>
                                <span className="bmc-txt-size-b6 bmc-txt-weight-4 bmc-txt-color-gray-01">미조치 현장 수</span>
                            </div>
                            <div className="mt-1 w-100 d-flex justify-content-end align-items-center">
                                <IconBuilding style={{width: '30px'}} />
                            </div>
                        </div>
                    </Col>
                    <Col>
                        <div onClick={fn_openSftyChkInfoModal} className="rounded-3 px-3 py-2 cursor-pointer bmc-bg-color-03" style={{lineHeight: '20px'}}>
                            <div>
                                <span className="bmc-txt-size-h3">{actNoCmpltCnt}</span>
                            </div>
                            <div>
                                <span className="bmc-txt-size-b6 bmc-txt-weight-4 bmc-txt-color-gray-01">미조치 대상 건</span>
                            </div>
                            <div className="mt-1 w-100 d-flex justify-content-end align-items-center">
                                <IconDocument style={{width: '30px'}} />
                            </div>
                        </div>
                    </Col>
                </Row>
            </Container>
        </>
    );
}

export default HOCRRiskStatus;
