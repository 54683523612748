import React, {useContext, useEffect, useRef, useState} from 'react';
import Datatables, {getCheckedItems} from "../component/Datatables";
import axios from "axios";
import {toast, ToastContainer} from "react-toastify";
import messageCodeDic from "../../util/messageCodeDic";
import errorCodeDic from "../../util/errorCodeDic";
import BMCInput from "../../components/common/BMCInput";

import SHSearch from '../component/BMCSearch';
import SubMenuTitle from "../component/SubMenuTitle";
import {getLoginUser} from "../../helper/SecurityHelper";
import {Col, Container, Row, Table} from "react-bootstrap";
import BMCIconButton from "../../components/common/BMCIconButton";
import SftyWrkMetRgstModal from "../safetyWorkReportManagement/modal/SftyWrkMetRgstModal";
import IncgrtyRgstModal from "./modal/IncgrtyRgstModal";
import {UserContext} from "../../context/User";
import BMCDatePicker2 from "../../components/common/BMCDatePicker2";
import {DateHelper} from "../../helper/DateHelper";
import BMCSelect from "../../components/common/BMCSelect";
import IncgrtyPicModal from "./modal/IncgrtyPicModal";

type Props = {};

const IncongruityManagementPage = (props: Props) => {
    const [modalIsOpen, setModalIsOpen] = React.useState(false);
    const [detailData, setDetailData] = useState<any>(null);
    const [isSaveSuccess, setIsSaveSuccess] = useState(false);

    const [data, setData] = useState<any>()
    const searchFormRef = useRef<any>();
    const [datatable, setDatatable] = useState<any>();
    const defaultUser = getLoginUser();
    const {user} = useContext<any>(UserContext);

    const [startDate, setStartDate] = useState<Date>(DateHelper.dateByMonth(-3));
    const [endDate, setEndDate] = useState<Date>(DateHelper.dateByMonth(0));
    const prodRef = useRef<any>();
    const [isPicModalOpen, setIsPicModalOpen] = useState<boolean>(false);
    const [picDtlData, setPicDtlData] = useState<any>({});
    const [isCodeDataLoaded, setIsCodeDataLoaded] = useState<boolean>(false);

    useEffect(() => {

        if (datatable) {
            datatable.draw();
        }

    }, [user, datatable])

    useEffect(() => {
        if (!modalIsOpen) {
            datatable?.draw();
        }
    }, [modalIsOpen])

    useEffect(() => {
        if(isCodeDataLoaded){
            datatable?.draw();
        }

    }, [isCodeDataLoaded])

    const fn_search = () => {
        datatable.draw();
    }

    /**
     * 등록 버튼 클릭
     */
    const fn_registPopOpen = () => {

        setDetailData(null)
        setModalIsOpen(true);
    }

    const fn_delete = () => {

        var checkedData = getCheckedItems(datatable);
        let delList: number[] = [];

        if (checkedData.length == 0) {
            toast.warn(messageCodeDic['005']);
            return;
        }

        checkedData.map((data: any) => {
            delList.push((data.incgrtyId));
        })

        console.log('delList: ', delList);

        if (!window.confirm(messageCodeDic['004'])) {
            return;
        }

        axios.post('/admin/api/incgrty/deleteIncgrtyData', {delList: delList}).then(rsltData => {
                if (rsltData.data > 0) {
                    toast.success(messageCodeDic['002'])
                    fn_search();
                } else {
                    toast.error(errorCodeDic['003'])
                }

            }
        ).catch(() => {
                toast.error(errorCodeDic['003'])
            }
        )
    }

    const fn_setFormData = (e: any) => {

        setData({
            ...data,
            [e.target.name]: e.target.value
        })

    }

    return (
        <Container fluid className="bg-white px-0 bmc-txt-color-primary">
            <SubMenuTitle title={'부적합 관리'} parentMenu={'현장안전관리'}/>
            <IncgrtyPicModal detailData={picDtlData} setModalIsOpen={setIsPicModalOpen} modalIsOpen={isPicModalOpen} />
            <IncgrtyRgstModal setModalIsOpen={setModalIsOpen} modalIsOpen={modalIsOpen}
                              siteId={user?.blngSiteId || defaultUser.blngSiteId}
                              detailData={detailData} setIsSaveSuccess={setIsSaveSuccess}/>

            <div className="p-4 overflow-y-auto" style={{height: 'calc(100vh - 180px)'}}>
                <SHSearch datatable={datatable} formRef={searchFormRef} btnType={'p'}>
                    <Row>
                        <Col>
                            <Table className="bmc-font-SP bmc-fs-b2 my-3">
                                <tbody>
                                <tr className="border-top border-bottom align-middle">
                                    <th className="bmc-bg-th" style={{width: '15%'}}>상태</th>
                                    <td style={{width: '35%'}}>
                                        <BMCSelect className={'form-select'} initOptionGb={'all'} value={data?.prcsStatCd}
                                                   onChange={fn_setFormData} name={'prcsStatCd'}
                                                   codeGrpId={'I001'}></BMCSelect>
                                    </td>
                                    <th className="bmc-bg-th" style={{width: '15%'}}>등록업체</th>
                                    <td style={{width: '35%'}}>
                                        <BMCSelect initOptionGb={'none'} codeGb={'siteCmpny'}
                                                   className={'form-select'} setIsCodeDataLoaded={setIsCodeDataLoaded}
                                                   name={'cmpnySeq'} selParam={{siteId: user?.blngSiteId || defaultUser.blngSiteId, cmpnySeq: defaultUser.blngCmpnySeq}}
                                        ></BMCSelect>
                                    </td>
                                </tr>
                                <tr className="border-top border-bottom align-middle">
                                    <th className="bmc-bg-th" style={{width: '15%'}}>기간검색</th>
                                    <td style={{width: '35%'}}>
                                        <div className="d-flex flex-row gap-2">
                                            <BMCInput type={'hidden'} name={'siteId'}
                                                      value={user?.blngSiteId || defaultUser.blngSiteId}></BMCInput>
                                            <div className="d-flex align-items-center" ref={prodRef}>
                                                <BMCDatePicker2 name={'startRgstDt'} defaultValue={startDate}
                                                                onChange={fn_setFormData}/>
                                                <span>&nbsp;~&nbsp;</span>
                                                <BMCDatePicker2 name={'endRgstDt'} defaultValue={endDate}
                                                                onChange={fn_setFormData}/>
                                            </div>
                                            <div className="d-flex align-items-center gap-1" ref={prodRef}>
                                                <input className="form-check-input bmc-check" id={'isAll'} name={'isAll'}
                                                       type="checkbox" onChange={fn_setFormData}/>
                                                <label className={'form-check-label text-black'}
                                                       htmlFor={'isAll'}>전체기간</label>
                                            </div>
                                        </div>
                                    </td>
                                    <th className="bmc-bg-th" style={{width: '15%'}}>검색어</th>
                                    <td style={{width: '35%'}}>
                                        <BMCInput name={'srchTxt'} className={'form-control'} value={data?.srchTxt}
                                                  onChange={fn_setFormData}/>
                                    </td>
                                </tr>
                                </tbody>
                            </Table>
                        </Col>
                    </Row>
                </SHSearch>
                <div className='w-100 d-flex justify-content-end align-items-center mb-2'>
                    <BMCIconButton kind={'submit'} marginRight={'5px'} onClick={fn_registPopOpen}>등록</BMCIconButton>
                    <BMCIconButton kind={'delete'} marginRight={'0'} onClick={fn_delete}>삭제</BMCIconButton>
                </div>

                <div className={'ge-result'}>
                    <Datatables
                        dataUrl={'/admin/api/incgrty/retrieveIncgrtyDataList'}
                        parameterFormRef={searchFormRef}
                        columns={[
                            {data: "prcsStatNm", title: "상태", textAlign: 'center'},
                            {data: "prcsStatNm", title: "상태", textAlign: 'center', render: (data: any) => {
                                    switch(data) {
                                        case '조치권고':
                                            return "<div class='bmc-box-status request'>조치권고</div>";
                                        case '조치완료':
                                            return "<div class='bmc-box-status approvaldone'>조치완료</div>";
                                        default:
                                            return "";
                                    }
                                }},
                            {data: "incgrtyTitle", title: "제목", textAlign: 'left'},
                            {
                                title: "조치전",
                                type: "button",
                                className: "text-center",
                                buttons: [{
                                    label: "<img style='height: 20px' class='cursor-pointer' src='/icon_picture.svg' />",
                                    onClick: (v: any) => {
                                        if(v.bfAtchFileId){
                                            setPicDtlData({title: '조치 전 사진', fileId: v.bfAtchFileId})
                                            setIsPicModalOpen(true);

                                        }else{
                                            toast.warn('등록된 사진이 없습니다');
                                        }
                                    },
                                }],
                            },
                            {data: "rgstrNm", title: "등록자", textAlign: 'center'},
                            {data: "rgstDt", title: "작성일", textAlign: 'center'},
                            {
                                title: "조치후",
                                type: "button",
                                className: "text-center",
                                buttons: [{
                                    label: "<img style='height: 20px' class='cursor-pointer' src='/icon_picture.svg' />",
                                    onClick: (v: any) => {
                                        if(v.afAtchFileId){
                                            setPicDtlData({title: '조치 후 사진', fileId: v.afAtchFileId})
                                            setIsPicModalOpen(true);

                                        }else{
                                            toast.warn('등록된 사진이 없습니다');
                                        }
                                    },
                                }],
                            },
                            // {data: "incgrtyPlcNm", title: "위치", textAlign: 'center'},
                            {data: "actCmpltNm", title: "조치 담당자", textAlign: 'center'},
                            {data: "actCmpltDt", title: "조치 완료일", textAlign: 'center'},


                        ]}
                        checked={true}
                        index={true}

                        onLoad={(dt: any) => {
                            setDatatable(dt)
                        }}
                        onClick={(data) => {
                            setDetailData(data)
                            setModalIsOpen(true);
                        }
                        }
                    />
                </div>
            </div>
        </Container>
    );
};

export default IncongruityManagementPage;
