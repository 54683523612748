import {Col, Modal, Row, Table} from "react-bootstrap";
import {IoMdClose} from "react-icons/io";

import React, {Dispatch, SetStateAction, useContext, useEffect, useRef, useState} from "react";
import {fn_getCurrentDate} from "../../../helper/CmmnHelper";
import {getLoginUser} from "../../../helper/SecurityHelper";

import axios from "axios";
import {toast} from "react-toastify";
import messageCodeDic from "../../../util/messageCodeDic";
import errorCodeDic from "../../../util/errorCodeDic";
import BMCInput from "../../../components/common/BMCInput";
import BMCTextarea from "../../../components/common/BMCTextarea";
import BMCSelect from "../../../components/common/BMCSelect";
import iconTrashBean from "../../../images/icon_remove.png";
import ActChrgrModal from "./ActChrgrModal";
import restapi from "../../../helper/restAPIHelper";
import BMCFile from "../../../components/common/BMCFile";
import {ProgressContext} from "../../../context/Progress";
import BMCIconButton from "../../../components/common/BMCIconButton";
import {Container} from "@mui/material";
import BMCDatePicker from "../../../components/common/BMCDatePicker";
import CustomDatePicker from "../../component/ui/DatePicker";



interface IProps {
    setModalIsOpen: Dispatch<SetStateAction<boolean>>;
    modalIsOpen: boolean;
    detailData: any;
    setIsSaveSuccess?: Dispatch<SetStateAction<boolean>>;
    siteId: number;
}

const IncgrtyRgstModal = (props: IProps) => {

    const currentDate = fn_getCurrentDate();
    const user = getLoginUser();

    const [data, setData] = useState<any>()

    const [chrgrDataList, setChrgrDataList] = useState<any>([]);
    const [isActChrgrPopOpend, setIsActChrgrPopOpend] = React.useState<boolean>(false);
    const formRef = React.createRef<HTMLFormElement>();
    const {spinner} = useContext(ProgressContext);
    const fileRef = useRef() as React.MutableRefObject<any>;
    const [imgTarget, setImgTarget] = useState<any>(null);
    const prodRef = useRef<any>();
    const [actCmpltDt, setActCmpltDt] = useState<string>('');
    const [isDatePopupOpen, setIsDatePopupOpen] = useState(false);

    const fn_setFormData = (e: any) => {
        setData({
            ...data,
            [e.target.name]: e.target.value
        })
    }


    function afterOpenModal() {
        const siteId = user.blngSiteId;
        const cmpnySeq = user.blngCmpnySeq;

        // 신규
        if (!props.detailData) {
            setData({
                siteId: siteId,
                cmpnySeq: cmpnySeq,
            })

            setChrgrDataList([])
            setActCmpltDt('');

            // 상세
        } else {
            setData(props.detailData);
            setActCmpltDt(props.detailData.actCmpltDt);
            restapi.retrieveIncgrtyChrgrDataList(props.detailData.incgrtyId).then((rsltData) => {
                console.log('rsltData: ', rsltData);
                setChrgrDataList(rsltData);

            })

        }

    }

    function fn_closeModal() {
        props.setModalIsOpen(false);
    }

    const submit = (e: any) => {
        e.preventDefault()

    }

    const fn_delRow = (idx: number) => {

        // @ts-ignore
        let cstmChrgrDataList = [].concat(chrgrDataList);
        cstmChrgrDataList.splice(idx, 1);
        setChrgrDataList(cstmChrgrDataList);
    }

    const
        fn_registData = () => {

        if (!data) {
            toast.warn('데이터를 입력해 주십시오.')
            return;
        }

        if (!data.incgrtyTitle) {
            toast.warn('제목을 입력해 주십시오.')
            return;
        }

        if (!data.incgrtyPlcCd) {
            toast.warn('부적합 위치를 선택해 주십시오.');
            return;
        }

        if (!data.incgrtyCntnts) {
            toast.warn('부적합 내용을 입력해 주십시오.');
            return;
        }

        if (chrgrDataList.length == 0) {
            toast.warn('조치담당자를 선택해 주십시오.')
            return;
        }

        if (!props.detailData && !data.bfAtchFile) {
            toast.warn('조치전 사진을 입력해 주십시오.');
            return;
        }

        let param = data;
        if (data.prcsStatCd == 'I001001' && user.userSeq != data.rgstrSeq) {

            if (!data.actCmpltOpnn) {
                toast.warn('조치 후 의견을 입력해 주십시오.');
                return;
            }

            if (!actCmpltDt) {
                toast.warn('조치완료일을 입력해 주십시오.');
                return;
            }

            if (!data.afAtchFile) {
                toast.warn('조치후 사진을 입력해 주십시오.');
                return;
            }

        }

        param.rgstrSeq = user.userSeq;
        param.updtrSeq = user.userSeq;
        param.chrgrDataList = chrgrDataList;
        // @ts-ignore
        var formData: any = new FormData(formRef.current);

        if (param.bfAtchFile) {
            console.log('param.bfAtchFile: ', param.bfAtchFile);
            formData.set('bfAtchFile', param.bfAtchFile);
            delete param.bfAtchFile
        }

        if (param.afAtchFile) {
            formData.set('afAtchFile', param.afAtchFile);
            delete param.afAtchFile
        }


        let msg = '';
        if (chrgrDataList.filter((data: any) => {
            return (data.userSeq == user.userSeq)
        }).length > 0 && data?.prcsStatCd == 'I001001') {
            param.prcsStatCd = 'I001002'
            param.actCmpltSeq = user.userSeq;
            param.actCmpltDt = actCmpltDt;
            msg = '조치 완료 하시겠습니까?'
        } else {
            formData.set('prcsStatCd', 'I001001');
            msg = '저장 하시겠습니까?'
        }
        formData.set('incgrRgstInfo', new Blob([JSON.stringify(param)], {type: "application/json"}))
        console.log('param: ', param);
        if (!window.confirm(msg)) {
            return;
        }

        spinner.start();
        axios.post('/admin/api/incgrty/saveData', formData).then((result) => {

            if (result.data > 0) {
                toast.success(messageCodeDic['001'])
                props.setModalIsOpen(false);
            } else {
                toast.error(errorCodeDic['003']);
            }
        }).catch(error => {
            toast.error(errorCodeDic['003']);
            console.log('error: ', error);
        })
            .finally(() => {
                spinner.stop();
            });

    }

    const fn_uploadImg = (gb: string) => {
        setImgTarget(gb);
        fileRef.current.click();
    }

    const fn_onfileChange = (e: any) => {
        console.log('e.target.files: ', e.target.files);
        let cstmData = data;
        if(cstmData === null){
            cstmData = {}
        }
        if (e.target.files.length > 0) {
            const file = e.target.files[0];
            const imgSrc = URL.createObjectURL(file);
            if (imgTarget == 'bf') {
                cstmData.fileTmpSrc = imgSrc;
                cstmData.bfAtchFile = file;
                cstmData.isPhotoUpload = true;
                setData(Object.assign({}, cstmData));
            } else if (imgTarget == 'af') {
                cstmData.fileAfTmpSrc = imgSrc;
                cstmData.afAtchFile = file;
                cstmData.isPhotoUpload = true;
                setData(Object.assign({}, cstmData));
            }
        }

    }

    const fn_datePopupOpen = () => {
        setIsDatePopupOpen(!isDatePopupOpen);
    };

    return (
        <div>
            <ActChrgrModal setModalIsOpen={setIsActChrgrPopOpend} modalIsOpen={isActChrgrPopOpend}
                           actChrgList={chrgrDataList} cmpnySeq={data?.cmpnySeq}
                           setActChrgList={setChrgrDataList}/>

            <Modal
                className="bmc-modal" size="xl"
                show={props.modalIsOpen}
                onShow={afterOpenModal}
                onHide={fn_closeModal}

            >
                <Modal.Header>
                    <div className="d-flex justify-content-between align-items-center w-100 bmc-font-SD">
                        부적합 등록/수정
                        <IoMdClose className='hover:cursor-pointer' onClick={fn_closeModal}/>
                    </div>
                </Modal.Header>

                <Modal.Body className="px-4">
                    <input hidden type={'file'} ref={fileRef} onChange={fn_onfileChange}/>
                    <form name={'form'} ref={formRef} encType="multipart/form-data" method={"post"}
                          onSubmit={submit}>
                        <div className="bmc-modal-div">
                            <div className="div-header">
                                부적합 정보
                            </div>

                            <div className="div-body px-3">
                                <Table className="my-2 align-middle">
                                    <tbody>
                                    <tr className="border-top align-middle">
                                        <th className="bmc-bg-th" style={{width: '15%'}}>현장</th>
                                        <td style={{width: '35%'}}>
                                            <BMCSelect initOptionGb={'none'} disabled={true} codeGb={'site'}
                                                      onChange={fn_setFormData} className={'form-control w-100'}
                                                      value={data?.siteId}></BMCSelect>
                                        </td>
                                        <th className="bmc-bg-th" style={{width: '15%'}}>업체</th>
                                        <td style={{width: '35%'}}>
                                            <BMCSelect initOptionGb={'sel'} disabled={true}
                                                      className={"form-control w-100"}
                                                      name={'cmpnySeq'} codeGb={'siteCmpny'}
                                                      onChange={fn_setFormData} selParam={{siteId: props.siteId}}
                                                      value={data?.cmpnySeq}></BMCSelect>
                                        </td>
                                    </tr>
                                    <tr className="border-top align-middle">
                                        <th className="bmc-bg-th" style={{width: '15%'}}>제목</th>
                                        <td style={{width: '35%'}}>
                                            <BMCInput type="text" className="form-control" name={'incgrtyTitle'}
                                                     onChange={fn_setFormData} value={data?.incgrtyTitle}/>
                                        </td>
                                        <th className="bmc-bg-th" style={{width: '15%'}}>조치 담당자</th>
                                        <td style={{width: '35%'}}>
                                            <div className="d-flex justify-content-between align-items-center">
                                                <BMCInput type={'text'} readOnly={true} className={'form-control'} name={'actChrgrNm'} value={chrgrDataList.length > 0 && chrgrDataList[0].userName} />

                                                <button className="bmc-btn btn-secondary min-w-[70px] ml-5"
                                                        type="button" onClick={() => setIsActChrgrPopOpend(true)}>검색
                                                </button>
                                            </div>
                                        </td>
                                    </tr>

                                    <tr className="border-top align-middle">
                                        <th className="bmc-bg-th" style={{width: '15%'}}>부적합 위치</th>
                                        <td style={{width: '35%'}}>
                                            <BMCSelect initOptionGb={'sel'} name={'incgrtyPlcCd'} className={"w-100"}
                                                      selParam={{siteId: user.blngSiteId, manageCode: 'M001004'}}
                                                      codeGb={'siteMngCd'}
                                                      onChange={fn_setFormData}
                                                      value={data?.incgrtyPlcCd}></BMCSelect>
                                        </td>
                                        <th className="bmc-bg-th" style={{width: '15%'}}>부적합 세부 위치</th>
                                        <td style={{width: '35%'}}>
                                            <BMCInput type="text" className="form-control" name={'incgrtyDtlPlc'}
                                                     onChange={fn_setFormData} value={data?.incgrtyDtlPlc}/>
                                        </td>
                                    </tr>

                                    <tr className="border-top align-middle">
                                        <th className="bmc-bg-th" style={{width: '15%'}}>부적합 내용</th>
                                        <td style={{width: '85%'}} colSpan={3}>
                                            <BMCInput type="text" className="form-control" name={'incgrtyCntnts'}
                                                     onChange={fn_setFormData} value={data?.incgrtyCntnts}/>
                                        </td>
                                    </tr>

                                    {data?.prcsStatCd && <tr className="border-top align-middle">
                                        <th className="bmc-bg-th" style={{width: '15%'}}>조치 후 의견</th>
                                        <td style={{width: '35%'}}>
                                            <BMCInput type="text" className="form-control" name={'actCmpltOpnn'}
                                                     onChange={fn_setFormData} value={data?.actCmpltOpnn}/>
                                        </td>
                                        <th className="bmc-bg-th" style={{width: '15%'}}>조치완료일</th>
                                        <td style={{width: '35%'}} ref={prodRef}>
                                            <BMCDatePicker className={"text-center"} readOnly={true}
                                                           value={actCmpltDt} name={'actCmpltDt'}
                                                           onClick={fn_datePopupOpen}
                                                           onChange={fn_setFormData}
                                                           calendar={fn_datePopupOpen}
                                            />
                                            {isDatePopupOpen
                                                ? <div className='absolute top-255 right-80'>
                                                    <CustomDatePicker setIsDatePopupOpen={setIsDatePopupOpen}
                                                                      prodRef={prodRef}
                                                                      setDateView={setActCmpltDt}
                                                    />
                                                </div>
                                                : null}
                                        </td>
                                    </tr>
                                    }
                                    </tbody>
                                </Table>
                            </div>
                        </div>


                        <div className="bmc-modal-div under mb-2">
                            <div className="div-header">
                                <div>
                                    부적합 대상
                                </div>
                            </div>
                            <div>
                                <Container className='bmc-box-form py-2 mt-3'>
                                    <Row>
                                        <Col>
                                            <span className="bmc-fw-5">조치전</span>
                                        </Col>
                                        <Col>
                                            <span className="bmc-fw-5">조치후</span>
                                        </Col>
                                    </Row>
                                    <Row>
                                        <Col>
                                            {data?.fileTmpSrc ?
                                                <img className={'cursor-pointer'}
                                                     onClick={() => fn_uploadImg('bf')}
                                                     src={data.fileTmpSrc}/>
                                                : data?.bfAtchFileId ?
                                                    <img
                                                        className={data?.prcsStatCd == 'I001001' && user.userSeq == data?.rgstrSeq ? 'cursor-pointer' : ''}
                                                        onClick={() => data?.prcsStatCd == 'I001001' && user.userSeq == data?.rgstrSeq && fn_uploadImg('bf')}
                                                        src={process.env.REACT_APP_FILE_URL + "/bmcss-data/fileDownload?attachSeq=" + data?.bfAtchFileId + '&sn=0&isPdf=0&time=' + new Date().getTime()}/>
                                                    :
                                                    <label htmlFor="input-file"
                                                           className={'bmc-btn btn-secondary w-[100px] cursor-pointer'}
                                                           onClick={() => fn_uploadImg('bf')}>
                                                        사진 업로드
                                                    </label>
                                            }


                                        </Col>
                                        <Col>
                                            {data?.fileAfTmpSrc ?
                                                <img className={'cursor-pointer'}
                                                     onClick={() => fn_uploadImg('af')}
                                                     src={data.fileAfTmpSrc}/>
                                                : data?.afAtchFileId ?
                                                    <img
                                                        className={data?.prcsStatCd == 'I001001' ? 'cursor-pointer' : ''}
                                                        onClick={() => data?.prcsStatCd == 'I001001' && fn_uploadImg('af')}
                                                        src={process.env.REACT_APP_FILE_URL + "/bmcss-data/fileDownload?attachSeq=" + data?.afAtchFileId + '&sn=0&isPdf=0&time=' + new Date().getTime()}/>
                                                    : data?.prcsStatCd == 'I001001' &&
                                                    !(chrgrDataList.filter((data: any) => {
                                                        return (data.userSeq == user.userSeq)
                                                    }).length == 0 && data?.prcsStatCd == 'I001001')
                                                    && <label htmlFor="input-file"
                                                              className={'bmc-btn btn-secondary w-[100px] cursor-pointer'}
                                                              onClick={() => fn_uploadImg('af')}>
                                                        사진 업로드
                                                    </label>
                                            }

                                        </Col>
                                    </Row>
                                </Container>
                            </div>
                        </div>

                        <div className="bmc-modal-div">
                            <div className="div-header">
                                작성정보
                            </div>
                            <div className="div-body px-3">
                                <Table className="my-2 align-middle">
                                    <tbody>
                                    <tr className="border-top align-middle">
                                        <th className="bmc-bg-th" style={{width: '15%'}}>작성일</th>
                                        <td style={{width: '35%'}}>
                                            <input value={data?.rgstDt || currentDate}
                                                   className={"form-control"}
                                                   disabled={true}/>
                                        </td>
                                        <th className="bmc-bg-th" style={{width: '15%'}}>작성자</th>
                                        <td style={{width: '35%'}}>
                                            <input className={"form-control"}
                                                   value={data?.rgstrNm || user.userName} disabled={true}/>
                                        </td>
                                    </tr>
                                    </tbody>
                                </Table>
                            </div>
                        </div>
                    </form>
                </Modal.Body>
                <Modal.Footer className="py-2">
                    <div className='flex justify-center w-100'>
                        <button
                            className='bmc-btn pill l me-3'
                            onClick={fn_closeModal}>닫기
                        </button>
                        {
                            (!(chrgrDataList.filter((data: any) => {
                                    return (data.userSeq == user.userSeq)
                                }).length == 0 && data?.prcsStatCd == 'I001001')
                                || user.userSeq == data?.rgstrSeq) && data?.prcsStatCd != 'I001002' &&
                            <button onClick={fn_registData}
                                    className='bmc-btn pill p white'>{(chrgrDataList.filter((data: any) => {
                                return (data.userSeq == user.userSeq)
                            }).length > 0 && data?.prcsStatCd == 'I001001') ? '조치완료' : '등록/수정'}
                            </button>
                        }

                    </div>
                </Modal.Footer>


            </Modal>
        </div>
    )
}

export default IncgrtyRgstModal;

