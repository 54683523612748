import React, {useEffect, useRef, useState} from 'react';
import {Col, Container, Row} from "react-bootstrap";
import {ReactComponent as IconTeam} from "../../assets/images/ico/icon-team.svg";
import {Doughnut} from "react-chartjs-2";
import {Bar} from "react-chartjs-2";
import restapi from "../../helper/restAPIHelper";
import {CmmnFn} from "../../helper/CmmnHelper";
import WrkPrmtInfoListModal from "./modal/WrkPrmtInfoListModal";

const HOCRTBMStatus = () => {
    const [totTbmCnt, setTotTbmCnt] = useState<number>(0);
    const [wrkPrmtList, setWrkPrmtList] = useState<any>([]);
    const [siteDataList, setSiteDataList] = useState<any>([]);
    const [smSiteDataList, setSmSiteDataList] = useState<any>([]);
    const [modalIsOpen, setModalIsOpen] = useState<boolean>(false);
    const [dataList, setDataList] = useState<any>([]);
    const timerRef = useRef<any>();


    const [tbm_data, setTbmData] = useState<any>({
        facility : {
            perform : 20,
            not_perform : 10,
        },
        investment : {
            perform : 5,
            not_perform : 30,
        },
        repair : {
            perform : 15,
            not_perform : 7,
        },
        normal : {
            perform : 20,
            not_perform : 5,
        }
    });
    const tbm_total = (tbm_data.facility.perform + tbm_data.facility.not_perform)+(tbm_data.investment.perform + tbm_data.investment.not_perform)+(tbm_data.repair.perform + tbm_data.repair.not_perform)+(tbm_data.normal.perform + tbm_data.normal.not_perform);

    const [facilityData, setFacilityData] = useState<any>({
        labels: ['시설현장'],
        datasets: [
            {
                label: ['수행'],
                data: [tbm_data.facility.perform],
                backgroundColor: '#4A3AFF', borderWidth: 0, hoverOffset: 5,
                borderRadius: [{ topLeft: 12, topRight: 0, bottomLeft: 12, bottomRight: 0 }],
            },
            {
                label: ['미수행'],
                data: [tbm_data.facility.not_perform],
                backgroundColor: '#49c6ff', borderWidth: 0, hoverOffset: 5,
            },
            {
                label: [''],
                data: [tbm_total - tbm_data.facility.perform - tbm_data.facility.not_perform],
                backgroundColor: '#f8f8ff', borderWidth: 0, hoverOffset: 5,
                borderRadius: [{ topLeft: 0, topRight: 12, bottomLeft: 0, bottomRight: 12 }],
            },
        ],
    });

    const [investmentData, setInvestmentData] = useState<any>({
        labels: ['출자토지'],
        datasets: [
            {
                label: ['수행'],
                data: [tbm_data.investment.perform],
                backgroundColor: '#4A3AFF', borderWidth: 0, hoverOffset: 5,
                borderRadius: [{ topLeft: 12, topRight: 0, bottomLeft: 12, bottomRight: 0 }],
            },
            {
                label: ['미수행'],
                data: [tbm_data.investment.not_perform],
                backgroundColor: '#49c6ff', borderWidth: 0, hoverOffset: 5,
            },
            {
                label: [''],
                data: [tbm_total - tbm_data.investment.perform - tbm_data.investment.not_perform],
                backgroundColor: '#f8f8ff', borderWidth: 0, hoverOffset: 5,
                borderRadius: [{ topLeft: 0, topRight: 12, bottomLeft: 0, bottomRight: 12 }],
            },
        ],
    });

    const [repairData, setRepairData] = useState<any>({
        labels: ['유지보수'],
        datasets: [
            {
                label: ['수행'],
                data: [tbm_data.repair.perform],
                backgroundColor: '#4A3AFF', borderWidth: 0, hoverOffset: 5,
                borderRadius: [{ topLeft: 12, topRight: 0, bottomLeft: 12, bottomRight: 0 }],
            },
            {
                label: ['미수행'],
                data: [tbm_data.repair.not_perform],
                backgroundColor: '#49c6ff', borderWidth: 0, hoverOffset: 5,
            },
            {
                label: [''],
                data: [tbm_total - tbm_data.repair.perform - tbm_data.repair.not_perform],
                backgroundColor: '#f8f8ff', borderWidth: 0, hoverOffset: 5,
                borderRadius: [{ topLeft: 0, topRight: 12, bottomLeft: 0, bottomRight: 12 }],
            },
        ],
    });

    const [normalData, setNormalData] = useState<any>({
        labels: ['일반현장'],
        datasets: [
            {
                label: ['수행'],
                data: [tbm_data.normal.perform],
                backgroundColor: '#4A3AFF', borderWidth: 0, hoverOffset: 5,
                borderRadius: [{ topLeft: 12, topRight: 0, bottomLeft: 12, bottomRight: 0 }],
            },
            {
                label: ['미수행'],
                data: [tbm_data.normal.not_perform],
                backgroundColor: '#49c6ff', borderWidth: 0, hoverOffset: 5,
            },
            {
                label: [''],
                data: [tbm_total - tbm_data.normal.perform - tbm_data.normal.not_perform],
                backgroundColor: '#f8f8ff', borderWidth: 0, hoverOffset: 5,
                borderRadius: [{ topLeft: 0, topRight: 12, bottomLeft: 0, bottomRight: 12 }],
            },
        ],
    });

    const options = {
        indexAxis: 'y' as const,
        responsive: false,
        plugins: {
            legend: {
                display: false
            },
        },
        scales: {
            x: {
                stacked: true,
                grid: {
                    display: false,
                },
                ticks:{
                    display: false,
                },
                border:{
                    display: false
                }
            },
            y: {
                stacked: true,
                grid: {
                    display: false,
                },
                ticks:{
                    font:{
                        size: 15,
                        weight: 'bold'
                    }
                },
                border:{
                    display: false
                }
            }
        }
    };

    useEffect(() => {
        setNormalData({
            labels: ['일반현장'],
            datasets: [
                {
                    label: ['수행'],
                    data: [tbm_data.normal.perform],
                    backgroundColor: '#4A3AFF', borderWidth: 0, hoverOffset: 5,
                    borderRadius: [{ topLeft: 12, topRight: 0, bottomLeft: 12, bottomRight: 0 }],
                },
                {
                    label: ['미수행'],
                    data: [tbm_data.normal.not_perform],
                    backgroundColor: '#49c6ff', borderWidth: 0, hoverOffset: 5,
                },
                {
                    label: [''],
                    data: [tbm_total - tbm_data.normal.perform - tbm_data.normal.not_perform],
                    backgroundColor: '#f8f8ff', borderWidth: 0, hoverOffset: 5,
                    borderRadius: [{ topLeft: 0, topRight: 12, bottomLeft: 0, bottomRight: 12 }],
                },
            ],
        });
        setRepairData({
            labels: ['유지보수'],
            datasets: [
                {
                    label: ['수행'],
                    data: [tbm_data.repair.perform],
                    backgroundColor: '#4A3AFF', borderWidth: 0, hoverOffset: 5,
                    borderRadius: [{ topLeft: 12, topRight: 0, bottomLeft: 12, bottomRight: 0 }],
                },
                {
                    label: ['미수행'],
                    data: [tbm_data.repair.not_perform],
                    backgroundColor: '#49c6ff', borderWidth: 0, hoverOffset: 5,
                },
                {
                    label: [''],
                    data: [tbm_total - tbm_data.repair.perform - tbm_data.repair.not_perform],
                    backgroundColor: '#f8f8ff', borderWidth: 0, hoverOffset: 5,
                    borderRadius: [{ topLeft: 0, topRight: 12, bottomLeft: 0, bottomRight: 12 }],
                },
            ],
        })
        setFacilityData({
            labels: ['시설현장'],
            datasets: [
                {
                    label: ['수행'],
                    data: [tbm_data.facility.perform],
                    backgroundColor: '#4A3AFF', borderWidth: 0, hoverOffset: 5,
                    borderRadius: [{ topLeft: 12, topRight: 0, bottomLeft: 12, bottomRight: 0 }],
                },
                {
                    label: ['미수행'],
                    data: [tbm_data.facility.not_perform],
                    backgroundColor: '#49c6ff', borderWidth: 0, hoverOffset: 5,
                },
                {
                    label: [''],
                    data: [tbm_total - tbm_data.facility.perform - tbm_data.facility.not_perform],
                    backgroundColor: '#f8f8ff', borderWidth: 0, hoverOffset: 5,
                    borderRadius: [{ topLeft: 0, topRight: 12, bottomLeft: 0, bottomRight: 12 }],
                },
            ],
        })
        setInvestmentData({
            labels: ['출자토지'],
            datasets: [
                {
                    label: ['수행'],
                    data: [tbm_data.investment.perform],
                    backgroundColor: '#4A3AFF', borderWidth: 0, hoverOffset: 5,
                    borderRadius: [{ topLeft: 12, topRight: 0, bottomLeft: 12, bottomRight: 0 }],
                },
                {
                    label: ['미수행'],
                    data: [tbm_data.investment.not_perform],
                    backgroundColor: '#49c6ff', borderWidth: 0, hoverOffset: 5,
                },
                {
                    label: [''],
                    data: [tbm_total - tbm_data.investment.perform - tbm_data.investment.not_perform],
                    backgroundColor: '#f8f8ff', borderWidth: 0, hoverOffset: 5,
                    borderRadius: [{ topLeft: 0, topRight: 12, bottomLeft: 0, bottomRight: 12 }],
                },
            ],
        })
    }, [tbm_data]);



    useEffect(() => {
        fn_retrieveData();
        //timerRef.current = setInterval(fn_retrieveData, 5000);

    }, []);


    const fn_retrieveData = () => {
        restapi.retrieveWrkPrmtDataTotList({wrkDt: CmmnFn.fn_getCurrentDate()}).then((rsltData: any) => {
            setWrkPrmtList(rsltData);
            console.log('wrkPrmt rsltData: ', rsltData);

            const siteDataList = rsltData.filter((data: any) => (!data.isSmSite));
            const smSiteDataList = rsltData.filter((data: any) => (data.isSmSite));
            const cmpltdSiteDataList = siteDataList.filter((data: any) => (data.isWrkCmpltd));
            const cmpltdSmSiteDataList  = smSiteDataList.filter((data: any) => (data.isWrkCmpltd));

            setSiteDataList(siteDataList);
            setSmSiteDataList(smSiteDataList);

            console.log('siteDataList: ', siteDataList);

            console.log('cmpltdSiteDataList: ', cmpltdSiteDataList);
            console.log('cmpltdSmSiteDataList: ', cmpltdSmSiteDataList);

            /** 전체현장 **/
            /* 일반공사현장 */
            const nrmlSite = siteDataList.filter((data: any) => (data.siteType === 'T002001'));
            /* 유지보수 */
            const mntncSite = smSiteDataList;
            /* 관리시설 */
            const mngFcrtSite = siteDataList.filter((data: any) => (data.siteType === 'T002004'));
            /* 출자토지 */
            const erthSite = siteDataList.filter((data: any) => (data.siteType === 'T002003'));

            /** 완료 **/
            /* 일반공사현장 */
            const cmpltdNrmlSite = cmpltdSiteDataList.filter((data: any) => (data.siteType === 'T002001'));
            const cmpltdMntncSite = cmpltdSmSiteDataList;
            const cmpltdMngFcrtSite = cmpltdSiteDataList.filter((data: any) => (data.siteType === 'T002004'));
            const cmpltdErthSite = cmpltdSiteDataList.filter((data: any) => (data.siteType === 'T002003'));

            setTbmData({
                normal : {
                    perform : cmpltdNrmlSite.length,
                    not_perform : nrmlSite.length - cmpltdNrmlSite.length,
                },
                repair : {
                    perform : cmpltdMntncSite.length,
                    not_perform : mntncSite.length - cmpltdMntncSite.length ,
                },
                facility : {
                    perform : cmpltdMngFcrtSite.length,
                        not_perform : mngFcrtSite.length - cmpltdMngFcrtSite.length,
                },
                investment : {
                    perform : cmpltdErthSite.length,
                        not_perform : erthSite.length - cmpltdErthSite.length,
                },


            })



            /*setSiteData({
                labels: ['수행', '미수행'],
                datasets: [
                    {
                        data: [cmpltdSiteDataList.length, siteDataList.length - cmpltdSiteDataList.length],
                        backgroundColor: [
                            '#4A3AFF',
                            '#93AAFD',
                        ],
                        borderColor: [
                            'rgba(30, 31, 35, 1)',
                            'rgba(255, 255, 255, 1)',
                        ],
                        borderWidth: 1,
                        hoverOffset: 5,
                    },
                ],
            });

            setSmallsiteData({
                labels: ['수행', '미수행'],
                datasets: [
                    {
                        data: [cmpltdSmSiteDataList.length, smSiteDataList.length - cmpltdSmSiteDataList.length],
                        backgroundColor: [
                            '#4A3AFF',
                            '#93AAFD',
                        ],
                        borderColor: [
                            'rgba(255, 255, 255, 1)',
                            'rgba(255, 255, 255, 1)',
                        ],
                        borderWidth: 1,
                        hoverOffset: 5,
                    },
                ],
            })*/


        });
    }

    const fn_openWrkPrmtInfoListModal = (srchGb: string) => {
        if(srchGb == 'tot'){
            setDataList(wrkPrmtList);
        }else if(srchGb == 'site'){
            setDataList(siteDataList)
        }else if(srchGb == 'smSite'){
            setDataList(smSiteDataList)
        }else if(srchGb == 'T002001'){
            setDataList(siteDataList.filter((data: any) => (data.siteType === 'T002001')))
        }else if(srchGb == 'T002004'){
            setDataList(siteDataList.filter((data: any) => (data.siteType === 'T002004')))
        }else if(srchGb == 'T002003'){
            setDataList(siteDataList.filter((data: any) => (data.siteType === 'T002003')))
        }

        setModalIsOpen(true);
    }

    return(
        <>
            <WrkPrmtInfoListModal setModalIsOpen={setModalIsOpen} modalIsOpen={modalIsOpen} dataList={dataList} />
            <Container fluid className="bmc-bg-pane">
                <Row>
                    <Col>
                        <div className="d-flex flex-column justify-content-between">
                            <div className="w-100 d-flex align-items-center justify-content-between pb-2 border-bottom">
                                <span className="bmc-txt-size-h4">TBM 현황</span>
                            </div>
                        </div>
                    </Col>
                </Row>
                <Row className="pt-1">
                    <Col>
                        <div className="d-flex flex-column justify-content-between">
                            <div className="w-100 d-flex align-items-center justify-content-between pb-1" style={{borderBottom: '1px dashed #dee2e6'}}>
                                <span className="bmc-txt-size-b5">TBM 전체 건수</span>
                                <div className="text-end"><span className="bmc-fs-h3 bmc-fw-6 me-1">{/*{wrkPrmtList.length}*/}{tbm_total}</span>건</div>
                            </div>
                        </div>
                    </Col>
                </Row>
                <Row className="pt-1">
                    <Col>
                        <div className="d-flex flex-column justify-content-between">
                            <div>
                                <Row>
                                    <Col>
                                        {/* 일반현장 */}
                                        <div onClick={() => {fn_openWrkPrmtInfoListModal('T002001')}} className="cursor-pointer d-flex justify-content-between align-items-center">
                                            <Bar className="z-50" options={options}
                                                 data={normalData} width={320} height={46} />
                                            <div className="text-right" style={{color: '#615E83'}}>
                                                <span className="bmc-txt-color-primary bmc-txt-size-h5 me-1">{tbm_data.normal.perform + tbm_data.normal.not_perform}</span>
                                            </div>
                                        </div>
                                        {/* 유지보수 */}
                                        <div onClick={() => {fn_openWrkPrmtInfoListModal('smSite')}} className="cursor-pointer d-flex justify-content-between align-items-center">
                                            <Bar className="z-50" options={options}
                                                 data={repairData} width={320} height={46} />
                                            <div className="text-right" style={{color: '#615E83'}}>
                                                <span className="bmc-txt-color-primary bmc-txt-size-h5 me-1">{tbm_data.repair.perform + tbm_data.repair.not_perform}</span>
                                            </div>
                                        </div>
                                        {/* 시설현장 */}
                                        <div onClick={() => {fn_openWrkPrmtInfoListModal('T002004')}} className="cursor-pointer d-flex justify-content-between align-items-center">
                                            <Bar className="z-50" options={options}
                                                      data={facilityData} width={320} height={46} />
                                            <div className="text-right" style={{color: '#615E83'}}>
                                                <span className="bmc-txt-color-primary bmc-txt-size-h5 me-1">{tbm_data.facility.perform + tbm_data.facility.not_perform}</span>
                                            </div>
                                        </div>
                                        {/* 출자토지 */}
                                        <div onClick={() => {fn_openWrkPrmtInfoListModal('T002003')}} className="cursor-pointer d-flex justify-content-between align-items-center">
                                            <Bar className="z-50" options={options}
                                                 data={investmentData} width={320} height={46} />
                                            <div className="text-right" style={{color: '#615E83'}}>
                                                <span className="bmc-txt-color-primary bmc-txt-size-h5 me-1">{tbm_data.investment.perform + tbm_data.investment.not_perform}</span>
                                            </div>
                                        </div>
                                        <div className="mt-1 d-flex align-items-center justify-content-center bmc-txt-size-b6" >
                                            <div style={{width: '75px'}}>
                                                <span style={{color: '#4A3AFF'}}>●</span>
                                                <span className="ps-1">수행</span>
                                            </div>
                                            <div style={{width: '75px'}}>
                                                <span style={{color: '#49c6ff'}}>●</span>
                                                <span className="ps-1">미수행</span>
                                            </div>
                                        </div>
                                    </Col>
                                </Row>
                            </div>
                        </div>
                    </Col>
                </Row>
            </Container>
        </>
    );
}

export default HOCRTBMStatus;
