import React, {Dispatch, SetStateAction, useContext, useState} from "react";
import {ProgressContext} from "../../../context/Progress";
import {getLoginUser} from "../../../helper/SecurityHelper";
import {validFormData} from "../../../helper/FormHelper";
import axios from "axios";
import {toast} from "react-toastify";
import messageCodeDic from "../../../util/messageCodeDic";
import errorCodeDic from "../../../util/errorCodeDic";
import restapi from "../../../helper/restAPIHelper";
import {Modal, Table} from "react-bootstrap";
import {IoMdClose} from "react-icons/io";
import BMCInput from "../../../components/common/BMCInput";
import BMCTextarea from "../../../components/common/BMCTextarea";
import BMCFile from "../../../components/common/BMCFile";
import {CmmnFn} from "../../../helper/CmmnHelper";

interface IProps {
    setModalIsOpen: Dispatch<SetStateAction<boolean>>;
    modalIsOpen: boolean;
    detailData: any;
}

const SafetyManagementGeneralBulletinBoardModal = (props:IProps) => {
    const [data, setData] = useState<any>();
    const [repFileSn, setRepFileSn] = useState<any>(null);
    const [fileList, setFileList] = useState([]);
    const formRef = React.createRef<HTMLFormElement>();
    const {spinner} = useContext(ProgressContext);
    const user: any = getLoginUser();

    const fn_updateData = (e: any) => {
        if (e.target.type == 'checkbox') {
            setData({
                ...data,
                [e.target.name]: e.target.checked
            })
            console.log(e.target.checked)
        } else {
            setData({
                ...data,
                [e.target.name]: e.target.value
            })
        }
    }

    const fn_registData = () => {
        var option = {
            addValues: {
                regSeq: user.userSeq,
                updateSeq: user.userSeq,
            },
            validOpt: {
                title: '제목을 입력해 주십시오.',
                contents: '내용을 입력해 주십시오.',
            },
            files: ['files']
        }

        var formData: any = validFormData(formRef, option)
        if(!formData){
            return;
        }

        if(fileList.length > 0){
            formData.set('repFileSn', repFileSn);
        }

        if (formData) {
            if (!window.confirm('저장 하시겠습니까?')) {
                return;
            }

            spinner.start();
            axios.post('/admin/api/sftyMngGnrlblltnBrd/saveSftyMngGnrlblltnBrdData', formData).then((result) => {

                if (result.data > 0) {
                    toast.success(messageCodeDic['001'])
                    props.setModalIsOpen(false);
                } else {
                    toast.error(errorCodeDic['003']);
                }
            }).catch(error => {
                toast.error(errorCodeDic['003']);
                console.log('error: ', error);
            }).finally(() => {
                spinner.stop();
            });
        }
    }

    const fn_openModal = () => {
        console.log(props.detailData)
        if(!props.detailData){
            setFileList([]);
            setData(null);
        }else{
            setData(props.detailData);
            if(props.detailData.atchFileId){
                restapi.retrieveAttachInfoList({attachSeq: props.detailData.atchFileId}).then((rsltData: any) => {
                    if (props.detailData?.repfileSn !== null && props.detailData?.repfileSn !== undefined) {
                        rsltData.map((data: any, idx: number) => {
                            if (data.sn == props.detailData.repfileSn) {
                                data.isRep = true
                                setRepFileSn(idx);
                            }
                        })
                    }

                    setFileList([].concat(rsltData));
                })
            }else{
                setFileList([]);
            }
        }
    }

    const fn_closeModal = () => {
        props.setModalIsOpen(false);
    }

    const submit = (e: any) => {
        e.preventDefault()

    }

    return (
        <div>
            <Modal
                className="bmc-modal" size="xl"
                show={props.modalIsOpen}
                onShow={fn_openModal}
                onHide={fn_closeModal}
            >

                <Modal.Header>
                    <div className="d-flex justify-content-between align-items-center w-100 bmc-font-SD">
                        안전교육컨텐츠 등록/수정
                        <IoMdClose className='hover:cursor-pointer' onClick={fn_closeModal}/>
                    </div>
                </Modal.Header>
                <Modal.Body className="px-4">
                    <form name={'detailForm'} ref={formRef} encType="multipart/form-data" method={"post"} onSubmit={submit}>
                        <BMCInput type={'hidden'} value={data?.atchFileId} name={'atchFileId'}></BMCInput>
                        <BMCInput type={'hidden'} value={data?.sftyMngGnrlblltnBrdId} name={'sftyMngGnrlblltnBrdId'}></BMCInput>
                        <div className="bmc-modal-div">
                            <div className="div-header">
                                안전교육컨텐츠 정보
                            </div>
                            <div className="div-body px-3">
                                <Table className="my-2 align-middle">
                                    <tbody>
                                    <tr className="border-top align-middle">
                                        <th className="bmc-bg-th" style={{width: '15%'}}>작성자</th>
                                        <td style={{width: '35%'}}>
                                            <BMCInput type="text" className="form-control" name={'userName'}
                                                     onChange={fn_updateData}
                                                     value={data?.userName ? data?.userName : user.userName}
                                                     disabled={true}/>
                                        </td>
                                        <th className="bmc-bg-th" style={{width: '15%'}}>작성일시</th>
                                        <td style={{width: '35%'}}>
                                            <BMCInput type="text" className="form-control" name={'rgstDt'}
                                                     onChange={fn_updateData} value={data?.rgstDt || CmmnFn.fn_getCurrentDate()} disabled={true}/>
                                        </td>
                                    </tr>
                                    <tr className="border-top align-middle">
                                        <th className="bmc-bg-th" style={{width: '15%'}}>제목</th>
                                        <td colSpan={3}>
                                            <div className="d-flex gap-2">
                                                <div className="d-flex align-items-center flex-grow-1">
                                                    <BMCInput type="text" className="form-control" name={'title'}
                                                             onChange={fn_updateData} value={data?.title} placeholder={'제목을 입력해주세요.'}/>
                                                </div>
                                                <div className="d-flex align-items-center gap-1 flex-grow-2">
                                                    <input className="form-check-input bmc-check" id={'isFixedTop'} checked={data?.isFixedTop == true || data?.isFixedTop == 't' ? true : false}
                                                           name={'isFixedTop'} type="checkbox" onChange={fn_updateData}/>
                                                    <label className={'form-check-label text-black'}
                                                           htmlFor={'isFixedTop'}>상단고정</label>
                                                </div>
                                            </div>
                                        </td>
                                    </tr>
                                    <tr className="border-top align-middle">
                                        <th className="bmc-bg-th" style={{width: '15%'}}>내용</th>
                                        <td style={{width: '85%'}} colSpan={3}>
                                            <BMCTextarea rows={5} onChange={fn_updateData} name={'contents'}
                                                        value={data?.contents}
                                                        className={"form-control"}
                                                        placeholder='내용을 입력해주세요.'/>
                                        </td>
                                    </tr>
                                    <tr className="align-middle" style={{width: '15%'}}>
                                        <th className="bmc-bg-th">첨부파일</th>
                                        <td colSpan={3}>
                                            <BMCFile fileName={'files'} fileList={fileList} setFileList={setFileList}
                                                    repFileSn={repFileSn} setRepFileSn={setRepFileSn}></BMCFile>
                                        </td>
                                    </tr>
                                    </tbody>
                                </Table>
                            </div>
                        </div>
                    </form>
                </Modal.Body>

                <Modal.Footer className="py-2">
                    <div className='flex justify-center w-100'>
                        <button
                            className='bmc-btn pill l white me-3'
                            onClick={fn_closeModal}>닫기
                        </button>
                        <button onClick={fn_registData}
                                className='bmc-btn pill p'>등록/수정
                        </button>
                    </div>
                </Modal.Footer>

            </Modal>
        </div>
    )
}

export default SafetyManagementGeneralBulletinBoardModal;
