import React, {useContext, useEffect, useRef, useState} from 'react';
import {Container} from "react-bootstrap";
import {ReactComponent as IconClock} from '../../assets/images/icon/icon-clock.svg';
import {ReactComponent as IconWeatherSample} from '../../assets/images/icon/icon-weather-sample.svg';
import {ReactComponent as IconWeatherline} from '../../assets/images/icon/icon-weather-line.svg';
import {ReactComponent as IconDustSample1} from '../../assets/images/icon/icon-dust-sample1.svg';
import {ReactComponent as IconDustSample2} from '../../assets/images/icon/icon-dust-sample2.svg';
import restapi from '../../helper/restAPIHelper';

import IconWthrShiny from '../../assets/images/icon/icon_weather_shiny.svg';
import IconWthrCloudy from '../../assets/images/icon/icon-weather-cloudy.svg';
import IconWthrRainy from '../../assets/images/icon/icon_weather_rainy.svg';
import {IntervalContext} from "../../context/Interval";


const HOTotalWeatherInfoV2 = () => {

    const [date, setDate] = useState(() => new Date());
    const week = ['Sun', 'Mon', 'Tue', 'Wed', 'Thu', 'Fri', 'Sat'];
    let dayOfWeek = week[date.getDay()];
    const [wthrInfo, setWthrInfo] = useState<any>({});
    const [pm10Grade, setPm10Grade] = useState<string|null>(null);
    const [pm10Color, setPm10Color] = useState<any|null>(null);
    const [pm25Grade, setPm25Grade] = useState<string|null>(null);
    const [pm25Color, setPm25Color] = useState<any|null>(null);
    const {interval} = useContext<any>(IntervalContext);

    useEffect(() => {
        const timeId = setInterval(() => tick(), 1000)
        // 날씨 정보 가져오기
        restapi.retrieveWtherInfoData().then((rsltData) => {
            if(rsltData){
                console.log('weather rsltData: ', rsltData);
                setWthrInfo(rsltData);
            }
        })


    }, [])

    const tick = () => {
        setDate(new Date())
    }

    useEffect(() => {
        var pm10Value = wthrInfo.pm10
        if(10 <= pm10Value && pm10Value <= 30){
            setPm10Grade("좋음")
            setPm10Color("#4C5EFF")
        }else if(31 <= pm10Value && pm10Value <= 80){
            setPm10Grade("보통")
            setPm10Color("#00AEFF")
        }else if(81 <= pm10Value && pm10Value <= 150){
            setPm10Grade("나쁨")
            setPm10Color("#f9c700")
        }else if(151 <= pm10Value){
            setPm10Grade("매우나쁨")
            setPm10Color("#e53030")
        }
        var pm25Value = wthrInfo.pm25
        if(10 <= pm25Value && pm25Value <= 15){
            setPm25Grade("좋음")
            setPm25Color("#4C5EFF")
        }else if(16 <= pm25Value && pm25Value <= 35){
            setPm25Grade("보통")
            setPm25Color("#00AEFF")
        }else if(36 <= pm25Value && pm25Value <= 75){
            setPm25Grade("나쁨")
            setPm25Color("#f9c700")
        }else if(75 <= pm25Value){
            setPm25Grade("매우나쁨")
            setPm25Color("#e53030")
        }
    }, [wthrInfo])


    return(
        <Container fluid className="bmc-bg-pane weather-info">
            <div className="d-flex flex-cloumn justify-content-between mb-3">
                <div className="d-flex">
                    <span className="weather-info-date align-self-center">{date.toLocaleDateString()} {dayOfWeek}</span>
                </div>
                <div className="d-flex gap-2">
                    <IconClock width={30} height={30} className="align-self-center"/>
                    <span className="weather-info-time align-self-center">{date.toLocaleTimeString()}</span>
                </div>
            </div>
            <div className="d-flex flex-cloumn justify-content-between align-items-center mb-3">
                <div className="d-flex gap-2 ps-3 pe-4 flex-grow-1">
                    {[1, 3, 4].includes(wthrInfo.sky) &&
                        <img style={{height: '100%'}}
                             src={wthrInfo.sky == 1 ? IconWthrShiny : wthrInfo.sky == 3 ? IconWthrCloudy : wthrInfo.sky == 4 ? IconWthrRainy : '-'}/>
                    }
                    <span
                        className="align-self-center weather-info-temperature">{wthrInfo.t1h !== undefined && wthrInfo.t1h !== null ? (wthrInfo.t1h + '°') : '-'}</span>
                </div>
                <div className="px-3">
                    <IconWeatherline width={5}height={30}/>
                </div>
                <div className="d-flex gap-2 flex-grow-1">
                    <div className="d-flex flex-column">
                        <span className="weather-info-summery">{wthrInfo.sky == 1 ? '맑음' : wthrInfo.sky == 3 ? '구름많음' : wthrInfo.sky == 4 ? '흐림' : '-'}</span>
                        <span className="weather-info-explanation">{wthrInfo.t1h ? '어제보다 1° 낮아요' : ''}</span>
                    </div>
                </div>
            </div>
            <div className="d-flex justify-content-between align-items-center row px-2 py-1">
                <div className="col-3 px-1">
                    <div className="d-flex flex-column align-items-center flex-grow-1 weather-info-item py-1">
                        <span style={{fontSize:"1.2em"}} className={"mb-1"}>풍속</span>
                        <span style={{fontSize:"1.2em"}} className="weather-info-item-value">{(wthrInfo.wsd !== undefined && wthrInfo.wsd !== null ) ? (wthrInfo.wsd + 'm/s') : '-'}</span>
                    </div>
                </div>
                <div className="col-3 px-1">
                    <div className="d-flex flex-column align-items-center flex-grow-1 weather-info-item py-1">
                        <span style={{fontSize:"1.2em"}} className={"mb-1"}>습도</span>
                        <span style={{fontSize:"1.2em"}} className="weather-info-item-value">{(wthrInfo.reh !== undefined && wthrInfo.reh !== null) ? (wthrInfo.reh + '%') : '-'}</span>
                    </div>
                </div>
                <div className="col-3 px-1">
                    <div className="d-flex flex-column align-items-center flex-grow-1 weather-info-item py-1">
                        <span style={{fontSize:"1.2em"}} className={"mb-1"}>미세먼지</span>
                        <div className="d-flex flex-row gap-1">
                            <span style={{fontSize:"1.2em", color:pm10Color}} className="weather-info-item-value">{pm10Grade}</span>
                            {pm10Grade == '좋음' ? <IconDustSample2 width={18} height={18} className="align-self-center"/> :
                                <IconDustSample1 width={20} height={20} className="align-self-center"/>
                            }

                        </div>
                    </div>
                </div>
                <div className="col-3 px-1">
                    <div className="d-flex flex-column align-items-center flex-grow-1 weather-info-item py-1">
                        <span style={{fontSize:"1.2em"}} className={"mb-1"}>초미세먼지</span>
                        <div className="d-flex flex-row gap-1">
                            <span style={{fontSize:"1.2em",color:pm25Color}} className="weather-info-item-value">{pm25Grade}</span>
                            {pm25Grade == '좋음' ? <IconDustSample2 width={18} height={18} className="align-self-center"/> :
                                <IconDustSample1 width={20} height={20} className="align-self-center"/>
                            }
                        </div>
                    </div>
                </div>

            </div>
        </Container>
    );
}

export default HOTotalWeatherInfoV2;
