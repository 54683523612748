import React, {useContext, useEffect, useRef, useState} from 'react';
import SubMenuTitle from "../component/SubMenuTitle";
import {Col, Container, Row, Table } from 'react-bootstrap';
import SiteManageRgstModal from "../headManage/modal/SiteManageRgstModal";
import SHSearch from "../component/BMCSearch";
import BMCSelect from "../../components/common/BMCSelect";
import Datatables, {getCheckedItems} from "../component/Datatables";
import BMCIconButton from "../../components/common/BMCIconButton";
import {getLoginUser} from "../../helper/SecurityHelper";
import {toast} from "react-toastify";
import messageCodeDic from "../../util/messageCodeDic";
import restapi from "../../helper/restAPIHelper";
import axios from "axios";
import errorCodeDic from "../../util/errorCodeDic";
import {ProgressContext} from "../../context/Progress";
import BMCDatePicker2 from "../../components/common/BMCDatePicker2";
import {DateHelper} from "../../helper/DateHelper";
import FcltySiteRgstModal from "./modal/FcltySiteRgstModal";

type Props = {};

const FcltyMngDtlPage = (props: Props) => {

    const [modalIsOpen, setModalIsOpen] = React.useState(false);
    const searchFormRef = useRef<any>();
    const [datatable, setDatatable] = useState<any>();
    const [guCd, setGuCd] = useState<any>();
    const [detailData, setDetailData] = useState(null);
    const {spinner} = useContext(ProgressContext);
    const user = getLoginUser();
    const prodRef = useRef<any>();
    const [srchStrtDt, setSrchStrtDt] = useState<Date>(DateHelper.dateByMonth(-3));
    const [srchEndDate, setSrchEndDate] = useState<Date>(DateHelper.dateByMonth(0));
    const srchTxtRef = useRef<any>();
    const [data, setData] = useState<any>();


    useEffect(() => {
        if (!modalIsOpen) {
            datatable?.draw();
        }
    }, [modalIsOpen]);

    const fn_search = (e?: any) => {
        if(e){
            e.preventDefault()
        }
        if(datatable){
            datatable.row( this ).remove().draw( false );
        }
    }

    const fn_setFormData = (e: any) => {
        setData({
            ...data,
            [e.target.name]: e.target.value
        })
    }

    /**
     * 등록 버튼 클릭
     */
    const fn_registPopOpen = () => {
        setDetailData(null);
        setModalIsOpen(true);
    }

    const fn_delete = () => {
        let checkedData = getCheckedItems(datatable,"siteId")
        if (checkedData.length == 0) {
            toast.warn(messageCodeDic['005']);
            return;
        }

        spinner.start();
        restapi.retrieveIsInUseSite({siteIds: checkedData}).then((isInUse: any) => {
            if(isInUse){
                toast.warn('현재 사용자가 사용중인 현장은 삭제할 수 없습니다.');
                return;
            }else{
                if(!window.confirm(messageCodeDic['004'])){
                    return;
                }
                spinner.start();
                axios.post('/admin/api/site/deleteSiteData', {delList: checkedData}).then(rsltData => {
                    if (rsltData.data > 0) {
                        toast.success(messageCodeDic['002'])
                        fn_search();
                    } else {
                        toast.error(errorCodeDic['003'])
                    }
                }).catch(() => {
                    toast.error(errorCodeDic['003'])
                }).finally(() => {
                    spinner.stop();
                });

            }
        }).catch(() => {
            toast.error(errorCodeDic['003'])
        }).finally(() => {
            spinner.stop();
        });
    }

    return (
        <Container fluid className="bg-white px-0 bmc-txt-color-primary">
            <FcltySiteRgstModal setModalIsOpen={setModalIsOpen} modalIsOpen={modalIsOpen} detailData={detailData} />
            <SubMenuTitle title={'시설물 관리 상세'} parentMenu={'시설물 관리 상세'} />
            <div className="p-4 overflow-y-auto" style={{height: 'calc(100vh - 197px)'}}>
                <SHSearch datatable={datatable} formRef={searchFormRef} btnType={'p'}>
                    <Row>
                        <Col>
                            <Table className="bmc-font-SP bmc-fs-b2 mt-3 mb-3">
                                <tbody>
                                <tr className="border-top border-bottom align-middle">
                                    <th className="bmc-bg-th" style={{width:'10%'}}>기간검색</th>
                                    <td style={{width:'25%'}}>
                                        <div className="d-flex flex-row gap-2">
                                            <div className="d-flex align-items-center" ref={prodRef}>
                                                <BMCDatePicker2 name={'srchStrtDt'} readOnly={true} defaultValue={srchStrtDt} onChange={fn_setFormData} />
                                                <span>&nbsp;~&nbsp;</span>
                                                <BMCDatePicker2 name={'srchEndDt'} readOnly={true} defaultValue={srchEndDate} onChange={fn_setFormData} />
                                            </div>

                                        </div>
                                    </td>
                                    <th className="bmc-bg-th" style={{width:'10%'}}>검색어</th>
                                    <td style={{width:'30%'}}>
                                        <input type={'text'} name={'srchTxt'} className='form-control' ref={srchTxtRef}/>
                                    </td>
                                </tr>
                                </tbody>
                                {/*<BMCSelect initOptionGb={'all'} className={'form-select'} name={'guCd'} codeGb={'bjd'} codeGrpId={'BUSAN'} value={guCd} onChange={(e)=>{ setGuCd(e.target.value) }}></BMCSelect>
                                <BMCSelect initOptionGb={'all'} className={'form-select'} name={'dongCd'} codeGb={'bjd-dong'} codeGrpId={'BUSAN'} selParam={{guCd:guCd}}></BMCSelect>*/}
                            </Table>
                        </Col>
                    </Row>
                </SHSearch>
                <div className={'ge-result'}>
                    <Datatables
                        dataUrl={'/admin/api/site/retrieveFcltyDataList'}
                        parameterFormRef={searchFormRef}
                        columns={[
                            {data: "fcltyNo", title: "시설물번호", textAlign: 'center'},
                            {data: "siteName", title:"시설물명", textAlign: 'center'},
                            {data: "guNm", title: "구", textAlign: 'center',render: (data: any, type: any, row: any) => {
                                    if(!data){
                                        return data;
                                    }else{
                                        let n = data.split(" ");
                                        return n[n.length - 1];
                                    }
                                }
                            },
                            {data: "siteAddress", title: "주소", textAlign: 'center'},
                            {data: "regDate", title: "등록 일자", textAlign: 'center'},
                        ]}
                        checked={true}
                        onLoad={(dt: any) => {
                            setDatatable(dt)
                        }}
                        onClick={(data) => {
                            setDetailData(Object.assign({},data))
                            setModalIsOpen(true);
                        }}
                    >
                        <div className='w-100 d-flex justify-content-end align-items-center mb-2'>

                            <BMCIconButton kind={'submit'} marginRight={'5px'} onClick={fn_registPopOpen}> 등록
                            </BMCIconButton>

                            <BMCIconButton kind={'delete'} marginRight={'0'} onClick={fn_delete}> 삭제
                            </BMCIconButton>

                        </div>
                    </Datatables>
                </div>
            </div>
        </Container>
    );
};

export default FcltyMngDtlPage;
