import React, {Dispatch, SetStateAction, useContext, useEffect, useRef, useState} from "react";
import {Modal, Table} from "react-bootstrap";
import {IoMdClose} from "react-icons/io";
import axios from "axios";
import {toast} from "react-toastify";
import {getLoginUser} from "../../../helper/SecurityHelper";

import {ProgressContext} from "../../../context/Progress";
import messageCodeDic from "../../../util/messageCodeDic";

interface IProps {
    setModalIsOpen: Dispatch<SetStateAction<boolean>>;
    modalIsOpen: boolean;
    detailData: any;
    dataList?: any;

}
export default function CctvViewerModal(props: IProps) {
    const user = getLoginUser();
    const [cctvData, setCctvData] = useState<any>({});
    const {spinner} = useContext(ProgressContext);
    const playImage = useRef<any>(null);
    const [cctvDataList, setCctvDataList] = useState<any>([]);

    const fn_onAfterShow = () => {
        if(!props.detailData) {
            setCctvData({});
            setCctvDataList([])
        }else{
            setCctvData(props.detailData)
            if(props.dataList){
                setCctvDataList(props.dataList)
            }
        }
    }
    function fn_closeModal() {
        setCctvData(null);
        //window.cc = playImage.current
        //playImage.current.destory()
        playImage.current.src = ""
        props.setModalIsOpen(false);
    }

    const fn_nextCctv = (direction: string) => {
        let cctvIdx;

        console.log('cctvData: ', cctvData);
        if(direction == 'left'){
            if(cctvData.idx == 0){
                cctvIdx = cctvDataList.length - 1;
            }else{
                cctvIdx = cctvData.idx - 1;
            }
        }else if(direction == 'right'){
            if(cctvData.idx == cctvDataList.length -1){
                cctvIdx = 0;
            }else{
                cctvIdx = cctvData.idx + 1;
            }
        }
        console.log('cctvDataList: ', cctvDataList);
        cctvDataList[cctvIdx].idx = cctvIdx;
        let cstmCctvData = cctvDataList[cctvIdx];
        setCctvData(cstmCctvData);
    }

    return (
        <div>
            <Modal
                className="bmc-modal  sub bmc-modal-800"
                show={props.modalIsOpen}
                onShow={fn_onAfterShow}
                onHide={fn_closeModal}
            >
                <Modal.Header>
                    <div className="d-flex justify-content-between align-items-center w-100 bmc-font-SD">
                        {cctvData?.name} CCTV ({cctvData?.siteName})
                        <IoMdClose className='hover:cursor-pointer' onClick={fn_closeModal}/>
                    </div>
                </Modal.Header>
                <Modal.Body className="px-4">
                    <form name={'form'}>
                        <div className="bmc-modal-div">
                            <div className="div-header">
                                {cctvData?.location}
                            </div>
                            <div className="div-body p-3">
                                {cctvData ?
                                    <div style={{position:"relative"}}>

                                        <>
                                            <div className="position-relative cursor-pointer">
                                                <img className="rounded-[5px] object-cover w-100" src={process.env.REACT_APP_CCTV_URL + "/cctv-play?url=" + encodeURIComponent(cctvData.rtspUrl)} alt="cctv" style={{minHeight:"100px"}} ref={playImage} />
                                            </div>
                                            {cctvDataList.length > 0 &&
                                                <div className="rounded-br-[5px] px-3 py-2 " style={{backgroundColor:"#00000088", position: "absolute", bottom:0, right:0}}>
                                                    <span className={"d-inline-block pe-2 cursor-pointer"} onClick={() => fn_nextCctv('left')} style={{borderRight:"solid 1px #36363A"}}><i className="bmc-arrow left"></i></span>
                                                    <span className={"d-inline-block ps-2 cursor-pointer"} onClick={() => fn_nextCctv('right')} ><i className="bmc-arrow right"></i></span>
                                                </div>}
                                        </>

                                    </div>
                                    :
                                    <span>영상 로딩중 입니다.</span>
                                }




                            </div>
                        </div>
                    </form>
                </Modal.Body>
                <Modal.Footer className="py-2">
                    <div className='flex justify-center w-100'>
                        <button
                            className='bmc-btn pill l white me-3'
                            onClick={fn_closeModal}>닫기
                        </button>
                    </div>
                </Modal.Footer>
            </Modal>
        </div>
    )
}
