import {Modal, Table} from "react-bootstrap";
import {IoMdClose} from "react-icons/io";
import iconCalendar from "../../../images/icon-calendar-black.svg";
import CustomDatePickerRange from "../../component/ui/DatePickerRange";
import React, {Dispatch, SetStateAction, useContext, useEffect, useRef, useState} from "react";
import {CmmnFn, fn_getCurrentDate} from "../../../helper/CmmnHelper";
import {getLoginUser} from "../../../helper/SecurityHelper";
import {Col, Row} from "react-bootstrap";
import {Container} from "@mui/material";
import {COMMON_MODAL_STYLE, COMMON_MULTISEL_STRINGS} from "../../../helper/StyleHelper";
import Select from 'react-select'
import {MultiSelect} from "react-multi-select-component";
import axios from "axios";
import {toast} from "react-toastify";
import messageCodeDic from "../../../util/messageCodeDic";
import errorCodeDic from "../../../util/errorCodeDic";
import BMCInput from "../../../components/common/BMCInput";
import BMCTextarea from "../../../components/common/BMCTextarea";
import BMCSelect from "../../../components/common/BMCSelect";
import CustomDatePicker from "../../component/ui/DatePicker";
import SiteSearchModal from "../../companyManagement/modal/SiteSearchModal";
import DriverSrchModal from "./DriverSrchModal";
import restapi from "../../../helper/restAPIHelper";
import {disableForm} from "../../../helper/FormHelper";
import iconTrashBean from "../../../images/icon_trashBean.png";
import {ProgressContext} from "../../../context/Progress";
import BMCDatePicker2 from "../../../components/common/BMCDatePicker2";
import {DateHelper} from "../../../helper/DateHelper";
import BMCIconButton from "../../../components/common/BMCIconButton";
import BMCDatePicker from "../../../components/common/BMCDatePicker";
import iconNodata from "../../../images/icon_nodocdata.png";



interface IProps {
    setModalIsOpen: Dispatch<SetStateAction<boolean>>;
    modalIsOpen: boolean;
    detailData: any;
    setIsSaveSuccess: Dispatch<SetStateAction<boolean>>;
    siteId: number;
}

const EqmntIncgrtyDtlModal = (props: IProps) => {

    const inspctProdRef = useRef<any>();
    const isrncProdRef = useRef<any>();


    const [eqmntIspctStrtDt, setEqmntIspctStrtDt] = useState<Date | null>(null);
    const [eqmntIspctEndDt, setEqmntIspctEndDt] = useState<Date | null>(null);
    const [eqmntIsrncStrtDt, setEqmntIsrncStrtDt] = useState<Date | null>(null);
    const [eqmntIsrncEndDt, setEqmntIsrncEndDt] = useState<Date | null>(null);
    const [data, setData] = useState<any>({})
    const [isDriverPopOpend, setIsDriverPopOpend] = React.useState<boolean>(false);
    const [driverData, setDriverData] = useState<any>({});
    const [selected, setSelected] = useState([])
    const [dataList, setDataList] = useState<any>([]);
    const formRef = useRef<any>();
    const user = getLoginUser();
    const {spinner} = useContext(ProgressContext);
    const fileRef = useRef() as React.MutableRefObject<any>;
    const [imgTarget, setImgTarget] = useState<any>(null);
    const actProdRef = useRef<any>();
    const [slctdRowIdx, setSlctdRowIdx] = useState<number>(0);
    const [isActDatePopupOpen, setIsActDatePopupOpen] = useState(false);
    const [actCmpltDt, setActCmpltDt] = useState<string>('');


    useEffect(() =>{
        if(actCmpltDt){
            console.log('actCmpltDt: ', actCmpltDt);

            dataList[slctdRowIdx].actDt = actCmpltDt;
            setDataList([].concat(dataList));
        }
    }, [actCmpltDt, slctdRowIdx])

    const fn_setFormData = (e: any) => {
        setData({
            ...data,
            [e.target.name]: e.target.value
        })
    }


    function afterOpenModal() {
        disableForm(formRef);
        setData(props.detailData);
        console.log('props.detailData:', props.detailData);
        setEqmntIspctStrtDt(DateHelper.parse(props.detailData.eqmntIspctStrtDt));
        setEqmntIspctEndDt(DateHelper.parse(props.detailData.eqmntIspctEndDt));
        setEqmntIsrncStrtDt(DateHelper.parse(props.detailData.eqmntIsrncStrtDt));
        setEqmntIsrncEndDt(DateHelper.parse(props.detailData.eqmntIsrncEndDt));
        axios.post('/admin/api/eqmnt/retrieveEqmntChkData', {
            eqmntId: props.detailData.eqmntId,
            isDailyHist: true,
            prcsDt: props.detailData.prcsDt,
            chckrSeq: props.detailData.chckrSeq,
            prcsRslt: 'E002002'
        }).then((rsltData: any) => {
            let dataaList = rsltData.data;
            dataaList.map((data: any) => {
                !data.actrSeq && (data.actrSeq = user.userSeq)
            })

            setDataList(dataaList);
        })

    }

    function fn_closeModal() {
        props.setModalIsOpen(false);
    }

    const submit = (e: any) => {
        e.preventDefault()

    }


    const fn_updateDataList = (e: any, rowIdx: number) => {
        let cstmDataList: any = dataList;
        cstmDataList.map((data: any, idx: number) => {
            if (idx == rowIdx) {
                data[e.target.name] = e.target.value;
            }
        })

        setDataList([].concat(cstmDataList));
    }



    const fn_uploadActAfImg = (idx: number) => {
        setImgTarget({idx: idx});
        fileRef.current.click();
    }

    const fn_onfileChange = (e: any) => {
        console.log('e.target.files: ', e.target.files);
        let cstmDataList = dataList;

        if (e.target.files.length > 0) {
            const file = e.target.files[0];
            const imgSrc = URL.createObjectURL(file);
            cstmDataList[imgTarget.idx].fileTmpSrc = imgSrc;
            cstmDataList[imgTarget.idx].actFile = file;

            cstmDataList[imgTarget.idx].isPhotoUpload = true;
            setDataList([].concat(cstmDataList));
        }

    }

    const fn_actDatePopupOpen = (idx: number) => {
        console.log('idx: ', idx);
        setSlctdRowIdx(idx);
        setIsActDatePopupOpen(!isActDatePopupOpen);
    }

    const fn_registData = () => {


        let isValid = true;

        let actFileList: any = [];

        dataList.map((data: any, idx: number) => {
            if (data.actFile) {
                actFileList.push(data.actFile)
            }
            if (!data.fileTmpSrc) {
                toast.warn('조치대상 ' + (idx + 1) + '의 조치후 사진을 입력해 주십시오');
                isValid = false;
            }
            if (!data.actDt) {
                toast.warn('조치대상 ' + (idx + 1) + '의 조치완료일을 선택해 주십시오');
                isValid = false;
            }
            if (!data.actCmnt) {
                toast.warn('조치대상 ' + (idx + 1) + '의 조치  입력해 주십시오');
                isValid = false;
            }

        });
        if (!isValid) {
            return;
        }

        var formData: any = new FormData(formRef.current);
        if (actFileList.length > 0) {
            for (var i = 0; i < actFileList.length; i++) {
                formData.append('actFileList', actFileList[i]);
            }
        }

        let param: any = {eqmntChkPrcsDataList: dataList};
        formData.set('jsonData', new Blob([JSON.stringify(param)], {type: "application/json"}));

        if(!window.confirm('조치등록 하시겠습니까?')){
            return;
        }
        spinner.start();

        axios.post('/admin/api/eqmnt/registEqmntIncgrtyActData', formData).then((result) => {
            if (result.data > 0) {
                toast.success(messageCodeDic['001'])
                props.setModalIsOpen(false);
            } else {
                toast.error(errorCodeDic['003']);
            }
        }).catch(error => {
            toast.error(errorCodeDic['003']);
            console.log('error: ', error);
        })
            .finally(() => {
                spinner.stop();
            });

    }

    return (
        <div>
            <DriverSrchModal setModalIsOpen={setIsDriverPopOpend} modalIsOpen={isDriverPopOpend} driverData={driverData}
                             isSingleCheck={true}
                             setDriverData={setDriverData} siteId={data?.siteId} cmpnySeq={data?.cmpnySeq}/>
            <Modal
                className="bmc-modal" size="xl"
                show={props.modalIsOpen}
                onShow={afterOpenModal}
                onHide={fn_closeModal}
            >
                {isActDatePopupOpen
                    ? <div className='absolute top-200 left-1/3'>
                        <CustomDatePicker setIsDatePopupOpen={setIsActDatePopupOpen}
                                          prodRef={actProdRef}
                                          setDateView={setActCmpltDt}
                        />
                    </div>
                    : null}
                <Modal.Header>
                    <div className="d-flex justify-content-between align-items-center w-100 bmc-font-SD">
                        중장비 부적합 조치 등록
                        <IoMdClose className='hover:cursor-pointer' onClick={fn_closeModal}/>
                    </div>
                </Modal.Header>
                <Modal.Body className="px-4">
                    <input hidden type={'file'} ref={fileRef} onChange={fn_onfileChange}/>
                    <form name={'form'} ref={formRef}
                          onSubmit={submit}>
                        <div className="bmc-modal-div">
                            <div className="div-header">
                                장비정보
                            </div>
                            <div className="div-body px-3">
                                <Table className="my-2 align-middle">
                                    <tbody>
                                    <tr className="border-top align-middle">
                                        <th className="bmc-bg-th" style={{width: '15%'}}>현장</th>
                                        <td style={{width: '35%'}}>
                                            <BMCSelect initOptionGb={'none'} disabled={true} codeGb={'site'}
                                                      onChange={fn_setFormData} className={'form-control'}
                                                      value={data?.siteId}></BMCSelect>
                                        </td>
                                        <th className="bmc-bg-th" style={{width: '15%'}}>업체</th>
                                        <td style={{width: '35%'}}>
                                            <BMCSelect initOptionGb={'sel'} disabled={data?.driverSeq ? true : false}
                                                      name={'cmpnySeq'} codeGb={'siteCmpny'} className={'w-100'}
                                                      onChange={fn_setFormData} selParam={{siteId: props.siteId}}
                                                      value={data?.cmpnySeq}></BMCSelect>
                                        </td>
                                    </tr>
                                    <tr className="border-top align-middle">
                                        <th className="bmc-bg-th" style={{width: '15%'}}>장비명</th>
                                        <td style={{width: '35%'}}>
                                            <BMCSelect initOptionGb={'sel'} name={'eqmntCd'} className={'w-100'}
                                                      codeGb={'siteMngCd'}
                                                      onChange={(e) => {
                                                          fn_setFormData(e);
                                                          setSelected([])
                                                      }} selParam={{
                                                siteId: props.siteId,
                                                manageCode: 'M001005'
                                            }} value={data?.eqmntCd}></BMCSelect>
                                        </td>
                                        <th className="bmc-bg-th" style={{width: '15%'}}>임대 업체</th>
                                        <td style={{width: '35%'}}>
                                            <BMCInput type="text" className="form-control" name={'rntCmpnyNm'}
                                                     onChange={fn_setFormData} value={data?.rntCmpnyNm}/>
                                        </td>
                                    </tr>
                                    <tr className="border-top align-middle">
                                        <th className="bmc-bg-th" style={{width: '15%'}}>장비 등록번호</th>
                                        <td style={{width: '35%'}}>
                                            <BMCInput type="text" className="form-control" name={'eqmntRgstNo'}
                                                     onChange={fn_setFormData} value={data?.eqmntRgstNo}/>
                                        </td>
                                        <th className="bmc-bg-th" style={{width: '15%'}}>장비 규격</th>
                                        <td style={{width: '35%'}}>
                                            <div className="d-flex align-items-center w-100">
                                                <BMCInput type="number" className="form-control" name={'eqmntWght'}
                                                         onChange={fn_setFormData} value={data?.eqmntWght}/>
                                                <span className={'ml-5'}>t(톤)</span>
                                            </div>
                                        </td>
                                    </tr>
                                    <tr className="border-top align-middle">
                                        <th className="bmc-bg-th" style={{width: '15%'}}>장비 검사기간</th>
                                        <td style={{width: '35%'}}>
                                            <div className="d-flex align-items-center w-100" ref={inspctProdRef}>
                                                <BMCDatePicker2 setter={setEqmntIspctStrtDt} name={'noticeStartDate'} defaultValue={eqmntIspctStrtDt} onChange={fn_setFormData}/>
                                                <span>&nbsp;~&nbsp;</span>
                                                <BMCDatePicker2 setter={setEqmntIspctEndDt} name={'noticeEndDate'} defaultValue={eqmntIspctEndDt} onChange={fn_setFormData}/>
                                            </div>
                                        </td>
                                        <th className="bmc-bg-th" style={{width: '15%'}}>보험기간</th>
                                        <td style={{width: '35%'}}>
                                            <div className="d-flex align-items-center w-100" ref={isrncProdRef}>
                                                <BMCDatePicker2 setter={setEqmntIsrncStrtDt} name={'x'} defaultValue={eqmntIsrncStrtDt} onChange={fn_setFormData}/>
                                                <span>&nbsp;~&nbsp;</span>
                                                <BMCDatePicker2 setter={setEqmntIsrncEndDt} name={'x'} defaultValue={eqmntIsrncEndDt} onChange={fn_setFormData}/>
                                            </div>
                                        </td>
                                    </tr>
                                    <tr className="border-top align-middle">
                                        <th className="bmc-bg-th" style={{width: '15%'}}>운전원</th>
                                        <td style={{width: '35%'}}>
                                            <div className="d-flex align-items-center w-100">
                                                <BMCInput type="text" disabled={true} className="form-control"
                                                         name={'driverNm'}
                                                         onChange={fn_setFormData} value={data?.driverNm}/>
                                                <BMCInput type="hidden" className="form-control" name={'driverSeq'}
                                                         onChange={fn_setFormData} value={data?.driverSeq}/>
                                            </div>
                                        </td>
                                        <th className="bmc-bg-th" style={{width: '15%'}}>운전원 연락처</th>
                                        <td style={{width: '35%'}}>
                                            <BMCInput type="text" disabled={true} className="form-control"
                                                     name={'driverPhone'}
                                                     onChange={fn_setFormData} value={data?.driverPhone}/>
                                        </td>
                                    </tr>
                                    <tr className="border-top align-middle">
                                        <th className="bmc-bg-th" style={{width: '15%'}}>운전원 면허번호</th>
                                        <td style={{width: '35%'}}>
                                            <BMCInput type="text" disabled={true} className="form-control"
                                                     name={'driverLcncNo'}
                                                     onChange={fn_setFormData} value={data?.driverLcncNo}/>
                                        </td>
                                        <th className="bmc-bg-th" style={{width: '15%'}}>장비교대운전원</th>
                                        <td style={{width: '35%'}}>
                                            <div className="d-flex align-items-center w-100">
                                                <BMCInput type="text" disabled={true} className="form-control"
                                                         name={'rotDriverNm'}
                                                         onChange={fn_setFormData} value={data?.rotDriverNm}/>
                                                <BMCInput type="hidden" disabled={true} className="form-control"
                                                         name={'rotDriverSeq'}
                                                         onChange={fn_setFormData} value={data?.rotDriverSeq}/>
                                            </div>
                                        </td>
                                    </tr>
                                    <tr className="border-top align-middle">
                                        <th className="bmc-bg-th" style={{width: '15%'}}>점검일자</th>
                                        <td style={{width: '35%'}}>
                                            <input value={data?.chkTime || ''}
                                                   className={"form-control"}
                                                   disabled={true}/>
                                        </td>
                                        <th className="bmc-bg-th" style={{width: '15%'}}>점검자</th>
                                        <td style={{width: '35%'}}>
                                            <input className={"form-control"}
                                                   value={data?.chckrNm || ''} disabled={true}/>
                                        </td>
                                    </tr>
                                    </tbody>
                                </Table>
                            </div>
                        </div>

                        <div className="bmc-modal-div">
                            <div className="div-header">
                                부적합 내용
                            </div>
                            <div className="div-body px-3">

                                    {
                                        dataList.length > 0 &&
                                            dataList.map((data: any, idx: number) => (
                                                <Container className='bmc-box-form py-2 mt-3' key={idx}>
                                                    <Row className="font-semibold mb-1">
                                                        <Col>
                                                            <div
                                                                className="d-flex align-items-center justify-content-between">
                                                                <div>{'조치대상 ' + (idx + 1)}</div>
                                                            </div>
                                                        </Col>
                                                    </Row>
                                                    <hr/>
                                                    <Row>
                                                        <Col>
                                                            <span className="bmc-fw-5 text-red-500">조치전</span>
                                                        </Col>
                                                        <Col>
                                                            <span className="bmc-fw-5 text-green-500">조치후</span>
                                                        </Col>
                                                    </Row>
                                                    <Row>
                                                        <Col>

                                                            <img
                                                                className={'max-h-[300px]'}
                                                                src={process.env.REACT_APP_FILE_URL + "/shss-data/fileDownload?attachSeq=" + data.badRsnFileId + '&sn=0&isPdf=0&time=' + new Date().getTime()}/>

                                                            <span className="bmc-fw-5">장비점검 질문</span>
                                                            <BMCInput className="bmc-form input"
                                                                     name={'chkCntnts'}
                                                                     readOnly={true}
                                                                     value={data?.chkCntnts}
                                                            />
                                                            <span className="bmc-fw-5">부적합 사유</span>
                                                            <BMCInput className="bmc-form input"
                                                                     name={'badRsn'}
                                                                     readOnly={true}
                                                                     value={data?.badRsn}
                                                            />

                                                        </Col>
                                                        <Col>
                                                            {data?.fileTmpSrc ?
                                                                <img className={'cursor-pointer max-h-[300px]'}
                                                                     onClick={() => fn_uploadActAfImg(idx)}
                                                                     src={data.fileTmpSrc}/>
                                                                : data.actFileId ?
                                                                    <img
                                                                        className={'max-h-[300px]'}
                                                                        src={process.env.REACT_APP_FILE_URL + "/shss-data/fileDownload?attachSeq=" + data.actFileId + '&sn=0&isPdf=0&time=' + new Date().getTime()}/>
                                                                    :
                                                                    <label htmlFor="input-file"
                                                                           className={'bmc-btn btn-secondary w-[100px] cursor-pointer'}
                                                                           onClick={() => fn_uploadActAfImg(idx)}>
                                                                        사진 업로드
                                                                    </label>
                                                            }


                                                            <div className="d-flex align-items-center w-100 bmc-font-SD">
                                                                <div>
                                                                    <span className="bmc-fw-5">조치 완료일</span>
                                                                    <div
                                                                        className="d-flex align-items-center justify-content-between"
                                                                        ref={actProdRef}>
                                                                        <BMCDatePicker
                                                                            className={"text-center"}
                                                                            onClick={() => fn_actDatePopupOpen(idx)}
                                                                            calendar={() => fn_actDatePopupOpen(idx)}
                                                                            value={data.actDt} name={'actDt'}
                                                                            readOnly={true}/>
                                                                    </div>
                                                                </div>
                                                                <div className={'ml-10'}>
                                                                    <span className="bmc-fw-5">조치 담당자</span>
                                                                    <div
                                                                        className="d-flex align-items-center justify-content-between"
                                                                        ref={actProdRef}>
                                                                        <BMCInput className="bmc-form input"
                                                                                 name={'actrNm'}
                                                                                 readOnly={true}
                                                                                 value={data?.actrNm || user.userName}
                                                                        />
                                                                    </div>
                                                                </div>
                                                            </div>
                                                                    <span className="bmc-fw-5">조치 코멘트</span>
                                                                    <BMCInput name={'actCmnt'} onChange={(e) => fn_updateDataList(e, idx)}
                                                                             className={'form-control'}
                                                                             value={data.actCmnt}></BMCInput>




                                                        </Col>
                                                    </Row>
                                                </Container>


                                            ))
                                    }

                            </div>
                        </div>

                    </form>
                </Modal.Body>
                <Modal.Footer className="py-2">
                    <div className='flex justify-center w-100'>
                        <button
                            className='bmc-btn pill l white me-3'
                            onClick={fn_closeModal}>닫기
                        </button>
                        {!data.isActDone &&
                            <button onClick={fn_registData}
                                    className='bmc-btn pill p'>조치등록
                            </button>
                        }

                    </div>
                </Modal.Footer>

            </Modal>
        </div>
    )
}

export default EqmntIncgrtyDtlModal;

