import {IoMdClose} from "react-icons/io";
import iconCalendar from "../../../images/icon-calendar-black.svg";
import CustomDatePickerRange from "../../component/ui/DatePickerRange";
import React, {Dispatch, SetStateAction, useEffect, useRef, useState} from "react";
import {CmmnFn, fn_getCurrentDate} from "../../../helper/CmmnHelper";
import {getLoginUser} from "../../../helper/SecurityHelper";
import {Col, Row, Table, Modal} from "react-bootstrap";
import {COMMON_MODAL_STYLE, COMMON_MODAL_STYLE_SCROLL, COMMON_MULTISEL_STRINGS} from "../../../helper/StyleHelper";
import BMCInput from "../../../components/common/BMCInput";
import BMCTextarea from "../../../components/common/BMCTextarea";
import axios from "axios";
import {toast} from "react-toastify";
import messageCodeDic from "../../../util/messageCodeDic";
import errorCodeDic from "../../../util/errorCodeDic";
import BMCSelect from "../../../components/common/BMCSelect";

import {MultiSelect} from "react-multi-select-component";
import restapi from "../../../helper/restAPIHelper";
import iconTrashBean from "../../../images/icon_trashBean.png";
import iconSave from "../../../images/icon_save.svg";
import iconUser from "../../../images/icon_user.svg";
import SiteSearchModal from "../../companyManagement/modal/SiteSearchModal";
import WrkrSrchModal from "./WrkrSrchModal";
import CustomDatePicker from "../../component/ui/DatePicker";
import BMCRadio from "../../../components/common/BMCRadio";
import BMCRadioGroup from "../../../components/common/BMCRadioGroup";
import BMCCheckbox from "../../../components/common/BMCCheckbox";
import BMCDatePicker from "../../../components/common/BMCDatePicker";
import CmpnyCardList from "../../../components/cmpny/CmpnyCardList";
import BMCIconButton from "../../../components/common/BMCIconButton";
import BMCInputBox from '../../../components/common/BMCInputBox';
import IconRemove from '../../../images/icon_remove.png';
const $ = require("jquery")
interface IProps {
    setModalIsOpen: Dispatch<SetStateAction<boolean>>;
    modalIsOpen: boolean;
    detailData: any;
    setIsSaveSuccess: Dispatch<SetStateAction<boolean>>;
}

const WrkPrmtDetailModal = (props: IProps) => {

    const currentDate = fn_getCurrentDate();
    const user = getLoginUser();

    const prodRef = useRef<any>();

    const [isDatePopupOpen, setIsDatePopupOpen] = useState(false);
    const [data, setData] = useState<any>()
    const [chrgrDataList, setChrgrDataList] = useState<any>([]);
    const [selChrgrs, setSelChrgrs] = useState([])
    const [eqmntDataList, setEqmntDataList] = useState<any>([]);
    const [selEqmnts, setSelEqmnts] = useState<any>([]);
    const [eqmntOpts, setEqmntOpts] = useState<any>([]);
    const [todayWrkInfoList, setTodayWrkInfoList] = useState<any[]>([]);
    const [wrkrDataList, setWrkrDataList] = useState<object[]>([]);
    const [wrkDt, setWrkDt] = useState<string>('');

    const [isWrkrModalOpen, setIsWrkrModalOpen] = useState(false);
    const [todayWrkIdx, setTodayWrkIdx] = useState<number>(0);
    const [finalWrkrDataList, setFinalWrkrDataList] = useState<any[]>([]);
    const [isWrkCntntsHide, setIsWrkCntntsHide] = useState<boolean>(false);
    const [wrkHrList, setWrkHrList] = useState<any>([]);
    const [wrkMiList, setWrkMiList] = useState<any>([]);

    const [inputView, setInputView] =useState(false);

    useEffect(() => {
        const optsArray: number[] = []
        for (let i = 0; i < 50; i++) {
            optsArray.push(i + 1);
        }
        setEqmntOpts(optsArray);

    }, [])

    useEffect(() => {
        console.log('data: ', data);
    }, [data])



    const fn_genWrkTime = () => {

        let date = new Date(2023, 0, 1);
        let hrArr = ['00']
        let minArr = ['00']
        let hr;
        let mi;

        for (let i = 0; i < 23; i++) {
            date.setHours(date.getHours() + 1);
            hr = date.getHours() + '';
            if (hr.length == 1) {
                hr = '0' + hr;
            }
            hrArr.push(hr);
        }
        for (let i = 0; i < 5; i++) {
            date.setMinutes(date.getMinutes() + 10);
            mi = date.getMinutes() + '';
            if (mi.length == 1) {
                mi = '0' + mi;
            }
            minArr.push(mi);
        }


        setWrkHrList(hrArr);
        setWrkMiList(minArr)


    }


    useEffect(() => {
        if (wrkrDataList.length > 0) {
            finalWrkrDataList[todayWrkIdx] = wrkrDataList
            // @ts-ignore
            setFinalWrkrDataList([].concat(finalWrkrDataList))

        }
    }, [wrkrDataList]);


    useEffect(() => {
        // 상세 조회
        if (chrgrDataList.length > 0 && eqmntDataList.length > 0 && props.detailData) {
            restapi.retrieveWrkPrmtDtlData(props.detailData.wrkPrmtId).then((rsltData) => {
                const chrgrSeqList = rsltData.chrgDataList;
                const dtlEqmntDataList = rsltData.eqmntDataList;
                const dtlWrkrStatDataList = rsltData.wrkrStatDataList;
                let cstmChrgList: any = [];
                let cstmEqmntList: any = [];
                let cstmTodayWrkInfoList: any = [];
                let cstmFinalWrkrDataList: any = [];
                let grpIdx = 0;

                chrgrSeqList.map((chrgSeq: number) => {
                    chrgrDataList.map((chrgData: any) => {
                        if (chrgData.value == chrgSeq) {
                            cstmChrgList.push(chrgData);
                        }
                    })
                })
                dtlEqmntDataList.map((dtlEqmntData: any) => {
                    eqmntDataList.map((data: any) => {
                        if (data.value == dtlEqmntData.eqmntCd) {
                            data.eqmntCnt = dtlEqmntData.eqmntCnt;
                            cstmEqmntList.push(data);
                        }
                    })
                })


                dtlWrkrStatDataList.map((data: any) => {
                    data.isCnfrmd = true
                    if (cstmFinalWrkrDataList[data.wrkGrpId]) {
                        cstmFinalWrkrDataList[data.wrkGrpId] = cstmFinalWrkrDataList[data.wrkGrpId].concat(data)
                    } else {
                        cstmFinalWrkrDataList.push([data]);
                    }

                    if (grpIdx == data.wrkGrpId) {
                        cstmTodayWrkInfoList.push({ocptCd: data.ocptCd, wrkCntnts: data.wrkCntnts})
                        grpIdx++;
                    }
                })

                setSelChrgrs(cstmChrgList);
                setSelEqmnts(cstmEqmntList);
                setFinalWrkrDataList(cstmFinalWrkrDataList);
                setTodayWrkInfoList(cstmTodayWrkInfoList);


                //todayWrkInfoList
            })
        }
    }, [chrgrDataList, eqmntDataList])

    const fn_retrieveChrgrDataList = () => {
        restapi.retrieveUserDataList({
            isSiteMngr: true,
            blngSiteId: user.blngSiteId,
            blngCmpnySeq: user.blngCmpnySeq
        }).then((rsltData) => {
            const rsltChrgrDataList: object[] = []
            rsltData.map((data: any) => {
                rsltChrgrDataList.push({label: '[' + data.chrgNm + ']' + data.userName, value: data.userSeq})
            })
            setChrgrDataList(rsltChrgrDataList)
        })
    }

    const fn_retrieveEqmntDataList = () => {
        restapi.retrieveSiteManageItemList(user.blngSiteId, 'M001005').then((rsltData) => {
            const rsltEqmntDataList: object[] = []
            rsltData.map((data: any) => {
                rsltEqmntDataList.push({label: data.manageName, value: data.manageSn})
            })
            setEqmntDataList(rsltEqmntDataList);
        })
    }


    const fn_setFormData = (e: any) => {
        CmmnFn.setFormData(e, data, setData);

    }


    const fn_onAfterOpen = () => {
        fn_retrieveChrgrDataList();
        fn_retrieveEqmntDataList();
        fn_genWrkTime();

        // 신규
        if (!props.detailData) {

            const siteId = user.blngSiteId;
            const cmpnySeq = user.blngCmpnySeq;

            setData({siteId: siteId, cmpnySeq: cmpnySeq ,isWrkSfty1: true ,isWrkSfty2: true ,isWrkSfty3: true ,isWrkSfty4: true})
            setWrkDt('');
            setSelChrgrs([]);
            setFinalWrkrDataList([]);
            setTodayWrkInfoList([]);
            setSelEqmnts([]);
            // 상세
        } else {
            setData(props.detailData);
            setWrkDt(props.detailData.wrkDt);
        }


    }


    function fn_closeModal() {
        props.setModalIsOpen(false);
    }

    const submit = (e: any) => {
        e.preventDefault()

    }

    const fn_datePopupOpen = () => {
        setIsDatePopupOpen(!isDatePopupOpen);
    };


    const fn_registData = () => {


        if (!data) {
            toast.warn('데이터를 입력해 주십시오.')
            return;
        }

        if (!wrkDt) {
            toast.warn('작업일을 선택해 주십시오.')
            return;
        }

        if (selChrgrs.length == 0) {
            toast.warn('작업책임자를 선택해 주십시오.');
            return;
        }

        if (!data.cmpnySeq) {
            toast.warn('작업업체명을 선택해 주십시오.');
            return;
        }

        if (!data.wrkTimeGbCd) {
            toast.warn('작업시간을 선택해 주십시오.');
            return;
        }

        let isPass = false;

        let wrkStatDataList: any = [];

        finalWrkrDataList.map((innerData, parIdx) => {
            innerData.map((data: any) => {
                if (data.isCnfrmd) {
                    data.wrkGrpId = parIdx;
                    wrkStatDataList.push(data);
                    isPass = true;
                }
            })
        })

        if (finalWrkrDataList.length == 0 || !isPass) {
            toast.warn('금일작업자를 입력하고 저장해 주십시오.');
            return;
        }

        let parmChrgs: any = []
        selChrgrs.map((data: any) => {
            parmChrgs.push(data.value);
        })

        selEqmnts.map((data: any) => {
            data.eqmntCd = data.value
        })


        let param = data;
        param.rgstrSeq = user.userSeq;
        param.updtrSeq = user.userSeq;
        param.wrkDt = wrkDt;
        param.chrgDataList = parmChrgs;
        param.wrkrStatDataList = wrkStatDataList;
        param.eqmntDataList = selEqmnts;

        if (!window.confirm('저장 하시겠습니까?')) {
            return;
        }

        axios.post('/admin/api/wrkPrmt/saveData', param).then((result) => {
                if (result.data > 0) {
                    toast.success(messageCodeDic['001'])
                    props.setModalIsOpen(false);
                    props.setIsSaveSuccess(true);
                } else {
                    toast.error(errorCodeDic['003']);
                    props.setIsSaveSuccess(false);
                }
            }
        )


    }

    const fn_delRow = (idx: number) => {

        // @ts-ignore
        let cstmTodayWrkrInfoDataList: any = [].concat(todayWrkInfoList);
        // @ts-ignore
        let cstmFinalWrkrDataList: any = [].concat(finalWrkrDataList);

        cstmTodayWrkrInfoDataList.splice(idx, 1);
        cstmFinalWrkrDataList.splice(idx, 1);
        setTodayWrkInfoList(cstmTodayWrkrInfoDataList);
        setFinalWrkrDataList(cstmFinalWrkrDataList);
    }

    const fn_delWrkStatRow = (parIdx: number, idx: number) => {

        finalWrkrDataList[parIdx].splice(idx, 1)

        // @ts-ignore
        setFinalWrkrDataList([].concat(finalWrkrDataList));
    }

    const fn_saveRow = (idx: number) => {
        setTodayWrkIdx(idx);

        const ocptCd = todayWrkInfoList[idx].ocptCd;
        const wrkCntnts = todayWrkInfoList[idx].wrkCntnts;

        if (!ocptCd) {
            toast.warn('직종을 선택해 주십시오');
            return;
        }


        if (finalWrkrDataList[idx].isCnfrmd === null) {
            toast.warn('작업자를 선택해 주십시오');
            return;
        }

        finalWrkrDataList[idx].map((wrkData: any) => {
            wrkData.isCnfrmd = true;
            wrkData.ocptCd = ocptCd;
            wrkData.wrkCntnts = wrkCntnts
        });
        // @ts-ignore
        setFinalWrkrDataList([].concat(finalWrkrDataList))
    }

    const fn_addTodayWrkRow = () => {
        setTodayWrkInfoList(todayWrkInfoList.concat({}))
        setFinalWrkrDataList(finalWrkrDataList.concat({isCnfrmd: null}))
    }

    const fn_wrkrModalOpen = (idx: number) => {
        setTodayWrkIdx(idx);
        setIsWrkrModalOpen(true);
    }

    const fn_updateTodayWrkInfoData = (e: any, idx: number) => {
        todayWrkInfoList[idx][e.target.name] = e.target.value;


        // @ts-ignore
        setTodayWrkInfoList([].concat(todayWrkInfoList))


    }

    const fn_updateWrkStatInfoData = (e: any, parIdx: number, idx: number) => {
        finalWrkrDataList[parIdx][idx][e.target.name] = e.target.value;

        // @ts-ignore
        setFinalWrkrDataList([].concat(finalWrkrDataList));
    }

    const fn_toggleWrkCntnts = () => {
        setIsWrkCntntsHide(!isWrkCntntsHide);
    }

    const fn_onWrkTimeSelChg = (e: any) => {
        const timeGb = e.target.value;
        let wrkStrtHr;
        let wrkStrtMi;
        let wrkEndHr;
        let wrkEndMi;

        // 주간
        if (timeGb == 'T001001') {
            wrkStrtHr = '08';
            wrkStrtMi = '00';
            wrkEndHr = '17';
            wrkEndMi = '00';

            // 조출
        } else if (timeGb == 'T001002') {
            wrkStrtHr = '05';
            wrkStrtMi = '00';
            wrkEndHr = '08';
            wrkEndMi = '00';
            // 중식
        } else if (timeGb == 'T001003') {
            wrkStrtHr = '12';
            wrkStrtMi = '00';
            wrkEndHr = '13';
            wrkEndMi = '00';
            // 연장
        } else if (timeGb == 'T001004') {
            wrkStrtHr = '17';
            wrkStrtMi = '00';
            wrkEndHr = '22';
            wrkEndMi = '00';
            // 야간
        } else if (timeGb == 'T001005') {
            wrkStrtHr = '22';
            wrkStrtMi = '00';
            wrkEndHr = '00';
            wrkEndMi = '00';

        }

        setData({
            ...data,
            wrkStrtHr: wrkStrtHr,
            wrkStrtMi: wrkStrtMi,
            wrkEndHr: wrkEndHr,
            wrkEndMi: wrkEndMi,
            wrkTimeGbCd: timeGb
        })
    }

    const fn_updateSelEqmntsData = (e: any, selIdx: number) => {
        selEqmnts.map((data: any, idx: number) => {
            if (selIdx == idx) {
                data.eqmntCnt = e.target.value;
            }
        })
        setSelEqmnts([].concat(selEqmnts));
    }


    return (
        <div>
            <Modal className="bmc-modal" size="xl" show={props.modalIsOpen} onHide={fn_closeModal} onShow={fn_onAfterOpen}>
                <Modal.Header>
                    <div className="d-flex justify-content-between align-items-center w-100 bmc-font-SD">
                        작업허가서 상세보기
                        <IoMdClose className='hover:cursor-pointer' onClick={fn_closeModal}/>
                    </div>
                </Modal.Header>
                <Modal.Body className="px-4">
                    <div className="bmc-modal-div under">
                        <div className="div-header">
                            작업허가서
                        </div>
                        <div className="div-body">
                            <Table>
                                <colgroup>
                                    <col width={'15%'} />
                                    <col width={'35%'} />
                                    <col width={'15%'} />
                                    <col width={'35%'} />
                                </colgroup>
                                <tbody>
                                    <tr>
                                        <th>현장</th>
                                        <td>{data?.siteName}</td>
                                        <th>작업일</th>
                                        <td>{data?.wrkDt}</td>
                                    </tr>
                                    <tr>
                                        <th>작업책임자</th>
                                        <td>{data?.chrgrNms}</td>
                                        <th>작업업체명</th>
                                        <td>{data?.companyName}</td>
                                    </tr>
                                    <tr>
                                        <th>작업시간</th>
                                        <td colSpan={3}>{data?.wrkTimeGbNm} {data?.wrkStrtHr}:{data?.wrkStrtMi} ~ {data?.wrkEndHr}:{data?.wrkEndMi}</td>
                                    </tr>
                                </tbody>
                            </Table>
                        </div>
                    </div>

                    <div className="bmc-modal-div under">
                        <div className="div-header">
                            작업내용
                        </div>
                        <div className="div-body">
                            <Table className="text-center">
                                <thead>
                                    <tr>
                                        <th className="border-end">공종</th>
                                        <th className="border-end">세부공종</th>
                                        <th className="border-end">중장비</th>
                                        <th className="border-end">작업위치</th>
                                        <th>작업인원</th>
                                    </tr>
                                </thead>
                                <tbody>
                                    <tr>
                                        <td className="border-end">토목공사</td>
                                        <td className="border-end">절토작업</td>
                                        <td className="border-end">굴삭기(백호)</td>
                                        <td className="border-end">D동</td>
                                        <td>150명</td>
                                    </tr>
                                    <tr>
                                        <td className="border-end">토목공사</td>
                                        <td className="border-end">절토작업</td>
                                        <td className="border-end">굴삭기(백호)</td>
                                        <td className="border-end">D동</td>
                                        <td>150명</td>
                                    </tr>
                                    <tr>
                                        <td className="border-end">토목공사</td>
                                        <td className="border-end">절토작업</td>
                                        <td className="border-end">굴삭기(백호)</td>
                                        <td className="border-end">D동</td>
                                        <td>150명</td>
                                    </tr>
                                </tbody>
                            </Table>
                        </div>
                    </div>

                    <div className="bmc-modal-div under">
                        <div className="div-header">
                            금일 작업 사항
                        </div>
                        <div className="div-body">
                            <Table className="text-center">
                                <colgroup>
                                    <col width={'25%'} />
                                    <col width={'15%'} />
                                    <col width={'60%'} />
                                </colgroup>
                                <thead>
                                <tr>
                                    <th className="border-end">공종</th>
                                    <th className="border-end">인원</th>
                                    <th>작업사항</th>
                                </tr>
                                </thead>
                                <tbody>
                                <tr>
                                    <td className="border-end">토목공사</td>
                                    <td className="border-end">15</td>
                                    <td>흙막이 작업</td>
                                </tr>
                                <tr>
                                    <td className="border-end">토목공사</td>
                                    <td className="border-end">15</td>
                                    <td>흙막이 작업</td>
                                </tr>
                                </tbody>
                            </Table>
                        </div>
                    </div>

                    <div className="bmc-modal-div under">
                        <div className="div-header">
                            근로자별 작업 현황
                        </div>
                        <div className="div-body">
                            <Table className="text-center">
                                <colgroup>
                                    <col width={'5%'} />
                                    <col width={'15%'} />
                                    <col width={'10%'} />
                                    <col width={'10%'} />
                                    <col width={'25%'} />
                                    <col width={'35%'} />
                                </colgroup>
                                <thead>
                                <tr>
                                    <th className="border-end">No.</th>
                                    <th className="border-end">직종</th>
                                    <th className="border-end">이름</th>
                                    <th className="border-end">특이사항</th>
                                    <th className="border-end">금일작업사항</th>
                                    <th>비고</th>
                                </tr>
                                </thead>
                                <tbody>
                                <tr>
                                    <td className="border-end">1</td>
                                    <td className="border-end">토목공사</td>
                                    <td className="border-end">홍길동</td>
                                    <td className="border-end">N</td>
                                    <td className="border-end">흙막이 작업</td>
                                    <td></td>
                                </tr>
                                <tr>
                                    <td className="border-end">2</td>
                                    <td className="border-end">토목공사</td>
                                    <td className="border-end">홍길동</td>
                                    <td className="border-end">N</td>
                                    <td className="border-end">흙막이 작업</td>
                                    <td></td>
                                </tr>
                                </tbody>
                            </Table>
                        </div>
                    </div>

                    <div className="bmc-modal-div under">
                        <div className="div-header justify-content-start">
                            유해/위험성 확인 및 안전 보호구 선정/착용 <span className="ms-1 bmc-text-font_secondary01 bmc-fs-b4">(안전화, 안전모, 보안경은 필수 착용/방독 마스크는 필수 휴대)</span>
                        </div>
                        <div className="div-body">
                            <Row>
                                <Col className="py-2">
                                    <div className="px-3 py-2 h-100" style={{borderRadius:'4px',border:'1px solid #eeeeee'}}>
                                        <Table borderless className="bmc-checklist">
                                            <colgroup>
                                                <col width={'70%'} />
                                                <col width={'30%'} />
                                            </colgroup>
                                            <tbody>
                                            <tr>
                                                <th colSpan={2}>유해/위험성</th>
                                            </tr>
                                            <tr>
                                                <td className="bmc-text-font_primary">설비 표면 또는 작업구간 온도 위험성</td>
                                                <td className="text-end">
                                                    <BMCRadioGroup>
                                                        <BMCRadio name="isDngr1" value={true} checked={data && data.isDngr1 === true} onChange={fn_setFormData} >
                                                            있음
                                                        </BMCRadio>
                                                        <BMCRadio name="isDngr1" className={'ml-10'} value={false} checked={data&& data.isDngr1 === false} onChange={fn_setFormData}>
                                                            없음
                                                        </BMCRadio>
                                                    </BMCRadioGroup>
                                                </td>
                                            </tr>
                                            <tr>
                                                <td className="bmc-text-font_primary">작업중 추락의 위험성</td>
                                                <td className="text-end">
                                                    <BMCRadioGroup>
                                                        <BMCRadio name="isDngr2" value={true} checked={data && data.isDngr2 === true} onChange={fn_setFormData}>
                                                            있음
                                                        </BMCRadio>
                                                        <BMCRadio name="isDngr2" className={'ml-10'} checked={data && data.isDngr2 === false} value={false} onChange={fn_setFormData}>
                                                            없음
                                                        </BMCRadio>
                                                    </BMCRadioGroup>
                                                </td>
                                            </tr>
                                            <tr>
                                                <td className="bmc-text-font_primary">산소 결핍 또는 유해가스의 위험성</td>
                                                <td className="text-end">
                                                    <BMCRadioGroup>
                                                        <BMCRadio name="isDngr3" value={true} checked={data && data.isDngr3 === true} onChange={fn_setFormData}>
                                                            있음
                                                        </BMCRadio>
                                                        <BMCRadio name="isDngr3" className={'ml-10'} checked={data && data.isDngr3 === false} value={false} onChange={fn_setFormData}>
                                                            없음
                                                        </BMCRadio>
                                                    </BMCRadioGroup>
                                                </td>
                                            </tr>
                                            <tr>
                                                <td className="bmc-text-font_primary">작업 시 감전 가능성</td>
                                                <td className="text-end">
                                                    <BMCRadioGroup>
                                                        <BMCRadio name="isDngr4" value={true} checked={data && data.isDngr4 === true} onChange={fn_setFormData}>
                                                            있음
                                                        </BMCRadio>
                                                        <BMCRadio name="isDngr4" className={'ml-10'} checked={data && data.isDngr4 === false} value={false} onChange={fn_setFormData}>
                                                            없음
                                                        </BMCRadio>
                                                    </BMCRadioGroup>
                                                </td>
                                            </tr>
                                            <tr>
                                                <td className="bmc-text-font_primary">굴착 작업 시 지장물 파손 위험성</td>
                                                <td className="text-end">
                                                    <BMCRadioGroup>
                                                        <BMCRadio name="isDngr5" value={true} checked={data && data.isDngr5 === true} onChange={fn_setFormData}>
                                                            있음
                                                        </BMCRadio>
                                                        <BMCRadio name="isDngr5" className={'ml-10'} checked={data && data.isDngr5 === false} value={false} onChange={fn_setFormData}>
                                                            없음
                                                        </BMCRadio>
                                                    </BMCRadioGroup>
                                                </td>
                                            </tr>
                                            <tr>
                                                <td className="bmc-text-font_primary">
                                                    기타 위험성
                                                    {data?.isDngr6 == true &&
                                                        <div className="input-group input-group-sm mt-2">
                                                            <BMCInput className="bmc-form input" name={'dngrEtcCntnts'} value={data?.dngrEtcCntnts} onChange={fn_setFormData}/>
                                                        </div>
                                                    }
                                                </td>
                                                <td className="text-end">
                                                    <BMCRadioGroup>
                                                        <BMCRadio name="isDngr6" value={true} checked={data && data.isDngr6 === true} onChange={(e: any) => {
                                                            fn_setFormData(e);
                                                        }}>
                                                            있음
                                                        </BMCRadio>
                                                        <BMCRadio name="isDngr6" className={'ml-10'} checked={data && data.isDngr6 === false} value={false} onChange={(e: any) => {
                                                            fn_setFormData(e);
                                                        }}>
                                                            없음
                                                        </BMCRadio>
                                                    </BMCRadioGroup>
                                                </td>
                                            </tr>
                                            </tbody>
                                        </Table>
                                    </div>

                                </Col>
                                <Col className="py-2">
                                    <div className="px-3 py-2" style={{borderRadius:'4px',border:'1px solid #eeeeee'}}>
                                        <Table borderless className="bmc-checklist">
                                            <colgroup>
                                                <col width={'20%'} />
                                                <col width={'80%'} />
                                            </colgroup>
                                            <tbody>
                                            <tr>
                                                <th colSpan={2}>
                                                    안전 보호구 <span className="bmc-text-font_secondary01 bmc-fs-b4">(중복체크가능)</span>
                                                </th>
                                            </tr>
                                            <tr>
                                                <td className="bmc-text-font_primary">안전화</td>
                                                <td className="text-end ps-0">
                                                    <BMCCheckbox name={"isSftyShoe1"} className={'ml-10'} checked={data?.isSftyShoe1} onChange={fn_setFormData}>
                                                        내화학
                                                    </BMCCheckbox>
                                                    <BMCCheckbox name={"isSftyShoe2"} className={'ml-10'} checked={data?.isSftyShoe2} onChange={fn_setFormData}>
                                                        방열
                                                    </BMCCheckbox>
                                                    <BMCCheckbox name={"isSftyShoe3"} className={'ml-10'} checked={data?.isSftyShoe3} onChange={fn_setFormData}>
                                                        절열
                                                    </BMCCheckbox>
                                                    <BMCCheckbox name={"isSftyShoe4"} className={'ml-10'} checked={data?.isSftyShoe4} onChange={fn_setFormData}>
                                                        제전화
                                                    </BMCCheckbox>
                                                </td>
                                            </tr>
                                            <tr>
                                                <td className="bmc-text-font_primary">보안면</td>
                                                <td className="text-end ps-0">
                                                    <BMCCheckbox name={"isPrtctMsk1"} className={'ml-10'} checked={data?.isPrtctMsk1} onChange={fn_setFormData}>
                                                        일반
                                                    </BMCCheckbox>
                                                    <BMCCheckbox name={"isPrtctMsk2"} className={'ml-10'} checked={data?.isPrtctMsk2} onChange={fn_setFormData}>
                                                        방염
                                                    </BMCCheckbox>
                                                    <BMCCheckbox name={"isPrtctMsk3"} className={'ml-10'} checked={data?.isPrtctMsk3} onChange={fn_setFormData}>
                                                        방열
                                                    </BMCCheckbox>
                                                    <BMCCheckbox name={"isPrtctMsk4"} className={'ml-10'} checked={data?.isPrtctMsk4} onChange={fn_setFormData}>
                                                        용접
                                                    </BMCCheckbox>
                                                </td>
                                            </tr>
                                            <tr>
                                                <td className="bmc-text-font_primary">호흡보호구</td>
                                                <td className="text-end ps-0">
                                                    <BMCCheckbox name={"isBrthPrtct1"} className={'ml-10'} checked={data?.isBrthPrtct1} onChange={fn_setFormData}>
                                                        방진
                                                    </BMCCheckbox>
                                                    <BMCCheckbox name={"isBrthPrtct2"} className={'ml-10'} checked={data?.isBrthPrtct2} onChange={fn_setFormData}>
                                                        전면방독
                                                    </BMCCheckbox>
                                                    <BMCCheckbox name={"isBrthPrtct3"} className={'ml-10'} checked={data?.isBrthPrtct3} onChange={fn_setFormData}>
                                                        반면방독
                                                    </BMCCheckbox>
                                                    <BMCCheckbox name={"isBrthPrtct4"} className={'ml-10'} checked={data?.isBrthPrtct4} onChange={fn_setFormData}>
                                                        공기호흡기
                                                    </BMCCheckbox>
                                                </td>
                                            </tr>
                                            <tr>
                                                <td className="bmc-fs-b3 bmc-text-font_primary">보호(안전장갑)</td>
                                                <td className="text-end ps-0">
                                                    <BMCCheckbox name={"isPrtctGlv1"} className={'ml-10'} checked={data?.isPrtctGlv1} onChange={fn_setFormData}>
                                                        일반
                                                    </BMCCheckbox>
                                                    <BMCCheckbox name={"isPrtctGlv2"} className={'ml-10'} checked={data?.isPrtctGlv2} onChange={fn_setFormData}>
                                                        내화학
                                                    </BMCCheckbox>
                                                    <BMCCheckbox name={"isPrtctGlv3"} className={'ml-10'} checked={data?.isPrtctGlv3} onChange={fn_setFormData}>
                                                        방염
                                                    </BMCCheckbox>
                                                    <BMCCheckbox name={"isPrtctGlv4"} className={'ml-10'} checked={data?.isPrtctGlv4} onChange={fn_setFormData}>
                                                        방열
                                                    </BMCCheckbox>
                                                    <BMCCheckbox name={"isPrtctGlv5"} className={'ml-10'} checked={data?.isPrtctGlv5} onChange={fn_setFormData}>
                                                        철면
                                                    </BMCCheckbox>
                                                    <BMCCheckbox name={"isPrtctGlv6"} className={'ml-10'} checked={data?.isPrtctGlv6} onChange={fn_setFormData}>
                                                        용접
                                                    </BMCCheckbox>
                                                    <BMCCheckbox name={"isPrtctGlv7"} className={'ml-10'} checked={data?.isPrtctGlv7} onChange={fn_setFormData}>
                                                        팔토시
                                                    </BMCCheckbox>
                                                </td>
                                            </tr>
                                            <tr>
                                                <td className="bmc-text-font_primary">보호의</td>
                                                <td className="text-end ps-0">
                                                    <BMCCheckbox name={"isPrtctClth1"} className={'ml-10'} checked={data?.isPrtctClth1} onChange={fn_setFormData}>
                                                        방진
                                                    </BMCCheckbox>
                                                    <BMCCheckbox name={"isPrtctClth2"} className={'ml-10'} checked={data?.isPrtctClth2} onChange={fn_setFormData}>
                                                        내화학
                                                    </BMCCheckbox>
                                                    <BMCCheckbox name={"isPrtctClth3"} className={'ml-10'} checked={data?.isPrtctClth3} onChange={fn_setFormData}>
                                                        방염
                                                    </BMCCheckbox>
                                                    <BMCCheckbox name={"isPrtctClth4"} className={'ml-10'} checked={data?.isPrtctClth4} onChange={fn_setFormData}>
                                                        방열
                                                    </BMCCheckbox>
                                                    <BMCCheckbox name={"isPrtctClth5"} className={'ml-10'} checked={data?.isPrtctClth5} onChange={fn_setFormData}>
                                                        밀폐
                                                    </BMCCheckbox>
                                                </td>
                                            </tr>
                                            <tr>
                                                <td className="bmc-text-font_primary">안전벨트</td>
                                                <td className="text-end ps-0">
                                                    <BMCCheckbox name={"isPrtctBlt1"} className={'ml-10'} checked={data?.isPrtctBlt1} onChange={fn_setFormData}>
                                                        안전벨트
                                                    </BMCCheckbox>
                                                </td>
                                            </tr>
                                            <tr>
                                                <td className="bmc-text-font_primary">귀보호</td>
                                                <td className="text-end ps-0">
                                                    <BMCCheckbox name={"isEarPrtct1"} className={'ml-10'} checked={data?.isEarPrtct1} onChange={fn_setFormData}>
                                                        귀마개
                                                    </BMCCheckbox>
                                                    <BMCCheckbox name={"isEarPrtct2"} className={'ml-10'} checked={data?.isEarPrtct2} onChange={fn_setFormData}>
                                                        귀덮개
                                                    </BMCCheckbox>
                                                </td>
                                            </tr>
                                            <tr>
                                                <td className="align-middle bmc-text-font_primary">기타</td>
                                                <td>
                                                    <BMCInput className="bmc-form input" name={'sftyPrtctEtcCntnts'} value={data?.sftyPrtctEtcCntnts} onChange={fn_setFormData}/>
                                                </td>
                                            </tr>
                                            </tbody>
                                        </Table>
                                    </div>
                                </Col>
                            </Row>
                        </div>
                    </div>

                    <div className="bmc-modal-div under">
                        <div className="div-header justify-content-start">
                            작업수행부서(협력회사) 안전조치 및 작업준비
                        </div>
                        <div className="div-body">
                            <Row>
                                <Col className="py-2">
                                    <div className="px-3 py-2 h-100" style={{borderRadius:'4px',border:'1px solid #eeeeee'}}>
                                        <Table borderless className="mb-0 bmc-checklist">
                                            <tbody>
                                            <tr>
                                                <th>
                                                    작업시 안전 조치
                                                </th>
                                            </tr>
                                            <tr>
                                                <td className="border-bottom-0 py-3">
                                                    <Row className="row-cols-2">
                                                        <Col className="mb-2">
                                                            <BMCCheckbox name={"isWrkSfty1"} checked={data?.isWrkSfty1} onChange={fn_setFormData}>
                                                                위험/작업 표지판 설치
                                                            </BMCCheckbox>
                                                        </Col>
                                                        <Col className="mb-2">
                                                            <BMCCheckbox name={"isWrkSfty2"} checked={data?.isWrkSfty2} onChange={fn_setFormData}>
                                                                작업자 안전교육 실시
                                                            </BMCCheckbox>
                                                        </Col>
                                                        <Col>
                                                            <BMCCheckbox name={"isWrkSfty3"} checked={data?.isWrkSfty3} onChange={fn_setFormData}>
                                                                작업구역 내 통제
                                                            </BMCCheckbox>
                                                        </Col>
                                                        <Col>
                                                            <BMCCheckbox name={"isWrkSfty4"} checked={data?.isWrkSfty4} onChange={fn_setFormData}>
                                                                작업공구 및 도구의 적합성
                                                            </BMCCheckbox>
                                                        </Col>
                                                    </Row>
                                                </td>
                                            </tr>
                                            </tbody>
                                        </Table>
                                    </div>

                                </Col>
                                <Col className="py-2">
                                    <div className="px-3 py-2" style={{borderRadius:'4px',border:'1px solid #eeeeee'}}>
                                        <Table borderless className="mb-0 bmc-checklist">
                                            <tbody>
                                            <tr>
                                                <th>
                                                    작업준비상태 확인
                                                </th>
                                            </tr>
                                            <tr>
                                                <td className="border-bottom-0 py-3">
                                                    <Row className="px-0">
                                                        <Col className="ps-1">
                                                            <div className="px-3 py-2 bmc-text-font_primary" style={{backgroundColor:'#fff8f8',borderRadius:'4px'}}>
                                                                <div>안전관리자</div>
                                                                <div className="text-end bmc-fw-5">김길동</div>
                                                            </div>
                                                        </Col>
                                                        <Col className="pe-1">
                                                            <div className="px-3 py-2 bmc-text-font_primary" style={{backgroundColor:'#f5f5f5',borderRadius:'4px'}}>
                                                                <div>관리감독자</div>
                                                                <div className="text-end bmc-fw-5">김길동</div>
                                                            </div>
                                                        </Col>
                                                    </Row>
                                                </td>
                                            </tr>
                                            </tbody>
                                        </Table>
                                    </div>
                                </Col>
                            </Row>
                        </div>
                    </div>

                    <div className="bmc-modal-div under">
                        <div className="div-header">
                            첨부파일
                        </div>
                        <div className="div-body">

                        </div>
                    </div>
                </Modal.Body>
                <Modal.Footer className="py-2">
                    <div className='flex justify-center w-100'>
                        <button
                            className='bmc-btn pill l white me-3'
                            onClick={fn_closeModal}>취소
                        </button>
                        <button onClick={fn_registData}
                                className='bmc-btn pill p'>저장
                        </button>
                    </div>
                </Modal.Footer>
            </Modal>
            {/*<WrkrSrchModal setModalIsOpen={setIsWrkrModalOpen} modalIsOpen={isWrkrModalOpen} wrkrDataList={wrkrDataList}
                           setWrkrDataList={setWrkrDataList}/>

            <Modal
                isOpen={props.modalIsOpen}
                style={COMMON_MODAL_STYLE_SCROLL}
                onAfterOpen={fn_onAfterOpen}
            >
                <div
                    className={'flex shrink-0 items-center justify-between py-2.5 border-b border-solid border-[#dee2e6]  text-black'}>
                    <h5 className="modal-title">작업허가서 등록/수정</h5>
                    <IoMdClose className='hover:cursor-pointer' onClick={fn_closeModal}/>
                </div>
                <form name={'wrkPrmtRgstForm'} className={'relative text-black min-h-[305px] max-w-[800px] mt-16'}
                      onSubmit={submit}>
                    <div className={'row mt-3 mb-2'}>
                        <div className={'col-md-6'}>
                            <h6 className="">작업허가서</h6>
                        </div>

                    </div>
                    <div className={'row'}>
                        <div className={'col-md-6'}>
                            <div className="input-group input-group-sm mb-3 required">
                                <span className="input-group-text">현장</span>
                                <BMCSelect initOptionGb={'none'} name={'siteId'} disabled={true} codeGb={'site'}
                                          onChange={fn_setFormData}
                                          value={data?.siteId}></BMCSelect>
                            </div>
                        </div>
                        <div className={'col-md-6'}>
                            <div className="input-group input-group-sm mb-3 required"
                                 ref={prodRef}>
                                <span className="input-group-text">작업일</span>
                                <input
                                    className={"form-control cursor-pointer text-center"} onClick={fn_datePopupOpen}
                                    value={wrkDt} name={'eduDt'} onChange={fn_setFormData} readOnly={true}/>

                                <img className='input-group-text cursor-pointer' src={iconCalendar}
                                     onClick={fn_datePopupOpen} alt='calendar'/>

                                {isDatePopupOpen
                                    ? <div className='absolute top-33 left-80'>
                                        <CustomDatePicker setIsDatePopupOpen={setIsDatePopupOpen}
                                                          prodRef={prodRef}
                                                          setDateView={setWrkDt}
                                        />
                                    </div>
                                    : null}

                            </div>
                        </div>

                        <div className={'col-md-6'}>
                            <div className="input-group input-group-sm mb-3 required">
                                <span className="input-group-text">작업책임자</span>
                                <MultiSelect className={'form-control'}
                                             options={chrgrDataList}
                                             value={selChrgrs}
                                             onChange={setSelChrgrs}
                                             labelledBy={"작업책임자 선택"}
                                             isCreatable={false}
                                             overrideStrings={COMMON_MULTISEL_STRINGS}
                                />
                            </div>
록
                        </div>
                        <div className={'col-md-6'}>
                            <div className="input-group input-group-sm mb-3 required">
                                <span className="input-group-text">작업업체명</span>
                                <BMCSelect initOptionGb={'none'} name={'cmpnySeq'} selParam={{siteId: user.blngSiteId}}
                                          disabled={true} codeGb={'siteCmpny'}
                                          onChange={fn_setFormData}
                                          value={data?.cmpnySeq}></BMCSelect>
                            </div>
                        </div>

                        <div className={'col-md-12'}>
                            <div className="input-group input-group-sm mb-3 required">
                                <span className="input-group-text">작업시간</span>
                                <BMCSelect initOptionGb={'sel'} name={'wrkTimeGbCd'}
                                          classNmae={'form-select text-center mr-10'} codeGb={'cmmn'} codeGrpId={'T001'}
                                          onChange={(e) => {
                                              fn_onWrkTimeSelChg(e);
                                          }}
                                          value={data?.wrkTimeGbCd}></BMCSelect>
                                <select className={'form-select'} value={data?.wrkStrtHr || ''}
                                        onChange={fn_setFormData} name={'wrkStrtHr'}>
                                    {
                                        wrkHrList.map((data: string, idx: number) => (
                                            <option className={'text-center'} key={idx} value={data}>{data}</option>
                                        ))
                                    }

                                </select>
                                <span className="input-group-text">시</span>
                                <select className={'form-select'} name={'wrkStrtMi'} onChange={fn_setFormData}
                                        value={data?.wrkStrtMi || ''}>
                                    {
                                        wrkMiList.map((data: string, idx: number) => (
                                            <option className={'text-center'} key={idx} value={data}>{data}</option>
                                        ))
                                    }
                                </select>
                                <span className="input-group-text mr-10">분</span>
                                <span className="input-group-md mr-10">~</span>
                                <select className={'form-select'} value={data?.wrkEndHr || ''} onChange={fn_setFormData}
                                        name={'wrkEndHr'}>
                                    {
                                        wrkHrList.map((data: string, idx: number) => (
                                            <option className={'text-center'} key={idx} value={data}>{data}</option>
                                        ))
                                    }

                                </select>
                                <span className="input-group-text">시</span>
                                <select className={'form-select'} name={'wrkEndMi'} onChange={fn_setFormData}
                                        value={data?.wrkEndMi || ''}>
                                    {
                                        wrkMiList.map((data: string, idx: number) => (
                                            <option className={'text-center'} key={idx} value={data}>{data}</option>
                                        ))
                                    }
                                </select>
                                <span className="input-group-text">분</span>
                            </div>
                        </div>

                    </div>
                    <hr/>
                    <div className={'row mt-3 mb-2'}>
                        <div className={'col-md-6'}>
                            <h6 className="">작업 내용</h6>
                        </div>
                        <div className={'col-md-6 text-end input-group-sm'}>
                            <button className="btn btn-secondary" type="button"
                                    onClick={fn_toggleWrkCntnts}>{isWrkCntntsHide ? '수기입력 보이기' : '수기입력 숨기기'}
                            </button>
                        </div>
                    </div>

                    {!isWrkCntntsHide &&
                        <>
                            <div className={'row'}>
                                <div className={'col-md-6'}>
                                    <div className="input-group input-group-sm mb-3">
                                        <span className="input-group-text">공종</span>
                                        <BMCSelect initOptionGb={'sel'} name={'wrkTypeCd'}
                                                  selParam={{siteId: user.blngSiteId, manageCode: 'M001001'}}
                                                  codeGb={'siteMngCd'}
                                                  onChange={fn_setFormData}
                                                  value={data?.wrkTypeCd}></BMCSelect>
                                    </div>
                                </div>
                                <div className={'col-md-6'}>
                                    <div className="input-group input-group-sm mb-3">
                                        <span className="input-group-text">작업위치</span>
                                        <BMCSelect initOptionGb={'sel'} name={'wrkPlcCd'}
                                                  selParam={{siteId: user.blngSiteId, manageCode: 'M001004'}}
                                                  codeGb={'siteMngCd'}
                                                  onChange={fn_setFormData}
                                                  value={data?.wrkPlcCd}></BMCSelect>
                                    </div>
                                </div>
                                <div className={'col-md-6'}>
                                    <div className="input-group input-group-sm mb-3">
                                        <span className="input-group-text">작업인원</span>
                                        <BMCInput type="number" name={'wrkrCnt'} value={data?.wrkrCnt}
                                                 onChange={fn_setFormData} className="form-control"
                                        />
                                    </div>
                                </div>
                                <div className={'col-md-6'}>
                                    <div className="input-group input-group-sm mb-3">
                                        <span className="input-group-text">중장비</span>
                                        <MultiSelect className={'form-control'}
                                                     options={eqmntDataList}
                                                     value={selEqmnts}
                                                     onChange={setSelEqmnts}
                                                     labelledBy={"중장비 선택"}
                                                     isCreatable={false}
                                                     overrideStrings={COMMON_MULTISEL_STRINGS}
                                        />


                                    </div>
                                </div>
                            </div>
                            <div className="card ga-card">
                                <div className="card-body">
                                    <table
                                        className="table table-sm text-center table-bordered border-secondary align-middle text-nowrap">
                                        <thead>
                                        <tr className="table-secondary">
                                            <td className="align-middle w-[70%]">장비명</td>
                                            <td className="align-middle w-[30%]">수량</td>

                                        </tr>
                                        </thead>
                                        <tbody>
                                        {
                                            selEqmnts.length > 0 ?
                                                selEqmnts.map((data: any, idx: number) => (
                                                    <tr key={idx}>
                                                        <td className={'text-center'}>{data.label}
                                                        </td>
                                                        <td>
                                                            <select className={'form-select text-right'}
                                                                    name={'eqmntCnt'} value={data.eqmntCnt}
                                                                    onChange={(e) => {
                                                                        fn_updateSelEqmntsData(e, idx)
                                                                    }}>
                                                                {
                                                                    eqmntOpts.map((data: number) => (
                                                                        <option key={data} value={data}>{data}</option>
                                                                    ))
                                                                }
                                                            </select>
                                                        </td>

                                                    </tr>

                                                )) : <tr>
                                                    <td colSpan={2}>중장비를 선택해 주세요.</td>
                                                </tr>
                                        }

                                        </tbody>
                                    </table>
                                </div>
                            </div>
                        </>

                    }


                    <hr/>
                    <div className={'row mt-3 mb-2'}>
                        <div className={'col-md-6'}>
                            <h6 className="">금일작업 사항</h6>
                        </div>
                        <div className={'col-md-6 text-end input-group-sm'}>
                            <button className="btn btn-secondary" type="button" onClick={fn_addTodayWrkRow}>추가
                            </button>
                        </div>
                    </div>

                    <div className="card ga-card">
                        <div className="card-body">
                            <table
                                className="table table-sm text-center table-bordered border-secondary align-middle text-nowrap">
                                <thead>
                                <tr className="table-secondary">
                                    <td className="align-middle w-[20%]">직종</td>
                                    <td className="align-middle w-[20%]">작업자 선택</td>
                                    <td className="align-middle w-[54%]">금일 작업사항</td>
                                    <td className="align-middle w-[8%]">저장</td>
                                    <td className="align-middle w-[8%]">삭제</td>
                                </tr>
                                </thead>
                                <tbody>
                                {
                                    todayWrkInfoList.length > 0 ?
                                        todayWrkInfoList.map((todayWrkData: any, idx) => (
                                            <tr key={idx}>
                                                <td><BMCSelect initOptionGb={'sel'} name={'ocptCd'}
                                                              selParam={{
                                                                  siteId: user.blngSiteId,
                                                                  manageCode: 'M001002'
                                                              }} onChange={(e) => {
                                                    fn_updateTodayWrkInfoData(e, idx)
                                                }}
                                                              codeGb={'siteMngCd'}
                                                              value={todayWrkData.ocptCd}></BMCSelect>
                                                </td>
                                                <td>
                                                    <div className="input-group input-group-sm">

                                                        <BMCInput className={'form-control text-right'} disabled={true}
                                                                 value={(finalWrkrDataList[idx].length || 0) + ' 명'}/>
                                                        <img
                                                            className={'w-[40px] cursor-pointer inline-block input-group-text'}
                                                            src={iconUser} onClick={() => fn_wrkrModalOpen(idx)}/>
                                                    </div>

                                                </td>


                                                <td><BMCInput name={'wrkCntnts'} value={todayWrkData.wrkCntnts}
                                                             onChange={(e) => {
                                                                 fn_updateTodayWrkInfoData(e, idx)
                                                             }}/></td>
                                                <td className={'text-center'}><img
                                                    className={'w-[25px] cursor-pointer inline-block'}
                                                    src={iconSave} onClick={() => fn_saveRow(idx)}/></td>
                                                <td className={'text-center'}><img
                                                    className={'w-[25px] cursor-pointer inline-block'}
                                                    src={iconTrashBean} onClick={() => fn_delRow(idx)}/></td>
                                            </tr>

                                        )) : <tr>
                                            <td colSpan={5}>금일 작업사항을 등록해 주세요.</td>
                                        </tr>
                                }

                                </tbody>
                            </table>
                        </div>
                    </div>

                    <hr/>
                    <div className={'row mt-3 mb-2'}>
                        <div className={'col-md-6'}>
                            <h6 className="">근로자별 작업 현황</h6>
                        </div>
                    </div>

                    <div className="card ga-card">
                        <div className="card-body">
                            <table
                                className="table table-sm text-center table-bordered border-secondary align-middle text-nowrap">
                                <thead>
                                <tr className="table-secondary">
                                    <td className="align-middle w-[20%]">직종</td>
                                    <td className="align-middle w-[20%]">이름</td>
                                    <td className="align-middle w-[20%]">특이사항</td>
                                    <td className="align-middle w-[20%]">금일 작업사항</td>
                                    <td className="align-middle w-[12%]">비고</td>
                                    <td className="align-middle w-[8%]">삭제</td>
                                </tr>
                                </thead>
                                <tbody id="wrkDclrRgst_siteTBody">
                                {
                                    finalWrkrDataList.length > 0 ?
                                        finalWrkrDataList.map((finalWrkData: any, parIdx) => (
                                            finalWrkData.length > 0 &&
                                            finalWrkData.map((data: any, idx: number) => (
                                                data.isCnfrmd &&
                                                <tr key={idx}>
                                                    <td><BMCSelect initOptionGb={'sel'} name={'ocptCd'} disabled={true}
                                                                  classNmae={'form-control text-center'}
                                                                  selParam={{
                                                                      siteId: user.blngSiteId,
                                                                      manageCode: 'M001002'
                                                                  }} onChange={(e) => {
                                                        fn_updateTodayWrkInfoData(e, idx)
                                                    }}
                                                                  codeGb={'siteMngCd'} value={data.ocptCd}></BMCSelect>
                                                    </td>
                                                    <td>
                                                        {data.userName}
                                                    </td>
                                                    <td>
                                                        {data.sgnfcnt}
                                                    </td>
                                                    <td>
                                                        {data.wrkCntnts}
                                                    </td>
                                                    <td>
                                                        <BMCInput value={data.bigo} name={'bigo'} onChange={(e) => {
                                                            fn_updateWrkStatInfoData(e, parIdx, idx);
                                                        }}/>
                                                    </td>
                                                    <td>
                                                        <img
                                                            className={'w-[25px] cursor-pointer inline-block'}
                                                            src={iconTrashBean} onClick={() => {
                                                            fn_delWrkStatRow(parIdx, idx)
                                                        }}/>
                                                    </td>
                                                </tr>
                                            ))


                                        )) : <tr>
                                            <td colSpan={6}>허가서에 포함된 근로자가 없습니다.</td>
                                        </tr>
                                }

                                </tbody>
                            </table>
                        </div>
                    </div>

                    <hr/>
                    <div className={'row mt-3 mb-3'}>
                        <div className={'col-md-12'}>
                            <h6 className="">유해/위험성 확인 및 안전보호구 선정/착용 (안전화, 안전모, 보안경은 필수 착용/방독마스트는 필수 휴대)</h6>
                        </div>
                    </div>

                    <div className={'row'}>
                        <div className={'col-md-3'}>
                            <span>유해/위험성:</span>
                        </div>
                        <div className={'col-md-6'}>
                            <div className="input-group input-group-sm">
                                <span>-설비 표면 또는 작업구간 온도 위험성:</span>
                            </div>
                            <div className="input-group input-group-sm mt-2">
                                <span>- 작업중 추락의 위험성:</span>
                            </div>
                            <div className="input-group input-group-sm mt-2">
                                <span>- 산소 결핍 또는 유해가스의 위험성:</span>
                            </div>
                            <div className="input-group input-group-sm mt-2">
                                <span>- 작업 시 감전 가능성:</span>
                            </div>
                            <div className="input-group input-group-sm mt-2">
                                <span>- 굴착 작업 시 지장물 파손 위험성:</span>
                            </div>
                            <div className="input-group input-group-sm mt-2">
                                <span>- 기타 위험성:</span>
                            </div>
                        </div>
                        <div className={'col-md-3'}>
                            <div className="input-group input-group-sm">
                                <BMCRadioGroup>
                                    <BMCRadio name="isDngr1" value={true} checked={data && data.isDngr1 === true} onChange={fn_setFormData} >
                                        있음
                                    </BMCRadio>
                                    <BMCRadio name="isDngr1" className={'ml-10'} value={false} checked={data&& data.isDngr1 === false} onChange={fn_setFormData}>
                                        없음
                                    </BMCRadio>
                                </BMCRadioGroup>
                            </div>
                            <div className="input-group input-group-sm mt-2">
                                <BMCRadioGroup>
                                    <BMCRadio name="isDngr2" value={true} checked={data && data.isDngr2 === true} onChange={fn_setFormData}>
                                        있음
                                    </BMCRadio>
                                    <BMCRadio name="isDngr2" className={'ml-10'} checked={data && data.isDngr2 === false} value={false} onChange={fn_setFormData}>
                                        없음
                                    </BMCRadio>
                                </BMCRadioGroup>
                            </div>
                            <div className="input-group input-group-sm mt-2">
                                <BMCRadioGroup>
                                    <BMCRadio name="isDngr3" value={true} checked={data && data.isDngr3 === true} onChange={fn_setFormData}>
                                        있음
                                    </BMCRadio>
                                    <BMCRadio name="isDngr3" className={'ml-10'} checked={data && data.isDngr3 === false} value={false} onChange={fn_setFormData}>
                                        없음
                                    </BMCRadio>
                                </BMCRadioGroup>
                            </div>
                            <div className="input-group input-group-sm mt-2">
                                <BMCRadioGroup>
                                    <BMCRadio name="isDngr4" value={true} checked={data && data.isDngr4 === true} onChange={fn_setFormData}>
                                        있음
                                    </BMCRadio>
                                    <BMCRadio name="isDngr4" className={'ml-10'} checked={data && data.isDngr4 === false} value={false} onChange={fn_setFormData}>
                                        없음
                                    </BMCRadio>
                                </BMCRadioGroup>
                            </div>
                            <div className="input-group input-group-sm mt-2">
                                <BMCRadioGroup>
                                    <BMCRadio name="isDngr5" value={true} checked={data && data.isDngr5 === true} onChange={fn_setFormData}>
                                        있음
                                    </BMCRadio>
                                    <BMCRadio name="isDngr5" className={'ml-10'} checked={data && data.isDngr5 === false} value={false} onChange={fn_setFormData}>
                                        없음
                                    </BMCRadio>
                                </BMCRadioGroup>
                            </div>
                            <div className="input-group input-group-sm mt-2">
                                <BMCRadioGroup>
                                    <BMCRadio name="isDngr6" value={true} checked={data && data.isDngr6 === true} onChange={(e: any) => {
                                        fn_setFormData(e);
                                    }}>
                                        있음
                                    </BMCRadio>
                                    <BMCRadio name="isDngr6" className={'ml-10'} checked={data && data.isDngr6 === false} value={false} onChange={(e: any) => {
                                        fn_setFormData(e);
                                    }}>
                                        없음
                                    </BMCRadio>
                                </BMCRadioGroup>

                            </div>

                        </div>
                        {data?.isDngr6 == true &&
                            <>
                                <div className={'col-md-4'}></div>
                                <div className={'col-md-8'}>
                                    <div className="input-group input-group-sm mt-2">
                                        <BMCInput name={'dngrEtcCntnts'} value={data?.dngrEtcCntnts} onChange={fn_setFormData}/>
                                    </div>
                                </div>
                            </>}
                    </div>

                    <div className={'row mt-3'}>
                        <div className={'col-md-2'}>
                            <span>안전 보호구:</span>
                        </div>
                        <div className={'col-md-2'}>
                            <div className="input-group input-group-sm">
                                <span>안전화:</span>
                            </div>
                            <div className="input-group input-group-sm mt-2">
                                <span>보안면:</span>
                            </div>
                            <div className="input-group input-group-sm mt-2">
                                <span>호흡보호구:</span>
                            </div>
                            <div className="input-group input-group-sm mt-2">
                                <span>보호(안전 장갑):</span>
                            </div>
                            <div className="input-group input-group-sm mt-2">
                                <span>보호의:</span>
                            </div>
                            <div className="input-group input-group-sm mt-2">
                                <span>안전벨트:</span>
                            </div>
                            <div className="input-group input-group-sm mt-2">
                                <span>귀보호:</span>
                            </div>
                            <div className="input-group input-group-sm mt-2">
                                <span>기타:</span>
                            </div>
                        </div>
                        <div className={'col-md-8'}>
                            <div className="input-group input-group-sm">
                                <BMCCheckbox name={"isSftyShoe1"} className={'ml-10'} checked={data?.isSftyShoe1} onChange={fn_setFormData}>
                                    내화학
                                </BMCCheckbox>
                                <BMCCheckbox name={"isSftyShoe2"} className={'ml-10'} checked={data?.isSftyShoe2} onChange={fn_setFormData}>
                                    방열
                                </BMCCheckbox>
                                <BMCCheckbox name={"isSftyShoe3"} className={'ml-10'} checked={data?.isSftyShoe3} onChange={fn_setFormData}>
                                    절열
                                </BMCCheckbox>
                                <BMCCheckbox name={"isSftyShoe4"} className={'ml-10'} checked={data?.isSftyShoe4} onChange={fn_setFormData}>
                                    제전화
                                </BMCCheckbox>
                            </div>
                            <div className="input-group input-group-sm mt-2">
                                <BMCCheckbox name={"isPrtctMsk1"} className={'ml-10'} checked={data?.isPrtctMsk1} onChange={fn_setFormData}>
                                    일반
                                </BMCCheckbox>
                                <BMCCheckbox name={"isPrtctMsk2"} className={'ml-10'} checked={data?.isPrtctMsk2} onChange={fn_setFormData}>
                                    방염
                                </BMCCheckbox>
                                <BMCCheckbox name={"isPrtctMsk3"} className={'ml-10'} checked={data?.isPrtctMsk3} onChange={fn_setFormData}>
                                    방열
                                </BMCCheckbox>
                                <BMCCheckbox name={"isPrtctMsk4"} className={'ml-10'} checked={data?.isPrtctMsk4} onChange={fn_setFormData}>
                                    용접
                                </BMCCheckbox>
                            </div>
                            <div className="input-group input-group-sm mt-2">
                                <BMCCheckbox name={"isBrthPrtct1"} className={'ml-10'} checked={data?.isBrthPrtct1} onChange={fn_setFormData}>
                                    방진
                                </BMCCheckbox>
                                <BMCCheckbox name={"isBrthPrtct2"} className={'ml-10'} checked={data?.isBrthPrtct2} onChange={fn_setFormData}>
                                    전면방독
                                </BMCCheckbox>
                                <BMCCheckbox name={"isBrthPrtct3"} className={'ml-10'} checked={data?.isBrthPrtct3} onChange={fn_setFormData}>
                                    반면방독
                                </BMCCheckbox>
                                <BMCCheckbox name={"isBrthPrtct4"} className={'ml-10'} checked={data?.isBrthPrtct4} onChange={fn_setFormData}>
                                    공기호흡기
                                </BMCCheckbox>
                            </div>
                            <div className="input-group input-group-sm mt-2">
                                <BMCCheckbox name={"isPrtctGlv1"} className={'ml-10'} checked={data?.isPrtctGlv1} onChange={fn_setFormData}>
                                    일반
                                </BMCCheckbox>
                                <BMCCheckbox name={"isPrtctGlv2"} className={'ml-10'} checked={data?.isPrtctGlv2} onChange={fn_setFormData}>
                                    내화학
                                </BMCCheckbox>
                                <BMCCheckbox name={"isPrtctGlv3"} className={'ml-10'} checked={data?.isPrtctGlv3} onChange={fn_setFormData}>
                                    방염
                                </BMCCheckbox>
                                <BMCCheckbox name={"isPrtctGlv4"} className={'ml-10'} checked={data?.isPrtctGlv4} onChange={fn_setFormData}>
                                    방열
                                </BMCCheckbox>
                                <BMCCheckbox name={"isPrtctGlv5"} className={'ml-10'} checked={data?.isPrtctGlv5} onChange={fn_setFormData}>
                                    철면
                                </BMCCheckbox>
                                <BMCCheckbox name={"isPrtctGlv6"} className={'ml-10'} checked={data?.isPrtctGlv6} onChange={fn_setFormData}>
                                    용접
                                </BMCCheckbox>
                                <BMCCheckbox name={"isPrtctGlv7"} className={'ml-10'} checked={data?.isPrtctGlv7} onChange={fn_setFormData}>
                                    팔토시
                                </BMCCheckbox>
                            </div>
                            <div className="input-group input-group-sm mt-2">
                                <BMCCheckbox name={"isPrtctClth1"} className={'ml-10'} checked={data?.isPrtctClth1} onChange={fn_setFormData}>
                                    방진
                                </BMCCheckbox>
                                <BMCCheckbox name={"isPrtctClth2"} className={'ml-10'} checked={data?.isPrtctClth2} onChange={fn_setFormData}>
                                    내화학
                                </BMCCheckbox>
                                <BMCCheckbox name={"isPrtctClth3"} className={'ml-10'} checked={data?.isPrtctClth3} onChange={fn_setFormData}>
                                    방염
                                </BMCCheckbox>
                                <BMCCheckbox name={"isPrtctClth4"} className={'ml-10'} checked={data?.isPrtctClth4} onChange={fn_setFormData}>
                                    방열
                                </BMCCheckbox>
                                <BMCCheckbox name={"isPrtctClth5"} className={'ml-10'} checked={data?.isPrtctClth5} onChange={fn_setFormData}>
                                    밀폐
                                </BMCCheckbox>
                            </div>
                            <div className="input-group input-group-sm mt-2">
                                <BMCCheckbox name={"isPrtctBlt1"} className={'ml-10'} checked={data?.isPrtctBlt1} onChange={fn_setFormData}>
                                    안전벨트
                                </BMCCheckbox>
                            </div>
                            <div className="input-group input-group-sm mt-2">
                                <BMCCheckbox name={"isEarPrtct1"} className={'ml-10'} checked={data?.isEarPrtct1} onChange={fn_setFormData}>
                                    귀마개
                                </BMCCheckbox>
                                <BMCCheckbox name={"isEarPrtct2"} className={'ml-10'} checked={data?.isEarPrtct2} onChange={fn_setFormData}>
                                    귀덮개
                                </BMCCheckbox>
                            </div>
                            <div className="input-group input-group-sm mt-2">
                                <BMCInput name={'sftyPrtctEtcCntnts'} value={data?.sftyPrtctEtcCntnts} onChange={fn_setFormData}/>
                            </div>
                        </div>
                    </div>

                    <hr className={'mt-3'}/>
                    <div className={'row mt-3 mb-3'}>
                        <div className={'col-md-12'}>
                            <h6>작업수행부서(협력회사)안전조치 및 작업준비</h6>
                        </div>
                        <div className={'col-md-12 mt-2'}>
                            <span> 가. 작업시 안전조치 사항 ( 조치가 필요한 경우 "필요"란에 [V]. 조치완료시 "확인"란에 [V] 표기 ) </span>
                        </div>
                        <div className={'col-md-6 mt-2'}>
                            <div className="card ga-card">
                                <div className="card-body">
                                    <table
                                        className="table table-sm text-center table-bordered border-secondary align-middle text-nowrap">
                                        <thead>
                                        <tr className="table-secondary">
                                            <td className="align-middle" colSpan={2}>공통사항</td>
                                        </tr>
                                        <tr className="table-secondary">
                                            <td className="align-middle">조치</td>
                                            <td className="align-middle">필요</td>
                                        </tr>
                                        </thead>
                                        <tbody>
                                            <tr>
                                                <td>위험/작업 표지판 설치</td>
                                                <td>
                                                    <BMCCheckbox name={"isWrkSfty1"} checked={data?.isWrkSfty1} onChange={fn_setFormData}>
                                                    </BMCCheckbox>
                                                </td>
                                            </tr>
                                            <tr>
                                                <td>작업자 안전교육 실시</td>
                                                <td>
                                                    <BMCCheckbox name={"isWrkSfty2"} checked={data?.isWrkSfty2} onChange={fn_setFormData}>
                                                    </BMCCheckbox>
                                                </td>
                                            </tr>
                                            <tr>
                                                <td>작업구역 내 통제</td>
                                                <td>
                                                    <BMCCheckbox name={"isWrkSfty3"} checked={data?.isWrkSfty3} onChange={fn_setFormData}>
                                                    </BMCCheckbox>
                                                </td>
                                            </tr>
                                            <tr>
                                                <td>작업공구 및 도구의 적합성</td>
                                                <td>
                                                    <BMCCheckbox name={"isWrkSfty4"} checked={data?.isWrkSfty4} onChange={fn_setFormData}>
                                                    </BMCCheckbox>
                                                </td>
                                            </tr>
                                        </tbody>
                                    </table>
                                </div>
                            </div>
                        </div>
                    </div>





                    <hr/>
                    <h6 className="mt-3 mb-2">작성정보</h6>
                    <div className={'row'}>
                        <div className={'col-md-6'}>
                            <div className="input-group input-group-sm mb-3">
                                <span className="input-group-text">작성일</span>
                                <BMCInput value={currentDate} name={'rgstDt'} onChange={fn_setFormData}
                                         className={"form-control"}
                                         disabled={true}/>
                            </div>

                        </div>
                        <div className={'col-md-6'}>
                            <div className="input-group input-group-sm mb-3">
                                <span className="input-group-text">작성자</span>
                                <BMCInput className={"form-control"} name={'rgstrNm'}
                                         value={data?.rgstrNm || user?.userName}
                                         onChange={fn_setFormData}
                                         disabled={true}/>
                            </div>
                        </div>
                    </div>


                    <div className='flex justify-end mt-15'>
                        <button onClick={fn_registData}
                                className='text-15 font-preLight w-89 h-40 outline-none text-[#E05185] border-[#E05185] border-2 rounded-[5px] mr-5'>등록/수정

                        </button>
                        <button
                            className='text-15 font-preLight w-89 h-40 outline-none text-black text-black border-2 rounded-[5px]'
                            onClick={fn_closeModal}>닫기
                        </button>
                    </div>
                </form>
            </Modal>*/}
        </div>
    )
}

export default WrkPrmtDetailModal;

