import React, {useContext, useEffect, useRef, useState} from 'react';
import NoticeRegistModal from "../noticeManagement/modal/NoticeRegistModal";
import Datatables from "../component/Datatables";
import axios from "axios";
import {toast, ToastContainer} from "react-toastify";
import messageCodeDic from "../../util/messageCodeDic";
import errorCodeDic from "../../util/errorCodeDic";
import CnstrBaseInfoRgstModal from '../siteConstructionManagement/modal/CnstrBaseInfoRgstModal';
import iconSearch from "../../images/icon-search.svg";
import iconCalendar from "../../images/icon-calendar-black.svg";
import BMCSelect from "../../components/common/BMCSelect";
import {CmmnFn} from "../../helper/CmmnHelper";
import CmpnyInfoRgstModal from "../companyManagement/modal/CmpnyInfoRgstModal";
import SiteMangeItemRgstModal from './modal/SiteMangeItemRgstModal';
import {Col, Container, Row, Table } from 'react-bootstrap';
import SubMenuTitle from "../component/SubMenuTitle";
import iconEdit from "../../images/icon_edit.png";
import iconTrash from "../../images/icon_trashBean.png";
import iconCross from "../../images/icon_cross_01.png";
import iconArrow from "../../images/icon_arrow_01.png";
import iconReset from "../../images/icon_reset.png";
import restapi from "../../helper/restAPIHelper";
import {getLoginUser, IS_SM_SITE} from "../../helper/SecurityHelper";
import RskAsmntCdRgstModal from "../dngrEvalMng/modal/RskAsmntCdRgstModal";
import CnfgEqmntChkListModal from './modal/CnfgEqmntChkListModal';
import warnCodeDic from "../../util/warnCodeDic";
import {UserContext} from "../../context/User";
import BMCInput from "../../components/common/BMCInput";
import WrkSiteDtlModal from "./modal/WrkSiteDtlModal";

type Props = {};

const SiteManagementAdminPage = (props: Props) => {
    const [modalIsOpen, setModalIsOpen] = React.useState(false);
    const [cnfgItemList, setCnfgItemList] = useState<any>([]);
    const [manageItemList, setManageItemList] = useState<any>([]);
    const [isSaveSuccess, setIsSaveSuccess] = useState(false);
    const [cnfgItem, setCnfgItem] = useState<any>(null);
    const [manageItem, setManageItem] = useState<any>(null);
    const defaultUser = getLoginUser();
    const [isModalOpen, setIsModalOpen] = useState<boolean>(false);
    const {user} = useContext<any>(UserContext);
    const [isWrkSiteModalOpen, setIsWrkSiteModalOpen] = useState<boolean>(false);

    useEffect(()=>{


        setCnfgItem(null);
        setManageItem(null);
        fn_retrieveCnfgItemList();

    }, [user])

    useEffect(() => {

        if (cnfgItemList.length > 0) {
            if(cnfgItem){
                fn_retrieveSiteManageItemList(cnfgItem.code)
            }else{
                setManageItemList([]);
            }
        }
    }, [cnfgItemList, cnfgItem])


    useEffect(() => {
        if (isSaveSuccess) {
            fn_retrieveSiteManageItemList(cnfgItem.code);
            setIsSaveSuccess(false);
        }
    }, [isSaveSuccess])

    const fn_retrieveSiteManageItemList = (manageCode: string) => {

        const siteId = (user?.blngSiteId || defaultUser.blngSiteId);
        const param = {siteId:siteId, manageCode:manageCode}
        axios.post('/admin/api/manage/retrieveSiteManageItemList', param).then((result) => {
            setManageItemList(result.data)
        })
    }

    const fn_registPopOpen = (isNew:boolean) => {


        if(!cnfgItem){
            toast.warn('먼저 분류를 선택해 주십시오.');
            return;
        }

        if(isNew){
            setManageItem(null);
        }
        setModalIsOpen(true);

    }

    const fn_deleteSiteManageItem = (data: any) => {

        var manageName = data.manageName;
        var manageSn = data.manageSn;

        if(!window.confirm(manageName + ' 을(를) ' + messageCodeDic['004'])){
            return;
        }

        axios.post('/admin/api/manage/deleteSiteManageItemData', {manageSn:manageSn}).then((result) => {
            if(result.data > 0){
                fn_retrieveSiteManageItemList(cnfgItem.code);
                toast.success(messageCodeDic['002'])
            }else{
                if(result.data == -11){
                    toast.warn(warnCodeDic['001']);
                }else{
                    toast.error(errorCodeDic['003']);
                }


            }

        })
    }

    const fn_onSiteChange = () => {
        setCnfgItem(null);
        setManageItem(null);
        fn_retrieveCnfgItemList();
    }


    const fn_retrieveCnfgItemList = () => {
        let codes:any = [];

        if(CmmnFn.isSmSite()){
            codes = ['M001001', 'M001002', 'M001003', ,'M001006']
        }else{
            codes = ['M001001','M001002','M001003','M001004','M001005']
        }


        CmmnFn.loadCmmnCdData('M001', codes).then((resultData: any) => {

            if(cnfgItem){
                resultData.map((data: any) => {
                    if (cnfgItem == data.code) {
                        data.isSelected = true;
                    } else {
                        data.isSelected = false;
                    }
                });
            }
            setCnfgItemList(resultData);


        })

    }

    const fn_onCnfgItemChanged = (code: string) => {


        let cstmCnfgItemList = cnfgItemList;

        cstmCnfgItemList.map((data: any) => {
            if (code == data.code) {
                data.isSelected = true;
                setCnfgItem(data);
            } else {
                data.isSelected = false;
            }

        })

        setManageItem(null);

        setCnfgItemList([].concat(cstmCnfgItemList));

    }
    const fn_onManageItemChanged = (manageSn: string) => {
        let cstmManageItemList = manageItemList;

        cstmManageItemList.map((data: any) => {

            if (manageSn == data.manageSn) {
                data.isSelected = true;
                setManageItem(data);
            } else {
                data.isSelected = false;
            }

        })
        console.log('cnfgItem: ', cnfgItem);
        if(cnfgItem.code == "M001005"){
            setIsModalOpen(true);
        }else if(cnfgItem.code == "M001006"){
            setIsWrkSiteModalOpen(true);
        }
        setManageItemList([].concat(cstmManageItemList));
    }

    const fn_reset = () => {



    }
    return (
        <Container fluid className="bg-white px-0 bmc-txt-color-primary">
            <CnfgEqmntChkListModal setModalIsOpen={setIsModalOpen} modalIsOpen={isModalOpen}
                                 siteId={user?.blngSiteId || defaultUser.blngSiteId}
                                   manageItem={manageItem}
                                   detailData={cnfgItem}
            ></CnfgEqmntChkListModal>
            <WrkSiteDtlModal detailData={cnfgItem} setModalIsOpen={setIsWrkSiteModalOpen} modalIsOpen={isWrkSiteModalOpen} manageItem={manageItem} siteId={user?.blngSiteId || defaultUser.blngSiteId} />

            <SubMenuTitle title={'현장세부정보설정'} parentMenu={'현장 Admin'} />

            <div className="p-4 overflow-y-auto" style={{height: 'calc(100vh - 180px)'}}>
                <div className="rounded-[5px] border mb-3 px-3">
                    <SiteMangeItemRgstModal setModalIsOpen={setModalIsOpen} manageItem={manageItem} siteId={user?.blngSiteId || defaultUser.blngSiteId} modalIsOpen={modalIsOpen}
                                            detailData={cnfgItem} setIsSaveSuccess={setIsSaveSuccess}/>
                    <BMCInput type={'hidden'} name={'siteId'} value={user?.blngSiteId || defaultUser.blngSiteId}></BMCInput>
                </div>

                <div className="ge-result container-fluid" >
                    <div className="row">
                        <div className="col-md-3 pe-0">
                            <div className="input-group input-group-sm mb-3">
                                <div className="form-control p-3">
                                    <div className="ge-title01">분류</div>
                                    <div data-ge-area="step1">
                                        {
                                            cnfgItemList.length > 0 ?
                                                cnfgItemList.map((data: any, idx: number) => (

                                                    <div className="row" key={idx}>
                                                        <div className="col-12">
                                                            <div className="form-check">
                                                                <input className="form-check-input"
                                                                       name="lvl1Cd"
                                                                       type="checkbox"
                                                                       id={"lvl1_" + idx}
                                                                       onChange={() => {
                                                                           fn_onCnfgItemChanged(data.code)
                                                                       }}
                                                                       value={data.code}
                                                                       checked={data.isSelected || false}/>
                                                                <label className="form-check-label"
                                                                       htmlFor={"lvl1_" + idx}>{data.codeNm}</label>
                                                            </div>
                                                        </div>

                                                    </div>
                                                )) : <span>조회된 데이터가 없습니다.</span>


                                        }


                                    </div>


                                </div>
                                <span className="mt-2 px-3"><img
                                    src={iconArrow} width="9"
                                    className="align-self-start"/></span>

                            </div>
                        </div>
                        <div className="col-md-9 ps-0 pe-0">
                            <div className="input-group input-group-sm mb-3">
                                <div className="form-control p-3">
                                    <div className="ge-title01">항목</div>
                                    <div data-ge-area="step2">
                                        {
                                            manageItemList.length > 0 ?
                                                manageItemList.map((data: any, idx: number) => (

                                                    <div className="row row-hover" key={idx}>
                                                        <div className="col-9">
                                                            <div className="form-check">
                                                                <input className="form-check-input"
                                                                       name="lvl2Cd"
                                                                       id={"lvl2_" + idx}
                                                                       type="checkbox"
                                                                       onChange={() => {
                                                                           fn_onManageItemChanged(data.manageSn)
                                                                       }}
                                                                       value={data.manageSn}
                                                                       checked={data.isSelected || false}/>
                                                                <label className="form-check-label"
                                                                       htmlFor={"lvl2_" + idx}>{data.manageName}</label>
                                                            </div>
                                                        </div>
                                                        <div className="col-3">
                                                            <div className="d-flex align-items-center w-100">
                                                                <img onClick={() => {setManageItem(data); fn_registPopOpen(false);}}
                                                                     src={iconEdit} width="20"
                                                                     className="align-self-end cursor-pointer"/>


                                                                <img onClick={() => {fn_deleteSiteManageItem(data)}}
                                                                     src={iconTrash}
                                                                     width="20"
                                                                     className="align-self-end cursor-pointer ml-5"/>




                                                            </div>
                                                        </div>
                                                    </div>
                                                )) : <span>조회된 데이터가 없습니다.</span>


                                        }

                                    </div>
                                    <div className="w-100 d-inline-flex text-center justify-content-between">
                                        <button className="ge-add01"  onClick={() => {
                                            fn_registPopOpen(true);
                                        }
                                        }><img className={'ge-img'}
                                               src={iconCross} width="13"/></button>
                                    </div>

                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </Container>
    );
};

export default SiteManagementAdminPage;
