import { Container } from "react-bootstrap";
import HOTotalWeatherInfoV2 from "../headoffice/HOTotalWeatherInfoV2";
import SiteRiskStatus from "../home/SiteRiskStatus";
import HOEtcInfoV2 from "../headoffice/HOEtcInfoV2";
import HOLocalFieldStatus from "../headoffice/HOLocalFieldStatus";
import React from "react";
import IvstTtlChkSttsInfo from "../headoffice/IvstTtlChkSttsInfo";
import IvstTdyStChkSttsInfo from "../headoffice/IvstTdyStChkSttsInfo";
import IvstStStts from "../headoffice/IvstStStts";
import IvstMntrngMpInfo from "../headoffice/IvstMntrngMpInfo";
import IvstMapField from "./IvstMapField";

export default function IvstMonitoringPage() {
    return (
        <Container fluid className="px-0" style={{backgroundColor:'#0E1841'}}>
            <div className="d-flex w-100 overflow-hidden" style={{height: 'calc(100vh - 100px)'}}>
                <div className="d-flex flex-grow-1 ho-monitoring-main-left-area">
                    <div className="d-flex w-100">
                        <div className="d-flex flex-row w-100 gap-3">
                            <div className="flex-grow-1" style={{height: 'calc(100vh - 145px)', overflowY:"auto", overflowX:"hidden"}}>
                                <div className="d-flex flex-column gap-3">
                                    <div className={"row h-2/4"}>
                                        <div className={"col-3"}>
                                            <HOTotalWeatherInfoV2 />
                                        </div>
                                        <div className={"col-3"}>
                                            <IvstTtlChkSttsInfo />
                                        </div>
                                        <div className={"col-6"}>
                                            <IvstTdyStChkSttsInfo />
                                        </div>
                                    </div>

                                    <div className="d-flex gap-4">
                                        <div className={'w-2/4'}>
                                            <IvstMapField/>
                                        </div>
                                        <div className={'w-2/4'}>
                                            <IvstStStts />
                                        </div>
                                    </div>
                                </div>
                            </div>

                        </div>
                    </div>
                </div>
            </div>
        </Container>
    )
}
