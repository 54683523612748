import React, {useContext, useEffect, useRef, useState} from "react";
import sizeConfigs from "../configs/sizeConfigs";
import colorConfigs from "../configs/colorConfigs";
import {AppBar, Drawer, List, Toolbar} from "@mui/material";
import SidebarItemCollapse from "./SidebarItemCollapse";
import SidebarItem from "./SidebarItem";
import {getLoginUser, IS_SM_SITE, logout, setItemWithExpireTime, USER_SESSION} from "../../helper/SecurityHelper";
import {useNavigate} from "react-router-dom";
import {UserContext} from "../../context/User";
import {toast} from "react-toastify";
import axios from "axios";
import restapi from "../../helper/restAPIHelper";
import errorCodeDic from "../../util/errorCodeDic";
import appRoutesSmSite from "../../routes/appRoutesSmSite";
import {Constatnts} from "../../util/Constants";
import RcvNtfyListModal from "../../pages/headoffice/modal/RcvNtfyListModal";
import BMCIconButton from "./BMCIconButton";
import {ProgressContext} from "../../context/Progress";
import {ReactComponent as LogoBmcSite} from "../../assets/images/logo/logo_bmc-site.svg";
import {ReactComponent as IconSample} from '../../assets/images/icon/icon-sample.svg';
import {ReactComponent as IconBell} from '../../assets/images/icon/icon-bell.svg';
import {ReactComponent as IconHeaderLine} from '../../assets/images/icon/icon-header-line-v2.svg';
import {ReactComponent as IconHeaderLogout} from '../../assets/images/icon/icon-header-logout-white.svg';
import {ReactComponent as LogoMenubarGsil} from '../../assets/images/logo/logo-menubar-gsil.svg';


type Anchor = 'top' | 'left' | 'bottom' | 'right';



const View = () => {
    const navigate = useNavigate();
    const [open, setOpen] = React.useState(false);
    const {user,setUser} = useContext<any>(UserContext);
    const defaultUser: any = getLoginUser();
    const [siteId, setSiteId] = useState<number>(defaultUser?.blngSiteId);
    const [siteCodeList, setSiteCodeList] = useState<any>([]);
    const siteIdRef = useRef<any>();
    const [isRcvNtfyModal, setIsRcvNtfyModal] = useState<boolean>(false);
    const [isNewNtfy, setIsNewNtfy] = useState<boolean>(false);
    const [rcvNtfyDataList, setRcvNtfyDataList] = useState<any>([]);
    const {spinner} = useContext(ProgressContext);
    const [date, setDate] = useState(() => new Date());
    const week = ['일요일', '월요일', '화요일', '수요일', '목요일', '금요일', '토요일'];
    let dayOfWeek = week[date.getDay()];
    const [wthrInfo, setWthrInfo] = useState<any>({});
    const [pm10Grade, setPm10Grade] = useState<string|null>(null);

    useEffect(() => {
        const pathname = window.location.pathname;
        console.log('user 123: ', user);

        if(pathname === '/' || pathname.indexOf("monitoring") > -1){
            setOpen(false)
        }else{
            setOpen(true)
        }
        restapi.retrieveWtherInfoData().then((rsltData) => {
            setWthrInfo(rsltData);
        })
    }, [])

    function fn_clickMenu(){
        setOpen(!open)
    }
    function fn_clickHome(){
        navigate("/smSite")
    }
    const fn_onSiteChange = (e: any) => {
        const siteId = e.target.value;
        setSiteId(siteId);
        restapi.changeCurrentUserSite({userSeq:defaultUser.userSeq, siteId:siteId, isRepSiteChg:true}).then((rsltCnt: any) => {
            if (rsltCnt > 0) {
                restapi.getUserSession(defaultUser.userSeq).then((user: any) => {
                    setUser(user);
                    setItemWithExpireTime(USER_SESSION, user);
                });
            }else{
                toast.error(errorCodeDic['003']);
            }

        });
        console.log('siteId: ', siteId);

    }

    useEffect(() => {
        console.log('cc site: ', siteId);
        restapi.retrieveSiteDataListNoPaging({siteId: siteId}).then((rsltData: any) => {

            if(rsltData.length > 0){
                localStorage.setItem(IS_SM_SITE, rsltData[0].isSmSite);
                if(rsltData[0].isSmSite){
                    let pathname = window.location.pathname

                    if(pathname.indexOf('/smSite/') == -1){
                        pathname = '/smSite' + pathname;
                    }

                    navigate(pathname);
                }else{
                    navigate('/monitoring');
                }

            }



        })

    }, [siteId]);
    useEffect(() => {
        console.log('user: ', user);
        fn_retrieveSiteCodeList().then((siteCodeList: any) => {
            setSiteCodeList(siteCodeList);

            if (siteCodeList.length == 0) {
                toast.warn('조회된 현장이 없습니다.');
                return;
            }

        })
    }, [])

    const fn_retrieveSiteCodeList = async () => {
        return new Promise(function (resolve, reject) {
            let param:any = {}

            if(defaultUser.authGrpId == 'SITE'){
                let siteIdList: any = [];
                const authChrgDataList = defaultUser.authChrgDataList;
                authChrgDataList.map((data: any) => {
                    siteIdList.push(data.siteId);
                })
                param.siteIdList = siteIdList;
            }
            if(param.siteIdList?.length > 0 || defaultUser.authGrpId == 'HEAD'){
                axios.post('/admin/api/site/retrieveSiteCodeList', param).then((result) => {
                    resolve(result.data);
                })
            }else{
                resolve([]);
            }
        });
    }
    const fn_openRcvNtfyPop = () => {
        setIsRcvNtfyModal(true);
    }

    const fn_moveToHeadWeb = () => {
        spinner.start();
        restapi.changeUserSiteHeadWebStat({blngSiteId: null, userSeq: defaultUser.userSeq}).then((rsltCnt: any) => {
            if (rsltCnt > 0) {
                restapi.getUserSession(defaultUser.userSeq).then((data: any) => {
                    setUser(data);
                    setItemWithExpireTime(USER_SESSION, data);
                    navigate('/')
                });
            }else{
                toast.error(errorCodeDic['003']);
            }
        }).catch(error => {
            toast.error(errorCodeDic['003']);
            console.log('error: ', error);
        })
            .finally(() => {
                spinner.stop();
            });
    }

    return (
        <React.Fragment>
            <RcvNtfyListModal setModalIsOpen={setIsRcvNtfyModal} modalIsOpen={isRcvNtfyModal} dataList={rcvNtfyDataList} siteId={user?.blngSiteId || defaultUser.blngSiteId}/>
            <AppBar
                position="fixed"
                sx={{
                    width: `100%`,
                    pl: sizeConfigs.sidebar.width,
                    boxShadow: "unset",
                    backgroundColor: colorConfigs.topbar.bg,
                    color: colorConfigs.topbar.color
                }}
            >
                <Toolbar className={"text-white"}>
                    <div className="d-flex flex-row justify-content-between w-100">
                        <div className="d-flex">
                            {/*<select className='bmc-menu-site-header-title-v2 site' name={'siteId'} disabled={defaultUser.authGrpId == 'HEAD'} ref={siteIdRef}
                                    onChange={fn_onSiteChange} value={siteId}>
                                {
                                    siteCodeList.length > 0 ? (
                                        siteCodeList.map((data: any, idx: number) => (
                                            <option key={idx} value={data.siteId}>{data.siteName}</option>
                                        ))
                                    ) : (
                                        <option key={1} value={'test'}>배정된 현장 없음</option>
                                    )
                                }
                            </select>*/}

                            <select className='bmc-menu-site-header-title-v2 site' name={'siteId'} disabled={defaultUser.authGrpId == 'HEAD'} ref={siteIdRef}
                                    onChange={fn_onSiteChange} value={siteId}>
                                {
                                    siteCodeList.length > 0 ? (
                                        siteCodeList.map((data: any, idx: number) => (
                                            <option key={idx} value={data.siteId}>{data.siteName}</option>
                                        ))
                                    ) : (
                                        <option key={1} value={'test'}>배정된 현장 없음</option>
                                    )
                                }
                            </select>
                        </div>

                        <div className="d-flex">
                            <span className="toolbar-system-title">현장용 안전관리 플랫폼</span>
                        </div>
                        <div className="d-flex flex-row align-items-center gap-2 px-4">
                            {
                                defaultUser.authGrpId == 'HEAD' &&
                                <BMCIconButton className="btn-sm" kind={'normal'} onClick={fn_moveToHeadWeb}>본사 웹</BMCIconButton>
                            }
                            <div className="d-flex align-items-center justify-content-center gap-2">
                                <IconSample width={20} height={20} />
                                <span className="site-header-user-name" style={{maxWidth:"100px",textOverflow:"ellipsis", overflow:"hidden", whiteSpace:"nowrap"}} title={defaultUser?.userName}>{defaultUser?.userName}</span>
                            </div>
                            <IconHeaderLine width={15} height={15} />
                            <div className="d-flex align-items-center justify-content-center relative cursor-pointer" onClick={fn_openRcvNtfyPop}>
                                <IconBell width={15} height={15} />
                                <span className="site-header-bell-count"></span>
                            </div>
                            <IconHeaderLine width={15} height={15} />
                            <div className="site-header-logout d-flex align-items-center justify-content-center cursor-pointer">
                                <IconHeaderLogout width={24} height={24} onClick={logout}/>
                            </div>
                        </div>
                    </div>
                </Toolbar>
            </AppBar>
            <Drawer
                variant="permanent"
                anchor={"left"}
                style={(open ? {width:"220px"} : {width:"220px"})}
                sx={{
                    overflow:"hidden",
                    flexShrink: 0,
                    zIndex: 9999,
                    "& .MuiDrawer-paper": {
                        position: "relative",
                        width: sizeConfigs.sidebar.width,
                        boxSizing: "border-box",
                        borderRight: "0px",
                        backgroundColor: "#1F2771",
                        color: colorConfigs.sidebar.color
                    }
                }}
            >
                <div className="d-flex px-15">
                    <LogoBmcSite width={220} height={100} onClick={fn_clickHome} />
                </div>
                <div className={"mt-4"} style={{height:"calc(100vh - 152px)", overflowY:"auto"}}>
                    <List className={"sh-menu-scroll shss-font-SD site-sidebar-menu "} onClick={()=>{setOpen(true)}}>
                        {defaultUser &&
                            appRoutesSmSite.map((route, index) => {
                                if(defaultUser.companyGbCd === 'C001005'){
                                    if(route.child){
                                        route.child = route.child.filter((data: any) => (!Constatnts.bldrAuthMenuList.includes(data.state)))
                                    }
                                }
                                return (
                                    route.sidebarProps ? (
                                        route.child ? (
                                            <SidebarItemCollapse item={route} key={index} />
                                        ) : (
                                            <SidebarItem item={route} key={index} isTopLevel={true}/>
                                        )
                                    ) : null
                                )})
                        }
                    </List>
                </div>
                <div className="align-self-center">
                    <LogoMenubarGsil height={28}/>
                </div>
            </Drawer>
        </React.Fragment>

        /*<React.Fragment>
            <RcvNtfyListModal setModalIsOpen={setIsRcvNtfyModal} modalIsOpen={isRcvNtfyModal} dataList={rcvNtfyDataList} siteId={user?.blngSiteId || defaultUser.blngSiteId}/>
            <Box
                position="fixed"
                sx={{
                    width: `calc(100vw - 150px)`,
                    height: '120px',
                    ml: sizeConfigsOld.sidebar.width,
                    boxShadow: "unset",
                    backgroundColor: colorConfigs.topbar.bg,
                    color: colorConfigs.topbar.color,
                    alignItems: 'center',
                    display: 'flex',
                    zIndex: 2
                }}
            >
                <Box className="bmc-menu-site-header w-100">

                    <Box className="bmc-menu-site-header">


                        <Box className="d-flex align-items-center px-4" style={{borderLeft: '1px dashed rgba(255,255,255,0.4)'}}>

                            <div className="me-2">
                                <span className="bmc-menu-site-head-header-weeks">{date.toLocaleDateString()} {dayOfWeek}</span>
                            </div>
                            <div className="d-flex align-items-center">
                                <img className="me-1" src={IconClock} style={{width: '22px', height: '22px'}} alt="시계"/>
                                <span className="bmc-menu-site-head-header-time">{date.toLocaleTimeString('en-GB')}</span>
                            </div>
                        </Box>
                        <Box className="d-flex align-items-center px-4" style={{borderLeft: '1px dashed rgba(255,255,255,0.4)'}}>
                            <div className="me-2">
                                <span className="bmc-txt-size-b6" style={{color: 'rgba(255,255,255,0.7)'}}>{'현재날씨'}</span>
                            </div>
                            {
                                wthrInfo.hasOwnProperty('sky') && (<div style={{marginLeft: 5, marginRight: 5, marginBottom: 5}}>
                                    { wthrInfo.pty === 1 ? <IconWthrRain fill='#fff' width={25} /> : wthrInfo.pty === 3 ? <IconWthrSnow fill='#fff' width={25} />
                                        : wthrInfo.sky === 1 ? <IconWthrShiny fill='#fff' width={25} /> : wthrInfo.sky === 3 ? <IconWthrCldy fill='#fff' width={25} /> : wthrInfo.sky === 1 ? <IconWthrCld fill='#fff' width={25} /> : ''}
                                </div>)
                            }

                            <span className="bmc-txt-size-h4 ms-2 text-white">
                            {wthrInfo.t1h ? wthrInfo.t1h + '°' : '-'}
                        </span>
                        </Box>
                        <Box className="d-flex align-items-center px-4" style={{borderLeft: '1px dashed rgba(255,255,255,0.4)'}}>
                            <div className="me-2">
                                <span className="bmc-txt-size-b6" style={{color: 'rgba(255,255,255,0.7)'}}>{'풍속'}</span>
                            </div>
                            <span className="bmc-txt-size-h4 text-white">
                            {wthrInfo.wsd ? wthrInfo.wsd + 'm/s' : '-'}
                        </span>
                        </Box>
                        <Box className="d-flex align-items-center px-4" style={{borderLeft: '1px dashed rgba(255,255,255,0.4)'}}>
                            <div className="me-2">
                                <span className="bmc-txt-size-b6" style={{color: 'rgba(255,255,255,0.7)'}}>{'미세먼지'}</span>
                            </div>
                            <span className="bmc-txt-size-h4 text-white">
                            {pm10Grade || '좋음'}
                        </span>
                        </Box>
                        <div className="mx-2 position-relative cursor-pointer"  onClick={fn_openRcvNtfyPop}>
                            <IconAlarm style={{width: '18px'}}/>

                            {isNewNtfy && <span className="position-absolute top-5 start-100 translate-middle p-1 bg-danger border border-light rounded-circle">
                                        <span className="visually-hidden">New alerts</span>
                                      </span>}
                        </div>
                    </Box>
                </Box>
            </Box>

            <Box style={{width: '150px', minHeight: '100vh', boxShadow: '0 0 10px #ccc', zIndex: 1, position:"fixed", backgroundColor:"white"}}>
                <div className="d-flex flex-column justify-content-between align-items-center h-100 py-4 px-2 bmc-txt-color-primary" style={{width:"150px"}}>
                    <Box>
                        <IconLogo style={{width: '98px', height: '47px'}} />
                    </Box>
                    <div className={"pt-3 pb-3"} style={{ height:"calc(100vh - 286px)", overflowY: "auto", marginTop: "30px", width:"100%"}}>
                        <Box className={'d-flex flex-column justify-content-center align-items-center'}>
                            {defaultUser &&
                                appRoutesSmSite.map((route, index) => {
                                    if(defaultUser.companyGbCd === 'C001005'){
                                        if(route.child){
                                            route.child = route.child.filter((data: any) => (!Constatnts.bldrAuthMenuList.includes(data.state)))
                                        }
                                    }
                                    return (
                                        route.sidebarProps ? (
                                            <SidebarItemOld item={route} key={index} />
                                        ) : null
                                    )})
                            }

                        </Box>
                    </div>
                    <Box className="d-flex flex-column justify-content-center align-items-center">
                        <Image style={{width: '50px', height: '50px', marginBottom: '5px'}} src={IconUnknownUser} roundedCircle />
                        <div className={"text-center"}>
                            <span className="bmc-txt-size-h5">{defaultUser?.userName}</span>
                        </div>
                        <div className="bmc-txt-size-b6 py-1" style={{color: '#8f95b2'}}>{defaultUser.blngCmpnyName}</div>
                        <IconButton
                            size="large"
                            aria-label="account of current user"
                            aria-controls="menu-appbar"
                            aria-haspopup="true"
                            color="inherit"
                            onClick={logout}
                        >
                            <IconLogout style={{width:'24px', fill: '#a5a5a5'}} />
                        </IconButton>
                    </Box>
                </div>
            </Box>
        </React.Fragment>*/
    );
};

export default View;
