import React, {useContext, useEffect, useRef, useState} from 'react';
import Modal from 'react-modal';
import {Col, Container, Row, Table} from 'react-bootstrap';
import SubMenuTitle from "../component/SubMenuTitle";
import {useNavigate} from "react-router-dom";


type IProps = {
};

Modal.setAppElement('#root');
const FcltySftyMngPage = (props: IProps) => {
    const navigate = useNavigate();

    useEffect(() => {
        navigate('/fclty');
    }, []);

    return (
        <Container fluid className="bg-white px-0 bmc-txt-color-primary">
            <SubMenuTitle title={'시설물안전 관리'} parentMenu={'현장 안전 관리'}/>

        </Container>
    );
};

export default FcltySftyMngPage;
