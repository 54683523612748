import React, {Dispatch, SetStateAction, useState} from "react";

interface IProps {
    items: object[]
}

export default function CmpnyCardList(props: IProps) {


    return (
        <div className="container-fluid px-0">
            <div className="row" id="ga-company-sites">
                {
                    props.items.map((item: any, idx) => {
                        return (
                            <div key={idx} className="col-md-4 mb-4">
                                <div className="card ga-card small">
                                    <div className="card-header">{item.companyGbNm}</div>
                                    <div className="card-body">
                                        <h5 className="card-title">{'업체명: ' + item.companyName}</h5>
                                        <p className="card-text">{'사업자등록번호: ' + (item.companyRegNum ? item.companyRegNum : '-')}</p>
                                        <p className="card-text">{'대표자: ' + (item.representativeName ? item.representativeName : '-')}</p>
                                        <p className="card-text">{'대표공종: ' + (item.sectorNm ? item.sectorNm : '-')}</p>
                                    </div>
                                </div>
                            </div>
                        )
                    })
                }


            </div>
        </div>
    )
}
