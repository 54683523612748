import React, {Dispatch, SetStateAction, useEffect, useRef, useState} from "react";
import {IoMdClose} from "react-icons/io";
import BMCSelect from "../../../components/common/BMCSelect";
import {Modal, Table} from "react-bootstrap";
import BMCTreeView from "../../../components/common/BMCTreeView";
import restapi from "../../../helper/restAPIHelper";
import Datatables, {getCheckedItems} from "../../component/Datatables";
import SHSearch from "../../component/BMCSearch";
import BMCInput from "../../../components/common/BMCInput";
import axios from "axios";
import {toast} from "react-toastify";
import iconTrashBean from "../../../images/icon_trashBean.png";
import messageCodeDic from "../../../util/messageCodeDic";
import errorCodeDic from "../../../util/errorCodeDic";

interface IProps {
    setModalIsOpen: Dispatch<SetStateAction<boolean>>;
    modalIsOpen: boolean;
    parentCode: string;
    siteId: number;
    codeLevel: number;
    dtlData: any;
}

const RskAsmntCdClassRgstModal = (props: IProps) => {
    const [dataList, setDataList] = useState<any>([])
    const [data, setData] = useState<any>()

    useEffect(() => {

        console.log('props.dtlData: ', props.dtlData);
        if(props.dtlData){
            setData({
                code:props.dtlData.code,
                codeName:props.dtlData.codeName,
            })
        }else{
            setData({
                code:'',
                codeName:'',
            })
        }



    }, [props.dtlData])

    const fn_afterOpenEvnt = () => {
        fn_retrieveSiteManageItemList();
    }


    function fn_closeModal() {
        props.setModalIsOpen(false);
    }

    const fn_setFormData = (e: any) => {
        setData({
            ...data,
            [e.target.name]: e.target.value
        })
    }


    const fn_save = () => {
        let paramDataList: any= [];
        let param: any = {siteId:props.siteId, parentCode:props.parentCode, rskAsmntCdDataList:[]}
        console.log('param:', param);
        let classNm;

        if(props.codeLevel == 1){
            classNm = '대분류';
        }else if(props.codeLevel == 2){
            classNm = '중분류';
        }else if(props.codeLevel == 3){
            classNm = '소분류';
        }

        if(!data || !data?.codeName){
            toast.warn(classNm + '를 입력해 주십시오');
            return;
        }


        data.codeLevel = props.codeLevel;
        data.parentCode = props.parentCode
        if(props.dtlData == null){
            data.chgStat = 'C';
        }else{
            data.chgStat = 'U';
        }

        paramDataList.push(data);
        param.rskAsmntCdDataList = paramDataList;

        console.log('param: ', param);

        if(!window.confirm('저장 하시겠습니까?')){
            return;
        }

        axios.post('/admin/api/rskAsmnt/saveRiskAsmntSiteCodeData', param).then((result) => {
            if (result.data > 0) {
                toast.success(messageCodeDic['001'])
                props.setModalIsOpen(false);
            } else {
                toast.error(errorCodeDic['003']);
            }
        })


    }

    const fn_retrieveSiteManageItemList = () => {

        const param = {code:props.parentCode,codeLevel:4,siteId:props.siteId, isCnfg:true}
        restapi.retrieveRskAsmntCodeDataList(param).then((result) => {
            setDataList(result)
        })
    }


    return (
        <div>

            <Modal
                className="bmc-modal sub" size={'sm'}
                show={props.modalIsOpen}
                onShow={fn_afterOpenEvnt}
                onHide={fn_closeModal}
            >

                <Modal.Header>
                    <div className="d-flex justify-content-between align-items-center w-100 bmc-font-SD">
                        {props.codeLevel == 1 ? '대분류' : props.codeLevel == 2 ? '중분류' : '소분류'}
                        <IoMdClose className='hover:cursor-pointer' onClick={fn_closeModal}/>
                    </div>
                </Modal.Header>

                <Modal.Body className="px-4 bmc-modal-table-w100">
                    <div className="bmc-modal-div">

                            <Table className="my-2 align-middle">
                                <tbody>
                                <tr>
                                    <th className="bmc-bg-th" style={{width: '25%'}}>{props.codeLevel == 1 ? '대분류' : props.codeLevel == 2 ? '중분류' : '소분류'}</th>
                                    <td style={{width: '75%'}}>
                                        <BMCInput type="text" className="form-control" name={'codeName'}
                                                 onChange={fn_setFormData} value={data?.codeName}/>
                                    </td>
                                </tr>
                                </tbody>
                            </Table>

                    </div>
                </Modal.Body>

                <Modal.Footer className="py-2">
                    <div className='flex justify-center w-100'>
                        <button
                            className='bmc-btn cancel p white me-3'
                            onClick={fn_closeModal}>닫기
                        </button>
                        <button onClick={fn_save}
                                className='bmc-btn confirm p'>저장
                        </button>
                    </div>
                </Modal.Footer>
            </Modal>
        </div>
    )
}

export default RskAsmntCdClassRgstModal;
