import React, {useContext, useEffect, useRef, useState} from 'react';
import axios from "axios";
import Datatables, {getCheckedItems} from "../component/Datatables";
import Modal from 'react-modal';

import {MultiSelect} from '@grapecity/wijmo.react.input';
import {getLoginUser} from "../../helper/SecurityHelper";
import {toast} from "react-toastify";
import messageCodeDic from "../../util/messageCodeDic";
import errorCodeDic from "../../util/errorCodeDic";

import CmpnyInfoRgstModal from "../companyManagement/modal/CmpnyInfoRgstModal";
import {ClipLoader} from 'react-spinners';
import {ProgressContext} from '../../context/Progress';
import SHSearch from '../component/BMCSearch';
import {Col, Container, Table, Row} from 'react-bootstrap';
import SubMenuTitle from "../component/SubMenuTitle";
import BMCIconButton from "../../components/common/BMCIconButton";
import {CmmnFn} from "../../helper/CmmnHelper";
import BMCSelect from "../../components/common/BMCSelect";
import BMCInput from "../../components/common/BMCInput";
import SiteSftyChkRgstModal from "../safetyWorkReportManagement/modal/SiteSftyChkRgstModal";

type Props = {};


Modal.setAppElement('#root');
const HeadApprStatPage = (props: Props) => {
    const [dataList, setDataList] = useState<any[]>([]);
    const [modalIsOpen, setModalIsOpen] = React.useState(false);
    const [detailData, setDetailData] = useState(null);
    const [siteCodeList, setSiteCodeList] = useState<any>([]);
    const [isSaveSuccess, setIsSaveSuccess] = useState(false);
    const [cmpnyCodeList, setCmpnyCodeList] = useState<any>([]);
    const user = getLoginUser();
    const siteIdRef = useRef<any>();
    const {spinner} = useContext(ProgressContext);
    const searchFormRef = useRef<any>();
    const [datatable, setDatatable] = useState<any>();
    const [data, setData] = useState<any>()
    const [isRefresh, setIsRefresh] = useState<any>(null);

    useEffect(() => {
        console.log('user: ', user);
        fn_retrieveSiteCodeList().then((siteCodeList: any) => {
            setSiteCodeList(siteCodeList);

            if (siteCodeList.length == 0) {
                toast.warn('조회된 현장이 없습니다.');
                return;
            }


        })
    }, [])

    useEffect(() => {
        if (isRefresh) {
            datatable?.draw();
        }
    }, [isRefresh])

    const fn_setSrchData = (e: any) => {

        setData({
            ...data,
            [e.target.name]: e.target.value
        })

    }
    const fn_delete = () => {
        let checkedData = getCheckedItems(datatable, "userSeq")

        if (checkedData.length == 0) {
            toast.warn(messageCodeDic['005']);
            return;
        }

        if (!window.confirm(messageCodeDic['004'])) {
            return;
        }

        axios.post('/admin/api/user/deleteUserData', {delList: checkedData}).then(rsltData => {
                if (rsltData.data > 0) {
                    toast.success(messageCodeDic['002'])
                    datatable?.draw();
                } else {
                    toast.error(errorCodeDic['003'])
                }

            }
        ).catch(() => {
                toast.error(errorCodeDic['003'])
            }
        )
    }

    const fn_retrieveSiteCodeList = async () => {
        return new Promise(function (resolve, reject) {
            let param:any = {}

            if(user.authGrpId == 'SITE'){
                let siteIdList: any = [];
                const authChrgDataList = user.authChrgDataList;
                authChrgDataList.map((data: any) => {
                    siteIdList.push(data.siteId);
                })
                param.siteIdList = siteIdList;
            }
            axios.post('/admin/api/site/retrieveSiteCodeList', param).then((result) => {
                resolve(result.data);
            })

        });
    }

    const fn_registPopOpen = () => {
        setDetailData(null);
        setModalIsOpen(true);
    }

    const fn_updateViewCnt = (param: object) => {
        axios.post('/admin/api/notice/updateViewCnt', param)
    }

    const fn_retrieveSiteManageItemList = (siteId: string) => {
        return new Promise(function (resolve, reject) {

            axios.post('/admin/api/manage/retrieveSiteManageItemList', {
                siteId: siteId,
                manageCode: 'M001003'
            }).then((result) => {
                resolve(result.data);
            })

        });
    }


    return (
        <Container fluid className="bg-white px-0 bmc-txt-color-primary">
            <SiteSftyChkRgstModal setModalIsOpen={setModalIsOpen} modalIsOpen={modalIsOpen} prcsGb={'rsltRgst'}
                                  detailData={detailData} sprvDprtGb={'C006002'} setIsRefresh={setIsRefresh}/>

            <SubMenuTitle title={'결재현황'} parentMenu={'설정'}/>

            <div className="p-4 overflow-y-auto" style={{height: 'calc(100vh - 197px)'}}>
                <SHSearch datatable={datatable} formRef={searchFormRef} btnType={'p'}>
                    <Row>
                        <Col>
                            <Table className="bmc-font-SP bmc-fs-b2 my-3">
                                <tbody>
                                <tr className="border-top border-bottom align-middle">
                                    <th className="bmc-bg-th" style={{width:'6%'}}>현장명</th>
                                    <td style={{width:'19%'}}>
                                        <input type={'hidden'} name={'apprUserSeq'} value={user.userSeq}/>
                                        <BMCInput type={'text'} className={'form-control'} onChange={fn_setSrchData} name={'siteName'} value={data?.siteName}></BMCInput>
                                    </td>
                                    <th className="bmc-bg-th" style={{width:'6%'}}>상태</th>
                                    <td style={{width:'19%'}}>
                                        <BMCSelect initOptionGb={'all'} name={'apprStat'} codeGb={'cmmn'} codeGrpId={'S002'} codes={['S002001', 'S002002']} className={"w-100"}
                                                   onChange={fn_setSrchData} value={data?.apprStat}></BMCSelect>
                                    </td>

                                </tr>
                                </tbody>
                            </Table>
                        </Col>
                    </Row>
                </SHSearch>

                <div className={'ge-result'}>
                    <Datatables
                        dataUrl={'/admin/api/siteSftyManage/retrieveSiteSftyManageDataList'}
                        parameterFormRef={searchFormRef}
                        columns={[
                            {data: "siteName", title: "현장명"},
                            {data: "sftyChkDt", title: "점검일"},
                            {data: "dprtNm", title: "부서명"},
                            {data: "apprStat", title: "상태", render: (data: any) => {
                                if(data === 'S002002'){
                                    return "<div class='bmc-box-status approvaldone'>결재완료</div>";
                                }else{
                                    return "<div class='bmc-box-status approval'>결재중</div>";
                                }

                                }},
                        ]}
                        checked={true}
                        index={true}

                        onLoad={(dt: any) => {
                            setDatatable(dt)
                        }}
                        onClick={(data) => {
                            setDetailData(data)
                            setModalIsOpen(true);
                            fn_updateViewCnt(data);
                        }
                        }
                    />
                </div>
            </div>
        </Container>


    );
};

export default HeadApprStatPage;
