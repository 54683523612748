import React from 'react';
import { Outlet } from 'react-router-dom';

type Props = {};

const SiteSafetyManagementPageLayout = (props: Props) => {
    return (
        <><Outlet /></>
    );
};

export default SiteSafetyManagementPageLayout;
