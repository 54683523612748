import React, {useContext, useEffect, useRef, useState} from 'react';
import {Col, Container, Row} from "react-bootstrap";
import {ReactComponent as IconStop} from "../../assets/images/icon/icon_prohibit.svg";
import {ReactComponent as IconSiren} from "../../assets/images/icon/icon_siren.svg";
import restapi from "../../helper/restAPIHelper";
import {CmmnFn} from "../../helper/CmmnHelper";
import WrkStopInfoListModal from "./modal/WrkStopInfoListModal";
import SosInfoListModal from "./modal/SosInfoListModal";
import {SiteContext} from "../../context/Site";
import {ReactComponent as IconSosCall} from "../../assets/images/icon/icon-sos-alert.svg";
import {ReactComponent as IconWrkStop} from "../../assets/images/icon/icon-wrk-stop.svg";
import {Doughnut} from "react-chartjs-2";
import InOutStatModal from "./modal/InOutStatModal";
import SftyChkInfoListModal from "./modal/SftyChkInfoListModal";
import SiteSftyChkInfoListModal from "./modal/SiteSftyChkInfoListModal";
import WrkPrmtInfoListModal from "./modal/WrkPrmtInfoListModal";
import {IntervalContext} from "../../context/Interval";



const HOEtcInfoV2 = () => {

    const [sosDataList, setSosDataList] = useState<any>([]);
    const [wrkStopDataList, setWrkStopDataList] = useState<any>([]);
    const [wrkStopModalIsOpen, setWrkStopModalIsOpen] = useState<boolean>(false);
    const [sosModalIsOpen, setSosModalIsOpen] = useState<boolean>(false);
    const timerRef = useRef<any>();
    const {site} = useContext<any>(SiteContext);
    const [statData, setStatData] = useState<any>({wrkrCnt:0, frgnrCnt:0, oldCnt:0, totCnt:0})
    const [modalIsOpen, setModalIsOpen] = React.useState(false);
    const [dataList, setDataList] = useState<any>([]);
    const [wrkrTotDataList, setWrkrTotDataList] = useState<any>([]);
    const [wrkrVulnarableDataList, setWrkrVulnarableDataList] = useState<any>([]);
    const [mngrVulnarableDataList, setMngrVulnarableDataList] = useState<any>([]);
    const [mngrFrgnlDataList, setMngrFrgnlDataList] = useState<any>([]);
    const [wrkrFrgnlDataList, setWrkrFrgnlDataList] = useState<any>([]);
    const [noActSiteCnt, setNoActSiteCnt] = useState<number>(0);
    const [noActCnt, setNoActCnt] = useState<number>(0);
    const [sftyChkDataList, setSftyChkDataList] = useState<any>([]);
    const [isSftyChkListModalOpen, setIsSftyChkListModalOpen] = useState<boolean>(false);
    const [actNoCmpltSiteDataList, setActNoCmpltSiteDataList] = useState<any>([]);
    const [isSiteSftyChkInfoListModalOpen, setIsSiteSftyChkInfoListModalOpen] = useState<any>(false);
    const [siteDataList, setSiteDataList] = useState<any>([]);
    const [isWrkPrmtInfoListModalOpen, setIsWrkPrmtInfoListModalOpen] = useState<boolean>(false);
    const [tbmStat, setTbmStat] = useState<any>({totCnt:0, noTbmCnt:0});
    const [wrkPrmtList, setWrkPrmtList] = useState<any>([]);
    const [isWrkPrmtListModalOpen, setIsWrkPrmtListModalOpen] = useState<any>(false);
    const {interval} = useContext<any>(IntervalContext);
    const [graphData, setGraphData] = useState<any>({
        labels: ['근로자', '외국인', "고령자"],
        datasets: [{
            data: [35, 21, 44],
            backgroundColor: [
                '#4C5EFF',
                '#4AC6FF',
                '#D39AFF'
            ],
            borderWidth: 1,
            hoverOffset: 5,
        }],
    });


    useEffect(() =>{
        fn_retrieveData();
        fn_retrieveSftyChkDataList();
        fn_retrieveTbmSiteDataList();
    }, [interval])

    const fn_retrieveData = () => {

        restapi.retrieveTotEtrncStatInfo({qrNfcTpCd: 'T003001'}).then((rsltData: any) => {
            const wrkrTotDataList = rsltData.wrkrTotDataList;
            setWrkrTotDataList(wrkrTotDataList);
            const mngrTotDataList = rsltData.mngrTotDataList;
            const wrkrVulnarableDataList = rsltData.wrkrVulnarableDataList;
            setWrkrVulnarableDataList(wrkrVulnarableDataList);
            const mngrVulnarableDataList = rsltData.mngrVulnarableDataList;
            setMngrVulnarableDataList(mngrVulnarableDataList);
            const mngrKoreanDataList = rsltData.mngrKoreanDataList;
            const wrkrKoreanDataList = rsltData.wrkrKoreanDataList;
            const mngrFrgnlDataList = rsltData.mngrFrgnlDataList;
            setMngrFrgnlDataList(mngrFrgnlDataList);
            const wrkrFrgnlDataList = rsltData.wrkrFrgnlDataList;
            setWrkrFrgnlDataList(wrkrFrgnlDataList);


            const oldManCnt = wrkrVulnarableDataList.length + mngrVulnarableDataList.length;
            const youngManCnt = wrkrTotDataList.length + mngrTotDataList.length - oldManCnt;
            const koreanCnt = mngrKoreanDataList.length + wrkrKoreanDataList.length;
            const frgnrCnt = wrkrTotDataList.length + mngrTotDataList.length - koreanCnt;
            const totCnt = wrkrTotDataList.length + mngrTotDataList.length
            const wrkrCnt = wrkrTotDataList.length;
            const mngrCnt = mngrTotDataList.length;

            setGraphData({
                labels: ['근로자', '외국인', "고령자"],
                datasets: [{
                    data: [wrkrCnt, frgnrCnt, oldManCnt],
                    backgroundColor: [
                        '#4C5EFF',
                        '#4AC6FF',
                        '#D39AFF'
                    ],
                    borderWidth: 1,
                    hoverOffset: 5,
                }],
            })


            setStatData({wrkrCnt:wrkrCnt, frgnrCnt:frgnrCnt, oldCnt:oldManCnt, totCnt: wrkrCnt+frgnrCnt+oldManCnt})


        })
    }

    const fn_retrieveSftyChkDataList = () => {
        restapi.retrieveSftyChkActStatData({}).then((rsltData: any) => {
            setNoActCnt(rsltData.actNoCmpltCnt || 0);
        })

        restapi.retrieveActNoCmpltSiteDataList().then((rsltData: any) => {
            setActNoCmpltSiteDataList(rsltData);
            setNoActSiteCnt(rsltData.length);
        })
    }


    const fn_openWrkStopInfoListModal = () => {
        setWrkStopModalIsOpen(true);
    }

    const fn_openSosModal = () => {
        setSosModalIsOpen(true);
    }

    const options = {
        responsive: true,
        maintainAspectRatio: false,
        borderColor: "#FFFFFF",
        plugins: {
            legend: {
                display: false,
            },
            emptyDoughnut: {
                color: 'rgba(255,255,0, 0.5)',
                width: 20,
                radiusDecrease: 10
            }
        },
        cutout: '70%',
    };

    const plugin = {
        id: 'emptyDoughnut',
        afterDraw(chart: any, args: any, options: any) {
            const {datasets} = chart.data;
            const {color, width, radiusDecrease} = options;
            let hasData = false;
            for (let i = 0; i < datasets.length; i += 1) {
                const dataset = datasets[i];
                hasData = dataset.data.length > 0;
            }
            if (!hasData) {
                const {chartArea: {left, top, right, bottom}, ctx} = chart;
                const centerX = (left + right) / 2;
                const centerY = (top + bottom) / 2;
                const r = Math.min(right - left, bottom - top) / 2;

                ctx.beginPath();
                ctx.lineWidth = width || 2;
                ctx.strokeStyle = color || 'rgba(255, 128, 0, 0.5)';
                ctx.arc(centerX, centerY, (r - radiusDecrease || 0), 0, 2 * Math.PI);
                ctx.stroke();
            }
        }
    };

    const fn_openInOutStatModal = (modalGb: string) => {

        if(modalGb == 'wrkr'){
            setDataList(wrkrTotDataList);
        }else if(modalGb == 'frgn'){
            setDataList(mngrFrgnlDataList.concat(wrkrFrgnlDataList));
        }else if(modalGb == 'old'){
            setDataList(mngrVulnarableDataList.concat(wrkrVulnarableDataList))
        }

        setModalIsOpen(true);
    }

    const fn_retrieveSiteSftyManageDataTotList = (actStatGbCd: any) => {

        restapi.retrieveSiteSftyManageDataTotList({siteId: site?.siteId, actStatGbCd:actStatGbCd, isSiteMngrPov: true}).then((rsltData: any) => {
            setSftyChkDataList(rsltData);
            setIsSftyChkListModalOpen(true);
        })

    }

    const fn_retrieveTbmSiteDataList = () => {
        restapi.retrieveWrkPrmtDataTotList({wrkDt: CmmnFn.fn_getCurrentDate()}).then((rsltData: any) => {
            setWrkPrmtList(rsltData);
            const siteDataList = rsltData.filter((data: any) => (!data.isSmSite));
            const smSiteDataList = rsltData.filter((data: any) => (data.isSmSite));
            const cmpltdSiteDataList = siteDataList.filter((data: any) => (data.isWrkCmpltd));
            setTbmStat({totCnt:siteDataList.length, noTbmCnt:siteDataList.length - cmpltdSiteDataList.length})

            console.log('siteDataList: ', siteDataList);
            console.log('cmpltdSiteDataList: ', cmpltdSiteDataList);
            setSiteDataList(siteDataList);
        })
    }

    const fn_openWrkPrmtInfoListModal = (srchGb: string) => {
        if(srchGb == 'tot'){
            setDataList(wrkPrmtList);
        }
        setIsWrkPrmtInfoListModalOpen(true);
    }

    return(
        <div>
            <SftyChkInfoListModal setModalIsOpen={setIsSftyChkListModalOpen} modalIsOpen={isSftyChkListModalOpen} dataList={sftyChkDataList} title={'안전점검 미조치 현황 목록'} ></SftyChkInfoListModal>
            <SiteSftyChkInfoListModal title={'미조치 현장 목록'} setModalIsOpen={setIsSiteSftyChkInfoListModalOpen} modalIsOpen={isSiteSftyChkInfoListModalOpen} dataList={actNoCmpltSiteDataList} />
            <InOutStatModal setModalIsOpen={setModalIsOpen} modalIsOpen={modalIsOpen} dataList={dataList} />
            <WrkPrmtInfoListModal setModalIsOpen={setIsWrkPrmtInfoListModalOpen} modalIsOpen={isWrkPrmtInfoListModalOpen} dataList={dataList} />

            <div className={"row"}>
                <div className={"col-7 pe-2"}>
                    <div className="d-flex bmc-bg-pane flex-column justify-content-between py-3">
                        <div className="d-flex align-items-center justify-content-between pb-2 border-bottom-v2">
                            <span className="bmc-area-title-v2">출역현황</span>
                        </div>
                        <div className="d-flex flex-column">
                            <div className="d-flex position-relative px-1 py-3">
                                <Doughnut className="z-50" options={options} data={graphData} plugins={[plugin]} width="248px" height={"248px"} />
                                <div className="position-absolute z-10 text-center" style={{left: "50%", top:"50%", transform: 'translate(-50%, -50%)'}}>
                                    <div className="etc-graph-total-title">전체</div>
                                    <div>
                                        <span className="etc-graph-total-count">{statData.totCnt}</span>
                                        <span className="etc-graph-total-gun">명</span>
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div className={"row"}>
                            <div onClick={() => fn_openInOutStatModal('wrkr')} className={"cursor-pointer col-4 text-nowrap p-0 text-center"}>
                                <span className="graph-label-icon" style={{color:'#4C5EFF'}}>●</span>
                                <span className="px-1 graph-label-text text-nowrap">근로자</span>
                                <span style={{color:'rgba(255, 113, 139, 1)'}}>{statData.wrkrCnt}</span>
                            </div>

                            <div onClick={() => fn_openInOutStatModal('frgn')} className={"cursor-pointer col-4 text-nowrap p-0 text-center"}>
                                <span className="graph-label-icon" style={{color:'#4AC6FF'}}>●</span>
                                <span className="px-1 graph-label-text text-nowrap">외국인</span>
                                <span style={{color:'#4AC6FF'}}>{statData.frgnrCnt}</span>
                            </div>
                            <div onClick={() => fn_openInOutStatModal('old')} className={"cursor-pointer col-4 text-nowrap p-0 text-center"}>
                                <span className="graph-label-icon" style={{color:'#D39AFF'}}>●</span>
                                <span className="px-1 graph-label-text text-nowrap">고령자</span>
                                <span style={{color:'#D39AFF'}}>{statData.oldCnt}</span>
                            </div>
                        </div>
                    </div>
                </div>
                <div className={"col-5 ps-2"}>
                    <div className="d-flex bmc-bg-pane flex-column justify-content-between py-3">
                        <div className="d-flex align-items-center justify-content-between pb-2 border-bottom-v2 mb-2">
                            <span className="bmc-area-title-v2">위험관리 현황</span>
                        </div>
                        <div onClick={() => setIsSiteSftyChkInfoListModalOpen(true)} className="cursor-pointer d-flex justify-content-between no-action-count-v2 mb-2 py-2 px-3">
                            <span>미조치<br/>현장수</span>
                            <span className="fs-4 align-self-center text-txtBlack">{noActSiteCnt}</span>
                        </div>
                        <div onClick={() => fn_retrieveSiteSftyManageDataTotList('C005002')} className="cursor-pointer d-flex justify-content-between no-action-target-v2 py-2 px-3">
                            <span>미조치<br/>대상건</span>
                            <span className="fs-4 align-self-center text-txtBlack">{noActCnt}</span>
                        </div>
                    </div>
                    <div className="d-flex bmc-bg-pane flex-column justify-content-between py-3 mt-3">
                        <div className="align-items-center justify-content-between pb-2 border-bottom-v2 mb-2">
                            <span className="bmc-area-title-v2">TBM 현황</span>
                            <br/>
                            <div style={{textAlign:"right"}}>
                                <span style={{fontSize:"0.9em"}}>(미수행 {tbmStat.noTbmCnt})</span>
                            </div>
                        </div>
                        <div onClick={() => fn_openWrkPrmtInfoListModal('tot')} className="cursor-pointer d-flex justify-content-between no-action-target-total-v2 py-2 px-3">
                            <span className="align-self-center">전체</span>
                            <span className="fs-4 align-self-center text-txtBlack">{tbmStat.totCnt}</span>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    /*<Row className="pt-3">
        <Col>
            <div className="d-flex justify-content-between">
                aaa
            </div>
        </Col>
    </Row>*/

    );
}

export default HOEtcInfoV2;
