import React, {Dispatch, SetStateAction, useContext, useEffect, useState} from "react";
import {ProgressContext} from "../../../context/Progress";
import {getLoginUser} from "../../../helper/SecurityHelper";
import {Modal, Table} from "react-bootstrap";
import {IoMdClose} from "react-icons/io";
import BMCInput from "../../../components/common/BMCInput";
import BMCTextarea from "../../../components/common/BMCTextarea";
import BMCSelect from "../../../components/common/BMCSelect";
import {validFormData} from "../../../helper/FormHelper";
import axios from "axios";
import {toast} from "react-toastify";
import messageCodeDic from "../../../util/messageCodeDic";
import errorCodeDic from "../../../util/errorCodeDic";
import restapi from "../../../helper/restAPIHelper";

interface IProps {
    setModalIsOpen: Dispatch<SetStateAction<boolean>>;
    modalIsOpen: boolean;
    siteId: number
    manageItem: any;
    detailData: any;
}

export default function WrkSiteDtlModal(props:IProps){
    const [data, setData] = useState<any>();
    const formRef = React.createRef<HTMLFormElement>();
    const {spinner} = useContext(ProgressContext);
    const user: any = getLoginUser();
    const [guCd, setGuCd] = useState<any>();

    const fn_updateData = (e: any) => {
        if (e.target.type == 'checkbox') {
            setData({
                ...data,
                [e.target.name]: e.target.checked
            })
            console.log(e.target.checked)
        } else {
            setData({
                ...data,
                [e.target.name]: e.target.value
            })
        }
    }


    const fn_registData = () => {

        if (!data) {
            toast.warn('데이터를 입력해 주십시오.')
            return;
        }

        if (!data.address){
            toast.warn('주소를 입력해 주십시오.')
            return;
        }

        let param = data;
        // @ts-ignore
        param.wrkSiteId = props.manageItem.manageSn;

        if(!window.confirm('저장 하시겠습니까?')){
            return;
        }

        spinner.start();
        axios.post('/admin/api/manage/saveWrkSiteDtlData', param).then((result) => {
            if (result.data > 0) {
                toast.success(messageCodeDic['001'])
                props.setModalIsOpen(false);
            } else {
                toast.error(errorCodeDic['003']);
            }
        }).catch(error => {
            toast.error(errorCodeDic['003']);
            console.log('error: ', error);
        })
        .finally(() => {
            spinner.stop();
        });
    }

    const fn_openModal = () => {

        restapi.selectWrkSiteDtlData({wrkSiteId: props.manageItem.manageSn}).then((rsltData: any) => {
            if(rsltData){
                setData(rsltData);
            }else{
                setData({})
            }
        })
    }

    const fn_closeModal = () => {
        props.setModalIsOpen(false);
    }

    const submit = (e: any) => {
        e.preventDefault()

    }

    const fn_openAddrPop = () => {
        // @ts-ignore
        new daum.Postcode({
            oncomplete: function (rtrnData: any) {
                // @ts-ignore
                var geocoder = new kakao.maps.services.Geocoder();
                geocoder.addressSearch(rtrnData.address, function (result: any, status: any) {
                    // @ts-ignore
                    if (status === kakao.maps.services.Status.OK) {
                        var lon = result[0].x
                        var lat = result[0].y
                        var address = result[0].address_name
                        var bjdCode = result[0].address.b_code
                        setData({
                            ...data,
                            address: address,
                            bjdCode: bjdCode,
                            longitude: lon,
                            latitude: lat
                        })
                    }
                });
            }
        }).open();
    }

    return(
        <div>
            <Modal
                className="bmc-modal" size="xl"
                show={props.modalIsOpen}
                onShow={fn_openModal}
                onHide={fn_closeModal}
            >

                <Modal.Header>
                    <div className="d-flex justify-content-between align-items-center w-100 bmc-font-SD">
                        작업현장 상세
                        <IoMdClose className='hover:cursor-pointer' onClick={fn_closeModal}/>
                    </div>
                </Modal.Header>
                <Modal.Body className="px-4">
                    <form name={'detailForm'} ref={formRef} encType="multipart/form-data" method={"post"} onSubmit={submit}>
                        <BMCInput type={'hidden'} value={data?.atchFileId} name={'atchFileId'}></BMCInput>
                        <BMCInput type={'hidden'} value={data?.sftyMngBrdId} name={'sftyMngBrdId'}></BMCInput>
                        <div className="bmc-modal-div">
                            <div className="div-header">
                                 기본정보
                            </div>
                            <div className="div-body px-3">
                                <Table className="my-2 align-middle">
                                    <tbody>

                                    <tr className="border-top align-middle">
                                        <th className="bmc-bg-th">
                                            작업현장 명
                                        </th>
                                        <td colSpan={3}>

                                            <BMCInput type="text" name={'wrkSiteNm'} value={data?.wrkSiteNm || props.manageItem?.manageName}
                                                      className="form-control" readOnly/>

                                        </td>

                                    </tr>
                                    <tr className="border-top align-middle">
                                    <th className="bmc-bg-th">
                                        주소
                                    </th>
                                    <td colSpan={3}>
                                        <div
                                            className="d-flex align-items-center justify-content-between w-100 p-0 bmc-fs-b3">
                                            <BMCInput type="text" name={'address'} value={data?.address}
                                                      className="form-control" readOnly/>
                                            <button className="bmc-btn btn-secondary min-w-[70px] ml-5 mr-10"
                                                    type="button" onClick={fn_openAddrPop}>검색
                                            </button>
                                            <span className={"min-w-[40px]"}>경도</span>
                                            <BMCInput type="text" className="w-[100px] form-control"
                                                      name={'longitude'} value={data?.longitude}
                                                      disabled={true}/>
                                            <span className={"min-w-[40px] ml-10"}>위도</span>
                                            <BMCInput type="text" className="w-[100px] form-control"
                                                      name={'latitude'}
                                                      value={data?.latitude} disabled={true}/>

                                        </div>
                                    </td>
                                    </tr>



                                    </tbody>
                                </Table>
                            </div>
                        </div>
                    </form>
                </Modal.Body>

                <Modal.Footer className="py-2">
                    <div className='flex justify-center w-100'>
                        <button
                            className='bmc-btn pill l white me-3'
                            onClick={fn_closeModal}>닫기
                        </button>
                        <button onClick={fn_registData}
                                className='bmc-btn pill p'>등록/수정
                        </button>
                    </div>
                </Modal.Footer>

            </Modal>
        </div>
    )
}
