import React, {useContext, useEffect, useState} from 'react';
import SubMenuTitle from "../component/SubMenuTitle";
import EmergencyNetworkRegistModal from "../siteConstructionManagement/modal/EmergencyNetworkRegistModal";
import SHSearch from "../component/BMCSearch";
import {Button, Container, Table} from "react-bootstrap";
import BMCInput from "../../components/common/BMCInput";
import BMCIconButton from "../../components/common/BMCIconButton";
import Datatables from "../component/Datatables";
import {NumberFormatBase} from "react-number-format";
import {Formatter} from "../../helper/FormatHelper";
import BMCSelect from "../../components/common/BMCSelect";
import iconTrashBean from "../../images/icon_remove.png";
import {fn_getCurrentDate} from "../../helper/CmmnHelper";
import {getLoginUser} from "../../helper/SecurityHelper";
import restapi from "../../helper/restAPIHelper";
import axios from "axios";
import BMCFile from "../../components/common/BMCFile";
import {toast} from "react-toastify";
import messageCodeDic from "../../util/messageCodeDic";
import errorCodeDic from "../../util/errorCodeDic";
import {ProgressContext} from "../../context/Progress";
import {USER_SESSION} from "../../helper/SecurityHelper";
import SafetyEduRgstModal from "../safetyEducationManagement/modal/SafetyEduRgstModal";
import {UserContext} from "../../context/User";
import PasswordChangeModal from "./modal/PasswordChangeModal";

type Props = {};

const MyInformationManagementPage = (props: Props) => {
    const [data, setData] = useState<any>()
    const [authChrgDataList, setAuthChrgDataList] = useState<[]>([]);
    const currentDate = fn_getCurrentDate();
    const defaultUser = getLoginUser();
    const {user} = useContext<any>(UserContext);
    const [fileList, setFileList] = useState([]);
    const formRef = React.createRef<HTMLFormElement>();
    const {spinner} = useContext(ProgressContext);

    const [modalIsOpen, setModalIsOpen] = React.useState(false);

    useEffect(() => {
        fn_getMyInformationManagementData(defaultUser.userSeq).then((rsltData) => {
            setData(rsltData);
        })

        restapi.retrieveUserSiteAuthDataList(defaultUser.userSeq).then((rsltData) => {
            setAuthChrgDataList(rsltData);
        })
    },[])

    useEffect(() => {
        if(data?.atchFileId){
            restapi.retrieveAttachInfoList({attachSeq: data?.atchFileId}).then((rsltData: any) => {
                setFileList([].concat(rsltData));
            })
        }else{
            setFileList([]);
        }
    }, [data])

    const fn_getMyInformationManagementData = async (userSeq : number) =>{
        return new Promise(function (resolve, reject) {
            axios.post('/admin/api/user/retrieveUserInfoDtl', {
                userSeq: userSeq
            }).then((result) => {
                resolve(result.data);
            })
        });
    }

    const fn_setFormData = (e: any) => {

        if (e.target.name == 'cnstrPrice') {
            e.target.value = parseInt(e.target.value.replaceAll(',', '')).toLocaleString()
        }
        setData({
            ...data,
            [e.target.name]: e.target.value
        })

    }

    const fn_save = () =>{
        if (!data) {
            toast.warn('데이터를 입력해 주십시오.')
            return;
        }

        if (!data.userName) {
            toast.warn('성명을 입력해 주십시오.')
            return;
        }

        if (!data.userPhone) {
            toast.warn('휴대전화를 입력해 주십시오.')
            return;
        }

        if (!data.position) {
            toast.warn('직위를 입력해 주십시오.')
            return;
        }

        if (!data.userEmail) {
            toast.warn('이메일을 입력해 주십시오.')
            return;
        }

        if(defaultUser.authGrpId == 'HEAD'){
            if (!data.dprtCd) {
                toast.warn('부서를 선택해 주십시오.')
                return;
            }
            if (!data.authCode) {
                toast.warn('권한을 선택해 주십시오.')
                return;
            }
        }

        let param = data;
        // @ts-ignore
        var formData: any = new FormData(formRef.current)

        if(fileList.length > 0){
            for(var i =0;i<fileList.length;i++){
                formData.append('atchFileList',fileList[i]);
            }
        }

        param.userSeq = defaultUser.userSeq;

        formData.set('userInfo', new Blob([JSON.stringify(param)], {type: "application/json"}))

        if (formData) {
            if (!window.confirm('저장 하시겠습니까?')) {
                return;
            }

            spinner.start();
            axios.post('/admin/api/user/updateMyInfo', formData).then((result) => {
                if (result.data > 0) {
                    toast.success(messageCodeDic['001'])
                } else {
                    toast.error(errorCodeDic['003']);
                }
            }).catch(error => {
                toast.error(errorCodeDic['003']);
                console.log('error: ', error);
            }).finally(() => {
                spinner.stop();
            });
        }
    }

    const fn_secession = () =>{
        if (!window.confirm('정말로 탈퇴하시겠습니까?')) {
            return;
        }

        axios.post('/admin/api/user/secession', {userSeq:user.userSeq}).then((result) => {
            if (result.data > 0) {
                localStorage.removeItem(USER_SESSION);
                window.location.href = "/login"
            } else {
                toast.error(errorCodeDic['003']);
            }
        }).catch(error => {
            toast.error(errorCodeDic['003']);
            console.log('error: ', error);
        }).finally(() => {
        });
    }

    const submit = (e: any) => {
        e.preventDefault()

    }

    const fn_pwPopOpen = ()=>{
        setModalIsOpen(true)
    }

    return (
        <Container fluid className="bg-white px-0 bmc-txt-color-primary">
            <SubMenuTitle title={'나의 정보 관리'} parentMenu={'My Page'} />
            <PasswordChangeModal setModalIsOpen={setModalIsOpen} modalIsOpen={modalIsOpen} detailData={data}/>

            <div className="p-4 overflow-y-auto" style={{height: 'calc(100vh - 180px)'}}>
                <div className='w-100 d-flex justify-content-end align-items-center mb-2'>
                    <BMCIconButton kind={'submit'} marginRight={'5px'} onClick={fn_save}>저장</BMCIconButton>
                    <BMCIconButton kind={'delete'} marginRight={'0'} onClick={fn_secession}>탈퇴</BMCIconButton>
                </div>

                <form name={'form'} ref={formRef} encType="multipart/form-data" method={"post"} onSubmit={submit}>
                    <div className="bmc-modal-div">
                        <div className="div-header">
                            관리자 정보
                        </div>
                        <div className="div-body px-3">
                            <Table className="my-2 align-middle">
                                <tbody>
                                <tr className="border-top align-middle">
                                    <th className="bmc-bg-th" style={{width: '15%'}}>성명</th>
                                    <td style={{width: '35%'}}>
                                        <BMCInput type="text" className="form-control" name={'userName'}
                                                  onChange={fn_setFormData} value={data?.userName}/>
                                    </td>
                                    <th className="bmc-bg-th" style={{width: '15%'}}>휴대전화</th>
                                    <td style={{width: '35%'}}>
                                        {
                                            //@ts-ignore
                                            <NumberFormatBase onChange={fn_setFormData} name={'userPhone'}
                                                              className={'form-control'}
                                                              value={data?.userPhone || ''}
                                                              format={Formatter.phoneNumFormat}
                                            />
                                        }
                                    </td>
                                </tr>
                                <tr className="border-top align-middle">
                                    <th className="bmc-bg-th" style={{width: '15%'}}>직위</th>
                                    <td style={{width: '35%'}}>
                                        <BMCInput type="text" className="form-control" name={'position'}
                                                  onChange={fn_setFormData} value={data?.position}/>
                                    </td>
                                    <th className="bmc-bg-th" style={{width: '15%'}}>소속업체</th>
                                    <td style={{width: '35%'}}>

                                        {defaultUser.authGrpId == 'SITE' ?
                                            <BMCSelect disabled={authChrgDataList.length > 0 ? true : false}
                                                       initOptionGb={'sel'}
                                                       name={'blngCmpnySeq'} codeGb={'siteCmpny'}
                                                       onChange={fn_setFormData} selParam={{siteId: user?.blngSiteId || defaultUser.blngSiteId}}
                                                       value={data?.blngCmpnySeq}></BMCSelect> : '부산도시공사'
                                        }

                                    </td>
                                </tr>
                                {defaultUser.authGrpId == 'HEAD' &&
                                    <tr className="border-top align-middle">
                                        <th className="bmc-bg-th" style={{width: '15%'}}>부서</th>
                                        <td style={{width: '35%'}}>
                                            <BMCSelect initOptionGb={'sel'} name={'dprtCd'} codeGrpId={'D001'} className={"w-100"} codeGb={'cmmn'}
                                                       onChange={fn_setFormData} value={data?.dprtCd}></BMCSelect>
                                        </td>
                                        <th className="bmc-bg-th" style={{width: '15%'}}>권한</th>
                                        <td style={{width: '35%'}}>
                                            <BMCInput type="text" className="form-control" name={'authCodeNm'} disabled={true}
                                                      onChange={fn_setFormData} value={data?.authCodeNm}/>
                                        </td>
                                    </tr>
                                }

                                <tr className="border-top align-middle">
                                    <th className="bmc-bg-th" style={{width: '15%'}}>아이디</th>
                                    <td style={{width: '35%'}}>
                                        <BMCInput type="text" className="form-control" name={'userId'}
                                                  onChange={fn_setFormData} value={data?.userId} disabled={true}/>
                                    </td>
                                    <th className="bmc-bg-th" style={{width: '15%'}}>비밀번호</th>
                                    <td style={{width: '35%'}}>
                                        <Button variant="secondary" onClick={fn_pwPopOpen}>비밀번호 변경</Button>
                                    </td>
                                </tr>
                                <tr className="border-top border-bottom align-middle">
                                    <th className="bmc-bg-th" style={{width: '15%'}}>이메일</th>
                                    <td style={{width: '35%'}}>
                                        <BMCInput type="text" className="form-control" name={'userEmail'}
                                                  onChange={fn_setFormData} value={data?.userEmail}/>
                                    </td>
                                    <th className="bmc-bg-th" style={{width: '15%'}}></th>
                                    <td></td>
                                </tr>
                                </tbody>
                            </Table>
                        </div>

                    </div>

                    {defaultUser.authGrpId == 'SITE' &&
                        <div className="bmc-modal-div">
                            <div className="div-header">
                                <div className='flex justify-content-between align-items-center w-100'>
                                    <span>현장 별 권한 및 직책 선택</span>
                                </div>
                            </div>
                            <div className="div-body px-3 pt-3">
                                <table className="table sub">
                                    <colgroup>
                                        <col width='70%' />
                                        <col width='15%' />
                                        <col width='15%' />
                                    </colgroup>
                                    <thead>
                                    <tr className="table-secondary">
                                        <th className="align-middle text-center">현장</th>
                                        <th className="align-middle text-center">권한</th>
                                        <th className="align-middle text-center">직책</th>
                                    </tr>
                                    </thead>
                                    <tbody id="wrkDclrRgst_siteTBody">
                                    {
                                        authChrgDataList.length > 0 ?
                                            authChrgDataList.map((authChrgData: any, idx) => (
                                                <tr key={idx}>
                                                    <td>
                                                        <BMCSelect initOptionGb={'none'} disabled={true} codeGb={'site'}
                                                                   onChange={fn_setFormData} isForDtl={true} className={'form-control'}
                                                                   value={authChrgData.siteId}></BMCSelect>
                                                    </td>
                                                    <td>
                                                        <BMCSelect initOptionGb={'none'} disabled={true} codeGb={'auth'}
                                                                   className={'form-control'}
                                                                   onChange={fn_setFormData}
                                                                   selParam={{authGrpId: 'SITE'}}
                                                                   value={authChrgData.authCode}></BMCSelect>
                                                    </td>
                                                    <td>
                                                        <BMCSelect initOptionGb={'none'} disabled={true}
                                                                   codeGb={'siteMngCd'} className={'form-control'}
                                                                   onChange={fn_setFormData}
                                                                   selParam={{
                                                                       siteId: authChrgData.siteId,
                                                                       manageCode: 'M001003'
                                                                   }} value={authChrgData.chrgCode}></BMCSelect>
                                                    </td>
                                                </tr>

                                            )) : <tr>
                                                <td colSpan={5} className={'text-center'}>현장별 권한을 등록해 주세요.</td>
                                            </tr>
                                    }

                                    </tbody>
                                </table>
                            </div>
                        </div>
                    }


                    <div className="bmc-modal-div">
                        <div className="div-header">
                            <div className='flex justify-content-between align-items-center w-100'>
                                <span>첨부파일</span>
                            </div>
                        </div>
                        <div className="div-body px-3 pt-3">
                            <Table className="my-2 align-middle">
                                <tbody>
                                <tr className="align-middle align-middle">
                                    <th className="align-middle" style={{width:"15%"}}>첨부파일</th>
                                    <td>
                                        <BMCFile fileName={'files'} fileList={fileList} setFileList={setFileList}></BMCFile>
                                    </td>
                                </tr>
                                </tbody>
                            </Table>
                        </div>
                    </div>
                </form>
            </div>
        </Container>
    );
};

export default MyInformationManagementPage;
