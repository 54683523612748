import React, {Dispatch, SetStateAction} from 'react';
import {ReactComponent as IconSearch} from '../../images/icon_search.svg';
import {fn_search} from "../../helper/CmmnHelper";

interface IProps {
    datatable?: any;
    btnType?: any;
    className?: any;
    onClick?: any;
}

export default function View(props: IProps) {

    return (
        <>
            {props.btnType != 'h' &&
                <button type={"button"}  className={"bmc-btn search " + (props.btnType == 'p' ? ' p' : ' l') + (props.className ? ' '+props.className : '')}
                        onClick={e => {props.onClick ? props.onClick() : fn_search(e, props.datatable)}}>
                    {
                        props.btnType == 'p' ?
                            <IconSearch style={{fill: '#ffffff'}}/> :
                            <IconSearch style={{fill: '#a4a4a4'}}/>
                    }
                    검색
                </button>
            }

        </>
    );
}

