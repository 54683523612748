import React, {Dispatch, SetStateAction, useEffect, useRef, useState} from "react";
import {Col, Modal, Row, Table} from "react-bootstrap";
import {IoMdClose} from "react-icons/io";
import Datatables, {getCheckedItems} from "../../component/Datatables";
import {toast} from "react-toastify";
import SHSearch from "../../component/BMCSearch";
import BMCDatePicker from "../../../components/common/BMCDatePicker";
import CustomDatePickerRange from "../../component/ui/DatePickerRange";
import {CmmnFn, fn_getCurrentDate} from "../../../helper/CmmnHelper";
import BMCSelect from "../../../components/common/BMCSelect";
import BMCInput from "../../../components/common/BMCInput";
import RskAsmntRgstModal from "../../siteSafetyManagement/modal/RskAsmntRgstModal";
import WrkPrmtRgstModal from "../../siteSafetyManagement/modal/WrkPrmtRgstModal";

interface IProps {
    setModalIsOpen: Dispatch<SetStateAction<boolean>>;
    modalIsOpen: boolean;

    siteId:number
    wrkStatCd:string

}

const WrkPrmtInfoListModal = (props: IProps) => {
    const [datatable, setDatatable] = useState<any>();
    const searchFormRef = useRef<any>();
    const [data, setData] = useState<any>({})
    const [modalIsOpen, setModalIsOpen] = React.useState(false);
    const [detailData, setDetailData] = useState<any>(null);
    const [isCodeDataLoade, setIsCodeDataLoade] = useState<any>(false);

    function fn_closeModal() {
        props.setModalIsOpen(false);
    }

    const fn_search = (e?: any) => {
        e?.preventDefault()
        datatable?.draw()
    }

    const fn_setFormData = (e: any) => {

        setData({
            ...data,
            [e.target.name]: e.target.value
        })

    }

    const fn_onShow = () => {
        fn_search();
    }
    return (
        <div>

            <Modal
                className="bmc-modal" size="lg"
                show={props.modalIsOpen}
                onShow={()=>{fn_onShow()}}
                onHide={fn_closeModal}
            >
                <WrkPrmtRgstModal setModalIsOpen={setModalIsOpen} modalIsOpen={modalIsOpen}
                                  siteId={props.siteId}
                                  detailData={detailData}/>
                <Modal.Header>
                    <div className="d-flex justify-content-between align-items-center w-100 bmc-font-SD">
                        작업허가서 목록 조회
                        <IoMdClose className='hover:cursor-pointer' onClick={fn_closeModal}/>
                    </div>
                </Modal.Header>

                <Modal.Body className="px-4">

                        <SHSearch datatable={datatable} formRef={searchFormRef} btnType={'p'}>
                            <Row>
                                <Col>
                                    <Table className="bmc-font-SP bmc-fs-b2 my-3">
                                        <tbody>
                                        <tr className="border-top align-middle">
                                            <th className="bmc-bg-th" style={{width: '10%'}}>업체</th>
                                            <td style={{width: '23%'}}>
                                                <BMCInput type={'hidden'} name={'siteId'} value={ props.siteId}></BMCInput>
                                                <BMCInput type={'hidden'} name={'prcsStatCd'} value={'S001004'}></BMCInput>
                                                <BMCInput type={'hidden'} name={'wrkDt'} value={CmmnFn.fn_getCurrentDate()}></BMCInput>
                                                <BMCSelect initOptionGb={'all'} name={'cmpnySeq'}
                                                          selParam={{siteId: props.siteId}}
                                                          disabled={false} codeGb={'siteCmpny'}
                                                          className="bmc-form select w-100"
                                                          onChange={fn_setFormData}
                                                          value={data?.cmpnySeq}></BMCSelect>
                                            </td>
                                            <th className="bmc-bg-th">공종</th>
                                            <td>
                                                <BMCSelect className={'form-select'} initOptionGb={'all'} value={data?.sectorCd}
                                                          onChange={fn_setFormData} name={'sectorCd'}
                                                          codeGrpId={'C002'}></BMCSelect>
                                            </td>
                                            <th className="bmc-bg-th" style={{width: '10%'}}>작업상태</th>
                                            <td style={{width: '23%'}}>
                                                <BMCInput type={'hidden'} name={'wrkStatCd'} value={ props.wrkStatCd}></BMCInput>
                                                <BMCSelect disabled={true} className={"form-select bmc-fs-b3"} initOptionGb={'all'}
                                                          name={'wrkStatFakeCd'} setIsCodeDataLoaded={setIsCodeDataLoade} codeGb={'cmmn'} codeGrpId={'S004'}
                                                          onChange={(e) => {
                                                              fn_setFormData(e);
                                                          }}
                                                          value={data?.wrkStatCd}/>
                                            </td>
                                        </tr>
                                        </tbody>
                                    </Table>
                                </Col>
                            </Row>
                        </SHSearch>

                    <div className={'ge-result'}>
                        <Datatables
                            dataUrl={'/admin/api/wrkPrmt/retrieveWrkPrmtDataList'}
                            parameterFormRef={searchFormRef}
                            columns={[
                                {data: "wrkTypeNm", title: "공종"},
                                {data: "todayWrkCntnts", title: "금일 작업사항 내용"},
                                {data: "totWrkrCnt", title: "총인원"},
                                {data: "wrkTimeGbNm", title: "작업시간", textAlign: 'center'},
                                {
                                    data: "", title: "작업상태", textAlign: 'center', render: (data: any, type: any, row: any) => {
                                        console.log('row: ', row);
                                        console.log('datatable.page.info().recordsTotal: ', datatable)
                                        if (row.totWrkrCnt == row.tbmCmpltCnt) {
                                            if(row.isWrkCmpltd) {
                                                return '작업완료'
                                            }else{
                                                return '작업중'
                                            }

                                        } else {
                                            return '작업대기'
                                        }
                                    }
                                },
                            ]}
                            checked={false}
                            index={true}

                            onLoad={(dt: any) => {
                                setData({
                                    ...data,
                                    ['wrkStatCd']: props.wrkStatCd
                                })
                                setDatatable(dt)
                            }}
                            onClick={(data) => {
                                setDetailData(data)
                                setModalIsOpen(true);
                            }
                            }
                        />
                    </div>
                </Modal.Body>
                <Modal.Footer className="py-2">
                    <div className='flex justify-center w-100'>

                        <button
                            className='bmc-btn pill l white me-3'
                            onClick={fn_closeModal}>닫기
                        </button>

                    </div>
                </Modal.Footer>


            </Modal>
        </div>
    )
}

export default WrkPrmtInfoListModal;
