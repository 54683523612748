import {Modal, Table} from "react-bootstrap";
import {IoMdClose} from "react-icons/io";
import React, {Dispatch, SetStateAction, useContext, useEffect, useRef, useState} from "react";
import {fn_getCurrentDate} from "../../../helper/CmmnHelper";
import {getLoginUser} from "../../../helper/SecurityHelper";
import axios from "axios";
import {toast} from "react-toastify";
import messageCodeDic from "../../../util/messageCodeDic";
import errorCodeDic from "../../../util/errorCodeDic";
import BMCInput from "../../../components/common/BMCInput";
import BMCTextarea from "../../../components/common/BMCTextarea";
import {ProgressContext} from "../../../context/Progress";
import restapi from "../../../helper/restAPIHelper";
import BMCSelect from "../../../components/common/BMCSelect";
import BMCFile from "../../../components/common/BMCFile";
import {validFormData} from "../../../helper/FormHelper";


interface IProps {
    setModalIsOpen: Dispatch<SetStateAction<boolean>>;
    modalIsOpen: boolean;
    detailData: any;
    siteId: number;
}

const EmergencyNetworkRegistModal = (props: IProps) => {

    const prodRef = useRef<any>();
    const currentDate = fn_getCurrentDate();
    const user: any = getLoginUser();
    const [isDatePopupOpen, setIsDatePopupOpen] = useState(false);
    const [data, setData] = useState<any>()
    const [selected, setSelected] = useState([])
    const [cmpnyCodeList, setCmpnyCodeList] = useState<any>([]);
    const [repFileSn, setRepFileSn] = useState<any>(null);
    const [fileList, setFileList] = useState([]);
    const formRef = React.createRef<HTMLFormElement>();
    const {spinner} = useContext(ProgressContext);

    useEffect(() => {
    }, [fileList])
    const updateData = (e: any) => {
        if (e.target.type == 'checkbox') {
            setData({
                ...data,
                [e.target.name]: e.target.checked
            })
        } else {
            setData({
                ...data,
                [e.target.name]: e.target.value
            })
        }
    }

    useEffect(() => {
        let param:any = {siteId: props.siteId}

        fn_retrieveCmpnyDataList(param)
    }, [props.siteId])

    useEffect(() => {
        // 신규
    }, [props.detailData])


    const fn_retrieveCmpnyDataList = async (param: object) => {
        return new Promise(function (resolve, reject) {
            axios.post('/admin/api/company/retrieveCmpnyDataList', param).then((result) => {
                resolve(result.data);
            })
        }).then((result: any) => {
            if (result) {
                const rsltCmpnyCodeList: object[] = []
                result.map((data: any) => {
                    rsltCmpnyCodeList.push({label: data.companyName, value: data.companySeq})
                })
                setCmpnyCodeList(rsltCmpnyCodeList);
                console.log('result: ', result);
            }
        });

    }

    function afterOpenModal() {
        if(!props.detailData){
            setFileList([]);
            setData({siteId: props.siteId});
        }else{
            setData(props.detailData);
            if(props.detailData.atchFileId){
                restapi.retrieveAttachInfoList({attachSeq: props.detailData.atchFileId}).then((rsltData: any) => {

                    if (props.detailData?.repPhotoSn !== null && props.detailData?.repPhotoSn !== undefined) {
                        rsltData.map((data: any, idx: number) => {
                            if (data.sn == props.detailData.repPhotoSn) {
                                data.isRep = true
                                setRepFileSn(idx);

                            }
                        })
                    }

                    setFileList([].concat(rsltData));
                })
            }else{
                setFileList([]);
            }
        }
    }

    function fn_closeModal() {
        props.setModalIsOpen(false);
    }

    const fn_datePopupOpen = () => {
        setIsDatePopupOpen(!isDatePopupOpen);
    };

    const submit = (e: any) => {
        e.preventDefault()

    }

    const fn_registData = () => {

        var option = {
            addValues: {
                regSeq: user.userSeq,
                updateSeq: user.userSeq,
            },
            validOpt: {
                title: '제목을 입력해 주십시오.',
                contents: '내용을 입력해 주십시오.',
            },
            files: ['photos']
        }

        var formData: any = validFormData(formRef, option)
        if(!formData){
            return;
        }

        if(fileList.length > 0){
            formData.set('repFileSn', repFileSn);
        }

        formData.set('siteId', data.siteId);

        if (formData) {
            if (!window.confirm('저장 하시겠습니까?')) {
                return;
            }

            spinner.start();
            axios.post('/admin/api/emrgNw/saveEmrgNwData', formData).then((result) => {

                if (result.data > 0) {
                    toast.success(messageCodeDic['001'])
                    props.setModalIsOpen(false);
                } else {
                    toast.error(errorCodeDic['003']);
                }
            }).catch(error => {
                toast.error(errorCodeDic['003']);
                console.log('error: ', error);
            }).finally(() => {
                spinner.stop();
            });
        }
    }

    return (
        <div>
            <Modal
                className="bmc-modal" size="xl"
                show={props.modalIsOpen}
                onShow={afterOpenModal}
                onHide={fn_closeModal}

            >
                <Modal.Header>
                    <div className="d-flex justify-content-between align-items-center w-100 bmc-font-SD">
                        비상연락망 등록
                        <IoMdClose className='hover:cursor-pointer' onClick={fn_closeModal}/>
                    </div>
                </Modal.Header>

                <Modal.Body className="px-4">
                    <form name={'detailForm'} ref={formRef} encType="multipart/form-data" method={"post"} onSubmit={submit}>
                        <BMCInput type={'hidden'} value={data?.atchFileId} name={'atchFileId'}></BMCInput>
                        <BMCInput type={'hidden'} value={data?.emrgNwId} name={'emrgNwId'}></BMCInput>
                        <div className="bmc-modal-div">
                            <div className="div-header">
                                비상연락망 정보
                            </div>

                            <div className="div-body px-3">
                                <Table className="my-2 align-middle">
                                    <tbody>
                                    <tr className="border-top align-middle">
                                        <th className="bmc-bg-th" style={{width: '15%'}}>현장</th>
                                        <td style={{width: '35%'}}>
                                            <BMCSelect initOptionGb={'none'} disabled={true} codeGb={'site'} name={'siteId'}
                                                      onChange={updateData}  className={'form-control w-100'}
                                                      value={data?.siteId} ></BMCSelect>
                                        </td>
                                        <th className="bmc-bg-th" style={{width: '15%'}}>업체</th>
                                        <td style={{width: '35%'}}>
                                            <BMCInput type="text" className="form-control" name={'companyName'}
                                                     onChange={updateData} value={user.blngCmpnyName} disabled={true}/>
                                        </td>
                                    </tr>
                                    <tr className="align-middle">
                                        <th className="bmc-bg-th" style={{width: '15%'}}>작성자</th>
                                        <td style={{width: '35%'}}>
                                            <BMCInput type="text" className="form-control" name={'rgstrNm'}
                                                     onChange={updateData} value={data?.userName?data?.userName:user.userName} disabled={true}/>
                                        </td>
                                        <th className="bmc-bg-th" style={{width: '15%'}}>작성일</th>
                                        <td style={{width: '35%'}}>
                                            <BMCInput type="text" className="form-control" name={'rgstDt'}
                                                     onChange={updateData} value={data?.rgstDt} disabled={true}/>
                                        </td>
                                    </tr>
                                    <tr className="align-middle">
                                        <th className="bmc-bg-th" style={{width: '15%'}}>제목</th>
                                        <td colSpan={3}>
                                            <BMCInput type="text" className="form-control" name={'title'}
                                                     onChange={updateData} value={data?.title} placeholder={'제목을 입력해주세요.'}/>
                                        </td>
                                    </tr>
                                    <tr className="align-middle">
                                        <th className="bmc-bg-th" style={{width: '15%'}}>내용</th>
                                        <td style={{width: '85%'}} colSpan={3}>
                                            <BMCTextarea rows={5} onChange={updateData} name={'contents'}
                                                        value={data?.contents}
                                                        className={"form-control"}
                                                        placeholder='내용을 입력해주세요.'/>
                                        </td>
                                    </tr>
                                    <tr className="align-middle align-middle">
                                        <th className="align-middle">첨부사진</th>
                                        <td colSpan={3}>
                                            <BMCFile fileName={'photos'} fileList={fileList} setFileList={setFileList}
                                                    repFileSn={repFileSn} setRepFileSn={setRepFileSn}></BMCFile>
                                        </td>
                                    </tr>
                                    </tbody>
                                </Table>
                            </div>
                        </div>
                    </form>
                </Modal.Body>
                <Modal.Footer className="py-2">
                    <div className='flex justify-center w-100'>
                        <button
                            className='bmc-btn pill l me-3'
                            onClick={fn_closeModal}>닫기
                        </button>
                        <button onClick={fn_registData}
                                className='bmc-btn pill p white'>등록/수정

                        </button>
                    </div>
                </Modal.Footer>
            </Modal>
        </div>
    )
}

export default EmergencyNetworkRegistModal;

