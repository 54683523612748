import React, {useContext, useEffect, useRef, useState} from 'react';
import axios from "axios";
import Datatables, {getCheckedItems} from "../component/Datatables";
import Modal from 'react-modal';
import {getLoginUser} from "../../helper/SecurityHelper";
import {toast} from "react-toastify";
import messageCodeDic from "../../util/messageCodeDic";
import errorCodeDic from "../../util/errorCodeDic";
import BMCSelect from "../../components/common/BMCSelect";
import {ProgressContext} from "../../context/Progress";
import restapi from "../../helper/restAPIHelper";
import SafetyEduRgstModal from "../safetyEducationManagement/modal/SafetyEduRgstModal";
import SHSearch from '../component/BMCSearch';
import {Col, Container, Row, Table} from 'react-bootstrap';
import SubMenuTitle from "../component/SubMenuTitle";
import BMCIconButton from "../../components/common/BMCIconButton";
import BMCInput from "../../components/common/BMCInput";
import BMCDatePicker from "../../components/common/BMCDatePicker";
import CustomDatePicker from "../component/ui/DatePicker";
import BMCTreeView from "../../components/common/BMCTreeView";
import {CmmnFn} from "../../helper/CmmnHelper";
import CustomDatePickerRange from "../component/ui/DatePickerRange";
import DprtDtlStatModal from "./modal/DprtDtlStatModal";
import WrkPlcStatModal from "./modal/WrkPlcStatModal";
import {UserContext} from "../../context/User";

type Props = {};

Modal.setAppElement('#root');
const WorkSectionStatusManagement = (props: Props) => {
    const [modalIsOpen, setModalIsOpen] = React.useState(false);
    const defaultUser = getLoginUser();
    const searchFormRef = useRef<any>();
    const [datatable, setDatatable] = useState<any>();
    const siteIdRef = useRef<any>();
    const [data, setData] = useState<any>()
    const [isDatePopupOpen, setIsDatePopupOpen] = useState(false);
    const prodRef = useRef<any>();

    const {spinner} = useContext(ProgressContext);

    const [strtDate, setStrtDate] = useState<string>(CmmnFn.getAmonthAgoDate);
    const [endDate, setEndDate] = useState<string>(CmmnFn.fn_getCurrentDate);
    const [wrkDt, setWrkDt] = useState<string>('');
    const {user} = useContext<any>(UserContext);

    useEffect(()=>{

        if(datatable){
            datatable.draw();
        }

    }, [user, datatable])

    useEffect(() => {
        if (!modalIsOpen) {
            datatable?.draw();
        }
    }, [modalIsOpen])


    const fn_search = (e?: any) => {
        if (e) {
            e.preventDefault()
        }

        datatable?.draw();

    }

    const fn_setFormData = (e: any) => {

        setData({
            ...data,
            [e.target.name]: e.target.value
        })

    }

    const fn_datePopupOpen = () => {
        setIsDatePopupOpen(!isDatePopupOpen);
    };


    return (
        <Container fluid className="bg-white px-0 bmc-txt-color-primary">
            <SubMenuTitle title={'작업 구간 현황 관리'} parentMenu={'인력 관리'}/>
            <WrkPlcStatModal siteId={user?.blngSiteId || defaultUser.blngSiteId} wrkDt={wrkDt} modalIsOpen={modalIsOpen} setModalIsOpen={setModalIsOpen}></WrkPlcStatModal>
            <div className="p-4 overflow-y-auto" style={{height: 'calc(100vh - 180px)'}}>
                <SHSearch datatable={datatable} formRef={searchFormRef} btnType={'p'}>
                    <Row>
                        <Col>
                            <Table className="bmc-font-SP bmc-fs-b2 my-3">
                                <tbody>
                                <tr className="border-top border-bottom align-middle">
                                    <th className="bmc-bg-th">기간</th>
                                    <td>
                                        <BMCInput type={'hidden'} name={'siteId'} value={user?.blngSiteId || defaultUser.blngSiteId}></BMCInput>
                                        <div className="d-flex align-items-center w-100" ref={prodRef}>
                                            <BMCDatePicker
                                                className={"text-center"} readOnly={true}
                                                value={strtDate} name={'strtDate'} onClick={fn_datePopupOpen}
                                                onChange={fn_setFormData}
                                                calendar={fn_datePopupOpen}
                                            />
                                            <span>&nbsp;~&nbsp;</span>
                                            <BMCDatePicker readOnly={true}
                                                           className={"text-center"}
                                                           value={endDate} name={'endDate'} onClick={fn_datePopupOpen}
                                                           onChange={fn_setFormData}
                                                           calendar={fn_datePopupOpen}
                                            />

                                            {isDatePopupOpen
                                                ? <CustomDatePickerRange setIsDatePopupOpen={setIsDatePopupOpen}
                                                                         prodRef={prodRef}
                                                                         setStartDateView={setStrtDate}
                                                                         setEndDateView={setEndDate}/>
                                                : null}
                                        </div>
                                    </td>

                                </tr>
                                </tbody>
                            </Table>
                        </Col>
                    </Row>
                </SHSearch>


                <div className={'ge-result'}>
                    <Datatables
                        dataUrl={'/admin/api/qrnfc/retrieveWrkSctDprtStatDataList'}
                        parameterFormRef={searchFormRef}
                        columns={[
                            {data: "useDt", title: "일자", textAlign: 'center'},
                            {data: "totCnt", title: "현장 총 출역 인원", textAlign: 'center'},
                            {data: "mngrCnt", title: "작업구간 관리자 수", textAlign: 'center'},
                            {data: "wrkrCnt", title: "작업구간 근로자 수", textAlign: 'center'},
                            {data: "totSctCnt", title: "작업구간 인원 합계", textAlign: 'center'},
                            {data: "wrkPlcCnt", title: "작업구간 개수", textAlign: 'center'},


                        ]}
                        checked={false}
                        index={true}

                        onLoad={(dt: any) => {

                            setDatatable(dt)
                        }}
                        onClick={(data) => {
                            setWrkDt(data.useDt);
                            setModalIsOpen(true);
                        }
                        }
                    />
                </div>
            </div>
        </Container>


    );
};

export default WorkSectionStatusManagement;
