import {Outlet, Route, Routes, useNavigate} from "react-router-dom";
import {getLoginUser} from "../helper/SecurityHelper";
import React, {useContext, useEffect} from "react";
import {ProgressContext} from "../context/Progress";
import {Box} from "@mui/material";
import {ToastContainer} from "react-toastify";
import Menubar from "../components/common/Menubar";
import Login from "../pages/Login";
import MainLayout from "../components/layout/MainLayout";
import {fcltyRoutes, headRoutes, ivstRoutes, routes, smSiteRoutes} from "./index";
import HeadOfficeMonitoring from "../pages/headoffice/HeadScreenRoot";
import {UserContext} from "../context/User";
import HeadScreenRoot from "../pages/headoffice/HeadScreenRoot";
import MainControl from "../pages/headoffice/MainControl";

const MainRoutes = () => {
    const defaultUser = getLoginUser()
    const {user, setUser} = useContext<any>(UserContext);
    var AudioContext;
    var audioContext;

    window.onload = function() {
        navigator.mediaDevices.getUserMedia({ audio: true }).then(() => {
            // @ts-ignore
            AudioContext = window.AudioContext || window.webkitAudioContext;
            audioContext = new AudioContext();
        }).catch(e => {
            console.error(`Audio permissions denied: ${e}`);
        });
    }


    useEffect(() => {
    }, []);

    return (
        <Routes>
            <Route path="/login" element={<Login/>}/>

            {defaultUser ? (
                (((user?.authGrpId || defaultUser.authGrpId) == 'SITE') ||

                ((user?.authGrpId || defaultUser.authGrpId) == 'HEAD' && (user?.blngSiteId || defaultUser.blngSiteId)))

                    ? <>
                        {(user?.isSmSite || defaultUser.isSmSite)
                            ? <><Route path="/smSite" element={<MainLayout isHead={false} isSmSite={true}/>}>
                                {smSiteRoutes}
                            </Route>
                                <Route path="/" element={<Login/>}/>
                            </> :
                            <Route path="/" element={<MainLayout isHead={false} isSmSite={false}/>}>
                                {routes}
                            </Route>

                        }


                    </> :
                     <>
                        <Route path="/" element={<MainLayout isHead={true} isSmSite={false}/>}>
                            {headRoutes}
                        </Route>
                         <Route path="/ivst" element={<MainLayout isHead={true} isIvst={true} isSmSite={false}/>}>
                             {ivstRoutes}
                         </Route>
                         <Route path="/fclty" element={<MainLayout isHead={true} isFclty={true} isSmSite={false}/>}>
                             {fcltyRoutes}
                         </Route>
                        <Route path={'/mainControl'} element={<MainControl/>} />
                    </>
            ) : (
                <Route path="/" element={<Login/>}/>
            )

            }


        </Routes>

    );
};

export default MainRoutes;
