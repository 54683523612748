import React, {Dispatch, SetStateAction, useEffect, useRef, useState} from "react";
import BMCDatePicker from "./BMCDatePicker";
import CustomDatePickerRange from "../../pages/component/ui/DatePickerRange";
import {CmmnFn} from "../../helper/CmmnHelper";

interface IProps {
    setStartDate: Dispatch<SetStateAction<any>>;
    setEndDate: Dispatch<SetStateAction<any>>;
    startDate: any;
    endDate: any;
    onChange: any;
    strtDateName?: string;
    endDateName?: string;
    disabled?: boolean;


}

export default function BMCRangeDatePicker(props: IProps) {
    const prodRef = useRef<any>();
    const [isDatePopupOpen, setIsDatePopupOpen] = useState(false);

    const fn_datePopupOpen = () => {
        setIsDatePopupOpen(!isDatePopupOpen);
    };

    return (
        <div className="d-flex align-items-center w-100" ref={prodRef}>
            <BMCDatePicker
                className={"text-center"} readOnly={true} disabled={props.disabled || false}
                value={props.startDate} name={props.strtDateName || 'strtDt'} onClick={fn_datePopupOpen}
                onChange={props.onChange}
                calendar={fn_datePopupOpen}
            />
            <span>&nbsp;~&nbsp;</span>
            <BMCDatePicker readOnly={true} disabled={props.disabled || false}
                           className={"text-center"}
                           value={props.endDate} name={props.endDateName || 'endDt'} onClick={fn_datePopupOpen}
                           onChange={props.onChange}
                           calendar={fn_datePopupOpen}
            />

            {(isDatePopupOpen && (!props.disabled || false) )
                ? <CustomDatePickerRange setIsDatePopupOpen={setIsDatePopupOpen}
                                         prodRef={prodRef}
                                         setStartDateView={props.setStartDate}
                                         setEndDateView={props.setEndDate}/>
                : null}
        </div>
    )
}
