import React, {useContext, useEffect, useRef, useState} from 'react';
import SubMenuTitle from "../component/SubMenuTitle";
import {Col, Container, Row, Tab, Table, Tabs} from 'react-bootstrap';
import SHSearch from "../component/BMCSearch";
import BMCSelect from "../../components/common/BMCSelect";
import BMCDatePicker2 from "../../components/common/BMCDatePicker2";
import Datatables, {getCheckedItems} from "../component/Datatables";
import BMCIconButton from "../../components/common/BMCIconButton";
import {ProgressContext} from "../../context/Progress";
import {getLoginUser} from "../../helper/SecurityHelper";
import {DateHelper} from "../../helper/DateHelper";
import {toast} from "react-toastify";
import messageCodeDic from "../../util/messageCodeDic";
import restapi from "../../helper/restAPIHelper";
import axios from "axios";
import errorCodeDic from "../../util/errorCodeDic";
import FcltySftyChkModal from "./modal/FcltySftyChkModal";
import BMCInput from "../../components/common/BMCInput";
import {CmmnFn} from "../../helper/CmmnHelper";
import SvrAcdntPnshmntChkListModal from "../safetyWorkReportManagement/modal/SvrAcdntPnshmntChkListModal";
import SvrAcdntPnshmntByDatePage from "../safetyWorkReportManagement/subPage/SvrAcdntPnshmntByDatePage";
import SvrAcdntPnshmntByDprtPage from "../safetyWorkReportManagement/subPage/SvrAcdntPnshmntByDprtPage";
import FcltySftyPlanPage from "./subPage/FcltySftyPlanPage";
import FcltySftyHistPage from "./subPage/FcltySftyHistPage";
import IvstSftyChkModal from "../ivst/modal/IvstSftyChkModal";

type Props = {};

const FcltySftyChkPage = (props: Props) => {

    const user = getLoginUser();
    const {spinner} = useContext(ProgressContext);
    const [dataList, setDataList] = useState<any>([]);
    const [srchData, setSrchData] = useState<any>({workTypeCd: 'T002004', strtDt:CmmnFn.getAmonthAgoDate(), endDt:CmmnFn.fn_getCurrentDate(), tabGb: 'plan'});
    const [statData, setStatData] = useState<any>({totSiteCnt: 0, noSiteCnt: 0, totWrkrCnt: 0, noWrkrCnt: 0});
    const [modalIsOpen, setModalIsOpen] = useState<boolean>(false);
    const [wrkrDataList, setWrkrDataList] = useState<any>([]);
    const [selData, setSelData] = useState<any>(null);

    const [isDatePopupOpen, setIsDatePopupOpen] = useState<boolean>(false);
    const prodRef = useRef<any>();

    useEffect(() => {
        if(!modalIsOpen){
            fn_search();
        }
    }, [srchData,modalIsOpen])

    useEffect(() => {
        if(selData){
            setModalIsOpen(true);
        }

    }, [selData]);

    const fn_search = () => {

        spinner.start();
        restapi.retrieveIvstSftyManageTotDataList(srchData).then((rsltData: any) => {
            console.log('rsltData: ', rsltData);
            setDataList(rsltData)
        }).catch(error => {
            toast.error(errorCodeDic['003']);
            console.log('error: ', error);
        })
            .finally(() => {
                spinner.stop();
            });
    }

    return (
        <Container fluid className="bg-white px-0 pb-4 bmc-text-font_primary">
            <FcltySftyChkModal setModalIsOpen={setModalIsOpen} modalIsOpen={modalIsOpen} detailData={selData}/>
            <SubMenuTitle title={'안전점검 및 정밀안전 진단 관리'} parentMenu={'안전점검 및 정밀안전 진단 관리'}/>
            <div className="p-4 overflow-y-auto" style={{height: 'calc(100vh - 180px)'}}>
                <Tabs
                    transition={true}
                    activeKey={srchData.tabGb}
                    onSelect={(k) =>{
                        let isHist = false;
                        if(k == 'hist') {
                            isHist = true;
                        }

                        setSrchData({
                        ...srchData,
                        'tabGb': k,
                        'isHist': isHist
                    })}}
                    id="tabGb"
                    className="mb-3 mt-2"
                >
                    <Tab eventKey="plan" title="안전점검 및 정밀안전 계획">
                        <FcltySftyPlanPage setModalIsOpen={setModalIsOpen} dataList={dataList} srchData={srchData} setSrchData={setSrchData} setSelData={setSelData} isDatePopupOpen={isDatePopupOpen} setIsDatePopupOpen={setIsDatePopupOpen} onSearch={fn_search}/>
                    </Tab>
                    <Tab eventKey="hist" title="안전점검 및 정밀안전 이력">
                        <FcltySftyHistPage dataList={dataList} srchData={srchData} setSrchData={setSrchData} setSelData={setSelData} isDatePopupOpen={isDatePopupOpen} setIsDatePopupOpen={setIsDatePopupOpen} onSearch={fn_search} />
                    </Tab>

                </Tabs>
            </div>
        </Container>


    );



};

export default FcltySftyChkPage;
