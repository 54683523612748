import React, {Dispatch, SetStateAction, useContext, useEffect, useRef, useState} from "react";
import {COMMON_MODAL_STYLE} from "../../../helper/StyleHelper";
import {Col, Modal, Row, Table} from "react-bootstrap";
import {IoMdClose} from "react-icons/io";
import Datatables, {getCheckedItems} from "../../component/Datatables";
import SHSearch from "../../component/BMCSearch";
import BMCInput from "../../../components/common/BMCInput";
import restapi from "../../../helper/restAPIHelper";
import {toast} from "react-toastify";
import errorCodeDic from "../../../util/errorCodeDic";
import {ProgressContext} from "../../../context/Progress";
import BMCSelect from "../../../components/common/BMCSelect";
import DailySftyRprtRgstModal from "./DailySftyRprtRgstModal";
import BMCIconButton from "../../../components/common/BMCIconButton";
import axios from "axios/index";

interface IProps {
    setModalIsOpen: Dispatch<SetStateAction<boolean>>;
    modalIsOpen: boolean;
    dataList: any;
}

const $ = require("jquery")

const DailySftyRprtChkListModal = (props: IProps) => {

    function fn_closeModal() {
        props.setModalIsOpen(false);
    }

    const fn_onShow = () => {

    }

    return (
        <div>
            <Modal
                className="bmc-modal sub" size="lg"
                show={props.modalIsOpen}
                onShow={() => {
                    fn_onShow()

                }}
                onHide={fn_closeModal}
            >


                <Modal.Header>
                    <div className="d-flex justify-content-between align-items-center w-100 bmc-font-SD">
                        미제출 사업장 보기 ({props.dataList.length}건)
                        <IoMdClose className='hover:cursor-pointer' onClick={fn_closeModal}/>
                    </div>
                </Modal.Header>

                <Modal.Body className="px-4">

                    <div className={'ge-result'}>
                        <Datatables
                            datas={props.dataList}
                            scrollY="550px"
                            className="bmc-fs-b4"
                            columns={[
                                {data: "siteName", title: "공사명", textAlign: 'center'},

                            ]}
                            checked={false}
                            onLoad={(dt: any) => {
                            }}

                            onClick={(data) => {

                            }
                            }
                        />
                    </div>
                </Modal.Body>
                <Modal.Footer className="py-2">
                    <div className='flex justify-center w-100'>
                        <button
                            className='bmc-btn pill l white me-3'
                            onClick={fn_closeModal}>닫기
                        </button>

                    </div>
                </Modal.Footer>


            </Modal>
        </div>
    )
}

export default DailySftyRprtChkListModal;
