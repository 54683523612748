import React, {useContext, useEffect, useRef, useState} from 'react';
import axios from "axios";
import Datatables, {getCheckedItems} from "../component/Datatables";
import Modal from 'react-modal';
import {getLoginUser} from "../../helper/SecurityHelper";
import {toast} from "react-toastify";
import messageCodeDic from "../../util/messageCodeDic";
import errorCodeDic from "../../util/errorCodeDic";
import BMCSelect from "../../components/common/BMCSelect";
import SafetyEduRgstModal from "./modal/SafetyEduRgstModal";
import {CmmnFn} from "../../helper/CmmnHelper";
import {ProgressContext} from "../../context/Progress";
import SHSearch from '../component/BMCSearch';
import {Col, Container, Row, Table} from 'react-bootstrap';
import SubMenuTitle from "../component/SubMenuTitle";
import BMCIconButton from '../../components/common/BMCIconButton';
import {UserContext} from "../../context/User";
import BMCInput from "../../components/common/BMCInput";
import BMCDatePicker from "../../components/common/BMCDatePicker";
import CustomDatePickerRange from "../component/ui/DatePickerRange";
import BMCRangeDatePicker from "../../components/common/BMCRangeDatePicker";
import BMCCheckbox from "../../components/common/BMCCheckbox";


type Props = {};

Modal.setAppElement('#root');
const SafetyEducationManagementPage = (props: Props) => {
    const [dataList, setDataList] = useState<any[]>([]);
    const [modalIsOpen, setModalIsOpen] = React.useState(false);
    const [detailData, setDetailData] = useState(null);
    const [isSaveSuccess, setIsSaveSuccess] = useState(false);
    const defaultUser = getLoginUser();
    const srchTxtRef = useRef<any>();
    const [srchData, setSrchData] = useState<any>()
    const {spinner} = useContext(ProgressContext);
    const searchFormRef = useRef<any>();
    const [datatable, setDatatable] = useState<any>();
    const {user} = useContext<any>(UserContext);
    const [startDate, setStartDate] = useState<string>('');
    const [endDate, setEndDate] = useState<string>('');


    useEffect(() => {
        setStartDate(CmmnFn.dateFormat(-2));
        setEndDate(CmmnFn.dateFormat(1));
    }, [])

    useEffect(()=>{

        if(datatable){
            datatable.draw();
        }

    }, [user, datatable])

    useEffect(() => {
        if (!modalIsOpen) {
            datatable?.draw();
        }
    }, [modalIsOpen])

    const fn_setSearchData = (e: any) => {

        if (e.target.type == 'checkbox') {
            setSrchData({
                ...srchData,
                [e.target.name]: e.target.checked
            })
        } else {

            setSrchData({
                ...srchData,
                [e.target.name]: e.target.value
            })
        }
    }

    const fn_search = (e?: any) => {

        if (e) {
            e.preventDefault()
        }
        datatable.draw();

        setIsSaveSuccess(false);
    }

    const fn_delete = () => {
        var checkedData = getCheckedItems(datatable);
        let delList: number[] = [];

        if (checkedData.length == 0) {
            toast.warn(messageCodeDic['005']);
            return;
        }

        checkedData.map((data: any) => {
            delList.push((data.eduId));
        })

        if (!window.confirm(messageCodeDic['004'])) {
            return;
        }

        axios.post('/admin/api/edu/deleteEduData', {delList: delList}).then(rsltData => {
                if (rsltData.data > 0) {
                    toast.success(messageCodeDic['002'])
                    fn_search();
                } else {
                    toast.error(errorCodeDic['003'])
                }

            }
        ).catch(() => {
                toast.error(errorCodeDic['003'])
            }
        )
    }


    const fn_registPopOpen = () => {
        setDetailData(null);
        setModalIsOpen(true);
    }

    const fn_onIsAllProdChg = (e:any) => {
        if(e.target.value){
            setStartDate('');
            setEndDate('');
        }
    }

    return (
        <Container fluid className="bg-white px-0 bmc-txt-color-primary">
            <SubMenuTitle title={'안전교육 관리'} parentMenu={'안전교육 관리'}/>

            <SafetyEduRgstModal setModalIsOpen={setModalIsOpen} modalIsOpen={modalIsOpen}  siteId={user?.blngSiteId || defaultUser.blngSiteId}
                                detailData={detailData} setIsSaveSuccess={setIsSaveSuccess}/>

            <div className="p-4 overflow-y-auto" style={{height: 'calc(100vh - 180px)'}}>
                <SHSearch datatable={datatable} formRef={searchFormRef} btnType={'p'}>
                    <Row>
                        <Col>
                            <Table className="bmc-font-SP bmc-fs-b2 my-3">
                                <tbody>
                                <tr className="border-top border-bottom align-middle">
                                    <th className="bmc-bg-th">교육종류</th>
                                    <td>
                                        <BMCInput type={'hidden'} name={'siteId'} value={user?.blngSiteId || defaultUser.blngSiteId} />
                                        <BMCSelect initOptionGb={'all'} name={'eduGbCd'} codeGb={'cmmn'} codeGrpId={'E001'} className={"min-w-[100px]"}
                                                   onChange={fn_setSearchData}
                                                   value={srchData?.eduGbCd}></BMCSelect>
                                    </td>
                                    <th className="bmc-bg-th">기간</th>
                                    <td>
                                        <div className="d-flex align-items-center w-100">
                                            <BMCRangeDatePicker disabled={srchData?.isAllProd || false} startDate={startDate} setStartDate={setStartDate} endDate={endDate} setEndDate={setEndDate} onChange={fn_setSearchData} />
                                            <BMCCheckbox name={'isAllProd'} onChange={(e: any) => {fn_setSearchData(e); fn_onIsAllProdChg(e);}}
                                                         checked={srchData?.isAllProd} className={'ml-3 w-[110px]'}>전체기간</BMCCheckbox>
                                        </div>
                                    </td>

                                    <th className="bmc-bg-th">검색어</th>
                                    <td className="w-[40%]">
                                        <input type={'text'} name={'srchTxt'} className='form-control' ref={srchTxtRef}/>
                                    </td>
                                </tr>
                                </tbody>
                            </Table>
                        </Col>
                    </Row>
                </SHSearch>


                <div className='w-100 d-flex justify-content-end align-items-center mb-2'>
                    <BMCIconButton kind={'submit'} marginRight={'5px'} onClick={fn_registPopOpen}>등록</BMCIconButton>
                    <BMCIconButton kind={'delete'} marginRight={'0'} onClick={fn_delete}>삭제</BMCIconButton>
                </div>

                <div className={'ge-result'}>
                    <Datatables
                        dataUrl={'/admin/api/edu/retrieveEduDataList'}
                        parameterFormRef={searchFormRef}
                        columns={[
                            {data: "eduGbNm", title: "구분"},
                            {data: "eduTitle", title: "교육제목"},
                            {data: "eduDt", title: "교육일자"},
                            {data: "tchrNm", title: "강사성명"},
                            {data: "eduPlc", title: "교육장소"},
                            {data: "eduTimeProd", title: "교육 시간"},
                            {data: "rgstrNm", title: "작성자"},
                        ]}
                        checked={true}
                        index={true}

                        onLoad={(dt: any) => {
                            setDatatable(dt)
                        }}
                        onClick={(data) => {
                            setDetailData(data)
                            setModalIsOpen(true);
                        }
                        }
                    />
                </div>
            </div>
        </Container>


    );
};

export default SafetyEducationManagementPage;
