import React, {Dispatch, SetStateAction, useContext, useEffect, useRef, useState} from "react";
import {COMMON_MODAL_STYLE} from "../../../helper/StyleHelper";
import {Col, Modal, Row, Table} from "react-bootstrap";
import {IoMdClose} from "react-icons/io";
import Datatables, {getCheckedItems} from "../../component/Datatables";
import axios from "axios";
import {toast} from "react-toastify";
import messageCodeDic from "../../../util/messageCodeDic";
import BMCSelect from "../../../components/common/BMCSelect";
import {getLoginUser, setItemWithExpireTime, USER_SESSION} from "../../../helper/SecurityHelper";
import SHSearch from "../../component/BMCSearch";
import BMCInput from "../../../components/common/BMCInput";
import restapi from "../../../helper/restAPIHelper";
import SiteSftyChkRgstModal from "../../safetyWorkReportManagement/modal/SiteSftyChkRgstModal";
import SiteManageRgstModal from "../../headManage/modal/SiteManageRgstModal";
import {SiteContext} from "../../../context/Site";
import {useNavigate} from "react-router-dom";
import errorCodeDic from "../../../util/errorCodeDic";
import {ProgressContext} from "../../../context/Progress";
import {UserContext} from "../../../context/User";
import FcltySiteRgstModal from "../../fclty/modal/FcltySiteRgstModal";

interface IProps {
    setModalIsOpen: Dispatch<SetStateAction<boolean>>;
    modalIsOpen: boolean;
    siteType?: string;
    dataList: object[];

}

const SiteInfoListModal = (props: IProps) => {
    const [datatable, setDatatable] = useState<any>();

    const [isCodeDataLoaded, setIsCodeDataLoaded] = useState<any>(null);
    const searchFormRef = useRef<any>();
    const [dataList, setDataList] = useState<any>([]);
    const [orgnDataList, setOrgnDataList] = useState<any>([]);
    const {setSite} = useContext<any>(SiteContext);
    const navigate = useNavigate();
    const {spinner} = useContext(ProgressContext);
    const defaultUser: any = getLoginUser();
    const {user,setUser} = useContext<any>(UserContext);
    const [modalIsOpen, setModalIsOpen] = React.useState(false);
    const [detailData, setDetailData] = useState(null);

    useEffect(() => {
        if (isCodeDataLoaded) {
            fn_search();
        }
    }, [isCodeDataLoaded]);

    function fn_closeModal() {
        props.setModalIsOpen(false);
    }

    const fn_search = (e?: any) => {
        e?.preventDefault()
        datatable?.draw()
    }

    const fn_onShow = () => {
        if(props.siteType){
            var arr = []
            for(var i in props.dataList){
                var item:any = props.dataList[i]
                if(item.siteType == props.siteType){
                    arr.push(item)
                }
            }
            setDataList(arr);
        }else{
            setDataList(props.dataList);
        }
        setOrgnDataList(props.dataList);
    }


    const fn_moveToSiteWeb = (siteId: number) => {
        /* nhk temp */
        // if(![449,437,131].includes(siteId)){
        //     return;
        // }

        spinner.start();
        restapi.changeUserSiteHeadWebStat({blngSiteId: siteId, userSeq: defaultUser.userSeq}).then((rsltCnt: any) => {
            if (rsltCnt > 0) {
                restapi.getUserSession(defaultUser.userSeq).then((data: any) => {
                    setUser(data);
                    setItemWithExpireTime(USER_SESSION, data);
                    navigate('/')
                });
            }else{
                toast.error(errorCodeDic['003']);
            }
        }).catch(error => {
            toast.error(errorCodeDic['003']);
            console.log('error: ', error);
        })
            .finally(() => {
                spinner.stop();
            });
    }


    return (
        <div>
            <FcltySiteRgstModal setModalIsOpen={setModalIsOpen} modalIsOpen={modalIsOpen} detailData={detailData} />
            <Modal
                className="bmc-modal" size="lg"
                show={props.modalIsOpen}
                onShow={() => {
                    fn_onShow()

                }}
                onHide={fn_closeModal}
            >


                <Modal.Header>
                    <div className="d-flex justify-content-between align-items-center w-100 bmc-font-SD">
                        현장 목록
                        <IoMdClose className='hover:cursor-pointer' onClick={fn_closeModal}/>
                    </div>
                </Modal.Header>

                <Modal.Body className="px-4">

                    <div className={'ge-result'}>
                        {props.siteType === 'T002004' ?
                            <Datatables
                                datas={dataList}
                                scrollY="550px"
                                className="bmc-fs-b4"
                                parameterFormRef={searchFormRef}
                                columns={[
                                    {data: "siteTypeNm", title: "구분", textAlign: 'center'},
                                    {data: 'fcltyGbNm', title: "유형", textAlign: 'center'},
                                    {data: "siteName", title: "현장명", textAlign: 'center'},
                                    {data: "siteAddress", title: "현장주소", textAlign: 'center'},
                                    {data: "cnstrProd", title: "공사기간", textAlign: 'center'},
                                    {data: "cnstrPrice", title: "공사규모", textAlign: 'center', format:'withCom'},
                                    {data: "headMstrNm", title: "공사담당자", textAlign: 'center'},
                                    {data: "headMstrPhone", title: "공사 담당자 연락처", textAlign: 'center'},
                                    {data: "siteMstrNm", title: "현장담당자", textAlign: 'center'},
                                    {data: "siteMstrPhone", title: "현장담당자 연락처", textAlign: 'center'},
                                ]}
                                checked={false}
                                onLoad={(dt: any) => {
                                    setDatatable(dt)
                                }}

                                onClick={(data) => {
                                    fn_moveToSiteWeb(data.siteId);
                                    //navigate('/siteSftyMng/fcltySftyMng');
                                    // setDetailData(data);
                                    // setModalIsOpen(true);
                                    //setSite(data);
                                    //props.setModalIsOpen(false);

                                }
                                }
                            /> :
                            <Datatables
                                datas={dataList}
                                scrollY="550px"
                                className="bmc-fs-b4"
                                parameterFormRef={searchFormRef}
                                columns={[
                                    {data: "siteTypeNm", title: "구분", textAlign: 'center'},
                                    {data: "siteName", title: "현장명", textAlign: 'center'},
                                    {data: "siteAddress", title: "현장주소", textAlign: 'center'},
                                    {data: "cnstrProd", title: "공사기간", textAlign: 'center'},
                                    {data: "cnstrPrice", title: "공사규모", textAlign: 'center', format:'withCom'},
                                    {data: "headMstrNm", title: "공사담당자", textAlign: 'center'},
                                    {data: "headMstrPhone", title: "공사 담당자 연락처", textAlign: 'center'},
                                    {data: "siteMstrNm", title: "현장담당자", textAlign: 'center'},
                                    {data: "siteMstrPhone", title: "현장담당자 연락처", textAlign: 'center'},
                                ]}
                                checked={false}
                                onLoad={(dt: any) => {
                                    setDatatable(dt)
                                }}

                                onClick={(data) => {
                                    if(['T002001', 'T002005'].includes(data.siteType)){
                                        fn_moveToSiteWeb(data.siteId);
                                    }
                                    //setSite(data);
                                    //props.setModalIsOpen(false);

                                }
                                }
                            />
                        }


                    </div>
                </Modal.Body>
                <Modal.Footer className="py-2">
                    <div className='flex justify-center w-100'>
                        <button
                            className='bmc-btn pill l white me-3'
                            onClick={fn_closeModal}>닫기
                        </button>

                    </div>
                </Modal.Footer>


            </Modal>
        </div>
    )
}

export default SiteInfoListModal;
