import React, {useEffect, useState} from "react";
import {Container} from "react-bootstrap";
import restapi from "../../helper/restAPIHelper";
import FcltySftyChkModal from "../fclty/modal/FcltySftyChkModal";

export default function FcltyWeekNotice(){

    const [thisWeekDataList, setThisWeekDataList] = useState<any>([]);
    const [nextWeekDataList, setNextWeekDataList] = useState<any>([]);
    const [isModalOpen, setIsModalOpen] = useState<boolean>(false);
    const [detailData, setDetailData] = useState<any>({});

    useEffect(() => {
        restapi.retrieveIvstSftyManageTotDataList({workTypeCd: 'T002004', isThisWeek: true, isEfctAsc: true}).then((rsltData: any) => {
            console.log('thisWeek: ', rsltData);
            setThisWeekDataList(rsltData);
        });

        restapi.retrieveIvstSftyManageTotDataList({workTypeCd: 'T002004', isNextWeek:true, isEfctAsc: true}).then((rsltData: any) => {
            console.log('nextWeek: ', rsltData);
            setNextWeekDataList(rsltData);
        })
    }, []);

    const fn_openDtlModal = (data: any) => {
        setDetailData(data);
        setIsModalOpen(true);
    }

    return(
        <Container fluid className="bmc-bg-pane">
            <FcltySftyChkModal setModalIsOpen={setIsModalOpen} modalIsOpen={isModalOpen} detailData={detailData}/>
            <div className="d-flex align-items-center justify-content-between pb-2 border-bottom-v2 mb-2">
                <span className="bmc-area-title-v3">금주 안전 점검 현장</span>
            </div>
            <div className="d-flexpx-0 mb-3">
                <div>
                    {
                        thisWeekDataList.length > 0 ?
                        thisWeekDataList.map((data: any, idx: number) => (
                            idx < 5 &&
                            <div className="cursor-pointer d-flex" key={idx} onClick={() => fn_openDtlModal(data)}>
                                <div className="d-inline-flex gap-2 calendar-notice-item w-100">
                                    <span className="fclty-notice-date">{data.efctDtMmdd}</span>
                                    <span className="calendar-notice-title align-self-center">{data.siteName}</span>
                                </div>
                            </div>
                        )) : <div className="py-2 bmc-txt-size-b5">
                                <div className="calendar-notice-title">금주 점검 현장이 없습니다.</div>
                            </div>

                    }
                </div>
            </div>

            <div className="d-flex align-items-center justify-content-between pb-2 border-bottom-v2 mb-2">
                <span className="bmc-area-title-v3">차주 안전 점검 현장</span>
            </div>
            <div className="d-flexpx-0">
                <div>
                    {
                        nextWeekDataList.length > 0 ?
                        nextWeekDataList.map((data: any, idx: number) => (
                            idx < 5 &&
                            <div className="cursor-pointer d-flex" key={idx} onClick={() => fn_openDtlModal(data)}>
                                <div className="d-inline-flex gap-2 calendar-notice-item w-100">
                                    <span className="fclty-notice-date">{data.efctDtMmdd}</span>
                                    <span className="calendar-notice-title align-self-center">{data.siteName}</span>
                                </div>
                            </div>
                        )): <div className="py-2 bmc-txt-size-b5">
                                <div className="calendar-notice-title">차주 점검 현장이 없습니다.</div>
                            </div>
                    }
                </div>
            </div>
        </Container>
    )
}
