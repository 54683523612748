import {Modal, Table} from "react-bootstrap";
import {IoMdClose} from "react-icons/io";
import iconCalendar from "../../../images/icon-calendar-black.svg";
import CustomDatePickerRange from "../../component/ui/DatePickerRange";
import React, {Dispatch, SetStateAction, useContext, useEffect, useRef, useState} from "react";
import {fn_getCurrentDate} from "../../../helper/CmmnHelper";
import {getLoginUser} from "../../../helper/SecurityHelper";
import {Col, Row} from "react-bootstrap";
import {Container} from "@mui/material";
import {COMMON_MODAL_STYLE} from "../../../helper/StyleHelper";
import BMCInput from "../../../components/common/BMCInput";
import BMCTextarea from "../../../components/common/BMCTextarea";
import axios from "axios";
import {toast} from "react-toastify";
import messageCodeDic from "../../../util/messageCodeDic";
import errorCodeDic from "../../../util/errorCodeDic";
import BMCSelect from "../../../components/common/BMCSelect";
import SiteCardList from "../../../components/site/SiteCardList";
import {ChangeMeta, NumberFormatBase} from "react-number-format";
import {Formatter} from "../../../helper/FormatHelper";
import {ProgressContext} from "../../../context/Progress";


interface IProps {
    setModalIsOpen: Dispatch<SetStateAction<boolean>>;
    modalIsOpen: boolean;
    riskAsmntId: number;
    setIsRjctd: Dispatch<SetStateAction<boolean>>;

}

const RjctRsnModal = (props: IProps) => {

    const [data, setData] = useState<any>({});
    const user = getLoginUser();
    const {spinner} = useContext(ProgressContext);

    const fn_setFormData = (e: any) => {

        setData({
            ...data,
            [e.target.name]: e.target.value
        })

    }


    const fn_onAfterOpen = () => {
    }


    function fn_closeModal() {
        props.setModalIsOpen(false);
    }

    const fn_registData = () => {
        if(!data.rjctRsn){
            toast.warn('반려 사유를 입력해 주십시오');
            return;
        }

        if(!window.confirm('반려 하시겠습니까?')){
            return;
        }
        const param = {riskAsmntId: props.riskAsmntId, userSeq: user.userSeq, rjctRsn: data.rjctRsn};
        spinner.start();
        axios.post('/admin/api/rskAsmnt/reject', param).then((rslt) => {
            if (rslt.data > 0) {
                toast.success(messageCodeDic['006']);
                props.setModalIsOpen(false);
                props.setIsRjctd(true);
            } else {
                toast.warn(errorCodeDic['003']);
            }
        }).catch(error => {
            toast.error(errorCodeDic['003']);
            console.log('error: ', error);
        })
            .finally(() => {
                spinner.stop();
            });
    }


    return (
        <div>


            <Modal
                className="bmc-modal sub" size={'lg'}
                show={props.modalIsOpen}
                onShow={fn_onAfterOpen}
                onHide={fn_closeModal}
            >
                <Modal.Header>
                    <div className="d-flex justify-content-between align-items-center w-100 bmc-font-SD">
                        {'반려 사유'}
                        <IoMdClose className='hover:cursor-pointer' onClick={fn_closeModal}/>
                    </div>
                </Modal.Header>


                <Modal.Body className="px-4 bmc-modal-table-w100">
                    <form name={'form'}>
                        <Table className="my-2 align-middle">
                            <tbody>
                            <tr>
                                <th className="bmc-bg-th" style={{width: '25%'}}>{'반려 사유'}</th>
                                <td style={{width: '75%'}}>
                                    <BMCInput type="text" className="form-control" name={'rjctRsn'}
                                             onChange={fn_setFormData} value={data?.rjctRsn}/>
                                </td>
                            </tr>
                            </tbody>
                        </Table>
                    </form>
                </Modal.Body>
                <Modal.Footer className="py-2">
                    <div className='flex justify-center w-100'>
                        <button
                            className='bmc-btn pill l white me-3'
                            onClick={fn_closeModal}>닫기
                        </button>
                        <button onClick={fn_registData}
                                className='bmc-btn pill p'>반려

                        </button>

                    </div>
                </Modal.Footer>

            </Modal>
        </div>
    )
}

export default RjctRsnModal;

