import React, {Dispatch, SetStateAction, useRef, useState} from "react";
import {Col, Modal, Row, Table} from "react-bootstrap";
import {IoMdClose} from "react-icons/io";
import SHSearch from "../../component/BMCSearch";
import BMCInput from "../../../components/common/BMCInput";
import Datatables from "../../component/Datatables";
import {toast} from "react-toastify";
import BMCSelect from "../../../components/common/BMCSelect";

interface IProps {
    setModalIsOpen: Dispatch<SetStateAction<boolean>>;
    modalIsOpen: boolean;
    setBlngSite: Dispatch<SetStateAction<object>>;
}

export default function IvstSiteSearchModal(props:IProps){

    const [selectedSite, setSelectedSite] = useState<any>(null);
    const [datatable, setDatatable] = useState<any>();
    const searchFormRef = useRef<any>();
    const [siteSeqList, setSiteSeqList] = useState<number[]>([]);
    const [data, setData] = useState<any>();



    const fn_openModal = () => {
        setSelectedSite(null);
    }

    function fn_closeModal() {
        setSiteSeqList([]);
        props.setModalIsOpen(false);
    }

    const fn_select = () => {

        if (!selectedSite) {
            toast.warn('현장을 선택해 주십시오.');
            return;
        }
        props.setBlngSite(selectedSite);
        fn_closeModal();
    }

    const fn_setFormData = (e: any) => {
        setData({
            ...data,
            [e.target.name]: e.target.value
        })
    }

    return(
        <div>
            <Modal
                className="bmc-modal sub" size="xl"
                show={props.modalIsOpen}
                onShow={fn_openModal}
                onHide={fn_closeModal}
            >

                <Modal.Header>
                    <div className="d-flex justify-content-between align-items-center w-100 bmc-font-SD">
                        현장 검색
                        <IoMdClose className='hover:cursor-pointer' onClick={fn_closeModal}/>
                    </div>
                </Modal.Header>

                <Modal.Body className="px-4">
                    <SHSearch datatable={datatable} formRef={searchFormRef} btnType={'p'}>
                        <Row>
                            <Col>
                                <BMCInput type={'hidden'} name={'unSftyMng'} value={false} />
                                <Table className="bmc-font-SP bmc-fs-b2 my-3">
                                    <tbody>
                                    <tr className="border-top align-middle">
                                        <th className="bmc-bg-th" style={{width: '10%'}}>지역</th>
                                        <td style={{width: '90%'}}>
                                            <div className="d-inline-flex gap-2">
                                                <div className="w-[150px]">
                                                    <BMCSelect initOptionGb={'all'} className={'form-select'} name={'guCd'} codeGb={'bjd'} codeGrpId={'BUSAN'}
                                                               value={data?.guCd} onChange={fn_setFormData}></BMCSelect>
                                                </div>
                                                <div className="w-[300px]">

                                                    <BMCInput name={'srchTxt'} className={'form-control'} onChange={fn_setFormData} value={data?.srchTxt}
                                                        placeholder="상세주소를 입력해주세요."/>
                                                </div>
                                            </div>
                                        </td>
                                    </tr>
                                    </tbody>
                                </Table>
                            </Col>
                        </Row>
                    </SHSearch>


                    <div className={'ge-result'}>
                        <Datatables
                            dataUrl={'/admin/api/site/retrieveIvstDataList'}
                            parameterFormRef={searchFormRef}
                            checkedDataList={siteSeqList}
                            pk={'siteId'}
                            columns={[
                                {data: "guNm", title: "구", textAlign: 'center',render: (data: any, type: any, row: any) => {
                                        if(!data){
                                            return data;
                                        }else{
                                            let n = data.split(" ");
                                            return n[n.length - 1];
                                        }
                                    }
                                },
                                {data: "dongNm", title:"동", textAlign: 'center',render: (data: any, type: any, row: any) => {
                                        if(!data){
                                            return data;
                                        }else{
                                            let n = data.split(" ");
                                            return n[n.length - 1];
                                        }
                                    }
                                },
                                {data: "jibun", title: "지번", textAlign: 'center'},
                                {data: "landTypeNm", title: "지목", textAlign: 'center'},
                                {data: "landStatusNm", title: "현황", textAlign: 'center'},
                                {data: "areaRange", title: "공부면적(m<sup>2</sup>)", textAlign: 'center'}
                            ]}
                            checked={true}
                            isSingleCheck={true}
                            onLoad={(dt: any) => {
                                setDatatable(dt)
                            }}
                            isNotCheckedAll={true}
                            onClick={(data) => {
                                if(data.isChecked) {
                                    setSelectedSite(data)
                                }
                            }}
                        />
                    </div>
                </Modal.Body>
                <Modal.Footer className="py-2">
                    <div className='flex justify-center w-100'>

                        <button
                            className='bmc-btn cancel p white me-3'
                            onClick={fn_closeModal}>닫기
                        </button>

                        <button onClick={fn_select}
                                className='bmc-btn confirm p'>선택

                        </button>
                    </div>
                </Modal.Footer>


            </Modal>
        </div>
    )
}
