import React, {Dispatch, SetStateAction, useContext, useEffect, useState} from "react";
import {ProgressContext} from "../../../context/Progress";
import {getLoginUser} from "../../../helper/SecurityHelper";
import {Modal, Table} from "react-bootstrap";
import {IoMdClose} from "react-icons/io";
import BMCInput from "../../../components/common/BMCInput";
import BMCTextarea from "../../../components/common/BMCTextarea";
import BMCSelect from "../../../components/common/BMCSelect";
import {validFormData} from "../../../helper/FormHelper";
import axios from "axios";
import {toast} from "react-toastify";
import messageCodeDic from "../../../util/messageCodeDic";
import errorCodeDic from "../../../util/errorCodeDic";

interface IProps {
    setModalIsOpen: Dispatch<SetStateAction<boolean>>;
    modalIsOpen: boolean;
    detailData: any;
    setIsSaveSuccess: Dispatch<SetStateAction<boolean>>;
}

export default function IvstSiteListModal(props:IProps){
    const [data, setData] = useState<any>();
    const formRef = React.createRef<HTMLFormElement>();
    const {spinner} = useContext(ProgressContext);
    const user: any = getLoginUser();
    const [guCd, setGuCd] = useState<any>();

    const fn_updateData = (e: any) => {
        if (e.target.type == 'checkbox') {
            setData({
                ...data,
                [e.target.name]: e.target.checked
            })
            console.log(e.target.checked)
        } else {
            setData({
                ...data,
                [e.target.name]: e.target.value
            })
        }
    }


    const fn_registData = () => {

        if (!data) {
            toast.warn('데이터를 입력해 주십시오.')
            return;
        }

        if(!data.siteAddress){
            toast.warn('주소를 입력해 주십시오.');
            return;
        }

        if (!data.guCd) {
            toast.warn('구를 선택해 주십시오.')
            return;
        }

        if(!data.dongCd) {
            toast.warn('동을 선택해 주십시오.');
            return;
        }

        if (!data.jibun) {
            toast.warn('지번을 입력해 주십시오.');
            return;
        }

        if (!data.areaRange) {
            toast.warn('공부면적을 입력해 주십시오.');
            return;
        }

        if (!data.landType) {
            toast.warn('지목을 선택해 주십시오.');
            return;
        }

        if (!data.landStatus) {
            toast.warn('현황을 선택해 주십시오.');
            return;
        }
        console.log('param: ', data);

        if (!window.confirm('저장 하시겠습니까?')) {
            return;
        }

        let param = data;
        param.regSeq = user.userSeq;
        param.updateSeq = user.userSeq;
        param.siteName = data.siteAddress;

        // @ts-ignore
        var formData: any = new FormData(formRef.current);

        formData.set('ivstData', new Blob([JSON.stringify(param)], {type: "application/json"}))
        spinner.start();
        axios.post('/admin/api/site/saveIvstData', formData).then((result) => {
            if (result.data > 0) {
                toast.success(messageCodeDic['001'])
                props.setModalIsOpen(false);
                props.setIsSaveSuccess(true);
            } else {
                toast.error(errorCodeDic['003']);
                props.setIsSaveSuccess(false);
            }
        }).catch(error => {
            toast.error(errorCodeDic['003']);
            console.log('error: ', error);
            props.setIsSaveSuccess(false);
        })
        .finally(() => {
            spinner.stop();
        });
    }

    const fn_openModal = () => {
        if(!props.detailData){
            setData(null);
        }else{
            setData(props.detailData);

            if(props.detailData?.guCd){
                setGuCd(props.detailData?.guCd);
            }
        }
    }

    const fn_closeModal = () => {
        props.setModalIsOpen(false);
    }

    const submit = (e: any) => {
        e.preventDefault()

    }

    const fn_openAddrPop = () => {
        // @ts-ignore
        new daum.Postcode({
            oncomplete: function (rtrnData: any) {
                // @ts-ignore
                var geocoder = new kakao.maps.services.Geocoder();
                geocoder.addressSearch(rtrnData.address, function (result: any, status: any) {
                    // @ts-ignore
                    if (status === kakao.maps.services.Status.OK) {
                        var lon = result[0].x
                        var lat = result[0].y
                        var address = result[0].address_name
                        var bjdCode = result[0].address.b_code
                        var jibun = result[0].address.main_address_no + '-' + result[0].address.sub_address_no;
                        console.log('result[0]: ', result[0]);
                        var guCd = bjdCode.substring(0,4) + '000000';
                        setGuCd(guCd);
                        setData({
                            ...data,
                            siteAddress: address,
                            bjdCode: bjdCode,
                            guCd: guCd,
                            dongCd: bjdCode,
                            longitude: lon,
                            latitude: lat,
                            jibun: jibun,
                        })

                    }
                });
            }
        }).open();
    }

    return(
        <div>
            <Modal
                className="bmc-modal" size="xl"
                show={props.modalIsOpen}
                onShow={fn_openModal}
                onHide={fn_closeModal}
            >

                <Modal.Header>
                    <div className="d-flex justify-content-between align-items-center w-100 bmc-font-SD">
                        출자토지 등록/수정
                        <IoMdClose className='hover:cursor-pointer' onClick={fn_closeModal}/>
                    </div>
                </Modal.Header>
                <Modal.Body className="px-4">
                    <form name={'detailForm'} ref={formRef} encType="multipart/form-data" method={"post"} onSubmit={submit}>
                        <BMCInput type={'hidden'} value={data?.atchFileId} name={'atchFileId'}></BMCInput>
                        <BMCInput type={'hidden'} value={data?.sftyMngBrdId} name={'sftyMngBrdId'}></BMCInput>
                        <div className="bmc-modal-div">
                            <div className="div-header">
                                출자토지 기본정보
                            </div>
                            <div className="div-body px-3">
                                <Table className="my-2 align-middle">
                                    <tbody>

                                    <tr className="border-top align-middle">
                                        <th className="bmc-bg-th">
                                            토지관리번호
                                        </th>
                                        <td>
                                            <BMCInput type="text" className="form-control" name={'lndMngNo'} onChange={fn_updateData} value={data?.lndMngNo}/>
                                        </td>
                                        <th className="bmc-bg-th">
                                            주소
                                        </th>
                                        <td>
                                            <div
                                                className="d-flex align-items-center justify-content-between w-100 p-0 bmc-fs-b3">
                                                <BMCInput type="text" name={'siteAddress'} value={data?.siteAddress}
                                                          className="form-control" readOnly/>
                                                <button className="bmc-btn btn-secondary min-w-[70px] ml-5 mr-10"
                                                        type="button" onClick={fn_openAddrPop}>검색
                                                </button>

                                            </div>
                                        </td>
                                    </tr>
                                    <tr className="border-top align-middle">
                                        <th className="bmc-bg-th" style={{width: '15%'}}>구</th>
                                        <td style={{width: '35%'}}>
                                            <BMCSelect initOptionGb={'all'} className={'form-select'} name={'guCd'} codeGb={'bjd'} codeGrpId={'BUSAN'} value={data?.guCd} onChange={(e)=>{ setGuCd(e.target.value); fn_updateData(e); }}></BMCSelect>
                                        </td>
                                        <th className="bmc-bg-th" style={{width: '15%'}}>동</th>
                                        <td style={{width: '35%'}}>
                                            <BMCSelect initOptionGb={'all'} className={'form-select'} name={'dongCd'} codeGb={'bjd-dong'} codeGrpId={'BUSAN'} selParam={{guCd:guCd}} value={data?.dongCd} onChange={fn_updateData}></BMCSelect>
                                        </td>
                                    </tr>
                                    <tr className="border-top align-middle">
                                        <th className="bmc-bg-th" style={{width: '15%'}}>지번</th>
                                        <td style={{width: '35%'}}>
                                            <BMCInput type="text" className="form-control" name={'jibun'} onChange={fn_updateData} value={data?.jibun}/>
                                        </td>
                                        <th className="bmc-bg-th" style={{width: '15%'}}>공부면적(m<sup>2</sup>)</th>
                                        <td style={{width: '35%'}}>
                                            <BMCInput type="number" className="form-control" name={'areaRange'} onChange={fn_updateData} value={data?.areaRange}/>
                                        </td>
                                    </tr>
                                    <tr className="border-top align-middle">
                                        <th className="bmc-bg-th" style={{width: '15%'}}>공부지목</th>
                                        <td style={{width: '35%'}}>
                                            <BMCSelect initOptionGb={'all'} className={'bmc-select'} codeGb={'cmmn'} codeGrpId={'S016'} name={'landType'} value={data?.landType} onChange={fn_updateData}></BMCSelect>
                                        </td>
                                        <th className="bmc-bg-th" style={{width: '15%'}}>현실지목</th>
                                        <td style={{width: '35%'}}>
                                            <BMCSelect initOptionGb={'all'} className={'bmc-select'} codeGb={'cmmn'} codeGrpId={'S020'} name={'landStatus'} value={data?.landStatus} onChange={fn_updateData}></BMCSelect>
                                        </td>
                                    </tr>
                                    </tbody>
                                </Table>
                            </div>
                        </div>
                    </form>
                </Modal.Body>

                <Modal.Footer className="py-2">
                    <div className='flex justify-center w-100'>
                        <button
                            className='bmc-btn pill l white me-3'
                            onClick={fn_closeModal}>닫기
                        </button>
                        <button onClick={fn_registData}
                                className='bmc-btn pill p'>등록/수정
                        </button>
                    </div>
                </Modal.Footer>

            </Modal>
        </div>
    )
}
