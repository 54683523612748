export const DateHelper = {
    dateByMonth: (addMonth: number, date?:Date) => {
        let today = new Date();
        if(date){
            today = date;
        }
        var newDate = new Date(today.setMonth(today.getMonth()+addMonth));
        return newDate;
    },
    parse(timeStr: string, format?: string) {
        if(timeStr){
            return new Date(timeStr);
        }else{
            return null
        }
    },
    format(date: Date | null, format?: string) {
        if(date){
            const year = date.getFullYear();
            const month = DateHelper.leftPad(date.getMonth() + 1);
            const day = DateHelper.leftPad(date.getDate());

            return [year, month, day].join("-");
        }else{
            return null
        }
    },
    leftPad(value: number) {
        if (value >= 10) {
            return value;
        }
        return `0${value}`;
    }
}
