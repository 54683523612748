import {Modal, Table} from "react-bootstrap";
import {IoMdClose} from "react-icons/io";
import iconCalendar from "../../../images/icon-calendar-solid.svg";
import CustomDatePickerRange from "../../component/ui/DatePickerRange";
import React, {Dispatch, SetStateAction, useEffect, useRef, useState} from "react";
import {fn_getCurrentDate} from "../../../helper/CmmnHelper";
import {getLoginUser} from "../../../helper/SecurityHelper";
import {Container} from "@mui/material";
import {COMMON_MODAL_STYLE, COMMON_MULTISEL_STRINGS} from "../../../helper/StyleHelper";
import Select from 'react-select'
import {MultiSelect} from "react-multi-select-component";
import axios from "axios";
import {toast} from "react-toastify";
import messageCodeDic from "../../../util/messageCodeDic";
import errorCodeDic from "../../../util/errorCodeDic";
import BMCInput from "../../../components/common/BMCInput";
import BMCTextarea from "../../../components/common/BMCTextarea";
import BMCSelect from "../../../components/common/BMCSelect";
import CustomDatePicker from "../../component/ui/DatePicker";
import BMCDatePicker from "../../../components/common/BMCDatePicker";
import BMCFile from "../../../components/common/BMCFile";
import restapi from "../../../helper/restAPIHelper";

interface IProps {
    setModalIsOpen: Dispatch<SetStateAction<boolean>>;
    modalIsOpen: boolean;
    detailData: any;
    setIsSaveSuccess: Dispatch<SetStateAction<boolean>>;
    siteId: number;
}

const MSDSRgstModal = (props: IProps) => {

    const prodRef = useRef<any>();
    const currentDate = fn_getCurrentDate();
    const user = getLoginUser();
    const [eduDt, setEduDt] = useState<string>('');
    const [isDatePopupOpen, setIsDatePopupOpen] = useState(false);
    const [data, setData] = useState<any>()
    const [selected, setSelected] = useState([])
    const [eduTimeList, setEduTimeList] = useState<any>([]);
    const [fileList, setFileList] = useState([]);
    const formRef = React.createRef<HTMLFormElement>();

    const fn_setFormData = (e: any) => {
        setData({
            ...data,
            [e.target.name]: e.target.value
        })
    }

    function afterOpenModal() {
        if (!props.detailData) {
            setFileList([]);
            setData(null);
            setData({siteId: props.siteId})
            setEduDt('')
            setSelected([])
            // 상세
        } else {
            setData(props.detailData);
            setEduDt(props.detailData.eduDt);
            if(props.detailData.atchFileId){
                restapi.retrieveAttachInfoList({attachSeq: props.detailData.atchFileId}).then((rsltData: any) => {
                    setFileList([].concat(rsltData));
                })
            }else{
                setFileList([]);
            }
        }
        fn_genEduTime();
    }

    function fn_closeModal() {
        props.setModalIsOpen(false);
    }

    const fn_datePopupOpen = () => {
        setIsDatePopupOpen(!isDatePopupOpen);
    };

    const submit = (e: any) => {
        e.preventDefault()
    }

    const fn_registData = () => {

        if (!data) {
            toast.warn('데이터를 입력해 주십시오.')
            return;
        }

        if (!data.cmpnySeq) {
            toast.warn('업체를 선택해 주십시오.')
            return;
        }

        if (!data.msdsGbCd) {
            toast.warn('구분을 선택해 주십시오.');
            return;
        }

        if (!data.productName) {
            toast.warn('제품명을 입력해 주십시오.');
            return;
        }

        if (!window.confirm('저장 하시겠습니까?')) {
            return;
        }

        let param = data;
        param.eduDt = eduDt;
        param.rgstrSeq = user.userSeq;
        param.updtrSeq = user.userSeq;

        // @ts-ignore
        var formData: any = new FormData(formRef.current)

        if(fileList.length > 0){
            for(var i =0;i<fileList.length;i++){
                formData.append('atchFileList',fileList[i]);
            }
        }

        formData.set('msdsVO', new Blob([JSON.stringify(param)], {type: "application/json"}))

        axios.post('/admin/api/msds/saveMsdsData', formData).then((result) => {
            if (result.data > 0) {
                toast.success(messageCodeDic['001'])
                props.setModalIsOpen(false);
                props.setIsSaveSuccess(true);
            } else {
                toast.error(errorCodeDic['003']);
                props.setIsSaveSuccess(false);
            }
        })
    }

    const fn_genEduTime = () => {
        let date = new Date(2023, 0, 1);
        let timeArr = ['00:00']
        let hr;
        let mi;

        for (var i = 0; i < 47; i++) {
            date.setMinutes(date.getMinutes() + 30);
            hr = date.getHours() + '';
            mi = date.getMinutes() + '';
            if (hr.length == 1) {
                hr = '0' + hr;
            }
            if (mi.length == 1) {
                mi = '0' + mi;
            }
            timeArr.push(hr + ':' + mi);
        }
        setEduTimeList(timeArr);
    }
    return (
        <div>
            <Modal
                className="bmc-modal" size="xl"
                show={props.modalIsOpen}
                onShow={afterOpenModal}
                onHide={fn_closeModal}
            >
                <Modal.Header>
                    <div className="d-flex justify-content-between align-items-center w-100 bmc-font-SD">
                        MSDS 등록/수정
                        <IoMdClose className='hover:cursor-pointer' onClick={fn_closeModal}/>
                    </div>
                </Modal.Header>

                <Modal.Body className="px-4">
                    <form name={'form'} ref={formRef} encType="multipart/form-data" method={"post"}
                          onSubmit={submit}>
                        <div className="bmc-modal-div">
                            <div className="div-header">
                                MSDS 정보
                            </div>
                            <div className="div-body px-3">
                                <Table className="my-2 align-middle">
                                    <tbody>
                                        <tr className="border-top align-middle">
                                            <th className="bmc-bg-th" style={{width: '15%'}}>현장</th>
                                            <td style={{width: '35%'}}>
                                                <BMCSelect initOptionGb={'none'} disabled={true} codeGb={'site'}
                                                          onChange={fn_setFormData} className={'form-control w-100'}
                                                          value={data?.siteId}></BMCSelect>
                                            </td>
                                            <th className="bmc-bg-th" style={{width: '15%'}}>업체</th>
                                            <td style={{width: '35%'}}>
                                                <BMCSelect initOptionGb={'sel'} className={"w-100"}
                                                          name={'cmpnySeq'} codeGb={'siteCmpny'}
                                                          onChange={fn_setFormData} selParam={{siteId: props.siteId}}
                                                          value={data?.cmpnySeq}></BMCSelect>
                                            </td>
                                        </tr>
                                        <tr>
                                            <th className="bmc-bg-th" style={{width: '15%'}}>구분</th>
                                            <td style={{width: '35%'}}>
                                                <BMCSelect initOptionGb={'sel'} className={'w-100'} name={'msdsGbCd'}
                                                          codeGb={'cmmn'} codeGrpId={'M002'}
                                                          onChange={fn_setFormData}
                                                          value={data?.msdsGbCd}></BMCSelect>
                                            </td>
                                            <th className="bmc-bg-th" style={{width: '15%'}}>제품명</th>
                                            <td style={{width: '35%'}}>
                                                <BMCInput type="text" className="form-control" name={'productName'}
                                                         onChange={fn_setFormData} value={data?.productName}/>
                                            </td>
                                        </tr>
                                        <tr className="border-top align-middle">
                                            <th className="bmc-bg-th" style={{width: '15%'}}>내용</th>
                                            <td style={{width: '85%'}} colSpan={3}>
                                                <BMCTextarea rows={5} onChange={fn_setFormData} name={'comments'}
                                                            value={data?.comments}
                                                            className={"form-control"}
                                                            placeholder='내용을 입력해주세요.'/>
                                            </td>
                                        </tr>
                                        <tr className="align-middle" style={{width: '15%'}}>
                                            <th className="bmc-bg-th">첨부파일</th>
                                            <td colSpan={3}>
                                                <BMCFile fileName={'files'} fileList={fileList} setFileList={setFileList}></BMCFile>
                                            </td>
                                        </tr>
                                    </tbody>
                                </Table>
                            </div>
                        </div>
                        <div className="bmc-modal-div">
                            <div className="div-header">
                                작성정보
                            </div>
                            <div className="div-body px-3">
                                <Table className="my-2 align-middle">
                                    <tbody>
                                    <tr className="border-top align-middle">
                                        <th className="bmc-bg-th" style={{width: '15%'}}>작성일</th>
                                        <td style={{width: '35%'}}>
                                            <input value={data?.rgstDt || currentDate}
                                                   className={"form-control"}
                                                   disabled={true}/>
                                        </td>
                                        <th className="bmc-bg-th" style={{width: '15%'}}>작성자</th>
                                        <td style={{width: '35%'}}>
                                            <input className={"form-control"}
                                                   value={data?.rgstrNm || user.userName} disabled={true}/>
                                        </td>
                                    </tr>
                                    </tbody>
                                </Table>
                            </div>
                        </div>
                    </form>
                </Modal.Body>

                <Modal.Footer className="py-2">
                    <div className='flex justify-center w-100'>
                        <button
                            className='bmc-btn pill l white me-3'
                            onClick={fn_closeModal}>취소
                        </button>
                        <button
                            className='bmc-btn pill p' onClick={fn_registData}>저장
                        </button>
                    </div>
                </Modal.Footer>

            </Modal>
        </div>
    )
}

export default MSDSRgstModal;

