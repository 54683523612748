import React, {createContext, useState} from 'react';

const IntervalContext = createContext({
  interval: null,
  setInterval: ({}: any) => {},

});

const IntervalProvider = ({children}: any) => {
  const [interval, setInterval] = useState(null);
  const value: any = {interval, setInterval};

  return <IntervalContext.Provider value={value}>{children}</IntervalContext.Provider>;
};

export {IntervalContext, IntervalProvider};


