import React, {useContext, useEffect, useRef, useState} from 'react';
import Datatables, {getCheckedItems} from "../component/Datatables";
import axios from "axios";
import {toast, ToastContainer} from "react-toastify";
import messageCodeDic from "../../util/messageCodeDic";
import errorCodeDic from "../../util/errorCodeDic";
import BMCSelect from "../../components/common/BMCSelect";
import BMCInput from "../../components/common/BMCInput";
import CustomDatePickerRange from "../component/ui/DatePickerRange";
import SiteSearchModal from "../companyManagement/modal/SiteSearchModal";
import WrkPrmtRgstModal from "./modal/WrkPrmtRgstModal";
import SHSearch from '../component/BMCSearch';
import SubMenuTitle from "../component/SubMenuTitle";
import {getLoginUser, IS_SM_SITE} from "../../helper/SecurityHelper";
import {Col, Container, Row, Table} from "react-bootstrap";
import BMCIconButton from "../../components/common/BMCIconButton";
import BMCDatePicker from "../../components/common/BMCDatePicker";
import WrkPrmtDetailModal from "./modal/WrkPrmtDetailModal";
import {CmmnFn} from "../../helper/CmmnHelper";
import {UserContext} from "../../context/User";
import WrkrRgstModal from "./modal/WrkrRgstModal";
import restapi from "../../helper/restAPIHelper";

type Props = {};

const WorkPermitManagementPage = (props: Props) => {
    const [modalIsOpen, setModalIsOpen] = React.useState(false);
    const [modalRgstIsOpen, setModalRgstIsOpen] = React.useState(false);
    const [wrkrModalIsOpen, setWrkrModalIsOpen] = React.useState(false);
    const [detailData, setDetailData] = useState<any>(null);
    const [isSaveSuccess, setIsSaveSuccess] = useState(false);
    const [isSitePopOpend, setIsSitePopOpend] = React.useState<boolean>(false);
    const [blngSiteList, setBlngSiteList] = useState<any[]>([]);
    const prodRef = useRef<any>();
    const [isDatePopupOpen, setIsDatePopupOpen] = useState(false);
    const [startDate, setStartDate] = useState<string>('');
    const [endDate, setEndDate] = useState<string>('');
    const [data, setData] = useState<any>({})
    const searchFormRef = useRef<any>();
    const [datatable, setDatatable] = useState<any>();
    const defaultUser = getLoginUser();
    const {user} = useContext<any>(UserContext);
    const [isCodeDataLoaded, setIsCodeDataLoaded] = useState<boolean>(false);
    const [isCopy, setIsCopy] = useState<boolean>(false);

    useEffect(() => {

        if (datatable) {
            datatable.draw();
        }

    }, [user, datatable])

    useEffect(() => {
        if (!modalRgstIsOpen && !wrkrModalIsOpen) {
            setIsCopy(false);
            datatable?.draw();
        }
    }, [modalRgstIsOpen, wrkrModalIsOpen])

    useEffect(() => {
        if(isCodeDataLoaded){
            datatable?.draw();
        }

    }, [isCodeDataLoaded])


    const fn_search = () => {
        datatable.draw();
    }

    /**
     * 등록 버튼 클릭
     */
    const fn_registPopOpen = () => {


        setDetailData(null)
        setModalRgstIsOpen(true);
    }

    const fn_delete = () => {

        var checkedData = getCheckedItems(datatable);
        let delList: number[] = [];

        if (checkedData.length == 0) {
            toast.warn(messageCodeDic['005']);
            return;
        }

        checkedData.map((data: any) => {
            delList.push((data.wrkPrmtId));
        })

        console.log('delList: ', delList);

        if (!window.confirm(messageCodeDic['004'])) {
            return;
        }

        axios.post('/admin/api/wrkPrmt/deleteWrkPrmtData', {delList: delList}).then(rsltData => {
                if (rsltData.data > 0) {
                    toast.success(messageCodeDic['002'])
                    fn_search();
                } else {
                    toast.error(errorCodeDic['003'])
                }

            }
        ).catch(() => {
                toast.error(errorCodeDic['003'])
            }
        )
    }


    const fn_datePopupOpen = () => {
        setIsDatePopupOpen(!isDatePopupOpen);
    };

    const fn_setFormData = (e: any) => {

        setData({
            ...data,
            [e.target.name]: e.target.value
        })

    }

    const fn_onChangeToAllVal = (e: any) => {
        const checked = e.target.checked;

        if (e.target.checked) {
        }

    }

    const fn_print = (e: any) =>{
        let param: any ={
            wrkPrmtId:e.wrkPrmtId,
            siteName: e.siteName, //현장명
            wrkTypeNm:e.wrkTypeNm, //고종
            companyName:e.companyName, //업체
            wrkPlcNm:e.wrkPlcNm, //작업위치
            chrgrNms:e.chrgrNms, //작업책임자
            wrkDt:e.wrkDt,
            wrkStrtHr:e.wrkStrtHr,
            wrkEndHr:e.wrkEndHr,
            wrkStrtMi:e.wrkStrtMi,
            wrkEndMi:e.wrkEndMi,
            wrkTimeGbNm:e.wrkTimeGbNm, //주간/야간
            wrkrCnt:e.wrkrCnt, //작업인원
        }

        restapi.retrieveWrkPrmtApprInfo({wrkPrmtId: e.wrkPrmtId}).then((rsltData: any) => {

            const apprUserDataList: any = rsltData.apprUserDataList;
            console.log('apprUserDataList: ', apprUserDataList);
            let apprData;
            let siteChrgNm;
            let userName;
            let apprStatNm;
            let prcsDt;
            if(apprUserDataList.length > 0){
                let maxLength = apprUserDataList.length;
                if(maxLength > 6){
                    maxLength = 6;
                }

                for(var i= 0; i < maxLength; i++){
                    apprData = apprUserDataList[i];
                    siteChrgNm = apprData.siteChrgNm;
                    userName = apprData.userName;
                    apprStatNm = apprData.apprStatNm;
                    prcsDt = apprData.prcsDt;
                    param['siteChrgNm' + (i+1)] = siteChrgNm;
                    param['userName' + (i+1)] = userName;
                    param['apprStatNm' + (i+1)] = apprStatNm;
                    param['prcsDt' + (i+1)] = prcsDt;
                }

            }

            axios.post('/admin/api/wrkPrmt/createWrkPrmtPrint', param).then(rsltData => {
                    window.open(process.env.REACT_APP_FILE_URL + "/bmcss-data/fileDownloadByFileName?fileName="+rsltData.data, "_blank")
                }
            ).catch(() => {
                    toast.error(errorCodeDic['003'])
                }
            )

        })

    }

    const fn_copy = () => {
        var checkedData = getCheckedItems(datatable);

        if (checkedData.length == 0) {
            toast.warn(messageCodeDic['007']);
            return;
        }

        if (checkedData.length > 1) {
            let msg = '';
            if(CmmnFn.isSmSite()) {
                msg = '작업지시서';
            }else{
                msg = '작업허가서'
            }
            toast.warn('하나의 '+ msg +'만 선택해 주십시오.');
            return;
        }
        console.log('checkedData: ', checkedData);

        checkedData[0].prcsStatCd = null;
        checkedData[0].prcsStatNm = null
        checkedData[0].rgstDt = null;
        checkedData[0].rgstrNm = null;
        checkedData[0].rgstrSeq = null;
        checkedData[0].wrkDt = CmmnFn.fn_getCurrentDate();
        checkedData[0].wrkStatNm = null;
        setIsCopy(true);
        setDetailData(checkedData[0]);
        setModalRgstIsOpen(true);
    }

    return (
        <Container fluid className="bg-white px-0 pb-4 bmc-text-font_primary">
            <SubMenuTitle title={CmmnFn.isSmSite()? '작업지시서 관리' : '작업허가서 관리'} parentMenu={'현장안전관리'}/>
            <SiteSearchModal setModalIsOpen={setIsSitePopOpend} modalIsOpen={isSitePopOpend}
                             blngSiteList={blngSiteList}
                             setBlngSiteList={setBlngSiteList} isSingleCheck={true} siteNm={data?.siteName}/>
            <WrkPrmtRgstModal setModalIsOpen={setModalRgstIsOpen} modalIsOpen={modalRgstIsOpen}
                              siteId={user?.blngSiteId || defaultUser.blngSiteId}
                              detailData={detailData} isCopy={isCopy}/>
            <WrkPrmtDetailModal setModalIsOpen={setModalIsOpen} modalIsOpen={modalIsOpen}
                                detailData={detailData} setIsSaveSuccess={setIsSaveSuccess}/>
            <WrkrRgstModal setModalIsOpen={setWrkrModalIsOpen} modalIsOpen={wrkrModalIsOpen}
                           siteId={user?.blngSiteId || defaultUser.blngSiteId}
                           detailData={detailData}></WrkrRgstModal>
            <div className="p-4 overflow-y-auto" style={{height: 'calc(100vh - 180px)'}}>
                <SHSearch datatable={datatable} formRef={searchFormRef} btnType={'p'}>
                    <Row>
                        <Col>
                            <Table className="bmc-font-SP bmc-fs-b2 my-3">
                                <tbody>
                                <tr className="border-top align-middle border-bottom">
                                    <th className="bmc-bg-th">업체명</th>
                                    <td>
                                        <BMCInput type={'hidden'} name={'isProdDataHide'} value={true} />
                                        <BMCInput type={'hidden'} name={'siteId'}
                                                  value={user?.blngSiteId || defaultUser.blngSiteId}></BMCInput>
                                        <BMCSelect name={'cmpnySeq'} onChange={fn_setFormData} value={data.cmpnySeq} setIsCodeDataLoaded={setIsCodeDataLoaded} initOptionGb={'all'} codeGb={'siteCmpny'} selParam={{siteId:user?.blngSiteId || defaultUser.blngSiteId}}></BMCSelect>

                                    </td>
                                    <th className="bmc-bg-th">공종</th>
                                    <td>
                                        <BMCSelect className={'form-select'} initOptionGb={'all'} value={data?.sectorCd}
                                                   onChange={fn_setFormData} name={'sectorCd'}
                                                   codeGrpId={'C002'}></BMCSelect>
                                    </td>
                                    <th className="bmc-bg-th">상태</th>
                                    <td colSpan={2}>
                                        <BMCSelect className={'form-select'} initOptionGb={'all'} name={'prcsGbCd'}
                                                   onChange={fn_setFormData} codeGrpId={'S001'}></BMCSelect>
                                    </td>
                                </tr>
                                <tr className="align-middle border-bottom">
                                    <th className="bmc-bg-th">기간</th>
                                    <td colSpan={2}>
                                        <div className="d-flex align-items-center w-100" ref={prodRef}>
                                            <BMCDatePicker
                                                className={"text-center"} readOnly={true}
                                                value={startDate} name={'strtDt'} onClick={fn_datePopupOpen}
                                                onChange={fn_setFormData}
                                                calendar={fn_datePopupOpen}
                                            />
                                            <span>&nbsp;~&nbsp;</span>
                                            <BMCDatePicker readOnly={true}
                                                           className={"text-center"}
                                                           value={endDate} name={'endDt'} onClick={fn_datePopupOpen}
                                                           onChange={fn_setFormData}
                                                           calendar={fn_datePopupOpen}
                                            />

                                            {isDatePopupOpen
                                                ? <CustomDatePickerRange setIsDatePopupOpen={setIsDatePopupOpen}
                                                                         prodRef={prodRef}
                                                                         setStartDateView={setStartDate}
                                                                         setEndDateView={setEndDate}/>
                                                : null}
                                        </div>
                                    </td>
                                    <td colSpan={6}>
                                        <div className="d-flex align-items-center">
                                            <div className={'form-check w-25'}>
                                                <input className="form-check-input bmc-check" id={'reception_toAll'}
                                                       onChange={fn_onChangeToAllVal} type="checkbox"/>
                                                <label className={'form-check-label text-black'}
                                                       htmlFor={'reception_toAll'}>전체기간</label>
                                            </div>
                                            <BMCInput className={'form-control w-100'} name={'srchTxt'}
                                                      onChange={fn_setFormData}/>
                                        </div>
                                    </td>
                                </tr>
                                </tbody>
                            </Table>
                        </Col>
                    </Row>
                </SHSearch>


                <div className='w-100 d-flex justify-content-end align-items-center mb-2'>


                    <BMCIconButton kind={'submit'} marginRight={'5px'} onClick={fn_registPopOpen}> 등록
                    </BMCIconButton>

                    <BMCIconButton kind={'delete'} marginRight={'5px'} onClick={fn_delete}> 삭제
                    </BMCIconButton>
                    <BMCIconButton kind={'copy'} marginRight={'0'} onClick={fn_copy}> 복사
                    </BMCIconButton>
                </div>
                <div>

                    {
                        <Datatables
                            dataUrl={'/admin/api/wrkPrmt/retrieveWrkPrmtDataList'}
                            parameterFormRef={searchFormRef}
                            columns={
                                CmmnFn.isSmSite() ?
                                    [
                                        {
                                            data: "prcsStatNm", title: "상태", textAlign: 'center', render: (data: any) => {
                                                switch (data) {
                                                    case '작성중':
                                                        return "<div class='bmc-box-status writing'>작성중</div>";
                                                    case '결재요청':
                                                        return "<div class='bmc-box-status request'>결재요청</div>";
                                                    case '결재중':
                                                        return "<div class='bmc-box-status approval'>결재중</div>";
                                                    case '결재완료':
                                                        return "<div class='bmc-box-status approvaldone'>결재완료</div>";
                                                    case '반려':
                                                        return "<div class='bmc-box-status refuse'>반려</div>";
                                                    default:
                                                        return "";
                                                }
                                            }
                                        },
                                        {data: "wrkSiteNm", title: "현장명", textAlign: 'center'},
                                        {data: "companyName", title: "업체", textAlign: 'center'},
                                        {data: "wrkCntntsAbbr", title: '작업사항'},
                                        {data: "wrkTypeNm", title: "공종", textAlign: 'center'},
                                        {data: "wrkrCnt", title: "작업인원", textAlign: 'center'},
                                        {data: "wrkDt", title: "작업일", textAlign: 'center'},
                                        {data: "wrkTimeGbNm", title: "작업시간", textAlign: 'center'},
                                        {
                                            title: "기능",
                                            type: "button",
                                            className: "text-center",
                                            buttons: [{
                                                label: "<img style='height: 20px' class='cursor-pointer' src='/icon_print.svg' />",
                                                onClick: (v: any) => {
                                                    fn_print(v);
                                                },
                                            }, {
                                                label: "<img style='height: 20px' class='cursor-pointer' src='/icon_user_plus.svg' />",
                                                onClick: (v: any) => {
                                                    setDetailData(v);
                                                    setWrkrModalIsOpen(true);
                                                    console.log(2, v)
                                                },
                                            }],
                                        },

                                    ]
                                    : [
                                        {
                                            data: "prcsStatNm", title: "상태", textAlign: 'center', render: (data: any) => {
                                                switch (data) {
                                                    case '작성중':
                                                        return "<div class='bmc-box-status writing'>작성중</div>";
                                                    case '결재요청':
                                                        return "<div class='bmc-box-status request'>결재요청</div>";
                                                    case '결재중':
                                                        return "<div class='bmc-box-status approval'>결재중</div>";
                                                    case '결재완료':
                                                        return "<div class='bmc-box-status approvaldone'>결재완료</div>";
                                                    case '반려':
                                                        return "<div class='bmc-box-status refuse'>반려</div>";
                                                    default:
                                                        return "";
                                                }
                                            }
                                        },
                                        {data: "companyName", title: "업체", textAlign: 'center'},
                                        {data: "wrkTypeNm", title: "공종", textAlign: 'center'},
                                        {data: "wrkrCnt", title: "작업인원", textAlign: 'center'},
                                        {data: "wrkDt", title: "작업일", textAlign: 'center'},
                                        {data: "wrkTimeGbNm", title: "작업시간", textAlign: 'center'},
                                        {
                                            title: "기능",
                                            type: "button",
                                            className: "text-center",
                                            buttons: [{
                                                label: "<img style='height: 20px' class='cursor-pointer' src='/icon_print.svg' />",
                                                onClick: (v: any) => {
                                                    fn_print(v);
                                                },
                                            }, {
                                                label: "<img style='height: 20px' class='cursor-pointer' src='/icon_user_plus.svg' />",
                                                onClick: (v: any) => {
                                                    setDetailData(v);
                                                    setWrkrModalIsOpen(true);
                                                    console.log(2, v)
                                                },
                                            }],
                                        },

                                    ]
                            }
                            checked={true}
                            index={true}

                            onLoad={(dt: any) => {
                                setDatatable(dt)
                            }}
                            onClick={(data) => {
                                setDetailData(data)
                                setModalRgstIsOpen(true);
                                //setModalIsOpen(true);
                            }
                            }
                        />}
                </div>
            </div>
        </Container>
    );
};

export default WorkPermitManagementPage;
