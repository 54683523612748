import React, {useContext, useEffect, useRef, useState} from 'react';
import Modal from 'react-modal';
import {Col, Container, Row, Table} from 'react-bootstrap';
import SubMenuTitle from "../component/SubMenuTitle";
import {useNavigate} from "react-router-dom";
import restapi from "../../helper/restAPIHelper";
import {getLoginUser, setItemWithExpireTime, USER_SESSION} from "../../helper/SecurityHelper";
import {toast} from "react-toastify";
import errorCodeDic from "../../util/errorCodeDic";
import {ProgressContext} from "../../context/Progress";
import {UserContext} from "../../context/User";


type IProps = {
};

Modal.setAppElement('#root');
const MntncSiteMngPage = (props: IProps) => {
    const navigate = useNavigate();
    const {spinner} = useContext(ProgressContext);
    const defaultUser: any = getLoginUser();
    const {user,setUser} = useContext<any>(UserContext);

    useEffect(() => {
        spinner.start();
        restapi.retrieveSmSiteInfoData().then((rsltData: any) => {
            if(!rsltData){
                return;
            }

            restapi.changeUserSiteHeadWebStat({blngSiteId: rsltData.siteId, userSeq: defaultUser.userSeq}).then((rsltCnt: any) => {
                if (rsltCnt > 0) {
                    restapi.getUserSession(defaultUser.userSeq).then((data: any) => {
                        setUser(data);
                        setItemWithExpireTime(USER_SESSION, data);
                        navigate('/smSite')
                    });
                }else{
                    toast.error(errorCodeDic['003']);
                }
            }).catch(error => {
                toast.error(errorCodeDic['003']);
                console.log('error: ', error);
            })
                .finally(() => {
                    spinner.stop();
                });

            navigate('/smSite');
        }).catch(error => {
            toast.error(errorCodeDic['003']);
            console.log('error: ', error);
        })
            .finally(() => {
                spinner.stop();
            });


    }, []);

    return (
        <Container fluid className="bg-white px-0 bmc-txt-color-primary">
            <SubMenuTitle title={'유지보수현장 관리'} parentMenu={'현장 안전 관리'}/>

        </Container>
    );
};

export default MntncSiteMngPage;
