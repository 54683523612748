import React, {Dispatch, SetStateAction, useEffect, useRef, useState} from "react";
import {Col, Modal, Row, Table} from "react-bootstrap";
import {IoMdClose} from "react-icons/io";
import Datatables, {getCheckedItems} from "../../component/Datatables";
import {toast} from "react-toastify";
import BMCSearch from "../../component/BMCSearch";
import RskAsmntRgstModal from "./RskAsmntRgstModal";
import BMCDatePicker2 from "../../../components/common/BMCDatePicker2";
import {DateHelper} from "../../../helper/DateHelper";
import { CmmnFn } from "../../../helper/CmmnHelper";

interface IProps {
    setModalIsOpen: Dispatch<SetStateAction<boolean>>;
    modalIsOpen: boolean;
    detailData?: any;
    riskEvalList: object[];
    setRiskEvalList: Dispatch<SetStateAction<object[]>>;
    siteId:number
    cmpnySeq:number
    wrkDt?:string | null
    wrkStrtDt?:string | null
    wrkEndDt?:string | null

}

const LoadRiskEvalModal = (props: IProps) => {
    const [datatable, setDatatable] = useState<any>();
    const [dataList, setDataList] = useState<any[]>([]);
    const searchFormRef = useRef<any>();
    const prodRef = useRef<any>();
    /* TODO: 날짜조건이 맞는지? 앞으로 3달?*/
    const [startDate, setStartDate] = useState<Date | null>(DateHelper.dateByMonth(0))
    const [endDate, setEndDate] = useState<Date | null>(DateHelper.dateByMonth(3))
    const [isDatePopupOpen, setIsDatePopupOpen] = useState(false);
    const [data, setData] = useState<any>()
    const [modalIsOpen, setModalIsOpen] = React.useState(false);
    const [detailData, setDetailData] = useState<any>(null);
    const [isSaveSuccess, setIsSaveSuccess] = useState(false);

    useEffect(() => {
        if(dataList.length > 0 && props.riskEvalList.length > 0) {

            let settingList = dataList;

            settingList.map((data) => {
                props.riskEvalList.map((blngData:any) => {
                    if(data.siteId == blngData.siteId){
                        data._checked = true;
                    }
                })
            })
            setDataList(settingList);
        }
    }, [dataList])


    function fn_closeModal() {
        props.setModalIsOpen(false);
    }

    const fn_search = (e?: any) => {
        e?.preventDefault()
        datatable?.draw()
    }
    const fn_select = () => {
        var checkedData = getCheckedItems(datatable)
        if (checkedData.length == 0) {
            toast.warn('위험성 평가서를 선택해 주십시오.');
            return;
        }

        var isValid = true;

        checkedData.map((data: any)=> {
            console.log('data: ', data);
            console.log('props: ', props);


            if(props.wrkStrtDt && props.wrkEndDt){
                // @ts-ignore
                const wrkStrtDt = CmmnFn.dateToString(props.wrkStrtDt);
                // @ts-ignore
                const wrkEndDt = CmmnFn.dateToString(props.wrkEndDt);

                if(!
                    ((data.raStartDate <= wrkStrtDt && wrkStrtDt <= data.raEndDate)
                        || (data.raStartDate <= wrkEndDt && wrkEndDt <= data.raEndDate)

                    )
                ){
                    toast.warn('작업기간이 평가기간에 포함되지 않은 위험성 평가서는 불러올 수 없습니다.');
                    isValid = false;
                }
            }

            if(props.wrkDt){
                if(!(data.raStartDate <= props.wrkDt && props.wrkDt <= data.raEndDate)){
                    toast.warn('작업일이 평가기간에 포함되지 않은 위험성 평가서는 불러올 수 없습니다.');
                    isValid = false;
                }
            }


        })

        if(!isValid){
            return;
        }

        props.setRiskEvalList(checkedData);
        props.setModalIsOpen(false);
    }

    const fn_datePopupOpen = () => {
        setIsDatePopupOpen(!isDatePopupOpen);
    };

    const fn_setFormData = (e: any) => {

        setData({
            ...data,
            [e.target.name]: e.target.value
        })

    }

    const fn_onShow = () => {
        fn_search()
    }
    return (
        <div>
            <RskAsmntRgstModal setModalIsOpen={setModalIsOpen} modalIsOpen={modalIsOpen} siteId={props.siteId}
                               detailData={detailData} isReadOnly={true}/>
            <Modal
                className="bmc-modal sub" size="lg"
                show={props.modalIsOpen}
                onShow={()=>{fn_onShow()}}
                onHide={fn_closeModal}
            >

                <Modal.Header>
                    <div className="d-flex justify-content-between align-items-center w-100 bmc-font-SD">
                        위험성평가서 불러오기
                        <IoMdClose className='hover:cursor-pointer' onClick={fn_closeModal}/>
                    </div>
                </Modal.Header>

                <Modal.Body className="px-4">

                    <BMCSearch datatable={datatable} formRef={searchFormRef} btnType={'p'}>
                        <input type={'hidden'} name={'siteId'} value={props.siteId}/>
                        <input type={'hidden'} name={'cmpnySeq'} value={props.cmpnySeq}/>
                        <input type={'hidden'} name={'prcsStatCd'} value={"S001004"}/>
                        <Row>
                            <Col>
                                <Table className="bmc-font-SP bmc-fs-b2 my-3">
                                    <tbody>
                                    <tr className="border-top align-middle">
                                        <th className="bmc-bg-th" style={{width:'6%'}}>평가기간</th>
                                        <td style={{width:'25%'}}>
                                            <div className="d-flex align-items-center w-100" ref={prodRef}>
                                                <BMCDatePicker2 name={'raStartDate'} defaultValue={startDate} onChange={fn_setFormData}/>
                                                <span>&nbsp;~&nbsp;</span>
                                                <BMCDatePicker2 name={'raEndDate'} defaultValue={endDate} onChange={fn_setFormData}/>
                                            </div>
                                        </td>
                                    </tr>
                                    </tbody>
                                </Table>
                            </Col>
                        </Row>
                    </BMCSearch>

                    <div className={'ge-result'}>
                        <Datatables
                            dataUrl={'/admin/api/rskAsmnt/retrieveRskAsmntDataList'}
                            parameterFormRef={searchFormRef}
                            columns={[
                                {data: "rgstrNm", title: "작성자", textAlign: 'center'},
                                {data: "prcsStatNm", title: "상태", textAlign: 'center', render: (data: any) => {
                                        switch(data) {
                                            case '작성중':
                                                return "<div class='bmc-box-status writing'>작성중</div>";
                                            case '결재요청':
                                                return "<div class='bmc-box-status request'>결재요청</div>";
                                            case '결재중':
                                                return "<div class='bmc-box-status approval'>결재중</div>";
                                            case '결재완료':
                                                return "<div class='bmc-box-status approvaldone'>결재완료</div>";
                                            case '반려':
                                                return "<div class='bmc-box-status refuse'>반려</div>";
                                            default:
                                                return "";
                                        }
                                    }},
                                {data: "cmpnyNm", title: "업체", textAlign: 'center'},
                                {data: "raProd", title: "평가기간", textAlign: 'center'},
                                {data: "workTypeNm", title: "공종", textAlign: 'center'},
                                {data: "ocptNm", title: "직종", textAlign: 'center'},
                                {data: "evlCntnts", title: "평가내용", textAlign: 'center'},
                            ]}
                            checked={true}
                            index={true}

                            onLoad={(dt: any) => {
                                setDatatable(dt)
                            }}
                            onClick={(data) => {
                                setDetailData(data)
                                setModalIsOpen(true);
                            }
                            }
                        />
                    </div>
                </Modal.Body>
                <Modal.Footer className="py-2">
                    <div className='flex justify-center w-100'>

                        <button
                            className='bmc-btn pill l white me-3'
                            onClick={fn_closeModal}>닫기
                        </button>

                        <button onClick={fn_select}
                                className='bmc-btn pill p'>선택

                        </button>
                    </div>
                </Modal.Footer>


            </Modal>
        </div>
    )
}

export default LoadRiskEvalModal;
