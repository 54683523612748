import React, {Dispatch, SetStateAction, useEffect, useRef, useState} from "react";
import {IoMdClose} from "react-icons/io";
import BMCSelect from "../../../components/common/BMCSelect";
import {Col, Modal, Row, Table} from "react-bootstrap";
import BMCTreeView from "../../../components/common/BMCTreeView";
import restapi from "../../../helper/restAPIHelper";
import RskAsmntSlctModal from "./RskAsmntSlctModal";
import iconTrashBean from "../../../images/icon_trashBean.png";
import IconRemove from '../../../images/icon_remove.png';
import {toast} from "react-toastify";
import iconNodata from "../../../images/icon_docdata.png";
import iconArrowLeft from "../../../images/icon_arrow_left.svg";
import iconArrowRight from "../../../images/icon_arrow_right.svg";
import BMCInput from "../../../components/common/BMCInput";
import SHSearch from "../../component/BMCSearch";
import {CmmnFn} from "../../../helper/CmmnHelper";
import IconUpperArrow from "../../../images/icon_uppper_arrow.png";
import IconDownArrow from "../../../images/icon_down_arrow.png";

interface IProps {
    setModalIsOpen: Dispatch<SetStateAction<boolean>>;
    modalIsOpen: boolean;
    detailData?: any;
    apprLineDataList: object[];
    mngrUserDataList: object[];
    setApprLineDataList: Dispatch<SetStateAction<object[]>>;
    isSingleCheck?: boolean
    siteNm?: string

    siteId: number
    cmpnySeq: number

    rgstrSeq: number

}

const ApprLineModal = (props: IProps) => {
    const [apprLineDataList, setApprLineDataList] = useState<any>([]);
    const [mngrDataList, setMngrDataList] = useState<any>([]);
    const [orgnMngrDataList, setOrgnMngrDataList] = useState<any>([]);
    const srchChkAllRef = useRef<any>();
    const selChkAllRef = useRef<any>();
    const searchFormRef = useRef<any>();
    const [data, setData] = useState<any>({})

    const fn_afterOpenEvnt = () => {

            let cstmMngrUserDataList:object[] = [];

            // @ts-ignore
            cstmMngrUserDataList = [].concat(props.mngrUserDataList);
            if (props.apprLineDataList.length == 0) {
                cstmMngrUserDataList.map((data: any, idx: number) => {
                    if (data.userSeq == props.rgstrSeq) {
                        data.statNm = '작성자';
                        setApprLineDataList([data]);
                        cstmMngrUserDataList.splice(idx, 1);
                    }
                })
                cstmMngrUserDataList = cstmMngrUserDataList;
            }else{

                let userSeqArr:number[] = [];
                let cstmApprLineDataList:any = props.apprLineDataList;

                cstmApprLineDataList.map((data: any) => {
                    userSeqArr.push(data.userSeq);
                })
                cstmMngrUserDataList = cstmMngrUserDataList.filter((data: any) => {
                    return !userSeqArr.includes(data.userSeq)
                })

                if(cstmApprLineDataList.length > 0){
                    cstmApprLineDataList[0].statNm = '작성자';
                    if(cstmApprLineDataList.length > 1){
                        cstmApprLineDataList[cstmApprLineDataList.length-1].statNm = '최종';
                    }

                }

                // @ts-ignore
                setApprLineDataList([].concat(cstmApprLineDataList));
            }
            setMngrDataList(cstmMngrUserDataList);
            setOrgnMngrDataList(cstmMngrUserDataList)



    }


    function fn_closeModal() {
        props.setModalIsOpen(false);
    }

    const fn_rgstData = () => {

        if (apprLineDataList.length == 0) {
            toast.warn('결재선을 선택해 주십시오.')
            return;
        }

        props.setApprLineDataList(apprLineDataList);
        props.setModalIsOpen(false);
    }

    const fn_onSrchChkChanged = (e: any, idx: number) => {

        mngrDataList[idx][e.target.name] = e.target.checked
        setMngrDataList([].concat(mngrDataList));
        setOrgnMngrDataList([].concat(mngrDataList));

    }

    const fn_onSelChkChanged = (e: any, idx: number) => {

        if (apprLineDataList[idx]['userSeq'] != props.rgstrSeq) {
            apprLineDataList[idx][e.target.name] = e.target.checked
            setApprLineDataList([].concat(apprLineDataList));
        }

    }

    const fn_addMngr = () => {

        let targetUser: any = [];

        let chkCnt = 0;

        mngrDataList.map((data: any) => {
            if (data.srchChk) {
                targetUser.push(data.userSeq);
                data.srchChk = null;
                apprLineDataList.push(data);
                chkCnt++;
            }
        })
        srchChkAllRef.current.checked = false;
        if (chkCnt == 0) {
            toast.warn('담당자를 선택해 주십시오');
            return;
        }
        const filteredData = mngrDataList.filter((data: any) => {
            return !targetUser.includes(data.userSeq)
        })


        setMngrDataList([].concat(filteredData));
        setOrgnMngrDataList([].concat(filteredData))
        apprLineDataList.map((data: any) => {
            if(data.statNm == '최종'){
                data.statNm = null;
            }
        })

        apprLineDataList[apprLineDataList.length-1].statNm = '최종'
        setApprLineDataList([].concat(apprLineDataList));
    }

    const fn_removeMngr = () => {
        let targetUser: any = [];

        let chkCnt = 0;

        apprLineDataList.map((data: any) => {
            if (data.selChk) {
                targetUser.push(data.userSeq);
                data.selChk = null;
                mngrDataList.push(data);
                chkCnt++;
            }
        })
        selChkAllRef.current.checked = false;
        if (chkCnt == 0) {
            toast.warn('담당자를 선택해 주십시오');
            return;
        }

        const filteredData = apprLineDataList.filter((data: any) => {
            return !targetUser.includes(data.userSeq)
        })



        if(filteredData.length > 1){
            filteredData.map((data: any) => {
                if(data.statNm == '최종'){
                    data.statNm = null;
                }
            })

            filteredData[filteredData.length-1].statNm = '최종'
        }

        setApprLineDataList([].concat(filteredData));
        setMngrDataList([].concat(mngrDataList));
        setOrgnMngrDataList([].concat(mngrDataList));

    }

    const fn_chkAll = (e: any) => {
        const name = e.target.name;
        const checked = e.target.checked;

        if (name == 'srchChkAll') {
            mngrDataList.map((data: any) => {
                data.srchChk = checked;
            })
            setMngrDataList([].concat(mngrDataList))
            setOrgnMngrDataList([].concat(mngrDataList));
        } else if (name == 'selChkAll') {
            apprLineDataList.map((data: any) => {
                if (data.userSeq != props.rgstrSeq) {
                    data.selChk = checked;
                }
            })
            setApprLineDataList([].concat(apprLineDataList))
        }
    }

    const fn_retrieveDataList = () => {
        const cstmMngrDatList = orgnMngrDataList.filter((mngrData: any) => {
            let isUserName = true;
            let isCmpnySeq = true;
            let isChrgCode = true;
            console.log('mngrData: ', mngrData);
            console.log('mngrData.companySeq: ', mngrData.companySeq);
            console.log('data.cmpnySeq: ', data.cmpnySeq);


            if(data.userName){
                if(mngrData.userName.indexOf(data.userName) > -1){
                    isUserName = true;
                }else{
                    isUserName = false;
                }
            }
            if(data.cmpnySeq){
                isCmpnySeq = (mngrData.blngCmpnySeq == data.cmpnySeq);
            }
            if(data.chrgCode){
                isChrgCode = (mngrData.chrgCode == data.chrgCode)
            }

            return isUserName && isCmpnySeq && isChrgCode
        })
        console.log('search:  ')
        setMngrDataList([].concat(cstmMngrDatList));

    }

    const fn_setSrchData = (e: any) => {
        CmmnFn.setFormData(e, data, setData);

    }

    const fn_changeSelListOrder = (direction: string) => {

        let selectedIndex = apprLineDataList.findIndex((item: any) => item.selChk);

        let chkdSize = apprLineDataList.filter((data: any) => data.selChk).length;

        if(chkdSize > 1){
            toast.warn('하나의 대상만 선택해 주십시오.');
            return;
        }

        if (selectedIndex === -1) {
            toast.warn('대상을 선택해 주십시오.');
            return;
        } else if (selectedIndex === 0) {
            toast.warn('첫 번째 항목은 이동할 수 없습니다.');
            return;
        } else if (selectedIndex < 0 || selectedIndex >= apprLineDataList.length) {
            toast.warn('유효하지 않은 선택입니다.');
            return;
        }

        let newIndex = direction === 'UP' ? selectedIndex - 1 : selectedIndex + 1;

        if (newIndex === 0 || newIndex >= apprLineDataList.length) {
            toast.warn('더 이상 이동할 수 없습니다.');
            return;
        }

        // Reordering logic
        const newList = [...apprLineDataList];
        const [removedItem] = newList.splice(selectedIndex, 1);
        newList.splice(newIndex, 0, removedItem);

        newList.map((data: any, idx: number)=> {
            if(idx == newList.length - 1){
                data.statNm = '최종';
            }else if(idx != 0){
                data.statNm = null;
            }
        })

        setApprLineDataList(newList);
    }

    return (
        <div>

            <Modal
                className="bmc-modal sub" size="xl"
                show={props.modalIsOpen}
                onShow={fn_afterOpenEvnt}
                onHide={fn_closeModal}
            >

                <Modal.Header>
                    <div className="d-flex justify-content-between align-items-center w-100 bmc-font-SD">
                        결재선
                        <IoMdClose className='hover:cursor-pointer' onClick={fn_closeModal}/>
                    </div>
                </Modal.Header>

                <Modal.Body className="px-4">
                    <Row>
                        <Col>
                            <SHSearch formRef={searchFormRef} srchFnc={fn_retrieveDataList} btnType={'p'}>
                            <Table className="bmc-font-SP bmc-fs-b2 my-3">
                                <tbody>
                                <tr className="border-top align-middle border-bottom">
                                    <th className="bmc-bg-th">이름</th>
                                    <td className={'text-right'}>
                                        <BMCInput name={'userName'} className={'form-control'} onChange={fn_setSrchData} value={data.userName} />
                                    </td>
                                    <th className="bmc-bg-th">업체</th>
                                    <td className={'text-right'}>
                                        <BMCSelect initOptionGb={'all'} name={'cmpnySeq'} className={"form-select"}
                                                  selParam={{siteId: props.siteId}}
                                                  codeGb={'siteCmpny'}
                                                  onChange={fn_setSrchData}
                                                  value={data.cmpnySeq}></BMCSelect>
                                    </td>
                                    <th className="bmc-bg-th">직책</th>
                                    <td className={'text-right'}>
                                        <BMCSelect initOptionGb={'all'} name={'chrgCode'} className={"form-select"}
                                                  selParam={{siteId: props.siteId, manageCode: 'M001003'}}
                                                  codeGb={'siteMngCd'}
                                                  onChange={fn_setSrchData}
                                                  value={data.chrgCode}></BMCSelect>
                                    </td>
                                </tr>
                                </tbody>
                            </Table>
                            </SHSearch>

                        </Col>
                    </Row>
                    <div className="row mt-3">
                        <div className="col-5 pe-0">
                            <div style={{borderTopLeftRadius: '8px', borderTopRightRadius: '8px'}}
                                 className="bmc-bg-gray6 w-100 text-center bmc-text-black py-1 bmc-fw-5 mt-2">
                                조회 리스트
                            </div>
                            <Table borderless>
                                <thead>
                                <tr>
                                    <th><input type="checkbox" name={'srchChkAll'} ref={srchChkAllRef} className={'form-check-input'}
                                               onChange={fn_chkAll}/></th>
                                    <th className={'text-center'}>업체명</th>
                                    <th className={'text-center'}>직책</th>
                                    <th className={'text-center'}>이름</th>
                                </tr>
                                </thead>
                                <tbody>
                                {
                                    mngrDataList.length > 0 ?
                                        mngrDataList.map((data: any, idx: number) => (
                                            <tr key={idx}>
                                                <td><input type="checkbox" name={'srchChk'}
                                                           onChange={(e) => {
                                                               fn_onSrchChkChanged(e, idx)
                                                           }}
                                                           className={'form-check-input'}
                                                           checked={data.srchChk || false}
                                                /></td>
                                                <td>
                                                    {data.companyName}
                                                </td>
                                                <td className={'text-center'}>
                                                    {data.siteChrgNm}
                                                </td>
                                                <td>
                                                    {data.userName}
                                                </td>
                                            </tr>

                                        )) : <tr>
                                            <td colSpan={4}>
                                                <div
                                                    className="w-100 justify-content-center d-flex flex-column align-items-center py-2">
                                                    <img src={iconNodata} alt="이미지" style={{width: '70px'}}/>
                                                    <span className="bmc-text-font_secondary02 bmc-fw-5 mt-2">조회된 결재 담당자가 없습니다.</span>
                                                </div>
                                            </td>
                                        </tr>
                                }

                                </tbody>
                            </Table>
                        </div>
                        <div className="col-md-1 h-100 d-flex justify-content-center align-items-center p-0">
                            <div className="d-flex justify-content-center flex-column" style={{height: '600px'}}>
                                <img
                                    src={iconArrowLeft} width="20" onClick={fn_removeMngr}
                                    className="align-self-center cursor-pointer"/>
                                <img
                                    src={iconArrowRight} width="20" onClick={fn_addMngr}
                                    className="align-self-center mt-3  cursor-pointer"/>

                            </div>

                        </div>

                        <div className="col-6 ps-0">
                            <div style={{borderTopLeftRadius: '8px', borderTopRightRadius: '8px'}}
                                 className="bmc-bg-gray6 w-100 text-center bmc-text-black py-1 bmc-fw-5 mt-2">
                                <div className={'d-flex justify-between align-items-center px-3'}>
                                    <div></div>
                                    <div>선택 리스트</div>
                                    <div className={'d-flex justify-between'}>
                                        <img src={IconUpperArrow} onClick={() => fn_changeSelListOrder('UP')}
                                             style={{width: '16px', height: '16px', cursor: 'pointer'}} alt="아이콘"/>
                                        <img src={IconDownArrow} onClick={() => fn_changeSelListOrder('DOWN')} style={{
                                            width: '16px',
                                            height: '16px',
                                            marginLeft: '8px',
                                            cursor: 'pointer'
                                        }} alt="아이콘"/>
                                    </div>
                                </div>
                            </div>
                            <Table borderless>
                                <thead>
                                <tr>
                                    <th><input type="checkbox" name={'selChkAll'} ref={selChkAllRef}
                                               className={'form-check-input'}
                                               onChange={fn_chkAll}/></th>
                                    <th className={'text-center'}>상신</th>
                                    <th className={'text-center'}>업체명</th>
                                    <th className={'text-center'}>직책</th>
                                    <th className={'text-center'}>이름</th>
                                </tr>
                                </thead>
                                <tbody>
                                {
                                    apprLineDataList.length > 0 ?
                                        apprLineDataList.map((data: any, idx: number) => (
                                            <tr key={idx}>
                                                <td><input type="checkbox" name={'selChk'}
                                                           onChange={(e) => {
                                                               fn_onSelChkChanged(e, idx)
                                                           }}
                                                           className={'form-check-input'}
                                                           checked={data.selChk || false}
                                                /></td>
                                                <td className={'text-center'}>{data.statNm}</td>
                                                <td>
                                                    {data.companyName}
                                                </td>
                                                <td className={'text-center'}>
                                                    {data.siteChrgNm}
                                                </td>
                                                <td>
                                                    {data.userName}
                                                </td>

                                            </tr>

                                        )) : <tr>
                                            <td colSpan={5}>
                                                <div
                                                    className="w-100 justify-content-center d-flex flex-column align-items-center py-2">
                                                    <img src={iconNodata} alt="이미지" style={{width: '70px'}}/>
                                                    <span className="bmc-text-font_secondary02 bmc-fw-5 mt-2">선택된 결재 담당자가 없습니다. 좌측에서 담당자를 선택하세요.</span>
                                                </div>
                                            </td>
                                        </tr>
                                }

                                </tbody>
                            </Table>
                        </div>
                    </div>

                </Modal.Body>
                <Modal.Footer className="py-2">
                    <div className='flex justify-center w-100'>
                        <button
                            className='bmc-btn pill l white me-3'
                            onClick={fn_closeModal}>닫기
                        </button>
                        <button
                            className='bmc-btn pill p' onClick={fn_rgstData}>저장
                        </button>
                    </div>
                </Modal.Footer>
            </Modal>
        </div>
    )
}

export default ApprLineModal;
