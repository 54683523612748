import React, {useContext, useEffect, useRef, useState} from 'react';
import SubMenuTitle from "../component/SubMenuTitle";
import SHSearch from "../component/BMCSearch";
import {Container, Tab, Table, Tabs} from "react-bootstrap";
import BMCInput from "../../components/common/BMCInput";
import Datatables from "../component/Datatables";
import {getLoginUser} from "../../helper/SecurityHelper";
import {UserContext} from "../../context/User";
import axios from "axios";
import BMCSelect from "../../components/common/BMCSelect";
import WrkPrmtRgstModal from "../siteSafetyManagement/modal/WrkPrmtRgstModal";
import RskAsmntRgstModal from "../siteSafetyManagement/modal/RskAsmntRgstModal";

type Props = {};

const ApprovalStatusManagementPage = (props: Props) => {

    const searchFormRef = useRef<any>();
    const cmpnySeqRef = useRef<any>();
    const [data, setData] = useState<any>();
    const srchTxtRef = useRef<any>();
    const defaultUser = getLoginUser();
    const {user} = useContext<any>(UserContext);
    const [gridTab, setGridTab] = useState<any>('wrkPrmt')
    const [wrkPrmtDatatable, setWrkPrmtDatatable] = useState<any>();
    const [rskAsmntDatatable, setRskAsmntDatatable] = useState<any>();
    const [cmpnyCodeList, setCmpnyCodeList] = useState<any>([]);
    const [detailData, setDetailData] = useState<any>(null);
    const [wrkModalIsOpen, setWrkModalIsOpen] = React.useState(false);
    const [rskModalIsOpen, setRskModalIsOpen] = React.useState(false);
    const fn_setSrchData = (e: any) => {

        if (e.target.name == 'state') {
            setData({
                ...data,
                ['state']: e.target.value,
                ['prcsGbCd']: e.target.value,
                ['prcsStatCd']: e.target.value
            })
            console.log(data);
        }else{
            setData({
                ...data,
                [e.target.name]: e.target.value
            })
        }
    }

    useEffect(() => {
        fn_retrieveSrchCond(user?.blngSiteId || defaultUser.blngSiteId)
    }, [user])

    useEffect(() => {
        fn_search()
    },[gridTab])

    const fn_retrieveSrchCond = (siteId: number) => {
        fn_retrieveCmpnySiteDataList(defaultUser.blngSiteId).then((rsltData) => {
            setCmpnyCodeList(rsltData);
        });
    }

    const fn_retrieveCmpnySiteDataList = async (siteId: string) => {
        return new Promise(function (resolve, reject) {
            axios.post('/admin/api/company/retrieveCmpnySiteDataList', {
                siteId: siteId
            }).then((result) => {
                resolve(result.data);
            })
        });
    }

    const fn_search = () =>{
        if(gridTab == 'wrkPrmt'){
            wrkPrmtDatatable?.draw()
        }else if(gridTab == 'rskAsmnt'){
            rskAsmntDatatable?.draw()
        }else{
            //TODO PTW 설계 필요
        }
    }

    return (
        <Container fluid className="bg-white px-0 bmc-txt-color-primary">
            <WrkPrmtRgstModal setModalIsOpen={setWrkModalIsOpen} modalIsOpen={wrkModalIsOpen}
                              siteId={user?.blngSiteId || defaultUser.blngSiteId}
                              detailData={detailData}/>
            <RskAsmntRgstModal setModalIsOpen={setRskModalIsOpen} modalIsOpen={rskModalIsOpen} siteId={user?.blngSiteId || defaultUser.blngSiteId}
                               detailData={detailData}/>

            <SubMenuTitle title={'나의결재현황'} parentMenu={'My Page'} />

            <div className="p-4 overflow-y-auto" style={{height: 'calc(100vh - 180px)'}}>
                <SHSearch onSearch={() => fn_search()} formRef={searchFormRef} btnType={'p'}>
                    <Table className="bmc-font-SP bmc-fs-b2 my-3">
                        <tbody>
                        <tr className="border-top border-bottom align-middle">
                            <th className="bmc-bg-th" style={{width:"15%"}}>업체</th>
                            <td style={{width:"35%"}}>
                                <BMCInput type={'hidden'} name={'siteId'} value={user?.blngSiteId || defaultUser.blngSiteId}></BMCInput>
                                <BMCInput type={'hidden'} name={'prcsGbCd'} value={data?.prcsGbCd}></BMCInput>
                                <BMCInput type={'hidden'} name={'prcsStatCd'} value={data?.prcsStatCd}></BMCInput>
                                <BMCInput type={'hidden'} name={'apprUserSeq'} value={defaultUser.userSeq}></BMCInput>
                                <select className='form-select' name={'cmpnySeq'} ref={cmpnySeqRef}
                                        onChange={fn_setSrchData}>
                                    <option value=''>전체</option>
                                    {cmpnyCodeList.map((data: any, idx: number) => (
                                        <option key={idx} value={data.companySeq}>{data.companyName}</option>
                                    ))}
                                </select>
                            </td>
                            <th className="bmc-bg-th" style={{width:"15%"}}>상태</th>
                            <td style={{width:"35%"}}>
                                <BMCSelect className={'form-select'} initOptionGb={'all'} name={'state'}
                                           onChange={fn_setSrchData} value={data?.state} codeGrpId={'S001'}></BMCSelect>
                            </td>
                        </tr>

                        <tr className="border-top border-bottom align-middle">
                            <th className="bmc-bg-th" style={{width:"15%"}}>검색어</th>
                            <td style={{width:"35%"}}>
                                <input type={'text'} name={'srchTxt'} className='form-control' ref={srchTxtRef}/>
                            </td>
                            <th className="bmc-bg-th" style={{width:"15%"}}></th>
                            <td style={{width:"35%"}}></td>
                        </tr>
                        </tbody>
                    </Table>
                </SHSearch>

                <div className={'ge-result'}>
                    <Tabs
                        transition={true}
                        activeKey={gridTab}
                        onSelect={(k) => setGridTab(k)}
                        id="gridTab"
                        className="mb-3"
                    >
                        <Tab eventKey="wrkPrmt" title="작업허가서">
                            <Datatables
                                dataUrl={'/admin/api/wrkPrmt/retrieveWrkPrmtDataList'}
                                parameterFormRef={searchFormRef}
                                columns={
                                    [
                                        {
                                            data: "prcsStatNm", title: "상태", textAlign: 'center', render: (data: any) => {
                                                switch (data) {
                                                    case '작성중':
                                                        return "<div class='bmc-box-status writing'>작성중</div>";
                                                    case '결재요청':
                                                        return "<div class='bmc-box-status request'>결재요청</div>";
                                                    case '결재중':
                                                        return "<div class='bmc-box-status approval'>결재중</div>";
                                                    case '결재완료':
                                                        return "<div class='bmc-box-status approvaldone'>결재완료</div>";
                                                    case '반려':
                                                        return "<div class='bmc-box-status refuse'>반려</div>";
                                                    default:
                                                        return "";
                                                }
                                            }
                                        },
                                        {data: "companyName", title: "업체", textAlign: 'center'},
                                        {data: "wrkTypeNm", title: "공종", textAlign: 'center'},
                                        {data: "wrkrCnt", title: "작업인원", textAlign: 'center'},
                                        {data: "wrkDt", title: "작업일", textAlign: 'center'},
                                        {data: "wrkTimeGbNm", title: "작업시간", textAlign: 'center'},
                                        {
                                            title: "프린트",
                                            type: "button",
                                            className: "text-center",
                                            buttons: [{
                                                label: "<img style='height: 20px' class='cursor-pointer' src='/icon_print.svg' />",
                                                onClick: (v: any) => {
                                                    alert('개발중')
                                                },
                                            }],
                                        },
                                    ]
                                }
                                checked={false}
                                index={true}

                                onLoad={(dt: any) => {
                                    setWrkPrmtDatatable(dt)
                                }}
                                onClick={(data) => {
                                    setDetailData(data)
                                    setWrkModalIsOpen(true);
                                }}
                            />
                        </Tab>
                        <Tab eventKey="rskAsmnt" title="위험성평가서">
                            <Datatables
                                dataUrl={'/admin/api/rskAsmnt/retrieveRskAsmntDataList'}
                                parameterFormRef={searchFormRef}
                                columns={[
                                    {data: "rskAsmntGbNm", title: "구분", textAlign: 'center'},
                                    {
                                        data: "prcsStatNm", title: "상태", textAlign: 'center', render: (data: any) => {
                                            switch (data) {
                                                case '작성중':
                                                    return "<div class='bmc-box-status writing'>작성중</div>";
                                                case '결재요청':
                                                    return "<div class='bmc-box-status request'>결재요청</div>";
                                                case '결재중':
                                                    return "<div class='bmc-box-status approval'>결재중</div>";
                                                case '결재완료':
                                                    return "<div class='bmc-box-status approvaldone'>결재완료</div>";
                                                case '반려':
                                                    return "<div class='bmc-box-status refuse'>반려</div>";
                                                default:
                                                    return "";
                                            }
                                        }
                                    },
                                    {data: "cmpnyNm", title: "업체", textAlign: 'center'},
                                    {data: "workTypeNm", title: "공종", textAlign: 'center'},
                                    {data: "raProd", title: "평가기간", textAlign: 'center'},
                                    {data: "evlCntnts", title: "평가내용", textAlign: 'center'},
                                    {data: "rgstrNm", title: "작성자", textAlign: 'center'},
                                    {data: "rgstDt", title: "작성일", textAlign: 'center'},
                                    {
                                        title: "프린트",
                                        type: "button",
                                        className: "text-center",
                                        buttons: [{
                                            label: "<img style='height: 20px' class='cursor-pointer' src='/icon_print.svg' />",
                                            onClick: (v: any) => {
                                                alert('개발중')
                                            },
                                        }],
                                    },
                                ]}
                                checked={false}
                                index={true}

                                onLoad={(dt: any) => {
                                    setRskAsmntDatatable(dt)
                                }}
                                onClick={(data) => {
                                    setDetailData(data);
                                    setRskModalIsOpen(data);
                                }}
                            />
                        </Tab>
                        <Tab eventKey="contact" title="Contact">
                            추후 적용
                        </Tab>
                    </Tabs>
                </div>
            </div>
        </Container>
    );
};

export default ApprovalStatusManagementPage;
