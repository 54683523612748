import React, {useContext, useEffect, useRef, useState} from 'react';
import Datatables, {getCheckedItems} from "../component/Datatables";
import axios from "axios";
import {toast, ToastContainer} from "react-toastify";
import messageCodeDic from "../../util/messageCodeDic";
import errorCodeDic from "../../util/errorCodeDic";
import BMCSelect from "../../components/common/BMCSelect";
import BMCInput from "../../components/common/BMCInput";
import CustomDatePickerRange from "../component/ui/DatePickerRange";
import SiteSearchModal from "../companyManagement/modal/SiteSearchModal";
import BMCSearch from '../component/BMCSearch';
import SubMenuTitle from "../component/SubMenuTitle";
import {getLoginUser} from "../../helper/SecurityHelper";
import {Col, Container, Row, Table} from "react-bootstrap";
import BMCIconButton from "../../components/common/BMCIconButton";
import RskAsmntRgstModal from "./modal/RskAsmntRgstModal";
import BMCDatePicker from "../../components/common/BMCDatePicker";
import RskAsmntDetailModal from "./modal/RskAsmntDetailModal";
import {UserContext} from "../../context/User";
import InitRskEvalModal from "./modal/InitRskEvalModal";
import {useSelector} from "react-redux";
import {RootState} from "../../redux/store";
import RgrRskEvalModal from "./modal/RgrRskEvalModal";
import restapi from "../../helper/restAPIHelper";
import IFrameModal from "../../modal/IFrameModal";

type Props = {};

const RiskAssessmentManagementPage = (props: Props) => {
    const [modalIsOpen, setModalIsOpen] = React.useState(false);
    const [modalDetailIsOpen, setModalDetailIsOpen] = React.useState(false);
    const [detailData, setDetailData] = useState<any>(null);
    const [isSitePopOpend, setIsSitePopOpend] = React.useState<boolean>(false);
    const [blngSiteList, setBlngSiteList] = useState<any[]>([]);
    const prodRef = useRef<any>();
    const [isDatePopupOpen, setIsDatePopupOpen] = useState(false);
    const [startDate, setStartDate] = useState<string>('');
    const [endDate, setEndDate] = useState<string>('');
    const [data, setData] = useState<any>({})
    const searchFormRef = useRef<any>();
    const [datatable, setDatatable] = useState<any>();
    const defaultUser = getLoginUser();
    const {user} = useContext<any>(UserContext);
    const [isInitRskEvlModalOpen, setIsInitRskEvlModalOpen] = useState<boolean>(false);
    const [isRgrRskEvlModalOpen, setIsRgrRskEvlModalOpen] = useState<boolean>(false);
    const [isCodeDataLoaded, setIsCodeDataLoaded] = useState<boolean>(false);
    const [isIFrameModalOpen, setIsIFrameModalOpen] = useState<boolean>(false);
    const [riskAsmntFileId, setRiskAsmntFileId] = useState<string>('');

    useEffect(()=>{

        if(datatable){
            datatable.draw();
        }

    }, [user, datatable])

    useEffect(() => {

        datatable?.draw();
    }, [modalIsOpen])

    useEffect(() => {
        if(isCodeDataLoaded){
            datatable?.draw();
        }

    }, [isCodeDataLoaded])


    const fn_search = () => {
        datatable.draw();
    }

    /**
     * 등록 버튼 클릭
     */
    const fn_registPopOpen = () => {


        setDetailData(null)
        setModalIsOpen(true);
    }

    const fn_delete = () => {

        var checkedData = getCheckedItems(datatable);
        let delList: number[] = [];

        if (checkedData.length == 0) {
            toast.warn(messageCodeDic['005']);
            return;
        }

        checkedData.map((data: any) => {
            delList.push((data.riskAsmntId));
        })


        if (!window.confirm(messageCodeDic['004'])) {
            return;
        }

        axios.post('/admin/api/rskAsmnt/deleteRiskAsmntData', {delList: delList}).then(rsltData => {
                if (rsltData.data > 0) {
                    toast.success(messageCodeDic['002'])
                    fn_search();
                } else {
                    toast.error(errorCodeDic['003'])
                }

            }
        ).catch(() => {
                toast.error(errorCodeDic['003'])
            }
        )
    }


    const fn_datePopupOpen = () => {
        setIsDatePopupOpen(!isDatePopupOpen);
    };

    const fn_setFormData = (e: any) => {

        setData({
            ...data,
            [e.target.name]: e.target.value
        })

    }

    const fn_onChangeToAllVal = (e: any) => {
        const checked = e.target.checked;

        if (e.target.checked) {
        }

    }

    const fn_print = (e: any) => {
        console.log("e",e);
        let param:any ={
            riskAsmntId:e.riskAsmntId,
            siteName: e.siteName, //현장명
            workTypeNm:e.workTypeNm, //공종
            cmpnyNm:e.cmpnyNm, //업체
            ocptNm:e.ocptNm, //직종
            rskAsmntGbNm:e.rskAsmntGbNm, //정시.수시 구분
            raProd:e.raProd, //평가기간
        }

        if(e.riskAsmntFileId){
            setRiskAsmntFileId(e.riskAsmntFileId);
            setIsIFrameModalOpen(true);
        }else{
            restapi.retrieveRiskAsmntApprInfo({riskAsmntId: e.riskAsmntId}).then((rsltData: any) => {
                const apprUserDataList: any = rsltData.apprUserDataList;
                let apprData;
                let chrgNm;
                let userName;
                let apprStatNm;
                let prcsDt;
                if(apprUserDataList.length > 0){
                    let maxLength = apprUserDataList.length;
                    if(maxLength > 8){
                        maxLength = 8;
                    }

                    for(var i= 0; i < maxLength; i++){
                        apprData = apprUserDataList[i];
                        chrgNm = apprData.chrgNm;
                        userName = apprData.userName;
                        apprStatNm = apprData.apprStatNm;
                        prcsDt = apprData.prcsDt;
                        param['chrgNm' + (i+1)] = chrgNm;
                        param['userName' + (i+1)] = userName;
                        param['apprStatNm' + (i+1)] = apprStatNm;
                        param['prcsDt' + (i+1)] = prcsDt;
                    }

                }
                axios.post('/admin/api/rskAsmnt/createRskAsmntPrint', param).then(rsltData => {
                        window.open(process.env.REACT_APP_FILE_URL + "/bmcss-data/fileDownloadByFileName?fileName="+rsltData.data, "_blank")
                    }
                ).catch(() => {
                        toast.error(errorCodeDic['003'])
                    }
                )

            })

        }


    }

    return (
        <Container fluid className="bg-white px-0 bmc-txt-color-primary">
            <SubMenuTitle title={'위험성평가 관리'} parentMenu={'현장안전관리'}/>
            <IFrameModal setModalIsOpen={setIsIFrameModalOpen} modalIsOpen={isIFrameModalOpen} title={'위험성 평가 PDF'} iframeSrc={process.env.REACT_APP_FILE_URL + "/bmcss-data/fileDownload?attachSeq=" + riskAsmntFileId + '&sn=0&isPdf=1'} />
            <InitRskEvalModal setModalIsOpen={setIsInitRskEvlModalOpen} modalIsOpen={isInitRskEvlModalOpen} />
            <RgrRskEvalModal setModalIsOpen={setIsRgrRskEvlModalOpen} modalIsOpen={isRgrRskEvlModalOpen} />
            <SiteSearchModal setModalIsOpen={setIsSitePopOpend} modalIsOpen={isSitePopOpend}
                             blngSiteList={blngSiteList}
                             setBlngSiteList={setBlngSiteList} isSingleCheck={true} siteNm={data?.siteName}/>
            <RskAsmntDetailModal setModalIsOpen={setModalDetailIsOpen} modalIsOpen={modalDetailIsOpen}
                                 detailData={detailData} siteId={user?.blngSiteId || defaultUser.blngSiteId}/>
            <RskAsmntRgstModal setModalIsOpen={setModalIsOpen} modalIsOpen={modalIsOpen} siteId={user?.blngSiteId || defaultUser.blngSiteId}
                               detailData={detailData}/>

            <div className="p-4 overflow-y-auto" style={{height: 'calc(100vh - 180px)'}}>
                <BMCSearch datatable={datatable} formRef={searchFormRef} btnType={'p'}>
                    <Row>
                        <Col>
                            <Table className="bmc-font-SP bmc-fs-b2 my-3">
                                <tbody>
                                <tr className="border-top align-middle border-bottom">
                                    <th className="bmc-bg-th">업체명</th>
                                    <td>
                                        <BMCInput type={'hidden'} name={'siteId'} value={user?.blngSiteId || defaultUser.blngSiteId}></BMCInput>
                                        <BMCSelect name={'cmpnySeq'} onChange={fn_setFormData} value={data.cmpnySeq} setIsCodeDataLoaded={setIsCodeDataLoaded} initOptionGb={'all'} codeGb={'siteCmpny'} selParam={{siteId:user?.blngSiteId || defaultUser.blngSiteId}}></BMCSelect>

                                    </td>
                                    <th className="bmc-bg-th">공종</th>
                                    <td>
                                        <BMCSelect initOptionGb={'sel'} name={'workTypeCd'} className={"form-select"}
                                                   selParam={{siteId: user?.blngSiteId || defaultUser.blngSiteId, manageCode: 'M001001'}}
                                                   codeGb={'siteMngCd'}
                                                   onChange={fn_setFormData}
                                                   value={data?.workTypeCd}></BMCSelect>
                                    </td>
                                </tr>
                                <tr className="align-middle border-bottom">
                                    <th className="bmc-bg-th">기간</th>
                                    <td>
                                        <div className="d-flex align-items-center w-100" ref={prodRef}>
                                            <BMCDatePicker
                                                className={"text-center"} readOnly={true}
                                                value={startDate} name={'raStartDate'} onClick={fn_datePopupOpen}
                                                onChange={fn_setFormData}
                                                calendar={fn_datePopupOpen}
                                            />
                                            <span>&nbsp;~&nbsp;</span>
                                            <BMCDatePicker readOnly={true}
                                                           className={"text-center"}
                                                           value={endDate} name={'raEndDate'} onClick={fn_datePopupOpen}
                                                           onChange={fn_setFormData}
                                                           calendar={fn_datePopupOpen}
                                            />

                                            {isDatePopupOpen
                                                ? <CustomDatePickerRange setIsDatePopupOpen={setIsDatePopupOpen}
                                                                         prodRef={prodRef}
                                                                         setStartDateView={setStartDate}
                                                                         setEndDateView={setEndDate}/>
                                                : null}
                                        </div>
                                    </td>
                                    <td colSpan={4}>
                                        <div className="d-flex align-items-center">
                                            <div className={'form-check w-25'}>
                                                <input className="form-check-input bmc-check" id={'reception_toAll'}
                                                       onChange={fn_onChangeToAllVal} type="checkbox"/>
                                                <label className={'form-check-label text-black'}
                                                       htmlFor={'reception_toAll'}>전체기간</label>
                                            </div>
                                            <BMCInput className={'form-control'} name={'srchTxt'} value={data?.srchTxt}
                                                      onChange={fn_setFormData}/>
                                        </div>
                                    </td>
                                </tr>
                                </tbody>
                            </Table>

                        </Col>
                    </Row>
                </BMCSearch>


                <div className={'w-100 d-flex justify-content-between'}>
                    <div className='w-100 d-flex justify-content-left align-items-center mb-2'>
                        <button className={'bmc-btn lblue p'} onClick={() => setIsInitRskEvlModalOpen(true)}> 최초 위험성 평가
                        </button>

                        <button className={'bmc-btn lpurple p ms-2'} onClick={() => setIsRgrRskEvlModalOpen(true)}> 정기 위험성 평가
                        </button>

                    </div>

                    <div className='w-100 d-flex justify-content-end align-items-center mb-2'>
                        <BMCIconButton kind={'submit'} marginRight={'5px'} onClick={fn_registPopOpen}> 등록
                        </BMCIconButton>
                        <BMCIconButton kind={'delete'} marginRight={'0'} onClick={fn_delete}> 삭제
                        </BMCIconButton>
                    </div>
                </div>


                <div>
                    <Datatables
                        dataUrl={'/admin/api/rskAsmnt/retrieveRskAsmntDataList'}
                        parameterFormRef={searchFormRef}
                        columns={[
                            {data: "rskAsmntGbNm", title: "구분", textAlign: 'center'},
                            {
                                data: "prcsStatNm", title: "상태", textAlign: 'center', render: (data: any) => {
                                    switch (data) {
                                        case '작성중':
                                            return "<div class='bmc-box-status writing'>작성중</div>";
                                        case '결재요청':
                                            return "<div class='bmc-box-status request'>결재요청</div>";
                                        case '결재중':
                                            return "<div class='bmc-box-status approval'>결재중</div>";
                                        case '결재완료':
                                            return "<div class='bmc-box-status approvaldone'>결재완료</div>";
                                        case '반려':
                                            return "<div class='bmc-box-status refuse'>반려</div>";
                                        default:
                                            return "";
                                    }
                                }
                            },
                            {data: "cmpnyNm", title: "업체", textAlign: 'center'},
                            {data: "workTypeNm", title: "공종", textAlign: 'center'},
                            {data: "raProd", title: "평가기간", textAlign: 'center'},
                            {data: "evlCntnts", title: "평가내용", textAlign: 'center'},
                            {data: "rgstrNm", title: "작성자", textAlign: 'center'},
                            {data: "rgstDt", title: "작성일", textAlign: 'center'},
                            {
                                title: "프린트",
                                type: "button",
                                className: "text-center",
                                buttons: [{
                                    label: "<img style='height: 20px' class='cursor-pointer' src='/icon_print.svg' />",
                                    onClick: (v: any) => {
                                        fn_print(v);
                                    },
                                }],
                            },
                        ]}
                        checked={true}
                        index={true}

                        onLoad={(dt: any) => {
                            setDatatable(dt)
                        }}
                        onClick={(data) => {
                            setDetailData(data)
                            setModalIsOpen(true);
                        }
                        }
                    />
                </div>
            </div>
        </Container>
    );
};

export default RiskAssessmentManagementPage;
