import React, {createContext, useState} from 'react';

const UserContext = createContext({
  user: null,
  setUser: ({}: any) => {},
});

const UserProvider = ({children}: any) => {
  const [user, setUser] = useState(null);

  const value: any = {user, setUser};

  return <UserContext.Provider value={value}>{children}</UserContext.Provider>;
};

export {UserContext, UserProvider};
