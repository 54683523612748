import DatePicker from "react-datepicker";
import {Dispatch, SetStateAction, useRef, useEffect, useState} from "react";
import "react-datepicker/dist/react-datepicker.css";
import '../../../css/datePicker.css'
import { ko } from "date-fns/locale";
import { getMonth, getYear } from "date-fns";
import { MdOutlineArrowBackIosNew } from 'react-icons/md'
import { MdOutlineArrowForwardIos } from 'react-icons/md'
const $ = require("jquery")
interface IProps {

  setStartDateView: Dispatch<SetStateAction<string>>;
  setEndDateView: Dispatch<SetStateAction<string>>;
  setIsDatePopupOpen: Dispatch<SetStateAction<boolean>>;
  prodRef: any;
}

const CustomDatePickerRange = (props: IProps) => {
  const [startDate, setStartDate] = useState<Date>(new Date());
  const [endDate, setEndDate] = useState<Date>(new Date());
  const [x, setX] = useState<Number>(-1000);
  const [y, setY] = useState<Number>(-1000);
  const modalRef = useRef<HTMLDivElement>(null);
  const _ = require("lodash");
  const years = _.range(2000, getYear(new Date()) + 5, 1); // 연도 선택 select box에 보여질 데이터 : range(시작 연도, 끝 연도, 연도 간격)
  const months = [  // 월 선택 select box에 보여질 데이터
    "01",
    "02",
    "03",
    "04",
    "05",
    "06",
    "07",
    "08",
    "09",
    "10",
    "11",
    "12",
  ];
  const onChange = (dates: any) => {
    const [start, end] = dates;
    setStartDate(start);
    setEndDate(end);

    let startYear = start.getFullYear();
    let startMonth: number = start.getMonth() + 1;
    let startDay = start.getDate();

    props.setStartDateView(`${startYear}-${startMonth >= 10 ? startMonth : '0' + startMonth}-${startDay >= 10 ? startDay : '0' + startDay}`);
    if(end){
      let endYear = end.getFullYear();
      let endMonth: number = end.getMonth() + 1;
      let endDay = end.getDate();
      props.setEndDateView(`${endYear}-${endMonth >= 10 ? endMonth : '0' + endMonth}-${endDay >= 10 ? endDay : '0' + endDay}`);
    }


    if (end) {
      props.setIsDatePopupOpen(false);
    }
  };
  useEffect(() => {
    // 이벤트 핸들러 함수
    const handler = (e: any) => {
      // mousedown 이벤트가 발생한 영역이 모달창이 아닐 때, 모달창 제거 처리
      if (modalRef.current && !modalRef.current?.contains(e.target) && !props.prodRef.current?.contains(e.target)) {
        props.setIsDatePopupOpen(false);
      }

    };
    // 이벤트 핸들러 등록
    document.addEventListener('mousedown', handler);
    document.addEventListener('touchstart', handler); // 모바일 대응
    return () => {
      // 이벤트 핸들러 해제
      document.removeEventListener('mousedown', handler);
      document.removeEventListener('touchstart', handler); // 모바일 대응
    };
  });
  useEffect(() => {
    var event = windowEvent()
    if(event){
      var target = event.target
      // @ts-ignore
      var x = event.x
      // @ts-ignore
      var y = event.y
      var t = $(target)
      var o = t.offset()
      var h = t.outerHeight()
      if(t.parents(".bmc-modal").length > 0){
        h = h - 45
      }
      setX(o.left)
      setY(o.top + h + 5)
      console.log(1212, o, h)
    }
  },[]);
  function windowEvent() {
    if (window.event)
      return window.event;
    return null;
  }
  return (
    <div className="bmc-cal" style={{position:"fixed", left: "0px", top: "0px", zIndex:1}}>
      <div ref={modalRef} style={{position:"absolute", left: x + "px", top: y + "px"}}>
        <DatePicker
            renderCustomHeader={({
                                   date,
                                   changeYear,
                                   changeMonth,
                                   decreaseMonth,
                                   increaseMonth,
                                   prevMonthButtonDisabled,
                                   nextMonthButtonDisabled,
                                 }) => (
                <div className="custom-react-datepicker__select-wrapper">
                  {/* 이전 월로 이동하는 버튼 */}
                  <button type="button" onClick={decreaseMonth} disabled={prevMonthButtonDisabled}>
                    <MdOutlineArrowBackIosNew />
                  </button>
                  <div className="custom-react-datepicker__select-item">
                    {/* 연도 선택 select box */}
                    <select
                        value={getYear(date)}
                        onChange={({ target: { value } }) => changeYear(Number(value))}
                    >
                      {years.map((option: any) => (
                          <option key={option} value={option}>
                            {option}
                          </option>
                      ))}
                    </select>
                    <span>년</span>
                  </div>
                  <div className="custom-react-datepicker__select-item">
                    {/* 월 선택 select box */}
                    <select
                        value={months[getMonth(date)]}
                        onChange={({ target: { value } }) =>
                            changeMonth(months.indexOf(value))
                        }
                    >
                      {months.map((option) => (
                          <option key={option} value={option}>
                            {option}
                          </option>
                      ))}
                    </select>
                    <span>월</span>
                  </div>
                  {/* 다음 월로 이동하는 버튼 */}
                  <button type="button" onClick={increaseMonth} disabled={nextMonthButtonDisabled}>
                    <MdOutlineArrowForwardIos />
                  </button>
                </div>
            )}
            locale={ko} // (월~일 부분) 한국어로 변환
            inline
            selected={startDate}
            onChange={onChange} // 선택한 날짜를 state에 저장
            selectsRange
            startDate={startDate}
            endDate={endDate}
        />
      </div>
    </div>
  );
};

export default CustomDatePickerRange;
