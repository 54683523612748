import {RouteType} from "../routes/config";
import SSMonitoringPage from "../pages/smallsite/SSMonitoringPage";
import NoticeManagementPageLayout from "../pages/noticeManagement/NoticeManagementPageLayout";
import WrkDisruptRqstHisPage from "../pages/noticeManagement/WrkDisruptRqstHisPage";
import SosRqstHisPage from "../pages/noticeManagement/SosRqstHisPage";
import NoticeRegistrationPage from "../pages/noticeManagement/NoticeRegistrationPage";
import SiteSafetyManagementPageLayout from "../pages/siteSafetyManagement/SiteSafetyManagementPageLayout";
import SmartTBMManagementPage from "../pages/siteSafetyManagement/SmartTBMManagementPage";
import WorkPermitManagementPage from "../pages/siteSafetyManagement/WorkPermitManagementPage";
import WrkPrmtProdMngPage from "../pages/siteSafetyManagement/WrkPrmtProdMngPage";
import RiskAssessmentManagementPage from "../pages/siteSafetyManagement/RiskAssessmentManagementPage";
import SafetyEducationContentsPage from "../pages/safetyInformationBoard/SafetyEducationContentsPage";
import SiteAdminPageLayout from "../pages/siteManagementAdmin/SiteAdminPageLayout";
import DngrEvalMngPage from "../pages/dngrEvalMng/DngrEvalMngPage";
import SiteManagementAdminPage from "../pages/siteManagementAdmin/SiteManagementAdminPage";
import SiteCmpnyMngPage from "../pages/siteManagementAdmin/SiteCmpnyMngPage";
import MainSiteInfoManagePage from "../pages/siteConstructionManagement/MainSiteInfoManagePage";
import ManPowerManagementPageLayout from "../pages/manpowerManagement/ManPowerManagementPageLayout";
import ManagerManagementPage from "../pages/manpowerManagement/ManagerManagementPage";
import EmployeeManagementPage from "../pages/manpowerManagement/EmployeeManagementPage";
import MyPageLayout from "../pages/myPage/MyPageLayout";
import AppsOutlinedIcon from "@mui/icons-material/AppsOutlined";
import MyInformationManagementPage from "../pages/myPage/MyInformationManagementPage";
import CompanyManagementPage from "../pages/companyManagement/CompanyManagementPage";
import ApprovalStatusManagementPage from "../pages/myPage/ApprovalStatusManagementPage";
import React from "react";
import SvrAcdntPnshmntMngPage from "../pages/siteSafetyManagement/SvrAcdntPnshmntMngPage";
import DailySftyRprtPage from "../pages/safetyWorkReportManagement/DailySftyRprtPage";

export const Constatnts = {
    bldrAuthMenuList: ['siteManagementAdminPage.cmpnyManage',
        'siteManagementAdminPage.mainSiteInfoManagePage'
    ],
    defaultSiteId: '155',
    defaultGuCd: '2632000000',
    defaultSmSiteId: '52',
    defaultCntrlSiteId: '52',
    headMstrMenuList: ['config.mngrMng']

}

export const appRoutesSmSiteForText: any = [
    {
        index: true,
        state: "home"
    },
    {
        path: "/smSite/monitoring",
        /*element: <MonitoringPage />,*/
        state: "smSite.monitoring",
        sidebarProps: {
            displayText: "모니터링",
        }
    },
    {
        path: "noticeManagement",
        state: "noticeManagement",
        sidebarProps: {
            displayText: "알림/공지사항 관리",
        },
        child: [
            {
                path: "/smSite/noticeManagement/WrkDisruptRqstHis",
                state: "noticeManagement.WrkDisruptRqstHis",
                sidebarProps: {
                    displayText: "작업중지요청 관리"
                },
            },
            {
                path: "/smSite/noticeManagement/SosRqstHis",
                state: "noticeManagement.SosRqstHis",
                sidebarProps: {
                    displayText: "SOS응급콜 관리"
                },
            },
            {
                path: "/smSite/noticeManagement/noticeRegistration",
                state: "noticeManagement.noticeRegistration",
                sidebarProps: {
                    displayText: "공지사항"
                },
            },

        ]
    },

    {
        path: "siteSafetyManagement",
        state: "siteSafetyManagement",
        sidebarProps: {
            displayText: "현장안전관리",
        },
        child: [
            {
                path: "/smSite/siteSafetyManagement/SmartTBMManagement",
                state: "siteSafetyManagement.SmartTBMManagement",
                sidebarProps: {
                    displayText: "TBM 관리"
                }
            },
            {
                path: "/smSite/siteSafetyManagement/wrkPrmtProdMng",
                state: "siteSafetyManagement.wrkPrmtProdMng",
                sidebarProps: {
                    displayText: "작업지시서 관리"
                }
            },
            {
                path: "/smSite/siteSafetyManagement/riskAssessmentManagement",
                state: "siteSafetyManagement.riskAssessmentManagement",
                sidebarProps: {
                    displayText: "위험성평가 관리"
                },
            },
            {
                path: "/smSite/siteSafetyManagement/safetyEducationContents",
                state: "siteSafetyManagement.safetyEducationContents",
                sidebarProps: {
                    displayText: "안전교육컨텐츠"
                },
            },
            {
                path: "/smSite/siteSafetyManagement/svrAcdntPnshmntMng",
                state: "siteSafetyManagement.svrAcdntPnshmntMng",
                sidebarProps: {
                    displayText: "중대재해처벌법 관리"
                }
            },
            {
                path: "/smSite/siteSafetyManagement/dailySftyRprt",
                state: "siteSafetyManagement.dailySftyRprt",
                sidebarProps: {
                    displayText: "일일안전일보"
                },
            },
        ]
    },

    {
        path: "siteManagementAdminPage",
        state: "siteManagementAdminPage",
        sidebarProps: {
            displayText: "현장 Admin",
        },
        child: [
            {
                path: "/smSite/siteManagementAdminPage/dngrEvalMng",
                state: "siteManagementAdminPage.dngrEvalMng",
                sidebarProps: {
                    displayText: "위험성평가설정"
                },
            },
            {
                path: "/smSite/siteManagementAdminPage/siteManagementAdmin",
                state: "siteManagementAdminPage.siteManagementAdmin",
                sidebarProps: {
                    displayText: "현장세부정보설정"
                },
            },
            {
                path: "/smSite/siteManagementAdminPage/cmpnyManage",
                state: "siteManagementAdminPage.cmpnyManage",
                sidebarProps: {
                    displayText: "협력업체 관리"
                },
            },
            {
                path: "/smSite/siteManagementAdminPage/mainSiteInfoManagePage",
                state: "siteManagementAdminPage.mainSiteInfoManagePage",
                sidebarProps: {
                    displayText: "공사기본정보관리"
                },
            },
        ]
    },

    {
        path: "manPowerManagement",
        state: "manPowerManagement",
        sidebarProps: {
            displayText: "인력 관리",
        },
        child: [

            {
                path: "/smSite/manPowerManagement/managerManagement",
                state: "manPowerManagement.managerManagement",
                sidebarProps: {
                    displayText: "관리자 관리"
                }
            },
            {
                path: "/smSite/manPowerManagement/employeeManagement",
                state: "manPowerManagement.employeeManagement",
                sidebarProps: {
                    displayText: "근로자 관리"
                },
            },
        ]
    },

    {
        path: "myPage",
        state: "myPage",
        sidebarProps: {
            displayText: "My Page",
        },
        child: [
            {
                path: "/smSite/myPage/myInformationManagement",
                state: "myPage.myInformationManagement",
                sidebarProps: {
                    displayText: "나의 정보 관리"
                },
            },
            {
                path: "/smSite/myPage/companyManagement",
                state: "myPage.companyManagement",
                sidebarProps: {
                    displayText: "소속 업체 관리"
                },
            },
            {
                path: "/smSite/myPage/approvalStatusManagementPage",
                state: "myPage.approvalStatusManagementPage",
                sidebarProps: {
                    displayText: "결재 현황 관리"
                },
            },
        ]
    },
];


