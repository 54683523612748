import React, {Dispatch, MutableRefObject, SetStateAction, useRef, useState} from "react";
import iconCalendar from "../../images/icon-calendar-black.svg";

interface IProps {
    value?: any;
    name?: string;
    onChange?: Dispatch<SetStateAction<any>>;
    className?: string;
    onClick?: Dispatch<SetStateAction<any>>;
    readOnly?: boolean;
    type?: string;
    disabled?: boolean;
    onBlur?: Dispatch<SetStateAction<any>>;
    placeholder?:string;
    calendar?: Dispatch<SetStateAction<any>>;
}

export default function BMCInput(props: IProps) {
    const [defaultValue, setDefaultValue] = useState('');

    const fn_setDefaultValue = (e: any) => {
        setDefaultValue(e.target.value);
    }

    return (
        <input type={props.type || 'text'} name={props.name || ''}
               className={'bmc-input '+props.className || 'bmc-input'}
               value={props.value || defaultValue}
               placeholder={props.placeholder || ''}
               onChange={props.onChange || fn_setDefaultValue}
               onClick={props.onClick}
               readOnly={props.readOnly||false}
               disabled={props.disabled||false}
               onBlur={props.onBlur}/>
    )
}
