import React, {createContext, useState} from 'react';

const SiteContext = createContext({
    site: null,
    setSite: ({}: any) => {},
});

const SiteProvider = ({children}: any) => {
    const [site, setSite] = useState(null);

    const value: any = {site, setSite};

    return <SiteContext.Provider value={value}>{children}</SiteContext.Provider>;
};

export {SiteContext, SiteProvider};
