import React, {Dispatch, SetStateAction, useState} from "react";

interface IProps {
    items: object[]
}

export default function ApprCardList(props: IProps) {


    return (
        <div className="container-fluid px-0">
            <div className="row" id="ga-company-sites">
                {
                    props.items.map((item: any, idx) => {
                        return (
                            <div key={idx} className="col-md-2 mb-4">
                                <div className="card ga-card small">
                                    <div className="card-header text-center">{item.chrgNm}</div>
                                    <div className="card-body items-center min-h-[100px]">
                                        <h5 className="card-title text-center">{item.userName}</h5>
                                        <h5 className={"card-title text-center " + (item.apprStat == 'S002002' ? "text-blue-600": item.apprStat == 'S002003' ? 'text-red-600' : '')}>{item.apprStatNm || '-'}</h5>
                                        <h5 className="card-title text-center">{item.prcsDt}</h5>
                                    </div>
                                </div>
                            </div>
                        )
                    })
                }


            </div>
        </div>
    )
}
