import React, {Dispatch, SetStateAction, useContext, useEffect, useRef, useState} from "react";
import {Modal, Table} from "react-bootstrap";
import {IoMdClose} from "react-icons/io";
import axios from "axios";
import {toast} from "react-toastify";
import {getLoginUser} from "../../../helper/SecurityHelper";
import BMCSelect from "../../../components/common/BMCSelect";
import BMCInput from "../../../components/common/BMCInput";
import restapi from "../../../helper/restAPIHelper";
import errorCodeDic from "../../../util/errorCodeDic";
import {ProgressContext} from "../../../context/Progress";
import messageCodeDic from "../../../util/messageCodeDic";
import QRCode from 'qrcode.react';
import ReactToPrint from "react-to-print";

interface IProps {
    setModalIsOpen: Dispatch<SetStateAction<boolean>>;
    modalIsOpen: boolean;
    dtlData: any;
}

class QrCodeToPrint extends React.Component {
    render() {
        // @ts-ignore
        const {value} = this.props;
        console.log('value: ', value);
        return (
            <div style={{display: 'flex', justifyContent: 'center', alignItems: 'center', height: '100%'}}>
                <QRCode
                    style={{width: 200, height: 200}} // Increase size for better readability
                    value={value}
                    // Assuming these properties exist; adjust according to actual API
                    size={200} // This can control the output size of the QR code
                    level={"H"} // Higher error correction level, if supported
                />
            </div>
        );
    }
}

const QrCodeModal = (props: IProps) => {

    const user = getLoginUser();

    const [data, setData] = useState<any>({});
    const {spinner} = useContext(ProgressContext);
    const ref = useRef();


    function fn_closeModal() {
        props.setModalIsOpen(false);
    }


    const fn_onAfterShow = () => {


    }

    const fn_printContent = () => {

    };



    // @ts-ignore
    return (
        <div>
            <Modal
                className="bmc-modal  sub" size="sm"
                show={props.modalIsOpen}
                onShow={fn_onAfterShow}
                onHide={fn_closeModal}
            >
                <Modal.Header>
                    <div className="d-flex justify-content-between align-items-center w-100 bmc-font-SD">
                        QR 출력 [{props.dtlData?.nfc}]
                        <IoMdClose className='hover:cursor-pointer' onClick={fn_closeModal}/>
                    </div>
                </Modal.Header>


                <Modal.Body className="px-4">

                    {// @ts-ignore
                        <QrCodeToPrint ref={ref} value={props.dtlData?.nfc} />}

                </Modal.Body>

                <Modal.Footer className="py-2">
                    <div className='flex justify-center w-100'>
                        <button
                            className='bmc-btn pill l white me-3'
                            onClick={fn_closeModal}>닫기
                        </button>
                        {
                            <ReactToPrint

                            trigger={() => <button className='bmc-btn pill p'>출력</button>}
                            //@ts-ignore
                            content={() => {
                                return ref.current;
                            }}
                        />}

                    </div>
                </Modal.Footer>
            </Modal>
        </div>
    )
}

export default QrCodeModal;
