import SHSearch from "../../component/BMCSearch";
import {Col, Row, Table} from "react-bootstrap";
import BMCSelect from "../../../components/common/BMCSelect";
import Datatables from "../../component/Datatables";
import restapi from "../../../helper/restAPIHelper";
import React, {Dispatch, SetStateAction, useContext, useEffect, useRef, useState} from "react";
import NoSbmtSiteListModal from "../modal/NoSbmtSiteListModal";
import SvrAcdntPnshmntRgstModal from "../../siteSafetyManagement/modal/SvrAcdntPnshmntRgstModal";
import {toast} from "react-toastify";
import errorCodeDic from "../../../util/errorCodeDic";
import {ProgressContext} from "../../../context/Progress";

interface IProps {
    chkDt: string;
    tabParam: any;
}

const SvrAcdntSiteKndModalPage = (props: IProps) => {
    const searchFormRef = useRef<any>();
    const [isModalOpen, setIsModalOpen] = useState<boolean>(false);
    const [dtlData, setDtlData] = useState<any>({});
    const [noSbmtSiteDataList, setNoSbmtSiteDataList] = useState<any>([]);
    const [isNoSbmtModalOpen, setIsNoSbmtModalOpen] = useState<boolean>(false);
    const [srchData, setSrchData] = useState<any>(null);
    const {spinner} = useContext(ProgressContext);
    const [dataList, setDataList] = useState<any>([]);
    const [datatable, setDatatable] = useState<any>();


    useEffect(() => {
        console.log('tabParam: ', props.tabParam);
        setSrchData(props.tabParam);
    }, [props.tabParam]);

    useEffect(() => {
        if(srchData){
            fn_retrieveSiteDataListNoPaging();
            fn_retrieveSvrAcdntPnshmntChkStatDataList();
        }

    }, [srchData]);

    const fn_setSrchData = (e: any) => {

        setSrchData({
            ...srchData,
            [e.target.name]: e.target.value
        })

    }
    const fn_openNoSbmtSitePop = () => {
        if(noSbmtSiteDataList.length > 0){
            setIsNoSbmtModalOpen(true);
        }
    }


    const fn_retrieveSiteDataListNoPaging = () => {
        restapi.retrieveSiteDataListNoPaging({chkDt:props.chkDt, isSvrAcdntNoSbmt:true, dprtCd:srchData?.dprtCd, siteType:srchData?.siteType}).then((rsltData: any) => {
            setNoSbmtSiteDataList(rsltData);
        })
    }

    const fn_retrieveSvrAcdntPnshmntChkStatDataList = () => {
        let param = {chkDt:props.chkDt, dprtCd:srchData?.dprtCd, siteType: srchData?.siteType};
        spinner.start();
        restapi.retrieveSvrAcdntPnshmntChkStatDataList(param).then((rsltData: any) => {
            setDataList(rsltData);
        }).catch(error => {
            toast.error(errorCodeDic['003']);
            console.log('error: ', error);
        })
            .finally(() => {
                spinner.stop();
            });
    }
    return (
        <>
            <NoSbmtSiteListModal setModalIsOpen={setIsNoSbmtModalOpen} modalIsOpen={isNoSbmtModalOpen} dataList={noSbmtSiteDataList} />
            <SvrAcdntPnshmntRgstModal setModalIsOpen={setIsModalOpen} modalIsOpen={isModalOpen} detailData={dtlData} siteId={dtlData?.siteId} />
            <SHSearch formRef={searchFormRef} btnType={'p'}>
                <Row>
                    <Col>
                        <Table className="bmc-font-SP bmc-fs-b2 my-3">
                            <tbody>
                            <tr className="border-top align-middle border-bottom">
                                <th className="bmc-bg-th">관리부서</th>
                                <td>
                                    <BMCSelect initOptionGb={'all'} codeGb={'cmmn'} value={srchData?.dprtCd} onChange={fn_setSrchData} codeGrpId={'D001'} name={'dprtCd'} />
                                </td>

                            </tr>
                            </tbody>
                        </Table>
                    </Col>
                </Row>

            </SHSearch>

        <div className='w-100 d-flex justify-content-between align-items-center mb-2'>
            <span className={'text-[#3561eb] underline cursor-pointer'} onClick={fn_openNoSbmtSitePop}>{noSbmtSiteDataList.length > 0 ? '미제출 사업장 (' + noSbmtSiteDataList.length + '건)' : '미제출 사업장 없음'}</span>
        </div>
        <div className={'ge-result'}>
            <Datatables
                datas={dataList}
                scrollY="550px"
                className="bmc-fs-b4"
                parameterFormRef={searchFormRef}
                columns={[
                    {data: "dprtNm", title: "관리부서", textAlign: 'center'},
                    {data: "siteName", title: "공사명", textAlign: 'center'},
                    {data: 'chkCnt', title: "상태", textAlign: 'center', render: (data: any) => {
                           let color = '';

                            if(data > 0){
                                return "<span class='text-red-500'>비정상</span>";
                            }else{
                                return "<span class='text-green-500'>정상</span>";
                            }

                        }
                    },
                    {data: '', title: "상세보기", textAlign: 'center', render: (data: any) => {

                            return "<span class='text-[#3561eb] underline cursor-pointer'>상세보기</span>";
                        }
                    }
                ]}
                checked={false}
                onLoad={(dt: any) => {
                    setDatatable(dt)
                }}

                onClick={(data) => {
                    console.log('data: ', data);
                    restapi.retrieveSvrAcdntPnshmntTotDataList({acdntPnshmntSeq:data.acdntPnshmntSeq}).then((rsltData: any) => {
                        console.log('rsltData: ', rsltData);
                        setDtlData(rsltData[0]);
                        setIsModalOpen(true);
                    })
                }
                }
            />
        </div>
        </>
    )
}

export default SvrAcdntSiteKndModalPage
