import React, {useContext} from 'react';
import {Container, Row, Col} from "react-bootstrap";
import DashboardWeather from './DashWeather';
import DashboardProcessRate from './DashProcessRate';
import DashboardWorkPermit from './DashWorkPermitDoc';
import DashboardRiskAssessment from './DashRiskAssessment';
import DashboardTodayWork from './DashTodayWork';
import DashboardTotalDepartureStatus from './DashTotalDepartureStatus';
import DashboardPartDepartureStatus from './DashPartDepartureStatus';
import DashboardZoneInformation from './DashZoneInformation';
import DashboardNotice from "./DashNotice";
import DashboardNonconformityState from "./DashNonconformityState";
import DashboardEquipmentStatus from "./DashEquipmentStatus";
import DashboardSiteCCTV from "./DashSiteCCTV";
import {MenuOpenContext} from "../../context/MenuOpen";
import DashWorkPermitDoc from "./DashWorkPermitDoc";
import DashEquipmentStatus from "./DashEquipmentStatus";
import DashTodayWork from "./DashTodayWork";
import DashRiskAssessment from "./DashRiskAssessment";
import DashZoneInformation from "./DashZoneInformation";
import DashSiteCCTV from "./DashSiteCCTV";
import DashNotice from "./DashNotice";
import DashPartDepartureStatus from "./DashPartDepartureStatus";
import HOTotalWeatherInfoV2 from "../headoffice/HOTotalWeatherInfoV2";
import DashProcessRate from "./DashProcessRate";
import SiteRiskStatus from "./SiteRiskStatus";
import DashSiteMultipleInfo from "./DashSiteMultipleInfo";

type Props = {};

const HomePage = (props: Props) => {
    const {open} = useContext(MenuOpenContext);
    return (
        <>
            <Container fluid style={{backgroundColor:'rgb(14, 24, 65)'}}>
                <Row className="pe-0">
                    <Col bsPrefix="col-7" style={{height: 'calc(100vh - 64px)', overflowY:"scroll" }}>
                        <Container fluid>
                            <Row className="pt-4">
                                <Col bsPrefix="col-6" className="ps-0">
                                    <HOTotalWeatherInfoV2 />
                                </Col>
                                <Col bsPrefix="col-6" className="pe-0">
                                    {/*<DashProcessRate />*/}
                                    <SiteRiskStatus />
                                </Col>
                            </Row>
                            <Row className="pt-4">
                                <Col bsPrefix="col-6" className="ps-0">
                                    <DashboardTotalDepartureStatus />
                                </Col>
                                <Col bsPrefix="col-6" className="pe-0">
                                    <DashPartDepartureStatus />
                                </Col>
                            </Row>
                            <Row className="pt-4">
                                <Col bsPrefix="col-6" className="ps-0">
                                    <DashWorkPermitDoc />
                                </Col>
                                <Col bsPrefix="col-6" className="pe-0">
                                    <DashEquipmentStatus />
                                </Col>
                            </Row>
                            <Row className="pt-4 pb-4">
                                <Col bsPrefix="col-6" className="ps-0">
                                    <DashTodayWork />
                                </Col>
                                <Col bsPrefix="col-6" className="pe-0">
                                    <div className={"pb-4"}>
                                        <DashRiskAssessment />
                                    </div>
                                    <div>
                                        <DashboardNonconformityState />
                                    </div>
                                </Col>
                            </Row>
                        </Container>
                    </Col>
                    <Col bsPrefix="col-5" className="p-4" style={{height: 'calc(100vh - 64px)', overflowY:"scroll" }}>
                        <div className="bmc-bg-pane" style={{minHeight: "calc(100vh - 113px)"}}>
                            <DashSiteMultipleInfo/>
                            <DashZoneInformation />
                            <DashSiteCCTV />
                        </div>
                    </Col>
                </Row>
            </Container>
        </>
  );
};

export default HomePage;
