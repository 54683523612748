import {IoMdClose} from "react-icons/io";
import iconCalendar from "../../../images/icon-calendar-black.svg";
import CustomDatePickerRange from "../../component/ui/DatePickerRange";
import React, {Dispatch, SetStateAction, useContext, useEffect, useRef, useState} from "react";
import {CmmnFn, fn_getCurrentDate} from "../../../helper/CmmnHelper";
import {getLoginUser} from "../../../helper/SecurityHelper";
import {Col, Row, Table, Modal} from "react-bootstrap";
import {COMMON_MODAL_STYLE, COMMON_MODAL_STYLE_SCROLL, COMMON_MULTISEL_STRINGS} from "../../../helper/StyleHelper";
import BMCInput from "../../../components/common/BMCInput";
import BMCTextarea from "../../../components/common/BMCTextarea";
import axios from "axios";
import {toast} from "react-toastify";
import messageCodeDic from "../../../util/messageCodeDic";
import errorCodeDic from "../../../util/errorCodeDic";
import BMCSelect from "../../../components/common/BMCSelect";

import {MultiSelect} from "react-multi-select-component";
import restapi from "../../../helper/restAPIHelper";

import BMCIconButton from "../../../components/common/BMCIconButton";

import {ProgressContext} from "../../../context/Progress";
import Datatables from "../../component/Datatables";
import SHSearch from "../../component/BMCSearch";
import TbmNtcListModal from "./TbmNtcListModal";
import TbmWrkPrmtListModal from "./TbmWrkPrmtListModal";
import TbmRiskAsmntListModal from "./TbmRiskAsmntListModal";
import WrkPrmtProdRgstModal from "./WrkPrmtProdRgstModal";
import WrkPrmtRgstModal from "./WrkPrmtRgstModal";

const $ = require("jquery")

interface IProps {
    setModalIsOpen: Dispatch<SetStateAction<boolean>>;
    modalIsOpen: boolean;
    detailData: any;
    siteId: number;
    isReadOnly?: boolean;
    cmpnySeq: number;
}

const SmrtTbmDtlModal = (props: IProps) => {

    const user = getLoginUser();

    const [todayWrkInfo, setTodayWrkInfo] = useState<any>({});

    const {spinner} = useContext(ProgressContext);
    const [datatable, setDatatable] = useState<any>();
    const searchFormRef = useRef<any>();
    const [dataList, setDataList] = useState<any>([]);
    const [dtlData, setDtlData] = useState<any>({});
    const [isOpenNtcModal, setIsOpenNtcModal] = useState<boolean>(false);
    const [isOpenRskAsmntModal, setIsOpenRskAsmntModal] = useState<boolean>(false);
    const [detailData, setDetailData] = useState<any>(null);
    const defaultUser = getLoginUser();
    const [isSmWrkPrmtModalOpen, setIsSmWrkPrmtModalOpen] = useState<boolean>(false);
    const [isWrkPrmtModalOpen, setIsWrkPrmtModalOpen] = useState<boolean>(false);

    useEffect(() => {
        if(datatable){
            datatable.draw();
        }
    }, [datatable])

    const fn_onAfterOpen = () => {
        console.log('open');
        console.log('props.detailData: ', props.detailData);
        const totWrkrCnt = props.detailData.totWrkrCnt;
        const tbmCmpltCnt = props.detailData.tbmCmpltCnt;
        const isWrkCmpltd = props.detailData.isWrkCmpltd;
        const wrkTypeNm = props.detailData.wrkTypeNm;
        const todayWrkCntnts = props.detailData.todayWrkCntnts;
        const istrctns = props.detailData.istrctns;
        let tbmYn = '';
        let wrkStat = '';
        if (totWrkrCnt == tbmCmpltCnt) {
            if (props.detailData.totWrkrCnt == 0) {
                tbmYn = 'N'
            } else {
                tbmYn = 'Y'
            }
        } else {
            tbmYn = 'N'
        }

        if (totWrkrCnt == tbmCmpltCnt) {
            if (totWrkrCnt == 0) {
                wrkStat = '작업대기'
            } else {
                if (isWrkCmpltd) {
                    wrkStat = '작업완료'
                } else {
                    wrkStat = '작업중'
                }
            }
        } else {
            wrkStat = '작업대기'
        }


        let param: any = {
            wrkTypeNm: wrkTypeNm,
            todayWrkCntnts: todayWrkCntnts,
            tbmYn: tbmYn,
            wrkStat: wrkStat,
            totWrkrCnt: totWrkrCnt,
            istrctns: istrctns,
        };

        console.log('param: ', param);
        setTodayWrkInfo(param);
        fn_retrieveUserTbmStatDataList();

        setDtlData({blngCmpnySeq: props.detailData.cmpnySeq, blngSiteId: props.detailData.siteId, wrkDt: props.detailData.wrkDt});
        setDetailData(props.detailData);


    }


    function fn_closeModal() {
        props.setModalIsOpen(false);
    }

    const fn_retrieveUserTbmStatDataList = () => {
        spinner.start()

        console.log('searchFormRef: ', searchFormRef.current);
        const obj = new FormData(searchFormRef.current);
        console.log('obj wrkdt: ', obj.get('wrkDt'));


        if(CmmnFn.isSmSite()){
            restapi.retrievePrmtWrkrDataList({
                wrkDt: props.detailData.wrkDt,
                wrkPrmtId: props.detailData.wrkPrmtId,
                workTypeCd: obj.get('workTypeCd'),
                ocptCd: obj.get('ocptCd'),
                // isSmSite: true,
            }).then((rsltData: any) => {
                setDataList(rsltData);
                console.log('rsltData: ', rsltData);
            }).catch(error => {
                toast.error(errorCodeDic['003']);
                console.log('error: ', error);
            })
            .finally(() => {
                spinner.stop();
            });
        }else{
            restapi.retrieveUserTbmStatDataList({
                siteId: obj.get('siteId'),
                wrkDt: obj.get('wrkDt'),
                tbmYn: obj.get('tbmYn'),
                cmpnySeq: obj.get('cmpnySeq'),
                workTypeCd: obj.get('workTypeCd'),
                ocptCd: obj.get('ocptCd'),
            }).then((rsltData: any) => {
                setDataList(rsltData);
                console.log('rsltData: ', rsltData);
            }).catch(error => {
                toast.error(errorCodeDic['003']);
                console.log('error: ', error);
            })
                .finally(() => {
                    spinner.stop();
                });
        }
    }

    const fn_openNtcModal = () => {
        setIsOpenNtcModal(true);
    }
    const fn_openWrkPrmtModal = () => {
        if(CmmnFn.isSmSite()){
            setIsSmWrkPrmtModalOpen(true);
        }else{
            setIsWrkPrmtModalOpen(true);
        }
    }
    const fn_openRskAsmntModal = () => {
        setIsOpenRskAsmntModal(true);
    }

    return (
        <div>
            <Modal className="bmc-modal" size="xl" show={props.modalIsOpen} onHide={fn_closeModal}
                   onShow={fn_onAfterOpen}>
                <TbmNtcListModal setModalIsOpen={setIsOpenNtcModal} modalIsOpen={isOpenNtcModal} dtlData={dtlData} />
                {/*<TbmWrkPrmtListModal setModalIsOpen={setIsOpenWrkPrmtModal} modalIsOpen={isOpenWrkPrmtModal} dtlData={dtlData} />*/}
                <TbmRiskAsmntListModal setModalIsOpen={setIsOpenRskAsmntModal} modalIsOpen={isOpenRskAsmntModal} dtlData={dtlData} />
                <WrkPrmtProdRgstModal setModalIsOpen={setIsSmWrkPrmtModalOpen} modalIsOpen={isSmWrkPrmtModalOpen}
                                      siteId={user?.blngSiteId || defaultUser.blngSiteId}
                                      detailData={detailData}/>
                <WrkPrmtRgstModal setModalIsOpen={setIsWrkPrmtModalOpen} modalIsOpen={isWrkPrmtModalOpen}
                                  siteId={user?.blngSiteId || defaultUser.blngSiteId}
                                  detailData={detailData}/>
                <Modal.Header>
                    <div className="d-flex justify-content-between align-items-center w-100 bmc-font-SD">
                        스마트 TBM 상세
                        <IoMdClose className='hover:cursor-pointer' onClick={fn_closeModal}/>
                    </div>
                </Modal.Header>
                <Modal.Body className="px-4">

                    <div className="bmc-modal-div">
                        <div className="div-header">
                            <div className='w-100 d-flex justify-content-between align-items-center mb-2'>
                            <div>
                                금일 작업 사항
                            </div>
                                <div className={'d-flex'}>
                                <BMCIconButton kind={'normal'} marginRight={'5px'} onClick={fn_openWrkPrmtModal}>작업허가서
                                </BMCIconButton>
                                <BMCIconButton kind={'normal'} marginRight={'5px'} onClick={fn_openRskAsmntModal}>위험성평가
                                </BMCIconButton>
                                <BMCIconButton kind={'normal'} marginRight={'5px'} onClick={fn_openNtcModal}>공지사항
                                </BMCIconButton>
                                </div>

                            </div>
                        </div>
                        <div className="div-body px-3">
                            <Table className="my-2" borderless style={{borderTop: 'none'}}>

                                <thead>
                                <tr className="table-secondary">
                                    <td className="text-center w-[15%]">공종</td>
                                    <td className="text-center w-[55%]">금일 작업사항 내용</td>
                                    <td className="text-center w-[10%]">총인원</td>
                                    <td className="text-center w-[10%]">TBM 여부</td>
                                    <td className="text-center w-[10%]">작업상태</td>

                                </tr>
                                </thead>
                                <tbody>
                                <tr>
                                    <td className={'text-center'}>
                                        {todayWrkInfo.wrkTypeNm}
                                    </td>
                                    <td className={'text-center'}>
                                        {todayWrkInfo.todayWrkCntnts || todayWrkInfo.istrctns}
                                    </td>
                                    <td className={'text-center'}>
                                        {todayWrkInfo.totWrkrCnt}
                                    </td>
                                    <td className={'text-center'}>
                                        {todayWrkInfo.tbmYn}
                                    </td>
                                    <td className={'text-center'}>
                                        {todayWrkInfo.wrkStat}
                                    </td>
                                </tr>

                                </tbody>
                            </Table>
                        </div>
                    </div>

                    <div className="bmc-modal-div">
                        <div className="div-header">
                            <div>
                                근로자 리스트
                            </div>
                        </div>
                        <div className="div-body px-3">
                            <SHSearch formRef={searchFormRef} srchFnc={fn_retrieveUserTbmStatDataList} btnType={'p'}>
                                <Row>
                                    <Col>
                                        <Table className="bmc-font-SP bmc-fs-b2 my-3">
                                            <tbody>
                                            <tr className="border-top align-middle border-bottom">
                                                <th className="bmc-bg-th">TBM 여부</th>
                                                <td>
                                                    <BMCInput type={'hidden'} name={'siteId'} value={props.detailData?.siteId}></BMCInput>
                                                    <BMCInput type={'hidden'} name={'wrkDt'} value={props.detailData?.wrkDt}></BMCInput>
                                                    <select name={'tbmYn'} className={'form-select'}>
                                                        <option value={''}>전체</option>
                                                        <option value={'Y'}>Y</option>
                                                        <option value={'N'}>N</option>
                                                    </select>
                                                </td>
                                                <th className="bmc-bg-th">업체</th>
                                                <td>
                                                    <BMCSelect initOptionGb={'all'}
                                                              codeGb={'siteCmpny'}
                                                              className={'form-control'}
                                                              name={'fakeCmpnySeq'}
                                                              value={props.cmpnySeq}
                                                              selParam={{siteId: props.detailData?.siteId}}
                                                              disabled={true}
                                                    ></BMCSelect>
                                                    <BMCInput type={'hidden'} name={'cmpnySeq'} value={props.cmpnySeq}></BMCInput>

                                                </td>
                                                <th className="bmc-bg-th">공종</th>

                                                <td>
                                                    <BMCSelect initOptionGb={'all'}
                                                              codeGb={'siteMngCd'}
                                                              className={'form-control'}
                                                              name={'workTypeCd'}
                                                              selParam={{
                                                                  siteId: props.detailData?.siteId,
                                                                  manageCode: 'M001001'
                                                              }}
                                                              ></BMCSelect>
                                                </td>
                                                <th className="bmc-bg-th">직종</th>
                                                <td>
                                                    <BMCSelect initOptionGb={'all'}
                                                              codeGb={'siteMngCd'}
                                                              className={'form-control'}
                                                              name={'ocptCd'}
                                                              selParam={{
                                                                  siteId: props.detailData?.siteId,
                                                                  manageCode: 'M001002'
                                                              }}
                                                              ></BMCSelect>
                                                </td>
                                            </tr>
                                            </tbody>
                                        </Table>
                                    </Col>
                                </Row>

                            </SHSearch>

                            <div className={'ge-result'}>
                                <Datatables
                                    datas={dataList}
                                    scrollY="550px"
                                    className="bmc-fs-b4"
                                    parameterFormRef={searchFormRef}
                                    columns={[
                                        {data: "userName", title: "성명", textAlign: 'center'},
                                        {data: "userPhone", title: "전화번호", textAlign: 'center'},
                                        {data: "companyName", title: "업체", textAlign: 'center'},
                                        {data: "wrkTpNm", title: "공종", textAlign: 'center'},
                                        {data: "ocptNm", title: "직종", textAlign: 'center'},
                                        {data: "tbmYn", title: "TBM 여부", textAlign: 'center'},


                                    ]}
                                    checked={false}
                                    index={true}
                                    isNotPaging={true}
                                    isNotOrdering={true}

                                    onLoad={(dt: any) => {
                                        setDatatable(dt)
                                    }}
                                    onClick={(data) => {

                                    }
                                    }
                                />
                            </div>
                        </div>
                    </div>


                </Modal.Body>
                <Modal.Footer className="py-2">
                    <div className='flex justify-center w-100'>
                        <button
                            className='bmc-btn pill l white me-3'
                            onClick={fn_closeModal}>닫기
                        </button>

                    </div>
                </Modal.Footer>
            </Modal>
        </div>
    )
}

export default SmrtTbmDtlModal;

