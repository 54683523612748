import React, {useEffect, useRef, useState} from 'react';
import {Col, Container, Row} from "react-bootstrap";
import {ReactComponent as IconTeam} from "../../assets/images/ico/icon-team.svg";
import {Doughnut, Bar} from "react-chartjs-2";
import restapi from "../../helper/restAPIHelper";
import InOutStatModal from "./modal/InOutStatModal";
import {CmmnFn} from "../../helper/CmmnHelper";

const HOCRInOut = () => {

    const [workerData, setWorkerData] = useState<any>({
        labels: ['근로자', '관리자'],
        datasets: [
            {
                data: [10, 9],
                backgroundColor: [
                    '#4A3AFF',
                    '#E5EAFC',
                ],
                borderColor: [
                    'rgba(255, 255, 255, 1)',
                    'rgba(255, 255, 255, 1)',
                ],
                borderWidth: 1,
                hoverOffset: 5,
            },
        ],
    })
    const [elderlyData, setElderlyData] = useState<any>({
        labels: ['고령자', '그 외'],
        datasets: [
            {
                data: [10, 9],
                backgroundColor: [
                    '#D39AFF',
                    '#E5EAFC',
                ],
                borderColor: [
                    'rgba(255, 255, 255, 1)',
                    'rgba(255, 255, 255, 1)',
                ],
                borderWidth: 1,
                hoverOffset: 5,
            },
        ],
    })
    const [foreignerData, setForeignerData] = useState({
        labels: ['외국인', '내국인'],
        datasets: [
            {
                data: [10, 9],
                backgroundColor: [
                    '#4AC6FF',
                    '#E5EAFC',
                ],
                borderColor: [
                    'rgba(255, 255, 255, 1)',
                    'rgba(255, 255, 255, 1)',
                ],
                borderWidth: 1,
                hoverOffset: 5,
            },
        ],
    });

    const [ageRangeData, setAgeRangeData] = useState({
        labels: ['10대', '20대', '30대', '40대', '50대', '60대', '70대 이상'],
        datasets: [
            {
                data: [0, 0, 0, 0, 0, 0, 0, 0],
                backgroundColor: [
                    '#F0E5FC'
                ],
                borderColor: [
                    'rgba(255, 255, 255, 1)',
                    'rgba(255, 255, 255, 1)',
                ],
                borderWidth: 1,
                hoverOffset: 5,
            },
        ],
    })

    const [totCnt, setTotCnt] = useState<number>(0);
    const [statData, setStatData] = useState<any>({wrkrCnt:0, mngrCnt:0, frgnrCnt:0, koreanCnt:0, oldCnt:0, youngCnt:0})
    const timerRef = useRef<any>();
    const [modalIsOpen, setModalIsOpen] = React.useState(false);
    const [dataList, setDataList] = useState<any>([]);
    const [wrkrTotDataList, setWrkrTotDataList] = useState<any>([]);
    const [wrkrVulnarableDataList, setWrkrVulnarableDataList] = useState<any>([]);
    const [mngrVulnarableDataList, setMngrVulnarableDataList] = useState<any>([]);
    const [mngrKoreanDataList, setMngrKoreanDataList] = useState<any>([]);
    const [wrkrKoreanDataList, setWrkrKoreanDataList] = useState<any>([]);
    const [mngrFrgnlDataList, setMngrFrgnlDataList] = useState<any>([]);
    const [wrkrFrgnlDataList, setWrkrFrgnlDataList] = useState<any>([]);
    useEffect(() => {
        fn_retrieveData();
        //timerRef.current = setInterval(fn_retrieveData, 5000);

        return () => {
            //clearInterval(timerRef.current);
        };
    }, []);


    const fn_setInOutStatPerAgeData = (wrkrDataList: any, mngrDataList: any) => {

        let age = 0;
        let age10th = 0;
        let age20th = 0;
        let age30th = 0;
        let age40th = 0;
        let age50th = 0;
        let age60th = 0;
        let age70th = 0;
        let unknown = 0;


        wrkrDataList.map((data: any) => {
            age = CmmnFn.getAge(data.userBirthday);
            if(age){
                if(age >= 10 && age < 20){
                    age10th++;
                }else if(age >= 20 && age < 30){
                    age20th++;
                }else if(age >= 30 && age < 40){
                    age30th++;
                }else if(age >= 40 && age < 50){
                    age40th++;
                }else if(age >= 50 && age < 60){
                    age50th++;
                }else if(age >= 60 && age < 70){
                    age60th++;
                }else if(age >= 70){
                    age70th++;
                }
            }else{
                unknown++;
            }
        });

        mngrDataList.map((data: any) => {
            age = CmmnFn.getAge(data.userBirthday);
            if(age){
                if(age >= 10 && age < 20){
                    age10th++;
                }else if(age >= 20 && age < 30){
                    age20th++;
                }else if(age >= 30 && age < 40){
                    age30th++;
                }else if(age >= 40 && age < 50){
                    age40th++;
                }else if(age >= 50 && age < 60){
                    age50th++;
                }else if(age >= 60 && age < 70){
                    age60th++;
                }else if(age >= 70){
                    age70th++;
                }
            }else{
                unknown++;
            }
        })

        let datas = [];
        let labels = [];

        if(unknown > 0){
            labels = ['10대', '20대', '30대', '40대', '50대', '60대', '70대 이상', '미상'];
            datas = [age10th, age20th, age30th, age40th, age50th, age60th, age70th, unknown];
        }else{
            labels = ['10대', '20대', '30대', '40대', '50대', '60대', '70대 이상'];
            datas = [age10th, age20th, age30th, age40th, age50th, age60th, age70th];
        }

        setAgeRangeData({
            labels: labels,
            datasets: [
                {
                    data: datas,
                    backgroundColor: [
                        '#F0E5FC'
                    ],
                    borderColor: [
                        'rgba(255, 255, 255, 1)',
                        'rgba(255, 255, 255, 1)',
                    ],
                    borderWidth: 1,
                    hoverOffset: 5,
                },
            ],
        })

    }

    const fn_retrieveData = () => {
        restapi.retrieveTotEtrncStatInfo({qrNfcTpCd: 'T003001'}).then((rsltData: any) => {
            const wrkrTotDataList = rsltData.wrkrTotDataList;
            setWrkrTotDataList(wrkrTotDataList);
            const mngrTotDataList = rsltData.mngrTotDataList;
            const wrkrVulnarableDataList = rsltData.wrkrVulnarableDataList;
            setWrkrVulnarableDataList(wrkrVulnarableDataList);
            const mngrVulnarableDataList = rsltData.mngrVulnarableDataList;
            setMngrVulnarableDataList(mngrVulnarableDataList);
            const mngrKoreanDataList = rsltData.mngrKoreanDataList;
            setMngrKoreanDataList(mngrKoreanDataList);
            const wrkrKoreanDataList = rsltData.wrkrKoreanDataList;
            setWrkrKoreanDataList(wrkrKoreanDataList);
            const mngrFrgnlDataList = rsltData.mngrFrgnlDataList;
            setMngrFrgnlDataList(mngrFrgnlDataList);
            const wrkrFrgnlDataList = rsltData.wrkrFrgnlDataList;
            setWrkrFrgnlDataList(wrkrFrgnlDataList)

            fn_setInOutStatPerAgeData(wrkrTotDataList, mngrTotDataList);


            const oldManCnt = wrkrVulnarableDataList.length + mngrVulnarableDataList.length;
            const youngManCnt = wrkrTotDataList.length + mngrTotDataList.length - oldManCnt;
            const koreanCnt = mngrKoreanDataList.length + wrkrKoreanDataList.length;
            const frgnrCnt = wrkrTotDataList.length + mngrTotDataList - koreanCnt;
            const totCnt = wrkrTotDataList.length + mngrTotDataList.length
            const wrkrCnt = wrkrTotDataList.length;
            const mngrCnt = mngrTotDataList.length;

            setWorkerData({
                labels: ['근로자', '관리자'],
                datasets: [
                    {
                        data: [wrkrCnt, mngrCnt],
                        backgroundColor: [
                            '#4A3AFF',
                            '#E5EAFC',
                        ],
                        borderColor: [
                            'rgba(255, 255, 255, 1)',
                            'rgba(255, 255, 255, 1)',
                        ],
                        borderWidth: 1,
                        hoverOffset: 5,
                    },
                ],
            })


            setElderlyData({
                labels: ['고령자', '그 외'],
                datasets: [
                    {
                        data: [oldManCnt, youngManCnt],
                        backgroundColor: [
                            '#D39AFF',
                            '#E5EAFC',
                        ],
                        borderColor: [
                            'rgba(255, 255, 255, 1)',
                            'rgba(255, 255, 255, 1)',
                        ],
                        borderWidth: 1,
                        hoverOffset: 5,
                    },
                ],
            })

            setForeignerData({
                labels: ['외국인', '내국인'],
                datasets: [
                    {
                        data: [frgnrCnt, koreanCnt],
                        backgroundColor: [
                            '#4AC6FF',
                            '#E5EAFC',
                        ],
                        borderColor: [
                            'rgba(255, 255, 255, 1)',
                            'rgba(255, 255, 255, 1)',
                        ],
                        borderWidth: 1,
                        hoverOffset: 5,
                    },
                ],
            })

            setStatData({wrkrCnt:wrkrCnt, mngrCnt:mngrCnt, frgnrCnt:frgnrCnt, koreanCnt:koreanCnt, oldCnt:oldManCnt, youngCnt:youngManCnt})

            setTotCnt(totCnt);

        })
    }


    const options = {
        responsive: true,
        plugins: {
            legend: {
                display: false,
            }
        },
        rotation: -90,
        circumference: 180,
        cutout: '80%',
    };

    const fn_openInOutStatModal = (modalGb: string) => {

        if(modalGb == 'wrkr'){
            setDataList(wrkrTotDataList);
        }else if(modalGb == 'frgn'){
            setDataList(mngrFrgnlDataList.concat(wrkrFrgnlDataList));
        }else if(modalGb == 'old'){
            setDataList(mngrVulnarableDataList.concat(wrkrVulnarableDataList))
        }

        setModalIsOpen(true);
    }

    return(
        <>
            <InOutStatModal setModalIsOpen={setModalIsOpen} modalIsOpen={modalIsOpen} dataList={dataList} />
            <Container fluid className="bmc-bg-pane">
                <Row>
                    <Col>
                        <div className="d-flex flex-column justify-content-between">
                            <div className="w-100 d-flex align-items-center justify-content-between pb-2 border-bottom">
                                <span className="bmc-txt-size-h4">출역현황</span>
                            </div>
                        </div>
                    </Col>
                </Row>
                <Row className="px-0 pt-3">
                    <Col bsPrefix='col-12' className="pe-0">
                        <div>
                            <span className="bmc-txt-size-b5 mb-2">총 출역인원</span>
                            &nbsp;<span className="bmc-txt-size-h4 me-1">{totCnt}</span>명
                        </div>
                    </Col>
                    <Col bsPrefix={'col-4'}>
                        <div onClick={() => fn_openInOutStatModal('wrkr')} className="cursor-pointer position-relative text-center">
                            <div className="position-absolute z-10 d-flex flex-column align-items-center" style={{left: "46%", top:"60%", transform: 'translate(-50%, -50%)'}}>
                                <span className="bmc-txt-size-b5">근로자</span>
                                <span className="bmc-txt-size-h5 bmc-txt-color-primary">{(statData.wrkrCnt + statData.mngrCnt) > 0 ? (statData.wrkrCnt/(statData.wrkrCnt + statData.mngrCnt) * 100).toFixed(1) : 0}%</span>
                            </div>
                            <Doughnut className="position-relative z-50" style={{marginTop: '-20px'}} options={options}
                                      data={workerData} width="100%"/>
                            {/*<div className="d-flex align-items-center justify-content-center bmc-fs-b4 w-100" style={{marginTop: '-20px'}}>
                                <div className={'cursor-pointer'} onClick={() => null}>
                                    <span style={{color: '#9CD5FF'}}>●</span>
                                    <span className="ps-1">근로자</span>
                                    <span className="ps-2" style={{color: '#5685F7'}}>●</span>
                                    <span className="ps-1">관리자</span>
                                </div>
                            </div>*/}
                        </div>
                    </Col>
                    <Col bsPrefix={'col-4'}>
                        <div onClick={() => fn_openInOutStatModal('frgn')} className="cursor-pointer position-relative text-center">
                            <div className="position-absolute z-10 d-flex flex-column align-items-center" style={{left: "46%", top:"60%", transform: 'translate(-50%, -50%)'}}>
                                <span className="bmc-txt-size-b5">외국인</span>
                                <span className="bmc-txt-size-h5 bmc-txt-color-primary">{(statData.frgnrCnt + statData.koreanCnt) > 0 ? (statData.frgnrCnt/(statData.frgnrCnt + statData.koreanCnt) * 100).toFixed(1) : 0}%</span>
                            </div>
                            <Doughnut className="position-relative z-50" style={{marginTop: '-20px'}} options={options}
                                      data={foreignerData} width="100%" height="100%"/>

                            {/*<div className="d-flex align-items-center justify-content-center bmc-fs-b4 w-100" style={{marginTop: '-20px'}}>
                                <div className={'cursor-pointer'} onClick={() => null}>
                                    <span style={{color: '#F0E5FC'}}>●</span>
                                    <span className="ps-1">내국인</span>
                                    <span className="ps-2" style={{color: '#C893FD'}}>●</span>
                                    <span className="ps-1">외국인</span>
                                </div>
                            </div>*/}
                        </div>
                    </Col>
                    <Col bsPrefix={'col-4'}>
                        <div onClick={() => fn_openInOutStatModal('old')} className="cursor-pointer position-relative text-center">
                            <div className="position-absolute z-10 d-flex flex-column align-items-center" style={{left: "46%", top:"60%", transform: 'translate(-50%, -50%)'}}>
                                <span className="bmc-txt-size-b5">고령자</span>
                                <span className="bmc-txt-size-h5 bmc-txt-color-primary">{(statData.oldCnt + statData.youngCnt) > 0 ? (statData.oldCnt/(statData.oldCnt + statData.youngCnt) * 100).toFixed(1) : 0}%</span>
                            </div>
                            <Doughnut className="position-relative z-50" style={{marginTop: '-20px'}} options={options}
                                      data={elderlyData} width="100%" height="100%"/>

                            {/*<div className="d-flex align-items-center justify-content-center bmc-fs-b4 w-100" style={{marginTop: '-20px'}}>
                                <div className={'cursor-pointer'} onClick={() => null}>
                                    <span style={{color: '#B7EDED'}}>●</span>
                                    <span className="ps-1">고령자</span>
                                    <span className="ps-2" style={{color: '#35D0B7'}}>●</span>
                                    <span className="ps-1">그 외</span>
                                </div>
                            </div>*/}
                        </div>
                    </Col>
                </Row>
                <Row>
                    <Col>
                        <div className="bmc-txt-size-b6 mb-3">연령대별 출역 인원</div>
                        <Bar className="position-relative z-50" style={{marginTop: '-10px'}}
                             data={ageRangeData} width="100%" height="40px"/>
                    </Col>
                </Row>
            </Container>
        </>
    );
}

export default HOCRInOut;
