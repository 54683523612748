import {IoMdClose} from "react-icons/io";
import React, {Dispatch, SetStateAction, useContext, useEffect, useRef, useState} from "react";
import {CmmnFn, fn_getCurrentDate} from "../../../helper/CmmnHelper";
import {getLoginUser, IS_SM_SITE} from "../../../helper/SecurityHelper";
import {Col, Row, Table, Modal} from "react-bootstrap";
import {COMMON_MODAL_STYLE, COMMON_MODAL_STYLE_SCROLL, COMMON_MULTISEL_STRINGS} from "../../../helper/StyleHelper";
import BMCInput from "../../../components/common/BMCInput";
import axios from "axios";
import {toast} from "react-toastify";
import messageCodeDic from "../../../util/messageCodeDic";
import errorCodeDic from "../../../util/errorCodeDic";
import BMCSelect from "../../../components/common/BMCSelect";

import {MultiSelect} from "react-multi-select-component";
import restapi from "../../../helper/restAPIHelper";

import BMCIconButton from "../../../components/common/BMCIconButton";
import IconRemove from '../../../images/icon_remove.png';
import ApprCardList from "../../../components/appr/ApprCardList";
import {
    validFormData,
    readonlyForm
} from "../../../helper/FormHelper";
import {ProgressContext} from "../../../context/Progress";
import BMCCheckbox from "../../../components/common/BMCCheckbox";
import BMCDatePicker2 from "../../../components/common/BMCDatePicker2";
import {DateHelper} from "../../../helper/DateHelper";
import ApprLineModal from "../../siteSafetyManagement/modal/ApprLineModal";
import BMCRadioGroup from "../../../components/common/BMCRadioGroup";
import BMCRadio from "../../../components/common/BMCRadio";
import BMCDatePicker from "../../../components/common/BMCDatePicker";
import CustomDatePicker from "../../component/ui/DatePicker";
import CmpnySrchModal from "../../headManage/modal/CmpnySrchModal";
import BMCFile from "../../../components/common/BMCFile";


const $ = require("jquery")

interface IProps {
    setModalIsOpen: Dispatch<SetStateAction<boolean>>;
    modalIsOpen: boolean;
    detailData: any;
    siteId: number;
    isReadOnly?: boolean;
    isCopy?: boolean;
}

const DailySftyRprtRgstModal = (props: IProps) => {

    const user = getLoginUser();

    const prodRef = useRef<any>();

    const [isDatePopupOpen, setIsDatePopupOpen] = useState(false);
    const [data, setData] = useState<any>()
    const [refUserList, setRefUserList] = useState<any>([]);

    const [apprLineDataList, setApprLineDataList] = useState<any>([]);
    const [mngrUserDataList, setMngrUserDataList] = useState<any>([]);
    const [allUserDataList, setAllUserDataList] = useState<any>([]);
    const [isApprLineModalOpen, setIsApprLineModalOpen] = useState(false);
    const [atchFileList, setAtchFileList] = useState([]);
    const formRef = React.createRef<HTMLFormElement>();
    const {spinner} = useContext(ProgressContext);

    const [sftyChkDataList, setSftyChkDataList] = useState<any>([]);
    const [chkDt, setChkDt] = useState<string>(CmmnFn.fn_getCurrentDate());
    const [isCmpnyModalOpen, setIsCmpnyModalOpen] = useState<boolean>(false);
    const [cmpnyDataList, setCmpnyDataList] = useState<object[]>([])
    const [isShowAtchFile, setIsShowAtchFile] = useState<boolean>(false);
    const [docFormData, setDocFormData] = useState<any>({});

    useEffect(() => {
        let cmpnyNms = '';
        cmpnyDataList.map((data: any, idx: number) => {
            if(idx == 0){
                cmpnyNms = data.companyName;
            }

        })
        if(cmpnyDataList.length >= 2){
            cmpnyNms += ' (외 ' + (cmpnyDataList.length - 1) + ')';
        }


        setData({
            ...data,
            'cmpnyNms': cmpnyNms
        })

    }, [cmpnyDataList]);


    useEffect(() => {

        let apprLinUserSeqList: number[] = [];
        apprLineDataList.map((data: any) => {
            apprLinUserSeqList.push(data.userSeq);
        });

        let cstmAllUserDataList = allUserDataList.filter((data: any) => (!apprLinUserSeqList.includes(data.userSeq)));
        let cstmData: object[] = [];

        cstmAllUserDataList = cstmAllUserDataList.filter((data: any) => (data.userSeq != props.detailData?.rgstrSeq))
        cstmAllUserDataList.map((data: any) => {
            cstmData.push({
                label: '[' + data.companyName + '] ' + data.userName + ' (' + data.chrgNm + ')',
                value: data.userSeq
            });
        })

        // @ts-ignore
        setRefUserList([].concat(cstmData));

    }, [apprLineDataList]);


    useEffect(() => {
        let flag = false;

        sftyChkDataList.map((data: any) => {
            if(data.isChkd) {
                flag = true;
            }
        })
        if(!flag){
            setAtchFileList([]);
        }


        setIsShowAtchFile(flag);
    }, [sftyChkDataList]);



    const fn_setFormData = (e: any) => {


        CmmnFn.setFormData(e, data, setData);

    }


    const fn_onAfterOpen = () => {

        restapi.retrieveDocFormData({formCd: 'D003001'}).then((rsltData: any) => {
            console.log('doc rsltData: ',rsltData);
            setDocFormData(rsltData);
        })

        restapi.retrieveDailySftyRprtChkDataList({sftyRprtSeq: props.detailData?.sftyRprtSeq || null}).then((rsltData: any) => {

            setSftyChkDataList(rsltData);
        })

        // 신규
        if (!props.detailData) {

            const siteId = props.siteId;


            setData({
                siteId: siteId,
                cmpnySeq: user.blngCmpnySeq,
            })
            setChkDt(CmmnFn.fn_getCurrentDate());
            setCmpnyDataList([]);
            setApprLineDataList([]);

            // 상세
        } else {
            console.log('props.detailData: ', props.detailData);

            setChkDt(props.detailData.chkDt);
            setData(props.detailData);
            restapi.retriveDailySftyRprtCntrctrDataList({sftyRprtSeq: props.detailData.sftyRprtSeq}).then((result) => {
                    setCmpnyDataList(result);
                }
            ).catch(error => {
                toast.error(errorCodeDic['003']);
                console.log('error: ', error);
            })
            .finally(() => {
                spinner.stop();
            });

            fn_retrieveApprUserDataList(props.detailData);
            const atchFileId = props.detailData.atchFileId;

            if (atchFileId) {
                restapi.retrieveAttachInfoList({attachSeq: atchFileId}).then((rsltData: any) => {
                    setAtchFileList([].concat(rsltData))
                })
            } else {
                setAtchFileList([]);
            }



        }

    }

    function fn_closeModal() {
        props.setModalIsOpen(false);
    }

    const submit = (e: any) => {
        e.preventDefault()

    }

    const fn_datePopupOpen = () => {
        setIsDatePopupOpen(!isDatePopupOpen);
    };


    const fn_registData = () => {

        if (!data) {
            toast.warn('데이터를 입력해 주십시오.')
            return;
        }

        if (!chkDt) {
            toast.warn('점검일을 선택해 주십시오.');
            return;
        }
        if(chkDt > CmmnFn.fn_getCurrentDate()){
            toast.warn('미래의 점검일을 등록할 수 없습니다.');
            return;
        }

        if(cmpnyDataList.length === 0){
            toast.warn('계약상대자를 선택해 주십시오.');
            return;
        }

        if(!data.prplWrk){
            toast.warn('추진공종을 입력해 주십시오.');
            return;
        }






        var option = {
            addValues: {
                rgstrSeq: user.userSeq,
                updtrSeq: user.userSeq,
                cmpnySeq: data.cmpnySeq,
                sftyRprtSeq: data.sftyRprtSeq,
                prcsStatCd: data.prcsStatCd,
                atchFileId: data.atchFileId
            },
            files: ['atchFileList']
        }

        let dailySftyRprtChkDataList: any = [];
        sftyChkDataList.map((data: any) => {
            if(data.isChkd){
                dailySftyRprtChkDataList.push(data);
            }
        })

        if(dailySftyRprtChkDataList.length > 0 && atchFileList.length == 0){
            toast.warn('특이사항 보고서를 업로드 해 주십시오.');
            return;;
        }

        var param: any = {}
        var formData: any = validFormData(formRef, option, param)
        param["chkDt"] = chkDt
        param["dailySftyRprtCntrctrDataList"] = cmpnyDataList;
        param["dailySftyRprtChkDataList"] = dailySftyRprtChkDataList;

        if (apprLineDataList.length > 0) {
            let apprUserSeqList: number[] = [];

            apprLineDataList.map((data: any) => {
                apprUserSeqList.push(data.userSeq);
            })

            param["apprUserSeqList"] = apprUserSeqList
        }


        restapi.retrieveDailySftyRprtTotDataList({prcsStatCd: 'S001004', chkDt: chkDt, siteId:props.siteId}).then((rsltData: any) => {
            if(rsltData.length > 0){
                toast.warn('해당현장의 선택한 날짜의 일일보고가 이미 완료 되었습니다.');
                return;
            }

            if (!window.confirm('저장 하시겠습니까?')) {
                return;
            }

            formData.set('jsonData', new Blob([JSON.stringify(param)], {type: "application/json"}));
            spinner.start();
            axios.post('/admin/api/dailySftyRprt/saveDailySftyRprtData', formData).then((result) => {
                    if (result.data > 0) {
                        toast.success(messageCodeDic['001'])
                        props.setModalIsOpen(false);
                    } else {
                        toast.error(errorCodeDic['003']);
                    }
                }
            ).catch(error => {
                toast.error(errorCodeDic['003']);
                console.log('error: ', error);
            })
                .finally(() => {
                    spinner.stop();
                });
        })




    }


    const fn_retrieveApprUserDataList = (detailData: any) => {

        console.log('detailData: ', detailData);
        let param = {}
        if(detailData.siteType == 'T002004'){
            param = {
                isHeadAppr: true
            }
        }else{
            param = {
                cmpnySeq: detailData.cmpnySeq,
                isApprMngr: true,
                siteId: props.siteId
            }
        }

        restapi.retrieveDailySftyRprtApprInfo({sftyRprtSeq: detailData.sftyRprtSeq}).then((apprData) => {
            const apprUserDataList = apprData.apprUserDataList;
            const refUserDataList: any = [];
            let cstmData: object[] = [];
            console.log('apprData: ', apprData);

            restapi.retrieveUserDataList(param).then((rsltData) => {
                setAllUserDataList(rsltData);
                if (apprUserDataList.length == 0 && refUserDataList.length == 0) {
                    setMngrUserDataList(rsltData);
                    rsltData = rsltData.filter((data: any) => (data.userSeq != detailData.rgstrSeq))
                    rsltData.map((data: any) => {
                        cstmData.push({
                            label: '[' + data.companyName + '] ' + data.userName + ' (' + data.chrgNm + ')',
                            value: data.userSeq
                        });
                    })
                    setRefUserList(cstmData);
                    setApprLineDataList([]);

                } else {
                    let refUserSeqList: any = [];
                    let apprUserSeqList: any = [];
                    refUserDataList.map((data: any) => {
                        refUserSeqList.push(data.userSeq)
                    })
                    apprUserDataList.map((data: any) => {
                        apprUserSeqList.push(data.userSeq)
                    })

                    setMngrUserDataList(rsltData.filter((data: any) => {
                        return !refUserSeqList.includes(data.userSeq);
                    }));

                    let cstmApprLineDataList = rsltData.filter((data: any) => {
                        return apprUserSeqList.includes(data.userSeq);
                    })

                    cstmApprLineDataList.map((cstmData: any) => {
                        apprUserDataList.map((apprData: any) => {
                            if (cstmData.userSeq == apprData.userSeq) {
                                cstmData.apprStat = apprData.apprStat;
                                cstmData.apprStatNm = apprData.apprStatNm;
                                cstmData.prcsDt = apprData.prcsDt;
                                cstmData.apprSn = apprData.apprSn;
                            }
                        })
                    })

                    // 오름차순 정렬
                    cstmApprLineDataList = cstmApprLineDataList.sort((a: any, b: any) => {
                        if (a.apprSn > b.apprSn) return 1;
                        if (a.apprSn < b.apprSn) return -1;
                        return 0;
                    });

                    setApprLineDataList(cstmApprLineDataList)

                    let csmtSelectData = rsltData.filter((data: any) => {
                        return refUserSeqList.includes(data.userSeq);
                    })


                    csmtSelectData.map((data: any) => {
                        data.label = '[' + data.companyName + '] ' + data.userName + ' (' + data.chrgNm + ')';
                        data.value = data.userSeq;
                    })


                    rsltData = rsltData.filter((data: any) => {
                        return !apprUserSeqList.includes(data.userSeq);
                    })

                    rsltData.map((data: any) => {
                        cstmData.push({
                            label: '[' + data.companyName + '] ' + data.userName + ' (' + data.chrgNm + ')',
                            value: data.userSeq
                        });
                    })

                    setRefUserList(cstmData);

                }

            })

        })


    }

    const fn_rqstAppr = () => {
        if (apprLineDataList.length == 0) {
            toast.warn('결재선을 선택해 주십시오.');
            return;
        }

        let apprUserSeqList: number[] = [];
        apprLineDataList.map((data: any, idx: number) => {
            apprUserSeqList.push(data.userSeq);
        })

        let refUserSeqList: any = [];

        if (!window.confirm('결재요청 하시겠습니까?')) {
            return;
        }

        let prcsStatCd = '';

        if (apprUserSeqList.length == 1) {
            prcsStatCd = 'S001004';
        } else {
            prcsStatCd = 'S001002';
        }

        axios.post('/admin/api/dailySftyRprt/rqstAppr', {
            sftyRprtSeq: props.detailData.sftyRprtSeq,
            apprUserSeqList: apprUserSeqList,
            refUserSeqList: refUserSeqList,
            prcsStatCd: prcsStatCd
        }).then((rslt) => {
            if (rslt.data > 0) {
                toast.success(messageCodeDic['006']);
                props.setModalIsOpen(false);
            } else {
                toast.warn(errorCodeDic['003']);
            }
        })

    }

    const fn_appr = (gb: string) => {
        let url = '';
        let param: any = {sftyRprtSeq: props.detailData.sftyRprtSeq};
        let msg = ''

        if ('appr' == gb) {
            url = '/admin/api/dailySftyRprt/approve';
            let apprUserSeqList = [];
            const myInfo = apprLineDataList.filter((data: any) => (data.userSeq == user.userSeq))[0];
            const nextInfo = apprLineDataList.filter((data: any) => (data.apprSn == (myInfo.apprSn + 1)));

            apprUserSeqList.push(myInfo.userSeq);
            if (nextInfo.length > 0) {
                apprUserSeqList.push(nextInfo[0].userSeq);
            }

            param.apprUserSeqList = apprUserSeqList;
            msg = '승인 하시겠습니까?'
        } else if ('rjct' == gb) {
            url = '/admin/api/dailySftyRprt/reject';
            param.userSeq = user.userSeq;
            msg = '반려 하시겠습니까?'
        }

        if (!window.confirm(msg)) {
            return;
        }

        axios.post(url, param).then((rslt) => {
            if (rslt.data > 0) {
                toast.success(messageCodeDic['006']);
                props.setModalIsOpen(false);
            } else {
                toast.warn(errorCodeDic['003']);
            }
        })
    }

    const fn_cancelRqst = () => {

        let apprUserSeqList: any = [];

        apprLineDataList.map((data: any) => {
            apprUserSeqList.push(data.userSeq)
        })
        if (!window.confirm('회수 하시겠습니까?')) {
            return;
        }

        axios.post('/admin/api/dailySftyRprt/cancelAppr', {
            sftyRprtSeq: props.detailData.sftyRprtSeq,
            apprUserSeqList: apprUserSeqList
        }).then((rslt) => {
            if (rslt.data > 0) {
                toast.success(messageCodeDic['006']);
                props.setModalIsOpen(false);
            } else {
                toast.warn(errorCodeDic['003']);
            }
        })
    }

    const fn_deleteDailySftyRprtData = () => {

        if (!window.confirm('삭제 하시겠습니까?')) {
            return;
        }
        axios.post('/admin/api/dailySftyRprt/deleteDailySftyRprtData', {sftyRprtSeq: props.detailData.sftyRprtSeq}).then((rslt) => {
            if (rslt.data > 0) {
                toast.success(messageCodeDic['006']);
                props.setModalIsOpen(false);
            } else {
                toast.warn(errorCodeDic['003']);
            }
        })
    }


    const fn_onIsCrctValChg = (data: any, idx: number, isChkd: boolean) => {

        sftyChkDataList.map((data: any, chkIdx: number) => {
            if(idx === chkIdx){
                data.isChkd = isChkd
            }
        })

        setSftyChkDataList([].concat(sftyChkDataList));
    }

    const fn_openCmpnySrchModal = () => {
        setIsCmpnyModalOpen(true);
    }

    return (
        <div>
            <ApprLineModal setModalIsOpen={setIsApprLineModalOpen} modalIsOpen={isApprLineModalOpen}
                           mngrUserDataList={mngrUserDataList}
                           apprLineDataList={apprLineDataList} cmpnySeq={data?.cmpnySeq} rgstrSeq={data?.rgstrSeq}
                           setApprLineDataList={setApprLineDataList} siteId={props.siteId}/>
            <CmpnySrchModal setModalIsOpen={setIsCmpnyModalOpen} modalIsOpen={isCmpnyModalOpen}
                            cmpnyDataList={cmpnyDataList} setCmpnyDataList={setCmpnyDataList} siteId={props.siteId}
                            />


            <Modal className="bmc-modal" size="xl" show={props.modalIsOpen} onHide={fn_closeModal}
                   onShow={fn_onAfterOpen}>
                <Modal.Header>
                    <div className="d-flex justify-content-between align-items-center w-100 bmc-font-SD">
                        일일안전일보 등록
                        <IoMdClose className='hover:cursor-pointer' onClick={fn_closeModal}/>
                    </div>
                </Modal.Header>
                <Modal.Body className="px-4">

                    <form name={'rgstForm'} id={'rgstForm'} encType="multipart/form-data" method={"post"}
                          onSubmit={submit} ref={formRef}>
                        <BMCInput type={'hidden'} value={data?.siteId} name={'siteId'}
                                 onChange={fn_setFormData}></BMCInput>

                        {(props.detailData && props.detailData.prcsStatCd) &&
                            <div className="bmc-modal-div">
                                <div className="div-header bmc-fw-5">
                                    <div className={'min-w-[100px] font-semibold'}>
                                        결재 정보
                                    </div>
                                    <div className='w-100 d-flex justify-content-end align-items-center mb-2'>

                                        {props.detailData.prcsStatCd == 'S001001' && props.detailData?.rgstrSeq == user.userSeq &&
                                            <>
                                                <BMCIconButton kind={'normal'} marginRight={'5px'} onClick={() => {
                                                    setIsApprLineModalOpen(true)
                                                }}> 결재선
                                                </BMCIconButton>
                                                <BMCIconButton kind={'normal'} marginRight={'5px'}
                                                              onClick={fn_rqstAppr}> 결재요청
                                                </BMCIconButton>
                                            </>
                                        }
                                        {
                                            apprLineDataList.filter((data: any) => (data.apprStat == 'S002001'))[0]?.userSeq == user.userSeq &&
                                            <>
                                                <button className={'bmc-btn mr-5 btn-primary'} onClick={() => {
                                                    fn_appr('appr')
                                                }}> 승인
                                                </button>
                                                <button className={'bmc-btn mr-5 btn-danger'} onClick={() => {
                                                    fn_appr('rjct')
                                                }}> 반려
                                                </button>
                                            </>
                                        }

                                        {(props.detailData.prcsStatCd == 'S001002' && (props.detailData?.rgstrSeq == user.userSeq || apprLineDataList.filter((data: any) => (data.userSeq == user.userSeq)).length > 0)) &&
                                            <BMCIconButton kind={'normal'} marginRight={'5px'}
                                                          onClick={fn_cancelRqst}> 회수
                                            </BMCIconButton>
                                        }

                                        {(['S001001', 'S001002'].includes(props.detailData.prcsStatCd) && props.detailData?.rgstrSeq == user.userSeq) &&

                                            <BMCIconButton kind={'delete'} marginRight={'5px'}
                                                          onClick={fn_deleteDailySftyRprtData}> 삭제
                                            </BMCIconButton>
                                        }


                                    </div>


                                </div>

                                <div className="div-body px-3">
                                    <div className='w-100 d-flex justify-content-end align-items-center mb-2'>

                                    </div>
                                    <div className="w-100">
                                        {apprLineDataList.length > 0 ? <ApprCardList items={apprLineDataList}/> :
                                            <span>※ 결재정보를 등록해 주십시오.</span>
                                        }

                                    </div>

                                </div>
                            </div>
                        }
                        <div className="bmc-modal-div">
                            <div className="div-header">
                                기본정보
                            </div>
                            <div className="div-body px-3">
                                <Table className="my-2 align-middle">
                                    <tbody>
                                    {data?.siteName &&
                                        <tr className="border-top align-middle">
                                            <th className="bmc-bg-th" style={{width: '15%'}}>공사명</th>
                                            <td style={{width: '85%'}} colSpan={3}>
                                                <input className={"form-control"}
                                                       value={data?.siteName} readOnly={true}/>
                                            </td>
                                        </tr>
                                    }

                                    <tr className="border-top align-middle">
                                        <th className="bmc-bg-th" style={{width: '15%'}}>작성자</th>
                                        <td style={{width: '35%'}}>
                                            <input className={"form-control"}
                                                   value={data?.rgstrNm || user.userName} readOnly={true}/>
                                        </td>
                                        <th className="bmc-bg-th" style={{width: '15%'}}>점검일</th>
                                        <td style={{width: '35%'}} ref={prodRef}>
                                            <BMCDatePicker
                                                className={"text-center w-100"} onClick={fn_datePopupOpen}
                                                value={chkDt} name={'chkDt'} onChange={fn_setFormData}
                                                calendar={fn_datePopupOpen}
                                                readOnly={true}/>


                                            {isDatePopupOpen
                                                ? <CustomDatePicker setIsDatePopupOpen={setIsDatePopupOpen}
                                                                    prodRef={prodRef}
                                                                    setDateView={setChkDt}
                                                />
                                                : null}
                                        </td>


                                    </tr>
                                    <tr>
                                        <th className="bmc-bg-th" style={{width: '15%'}}>계약상대자</th>
                                        <td style={{width: '35%'}}>
                                            <div className="d-flex justify-content-between align-items-center">
                                            <BMCInput type={'text'} readOnly={true}
                                                     className={'form-control'} name={'cmpnyNms'}
                                                     value={data?.cmpnyNms}/>

                                            <button className="bmc-btn btn-secondary min-w-[70px] ml-5"
                                                    type="button" onClick={() => fn_openCmpnySrchModal()}>검색
                                            </button>
                                            </div>

                                        </td>
                                        <th className="bmc-bg-th" style={{width: '15%'}}>추진공종</th>
                                        <td style={{width: '35%'}}>
                                            <BMCInput type={'text'} className={'form-control'} name={'prplWrk'} onChange={fn_setFormData}
                                                      value={data?.prplWrk}/>
                                        </td>
                                    </tr>
                                    </tbody>
                                </Table>
                            </div>
                        </div>

                        <div className="bmc-modal-div">
                            <div className="div-header">
                                <div>자율점검 항목 리스트</div>

                            </div>
                            <div className="div-body px-3">
                                <Table className="sub my-2 text-center">
                                    <thead>
                                    <tr className="table-secondary">
                                        <th className="align-middle w-[10%]">No</th>
                                        <th className="align-middle w-[60%]">자율점검 항목</th>
                                        <th className="align-middle w-[30%]">확인 결과</th>
                                    </tr>
                                    </thead>
                                    <tbody>
                                    {
                                        sftyChkDataList?.length > 0 ?
                                            sftyChkDataList.map((data: any, idx: number) => (
                                                <tr key={idx}>
                                                    <td>{idx + 1}</td>
                                                    <td className={'align-content-start'}>
                                                        {data.chkCodeNm}
                                                    </td>
                                                    <td className={'align-content-start'}>
                                                        <BMCRadioGroup>
                                                            <BMCRadio name={"isChkd" + idx} value={true} checked={data.isChkd}  onClick={() => fn_onIsCrctValChg(data, idx, true)} onChange={()=> (null)}>
                                                                <span>있음</span>
                                                            </BMCRadio>
                                                            <BMCRadio name={"isChkd" + idx} className={'ml-10'} value={false} checked={!data.isChkd}  onClick={() => fn_onIsCrctValChg(data, idx, false)} onChange={()=> (null)}>
                                                                <span>없음</span>
                                                            </BMCRadio>
                                                        </BMCRadioGroup>
                                                    </td>

                                                </tr>
                                            ))


                                            : <tr>
                                                <td colSpan={3}>
                                                    <div className="text-center">
                                                        등록된 자율점검 항목이 없습니다.
                                                    </div>
                                                </td>
                                            </tr>
                                    }

                                    </tbody>
                                </Table>
                            </div>
                        </div>

                        {isShowAtchFile &&
                        <div className="bmc-modal-div">
                            <div className="div-header">
                                <div>특이사항 *특이사항 발생 시, 별도서식에 따라 작성‧보고</div>
                                {!props.isReadOnly &&
                                    ((['S001001', 'S001002'].includes(props.detailData?.prcsStatCd) && props.detailData?.rgstrSeq == user.userSeq)
                                        || !props.detailData?.prcsStatCd)
                                    &&
                                <BMCIconButton kind={'normal'} marginRight={'5px'} onClick={() => {
                                    window.location.href = process.env.REACT_APP_FILE_URL + "/bmcss-data/fileDownload?attachSeq=" + docFormData.atchFileId + '&sn=' + docFormData.sn + '&isPdf=0'
                                }}> 양식 다운로드
                                </BMCIconButton>
                                }
                            </div>
                            <div className="div-body px-3">
                                <BMCFile fileName={'atchFileList'} fileList={atchFileList} maxFileCnt={1}
                                         setFileList={setAtchFileList} disabled={['S001003','S001004'].includes(props.detailData?.prcsStatCd) ? true : false}></BMCFile>

                            </div>
                        </div>
                        }

                    </form>
                </Modal.Body>
                <Modal.Footer className="py-2">
                    <div className='flex justify-center w-100'>
                        <button
                            className='bmc-btn pill l white me-3'
                            onClick={fn_closeModal}>닫기
                        </button>
                        {!props.isReadOnly &&
                            ((['S001001', 'S001002'].includes(props.detailData?.prcsStatCd) && props.detailData?.rgstrSeq == user.userSeq)
                                || !props.detailData?.prcsStatCd)
                            &&
                            <button onClick={fn_registData}
                                    className='bmc-btn pill p'>저장
                            </button>
                        }

                    </div>
                </Modal.Footer>
            </Modal>
        </div>
    )
}

export default DailySftyRprtRgstModal;

