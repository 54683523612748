import React, {useContext, useEffect, useRef, useState} from 'react';
import axios from "axios";
import Datatables, {getCheckedItems} from "../component/Datatables";
import Modal from 'react-modal';
import {getLoginUser} from "../../helper/SecurityHelper";
import {toast} from "react-toastify";
import messageCodeDic from "../../util/messageCodeDic";
import errorCodeDic from "../../util/errorCodeDic";
import BMCSelect from "../../components/common/BMCSelect";
import {CmmnFn} from "../../helper/CmmnHelper";
import {ProgressContext} from "../../context/Progress";
import SHSearch from '../component/BMCSearch';
import {Col, Container, Row, Table} from 'react-bootstrap';
import SubMenuTitle from "../component/SubMenuTitle";
import BMCIconButton from '../../components/common/BMCIconButton';
import BMCDatePicker from "../../components/common/BMCDatePicker";
import CustomDatePickerRange from "../component/ui/DatePickerRange";
import SiteSftyChkRgstModal from "./modal/SiteSftyChkRgstModal";
import SiteSftyChkRsltModal from "./modal/SiteSftyChkRsltModal";
import {TopMenuContext} from "../../context/TopMenu";
import BMCInput from "../../components/common/BMCInput";
import BMCDatePicker2 from "../../components/common/BMCDatePicker2";
import {DateHelper} from "../../helper/DateHelper";

type IProps = {
};

Modal.setAppElement('#root');
const SiteSftyChkPrvntPage = (props: IProps) => {
    const [dataList, setDataList] = useState<any[]>([]);
    const [modalIsOpen, setModalIsOpen] = React.useState(false);
    const [rsltModalIsOpen, setRsltModalIsOpen] = React.useState(false);
    const [detailData, setDetailData] = useState(null);
    const [srchData, setSrchData] = useState<any>()
    const {spinner} = useContext(ProgressContext);
    const searchFormRef = useRef<any>();
    const [datatable, setDatatable] = useState<any>();
    const [startDate, setStartDate] = useState<Date>(DateHelper.dateByMonth(-3));
    const [endDate, setEndDate] = useState<Date>(DateHelper.dateByMonth(0));
    const [isDatePopupOpen, setIsDatePopupOpen] = useState(false);
    const user = getLoginUser();
    const prodRef = useRef<any>();
    const [prcsGb, setPrcsGb] = useState<any>(null);
    const {menu} = useContext<any>(TopMenuContext);
    const [isRefresh, setIsRefresh] = useState<any>(null);

    useEffect(() => {
        if (datatable && srchData) {
            fn_search();
        }
    }, [datatable, srchData])

    useEffect(() => {
        if (isRefresh) {
            datatable?.draw();
        }
    }, [isRefresh])


    const fn_setSearchData = (e: any) => {
        setSrchData({
            ...srchData,
            [e.target.name]: e.target.value
        })
    }

    const fn_search = (e?: any) => {
        if (e) {
            e.preventDefault()
        }
        datatable.draw();
    }

    const fn_delete = () => {
        let checkedData = getCheckedItems(datatable,"sftyChkSeq")
        if (checkedData.length == 0) {
            toast.warn(messageCodeDic['005']);
            return;
        }
        if(!window.confirm(messageCodeDic['004'])){
            return;
        }
        axios.post('/admin/api/siteSftyManage/deleteSftyWrkMetData', {delList: checkedData}).then(rsltData => {
            if (rsltData.data > 0) {
                toast.success(messageCodeDic['002'])
                fn_search();
            } else {
                toast.error(errorCodeDic['003'])
            }
        }).catch(() => {
            toast.error(errorCodeDic['003'])
        })
    }

    const fn_registPopOpen = () => {
        setPrcsGb(null);
        setDetailData(null);
        setModalIsOpen(true);
    }

    const fn_updateViewCnt = (param: object) => {
        axios.post('/admin/api/notice/updateViewCnt', param)
    }

    const fn_datePopupOpen = () => {
        setIsDatePopupOpen(!isDatePopupOpen);
    };

    function dateFormat(addMonth: number) {
        let today = new Date();

        let paramMonth = today.getMonth() + addMonth;
        paramMonth = (paramMonth == 0 ? 1 : paramMonth);

        today.setMonth(paramMonth);
        let month = today.getMonth();
        let day = today.getDate();
        let monthFormat = month >= 10 ? month : '0' + month;
        let dayFormat = day >= 10 ? day : '0' + day;

        return today.getFullYear() + '-' + monthFormat + '-' + dayFormat ;
    }

    return (
        <Container fluid className="bg-white px-0 bmc-txt-color-primary">
            <SubMenuTitle title={'안전전담부서 주관'} parentMenu={'안전 점검관리'}/>
            <SiteSftyChkRgstModal setModalIsOpen={setModalIsOpen} modalIsOpen={modalIsOpen} prcsGb={prcsGb}
                                 detailData={detailData} sprvDprtGb={'C006001'} setIsRefresh={setIsRefresh}/>
            <SiteSftyChkRsltModal setModalIsOpen={setRsltModalIsOpen} modalIsOpen={rsltModalIsOpen}
                                  detailData={detailData} setIsRefresh={setIsRefresh} />

            <div className="p-4 overflow-y-auto" style={{height: 'calc(100vh - 197px)'}}>
                <SHSearch datatable={datatable} formRef={searchFormRef} btnType={'p'}>
                    <BMCInput type={'hidden'} name={'sprvDprtGb'} value={'C006001'}></BMCInput>
                    <Row>
                        <Col>
                            <Table className="bmc-font-SP bmc-fs-b2 my-3">
                                <tbody>
                                <tr className="border-top align-middle border-bottom">
                                    <th className="bmc-bg-th" style={{width: '6%'}}>점검종류</th>
                                    <td style={{width: '19%'}}>
                                        <BMCSelect initOptionGb={'all'} className={'form-select'} name={'chkGbCd'}
                                                   codeGb={'cmmn'} codeGrpId={'T005'}
                                                   onChange={fn_setSearchData}
                                                   value={srchData?.chkGbCd}></BMCSelect>
                                    </td>
                                    <th className="bmc-bg-th" style={{width: '6%'}}>공종</th>
                                    <td style={{width: '19%'}}>
                                        <BMCSelect initOptionGb={'all'} className={'form-select'} name={'workTypeCd'}
                                                   codeGb={'cmmn'} codeGrpId={'T002'}
                                                   onChange={fn_setSearchData}
                                                   value={srchData?.workTypeCd}></BMCSelect>
                                    </td>
                                    <th className="bmc-bg-th" style={{width: '6%'}}>진행상황</th>
                                    <td style={{width: '19%'}}>
                                        <BMCSelect initOptionGb={'all'} className={'form-select'} name={'sftyChkGbCd'}
                                                   codeGb={'cmmn'} codeGrpId={'C003'}
                                                   onChange={fn_setSearchData}
                                                   value={srchData?.sftyChkGbCd}></BMCSelect>
                                    </td>
                                    <th className="bmc-bg-th" style={{width: '6%'}}>점검일시</th>
                                    <td style={{width: '19%'}}>
                                        <div className="d-flex align-items-center w-100" ref={prodRef}>
                                            <BMCDatePicker2 name={'startSftyChkDt'} defaultValue={startDate}
                                                            onChange={fn_setSearchData}/>
                                            <span>&nbsp;~&nbsp;</span>
                                            <BMCDatePicker2 name={'endSftyChkDt'} defaultValue={endDate}
                                                            onChange={fn_setSearchData}/>
                                        </div>
                                    </td>
                                </tr>
                                </tbody>
                            </Table>
                        </Col>
                    </Row>
                </SHSearch>
                {user.authCode != 'headRead' &&
                    <div className='w-100 d-flex justify-content-end align-items-center mb-2'>

                        <BMCIconButton kind={'submit'} marginRight={'5px'} onClick={fn_registPopOpen}> 등록
                        </BMCIconButton>

                        <BMCIconButton kind={'delete'} marginRight={'0'} onClick={fn_delete}> 삭제
                        </BMCIconButton>
                    </div>
                }

                <div className={'ge-result'}>
                    <Datatables
                        dataUrl={'/admin/api/siteSftyManage/retrieveSiteSftyManageDataList'}
                        parameterFormRef={searchFormRef}
                        columns={[

                            {data: "chkGbNm", title: "점검종류"},
                            {data: "workTypeNm", title: "공종"},
                            {data: "sftyChkDt", title: "점검일시", render: (data: any, type: any, row: any) => {
                                    return row.sftyChkDt + "&nbsp;&nbsp;" + row.sftyChkHour + ':' + row.sftyChkMin;
                                }},
                            {data: "siteName", title: "점검현장"},
                            {data: "siteAddress", title: "현장주소"},
                            {data: "sftyChkGbNm", title: "진행상황"},
                            {data: '', title: '점검결과등록', render: (data: any, type: any, row: any) => {
                                    if(row.sftyChkGbNm == '현장조치중'){
                                        return "<div class='bmc-box-status approvaldone'><button>확인</button></div>";
                                    }else if(row.sftyChkGbNm == '결재요청 대기중'){
                                        return "<div class='bmc-box-status request'><button>결재요청</button></div>";
                                    }else if(row.sftyChkGbNm == '결재중'){
                                        return "<div class='bmc-box-status approval'><button>결재</button></div>";
                                    }else if(row.sftyChkGbNm == '최종확인 대기중'){
                                        return "<div class='bmc-box-status approval'><button>최종확인</button></div>";
                                    }
                                    else{
                                        return "<div class='bmc-box-status approvaldone'><button>확인</button></div>";
                                    }

                                }},
                        ]}
                        checked={true}
                        index={true}

                        onLoad={(dt: any) => {
                            setDatatable(dt)
                        }}
                        onClick={(data, gb) => {

                            if(data.sftyChkGbNm == '현장조치중') {
                                setPrcsGb('actRgst');
                            } else {
                                setPrcsGb('rsltRgst');
                            }
                            setDetailData(data)
                            setModalIsOpen(true);

                            /* if(gb == 'btn'){
                                 console.log('btn data: ', data);
                                 setPrcsGb('rsltRgst');
                                 setDetailData(data)
                                 setModalIsOpen(true);
                             }else{
                                 if(["C003001", "C003002"].includes(data.sftyChkGbCd)) {
                                     setDetailData(data)
                                     setModalIsOpen(true);
                                 }
                             }
     */
                            //점검예정일 경우 수정팝업 호출


                        }
                        }
                    />
                </div>
            </div>
        </Container>
    );
};

export default SiteSftyChkPrvntPage;
