import React, {useContext, useEffect, useRef, useState} from "react";
import {Container, Row, Col, Table} from "react-bootstrap";
import {ReactComponent as IconRight} from "../../assets/images/ico/icon-right.svg";
import {ArcElement, Chart as ChartJS, Tooltip} from "chart.js";
import {Doughnut} from "react-chartjs-2";
import {ReactComponent as IconTeam} from "../../assets/images/ico/icon-team.svg";
import {ReactComponent as IconPerson} from "../../assets/images/ico/icon-person.svg";
import {ReactComponent as IconTeamOut} from "../../assets/images/ico/icon-teamout.svg";
import {UserContext} from "../../context/User";
import {getLoginUser} from "../../helper/SecurityHelper";
import restapi from "../../helper/restAPIHelper";
import {toast} from "react-toastify";
import errorCodeDic from "../../util/errorCodeDic";
import WrkrInfoListModal from "./modal/WrkrInfoListModal";
import {useNavigate} from "react-router-dom";
import {MenuOpenContext} from "../../context/MenuOpen";
import {IntervalContext} from "../../context/Interval";

ChartJS.register(ArcElement, Tooltip);

const DashTotalDepartureStatus = () => {
    const {open} = useContext(MenuOpenContext);
    const {user} = useContext<any>(UserContext);
    const defaultUser = getLoginUser();
    const chartRef1 = useRef<any>();
    const chartRef2 = useRef<any>();
    const [data, setData] = useState<any>({totEtrncCnt:0, curCnt:0, outCnt:0})
    const [modalIsOpen, setModalIsOpen] = React.useState(false);
    const [title, setTitle] = useState<string>('');
    const [wrkrDataList, setWrkrDataList] = useState<any[]>([]);
    const navigate = useNavigate();
    const {interval} = useContext<any>(IntervalContext);
    const [employeeData, setEmployeeData] = useState<any>({
        labels: ['중점관리구역', '중점관리구역 외', '고령자','-'],
        datasets: [
            {
                data: [75, 320, 50,1],
                backgroundColor: [
                    'rgba(86, 133, 247, 1)',
                    'rgba(74, 198, 255, 1)',
                    'rgba(211, 154, 255, 1)',
                    '#EEEEEE',
                ],
                borderColor: [
                    'rgba(86, 133, 247, 1)',
                    'rgba(74, 198, 255, 1)',
                    'rgba(211, 154, 255, 1)',
                    'rgba(255, 255, 255, 1)',
                ],
                borderWidth: 1,
                hoverOffset: 10,
            }
        ]
    });
    const [managerData, setManagerData] = useState<any>({
        labels: ['중점관리구역', '중점관리구역 외', '고령자','-'],
        datasets: [
            {
                data: [4, 20, 0,1],
                backgroundColor: [
                    'rgba(86, 133, 247, 1)',
                    'rgba(74, 198, 255, 1)',
                    'rgba(211, 154, 255, 1)',
                    '#EEEEEE',
                ],
                borderColor: [
                    'rgba(86, 133, 247, 1)',
                    'rgba(74, 198, 255, 1)',
                    'rgba(211, 154, 255, 1)',
                    'rgba(255, 255, 255, 1)',
                ],
                borderWidth: 1,
                hoverOffset: 5,
            }
        ]
    })
    useEffect(() => {
        chartRef1.current.resize()
        chartRef2.current.resize()
    },[open])
    useEffect(() => {
        const siteId = user?.blngSiteId || defaultUser.blngSiteId;
        restapi.retrieveTotEtrncStatInfo({siteId: siteId, qrNfcTpCd: 'T003001'}).then((rsltData: any) => {
            console.log('wrkrRsltData: ', rsltData);
            const wrkrTotDataList = rsltData.wrkrTotDataList;
            const wrkrDprtDataList = rsltData.wrkrDprtDataList;
            const wrkrVulnarableDataList = rsltData.wrkrVulnarableDataList;
            const mngrTotDataList = rsltData.mngrTotDataList
            const mngrDprtDataList = rsltData.mngrDprtDataList
            const mngrVulnarableDataList = rsltData.mngrVulnarableDataList;


            setEmployeeData(
                {
                    labels: ['중점관리구역', '중점관리구역 외', '고령자', '-'],
                    datasets: [
                        {
                            data: [wrkrDprtDataList.length, wrkrTotDataList.length - wrkrDprtDataList.length, wrkrVulnarableDataList.length,1],
                            backgroundColor: [
                                'rgba(86, 133, 247, 1)',
                                'rgba(74, 198, 255, 1)',
                                'rgba(211, 154, 255, 1)',
                                '#EEEEEE',
                            ],
                            borderColor: [
                                'rgba(86, 133, 247, 1)',
                                'rgba(74, 198, 255, 1)',
                                'rgba(211, 154, 255, 1)',
                                'rgba(255, 255, 255, 1)',
                            ],
                            borderWidth: 1,
                            hoverOffset: 10,
                        }
                    ]
                }
            )

            setManagerData({
                labels: ['중점관리구역', '중점관리구역 외', '고령자', '-'],
                datasets: [
                    {
                        data: [mngrDprtDataList.length, mngrTotDataList.length - mngrDprtDataList.length, mngrVulnarableDataList.length,1],
                        backgroundColor: [
                            'rgba(86, 133, 247, 1)',
                            'rgba(74, 198, 255, 1)',
                            'rgba(211, 154, 255, 1)',
                            '#EEEEEE',
                        ],
                        borderColor: [
                            'rgba(86, 133, 247, 1)',
                            'rgba(74, 198, 255, 1)',
                            'rgba(211, 154, 255, 1)',
                            'rgba(255, 255, 255, 1)',
                        ],
                        borderWidth: 1,
                        hoverOffset: 5,
                    }
                ]
            })
            rsltData.statTotWrkrCnt = wrkrTotDataList.length;
            rsltData.statTotMngrCnt = mngrTotDataList.length;

            setData(rsltData);

        }).catch(error => {
            toast.error(errorCodeDic['003']);
            console.log('error: ', error);
        })
            .finally(() => {
            });
    }, [user, interval])

    const options = {
        responsive: true,
        plugins: {
            legend: {
                display: false,
            }
        },
        rotation: -90,
        circumference: 180,
        cutout: '70%',
        backgroundColor: '#f2f7ff'
    };

    const fn_openWrkrInfoModal = (srchGb: string) => {
        if(srchGb == 'tot'){
            setWrkrDataList(data.totWrkrList);
            setTitle('총 출역')
        }else if(srchGb == 'crnt'){
            setWrkrDataList(data.curWrkrList);
            setTitle('현재 인원')
        }else if(srchGb == 'out'){
            setWrkrDataList(data.outWrkrList);
            setTitle('OUT 인원')
        }else if(srchGb == 'wrkr'){
            setWrkrDataList(data.wrkrTotDataList);
            setTitle('근로자 출역 현황')
        }else if(srchGb == 'mngr'){
            setWrkrDataList(data.mngrTotDataList);
            setTitle('관리자 출역 현황')
        }
        setModalIsOpen(true);
    }

    return (
        <>
            <Container fluid className="bmc-bg-pane rounded-[8px]">
                <WrkrInfoListModal setModalIsOpen={setModalIsOpen} modalIsOpen={modalIsOpen}  wrkrDataList={wrkrDataList} siteId={user?.blngSiteId || defaultUser.blngSiteId} title={title} ></WrkrInfoListModal>
                <Row className="px-0">
                    <Col bsPrefix="col-7" className="w-100">
                        <div className="w-100 d-flex align-items-center justify-content-between pb-2 border-bottom">
                            <span className="pe-2 bmc-txt-size-h4">총 출역현황</span>
                            <div className={'cursor-pointer'} onClick={() => navigate('/manPowerManagement/departureStatusManagementPage')}>
                                <IconRight style={{width: '15px', marginBottom: '0.5px', stroke: '#1d1b39'}} />
                            </div>
                        </div>
                    </Col>
                </Row>
                <Row className="px-0 pt-3">
                    <Col className="px-0">
                        <Container fluid>
                            <Row className="px-0">
                                <Col className="px-0">
                                    <div style={{backgroundColor: '#ecf8ff', borderRadius: '16px', padding: '5px 0'}}>
                                        <Container fluid className="px-0 cursor-pointer" onClick={() => fn_openWrkrInfoModal('tot')}>
                                            <Row className="py-1">
                                                <Col>
                                                    <div className="d-flex justify-content-start align-items-center px-2 w-100">
                                                        <div
                                                            className="d-flex justify-content-center bg-white me-2"
                                                            style={{width: '35px', height: '35px', borderRadius: '8px', marginLeft: '2px'}}>
                                                            <IconTeam className="cursor-pointer" style={{width: '25px', fill: '#35c1ff'}}/>
                                                        </div>
                                                        <div className="bmc-txt-size-b6">
                                                            <div>총 출역</div>
                                                            <div>
                                                                <span className="bmc-txt-size-h3 me-1">{data.totEtrncCnt}</span>
                                                                <span>명</span>
                                                            </div>
                                                        </div>
                                                    </div>
                                                </Col>
                                            </Row>
                                        </Container>
                                    </div>
                                </Col>
                                <Col className="px-1">
                                    <div style={{backgroundColor: '#eff3ff', borderRadius: '16px', padding: '5px 0'}}>
                                        <Container fluid className="px-0 cursor-pointer" onClick={() => fn_openWrkrInfoModal('crnt')}>
                                            <Row className="py-1">
                                                <Col>
                                                    <div className="d-flex justify-content-start align-items-center px-2 w-100">
                                                        <div
                                                            className="d-flex justify-content-center bg-white me-2"
                                                            style={{width: '35px', height: '35px', borderRadius: '8px', marginLeft: '2px'}}>
                                                            <IconPerson className="cursor-pointer" style={{width: '25px', fill: '#4c5eff'}}/>
                                                        </div>
                                                        <div className="bmc-txt-size-b6">
                                                            <div>현재 인원</div>
                                                            <div>
                                                                <span className="bmc-txt-size-h3 me-1">{data.curCnt}</span>
                                                                <span>명</span>
                                                            </div>
                                                        </div>
                                                    </div>
                                                </Col>
                                            </Row>
                                        </Container>
                                    </div>
                                </Col>
                                <Col className="px-0">
                                    <div style={{backgroundColor: '#fff5f6', borderRadius: '16px', padding: '5px 0'}}>
                                        <Container fluid className="px-0 cursor-pointer" onClick={() => fn_openWrkrInfoModal('out')}>
                                            <Row className="py-1">
                                                <Col>
                                                    <div className="d-flex justify-content-start align-items-center px-2 w-100">
                                                        <div
                                                            className="d-flex justify-content-center bg-white me-2"
                                                            style={{width: '35px', height: '35px', borderRadius: '8px', marginLeft: '2px'}}>
                                                            <IconTeamOut className="cursor-pointer" style={{width: '25px', fill: '#ff718b'}}/>
                                                        </div>
                                                        <div className="bmc-txt-size-b6">
                                                            <div>OUT 인원</div>
                                                            <div>
                                                                <span className="bmc-txt-size-h3 me-1">{data.outCnt}</span>
                                                                <span>명</span>
                                                            </div>
                                                        </div>
                                                    </div>
                                                </Col>
                                            </Row>
                                        </Container>
                                    </div>
                                </Col>
                            </Row>
                            <Row>
                                <Col bsPrefix={"col-6"}>
                                    <div onClick={() => fn_openWrkrInfoModal('wrkr')} className="cursor-pointer d-flex position-relative justify-content-center w-100">
                                        <Doughnut className="position-relative z-50" options={options}
                                                  data={employeeData} width="100%" height="100%" ref={chartRef1} />
                                        <div className="position-absolute w-100 text-center z-10 left-0" style={{bottom:"20px"}}>
                                            <div className="bmc-txt-size-b5">근로자</div>
                                            <div>
                                                <span className="bmc-txt-size-h3 me-1">{data.statTotWrkrCnt}</span>
                                                <span className="bmc-txt-size-b6 bmc-text-font_secondary02">명</span>
                                            </div>
                                        </div>
                                    </div>
                                </Col>
                                <Col bsPrefix={"col-6"}>
                                    <div onClick={() => fn_openWrkrInfoModal('mngr')} className="cursor-pointer d-flex position-relative justify-content-center w-100">
                                        <Doughnut className="position-relative z-50" options={options}
                                                  data={managerData} width="100%" height="100%" ref={chartRef2}/>
                                        <div className="position-absolute w-100 text-center z-10 left-0" style={{bottom:"20px"}}>
                                            <div className="bmc-txt-size-b5">관리자</div>
                                            <div>
                                                <span className="bmc-txt-size-h3 me-1">{data.statTotMngrCnt}</span>
                                                <span className="bmc-txt-size-b6 bmc-text-font_secondary02">명</span>
                                            </div>
                                        </div>
                                    </div>
                                </Col>
                            </Row>
                            <Row className="pb-2">
                                <Col className="d-flex justify-content-center w-100 bmc-txt-size-b7">
                                    <div>
                                        <span className="pe-1 bmc-txt-color-01">●</span>
                                        <span className="pe-1">중점관리구역</span>
                                    </div>
                                    <div className="px-2">
                                        <span className="pe-1 bmc-txt-color-02">●</span>
                                        <span className="pe-1">중점관리구역 외</span>
                                    </div>
                                    <div>
                                        <span className="pe-1 bmc-txt-color-03">●</span>
                                        <span className="pe-1">고령자</span>
                                    </div>
                                </Col>
                            </Row>
                        </Container>
                    </Col>
                </Row>
            </Container>
        </>
    );
};

export default DashTotalDepartureStatus;

