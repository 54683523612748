import React, {useContext, useEffect, useRef, useState} from 'react';
import axios from "axios";
import Datatables, {getCheckedItems} from "../component/Datatables";
import Modal from 'react-modal';

import {MultiSelect} from '@grapecity/wijmo.react.input';
import {getLoginUser} from "../../helper/SecurityHelper";
import {toast} from "react-toastify";
import messageCodeDic from "../../util/messageCodeDic";
import errorCodeDic from "../../util/errorCodeDic";

import CmpnyInfoRgstModal from "../companyManagement/modal/CmpnyInfoRgstModal";
import MngrRgstModal from "./modal/MngrRgstModal";
import {ClipLoader} from 'react-spinners';
import {ProgressContext} from '../../context/Progress';
import SHSearch from '../component/BMCSearch';
import {Col, Container, Table, Row} from 'react-bootstrap';
import SubMenuTitle from "../component/SubMenuTitle";
import BMCIconButton from "../../components/common/BMCIconButton";
import {CmmnFn} from "../../helper/CmmnHelper";
import HeadMngrRgstModal from "./modal/HeadMngrRgstModal";
import BMCSelect from "../../components/common/BMCSelect";
import BMCInput from "../../components/common/BMCInput";

type Props = {};


Modal.setAppElement('#root');
const HeadMngrManagementPage = (props: Props) => {
    const [dataList, setDataList] = useState<any[]>([]);
    const [modalIsOpen, setModalIsOpen] = React.useState(false);
    const [detailData, setDetailData] = useState(null);
    const [siteCodeList, setSiteCodeList] = useState<any>([]);
    const [isSaveSuccess, setIsSaveSuccess] = useState(false);
    const [cmpnyCodeList, setCmpnyCodeList] = useState<any>([]);
    const user = getLoginUser();
    const siteIdRef = useRef<any>();
    const {spinner} = useContext(ProgressContext);
    const searchFormRef = useRef<any>();
    const [datatable, setDatatable] = useState<any>();
    const [data, setData] = useState<any>()

    useEffect(() => {
        console.log('user: ', user);
        fn_retrieveSiteCodeList().then((siteCodeList: any) => {
            setSiteCodeList(siteCodeList);

            if (siteCodeList.length == 0) {
                toast.warn('조회된 현장이 없습니다.');
                return;
            }


        })
    }, [])

    useEffect(() => {
        if (isSaveSuccess || !modalIsOpen) {
            datatable?.draw();
        }
    }, [isSaveSuccess, modalIsOpen])


    const fn_setSrchData = (e: any) => {

        setData({
            ...data,
            [e.target.name]: e.target.value
        })

    }
    const fn_delete = () => {
        let checkedData = getCheckedItems(datatable, "userSeq")

        if (checkedData.length == 0) {
            toast.warn(messageCodeDic['005']);
            return;
        }

        if (!window.confirm(messageCodeDic['004'])) {
            return;
        }

        axios.post('/admin/api/user/deleteUserData', {delList: checkedData}).then(rsltData => {
                if (rsltData.data > 0) {
                    toast.success(messageCodeDic['002'])
                    datatable?.draw();
                } else {
                    toast.error(errorCodeDic['003'])
                }

            }
        ).catch(() => {
                toast.error(errorCodeDic['003'])
            }
        )
    }


    const fn_retrieveDataList = async (param: object) => {
        console.log('param: ', param);
        return new Promise(function (resolve, reject) {
            axios.post('/admin/api/user/retrieveMngrDataList', param).then((result) => {
                resolve(result.data);
            })
        });


    }

    const fn_retrieveCmpnySiteDataList = async (siteId: string) => {
        return new Promise(function (resolve, reject) {
            axios.post('/admin/api/company/retrieveCmpnySiteDataList', {siteId: siteId}).then((result) => {
                resolve(result.data);
            })
        });
    }

    const fn_retrieveSiteCodeList = async () => {
        return new Promise(function (resolve, reject) {
            let param:any = {}

            if(user.authGrpId == 'SITE'){
                let siteIdList: any = [];
                const authChrgDataList = user.authChrgDataList;
                authChrgDataList.map((data: any) => {
                    siteIdList.push(data.siteId);
                })
                param.siteIdList = siteIdList;
            }
            axios.post('/admin/api/site/retrieveSiteCodeList', param).then((result) => {
                resolve(result.data);
            })

        });
    }

    const fn_registPopOpen = () => {
        setDetailData(null);
        setModalIsOpen(true);
    }

    const fn_updateViewCnt = (param: object) => {
        axios.post('/admin/api/notice/updateViewCnt', param)
    }

    const fn_retrieveSiteManageItemList = (siteId: string) => {
        return new Promise(function (resolve, reject) {

            axios.post('/admin/api/manage/retrieveSiteManageItemList', {
                siteId: siteId,
                manageCode: 'M001003'
            }).then((result) => {
                resolve(result.data);
            })

        });
    }


    return (
        <Container fluid className="bg-white px-0 bmc-txt-color-primary">

            <SubMenuTitle title={'본사 관리자 관리'} parentMenu={'설정'}/>
            <HeadMngrRgstModal setModalIsOpen={setModalIsOpen} modalIsOpen={modalIsOpen} siteId={siteIdRef.current?.value}
                           detailData={detailData} setIsSaveSuccess={setIsSaveSuccess}/>

            <div className="p-4 overflow-y-auto" style={{height: 'calc(100vh - 197px)'}}>
                <SHSearch datatable={datatable} formRef={searchFormRef} btnType={'p'}>
                    <input type={'hidden'} name={'authGrpId'} value={'HEAD'}/>
                    <Row>
                        <Col>
                            <Table className="bmc-font-SP bmc-fs-b2 my-3">
                                <tbody>
                                <tr className="border-top border-bottom align-middle">
                                    <th className="bmc-bg-th" style={{width:'6%'}}>성명</th>
                                    <td style={{width:'19%'}}>
                                        <BMCInput type={'text'} className={'form-control'} name={'userName'}></BMCInput>
                                    </td>
                                    <th className="bmc-bg-th" style={{width:'6%'}}>부서</th>
                                    <td style={{width:'19%'}}>
                                        <BMCSelect initOptionGb={'all'} name={'dprtCd'} codeGrpId={'D001'} className={"w-100"} codeGb={'cmmn'}
                                                   onChange={fn_setSrchData} value={data?.dprtCd}></BMCSelect>
                                    </td>

                                </tr>
                                </tbody>
                            </Table>
                        </Col>
                    </Row>
                </SHSearch>


                {['siteMstr', 'headMstr'].includes(CmmnFn.fn_getAuthForSite(user, siteIdRef.current?.value)) &&
                    <div className='w-100 d-flex justify-content-end align-items-center mb-2'>
                        <BMCIconButton kind={'submit'} marginRight={'5px'} onClick={fn_registPopOpen}>등록</BMCIconButton>
                        <BMCIconButton kind={'delete'} marginRight={'0'} onClick={fn_delete}>삭제</BMCIconButton>
                    </div>
                }

                <div className={'ge-result'}>
                    <Datatables
                        dataUrl={'/admin/api/user/retrieveMngrDataList'}
                        parameterFormRef={searchFormRef}
                        columns={[
                            {data: "userName", title: "성명"},
                            {data: "companyName", title: "업체"},
                            {data: "authCodeNm", title: "권한"},
                            {data: "position", title: "직위"},
                        ]}
                        checked={true}
                        index={true}

                        onLoad={(dt: any) => {
                            setDatatable(dt)
                        }}
                        onClick={(data) => {
                            setDetailData(data)
                            setModalIsOpen(true);
                            fn_updateViewCnt(data);
                        }
                        }
                    />
                </div>
            </div>
        </Container>


    );
};

export default HeadMngrManagementPage;
