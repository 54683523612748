import React, {Dispatch, SetStateAction, useEffect, useRef, useState} from "react";
import {COMMON_MODAL_STYLE} from "../../../helper/StyleHelper";
import {Col, Modal, Row, Table} from "react-bootstrap";
import {IoMdClose} from "react-icons/io";
import Datatables, {getCheckedItems} from "../../component/Datatables";
import axios from "axios";
import {toast} from "react-toastify";
import messageCodeDic from "../../../util/messageCodeDic";
import BMCSelect from "../../../components/common/BMCSelect";
import {getLoginUser} from "../../../helper/SecurityHelper";
import SHSearch from "../../component/BMCSearch";
import BMCInput from "../../../components/common/BMCInput";

interface IProps {
    setModalIsOpen: Dispatch<SetStateAction<boolean>>;
    modalIsOpen: boolean;
    todaywrkInfoDataList: object[];
    siteId: number;



}

const $ = require("jquery")

const TodayWrkInfoListModal = (props: IProps) => {
    const [datatable, setDatatable] = useState<any>();

    const [dataList, setDataList] = useState<any>([])


    function fn_closeModal() {
        props.setModalIsOpen(false);
    }
    const fn_onShow = () => {
        setDataList(props.todaywrkInfoDataList)
    }

    return (
        <div>

            <Modal
                className="bmc-modal" size="xl"
                show={props.modalIsOpen}
                onShow={() => {
                    fn_onShow()

                }}
                onHide={fn_closeModal}
            >


                <Modal.Header>
                    <div className="d-flex justify-content-between align-items-center w-100 bmc-font-SD">
                        {'금일 작업 사항'}
                        <IoMdClose className='hover:cursor-pointer' onClick={fn_closeModal}/>
                    </div>
                </Modal.Header>

                <Modal.Body className="px-4">

                    <div className={'ge-result'}>
                        <Datatables
                            datas={dataList}
                            scrollY="550px"
                            className="bmc-fs-b4"
                            columns={[
                                {data: "wrkTypeNm", title: "공종", textAlign: 'center'},
                                {data: "todayWrkCntnts", title: "금일 작업사항 내용", textAlign: 'center'},
                            ]}
                            checked={false}
                            onLoad={(dt: any) => {
                                setDatatable(dt)
                            }}

                            onClick={(data) => {

                            }
                            }
                        />
                    </div>
                </Modal.Body>
                <Modal.Footer className="py-2">
                    <div className='flex justify-center w-100'>
                        <button
                            className='bmc-btn pill l white me-3'
                            onClick={fn_closeModal}>닫기
                        </button>

                    </div>
                </Modal.Footer>


            </Modal>
        </div>
    )
}

export default TodayWrkInfoListModal;
