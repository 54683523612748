import {Container} from "react-bootstrap";
import React, {useContext, useEffect, useState} from "react";
import {ReactComponent as IconSiteInfoLine} from "../../assets/images/icon/icon-site-info-line.svg";
import {ReactComponent as IconSiteInfoLine2} from "../../assets/images/icon/icon-site-info-line2.svg";
import {getLoginUser, IS_SM_SITE} from "../../helper/SecurityHelper";
import {UserContext} from "../../context/User";
import restapi from "../../helper/restAPIHelper";
import axios from "axios";
import NoticeRegistModal from "../noticeManagement/modal/NoticeRegistModal";
import {useNavigate} from "react-router-dom";


export default function DashSiteMultipleInfo(){
    const [siteData, setSiteData] = useState<any>({});
    const defaultUser: any = getLoginUser();
    const {user,setUser} = useContext<any>(UserContext);
    const [noticedataList, setNoticeDataList] = useState<any>([]);
    const [modalIsOpen, setModalIsOpen] = React.useState(false);
    const [noticeDetailData, setNoticeDetailData] = useState(null);
    const navigate = useNavigate();

    useEffect(() => {
        const siteId = user?.blngSiteId || defaultUser.blngSiteId

        restapi.retrieveSiteDataListNoPaging({siteId: siteId}).then((rsltData: any) => {
            if(rsltData.length > 0){
                setSiteData(rsltData[0]);
            }
        })

        restapi.retrieveNoticeDataTotList({siteId: siteId, isValidNotice: true, isForDashBoard: true}).then((rsltData: any) => {
            setNoticeDataList(rsltData);
        })

    }, [user]);

    const fn_openNoticeModal = (data: any) => {
        setNoticeDetailData(Object.assign({}, data))
        setModalIsOpen(true);
        fn_updateViewCnt(data);
    }

    const fn_updateViewCnt = (param: object) => {
        axios.post('/admin/api/notice/updateViewCnt', param)
    }

    return(
        <Container className="px-0 bmc-txt-color-primary">
            <NoticeRegistModal setModalIsOpen={setModalIsOpen} modalIsOpen={modalIsOpen} siteId={user?.blngSiteId || defaultUser.blngSiteId}
                               noticeDetailData={noticeDetailData} />
            <div className="d-flex flex-row w-100 site-multiple-info p-2 gap-2">
                <div className="d-flex w-[150px] align-items-center justify-content-start ps-2 text-nowrap">
                    <span className="site-multiple-dday-text">Dday - </span>
                    <span className="ms-2 site-multiple-dday-value">{siteData?.remainDays}</span>
                </div>
                <IconSiteInfoLine  className="align-self-center"/>
                <div className="d-flex flex-row w-[190px] align-items-center justify-content-center gap-2">
                    <div className="d-flex flex-column">
                        <span className="site-multiple-date-text">착공일</span>
                        <span className="site-multiple-date-value">{siteData?.constructionStartDate?siteData?.constructionStartDate.replaceAll("-","."):null}</span>
                    </div>
                    <IconSiteInfoLine2 className="align-self-center"/>
                    <div className="d-flex flex-column">
                        <span className="site-multiple-date-text">준공일</span>
                        <span className="site-multiple-date-value">{siteData?.constructionEndDate?siteData?.constructionEndDate.replaceAll("-",".") : null}</span>
                    </div>
                </div>
                <IconSiteInfoLine className="align-self-center"/>
                <div className="d-flex flex-row flex-grow-1 align-items-center justify-content-start gap-2">
                    <div className="d-flex flex-column">
                        <span className="site-multiple-woker-title text-nowrap">현장소장</span>
                        <span className="site-multiple-woker-name">{siteData?.siteRepNm || '미정'}</span>
                    </div>
                    <IconSiteInfoLine2 className="align-self-center"/>
                    <div className="d-flex flex-column">
                        <span className="site-multiple-woker-title text-nowrap">감리</span>
                        <span className="site-multiple-woker-name">{siteData?.siteSprvNm || '미정'}</span>
                    </div>
                    <IconSiteInfoLine2 className="align-self-center"/>
                    <div className="d-flex flex-column">
                        <span className="site-multiple-woker-title text-nowrap">안전관리자</span>
                        <span className="site-multiple-woker-name">{siteData?.sftyMngrNm || '미정'}</span>
                    </div>
                </div>
            </div>

            <div className="d-flex pt-3 pb-2 justify-content-between">
                <span className="site-title-name">공지사항</span>
                <span className="site-notice-icon2 text-center mb-2 text-nowrap cursor-pointer" onClick={() => {navigate('/noticeManagement/noticeRegistration')}}>더보기</span>
            </div>
            <div className="row px-3">
                {noticedataList.length > 0 ?
                    noticedataList.map((data: any, idx: number) => (
                        <div className="col-6 px-2 py-2 cursor-pointer" onClick={() => fn_openNoticeModal(data)}>
                            <div className="site-notice-item px-3 py-3">
                                <div className="d-flex flex-column">
                                    <span className="site-notice-icon text-center justify-content-center mb-2">공지</span>
                                    <span className="site-notice-contents text-nowrap" title={data.title} style={{overflowX: "hidden", textOverflow:"ellipsis"}}>{data.title}</span>
                                    <span className="site-notice-date">{data.regDate}</span>
                                </div>
                            </div>
                        </div>
                    )):
                    <div className="col-12 px-0">
                        <div className="site-notice-item px-3 py-3">
                            <div className="d-flex flex-column">
                                <span className="site-notice-contents">등록된 공지사항이 없습니다</span>
                            </div>
                        </div>
                    </div>

                }

            </div>
        </Container>
    )
}
