import {Container} from "react-bootstrap";
import React from "react";
import IvstTtlChkSttsInfo from "../headoffice/IvstTtlChkSttsInfo";
import IvstTdyStChkSttsInfo from "../headoffice/IvstTdyStChkSttsInfo";
import IvstMntrngMpInfo from "../headoffice/IvstMntrngMpInfo";
import IvstStStts from "../headoffice/IvstStStts";
import HOTotalWeatherInfoV2 from "../headoffice/HOTotalWeatherInfoV2";
import HOMonthlyManagementCalendar from "../headoffice/HOMonthlyManagementCalendar";
import FcltyTtlChkSttsInfo from "../headoffice/FcltyTtlChkSttsInfo";
import FcltyWeekNotice from "../headoffice/FcltyWeekNotice";
import FcltyPlanNotice from "../headoffice/fcltyPlanNotice";
import FcltyHistoryNotice from "../headoffice/FcltyHistoryNotice";

export default function FcltyMonitoringPage() {
    return (
        <Container fluid className="px-0" style={{backgroundColor:'#0E1841'}}>
            <div className="d-flex w-100 overflow-hidden" style={{height: 'calc(100vh - 100px)'}}>
                <div className="d-flex flex-grow-1 ho-monitoring-main-left-area">
                    <div className="d-flex w-100">
                        <div className="d-flex flex-row w-100 gap-3">
                            <div className="flex-grow-1" style={{height: 'calc(100vh - 145px)', overflowY:"auto", overflowX:"hidden"}}>
                                <div className="d-flex flex-row gap-3">
                                    <div className="d-flex flex-column gap-3 w-[450px]">
                                        <div>
                                            <HOTotalWeatherInfoV2 />
                                        </div>
                                        <div className="h-[593px]">
                                            <HOMonthlyManagementCalendar/>
                                        </div>
                                    </div>
                                    <div className="d-flex flex-column gap-3 w-[550px]">
                                        <div className="d-flex">
                                            <FcltyTtlChkSttsInfo />
                                        </div>
                                        <div className="d-flex h-[593px]">
                                            <FcltyWeekNotice />
                                        </div>
                                    </div>
                                    <div className="d-flex flex-column gap-3 flex-grow-1">
                                        <div className="d-flex">
                                            <FcltyPlanNotice />
                                        </div>
                                        <div className="d-flex">
                                            <FcltyHistoryNotice />
                                        </div>
                                    </div>
                                </div>
                            </div>

                        </div>
                    </div>
                </div>
            </div>
        </Container>
    )
}
