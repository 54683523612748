import React, {Dispatch, SetStateAction, useRef} from 'react';
import {ReactComponent as IconSubmit} from '../../images/icon_submit.svg';
import {ReactComponent as IconSearch} from '../../images/icon_search.svg';
import IconSave from '../../images/icon_save.png';
import IconCopy from '../../images/icon_copy.png';
import IconDelete from '../../images/icon_delete.png';
import IconView from '../../images/icon_view.png';
import IconNotView from '../../images/icon_notview.png';
import {ReactComponent as IconAdd} from '../../images/icon-add.svg';

interface IProps {
    children?: any;
    kind: any;
    marginRight?: any;
    onClick?: Dispatch<SetStateAction<any>>;
    view?: any;
    disabled?:boolean;
    className?: string;
}

export default function BMCIconButton(props: IProps) {

    return (
        <>
            {
                props.view == null ?
                    <button type={"button"} style={{marginRight:props.marginRight}}
                            disabled={props.disabled || false}
                            className={props.className +  " bmc-btn"+
                                (props.kind == 'copy' || props.kind == 'delete' ? ' normal l' : '')+
                                (props.kind == 'save' || props.kind == 'submit' || props.kind == 'normal' ? ' normal p' : '')+
                                (props.kind == 'add' ? ' gray add' : '')
                            } onClick={props.onClick}>
                        {props.children}
                    </button>
                    :
                    <button type={"button"} style={{marginRight:props.marginRight}}
                            disabled={props.disabled||false}
                            className={props.className + " bmc-btn gray view"} onClick={props.onClick}>
                        {
                            props.view ?
                                <><img src={IconView} style={{width:'16px', height:'12px'}} alt="아이콘" /> 수기입력 보이기</> :
                                    <><img src={IconNotView} style={{width:'16px', height:'12px'}} alt="아이콘" />  수기입력 숨기기</>
                        }
                    </button>
            }

        </>
    );
}

