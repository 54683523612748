import React, {Dispatch, SetStateAction, useEffect, useRef, useState} from "react";
import {COMMON_MODAL_STYLE} from "../../../helper/StyleHelper";
import {Col, Modal, Row, Table} from "react-bootstrap";
import {IoMdClose} from "react-icons/io";
import Datatables, {getCheckedItems} from "../../component/Datatables";
import axios from "axios";
import {toast} from "react-toastify";
import messageCodeDic from "../../../util/messageCodeDic";
import BMCSelect from "../../../components/common/BMCSelect";
import {getLoginUser} from "../../../helper/SecurityHelper";
import SHSearch from "../../component/BMCSearch";
import BMCInput from "../../../components/common/BMCInput";
import restapi from "../../../helper/restAPIHelper";
import SiteSftyChkRgstModal from "../../safetyWorkReportManagement/modal/SiteSftyChkRgstModal";

interface IProps {
    setModalIsOpen: Dispatch<SetStateAction<boolean>>;
    modalIsOpen: boolean;
    dataList: object[];
    prcsStatCd?: string;
    sftyChkDt?: string;
    title?: string;

}

const $ = require("jquery")

const SftyChkInfoListModal = (props: IProps) => {
    const [datatable, setDatatable] = useState<any>();

    const [isCodeDataLoaded, setIsCodeDataLoaded] = useState<any>(null);
    const searchFormRef = useRef<any>();
    const [dataList, setDataList] = useState<any>([]);
    const [orgnDataList, setOrgnDataList] = useState<any>([]);
    const [modalIsOpen, setModalIsOpen] = useState<boolean>(false);
    const [detailData, setDetailData] = useState(null);
    const [sprvDprtGb, setSprvDprtGb] = useState<string>('');

    useEffect(() => {
        if (isCodeDataLoaded) {
            fn_search();
        }
    }, [isCodeDataLoaded]);

    function fn_closeModal() {
        props.setModalIsOpen(false);
    }

    const fn_search = (e?: any) => {
        e?.preventDefault()
        datatable?.draw()
    }

    const fn_onShow = () => {

        let cstmDataList = [];

        if(props.prcsStatCd){
            cstmDataList = props.dataList.filter((data: any) => (data.prcsStatCd == props.prcsStatCd))
        }else{
            cstmDataList = props.dataList
        }

        setDataList(cstmDataList);

        setOrgnDataList(props.dataList);
    }

    const fn_retrieveDataList = () => {
        const obj = new FormData(searchFormRef.current);
        const prcsStatCd = obj.get('prcsStatC');

        let cstmDataList = [];

        cstmDataList = orgnDataList.filter((data: any) => {
            let isValid = true;

            if (prcsStatCd) {
                if (data.prcsStatCd != prcsStatCd) {
                    isValid = false;
                }
            }
            return isValid;
        })

        setDataList(cstmDataList);

    }

    return (
        <div>
            <SiteSftyChkRgstModal setModalIsOpen={setModalIsOpen} modalIsOpen={modalIsOpen} prcsGb={'rsltRgst'}
                                  detailData={detailData} sprvDprtGb={sprvDprtGb}/>
            <Modal
                className="bmc-modal" size="xl"
                show={props.modalIsOpen}
                onShow={() => {
                    fn_onShow()

                }}
                onHide={fn_closeModal}
            >


                <Modal.Header>
                    <div className="d-flex justify-content-between align-items-center w-100 bmc-font-SD">
                        {props.title? props.title : '안전점검 대상 목록'}{props.sftyChkDt && '[' + props.sftyChkDt + ']'}
                        <IoMdClose className='hover:cursor-pointer' onClick={fn_closeModal}/>
                    </div>
                </Modal.Header>

                <Modal.Body className="px-4">

                    <div className={'ge-result'}>
                        <Datatables
                            datas={dataList}
                            scrollY="550px"
                            className="bmc-fs-b4"
                            parameterFormRef={searchFormRef}
                            columns={[
                                {data: "workTypeNm", title: "공종"},
                                {data: "sftyChkDt", title: "점검일시", render: (data: any, type: any, row: any) => {
                                        return row.sftyChkDt + "&nbsp;&nbsp;" + row.sftyChkHour + ':' + row.sftyChkMin;
                                    }},
                                {data: "siteName", title: "점검현장"},
                                {data: "siteAddress", title: "현장주소"},
                                {data: "extnlCommisnrNm", title: "외부위원"},
                                {data: "extnlCommisnrPhone", title: "핸드폰번호"},
                                {data: "dprtNm", title: "점검부서"},
                                {data: "sftyChkGbNm", title: "진행상황"},
                            ]}
                            checked={false}
                            onLoad={(dt: any) => {
                                setDatatable(dt)
                            }}

                            onClick={(data) => {
                                setDetailData(data)
                                setSprvDprtGb(data.sprvDprtGb)
                                setModalIsOpen(true);
                            }
                            }
                        />
                    </div>
                </Modal.Body>
                <Modal.Footer className="py-2">
                    <div className='flex justify-center w-100'>
                        <button
                            className='bmc-btn pill l white me-3'
                            onClick={fn_closeModal}>닫기
                        </button>

                    </div>
                </Modal.Footer>


            </Modal>
        </div>
    )
}

export default SftyChkInfoListModal;
