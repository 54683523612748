import React, {Dispatch, SetStateAction, useContext, useEffect, useState} from "react";
import {ProgressContext} from "../../../context/Progress";
import {getLoginUser} from "../../../helper/SecurityHelper";
import {Modal, Table} from "react-bootstrap";
import {IoMdClose} from "react-icons/io";
import BMCInput from "../../../components/common/BMCInput";
import BMCTextarea from "../../../components/common/BMCTextarea";
import BMCSelect from "../../../components/common/BMCSelect";
import {validFormData} from "../../../helper/FormHelper";
import axios from "axios";
import {toast} from "react-toastify";
import messageCodeDic from "../../../util/messageCodeDic";
import errorCodeDic from "../../../util/errorCodeDic";
import {CmmnFn} from "../../../helper/CmmnHelper";

interface IProps {
    setModalIsOpen: Dispatch<SetStateAction<boolean>>;
    modalIsOpen: boolean;
    detailData: any;
}

export default function FcltySiteRgstModal(props:IProps){
    const [data, setData] = useState<any>({});
    const formRef = React.createRef<HTMLFormElement>();
    const {spinner} = useContext(ProgressContext);
    const user: any = getLoginUser();

    const fn_updateData = (e: any) => {
        if (e.target.type == 'checkbox') {
            setData({
                ...data,
                [e.target.name]: e.target.checked
            })
            console.log(e.target.checked)
        } else {
            setData({
                ...data,
                [e.target.name]: e.target.value
            })
        }
    }


    const fn_registData = () => {

        if (!data) {
            toast.warn('데이터를 입력해 주십시오.')
            return;
        }

        if(!data.fcltyNo){
            toast.warn('시설물 번호를 입력해 주십시오.');
            return;
        }

        if(!data.siteName){
            toast.warn('시설물 명을 입력해 주십시오.');
            return;
        }

        if(!data.siteAddress){
            toast.warn('주소를 입력해 주십시오.');
            return;
        }

        if(!data.siteDtlAddress){
            toast.warn('상세주소를 입력해 주십시오.');
            return;
        }

        if (!data.guCd) {
            toast.warn('구를 선택해 주십시오.')
            return;
        }


        if (!window.confirm('저장 하시겠습니까?')) {
            return;
        }

        let param = data;
        param.regSeq = user.userSeq;
        param.updateSeq = user.userSeq;

        // @ts-ignore
        var formData: any = new FormData(formRef.current);

        formData.set('fcltyData', new Blob([JSON.stringify(param)], {type: "application/json"}))
        spinner.start();
        axios.post('/admin/api/site/saveFcltyData', formData).then((result) => {
            if (result.data > 0) {
                toast.success(messageCodeDic['001'])
                props.setModalIsOpen(false);
            } else {
                toast.error(errorCodeDic['003']);
            }
        }).catch(error => {
            toast.error(errorCodeDic['003']);
            console.log('error: ', error);
        })
        .finally(() => {
            spinner.stop();
        });
    }

    const fn_openModal = () => {
        if(!props.detailData){
            setData(null);
        }else{
            setData(props.detailData);

        }
    }

    const fn_closeModal = () => {
        props.setModalIsOpen(false);
    }

    const submit = (e: any) => {
        e.preventDefault()

    }

    const fn_openAddrPop = () => {
        // @ts-ignore
        new daum.Postcode({
            oncomplete: function (rtrnData: any) {
                // @ts-ignore
                var geocoder = new kakao.maps.services.Geocoder();
                geocoder.addressSearch(rtrnData.address, function (result: any, status: any) {
                    // @ts-ignore
                    if (status === kakao.maps.services.Status.OK) {
                        var lon = result[0].x
                        var lat = result[0].y
                        var address = result[0].address_name
                        var bjdCode = result[0].address.b_code
                        var jibun = result[0].address.main_address_no + '-' + result[0].address.sub_address_no;
                        console.log('result[0]: ', result[0]);
                        var guCd = bjdCode.substring(0,4) + '000000';
                        setData({
                            ...data,
                            siteAddress: address,
                            bjdCode: bjdCode,
                            guCd: guCd,
                            dongCd: bjdCode,
                            longitude: lon,
                            latitude: lat,
                            jibun: jibun,
                        })

                    }
                });
            }
        }).open();
    }

    return(
        <div>
            <Modal
                className="bmc-modal" size="xl"
                show={props.modalIsOpen}
                onShow={fn_openModal}
                onHide={fn_closeModal}
            >

                <Modal.Header>
                    <div className="d-flex justify-content-between align-items-center w-100 bmc-font-SD">
                        시설물 등록/수정
                        <IoMdClose className='hover:cursor-pointer' onClick={fn_closeModal}/>
                    </div>
                </Modal.Header>
                <Modal.Body className="px-4">
                    <form name={'detailForm'} ref={formRef} encType="multipart/form-data" method={"post"} onSubmit={submit}>
                        <BMCInput type={'hidden'} value={data?.atchFileId} name={'atchFileId'}></BMCInput>
                        <BMCInput type={'hidden'} value={data?.sftyMngBrdId} name={'sftyMngBrdId'}></BMCInput>
                        <div className="bmc-modal-div">
                            <div className="div-header">
                                시설소 기본정보
                            </div>
                            <div className="div-body px-3">
                                <Table className="my-2 align-middle">
                                    <tbody>


                                    <tr className="border-top align-middle">
                                        <th className="bmc-bg-th" style={{width: '15%'}}>시설물 번호</th>
                                        <td style={{width: '35%'}}>
                                            <BMCInput type="text" className="form-control" name={'fcltyNo'} onChange={fn_updateData} value={data?.fcltyNo}/>
                                        </td>
                                        <th className="bmc-bg-th" style={{width: '15%'}}>시설물 명</th>
                                        <td style={{width: '35%'}}>
                                            <BMCInput type="text" className="form-control" name={'siteName'} onChange={fn_updateData} value={data?.siteName}/>
                                        </td>
                                    </tr>
                                    <tr className="border-top align-middle">
                                        <th className="bmc-bg-th">
                                            주소
                                        </th>
                                        <td colSpan={3}>
                                            <div
                                                className="d-flex align-items-center justify-content-between w-100 p-0 bmc-fs-b3">
                                                <BMCInput type="text" name={'siteAddress'} value={data?.siteAddress}
                                                          className="form-control" readOnly/>
                                                <button className="bmc-btn btn-secondary min-w-[70px] ml-5 mr-10"
                                                        type="button" onClick={fn_openAddrPop}>검색
                                                </button>

                                            </div>
                                        </td>
                                    </tr>
                                    <tr className="border-top align-middle">
                                        <th className="bmc-bg-th">
                                            상세주소
                                        </th>
                                        <td>
                                            <div className="d-flex align-items-center px-0">
                                                <BMCInput type="text" className={'form-control'}
                                                          onChange={fn_updateData} name={'siteDtlAddress'}
                                                          value={data?.siteDtlAddress}/>
                                            </div>
                                        </td>
                                        <th className="bmc-bg-th" style={{width: '15%'}}>구</th>
                                        <td style={{width: '35%'}}>
                                            <BMCSelect initOptionGb={'all'} className={'form-select'} name={'guCd'} codeGb={'bjd'} codeGrpId={'BUSAN'} value={data?.guCd} onChange={fn_updateData}></BMCSelect>
                                        </td>
                                    </tr>
                                    </tbody>
                                </Table>
                            </div>
                        </div>

                        <div className="bmc-modal-div">
                            <div className="div-header">
                                작성정보
                            </div>
                            <div className="div-body px-3">
                                <Table className="my-2 align-middle">
                                    <tbody>
                                    <tr>
                                        <th className="bmc-bg-th">
                                            작성일
                                        </th>
                                        <td>
                                            <BMCInput value={data?.regDate || CmmnFn.fn_getCurrentDate()} name={'rgstDt'}
                                                      className={"form-control all-white"} disabled={true}/>
                                        </td>
                                        <th className="bmc-bg-th">
                                            작성자
                                        </th>
                                        <td>
                                            <BMCInput className={"form-control all-white"} name={'rgstrNm'}
                                                      value={data?.rgstrNm || user.userName} disabled={true}/>
                                        </td>
                                    </tr>
                                    </tbody>
                                </Table>
                            </div>
                        </div>
                    </form>
                </Modal.Body>

                <Modal.Footer className="py-2">
                    <div className='flex justify-center w-100'>
                        <button
                            className='bmc-btn pill l white me-3'
                            onClick={fn_closeModal}>닫기
                        </button>
                        <button onClick={fn_registData}
                                className='bmc-btn pill p'>등록/수정
                        </button>
                    </div>
                </Modal.Footer>

            </Modal>
        </div>
    )
}
