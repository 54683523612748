import React, {Dispatch, SetStateAction, useState} from "react";
import {toast} from "react-toastify";
import restapi from "../../helper/restAPIHelper";
import messageCodeDic from "../../util/messageCodeDic";
import errorCodeDic from "../../util/errorCodeDic";
import BMCInput from "./BMCInput";
import BMCCheckbox from "./BMCCheckbox";


interface IProps {
    fileName: string;
    fileList: any;
    setFileList: any;
    setRepFileSn?: any;
    repFileSn?: any;
    disabled?: boolean;
    maxFileCnt?: number;
    isDesc?: boolean;
    fileId?: string;
    maxSize?: number;
}

export default function BMCFile(props: IProps) {


    const fn_addFile = (e: any) => {

        const target = e.target;
        const maxFileCnt = (props.maxFileCnt || 5);   // 첨부파일 최대 개수
        let attFileCnt = props.fileList.length;    // 기존 추가된 첨부파일 개수
        let remainFileCnt = maxFileCnt - attFileCnt;    // 추가로 첨부가능한 개수
        let curFileCnt = target.files.length;  // 현재 선택된 첨부파일 개수

        // 첨부파일 개수 확인
        if (curFileCnt > remainFileCnt) {
            toast.warn("첨부파일은 최대 " + maxFileCnt + "개 까지 첨부 가능합니다.");
        }

        console.log('target.files: ', target.files[0]);
        for (var i = 0; i < Math.min(curFileCnt, remainFileCnt); i++) {

            const file: any = target.files[i];
            // 첨부파일 검증
            if (validation(file)) {
                // 파일 배열에 담기
                props.fileList.push(file);


            } else {
                continue;
            }
        }

        console.log('props.fileList: ', props.fileList);
        // @ts-ignore
        props.setFileList([].concat(props.fileList));
        // 초기화
        //target.value = "";
    }

    function validateFileExt(filename: string) {
        var extReg = /(bat|bin|cmd|com|cpl|dll|exe|gadget|inf1|ins|isu|jse|lnk|msc|msi|msp|mst|paf|pif|ps1|reg|rgs|scr|sct|sh|shb|shs|u3p|vb|vbe|vbs|vbscript|ws|wsf|wsh)$/i;
        var ext = filename.substr( (filename.lastIndexOf('.') +1) );
        return !extReg.test(ext);
    }

    const validation = (obj: any) => {
        const fileTypes = ['application/pdf', 'image/gif', 'image/jpeg', 'image/png', 'image/bmp', 'image/tif', 'application/haansofthwp', 'application/x-hwp', ''];
        let maxSize;

        if(props.maxSize){
            maxSize = props.maxSize;
        }else{
            maxSize = 100;
        }

        if (obj.name.length > 100) {
            toast.warn("파일명이 100자 이상인 파일은 제외되었습니다.");
            return false;
        } else if (obj.size > (maxSize * 1024 * 1024)) {
            toast.warn("최대 파일 용량인 " + maxSize + "MB를 초과한 파일은 제외되었습니다.");
            return false;
        } else if (obj.name.lastIndexOf('.') == -1) {
            toast.warn("확장자가 없는 파일은 제외되었습니다.");
            return false;
        } else if (!validateFileExt(obj.name)) {
            toast.warn("첨부가 불가능한 파일은 제외되었습니다.");
            return false;
        } else {
            return true;
        }
    }

    const deleteFile = (data: any, idx: number) => {

        if (data.attachSeq) {
            if (!window.confirm('삭제 하시겠습니까?')) {
                return;
            }

            restapi.deleteAttachFile({attachSeq: data.attachSeq, sn: data.sn}).then((rsltCnt: number) => {
                if (rsltCnt > 0) {
                    props.fileList.splice(idx, 1);
                    // @ts-ignore
                    props.setFileList([].concat(props.fileList));
                    toast.success(messageCodeDic['002']);
                } else {
                    toast.error(errorCodeDic['003']);
                }
            })
        } else {
            props.fileList.splice(idx, 1);
            // @ts-ignore
            props.setFileList([].concat(props.fileList));
        }

    }

    const fn_setFileListData = (e: any, idx: number) => {
        const targetName = e.target.name;
        const targetVal = e.target.value;

        props.fileList.map((data: any, inIdx: number) => {
            if(targetName == 'isRep'){
                if (idx == inIdx) {
                    data.isRep = true
                } else {
                    data.isRep = false
                }
            }else{
                if(idx == inIdx){
                    data[targetName] = targetVal;
                }

            }

        })

        props.setFileList([].concat(props.fileList));
        if(props.setRepFileSn){
            props.setRepFileSn(idx);

        }
    }

    return (
        <div className="insert">

            <input className={'hidden'} name={props.fileName} id={props.fileId ? props.fileId : "input-file"} type={"file"} onChange={fn_addFile}
                   multiple/>

            <div className="d-flex align-items-start justify-content-start w-100 p-0 bmc-fs-b3">
                <div className="file-list mr-5 flex-grow-1">
                    {props.fileList.map((data: any, idx: number) => (
                        <div key={idx} className="filebox w-100 d-flex justify-content-between align-items-end">
                            <div>
                            {props.setRepFileSn && <BMCCheckbox name={'isRep'} onChange={(e: any) => {
                                fn_setFileListData(e, idx)
                            }} checked={data.isRep} className={'mr-5'}></BMCCheckbox>}

                            <p className="name text-black">
                                {data.attachSeq ?
                                    <a href={process.env.REACT_APP_FILE_URL + "/bmcss-data/fileDownload?attachSeq=" + data.attachSeq + '&sn=' + data.sn + '&isPdf=0'}> {data.alias} </a>
                                    : data.name
                                }
                            </p>
                        </div>
                            <div className={'mr-10'}>
                            {props.isDesc &&
                                <input value={data.description} name={'description'} onChange={(e: any) => fn_setFileListData(e, idx)}  className={'p-1 ml-10 border-1 w-[250px]'} />
                            }

                            <a className="delete cursor-pointer align-self-center" onClick={() => deleteFile(data, idx)} style={{display:props.disabled ? 'none' : 'inline'}}><i
                                className="far fa-minus-square"></i></a>
                            </div>
                        </div>
                    ))}
                </div>
                <div className="flex-grow-2" aria-disabled={props.disabled} style={{display:props.disabled ? 'none' : 'block'}}>
                    <label htmlFor={props.fileId ? props.fileId : "input-file"} className={'bmc-btn btn-secondary cursor-pointer file-up-btn'}>
                        업로드
                    </label>
                </div>
            </div>
        </div>
    )

}
