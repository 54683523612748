import {Modal, Table} from "react-bootstrap";
import {IoMdClose} from "react-icons/io";
import iconCalendar from "../../../images/icon-calendar-black.svg";
import CustomDatePickerRange from "../../component/ui/DatePickerRange";
import React, {Dispatch, SetStateAction, useEffect, useRef, useState} from "react";
import {fn_getCurrentDate} from "../../../helper/CmmnHelper";
import {getLoginUser} from "../../../helper/SecurityHelper";
import {Col, Row} from "react-bootstrap";
import {Container} from "@mui/material";
import {COMMON_MODAL_STYLE} from "../../../helper/StyleHelper";
import BMCInput from "../../../components/common/BMCInput";
import BMCTextarea from "../../../components/common/BMCTextarea";
import axios from "axios";
import {toast} from "react-toastify";
import messageCodeDic from "../../../util/messageCodeDic";
import errorCodeDic from "../../../util/errorCodeDic";
import BMCSelect from "../../../components/common/BMCSelect";
import SiteCardList from "../../../components/site/SiteCardList";
import {ChangeMeta, NumberFormatBase} from "react-number-format";
import {Formatter} from "../../../helper/FormatHelper";


interface IProps {
    setModalIsOpen: Dispatch<SetStateAction<boolean>>;
    modalIsOpen: boolean;
    detailData: any;
    setIsSaveSuccess: Dispatch<SetStateAction<boolean>>;
    siteId: number;
    manageItem: object;
}

const SiteMangeItemRgstModal = (props: IProps) => {

    const [data, setData] = useState<any>()

    const fn_setFormData = (e: any) => {

        setData({
            ...data,
            [e.target.name]: e.target.value
        })

    }


    const fn_onAfterOpen = () => {
        if (!props.manageItem) {
            setData(null)
            // 상세
        } else {
            setData(props.manageItem);
        }
    }


    function fn_closeModal() {
        props.setModalIsOpen(false);
    }

    const submit = (e: any) => {
        e.preventDefault()

    }


    const fn_registData = () => {

        if (!data) {
            toast.warn('데이터를 입력해 주십시오.')
            return;
        }

        if (!data.manageName) {
            toast.warn(props.detailData?.codeNm + '명을 입력해 주십시오');
            return;
        }

        let param = data;
        param.siteId = props.siteId;
        console.log('param.code: ', param.code);


        param.manageCode = props.detailData.code;


        if(param.manageCode == 'M001005'){
            console.log('props.manageItem: ', props.manageItem);
            if(!props.manageItem){
                param.statGb = 'C'
            }else{
                if(param.statGb == 'O'){
                    param.targetCode = param.manageSn
                    param.manageSn = null;
                }else{
                    param.manageSn = param.tableCode;
                }
                param.statGb = 'U'
            }
        }

        console.log('param: ', param);

        if (!window.confirm('저장 하시겠습니까?')) {
            return;
        }

        axios.post('/admin/api/manage/saveSiteManageItemDate', param).then((result) => {
                if (result.data > 0) {
                    toast.success(messageCodeDic['001'])
                    props.setModalIsOpen(false);
                    props.setIsSaveSuccess(true);
                } else {
                    toast.error(errorCodeDic['003']);
                    props.setIsSaveSuccess(false);
                }
            }
        )


    }


    return (
        <div>


            <Modal
                className="bmc-modal sub" size={'lg'}
                show={props.modalIsOpen}
                onShow={fn_onAfterOpen}
                onHide={fn_closeModal}
            >
                <Modal.Header>
                    <div className="d-flex justify-content-between align-items-center w-100 bmc-font-SD">
                        {props.detailData?.codeNm}
                        <IoMdClose className='hover:cursor-pointer' onClick={fn_closeModal}/>
                    </div>
                </Modal.Header>


                <Modal.Body className="px-4 bmc-modal-table-w100">
                    <form name={'form'}>
                        <Table className="my-2 align-middle">
                            <tbody>
                            <tr>
                                <th className="bmc-bg-th" style={{width: '25%'}}>{props.detailData?.codeNm}</th>
                                <td style={{width: '75%'}}>
                                    <BMCInput type="text" className="form-control" name={'manageName'}
                                             onChange={fn_setFormData} value={data?.manageName}/>
                                </td>
                            </tr>
                            </tbody>
                        </Table>
                    </form>
                </Modal.Body>
                <Modal.Footer className="py-2">
                    <div className='flex justify-center w-100'>
                        <button
                            className='bmc-btn pill l white me-3'
                            onClick={fn_closeModal}>닫기
                        </button>
                        <button onClick={fn_registData}
                                className='bmc-btn pill p'>저장

                        </button>

                    </div>
                </Modal.Footer>

            </Modal>
        </div>
    )
}

export default SiteMangeItemRgstModal;

