import React, {useContext, useEffect, useRef, useState} from 'react';
import axios from "axios";
import Modal from 'react-modal';
import {getLoginUser} from "../../helper/SecurityHelper";
import {toast} from "react-toastify";
import errorCodeDic from "../../util/errorCodeDic";
import {ProgressContext} from "../../context/Progress";
import restapi from "../../helper/restAPIHelper";

import {Col, Container, Row, Tab, Table, Tabs} from 'react-bootstrap';
import SubMenuTitle from "../component/SubMenuTitle";

import {CmmnFn} from "../../helper/CmmnHelper";

import WrkrInfoListModal from "../home/modal/WrkrInfoListModal";
import {DateHelper} from "../../helper/DateHelper";
import RiskEvalChkStatPage from "./subPage/RiskEvalChkStatPage";
import RiskEvalIncgrtyPage from "./subPage/RiskEvalIncgrtyPage";
import {UserContext} from "../../context/User";
import RiskEvalChkDtlModal from "./modal/RiskEvalChkDtlModal";
import RiskEvalChkHistModal from "./modal/RiskEvalChkHistModal";

type Props = {};

Modal.setAppElement('#root');
const RiskEvalCheckPage = (props: Props) => {

    const defaultUser = getLoginUser();
    const {user} = useContext<any>(UserContext);
    const {spinner} = useContext(ProgressContext);
    const [dataList, setDataList] = useState<any>([]);
    const [srchData, setSrchData] = useState<any>({siteId:user?.blngSiteId || defaultUser.blngSiteId, tabGb: 'chkStat'});
    const [modalIsOpen, setModalIsOpen] = useState<boolean>(false);
    const [selData, setSelData] = useState<any>(null);

    const [isDatePopupOpen, setIsDatePopupOpen] = useState<boolean>(false);
    const prodRef = useRef<any>();
    const [isHistModalOpen, setIsHistModalOpen] = useState<boolean>(false);

    useEffect(() => {
        if(!modalIsOpen){
            fn_search();
        }
    }, [srchData,modalIsOpen])



    useEffect(() => {
        if(selData){
            if(selData.isHist){
                setIsHistModalOpen(true);
            }else{
                setModalIsOpen(true);
            }
        }

    }, [selData]);

    useEffect(() => {
        setSrchData({
            ...srchData,
            siteId: user?.blngSiteId || defaultUser.blngSiteId
        })
    }, [user]);

    const fn_search = () => {

        spinner.start();
        restapi.retrieveRiskEvalChkDataList(srchData).then((rsltData: any) => {
            setDataList(rsltData)
        }).catch(error => {
            toast.error(errorCodeDic['003']);
            console.log('error: ', error);
        })
        .finally(() => {
            spinner.stop();
        });
    }

    return (
        <Container fluid className="bg-white px-0 pb-4 bmc-text-font_primary">
            <RiskEvalChkDtlModal setModalIsOpen={setModalIsOpen} modalIsOpen={modalIsOpen} detailData={selData} siteId={user?.blngSiteId || defaultUser.blngSiteId} />
            <RiskEvalChkHistModal setModalIsOpen={setIsHistModalOpen} modalIsOpen={isHistModalOpen} paramData={selData}/>
            <SubMenuTitle title={'위험성평가 점검'} parentMenu={'현장안전관리'}/>
            <div className="overflow-y-auto px-4" style={{height: 'calc(100vh - 180px)'}}>
            <Tabs
                transition={true}
                activeKey={srchData.tabGb}
                onSelect={(tabGb) => setSrchData({
                    ...srchData,
                    'tabGb': tabGb,
                    'chkRsltCd': tabGb == 'chkStat' ? null : 'R005002'
                })}
                id="tabGb"
                className="mb-3 mt-2"
            >
            <Tab eventKey="chkStat" title="점검현황">
                <RiskEvalChkStatPage dataList={dataList} srchData={srchData} setSrchData={setSrchData} setSelData={setSelData} isDatePopupOpen={isDatePopupOpen} setIsDatePopupOpen={setIsDatePopupOpen} onSearch={fn_search}/>
            </Tab>
            <Tab eventKey="incgrty" title="위험성평가 부적합">
                <RiskEvalIncgrtyPage dataList={dataList} srchData={srchData} setSrchData={setSrchData} setSelData={setSelData} isDatePopupOpen={isDatePopupOpen} setIsDatePopupOpen={setIsDatePopupOpen} onSearch={fn_search} />
            </Tab>

            </Tabs>
            </div>
        </Container>


    );



};

export default RiskEvalCheckPage;
