import React, {useContext, useEffect, useRef, useState} from 'react';
import {Col, Container, Row} from "react-bootstrap";
import {ReactComponent as IconStop} from "../../assets/images/icon/icon_work_stop.svg";
import {ReactComponent as IconSiren} from "../../assets/images/icon/icon_sos_call.svg";
import restapi from "../../helper/restAPIHelper";
import {CmmnFn} from "../../helper/CmmnHelper";
import WrkStopInfoListModal from "./modal/WrkStopInfoListModal";
import SosInfoListModal from "./modal/SosInfoListModal";
import {getLoginUser} from "../../helper/SecurityHelper";
import {UserContext} from "../../context/User";

const HOCRTrafficLight = () => {

    const [sosDataList, setSosDataList] = useState<any>([]);
    const [wrkStopDataList, setWrkStopDataList] = useState<any>([]);
    const [wrkStopModalIsOpen, setWrkStopModalIsOpen] = useState<boolean>(false);
    const [sosModalIsOpen, setSosModalIsOpen] = useState<boolean>(false);
    const timerRef = useRef<any>();
    const defaultUser = getLoginUser();
    const {user} = useContext<any>(UserContext);

    useEffect(() => {
        fn_retrieveData();
        //timerRef.current = setInterval(fn_retrieveData, 5000);

        return () => {
            //clearInterval(timerRef.current);
        };
    }, []);

    const fn_retrieveData = () => {
        let param: any = {rgstDt: CmmnFn.fn_getCurrentDate()};
        if(CmmnFn.isSmSite()){
            param = {rgstDt: CmmnFn.fn_getCurrentDate(), siteId: user?.blngSiteId || defaultUser.blngSiteId};
        }

        restapi.retrieveSosHisTotDataList(param).then((rsltData: any) => {
            setSosDataList(rsltData);
        })

        restapi.retrieveWrkDisruptHisTotDataList(param).then((rsltData: any) => {
            setWrkStopDataList(rsltData);
        })
    }


    const fn_openWrkStopInfoListModal = () => {
        setWrkStopModalIsOpen(true);
    }

    const fn_openSosModal = () => {
        setSosModalIsOpen(true);
    }

    return(
        <>
            <WrkStopInfoListModal setModalIsOpen={setWrkStopModalIsOpen} modalIsOpen={wrkStopModalIsOpen} dataList={wrkStopDataList} />
            <SosInfoListModal setModalIsOpen={setSosModalIsOpen} modalIsOpen={sosModalIsOpen} dataList={sosDataList} />
            <Container fluid className="bmc-bg-pane">
                <Row>
                    <Col>
                        <div className="d-flex flex-column justify-content-between">
                            <div className="w-100 d-flex align-items-center justify-content-between pb-2 border-bottom">
                                <span className="bmc-txt-size-h4">현장 위험도 현황</span>
                            </div>
                        </div>
                    </Col>
                </Row>
                <Row className="pt-3">
                    <Col>
                        <div className="d-flex justify-content-between">
                            <div className="d-flex flex-column justify-content-center">
                                <div style={{fontSize: '1rem'}}>위험신호등</div>
                                <div style={{fontSize: '1.125rem', fontWeight: '600', textUnderlineOffset: '5px'}}>
                                    {
                                        (wrkStopDataList.length == 0 && sosDataList.length == 0) ?
                                            (<span className="underline" style={{color: '#0DD7A6'}}>정상</span>)
                                            : wrkStopDataList.length > 0 ?
                                                (<span className="underline" style={{color: '#FF8F51'}}>작업중지 요청</span>)
                                                : sosDataList.length > 0 ?
                                                    (<span className="underline" style={{color: '#FF5656'}}>SOS 응급 호출</span>)
                                                    : null
                                    }
                                </div>
                            </div>
                            <div className="rounded-pill d-flex justify-content-between align-items-center" style={{border: '2px solid #f5f5f5', backgroundColor: '#fff', width: '180px', height: '60px'}}>
                                <div className="ms-2 rounded-circle d-flex justify-content-center align-items-center" style={{width: '50px', height: '50px', border: '1px solid #f2f1f1'}} >
                                    <div className="rounded-circle" style={{width: '40px', height: '40px', backgroundColor: (wrkStopDataList.length == 0 && sosDataList.length == 0) ? '#0DD7A6' : '#E0E0E0', boxShadow: 'inset 0 10px 0 rgba(0,0,0,0.1)'}} />
                                </div>
                                <div className="rounded-circle d-flex justify-content-center align-items-center" style={{width: '50px', height: '50px', border: '1px solid #f2f1f1'}} >
                                    <div className="rounded-circle" style={{width: '40px', height: '40px', backgroundColor: wrkStopDataList.length > 0 ? '#FF8F51' : '#E0E0E0', boxShadow: 'inset 0 10px 0 rgba(0,0,0,0.1)'}} />
                                </div>
                                <div className="me-2 rounded-circle d-flex justify-content-center align-items-center" style={{width: '50px', height: '50px', border: '1px solid #f2f1f1'}} >
                                    <div className="rounded-circle" style={{width: '40px', height: '40px', backgroundColor: sosDataList.length > 0 ? '#FF5656' : '#E0E0E0', boxShadow: 'inset 0 10px 0 rgba(0,0,0,0.1)'}} />
                                </div>
                            </div>
                        </div>
                    </Col>
                </Row>
                <Row className="pt-2">
                    <Col>
                        <div className="d-flex justify-content-between align-items-center mb-2">
                            <div style={{fontSize: '1rem', fontWeight: '500'}}>위험 이력 건수</div>
                            <div style={{fontSize: '0.85rem'}}>기준</div>
                        </div>
                        <div className="d-flex justify-content-between" style={{lineHeight: '20px'}}>
                            <div onClick={fn_openWrkStopInfoListModal} className="cursor-pointer rounded-3 p-2" style={{width: '48%', backgroundColor: '#FAFAFA'}}>
                                <div className="bmc-fs-b3 bmc-txt-color-danger bmc-fw-5">작업 중지 요청</div>
                                <div className="d-flex align-items-center justify-content-between mt-2">
                                    <IconStop style={{width: '40px'}} />
                                    <div className="bmc-fs-b3"><span className="bmc-fs-h3 bmc-fw-6 me-1">{wrkStopDataList.length}</span>건</div>
                                </div>
                            </div>
                            <div onClick={fn_openSosModal} className="cursor-pointer rounded-3 p-2" style={{width: '48%', backgroundColor: '#FAFAFA'}}>
                                <div className="bmc-fs-b3 bmc-txt-color-danger bmc-fw-5">긴급 위험알람 호출</div>
                                <div className="d-flex align-items-center justify-content-between mt-2">
                                    <IconSiren style={{width: '40px'}} />
                                    <div className="bmc-fs-b3"><span className="bmc-fs-h3 bmc-fw-6 me-1">{sosDataList.length}</span>건</div>
                                </div>
                            </div>
                        </div>
                    </Col>
                </Row>
            </Container>
        </>
    );
}

export default HOCRTrafficLight;
