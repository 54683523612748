import React, {useContext, useEffect, useRef, useState} from 'react';
import axios from "axios";
import Datatables, {getCheckedItems} from "../component/Datatables";
import Modal from 'react-modal';
import {getLoginUser} from "../../helper/SecurityHelper";
import {toast} from "react-toastify";
import messageCodeDic from "../../util/messageCodeDic";
import errorCodeDic from "../../util/errorCodeDic";
import BMCSelect from "../../components/common/BMCSelect";
import {ProgressContext} from "../../context/Progress";
import restapi from "../../helper/restAPIHelper";
import SafetyEduRgstModal from "../safetyEducationManagement/modal/SafetyEduRgstModal";
import EqmntRgstModal from "./modal/EqmntRgstModal";
import BMCSearch from '../component/BMCSearch';
import {Col, Container, Row, Table } from 'react-bootstrap';
import SubMenuTitle from "../component/SubMenuTitle";
import BMCIconButton from "../../components/common/BMCIconButton";
import BMCInput from "../../components/common/BMCInput";
import DailyChkDtlModal from "./modal/DailyChkDtlModal";
import {UserContext} from "../../context/User";
import BMCDatePicker2 from "../../components/common/BMCDatePicker2";
import {DateHelper} from "../../helper/DateHelper";
import EqmntIncgrtyDtlModal from "./modal/EqmntIncgrtyDtlModal";

type Props = {};

Modal.setAppElement('#root');
const EqmntIncgrtyMngPage = (props: Props) => {
    const [modalIsOpen, setModalIsOpen] = React.useState(false);
    const [detailData, setDetailData] = useState(null);
    const [isSaveSuccess, setIsSaveSuccess] = useState(false);
    const defaultUser = getLoginUser();
    const srchTxtRef = useRef<any>();
    const [srchData, setSrchData] = useState<any>({})
    const {spinner} = useContext(ProgressContext);
    const searchFormRef = useRef<any>();
    const [datatable, setDatatable] = useState<any>();
    const {user} = useContext<any>(UserContext);

    const prodRef = useRef<any>();
    const [startDate, setStartDate] = useState<any>(DateHelper.dateByMonth(-3));
    const [endDate, setEndDate] = useState<any>(DateHelper.dateByMonth(0));
    const [isCodeDataLoaded, setIsCodeDataLoaded] = useState<boolean>(false);


    useEffect(()=>{

        if(datatable){
            datatable.draw();
        }

    }, [user, datatable])

    useEffect(() => {
        if (!modalIsOpen) {
            datatable?.draw();
        }
    }, [modalIsOpen])

    useEffect(() => {
        if(isCodeDataLoaded){
            datatable?.draw();
        }

    }, [isCodeDataLoaded])

    const fn_setSearchData = (e: any) => {

        setSrchData({
            ...srchData,
            [e.target.name]: e.target.value
        })

    }

    const fn_search = (e?: any) => {
        if (e) {
            e.preventDefault()
        }

        datatable.draw();
        setIsSaveSuccess(false);
    }

    const fn_delete = () => {
        let checkedData = getCheckedItems(datatable);

        let delList:number[] = [];

        if (checkedData.length == 0) {
            toast.warn(messageCodeDic['005']);
            return;
        }

        checkedData.map((data) => {
            delList.push(data.eqmntId);
        })



        if (!window.confirm(messageCodeDic['004'])) {
            return;
        }

        axios.post('/admin/api/eqmnt/deleteEqmntData', {delList: delList}).then(rsltData => {
                if (rsltData.data > 0) {
                    toast.success(messageCodeDic['002'])
                    fn_search();
                } else {
                    toast.error(errorCodeDic['003'])
                }

            }
        ).catch(() => {
                toast.error(errorCodeDic['003'])
            }
        )
    }

    const fn_updateViewCnt = (param: object) => {
        axios.post('/admin/api/notice/updateViewCnt', param)
    }

    const fn_onIsAllProdChg = (e:any) => {
        if(e.target.checked){
            setStartDate(null);
            setEndDate(null);
        }else{
            setStartDate(DateHelper.dateByMonth(-3));
            setEndDate(DateHelper.dateByMonth(0));
        }
    }

    const fn_registData = () => {

    }


    return (
        <Container fluid className="bg-white px-0 pb-4 bmc-text-font_primary">
            <SubMenuTitle title={'중장비 부적합 관리'} parentMenu={'현장장비관리'}/>
            <EqmntIncgrtyDtlModal setModalIsOpen={setModalIsOpen} modalIsOpen={modalIsOpen} siteId={user?.blngSiteId || defaultUser.blngSiteId}
                            detailData={detailData} setIsSaveSuccess={setIsSaveSuccess}/>
            <div className="p-4 overflow-y-auto" style={{height: 'calc(100vh - 180px)'}}>
                <BMCSearch datatable={datatable} formRef={searchFormRef} btnType={'p'}>
                    <BMCInput type={'hidden'} name={'isDailyHist'} value={true} ></BMCInput>
                    <BMCInput type={'hidden'} name={'isIncgrty'} value={true} ></BMCInput>
                    <Row>
                        <Col>
                            <Table className="bmc-font-SP bmc-fs-b2 my-3">
                                <tbody>
                                <tr className="border-top border-bottom align-middle">

                                    <th className="bmc-bg-th" style={{width: '15%'}}>업체</th>
                                    <td style={{width: '35%'}}>
                                        <BMCInput type={'hidden'} name={'siteId'} value={user?.blngSiteId || defaultUser.blngSiteId}></BMCInput>
                                        <BMCSelect name={'cmpnySeq'} onChange={fn_setSearchData} value={srchData.cmpnySeq} setIsCodeDataLoaded={setIsCodeDataLoaded} initOptionGb={'all'} codeGb={'siteCmpny'} selParam={{siteId:user?.blngSiteId || defaultUser.blngSiteId}}></BMCSelect>

                                    </td>
                                    <th className="bmc-bg-th" style={{width: '15%'}}>검색어</th>
                                    <td style={{width: '35%'}}>
                                        <input type={'text'} className='form-control' ref={srchTxtRef} />
                                    </td>
                                </tr>
                                <tr className="border-top border-bottom align-middle">
                                    <th className="bmc-bg-th" style={{width: '15%'}}>점검기간</th>
                                    <td style={{width: '35%'}} colSpan={3}>
                                        <div className="d-flex flex-row gap-2">
                                            <div className="d-flex align-items-center" ref={prodRef}>
                                                <BMCDatePicker2 setter={setStartDate} name={'startCmpltDt'} defaultValue={startDate}
                                                               onChange={fn_setSearchData}/>
                                                <span>&nbsp;~&nbsp;</span>
                                                <BMCDatePicker2 setter={setEndDate} name={'endCmpltDt'} defaultValue={endDate} onChange={fn_setSearchData}/>
                                            </div>
                                            <div className="d-flex align-items-center gap-1" ref={prodRef}>
                                                <input className="form-check-input bmc-check" id={'isAll'} name={'isAll'}
                                                       type="checkbox" onChange={(e: any) => {fn_setSearchData(e); fn_onIsAllProdChg(e);}}/>
                                                <label className={'form-check-label text-black'} htmlFor={'isAll'}>전체기간</label>
                                            </div>
                                        </div>
                                    </td>
                                </tr>
                                </tbody>
                            </Table>
                        </Col>
                    </Row>
                </BMCSearch>

            <div className={'ge-result'}>
                <Datatables
                    dataUrl={'/admin/api/eqmnt/retrieveEqmntDataList'}
                    parameterFormRef={searchFormRef}
                    columns={[
                        {data: "chckrNm", title: "점검자"},
                        {data: "eqmntNm", title: "장비명"},
                        {data: "cmpnyNm", title: "업체"},
                        {data: "eqmntRgstNo", title: "장비등록번호"},
                        {data: 'driverNm', title: '운전원'},
                        {data: "chkTime", title: "점검일자"},
                        {data: 'actDt', title: '조치일자'},
                        {data: '' , title: '상태', render: (data: any, type: any, row: any) => {
                                if(!row.isActDone){
                                    return '<span class="text-red-500">조치권고</span>'
                                }else{
                                    return '<span class="text-green-500">조치완료</span>'
                                }

                            }},
                    ]}
                    checked={false}
                    index={true}

                    onLoad={(dt: any) => {
                        setDatatable(dt)
                    }}
                    onClick={(data) => {
                        setDetailData(data)
                        setModalIsOpen(true);
                        fn_updateViewCnt(data);
                    }
                    }
                />
            </div>
            </div>


        </Container>


    );
};

export default EqmntIncgrtyMngPage;
