import {Modal, Table} from "react-bootstrap";
import {IoMdClose} from "react-icons/io";
import iconCalendar from "../../../images/icon-calendar-black.svg";
import CustomDatePickerRange from "../../component/ui/DatePickerRange";
import React, {Dispatch, SetStateAction, useContext, useEffect, useRef, useState} from "react";
import {fn_getCurrentDate} from "../../../helper/CmmnHelper";
import {getLoginUser} from "../../../helper/SecurityHelper";
import {Col, Row} from "react-bootstrap";
import {Container} from "@mui/material";
import {COMMON_MODAL_STYLE, COMMON_MULTISEL_STRINGS} from "../../../helper/StyleHelper";
import Select from 'react-select'
import {MultiSelect} from "react-multi-select-component";
import axios from "axios";
import {toast} from "react-toastify";
import messageCodeDic from "../../../util/messageCodeDic";
import errorCodeDic from "../../../util/errorCodeDic";
import BMCInput from "../../../components/common/BMCInput";
import BMCTextarea from "../../../components/common/BMCTextarea";
import BMCDatePicker from "../../../components/common/BMCDatePicker";
import {ProgressContext} from "../../../context/Progress";
import BMCCheckbox from "../../../components/common/BMCCheckbox";
import BMCFile from "../../../components/common/BMCFile";
import restapi from "../../../helper/restAPIHelper";


interface IProps {
    setModalIsOpen: Dispatch<SetStateAction<boolean>>;
    modalIsOpen: boolean;
    noticeDetailData: any;
    siteId: number;
}

const NoticeRegistModal = (props: IProps) => {

    const prodRef = useRef<any>();
    const currentDate = fn_getCurrentDate();
    const user: any = getLoginUser();

    const [startDate, setStartDate] = useState<string>('');
    const [endDate, setEndDate] = useState<string>('');
    const [isDatePopupOpen, setIsDatePopupOpen] = useState(false);

    const [data, setData] = useState<any>()

    const [selected, setSelected] = useState([])
    const [cmpnyCodeList, setCmpnyCodeList] = useState<any>([]);
    const [fileList, setFileList] = useState([]);
    const defaultUser: any = getLoginUser();
    const {spinner} = useContext(ProgressContext);

    const formRef = React.createRef<HTMLFormElement>();

    useEffect(() => {
        setData(null)
    }, [props.modalIsOpen])
    const updateData = (e: any) => {
        if (e.target.type == 'checkbox') {
            setData({
                ...data,
                [e.target.name]: e.target.checked
            })
        } else {
            setData({
                ...data,
                [e.target.name]: e.target.value
            })
        }

    }

    useEffect(() => {
        let param:any = {siteId: props.siteId}

        fn_retrieveCmpnyDataList(param)
    }, [props.siteId])

    useEffect(() => {
        // 신규
        if (!props.noticeDetailData) {
            setData(null)
            setStartDate('')
            setEndDate('')

            setSelected([])
            // 상세
        } else {
            setData(props.noticeDetailData);
            setStartDate(props.noticeDetailData.noticeStartDate)
            setEndDate(props.noticeDetailData.noticeEndDate)
            axios.post('/admin/api/notice/retrieveNoticeRecvDataList', {noticeId: props.noticeDetailData.noticeId}).then((result) => {
                let dataList: any = [];
                result.data.map((item: any) => {
                    dataList.push({label: item.companyName, value: item.companySeq})
                })
                setSelected(dataList);

            })
        }

    }, [props.noticeDetailData])


    const fn_retrieveCmpnyDataList = async (param: object) => {
        return new Promise(function (resolve, reject) {
            axios.post('/admin/api/company/retrieveCmpnyDataList', param).then((result) => {
                resolve(result.data);
            })
        }).then((result: any) => {
            if (result) {
                const rsltCmpnyCodeList: object[] = []
                result.map((data: any) => {
                    rsltCmpnyCodeList.push({label: data.companyName, value: data.companySeq})
                })
                setCmpnyCodeList(rsltCmpnyCodeList);
            }
        });

    }

    function afterOpenModal() {
        if(!props.noticeDetailData){
            setFileList([]);
            setData(null);
        }else{
            setData(props.noticeDetailData);
            if(props.noticeDetailData.atchFileId){
                restapi.retrieveAttachInfoList({attachSeq: props.noticeDetailData.atchFileId}).then((rsltData: any) => {
                    setFileList([].concat(rsltData));
                })
            }else{
                setFileList([]);
            }
        }
    }

    function fn_closeModal() {
        props.setModalIsOpen(false);
    }

    const fn_datePopupOpen = () => {
        setIsDatePopupOpen(!isDatePopupOpen);
    };

    const submit = (e: any) => {
        e.preventDefault()

    }

    const fn_registData = () => {

        if (!data) {
            toast.warn('데이터를 입력해 주십시오.')
            return;
        }

        if (!data.title) {
            toast.warn('제목을 입력해 주십시오.')
            return;
        }

        if (selected.length == 0) {
            toast.warn('수신자를 선택해 주십시오.')
            return;
        }

        if(!data.isAllProd){
            if (!startDate || !endDate) {
                toast.warn('공지기간을 입력해 주십시오.')
                return;
            }
        }

        if (!data.contents) {
            toast.warn('내용을 입력해 주십시오.')
            return;
        }

        if (!window.confirm('저장 하시겠습니까?')) {
            return;
        }

        let param = data;

        param.noticeStartDate = startDate;
        param.noticeEndDate = endDate;
        param.regSeq = user.userSeq;
        param.updtrSeq = user.userSeq;
        param.siteId = props.siteId;
        let receptionCompanySeqArray: any = [];
        selected.map((data: any) => {
            receptionCompanySeqArray.push(data.value)
        })
        param.rgstCmpnySeq = user.blngCmpnySeq;
        param.receptionCompanySeqArray = receptionCompanySeqArray;

        // @ts-ignore
        var formData: any = new FormData(formRef.current)

        if(fileList.length > 0){
            for(var i =0;i<fileList.length;i++){
                formData.append('atchFileList',fileList[i]);
            }
        }

        formData.set('noticeVo', new Blob([JSON.stringify(param)], {type: "application/json"}))

        spinner.start();
        axios.post('/admin/api/notice/saveNoticeData', formData).then((result) => {

            if (result.data > 0) {
                toast.success(messageCodeDic['001'])
                props.setModalIsOpen(false);
            } else {
                toast.error(errorCodeDic['003']);
            }
        }).catch(error => {
            toast.error(errorCodeDic['003']);
            console.log('error: ', error);
        })
        .finally(() => {
            spinner.stop();
        });

    }


    const fn_isAllProdChg = (e: any) => {
        if(e.target.value){
            setStartDate('');
            setEndDate('');
        }
    }

    return (
        <div>
            <Modal
                className="bmc-modal" size="xl"
                show={props.modalIsOpen}
                onShow={afterOpenModal}
                onHide={fn_closeModal}

            >
                <Modal.Header>
                    <div className="d-flex justify-content-between align-items-center w-100 bmc-font-SD">
                        공지사항 등록
                        <IoMdClose className='hover:cursor-pointer' onClick={fn_closeModal}/>
                    </div>
                </Modal.Header>

                <Modal.Body className="px-4">
                    <form name={'noticeDetailForm'} ref={formRef} encType="multipart/form-data" method={"post"}
                          onSubmit={submit}>
                        <div className="bmc-modal-div">
                            <div className="div-header">
                                공지사항 정보
                            </div>

                            <div className="div-body px-3">
                                <Table className="my-2 align-middle">
                                    <tbody>
                                    <tr className="border-top align-middle">
                                        <th className="bmc-bg-th" style={{width: '15%'}}>제목</th>
                                        <td style={{width: '35%'}}>
                                            <BMCInput type="text" className="form-control" name={'title'}
                                                     onChange={updateData} value={data?.title}/>
                                        </td>
                                        <th className="bmc-bg-th" style={{width: '15%'}}>수신자 선택</th>
                                        <td style={{width: '35%'}}>
                                            <MultiSelect className={'form-control'}
                                                         options={cmpnyCodeList}
                                                         value={selected}
                                                         onChange={setSelected}
                                                         labelledBy={"수신자 선택"}
                                                         isCreatable={false}
                                                         overrideStrings={COMMON_MULTISEL_STRINGS}
                                            />
                                        </td>
                                    </tr>
                                    <tr className="border-top align-middle">
                                        <th className="bmc-bg-th" style={{width: '15%'}}>공지기간</th>
                                        <td style={{width: '35%'}}>
                                            <div className="d-flex align-items-center w-100" ref={prodRef}>
                                                <BMCDatePicker
                                                    className={"text-center"} onClick={fn_datePopupOpen} calendar={fn_datePopupOpen}
                                                    value={startDate} disabled={data?.isAllProd || false} name={'noticeStartDate'} onChange={updateData}
                                                    readOnly={true}/>
                                                <span>&nbsp;~&nbsp;</span>
                                                <BMCDatePicker name={'noticeEndDate'} calendar={fn_datePopupOpen}
                                                               className={"text-center"}
                                                               onClick={fn_datePopupOpen}
                                                               value={endDate} disabled={data?.isAllProd || false} onChange={updateData} readOnly={true}/>

                                                {(isDatePopupOpen && !data?.isAllProd)
                                                    ? <CustomDatePickerRange setIsDatePopupOpen={setIsDatePopupOpen}
                                                                             prodRef={prodRef}
                                                                             setStartDateView={setStartDate}
                                                                             setEndDateView={setEndDate}/>
                                                    : null}

                                                <BMCCheckbox name={'isAllProd'} onChange={(e: any) => {updateData(e); fn_isAllProdChg(e);}}
                                                            checked={data?.isAllProd} className={'ml-5 w-[110px]'}>전체</BMCCheckbox>
                                            </div>
                                        </td>

                                    </tr>
                                    <tr className="border-top align-middle">
                                        <th className="bmc-bg-th" style={{width: '15%'}}>내용</th>
                                        <td style={{width: '85%'}} colSpan={3}>
                                            <BMCTextarea rows={5} onChange={updateData} name={'contents'}
                                                        value={data?.contents}
                                                        className={"form-control"}
                                                        placeholder='내용을 입력해주세요.'/>
                                        </td>
                                    </tr>
                                    <tr className="align-middle" style={{width: '15%'}}>
                                        <th className="bmc-bg-th">첨부파일</th>
                                        <td colSpan={3}>
                                            <BMCFile fileName={'files'} fileList={fileList} setFileList={setFileList}></BMCFile>
                                        </td>
                                    </tr>
                                    </tbody>
                                </Table>
                            </div>
                        </div>
                        <div className="bmc-modal-div">
                            <div className="div-header">
                                작성정보
                            </div>
                            <div className="div-body px-3">
                                <Table className="my-2 align-middle">
                                    <tbody>
                                    <tr className="border-top align-middle">
                                        <th className="bmc-bg-th" style={{width: '15%'}}>작성일</th>
                                        <td style={{width: '35%'}}>
                                            <input value={data?.regDate || currentDate}
                                                   className={"form-control"}
                                                   disabled={true}/>
                                        </td>
                                        <th className="bmc-bg-th" style={{width: '15%'}}>작성자</th>
                                        <td style={{width: '35%'}}>
                                            <input className={"form-control"}
                                                   value={data?.rgstrNm || user?.userName} disabled={true}/>
                                        </td>
                                    </tr>
                                    </tbody>
                                </Table>
                            </div>
                        </div>
                    </form>
                </Modal.Body>
                <Modal.Footer className="py-2">
                    <div className='flex justify-center w-100'>
                        <button
                            className='bmc-btn pill l me-3'
                            onClick={fn_closeModal}>닫기
                        </button>
                        <button onClick={fn_registData}
                                className='bmc-btn pill p white'>등록/수정

                        </button>
                    </div>
                </Modal.Footer>
            </Modal>
        </div>
    )
}

export default NoticeRegistModal;

