import React, {Dispatch, SetStateAction, useContext, useEffect, useRef, useState} from "react";
import {COMMON_MODAL_STYLE} from "../../../helper/StyleHelper";
import {Col, Modal, Row, Table} from "react-bootstrap";
import {IoMdClose} from "react-icons/io";
import Datatables, {getCheckedItems} from "../../component/Datatables";
import axios from "axios";
import {toast} from "react-toastify";
import messageCodeDic from "../../../util/messageCodeDic";
import BMCSelect from "../../../components/common/BMCSelect";
import {getLoginUser, setItemWithExpireTime, USER_SESSION} from "../../../helper/SecurityHelper";
import SHSearch from "../../component/BMCSearch";
import BMCInput from "../../../components/common/BMCInput";
import restapi from "../../../helper/restAPIHelper";
import SiteSftyChkRgstModal from "../../safetyWorkReportManagement/modal/SiteSftyChkRgstModal";
import SiteManageRgstModal from "../../headManage/modal/SiteManageRgstModal";
import {SiteContext} from "../../../context/Site";
import {useNavigate} from "react-router-dom";
import errorCodeDic from "../../../util/errorCodeDic";
import {ProgressContext} from "../../../context/Progress";
import {UserContext} from "../../../context/User";
import IvstSftyChkModal from "./IvstSftyChkModal";
import IvstSftyChkRsltModal from "./IvstSftyChkRsltModal";

interface IProps {
    setModalIsOpen: Dispatch<SetStateAction<boolean>>;
    modalIsOpen: boolean;
    dataList: object[];

}

const IvstSftyChkInfoListModal = (props: IProps) => {
    const [datatable, setDatatable] = useState<any>();

    const [isCodeDataLoaded, setIsCodeDataLoaded] = useState<any>(null);
    const searchFormRef = useRef<any>();
    const [dataList, setDataList] = useState<any>([]);
    const [detailData, setDetailData] = useState(null);
    const [isRsltModalOpen, setIsRsltModalOpen] = useState<boolean>(false);
    const [modalIsOpen, setModalIsOpen] = React.useState(false);

    useEffect(() => {
        if (isCodeDataLoaded) {
            fn_search();
        }
    }, [isCodeDataLoaded]);

    function fn_closeModal() {
        props.setModalIsOpen(false);
    }

    const fn_search = (e?: any) => {
        e?.preventDefault()
        datatable?.draw()
    }

    const fn_onShow = () => {

        setDataList(props.dataList);
    }



    return (
        <div>
            <IvstSftyChkModal setModalIsOpen={setModalIsOpen} modalIsOpen={modalIsOpen} detailData={detailData}/>
            <IvstSftyChkRsltModal setModalIsOpen={setIsRsltModalOpen} modalIsOpen={isRsltModalOpen} detailData={detailData} />
            <Modal
                className="bmc-modal" size="xl"
                show={props.modalIsOpen}
                onShow={() => {
                    fn_onShow()

                }}
                onHide={fn_closeModal}
            >

                <Modal.Header>
                    <div className="d-flex justify-content-between align-items-center w-100 bmc-font-SD">
                        출자토지 현장점검 목록
                        <IoMdClose className='hover:cursor-pointer' onClick={fn_closeModal}/>
                    </div>
                </Modal.Header>

                <Modal.Body className="px-4">

                    <div className={'ge-result'}>

                        <Datatables
                            datas={dataList}
                            scrollY="550px"
                            className="bmc-fs-b4"
                            parameterFormRef={searchFormRef}
                            columns={[
                                {data: "guNm", title: "구", textAlign: 'center',render: (data: any, type: any, row: any) => {
                                        if(!data){
                                            return data;
                                        }else{
                                            let n = data.split(" ");
                                            return n[n.length - 1];
                                        }
                                    }
                                },
                                {data: "dongNm", title:"동", textAlign: 'center',render: (data: any, type: any, row: any) => {
                                        if(!data){
                                            return data;
                                        }else{
                                            let n = data.split(" ");
                                            return n[n.length - 1];
                                        }
                                    }
                                },
                                {data: "jibun", title: "지번", textAlign: 'center'},
                                {data: "landTypeNm", title: "지목", textAlign: 'center'},
                                {data: "landStatusNm", title: "현황", textAlign: 'center'},
                                {data: "areaRange", title: "공부면적(m<sup>2</sup>)", textAlign: 'center'},
                                {data: "sftyChkDt", title: "점검예정일시", textAlign: 'center', render: (data: any, type: any, row: any) => {
                                        return row.sftyChkDt + "&nbsp;&nbsp;" + row.sftyChkHour + ':' + row.sftyChkMin;
                                    }},
                                {data: "ivstSprvDprtGbNm", title: "현장점검", textAlign: 'center'},
                                {data: 'sftyChkGbNm', title: '전문가 점검결과', textAlign: 'center', render: (data: any, type: any, row: any) => {
                                        if(row.ivstPrblmTypeNm == null){
                                            return '<button class="bg-[#3561eb] hover:bg-blue-700 text-white font-bold py-2 px-4 rounded">결과등록</button>';
                                        }else{
                                            return '<span class="text-[#3561eb] text-decoration-underline cursor-pointer">입력완료</span>';
                                        }
                                    }},
                                /*{data: 'ivstPrblmTypeNm', title: '이상유무', textAlign: 'center'},*/
                            ]}
                            checked={false}
                            onLoad={(dt: any) => {
                                setDatatable(dt)
                            }}

                            onClick={(data, gb, e) => {
                                setDetailData(Object.assign({},data))

                                if(gb == 'btn' || gb == 'span') {
                                    setIsRsltModalOpen(true);
                                } else {
                                    setModalIsOpen(true);
                                }
                            }}
                        />
                    </div>
                </Modal.Body>
                <Modal.Footer className="py-2">
                    <div className='flex justify-center w-100'>
                        <button
                            className='bmc-btn pill l white me-3'
                            onClick={fn_closeModal}>닫기
                        </button>

                    </div>
                </Modal.Footer>


            </Modal>
        </div>
    )
}

export default IvstSftyChkInfoListModal;
