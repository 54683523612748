import React, {useContext, useEffect, useState} from "react";
import {Container, Row, Col} from "react-bootstrap";
import {ReactComponent as IconRight} from "../../assets/images/ico/icon-right.svg";
import { Chart as ChartJS, ArcElement, Tooltip } from 'chart.js';
import {Doughnut} from 'react-chartjs-2';
import {UserContext} from "../../context/User";
import {getLoginUser} from "../../helper/SecurityHelper";
import restapi from "../../helper/restAPIHelper";
import {useNavigate} from "react-router-dom";
import RskAsmntInfoListModal from "./modal/RskAsmntInfoListModal";

ChartJS.register(ArcElement, Tooltip);

const DashRiskAssessment = () => {
    const {user} = useContext<any>(UserContext);
    const defaultUser = getLoginUser();
    const [cntStatData, setCntStatData] = useState<any>({cmpltdCnt:0, ingCnt:0, rjctCnt:0})
    const navigate = useNavigate();
    const [isRskAsmntInfoListModalOpen, setIsRskAsmntInfoListModalOpen] = useState<boolean>(false);
    const [prcsStatGb, setPrcsStatGb] = useState<string>('');
    const [data, setData] = useState<any>({
        labels: ['완료', '진행', '반려'],
        datasets: [
            {
                data: [35, 21, 21],
                backgroundColor: [
                    'rgba(86, 133, 247, 1)',
                    'rgba(74, 198, 255, 1)',
                    'rgba(211, 154, 255, 1)',
                ],
                borderColor: [
                    'rgba(255, 255, 255, 1)',
                    'rgba(255, 255, 255, 1)',
                    'rgba(255, 255, 255, 1)',
                ],
                borderWidth: 1,
                hoverOffset: 5,
            },
        ],
    });
    const plugin = {
        id: 'emptyDoughnut',
        afterDraw(chart: any, args: any, options: any) {
            const {datasets} = chart.data;
            const {color, width, radiusDecrease} = options;
            let hasData = false;
            for (let i = 0; i < datasets[0].data.length; i += 1) {
                const dataset = datasets[0].data[i];
                hasData = dataset > 0;
                if(hasData === true){
                    break;
                }
            }
            if (!hasData) {
                const {chartArea: {left, top, right, bottom}, ctx} = chart;
                const centerX = (left + right) / 2;
                const centerY = (top + bottom) / 2;
                const r = Math.min(right - left, bottom - top) / 2;

                ctx.beginPath();
                ctx.lineWidth = width || 2;
                ctx.strokeStyle = color || 'rgba(255, 128, 0, 0.5)';
                ctx.arc(centerX, centerY, (r - radiusDecrease || 0), 0, 2 * Math.PI);
                ctx.stroke();
            }
        }
    };
    const options = {
        responsive: true,
        plugins: {
            legend: {
                display: false,
            },
            emptyDoughnut: {
                color: '#cccccc55',
                width: 20,
                radiusDecrease: 15
            }
        },
        cutout: '70%',
    };

    useEffect(() => {
        const siteId = user?.blngSiteId || defaultUser.blngSiteId;

        restapi.retrieveRskAsmntTotDataList({siteId:siteId}).then((rsltData: any) => {
            console.log('rskAsmnt rsltData: ', rsltData);
            let cmpltdCnt = 0;
            let ingCnt = 0;
            let rjctCnt = 0;
            rsltData.map((data: any) => {
                // 완료
                if(data.prcsStatCd == 'S001004'){
                    cmpltdCnt++;
                // 반려
                }else if(data.prcsStatCd == 'S001005'){
                    rjctCnt++;
                // 진행
                }else if(['S001002', 'S001003'].includes(data.prcsStatCd)){
                    ingCnt++;
                }
            })
            setData({
                labels: ['완료', '진행', '반려'],
                datasets: [
                    {
                        data: [cmpltdCnt, ingCnt, rjctCnt],
                        backgroundColor: [
                            'rgba(86, 133, 247, 1)',
                            'rgba(74, 198, 255, 1)',
                            'rgba(211, 154, 255, 1)',
                        ],
                        borderColor: [
                            'rgba(255, 255, 255, 1)',
                            'rgba(255, 255, 255, 1)',
                            'rgba(255, 255, 255, 1)',
                        ],
                        borderWidth: 1,
                        hoverOffset: 5,
                    },
                ],
            })


            const cntData = {cmpltdCnt:cmpltdCnt, ingCnt:ingCnt, rjctCnt:rjctCnt};
            setCntStatData(cntData);
        })
    }, [user])

    const fn_openRskAsmntInfoListModal = (prcsStatGb: string) => {
        setPrcsStatGb(prcsStatGb);
        setIsRskAsmntInfoListModalOpen(true);

    }

    return(
        <>
            <Container fluid className="bmc-bg-pane rounded-[8px] h-100">
                <RskAsmntInfoListModal setModalIsOpen={setIsRskAsmntInfoListModalOpen} modalIsOpen={isRskAsmntInfoListModalOpen} siteId={user?.blngSiteId || defaultUser.blngSiteId} prcsStatGb={prcsStatGb} ></RskAsmntInfoListModal>
                <Row className="px-0">
                    <Col bsPrefix="col-7" className="w-100">
                        <div className="w-100 d-flex align-items-center justify-content-between pb-2 border-bottom">
                            <span className="pe-2 bmc-txt-size-h4">위험성 평가</span>
                            <div className={'cursor-pointer'} onClick={() => navigate('/siteSafetyManagement/riskAssessmentManagement')}>
                                <IconRight style={{width: '20px', marginBottom: '1px', stroke: '#1d1b39'}} />
                            </div>
                        </div>
                    </Col>
                </Row>
                <Row className="px-0 pt-3">
                    <Col>
                        <div className="d-flex w-100 position-relative justify-content-center" style={{height:'13vh'}}>
                            <Doughnut className="position-relative z-50" options={options} data={data} plugins={[plugin]} width="100%" height="13vh" />
                            <div className="position-absolute w-100 text-center start-50 top-50 z-10" style={{transform: 'translate(-50%, -50%)'}}>
                                <div className="bmc-fs-b3 bmc-text-font_secondary02">전체</div>
                                <div>
                                    <span className="bmc-fw-5 bmc-fs-h2">{cntStatData.cmpltdCnt + cntStatData.ingCnt + cntStatData.rjctCnt}</span>
                                    <span className="bmc-fs-b3">건</span>
                                </div>
                            </div>
                        </div>
                    </Col>
                </Row>
                <Row className="px-0 pt-3 bmc-txt-size-b7">
                    <Col className="d-flex align-items-center justify-content-between">
                        <div className={'cursor-pointer text-center'} onClick={() => fn_openRskAsmntInfoListModal('cmpltd')}>
                            <div>
                                <span className="bmc-txt-size-h5">{cntStatData.cmpltdCnt}</span>
                            </div>
                            <div>
                                <span className="bmc-txt-color-01">●</span>
                                <span className="px-1">완료</span>
                            </div>
                        </div>
                    </Col>
                    <Col className="border-start border-end">
                        <div className={'cursor-pointer text-center'} onClick={() => fn_openRskAsmntInfoListModal('ing')}>
                            <div>
                                <span className="bmc-txt-size-h5">{cntStatData.ingCnt}</span>
                            </div>
                            <div>
                                <span className="bmc-txt-color-02">●</span>
                                <span className="px-1">진행</span>
                            </div>
                        </div>
                    </Col>
                    <Col>
                        <div className={'cursor-pointer text-center'} onClick={() => fn_openRskAsmntInfoListModal('rjct')}>
                            <div>
                                <span className="bmc-txt-size-h5">{cntStatData.rjctCnt}</span>
                            </div>
                            <div>
                                <span className="bmc-txt-color-03">●</span>
                                <span className="px-1">반려</span>
                            </div>
                        </div>
                    </Col>
                </Row>
            </Container>
        </>
    );
};

export default DashRiskAssessment;

