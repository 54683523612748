import React, {Dispatch, SetStateAction, useContext, useEffect, useRef, useState} from "react";
import {COMMON_MODAL_STYLE} from "../../../helper/StyleHelper";
import {Col, Modal, Row, Table} from "react-bootstrap";
import {IoMdClose} from "react-icons/io";
import Datatables, {getCheckedItems} from "../../component/Datatables";

import {ProgressContext} from "../../../context/Progress";
import NoticeRegistModal from "../../noticeManagement/modal/NoticeRegistModal";
import WrkPrmtProdRgstModal from "../../siteSafetyManagement/modal/WrkPrmtProdRgstModal";

interface IProps {
    setModalIsOpen: Dispatch<SetStateAction<boolean>>;
    modalIsOpen: boolean;
    dataList: any;
    siteId: number
}

const $ = require("jquery")

const SmSiteWrkListModal = (props: IProps) => {

    const [dataList, setDataList] = useState<any>([]);
    const {spinner} = useContext(ProgressContext);
    const [modalIsOpen, setModalIsOpen] = React.useState(false);
    const [dtlData, setDtlData] = useState(null);

    function fn_closeModal() {
        props.setModalIsOpen(false);
    }

    const fn_onShow = () => {
        setDataList(props.dataList);

    }

    return (
        <div>
            <WrkPrmtProdRgstModal setModalIsOpen={setModalIsOpen} modalIsOpen={modalIsOpen}
                                  siteId={props.siteId}
                                  detailData={dtlData}/>
            <Modal
                className="bmc-modal sub" size="xl"
                show={props.modalIsOpen}
                onShow={() => {
                    fn_onShow()

                }}
                onHide={fn_closeModal}
            >


                <Modal.Header>
                    <div className="d-flex justify-content-between align-items-center w-100 bmc-font-SD">
                        {'유지보수 현장 목록'}
                        <IoMdClose className='hover:cursor-pointer' onClick={fn_closeModal}/>
                    </div>
                </Modal.Header>

                <Modal.Body className="px-4">
                    <div className={'ge-result'}>
                        <Datatables
                            datas={dataList}
                            scrollY="550px"
                            className="bmc-fs-b4"
                            columns={[
                                {data: "wrkDtProd", title: "작업기간", textAlign: 'center'},
                                {data: "companyName", title: "업체", textAlign: 'center'},
                                {data: "", title: "현장명", textAlign: 'center' ,render: function (data: any, type: any, row: any, meta: any) {
                                        return row.wrkSiteNm.length > 23 ? row.wrkSiteNm.substring(0,23) + '...' : row.wrkSiteNm
                                    }},
                                {data: "istrctns", title: "작업사항", textAlign: 'center'},
                                {data: "calTotWrkrCnt", title: "작업인원", textAlign: 'center'},

                            ]}
                            checked={false}
                            onLoad={(dt: any) => {
                            }}

                            onClick={(data) => {
                                setDtlData(data);
                                setModalIsOpen(true);
                            }
                            }
                        />
                    </div>
                </Modal.Body>
                <Modal.Footer className="py-2">
                    <div className='flex justify-center w-100'>
                        <button
                            className='bmc-btn pill l white me-3'
                            onClick={fn_closeModal}>닫기
                        </button>

                    </div>
                </Modal.Footer>


            </Modal>
        </div>
    )
}

export default SmSiteWrkListModal;
