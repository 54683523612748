import React, {Dispatch, SetStateAction, useEffect, useRef, useState} from "react";
import {COMMON_MODAL_STYLE} from "../../../helper/StyleHelper";
import {Col, Modal, Row, Table} from "react-bootstrap";
import {IoMdClose} from "react-icons/io";
import Datatables, {getCheckedItems} from "../../component/Datatables";
import axios from "axios";
import {toast} from "react-toastify";
import messageCodeDic from "../../../util/messageCodeDic";
import BMCSelect from "../../../components/common/BMCSelect";
import {getLoginUser} from "../../../helper/SecurityHelper";
import SHSearch from "../../component/BMCSearch";
import BMCInput from "../../../components/common/BMCInput";

interface IProps {
    setModalIsOpen: Dispatch<SetStateAction<boolean>>;
    modalIsOpen: boolean;
    modalTitle: string;
    authCode: string;
    setMngrData: Dispatch<SetStateAction<any>>;
    dprtCd?: string;
    sprvDprtGb?: string;

}

const MngrSrchModal = (props: IProps) => {
    const [datatable, setDatatable] = useState<any>();
    const searchFormRef = useRef<any>();
    const user = getLoginUser();
    const [userSeqList, setUserSeqList] = useState<number[]>([]);


    function fn_closeModal() {
        props.setModalIsOpen(false);
    }

    const fn_search = (e?: any) => {
        e?.preventDefault()
        datatable?.draw()
    }
    const fn_select = () => {

        let userSeq = getCheckedItems(datatable,"userSeq");
        let userName =  getCheckedItems(datatable,"userName");
        let userPhone = getCheckedItems(datatable,"userPhoneReg");
        if(userSeq.length == 0){
            let msg = '';
            if(props.authCode == 'headOut'){
                msg = '외부위원을';
            }else if(props.authCode == 'headPrvnt'){
                msg = '안전전담부서 담당자를';
            }
            toast.warn(msg + ' 선택해 주십시오');
            return;
        }


        const param = {userSeq:userSeq[0], userName:userName[0], userPhone:userPhone[0]};
        console.log('userSeq: ', userSeq);
        console.log('userName: ', userName);
        console.log('userPhone: ', userPhone);

        props.setMngrData(param);
        props.setModalIsOpen(false);
    }

    const fn_onShow = () => {

        fn_search()
    }

    return (
        <div>

            <Modal
                className="bmc-modal sub" size="xl"
                show={props.modalIsOpen}
                onShow={() => {
                    fn_onShow()

                }}
                onHide={fn_closeModal}
            >


                <Modal.Header>
                    <div className="d-flex justify-content-between align-items-center w-100 bmc-font-SD">
                        {props.modalTitle}
                        <IoMdClose className='hover:cursor-pointer' onClick={fn_closeModal}/>
                    </div>
                </Modal.Header>

                <Modal.Body className="px-4">

                        <SHSearch datatable={datatable} formRef={searchFormRef} btnType={'p'}>
                            <BMCInput type={'hidden'} name={'authCode'} value={props.authCode}></BMCInput>
                            <Row>
                                <Col>
                                    <Table className="bmc-font-SP bmc-fs-b2 my-3">
                                        <tbody>
                                        <tr className="border-top align-middle">
                                            {props.sprvDprtGb == 'C006002' &&
                                                <>
                                                <th className="bmc-bg-th" style={{width: '6%'}}>부서</th>
                                                <td style={{width: '27%'}}>
                                                <BMCSelect initOptionGb={'all'} disabled={true} codeGb={'cmmn'} codeGrpId={'D001'} value={props.dprtCd}/>
                                                    <BMCInput type={'hidden'} name={'dprtCd'} value={props.dprtCd}></BMCInput>
                                                </td>
                                                </>
                                            }

                                            <th className="bmc-bg-th" style={{width: '6%'}}>이름</th>
                                            <td style={{width: '27%'}}>
                                                <BMCInput name={'userName'} className={'form-control'}
                                                ></BMCInput>
                                            </td>
                                            <th className="bmc-bg-th" style={{width: '6%'}}>직위</th>
                                            <td style={{width: '27%'}}>
                                                <BMCInput name={'position'} className={'form-control'}
                                                ></BMCInput>
                                            </td>

                                        </tr>
                                        </tbody>
                                    </Table>
                                </Col>

                            </Row>
                        </SHSearch>
                    <div className={'ge-result'}>
                        <Datatables
                            dataUrl={'/admin/api/user/retrieveUserDataListWithView'}
                            parameterFormRef={searchFormRef}
                            checkedDataList={userSeqList}
                            pk={'userSeq'}
                            columns={[
                                {data: "userName", title: "이름", textAlign: 'left'},
                                {data: "position", title: "직위", textAlign: 'center'},
                                {data: "userPhoneReg", title: "전화번호", textAlign: 'center'},
                                {data: "regDate", title: "등록일", textAlign: 'center'},
                            ]}
                            checked={true}
                            isSingleCheck={true}
                            onLoad={(dt: any) => {
                                setDatatable(dt)
                            }}
                            onClick={(data) => {

                            }
                            }
                        />
                    </div>
                </Modal.Body>
                <Modal.Footer className="py-2">
                    <div className='flex justify-center w-100'>
                        <button
                            className='bmc-btn pill l white me-3'
                            onClick={fn_closeModal}>닫기
                        </button>
                        <button onClick={fn_select}
                                className='bmc-btn pill p'>선택

                        </button>
                    </div>
                </Modal.Footer>


            </Modal>
        </div>
    )
}

export default MngrSrchModal;
