import React, {useContext, useEffect, useState} from 'react';
import { Container } from 'react-bootstrap';
import ImageGallery from "react-image-gallery";
import SubMenuTitle from "../component/SubMenuTitle";
import restapi from "../../helper/restAPIHelper";
import {toast} from "react-toastify";
import errorCodeDic from "../../util/errorCodeDic";
import {UserContext} from "../../context/User";
import {getLoginUser} from "../../helper/SecurityHelper";
import {ProgressContext} from "../../context/Progress";
import CnstrPrgsFileRgstModal from "./modal/CnstrPrgsFileRgstModal";
import BMCIconButton from "../../components/common/BMCIconButton";

type Props = {};

const ConstructionProcessProgressManagementPage = (props: Props) => {
    const {user} = useContext<any>(UserContext);
    const defaultUser = getLoginUser();
    const {spinner} = useContext(ProgressContext);
    const [fileList, setFileList] = useState([]);
    const [repFileSn, setRepFileSn] = useState<any>(null);
    const [modalIsOpen, setModalIsOpen] = useState<boolean>(false);

    useEffect(() => {
        const siteId = user?.blngSiteId || defaultUser.blngSiteId;
        spinner.start()
        restapi.retrieveSiteDataListNoPaging({siteId: siteId}).then((rsltData: any) => {
            const dtlData = rsltData[0];
            if (dtlData.atchFileId) {
                restapi.retrieveAttachInfoList({attachSeq: dtlData.atchFileId}).then((rsltData: any) => {


                    rsltData.map((data: any, idx: number) => {
                        if (data.sn == dtlData.repPhotoSn) {
                            data.isRep = true
                            setRepFileSn(idx);

                        }

                        data.original = process.env.REACT_APP_FILE_URL + "/bmcss-data/fileDownload?attachSeq=" + data?.attachSeq + '&sn=' + data?.sn + '&isPdf=0&time=' + new Date().getTime()
                        data.thumbnail = data.original;
                        data.originalTitle = data.description;
                        data.thumbnailTitle = data.description;
                        data.thumbnailLabel = data.description;

                        console.log('rsltData: ', rsltData);
                    })

                    console.log('file rsltData: ', rsltData)

                    setFileList([].concat(rsltData));
                })
            } else {
                setFileList([]);
            }

        }).catch(() => {
                toast.error(errorCodeDic['003'])
            }
        ).finally(() => {
            spinner.stop();
        })
    }, [user, modalIsOpen])

    return (
        <Container fluid className="bg-white px-0 bmc-txt-color-primary">
            <CnstrPrgsFileRgstModal setModalIsOpen={setModalIsOpen} fileList={fileList} modalIsOpen={modalIsOpen}></CnstrPrgsFileRgstModal>
            <SubMenuTitle title={'공사공정진행관리'} parentMenu={'현장공사관리'} />
            <div className="p-4 overflow-y-auto" style={{height: 'calc(100vh - 180px)'}}>
                <div className='w-100 d-flex justify-content-end align-items-center mb-2'>
                    <BMCIconButton kind={'submit'} marginRight={'5px'} onClick={() => setModalIsOpen(true)}> 등록
                    </BMCIconButton>
                </div>
                {fileList.length > 0 ? <ImageGallery items={fileList} /> : <div className="d-flex flex-column align-items-center justify-content-center"
                                                                                style={{border: "1.5px solid black"}}>
                    <div className="text-center h3 m-2">
                        <span>{'등록된 현장사진이 없습니다.'}</span>
                    </div>
                </div>
                }
            </div>
        </Container>
    );
};

export default ConstructionProcessProgressManagementPage;
