import React, {useContext, useEffect, useRef, useState} from 'react';
import Datatables, {getCheckedItems} from "../component/Datatables";
import axios from "axios";
import {toast, ToastContainer} from "react-toastify";
import messageCodeDic from "../../util/messageCodeDic";
import errorCodeDic from "../../util/errorCodeDic";
import SHSearch from "../component/BMCSearch";
import SubMenuTitle from "../component/SubMenuTitle";
import {Col, Container, Row, Table } from 'react-bootstrap';
import BMCIconButton from '../../components/common/BMCIconButton';
import {ProgressContext} from "../../context/Progress";
import CnstrBaseInfoRgstModal from '../siteConstructionManagement/modal/CnstrBaseInfoRgstModal';
import SiteManageRgstModal from "./modal/SiteManageRgstModal";
import BMCInput from "../../components/common/BMCInput";
import restapi from "../../helper/restAPIHelper";
import {getLoginUser} from "../../helper/SecurityHelper";
import BMCSelect from "../../components/common/BMCSelect";
type Props = {};

const SiteManagePage = (props: Props) => {
    const [modalIsOpen, setModalIsOpen] = React.useState(false);
    const [datatable, setDatatable] = useState<any>();
    const [siteDetailData, setSiteDetailData] = useState(null);
    const [isSaveSuccess, setIsSaveSuccess] = useState(false);
    const searchFormRef = useRef<any>();
    const {spinner} = useContext(ProgressContext);
    const user = getLoginUser();

    useEffect(() => {
        if (isSaveSuccess) {
            fn_search();
            setIsSaveSuccess(false);
        }
    }, [isSaveSuccess])

    const fn_search = (e?: any) => {
        if(e){
            e.preventDefault()
        }

        if(datatable){
            datatable.row( this ).remove().draw( false );
        }

    }

    /**
     * 등록 버튼 클릭
     */
    const fn_registPopOpen = () => {
        setSiteDetailData(null);
        setModalIsOpen(true);
    }

    const fn_delete = () => {
        let checkedData = getCheckedItems(datatable,"siteId")
        if (checkedData.length == 0) {
            toast.warn(messageCodeDic['005']);
            return;
        }

        if(!window.confirm(messageCodeDic['004'])){
            return;
        }
        spinner.start();
        axios.post('/admin/api/site/deleteSiteData', {delList: checkedData}).then(rsltData => {
            if (rsltData.data > 0) {
                toast.success(messageCodeDic['002'])
                fn_search();
            } else {
                toast.error(errorCodeDic['003'])
            }
        }).catch(() => {
            toast.error(errorCodeDic['003'])
        }).finally(() => {
            spinner.stop();
        });




    }

    const fn_makeSite = () => {
        spinner.start();
        axios.post('/admin/api/site/createSiteData', {}).then((result) => {
                if (result.data > 0) {
                    toast.success('성공');
                }
            }
        ).catch(error => {
            toast.error(errorCodeDic['003']);
            console.log('error: ', error);
        })
            .finally(() => {
                spinner.stop();
            });

    }

    return (
        <Container fluid className="bg-white px-0 bmc-txt-color-primary">
            <SubMenuTitle title={'전체 현장 관리'} parentMenu={'전체 현장 관리'} />
            <SiteManageRgstModal setModalIsOpen={setModalIsOpen} modalIsOpen={modalIsOpen}
                                    cnstrBaseInfoDtlData={siteDetailData} setIsSaveSuccess={setIsSaveSuccess}/>

            <div className="p-4 overflow-y-auto" style={{height: 'calc(100vh - 197px)'}}>
                <SHSearch datatable={datatable} formRef={searchFormRef} btnType={'p'}>
                    <Row>
                        <Col>
                            <Table className="bmc-font-SP bmc-fs-b2 mt-3 mb-3">
                                <tbody>
                                <tr className="border-top border-bottom align-middle">
                                    <BMCInput type={'hidden'} name={'isSmSite'} value={'false'}></BMCInput>
                                    <th className="bmc-bg-th" style={{width:'6%'}}>현장명</th>
                                    <td>
                                        <input type={'text'} name={"siteName"} className='form-control'/>
                                    </td>
                                    <th className="bmc-bg-th" style={{width:'6%'}}>현장구분</th>
                                    <td>
                                        <BMCSelect initOptionGb={'all'} className={'bmc-select'} codeGb={'cmmn'}
                                                   codeGrpId={'T002'}
                                                   name={'siteType'}></BMCSelect>

                                    </td>
                                    <th className="bmc-bg-th" style={{width:'6%'}}>지역(구)</th>
                                    <td>
                                        <BMCSelect initOptionGb={'all'} className={'form-select'} name={'guCd'} codeGb={'bjd'} codeGrpId={'BUSAN'}></BMCSelect>
                                    </td>
                                </tr>
                                </tbody>
                            </Table>
                        </Col>
                    </Row>
                </SHSearch>
                <div className={'ge-result'}>
                    <Datatables
                        dataUrl={'/admin/api/site/retrieveSiteDataList'}
                        parameterFormRef={searchFormRef}
                        columns={[
                            {data: "siteName", title: "현장명", textAlign: 'left'},
                            {data: "siteTypeNm", title:"현장구분", textAlign: 'center'},
                            {data: "siteAddress", title: "주소", textAlign: 'left'},
                            {data: "cnstrProd", title: "공사기간"},
                            {data: "cnstrPrice", title: "공사금액(원)", format: 'withCom', textAlign: 'right'},
                            {data: "rcntUpdateDate", title: "최근 업데이트", orderColumn:"REG_DATE", orderBase:true},
                        ]}
                        checked={true}
                        onLoad={(dt: any) => {
                            setDatatable(dt)
                        }}
                        onClick={(data) => {
                            setSiteDetailData(Object.assign({},data))
                            setModalIsOpen(true);
                        }}
                    >
                        {user.authCode != 'headRead' &&
                            <div className='w-100 d-flex justify-content-end align-items-center mb-2'>

                                <BMCIconButton kind={'submit'} marginRight={'5px'} onClick={fn_registPopOpen}> 등록
                                </BMCIconButton>

                                <BMCIconButton kind={'delete'} marginRight={'0'} onClick={fn_delete}> 삭제
                                </BMCIconButton>

                            </div>
                        }{/*<BMCIconButton kind={'delete'} marginRight={'0'} onClick={fn_makeSite}> 현장 생성
                                </BMCIconButton>*/}
                    </Datatables>
                </div>
            </div>
        </Container>
    );
};

export default SiteManagePage;
