import React from 'react';
import SubMenuTitle from "../component/SubMenuTitle";
import {Button, Container} from "react-bootstrap";

type Props = {};

const SafetyLawPage = (props: Props) => {
    return (
        <Container fluid className="bg-white px-0 bmc-txt-color-primary">
            <SubMenuTitle title={'안전법률'} parentMenu={'안전 정보 게시판'} />
            <div className="p-4 overflow-y-auto" style={{height: 'calc(100vh - 180px)'}}>
                <div className="d-flex flex-column align-items-center justify-content-center">
                    <div className="text-center h2 mb-5">
                        <h1>관계법령 및 안전규정</h1>
                    </div>

                    <div className="safetyLawSub d-flex flex-row mb-3" style={{width:"1000px"}}>
                        <div className="d-flex align-items-center justify-content-center w-50">
                            <span className="safetyLawSubTitle align-middle h5">중대재해처벌법</span>
                        </div>
                        <div className="d-flex flex-column w-50 p-2">
                            <div className="safetyLawLink mb-2">
                                <Button variant="link" href="https://www.law.go.kr/법령/중대재해처벌등에관한법률" target="_blank">1. 중대재해 처벌 등에 관한 법률</Button>
                            </div>
                            <div className="safetyLawLink mb-2">
                                <Button variant="link" href="https://www.law.go.kr/법령/중대재해처벌등에관한법률시행령" target="_blank">2. 중대재해 처벌 등에 관한 법률 시행</Button>
                            </div>
                        </div>
                    </div>

                    <div className="safetyLawSub d-flex flex-row mb-3" style={{width:"1000px"}}>
                        <div className="d-flex align-items-center justify-content-center w-50">
                            <span className="safetyLawSubTitle align-middle h5">산업안전보건법</span>
                        </div>
                        <div className="d-flex flex-column w-50 p-2">
                            <div className="safetyLawLink mb-2">
                                <Button variant="link" href="https://www.law.go.kr/법령/산업안전보건법" target="_blank">1. 산업안전보건법</Button>
                            </div>
                            <div className="safetyLawLink mb-2">
                                <Button variant="link" href="https://www.law.go.kr/법령/산업안전보건법시행령" target="_blank">2. 산업안전보건법 시행령</Button>
                            </div>
                            <div className="safetyLawLink mb-2">
                                <Button variant="link" href="https://www.law.go.kr/법령/산업안전보건법시행규칙" target="_blank">3. 산업안전보건법 시행규칙</Button>
                            </div>
                            <div className="safetyLawLink mb-2">
                                <Button variant="link" href="https://www.law.go.kr/법령/산업안전보건기준에관한규칙" target="_blank">4. 산업안전보건법기준에 관한 법칙</Button>
                            </div>
                        </div>
                    </div>

                    <div className="safetyLawSub d-flex flex-row mb-3" style={{width:"1000px"}}>
                        <div className="d-flex align-items-center justify-content-center w-50">
                            <span className="safetyLawSubTitle align-middle h5">건설산업기본법</span>
                        </div>
                        <div className="d-flex flex-column w-50 p-2">
                            <div className="safetyLawLink mb-2">
                                <Button variant="link" href="https://www.law.go.kr/법령/건설산업기본법" target="_blank">1. 건설산업기본법</Button>
                            </div>
                            <div className="safetyLawLink mb-2">
                                <Button variant="link" href="https://www.law.go.kr/법령/건설산업기본법시행령" target="_blank">2. 건설산업기본법 시행령</Button>
                            </div>
                            <div className="safetyLawLink mb-2">
                                <Button variant="link" href="https://www.law.go.kr/법령/건설산업기본법시행규칙" target="_blank">3. 건설산업기본법 시행 규칙</Button>
                            </div>
                        </div>
                    </div>

                    <div className="safetyLawSub d-flex flex-row mb-3" style={{width:"1000px"}}>
                        <div className="d-flex align-items-center justify-content-center w-50">
                            <span className="safetyLawSubTitle align-middle h5">건설기술 진흥법</span>
                        </div>
                        <div className="d-flex flex-column w-50 p-2">
                            <div className="safetyLawLink mb-2">
                                <Button variant="link" href="https://www.law.go.kr/법령/건설기술진흥법" target="_blank">1. 건설기술 진흥법</Button>
                            </div>
                            <div className="safetyLawLink mb-2">
                                <Button variant="link" href="https://www.law.go.kr/법령/건설기술진흥법시행령" target="_blank">2. 건설기술 진흥법 시행령</Button>
                            </div>
                            <div className="safetyLawLink mb-2">
                                <Button variant="link" href="https://www.law.go.kr/법령/건설기술진흥법시행규칙" target="_blank">3. 건설기술 진흥법 시행 규칙</Button>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </Container>
    );
};

export default SafetyLawPage;
