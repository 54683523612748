import React, {useContext, useEffect, useState} from "react";
import {Container, Row, Col} from "react-bootstrap";
import {ReactComponent as IconRight} from "../../assets/images/ico/icon-right.svg";
import Calendar from 'react-calendar';
import 'react-calendar/dist/Calendar.css';
import moment from "moment";
import IconPrev from '../../assets/images/icon/icon_circle_left.png';
import IconNext from '../../assets/images/icon/icon_circle_right.png';
import restapi from "../../helper/restAPIHelper";
import {TopMenuContext} from "../../context/TopMenu";
import SftyChkInfoListModal from "./modal/SftyChkInfoListModal";
import SiteSftyChkRgstModal from "../safetyWorkReportManagement/modal/SiteSftyChkRgstModal";
import FcltySftyChkInfoListModal from "./modal/FcltySftyChkInfoListModal";
import FcltySftyChkModal from "../fclty/modal/FcltySftyChkModal";

export default function HOMonthlyManagementCalendar() {

    const [dataList, setDataList] = useState<any>([])
    const {setMenu} = useContext<any>(TopMenuContext);
    const [modalIsOpen, setModalIsOpen] = useState<boolean>(false);
    const [sftyChkDataList, setSftyChkDataList] = useState<any>([]);
    const [sftyChkDt, setSftyChkDt] = useState<string>('');
    const yyyy = moment(new Date()).format('YYYY');
    const mm = moment(new Date()).format('MM');
    const [thisMmSftyChkData, setThisMmSftyChkData] = useState<any>([]);
    const [isDtlModalOpen, setIsDtlModalOpen] = useState<boolean>(false);
    const [detailData, setDetailData] = useState<any>({});

    useEffect(() => {

        console.log('mm: ', mm);
        const efctMm = yyyy + mm;

        restapi.retrieveFcltySftyChkSchdleDataList({}).then((rsltData: any) => {
            setDataList(rsltData);
        })

        restapi.retrieveIvstSftyManageTotDataList({workTypeCd: 'T002004', efctMm:efctMm}).then((rsltData: any) => {
            setThisMmSftyChkData(rsltData);
        });
    }, [])

    const fn_onDayClick = (e: any) => {
        const efctDt = moment(e).format("YYYY-MM-DD");

        restapi.retrieveIvstSftyManageTotDataList({efctDt: efctDt, workTypeCd: 'T002004'}).then((rsltData: any) => {
            setSftyChkDt(sftyChkDt);
            setSftyChkDataList(rsltData);
            setModalIsOpen(true);

        })

    }

    const fn_allDayClick = () => {
        restapi.retrieveSiteSftyManageDataTotList({}).then((rsltData: any) => {
            setSftyChkDt('전체');
            setSftyChkDataList(rsltData);
            setModalIsOpen(true);

        })
    }

    const fn_openSftyChkDtlModal = (data: any) => {
        setDetailData(data);
        setIsDtlModalOpen(true);
    }

    return (
        <>
            <FcltySftyChkModal setModalIsOpen={setIsDtlModalOpen} modalIsOpen={isDtlModalOpen} detailData={detailData}/>
            <FcltySftyChkInfoListModal setModalIsOpen={setModalIsOpen} modalIsOpen={modalIsOpen} dataList={sftyChkDataList} />
            <Container fluid className="bmc-bg-pane">
                <div className="d-flex align-items-center justify-content-between mb-2">
                    <span className="bmc-area-title-v3">월간 안전 점검 관리 일정</span>
                </div>
                <div className="px-0 pt-2 mb-2">
                    <div>
                        <div className="border-1 rounded-3 p-2">
                            <Calendar
                                defaultView="month"
                                formatDay={(locale, date) => moment(date).format("D")}
                                tileContent={({date, view}) => {

                                    let html = [];
                                    const filtrdDataList = dataList.filter((data: any) => (data.efctDt == moment(date).format("YYYY-MM-DD")));
                                    if(filtrdDataList.length > 0){
                                        if(filtrdDataList[0].efctDt == moment(date).format("YYYY-MM-DD")){
                                            html.push(<div key={0} className="d-flex justify-content-between align-items-center">
                                                <div style={{color: '#4A88FF'}}>●</div>
                                                <div className="bmc-text-black">+{filtrdDataList[0].efctDtCnt}</div>
                                            </div>);
                                        }
                                    }

                                    return (
                                        <div key={1}>{html}</div>
                                    );
                                }}
                                onClickDay={fn_onDayClick}
                                next2Label={null}
                                prev2Label={null}
                                nextLabel={<img style={{width: '24px'}} src={IconNext} alt="다음"/>}
                                prevLabel={<img style={{width: '24px'}} src={IconPrev} alt="이전"/>}
                            />
                        </div>
                    </div>
                </div>
                <div className="px-0 pt-2">
                    <div className="d-flex mb-1">
                        <span className="fs-cstm-16 fw-cstm-600">{yyyy}년 {mm}월 안전 점검 일정</span>
                    </div>
                    <div>
                        {
                            thisMmSftyChkData.length > 0 ?
                            thisMmSftyChkData.map((data: any, idx: number) => (
                                idx < 3 &&
                                <div className="d-flex cursor-pointer" key={idx} onClick={() => fn_openSftyChkDtlModal(data)}>
                                    <div className="d-inline-flex gap-2 calendar-notice-item w-100">
                                        <span className={data.sftyDgnsGb == 'S018001' ? "calendar-notice-icon-blue" : "calendar-notice-icon-pink"}>{data.sftyDgnsGb == 'S018001' ? '정기' : '정밀'}</span>
                                        <span className="calendar-notice-title align-self-center">{data.siteName}</span>
                                    </div>
                                </div>
                            )) :
                                <div className="d-flex cursor-pointer" >
                                    <div className="d-inline-flex gap-2 calendar-notice-item w-100">
                                        <span className="calendar-notice-title align-self-center">해당 월의 안전 점검 일정이 없습니다.</span>
                                    </div>
                                </div>
                        }
                    </div>
                </div>
            </Container>
        </>
    );
}
