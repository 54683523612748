import React, {Dispatch, SetStateAction, useContext, useEffect, useRef, useState} from "react";
import {COMMON_MODAL_STYLE} from "../../../helper/StyleHelper";
import {Col, Modal, Row, Table} from "react-bootstrap";
import {IoMdClose} from "react-icons/io";
import Datatables, {getCheckedItems} from "../../component/Datatables";
import axios from "axios";
import {toast} from "react-toastify";
import messageCodeDic from "../../../util/messageCodeDic";
import BMCSelect from "../../../components/common/BMCSelect";
import {getLoginUser} from "../../../helper/SecurityHelper";
import BMCSearch from "../../component/BMCSearch";
import BMCInput from "../../../components/common/BMCInput";
import restapi from "../../../helper/restAPIHelper";
import errorCodeDic from "../../../util/errorCodeDic";
import {ProgressContext} from "../../../context/Progress";
import NoticeRegistModal from "../../noticeManagement/modal/NoticeRegistModal";
import WrkPrmtRgstModal from "./WrkPrmtRgstModal";
import RskAsmntRgstModal from "./RskAsmntRgstModal";

interface IProps {
    setModalIsOpen: Dispatch<SetStateAction<boolean>>;
    modalIsOpen: boolean;
    dtlData: any;
}

const $ = require("jquery")

const TbmRiskAsmntListModal = (props: IProps) => {

    const [dataList, setDataList] = useState<any>([]);
    const {spinner} = useContext(ProgressContext);
    const [modalIsOpen, setModalIsOpen] = React.useState(false);
    const [dtlData, setDtlData] = useState(null);

    function fn_closeModal() {
        props.setModalIsOpen(false);
    }

    const fn_onShow = () => {

        spinner.start();
        restapi
            .retrieveRskAsmntTotDataList({
                isWrkrPov: true,
                siteId: props.dtlData.blngSiteId,
                wrkDt: props.dtlData.wrkDt,
                cmpnySeq: props.dtlData.blngCmpnySeq,
            })
            .then((rsltData: any) => {
                setDataList(rsltData);
            })
            .catch(error => {
                toast.warn(errorCodeDic['003'])
            })
            .finally(() => {
                spinner.stop();
            });

    }

    return (
        <div>
            <RskAsmntRgstModal setModalIsOpen={setModalIsOpen} modalIsOpen={modalIsOpen} siteId={props.dtlData.siteId}
                               detailData={dtlData}/>

            <Modal
                className="bmc-modal sub" size="xl"
                show={props.modalIsOpen}
                onShow={() => {
                    fn_onShow()

                }}
                onHide={fn_closeModal}
            >


                <Modal.Header>
                    <div className="d-flex justify-content-between align-items-center w-100 bmc-font-SD">
                        {'위험성평가 TBM 목록'}
                        <IoMdClose className='hover:cursor-pointer' onClick={fn_closeModal}/>
                    </div>
                </Modal.Header>

                <Modal.Body className="px-4">
                    <div className={'ge-result'}>
                        <Datatables
                            datas={dataList}
                            scrollY="550px"
                            className="bmc-fs-b4"
                            columns={[
                                {data: "evlCntnts", title: "평가내용", textAlign: 'center'},
                                {data: "workTypeNm", title: "공종", textAlign: 'center'},
                                {data: "ocptNm", title: "직종", textAlign: 'center'},
                                {data: "raProd", title: "평가기간", textAlign: 'center'},

                            ]}
                            checked={false}
                            onLoad={(dt: any) => {
                            }}

                            onClick={(data) => {
                                setDtlData(data);
                                setModalIsOpen(true);
                            }
                            }
                        />
                    </div>
                </Modal.Body>
                <Modal.Footer className="py-2">
                    <div className='flex justify-center w-100'>
                        <button
                            className='bmc-btn pill l white me-3'
                            onClick={fn_closeModal}>닫기
                        </button>

                    </div>
                </Modal.Footer>


            </Modal>
        </div>
    )
}

export default TbmRiskAsmntListModal;
