import React, {useContext, useEffect, useState} from 'react';
import {Col, Container, Row, Table} from "react-bootstrap";
import {ReactComponent as IconRight} from "../../assets/images/ico/icon-right.svg";
import {ReactComponent as IconNoData} from "../../assets/images/icon/icon-folder.svg";
import {useNavigate} from "react-router-dom";
import {UserContext} from "../../context/User";
import {getLoginUser} from "../../helper/SecurityHelper";
import restapi from '../../helper/restAPIHelper';
import NoticeRegistModal from "../noticeManagement/modal/NoticeRegistModal";
import axios from "axios";
import { CmmnFn } from '../../helper/CmmnHelper';

const SSDashboardNotice = () => {

    const navigate = useNavigate();
    const {user} = useContext<any>(UserContext);
    const defaultUser = getLoginUser();
    const [dataList, setDataList] = useState<any>([]);
    const [modalIsOpen, setModalIsOpen] = React.useState(false);
    const [noticeDetailData, setNoticeDetailData] = useState(null);
    useEffect(() => {
        const siteId = user?.blngSiteId || defaultUser.blngSiteId;

        restapi.retrieveNoticeDataTotList({siteId: siteId}).then((rsltData: any) => {
            setDataList(rsltData);
        })
    }, [user])

    const fn_openNoticeModal = (data: any) => {
        setNoticeDetailData(data)
        setModalIsOpen(true);
        fn_updateViewCnt(data);
    }
    const fn_updateViewCnt = (param: object) => {
        axios.post('/admin/api/notice/updateViewCnt', param)
    }

    return(
        <>
            <NoticeRegistModal setModalIsOpen={setModalIsOpen} modalIsOpen={modalIsOpen} siteId={user?.blngSiteId || defaultUser.blngSiteId}
                               noticeDetailData={noticeDetailData} />
            <Container fluid className="bmc-bg-pane h-100">
                <Row className="px-0">
                    <Col bsPrefix="col-7" className="w-100">
                        <div className="w-100 d-flex align-items-center justify-content-between pb-2 border-bottom">
                            <span className="pe-2 bmc-site-area-title">공지사항</span>
                            <div className={'cursor-pointer'} onClick={() => navigate(CmmnFn.isSmSite()?'/smSite/noticeManagement/noticeRegistration' : '/noticeManagement/noticeRegistration')}>
                                <IconRight style={{width: '20px', marginBottom: '1px', stroke: '#1d1b39'}} />
                            </div>
                        </div>
                    </Col>
                </Row>
                <Row className="px-0 pt-2">
                    <Col>
                        {dataList.length > 0 ?
                            (
                                <div className="w-100 bmc-txt-color-primary">
                                    {
                                        dataList.map((data: any, idx: number) => (
                                            idx < 3 &&
                                            <div key={idx} className={'cursor-pointer d-flex justify-content-between align-items-center py-2 mb-1'} onClick={() => fn_openNoticeModal(data)} style={{borderBottom:'1px dotted #e5e5ef'}}>
                                                <div style={{width: '70%', color:'#555555'}} className="border-bottom-0 text-truncate">{data.title}</div>
                                                <div style={{width: '30%', color:'#555555'}} className="text-end border-bottom-0 bmc-txt-size-b5">{data.regDate}</div>
                                            </div>
                                        ))
                                    }
                                </div>
                            ) : (
                                <div className="d-flex flex-column justify-content-center align-items-center" style={{height: 'calc(100% - 20px)'}}>
                                    <IconNoData style={{width: '40px', height: '40px', marginBottom: '10px'}} />
                                    <div className="bmc-txt-color-gray-01 bmc-txt-size-b6">등록된 데이터가 없습니다.</div>
                                </div>
                            )
                        }
                    </Col>
                </Row>
            </Container>
        </>
    );
}

export default SSDashboardNotice;
