import { colors } from "@mui/material";

const colorConfigs = {
  sidebar: {
    bg: "#1E1F23",
    color: "#8E8E8E",
    hoverBg: "#3DEC8D",
    activeBg: "#3DEC8D"
  },
  topbar: {
    bg: "#2B358B",
    headBg: "#00AAC7",
    color: "#000"
  },
  dashboardBg: "#f8f9fe",
  mainBg: colors.grey["100"]
};

export default colorConfigs;
