import {RouteType} from "./config";
import NoticeManagementPageLayout from "../pages/noticeManagement/NoticeManagementPageLayout";
import MyInformationManagementPage from "../pages/myPage/MyInformationManagementPage";

/* SVG Icon */
import {ReactComponent as IconMonitoring} from '../assets/images/icon/icon-Union.svg';
import {ReactComponent as IconSiteConstruction} from '../assets/images/icon/icon-Browsers.svg';
import {ReactComponent as IconNotice} from '../assets/images/icon/icon-Microphone.svg';
import {ReactComponent as IconSiteSafety} from '../assets/images/icon/icon-corn.svg';
import {ReactComponent as IconFieldEquipment} from '../assets/images/icon/icon-truck.svg';
import {ReactComponent as IconSafetyWork} from '../assets/images/icon/icon-folders.svg';
import {ReactComponent as IconSiteManagement} from '../assets/images/icon/icon-setting.svg';
import SiteSftyChkPrvntPage from "../pages/safetyWorkReportManagement/SiteSftyChkPrvntPage";
import HeadMngrManagementPage from "../pages/manpowerManagement/HeadMngrManagementPage";
import React, {useEffect} from "react";
import {useNavigate} from "react-router-dom";
import HeadCompanyManagementPage from "../pages/headoffice/HeadCompanyManagementPage";
import HOTotalSafetyStatus from "../pages/headoffice/HOTotalSafetyStatus";
import HOTotalSafetyStatusV2 from "../pages/headoffice/HOTotalSafetyStatusV2";
import SiteManagePage from "../pages/headManage/SiteManagePage";
import SiteSftyChkBsnsPage from "../pages/safetyWorkReportManagement/SiteSftyChkBsnsPage";
import CctvHeadManagePage from "../pages/headManage/CctvHeadManagePage";
import SafetyNotificationManagementPage from "../pages/safetyNotificationManagement/SafetyNotificationManagementPage";
import SafetyManagementBoardPage from "../pages/safetyManagementBoard/SafetyManagementBoardPage";
import SafetyNewspaperPage from "../pages/safetyNewspaper/SafetyNewspaperPage";
import SiteWrkrMngPage from "../pages/safetyNewspaper/SiteWrkrMngPage";
import HeadSiteDocsPage from "../pages/safetyInformationBoard/HeadSiteDocsPage";
import SiteMngrManagementPage from "../pages/manpowerManagement/SiteMngrManagementPage";
import HeadManagementAdminPage from "../pages/headoffice/HeadManagementAdminPage";
import MntncSiteMngPage from "../pages/siteSftyMng/MntncSiteMngPage";
import IvstLandSitePage from "../pages/siteSftyMng/IvstLandSitePage";
import FcltySftyMngPage from "../pages/siteSftyMng/FcltySftyMngPage";
import HeadApprStatPage from "../pages/headoffice/HeadApprStatPage";
import DailySftyRprtHeadPage from "../pages/safetyWorkReportManagement/DailySftyRprtHeadPage";
import SvrAcdntPnshmntMngHeadPage from "../pages/safetyWorkReportManagement/SvrAcdntPnshmntMngHeadPage";
import CnstrctnSiteMngPage from "../pages/siteSftyMng/CnstrctnSiteMngPage";
import EnvrntCnfgPage from "../pages/headManage/EnvrntCnfgPage";
import AlrtMngStatPage from "../pages/safetyInformationBoard/AlrtMngStatPage";


const ToPage = ({ link }: any) => {
  const navigate = useNavigate();
  useEffect(()=>{
    navigate(link)
  },[])
  return (
      <>...</>
  );
};
const appRoutesHead: RouteType[] = [
  {
    index: true,
    element: <ToPage link={"/monitoring"} />,
    state: "home"
  },
  {
    path: "/monitoring",
    element: <HOTotalSafetyStatusV2/>,
    /*element: <MonitoringPage />,*/
    state: "monitoring",
    sidebarProps: {
      displayText: "전체현장관제",
      icon: <IconMonitoring />
    }
  },
  {
    path: "/totSiteStat",
    element: <SiteManagePage />,
    state: "totSiteStat",
    sidebarProps: {
      displayText: "전체현장관리",
      icon: <IconSiteConstruction />
    },
  },
  {
    path: "/siteSftyChk",
    element: <NoticeManagementPageLayout />,
    state: "siteSftyChk",
    sidebarProps: {
      displayText: "안전점검관리",
      icon: <IconNotice />
    },
    child: [
      {
        path: "/siteSftyChk/sftChkPrvnt",
        element: <SiteSftyChkPrvntPage />,
        state: "siteSftyChk.sftChkPrvnt",
        sidebarProps: {
          displayText: "안전전담부서주관"
        },
      },
      {
        path: "/siteSftyChk/sftChkBsns",
        element: <SiteSftyChkBsnsPage />,
        state: "siteSftyChk.sftChkBsns",
        sidebarProps: {
          displayText: "타사업부주관"
        },
      },
      {
        path: "/siteSftyChk/dailySftyRprtHead",
        element: <DailySftyRprtHeadPage />,
        state: "siteSftyChk.dailySftyRprtHead",
        sidebarProps: {
          displayText: "일일안전일보"
        },
      },
      {
        path: "/siteSftyChk/svrAcdntPnshmnt",
        element: <SvrAcdntPnshmntMngHeadPage />,
        state: "siteSftyChk.svrAcdntPnshmnt",
        sidebarProps: {
          displayText: "중대재해처벌법 관리"
        },
      },
    ]
  },
  {
    path: "/siteSftyMng",
    element: <NoticeManagementPageLayout />,
    state: "siteSftyMng",
    sidebarProps: {
      displayText: "현장안전관리",
      icon: <IconNotice />
    },
    child: [
      {
        path: "/siteSftyMng/mntncSiteMng",
        element: <MntncSiteMngPage />,
        state: "siteSftyMng.mntncSiteMng",
        sidebarProps: {
          displayText: "유지보수현장관리"
        },
      },
      {
        path: "/siteSftyMng/ivstLandSite",
        element: <IvstLandSitePage />,
        state: "siteSftyMng.ivstLandSite",
        sidebarProps: {
          displayText: "출자토지현장관리"
        },
      },
      {
        path: "/siteSftyMng/fcltySftyMng",
        element: <FcltySftyMngPage />,
        state: "siteSftyMng.fcltySftyMng",
        sidebarProps: {
          displayText: "시설물안전관리"
        },
      },
      /*{
        path: "/siteSftyMng/cnstrctnSiteMng",
        element: <CnstrctnSiteMngPage />,
        state: "siteSftyMng.cnstrctnSiteMng",
        sidebarProps: {
          displayText: "건설현장관리"
        },
      },*/
    ]
  },
  {
    path: "/siteCctv",
    element: <CctvHeadManagePage />,
    state: "siteCctv",
    sidebarProps: {
      displayText: "CCTV 관리",
      icon: <IconSiteSafety />
    },
  },
  {
    path: "/sftyNtc",
    element: <SafetyNotificationManagementPage />,
    state: "sftyNtc",
    sidebarProps: {
      displayText: "안전공지관리",
      icon: <IconFieldEquipment />
    },
  },
  {
    path: "/sftyInfoMng",
    element: <NoticeManagementPageLayout />,
    state: "sftyInfoMng",
    sidebarProps: {
      displayText: "안전정보관리",
      icon: <IconSafetyWork />
    },
    child: [
      {
        path: "/sftyInfoMng/sftyMng",
        element: <SafetyManagementBoardPage />,
        state: "sftyInfoMng.sftyMng",
        sidebarProps: {
          displayText: "안전관리게시판"
        },
      },
      {
        path: "/sftyInfoMng/sftyNewspaper",
        element: <SafetyNewspaperPage />,
        state: "sftyInfoMng.sftyNewspaper",
        sidebarProps: {
          displayText: "안전신문고"
        },
      },
      {
        path: "/sftyInfoMng/siteWrkrMng",
        element: <SiteWrkrMngPage />,
        state: "sftyInfoMng.siteWrkrMng",
        sidebarProps: {
          displayText: "현장근로자관리"
        },
      },
      {
        path: "/sftyInfoMng/siteDocs",
        element: <HeadSiteDocsPage />,
        state: "sftyInfoMng.siteDocs",
        sidebarProps: {
          displayText: "현장문서함"
        },
      },
      {
        path: "/sftyInfoMng/alrtMngStat",
        element: <AlrtMngStatPage />,
        state: "sftyInfoMng.alrtMngStat",
        sidebarProps: {
          displayText: "비상알림 관리 현황"
        },
      },
    ]
  },
  {
    path: "/config",
    element: <NoticeManagementPageLayout />,
    state: "config",
    sidebarProps: {
      displayText: "설정",
      icon: <IconSiteManagement />
    },
    child: [
      {
        path: "/config/sftyMng",
        element: <HeadCompanyManagementPage />,
        state: "config.sftyMng",
        sidebarProps: {
          displayText: "업체관리"
        },
      },
      {
        path: "/config/mngrMng",
        element: <HeadMngrManagementPage />,
        state: "config.mngrMng",
        sidebarProps: {
          displayText: "본사관리자관리"
        },
      },
      {
        path: "/config/siteMngrMng",
        element: <SiteMngrManagementPage />,
        state: "config.siteMngrMng",
        sidebarProps: {
          displayText: "현장관리자관리"
        },
      },
      {
        path: "/config/codeCnfg",
        element: <HeadManagementAdminPage />,
        state: "config.codeCnfg",
        sidebarProps: {
          displayText: "세부항목설정"
        },
      },
      {
        path: "/config/headApprStat",
        element: <HeadApprStatPage />,
        state: "config.headApprStat",
        sidebarProps: {
          displayText: "결재현황"
        },
      },
      {
        path: "/config/myPage",
        element: <MyInformationManagementPage />,
        state: "config.myPage",
        sidebarProps: {
          displayText: "My Page"
        },
      },
      {
        path: "/config/EnvrntCnfgPage",
        element: <EnvrntCnfgPage />,
        state: "config.myPage",
        sidebarProps: {
          displayText: "환경설정"
        },
      },
    ]
  },

];

export default appRoutesHead;
