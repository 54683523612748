import React, {Dispatch, SetStateAction, useContext, useEffect, useRef, useState} from "react";
import {IoMdClose} from "react-icons/io";
import BMCSelect from "../../../components/common/BMCSelect";
import {Modal, Table} from "react-bootstrap";
import BMCTreeView from "../../../components/common/BMCTreeView";
import restapi from "../../../helper/restAPIHelper";
import Datatables, {getCheckedItems} from "../../component/Datatables";
import SHSearch from "../../component/BMCSearch";
import BMCInput from "../../../components/common/BMCInput";
import axios from "axios";
import {toast} from "react-toastify";
import {CmmnFn} from "../../../helper/CmmnHelper";
import iconTrashBean from "../../../images/icon_trashBean.png";
import errorCodeDic from "../../../util/errorCodeDic";
import messageCodeDic from "../../../util/messageCodeDic";
import {ProgressContext} from "../../../context/Progress";

interface IProps {
    setModalIsOpen: Dispatch<SetStateAction<boolean>>;
    modalIsOpen: boolean;
    dataList: any;

}

const InOutStatModal = (props: IProps) => {

    const fn_afterOpenEvnt = () => {
    }

    function fn_closeModal() {
        props.setModalIsOpen(false);
    }



    return (
        <div>
            <Modal
                className="bmc-modal sub" size="xl"
                show={props.modalIsOpen}
                onShow={fn_afterOpenEvnt}
                onHide={fn_closeModal}
            >

                <Modal.Header>
                    <div className="d-flex justify-content-between align-items-center w-100 bmc-font-SD">
                        출역현황 상세보기
                        <IoMdClose className='hover:cursor-pointer' onClick={fn_closeModal}/>
                    </div>
                </Modal.Header>

                <Modal.Body className="px-4 bmc-modal-table-w100">

                    <div className="bmc-modal-div">
                        <div className="div-body px-3">
                            <div className="card-body">
                                <table
                                    className="bmc-table-primary w-100 dataTable no-footer">
                                    <thead>
                                    <tr>
                                        <th className={'text-center'}>No</th>
                                        <th className={'text-center'}>구</th>
                                        <th className={'text-center'}>현장</th>
                                        <th className={'text-center'}>성명</th>
                                        <th className={'text-center'}>업체</th>

                                    </tr>
                                    </thead>
                                    <tbody>
                                    {
                                        props.dataList.length > 0 ?
                                            props.dataList.map((data: any, idx:number) => (
                                                <tr key={idx}>
                                                    <td className={'text-center'}>
                                                        {idx + 1}
                                                    </td>
                                                    <td className={'text-center'}>
                                                        {data.guNm}
                                                    </td>
                                                    <td className={'text-center'}>
                                                        {data.siteName}
                                                    </td>
                                                    <td className={'text-center'}>
                                                        {data.userName}
                                                    </td>
                                                    <td className={'text-center'}>
                                                        {data.siteCmpnyNm}
                                                    </td>
                                                </tr>

                                            )) : <tr>
                                                <td className={'text-center'} colSpan={7}>출역 내역이 없습니다.</td>
                                            </tr>
                                    }

                                    </tbody>
                                </table>
                            </div>
                        </div>

                    </div>

                </Modal.Body>
                <Modal.Footer className="py-2">
                    <div className='flex justify-center w-100'>
                        <button
                            className='bmc-btn pill l white me-3'
                            onClick={fn_closeModal}>닫기
                        </button>
                    </div>
                </Modal.Footer>
            </Modal>
        </div>
    )
}

export default InOutStatModal;
