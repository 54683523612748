export default function BMCRadio({ children, value, name, disabled, onChange, className, checked, onClick }: any) {
    if(typeof checked === "string"){
        checked = eval(checked)
    }
    if(checked !== true){
        checked = false
    }
    return (
        <label className={'form-check-label bmc-input-label text-center'} onClick={onClick}>
            <input className={className ? className + ' bmc-radio form-check-input me-1 mt-1' : ' bmc-radio form-check-input me-1 mt-1'}
                   type="radio"
                   value={value}
                   name={name}
                   checked={checked || false}
                   disabled={disabled}
                   onChange={onChange}
            />
            {children}
        </label>
    );
}
