import React, {Dispatch, SetStateAction, useEffect, useRef, useState} from "react";
import {Col, Modal, Row, Table} from "react-bootstrap";
import {IoMdClose} from "react-icons/io";
import Datatables, {getCheckedItems} from "../../component/Datatables";
import {toast} from "react-toastify";
import SHSearch from "../../component/BMCSearch";
import BMCSelect from "../../../components/common/BMCSelect";
import BMCInput from "../../../components/common/BMCInput";

interface IProps {
    setModalIsOpen: Dispatch<SetStateAction<boolean>>;
    modalIsOpen: boolean;
    detailData?: any;
    blngSiteList: object[];
    setBlngSiteList: Dispatch<SetStateAction<object[]>>;
    isSingleCheck?: boolean
    siteNm?: string
    siteType?: string;
    siteTypeCodes?: any;
    isNrmSite?: boolean;

}

const HeadSiteSearchModal = (props: IProps) => {
    const [datatable, setDatatable] = useState<any>();
    const searchFormRef = useRef<any>();
    const [siteSeqList, setSiteSeqList] = useState<number[]>([]);
    const [blngSiteList, setBlngSiteList] = useState<any>([props.blngSiteList]);
    const [isCodeDataLoaded, setIsCodeDataLoaded] = useState<boolean>(false);

    useEffect(() => {
        if(isCodeDataLoaded){
            console.log('codedata loaded');
            fn_search()
        }
    }, [isCodeDataLoaded]);

    function fn_closeModal() {
        setIsCodeDataLoaded(false);
        props.setModalIsOpen(false);
    }

    const fn_onShow = () => {

        let siteSeqList:any = []
        props.blngSiteList.map((data: any) => {
            siteSeqList.push(data.siteId);
        })
        setBlngSiteList(props.blngSiteList);
        setSiteSeqList(siteSeqList);

       // fn_search()
    }

    const fn_search = (e?: any) => {
        e?.preventDefault()
        datatable?.draw()
    }
    const fn_select = () => {

        if (blngSiteList.length == 0) {
            toast.warn('현장을 선택해 주십시오.');
            return;
        }

        let rtnrDataList = [];
        if(props.isSingleCheck){
            rtnrDataList = [blngSiteList[blngSiteList.length - 1]];
        }else{
            rtnrDataList = blngSiteList;
        }

        props.setBlngSiteList(rtnrDataList);
        props.setModalIsOpen(false);
    }
    return (
        <div>
            <Modal
                className="bmc-modal sub" size="xl"
                show={props.modalIsOpen}
                onShow={() => fn_onShow()}
                onHide={fn_closeModal}
            >

                <Modal.Header>
                    <div className="d-flex justify-content-between align-items-center w-100 bmc-font-SD">
                        현장 검색
                        <IoMdClose className='hover:cursor-pointer' onClick={fn_closeModal}/>
                    </div>
                </Modal.Header>

                <Modal.Body className="px-4">
                    <SHSearch datatable={datatable} formRef={searchFormRef} btnType={'p'}>
                        <Row>
                            <Col>
                                <Table className="bmc-font-SP bmc-fs-b2 my-3">
                                    <tbody>
                                    <tr className="border-top align-middle">
                                        <th className="bmc-bg-th" style={{width: '15%'}}>공종</th>
                                        <td style={{width: '35%'}}>
                                            {/*<BMCInput type={'hidden'} name={'isNrmSite'} value={props.isNrmSite ? 'Y' : 'N'} />*/}
                                            <BMCInput type={'hidden'} name={'isSmSite'} value={'false'} />
                                            <BMCInput type={'hidden'} name={'siteTypeHidden'} value={props.siteType || ''} />
                                            <BMCSelect isCodeDataLoaded={isCodeDataLoaded} setIsCodeDataLoaded={setIsCodeDataLoaded} disabled={!!props.siteType} name={'siteType'} initOptionGb={'all'} value={props.siteType || ''} codeGb ={'cmmn'} codes={props.siteTypeCodes || ['T002001', 'T002002', 'T002003', 'T002004', 'T002005']} codeGrpId={'T002'}/>
                                        </td>
                                        <th className="bmc-bg-th" style={{width: '15%'}}>현장</th>
                                        <td style={{width: '35%'}}>
                                            <input type={'text'} name={"siteName"} value={props.siteNm}
                                                   className='form-control'/>
                                        </td>
                                    </tr>
                                    </tbody>
                                </Table>
                            </Col>
                        </Row>
                    </SHSearch>

                    <div className={'ge-result'}>
                        <Datatables
                            dataUrl={'/admin/api/site/retrieveSiteDataList'}
                            parameterFormRef={searchFormRef}
                            checkedDataList={siteSeqList}
                            pk={'siteId'}
                            columns={[
                                {data: "siteName", title: "현장명", textAlign: 'left'},
                            ]}
                            checked={true}
                            isSingleCheck={props.isSingleCheck}
                            onLoad={(dt: any) => {
                                setDatatable(dt)
                            }}
                            isNotCheckedAll={true}
                            onClick={(data) => {
                                let cstmSiteSeqList = siteSeqList;
                                let cstmBlngSiteList = blngSiteList;
                                const idx = cstmSiteSeqList.indexOf(data.siteId);

                                if(data.isChecked){
                                    if(idx == -1){
                                        cstmSiteSeqList.push(data.siteId)
                                        cstmBlngSiteList.push(data);

                                    }
                                }else{
                                    if(idx > -1){
                                        cstmSiteSeqList.splice(idx, 1);
                                        cstmBlngSiteList.splice(idx, 1);
                                    }
                                }
                                // @ts-ignore
                                setSiteSeqList([].concat(cstmSiteSeqList));
                                setBlngSiteList([].concat(cstmBlngSiteList));
                            }

                            }
                        />
                    </div>
                </Modal.Body>
                <Modal.Footer className="py-2">
                    <div className='flex justify-center w-100'>

                        <button
                            className='bmc-btn pill l white me-3'
                            onClick={fn_closeModal}>닫기
                        </button>

                        <button onClick={fn_select}
                                className='bmc-btn pill p'>선택

                        </button>
                    </div>
                </Modal.Footer>


            </Modal>
        </div>
    )
}

export default HeadSiteSearchModal;
