import React, {useContext, useEffect, useState} from "react";
import {Col, Container, Row} from "react-bootstrap";
import {ReactComponent as IconIvstTdy01} from "../../assets/images/icon/icon-ivst-tdy-01.svg";
import {ReactComponent as IconIvstTdy02} from "../../assets/images/icon/icon-ivst-tdy-05.svg";
import {ReactComponent as IconIvstTdy03} from "../../assets/images/icon/icon-ivst-tdy-04.svg";
import {ReactComponent as IconIvstTdy04} from "../../assets/images/icon/icon-ivst-tdy-03.svg";
import {ReactComponent as IconIvstTdy05} from "../../assets/images/icon/icon-ivst-tdy-02.svg";
import restapi from "../../helper/restAPIHelper";
import {toast} from "react-toastify";
import errorCodeDic from "../../util/errorCodeDic";
import {ProgressContext} from "../../context/Progress";
import IvstSftyChkInfoListModal from "../ivst/modal/IvstSftyChkInfoListModal";

export default function IvstTdyStChkSttsInfo(){
    const {spinner} = useContext(ProgressContext);
    const [totDataList, setTotDataList] = useState<any>([]);
    const [bfChkDataList, setBfChkDataList] = useState<any>([]);
    const [nPrblmDataList, setNPrblmDataList] = useState<any>([]);
    const [bfActDataList, setBfActDataList] = useState<any>([]);
    const [afActDataList, setAfActDataList] = useState<any>([]);
    const [isModalOpen, setIsModalOpen] = useState<boolean>(false);
    const [dtlDataList, setDtlDataList] = useState<any>([]);

    useEffect(() => {
        spinner.start();
        restapi.retrieveIvstSftyManageTotDataList({workTypeCd:'T002003'}).then((rsltData: any) => {
            // 전체
            const totDataList = rsltData;
            // 점검 전
            const bfChkDataList = rsltData.filter((data: any) => data.ivstSprvDprtGb == 'C007001');
            // 이상 없음
            const nPrblmDataList = rsltData.filter((data: any) => data.ivstSprvDprtGb == 'C007002');

            // 보수 조치 전
            const bfActDataList = rsltData.filter((data: any) => data.ivstSprvDprtGb == 'C007004');
            // 보수 조치 후
            const afActDataList = rsltData.filter((data: any) => data.ivstSprvDprtGb == 'C007005');

            setTotDataList(totDataList);
            setBfChkDataList(bfChkDataList);
            setNPrblmDataList(nPrblmDataList);
            setBfActDataList(bfActDataList);
            setAfActDataList(afActDataList);

        }).catch(error => {
            toast.error(errorCodeDic['003']);
            console.log('error: ', error);
        })
            .finally(() => {
                spinner.stop();
            });
    }, []);

    const fn_openDtlListPop = (gb: string) => {
        if(gb == 'tot'){
            setDtlDataList(totDataList);
        }else if(gb == 'bfChk'){
            setDtlDataList(bfChkDataList);
        }else if(gb == 'nPrblm'){
            setDtlDataList(nPrblmDataList);
        }else if(gb == 'bfAct'){
            setDtlDataList(bfActDataList);
        }else if(gb == 'afAct'){
            setDtlDataList(afActDataList);
        }
        setIsModalOpen(true);
    }

    return(
        <Container fluid className="bmc-bg-pane">
            <IvstSftyChkInfoListModal setModalIsOpen={setIsModalOpen} modalIsOpen={isModalOpen} dataList={dtlDataList} />
            <div className="d-flex align-items-center justify-content-between pb-2 border-bottom-v2 mb-2">
                <span className="bmc-area-title-v2">금일 현장점검 현황</span>
            </div>
            <div className="d-flex gap-2 w-100">
                <div style={{width: '32%'}}>
                    <div onClick={() => fn_openDtlListPop('tot')} className="cursor-pointer d-flex flex-column justify-content-between p-3 h-[156px]"
                         style={{backgroundColor: '#E1EAFF', borderRadius: 16}}
                    >
                        <div className="bmc-txt-color-primary">
                            <div className="bmc-txt-size-h2">{totDataList.length}</div>
                            <div className="bmc-txt-color-gray-02 bmc-txt-size-b5">전체 점검수</div>
                        </div>
                        <div className="d-flex justify-content-end align-items-center">
                            <IconIvstTdy01 width={35} height={35}/>
                        </div>
                    </div>
                </div>
                <div style={{width: '32%'}}>
                    <div onClick={() => fn_openDtlListPop('bfChk')} className="cursor-pointer d-flex flex-column justify-content-between p-3 h-[156px]"
                         style={{backgroundColor: '#D4FFFA', borderRadius: 16}}
                    >
                        <div className="bmc-txt-color-primary">
                            <div className="bmc-txt-size-h2">{bfChkDataList.length}</div>
                            <div className="bmc-txt-color-gray-02 bmc-txt-size-b5">점검전</div>
                        </div>
                        <div className="d-flex justify-content-end align-items-center">
                            <IconIvstTdy02 width={35} height={35}/>
                        </div>
                    </div>
                </div>
                <div style={{width: '32%'}}>
                    <div onClick={() => fn_openDtlListPop('nPrblm')} className="cursor-pointer d-flex flex-column justify-content-between p-3 h-[156px]"
                         style={{backgroundColor: '#DBF2FF', borderRadius: 16}}
                    >
                        <div className="bmc-txt-color-primary">
                            <div className="bmc-txt-size-h2">{nPrblmDataList.length}</div>
                            <div className="bmc-txt-color-gray-02 bmc-txt-size-b5">이상없음</div>
                        </div>
                        <div className="d-flex justify-content-end align-items-center">
                            <IconIvstTdy03 width={35} height={35}/>
                        </div>
                    </div>
                </div>
                <div style={{width: '32%'}}>
                    <div onClick={() => fn_openDtlListPop('bfAct')} className="cursor-pointer d-flex flex-column justify-content-between p-3 h-[156px]"
                         style={{backgroundColor: '#FFEDE2', borderRadius: 16}}
                    >
                        <div className="bmc-txt-color-primary">
                            <div className="bmc-txt-size-h2">{bfActDataList.length}</div>
                            <div className="bmc-txt-color-gray-02 bmc-txt-size-b5">보수 조치 전</div>
                        </div>
                        <div className="d-flex justify-content-end align-items-center">
                            <IconIvstTdy04 width={35} height={35}/>
                        </div>
                    </div>
                </div>
                <div style={{width: '32%'}}>
                    <div onClick={() => fn_openDtlListPop('afAct')} className="cursor-pointer d-flex flex-column justify-content-between p-3 h-[156px]"
                         style={{backgroundColor: '#FBEAEC', borderRadius: 16}}
                    >
                        <div className="bmc-txt-color-primary">
                            <div className="bmc-txt-size-h2">{afActDataList.length}</div>
                            <div className="bmc-txt-color-gray-02 bmc-txt-size-b5">보수 조치 후</div>
                        </div>
                        <div className="d-flex justify-content-end align-items-center">
                            <IconIvstTdy05 width={35} height={35}/>
                        </div>
                    </div>
                </div>
            </div>
        </Container>
    )
}
