import Modal from 'react-bootstrap/Modal';
import {IoMdClose} from "react-icons/io";
import React, {Dispatch, MutableRefObject, SetStateAction, useContext, useEffect, useRef, useState} from "react";

import {Col, Container, ModalBody, Row, Table} from "react-bootstrap";

import axios from "axios";
import {toast} from "react-toastify";

import IconRemove from "../../images/icon_remove.png";
import iconNodata from "../../images/icon_nodocdata.png";
import { getLoginUser } from '../../helper/SecurityHelper';
import {CmmnFn, fn_getCurrentDate } from '../../helper/CmmnHelper';
import { ProgressContext } from '../../context/Progress';
import restapi from '../../helper/restAPIHelper';
import messageCodeDic from '../../util/messageCodeDic';
import errorCodeDic from '../../util/errorCodeDic';
import MngrSrchModal from '../headManage/modal/MngrSrchModal';
import CmpnySrchModal from '../headManage/modal/CmpnySrchModal';
import BMCInput from '../../components/common/BMCInput';
import BMCDatePicker from '../../components/common/BMCDatePicker';
import CustomDatePickerRange from '../component/ui/DatePickerRange';
import BMCSelect from '../../components/common/BMCSelect';
import BMCIconButton from '../../components/common/BMCIconButton';
import SubMenuTitle from "../component/SubMenuTitle";
import {UserContext} from "../../context/User";
import {readonlyForm} from "../../helper/FormHelper";
import BMCFile from '../../components/common/BMCFile';


interface IProps {
}

const $ = require("jquery")
const MainSiteInfoManagePage = (props: IProps) => {
    const prodRef = useRef<any>();
    const currentDate = fn_getCurrentDate();
    const defaultUser = getLoginUser();
    const [startDate, setStartDate] = useState<string>('');
    const [endDate, setEndDate] = useState<string>('');
    const [isDatePopupOpen, setIsDatePopupOpen] = useState(false);
    const [cmpnyDataList, setCmpnyDataList] = useState<object[]>([])
    const [data, setData] = useState<any>({})
    const formRef = React.createRef<HTMLFormElement>();
    const [fileList, setFileList] = useState([]);
    const [repFileSn, setRepFileSn] = useState<any>(null);
    const {spinner} = useContext(ProgressContext);
    const [headChrgrDataList, setHeadChrgrDataList] = useState<any>([]);
    const [siteChrgrDataList, setSiteChrgrDataList] = useState<any>([]);
    const [bldrDataList, setBldrDataList] = useState<any>([]);
    const [sprvDataList, setSprvDataList] = useState<any>([]);
    const [drctDataList, setDrctDataList] = useState<any>([]);
    const [coprDataList, setCoprDataList] = useState<any>([]);
    const [isMngrModalOpen, setIsMngrModalOpen] = useState<boolean>(false);
    const [cmpnySeq, setCmpnySeq] = useState<any>(null);
    const [mngrDataList, setMngrDataList] = useState<any>([])
    const [headCmpnyInfo, setHeadCmpnyInfo] = useState<any>(null);
    const [companyGbCd, setCompanyGbCd] = useState<any>(null);
    const [isCmpnyModalOpen, setIsCmpnyModalOpen] = useState<boolean>(false);
    const [dprtCd, setDprtCd] = useState<string>('');
    const {user} = useContext<any>(UserContext);

    useEffect(() => {
        const siteId = user?.blngSiteId || defaultUser.blngSiteId;
        spinner.start()
        restapi.retrieveSiteDataListNoPaging({siteId: siteId}).then((rsltData: any) => {
            const dtlData = rsltData[0];

            setData(dtlData);
            setStartDate(dtlData.constructionStartDate)
            setEndDate(dtlData.constructionEndDate)
            if (dtlData.atchFileId) {
                restapi.retrieveAttachInfoList({attachSeq: dtlData.atchFileId}).then((rsltData: any) => {

                    if (dtlData?.repPhotoSn !== null && dtlData?.repPhotoSn !== undefined) {
                        rsltData.map((data: any, idx: number) => {
                            if (data.sn == dtlData.repPhotoSn) {
                                data.isRep = true
                                setRepFileSn(idx);

                            }
                        })
                    }

                    setFileList([].concat(rsltData));
                })
            } else {
                setFileList([]);
            }

            restapi.retrieveHeadSiteMstrUserDataList({siteId: dtlData.siteId}).then((rsltData: any) => {
                const bldrDataList = rsltData.bldrDataList;
                const headChrgrDataList = rsltData.headChrgrDataList;
                const siteChrgrDataList = rsltData.siteChrgrDataList;
                const sprvDataList = rsltData.sprvDataList;
                const drctDataList = rsltData.drctDataList;
                const coprDataList = rsltData.coprDataList;
                setHeadChrgrDataList(headChrgrDataList);
                setSiteChrgrDataList(siteChrgrDataList);
                setBldrDataList(bldrDataList);
                setSprvDataList(sprvDataList);
                setDrctDataList(drctDataList);
                setCoprDataList(coprDataList);
            })

        }).catch(() => {
                toast.error(errorCodeDic['003'])
            }
        ).finally(() => {
            spinner.stop();
        })
    }, [user])

    useEffect(() => {
        readonlyForm(formRef);
        $(formRef.current).find("input[name='siteRepNm']").removeAttr('readonly');
        $(formRef.current).find("input[name='siteSprvNm']").removeAttr('readonly');
        $(formRef.current).find("input[name='sftyMngrNm']").removeAttr('readonly');

        restapi.retrieveHeadCmpnyInfo().then((rsltData) => {
            setHeadCmpnyInfo(rsltData);
            console.log('headerInfo: ', rsltData);
        })

    }, [])

    useEffect(() => {
        // 본사
        if (companyGbCd == 'C001001') {
            console.log('mngrDataList: ', mngrDataList);
            setHeadChrgrDataList(mngrDataList);
        }
    }, [mngrDataList]);

    useEffect(() => {
        // 시공사
        if (companyGbCd == 'C001002') {
            console.log('cmpnyDataList: ', cmpnyDataList);
            setBldrDataList(cmpnyDataList);
            // 감리
        } else if (companyGbCd == 'C001003') {
            setSprvDataList(cmpnyDataList);
            // 직영
        } else if (companyGbCd == 'C001004') {
            setDrctDataList(cmpnyDataList);
        } else if (companyGbCd == 'C001005') {
            setCoprDataList(cmpnyDataList);
        }
    }, [cmpnyDataList]);


    useEffect(() => {
        console.log('repFileSn: ', repFileSn);
    }, [repFileSn])



    const fn_datePopupOpen = () => {
        setIsDatePopupOpen(!isDatePopupOpen);
    };


    const fn_openAddrPop = () => {
        // @ts-ignore
        new daum.Postcode({
            oncomplete: function (rtrnData: any) {
                // get lon, lat
                var lon = ''
                var lat = ''
                // @ts-ignore
                var geocoder = new kakao.maps.services.Geocoder();
                geocoder.addressSearch(rtrnData.address, function (result: any, status: any) {
                    // @ts-ignore
                    if (status === kakao.maps.services.Status.OK) {
                        lon = result[0].x
                        lat = result[0].y
                    }
                    setData({
                        ...data,
                        siteAddress: rtrnData.address,
                        longitude: lon,
                        latitude: lat
                    })
                });
            }
        }).open();
    }

    const fn_setFormData = (e: any) => {
        if (e.target.name == 'cnstrPrice') {
            if (e.target.value) {
                e.target.value = parseInt(e.target.value.replaceAll(',', '')).toLocaleString()
            }

        }

        setData({
            ...data,
            [e.target.name]: e.target.value
        })
    }

    const fn_registData = () => {

        if (!data) {
            toast.warn('데이터를 입력해 주십시오.')
            return;
        }

        if (!CmmnFn.isSmSite()) {
            if (!data.siteName) {
                toast.warn('현장명을 입력해 주십시오.');
                return;
            }

            if (!data.siteType) {
                toast.warn('공종을 선택해 주십시오.');
                return;
            }

            if (!data.cnstrPrice) {
                toast.warn('공사금액을 입력해 주십시오.');
                return;
            }

            if (!data.siteAddress) {
                toast.warn('주소를 입력해 주십시오.');
                return;
            }

            if (!startDate || !endDate) {
                toast.warn('공사기간을 입력해 주십시오.');
                return;
            }

            data.constructionStartDate = startDate;
            data.constructionEndDate = endDate;

            if (typeof data.cnstrPrice != 'number') {
                data.cnstrPrice = data.cnstrPrice.replace(/,/g, "");
            }
        }

        let param:any = data;

        param.headChrgrDataList = headChrgrDataList;
        param.bldrDataList = bldrDataList;
        param.sprvDataList = sprvDataList;
        param.drctDataList = drctDataList;
        param.coprDataList = coprDataList;
        param.siteChrgrDataList = siteChrgrDataList;
        param.regSeq = defaultUser.userSeq;
        param.updateSeq = defaultUser.userSeq;

        console.log(param);
        // @ts-ignore
        var formData: any = new FormData(formRef.current);

        if (fileList.length > 0 && repFileSn === null) {
            toast.warn('대표 현장사진을 선택해 주십시오')
            return;
        }

        if (fileList.length > 0) {
            param.repFileSn = repFileSn;
            for (var i = 0; i < fileList.length; i++) {

                formData.append('atchFileList', fileList[i]);
            }
        }

        console.log('fileList: ', fileList);
        console.log('param: ', param);
        console.log(123 ,formData.get('atchFileList'))

        /*        formData.set('longitude', data.longitude);
                formData.set('latitude', data.latitude);*/


        formData.set('siteInfo', new Blob([JSON.stringify(param)], {type: "application/json"}));

        if (!window.confirm('저장 하시겠습니까?')) {
            return;
        }

        spinner.start();
        axios.post('/admin/api/site/saveHeadSiteData', formData).then((result) => {
                if (result.data > 0) {
                    toast.success(messageCodeDic['001'])
                } else {
                    toast.error(errorCodeDic['003']);

                }
            }
        ).catch(error => {
            toast.error(errorCodeDic['003']);
            console.log('error: ', error);
        })
            .finally(() => {
                spinner.stop();
            });

    }
    const fn_openMngrModal = (cmpnySeq: number, companyGbCd: string) => {
        setCmpnySeq(cmpnySeq);
        setCompanyGbCd(companyGbCd);
        setIsMngrModalOpen(true);
        setDprtCd('');
        setMngrDataList(siteChrgrDataList);
    }

    const fn_delChrgrDataRow = (idx: number) => {
        headChrgrDataList.splice(idx, 1);
        setHeadChrgrDataList([].concat(headChrgrDataList));

    }

    const fn_cmpnyModalOpen = (companyGbCd: string) => {
        setCompanyGbCd(companyGbCd);
        setIsCmpnyModalOpen(true);
        // 시공사
        if (companyGbCd == 'C001002') {
            setCmpnyDataList(bldrDataList);
            // 감리
        } else if (companyGbCd == 'C001003') {
            setCmpnyDataList(sprvDataList);
            // 직영
        } else if (companyGbCd == 'C001004') {
            setCmpnyDataList(drctDataList);
            // 협력업체
        } else if (companyGbCd == 'C001005') {
            setCmpnyDataList(coprDataList)
        }
    }

    const fn_delSiteChrgrDataRow = (userSeq: number) => {
        console.log('userSeq: ', userSeq);
        const cstmSiteChrgrDataList = siteChrgrDataList.filter((data: any) => (data.userSeq != userSeq));
        setSiteChrgrDataList(cstmSiteChrgrDataList);
    }

    const fn_deleteCmpnyData = (companySeq: number, cmpnyGb: string) => {
        const cstmSiteChrgrDataList = siteChrgrDataList.filter((data: any) => (data.blngCmpnySeq != companySeq));

        let cstmCmpnyDataList;

        if(cmpnyGb == 'C001002'){
            cstmCmpnyDataList = bldrDataList.filter((data: any) => (data.companySeq != companySeq));
        }else if(cmpnyGb == 'C001003'){
            cstmCmpnyDataList = sprvDataList.filter((data: any) => (data.companySeq != companySeq));
        }else if(cmpnyGb == 'C001004'){
            cstmCmpnyDataList = drctDataList.filter((data: any) => (data.companySeq != companySeq));
        }else if(cmpnyGb == 'C001005'){
            cstmCmpnyDataList = coprDataList.filter((data: any) => (data.companySeq != companySeq));
        }

        setSiteChrgrDataList(cstmSiteChrgrDataList);
        setCompanyGbCd(cmpnyGb);
        setCmpnyDataList(cstmCmpnyDataList);
    }

    return (

        <Container fluid className="bg-white px-0 bmc-txt-color-primary">
                <MngrSrchModal setModalIsOpen={setIsMngrModalOpen} modalIsOpen={isMngrModalOpen} mngrDataList={mngrDataList}
                               setMngrDataList={setMngrDataList} cmpnySeq={cmpnySeq} companyGbCd={companyGbCd} dprtCd={dprtCd}/>
                <CmpnySrchModal setModalIsOpen={setIsCmpnyModalOpen} modalIsOpen={isCmpnyModalOpen}
                                cmpnyDataList={cmpnyDataList} setCmpnyDataList={setCmpnyDataList}
                                companyGbCd={companyGbCd}/>
                <SubMenuTitle title={'공사기본정보관리'} parentMenu={'현장공사관리'}/>

            <div className="p-4 overflow-y-auto" style={{height: 'calc(100vh - 180px)'}}>
                <div className='w-100 d-flex justify-content-end align-items-center mb-2'>
                    <BMCIconButton kind={'submit'} onClick={fn_registData}>저장</BMCIconButton>
                </div>

                <form name={'form'} ref={formRef} encType="multipart/form-data" method={"post"}>
                    <BMCInput type={'hidden'} value={data?.atchFileId} name={'atchFileId'}></BMCInput>
                    <BMCInput type={'hidden'} value={data?.siteId} name={'siteId'}></BMCInput>
                    <div className="bmc-modal-div">
                        <div className="div-header">
                            공사 기본정보
                        </div>
                        <div className="div-body px-3">
                            <Table className="my-2 align-middle">
                                {CmmnFn.isSmSite() ?
                                    <tbody>
                                    <tr className="border-top border-bottom align-middle">
                                        <th className="bmc-bg-th" style={{width: '15%'}}>
                                            현장명
                                        </th>
                                        <td style={{width: '75%'}}>
                                            <BMCInput name={'siteName'} readOnly={true} value={data?.siteName}
                                                      onChange={fn_setFormData}
                                                      className="form-control"/>
                                        </td>
                                    </tr>
                                    </tbody> :
                                    <tbody>
                                    <tr className="border-top border-bottom align-middle">
                                        <th className="bmc-bg-th" style={{width: '12%'}}>
                                            현장명
                                        </th>
                                        <td style={{width: '38%'}}>
                                            <BMCInput name={'siteName'} value={data?.siteName} onChange={fn_setFormData}
                                                      className="form-control"/>
                                        </td>
                                        <th className="bmc-bg-th" style={{width: '12%'}}>
                                            공사기간
                                        </th>
                                        <td style={{width: '38%'}}>
                                            <div className="d-flex align-items-center w-100" ref={prodRef}>
                                                <BMCDatePicker
                                                    className={"text-center"} readOnly={true}
                                                    value={startDate} name={'constructionStartDate'}
                                                    onClick={fn_datePopupOpen}
                                                    calendar={fn_datePopupOpen}
                                                />
                                                <span>&nbsp;~&nbsp;</span>
                                                <BMCDatePicker readOnly={true}
                                                               className={"text-center"}
                                                               value={endDate} name={'constructionEndDate'}
                                                               onClick={fn_datePopupOpen}
                                                               calendar={fn_datePopupOpen}
                                                />

                                                {false
                                                    ? <CustomDatePickerRange setIsDatePopupOpen={setIsDatePopupOpen}
                                                                             prodRef={prodRef}
                                                                             setStartDateView={setStartDate}
                                                                             setEndDateView={setEndDate}/>
                                                    : null}
                                            </div>
                                        </td>
                                    </tr>
                                    <tr className="border-top align-middle">
                                        <th className="bmc-bg-th">
                                            공종
                                        </th>
                                        <td>
                                            <BMCSelect initOptionGb={'sel'} disabled={true} className={'bmc-select'} codeGb={'cmmn'}
                                                       codeGrpId={'T002'}
                                                       name={'siteType'} onChange={fn_setFormData}
                                                       value={data?.siteType}></BMCSelect>

                                        </td>
                                        <th className="bmc-bg-th">
                                            공사금액
                                        </th>
                                        <td>
                                            <div className="d-flex align-items-center px-0">
                                                <BMCInput type="text" className="text-right form-control"
                                                          name={'cnstrPrice'} onChange={fn_setFormData}
                                                          value={data?.cnstrPrice?.toLocaleString()}/>
                                                <span>원</span>
                                            </div>
                                        </td>
                                    </tr>

                                    <tr className="border-top align-middle">
                                        <th className="bmc-bg-th">
                                            주소
                                        </th>
                                        <td colSpan={3}>
                                            <div
                                                className="d-flex align-items-center justify-content-between w-100 p-0 bmc-fs-b3">
                                                <BMCInput type="text" name={'siteAddress'} value={data?.siteAddress}
                                                          className="form-control" readOnly/>
                                                {/*<button className="bmc-btn btn-secondary min-w-[70px] ml-5 mr-10"
                                                        type="button" onClick={fn_openAddrPop}>검색
                                                </button>*/}
                                                <span className={"min-w-[40px]"}>경도</span>
                                                <BMCInput type="text" className="w-[100px] form-control"
                                                          name={'longitude'} value={data?.longitude} disabled={true}/>
                                                <span className={"min-w-[40px] ml-10"}>위도</span>
                                                <BMCInput type="text" className="w-[100px] form-control"
                                                          name={'latitude'}
                                                          value={data?.latitude} disabled={true}/>

                                            </div>
                                        </td>
                                    </tr>
                                    <tr className="border-top align-middle">
                                        <th className="bmc-bg-th">
                                            상세주소
                                        </th>
                                        <td>
                                            <div className="d-flex align-items-center px-0">
                                                <BMCInput type="text" className={'form-control'}
                                                          onChange={fn_setFormData} name={'siteDtlAddress'}
                                                          value={data?.siteDtlAddress}/>
                                            </div>
                                        </td>
                                        <th className="bmc-bg-th" style={{width: '15%'}}>부서</th>
                                        <td style={{width: '35%'}}>
                                            <BMCSelect initOptionGb={'sel'} disabled={true} name={'dprtCd'} codeGrpId={'D001'} className={"w-100"} codeGb={'cmmn'}
                                                       onChange={fn_setFormData} value={data?.dprtCd}></BMCSelect>
                                        </td>
                                    </tr>
                                    <tr className="border-top align-middle">
                                        <th className="bmc-bg-th">
                                            현장대리인
                                        </th>
                                        <td>
                                            <div className="d-flex align-items-center px-0">
                                                <BMCInput type="text" className={'form-control'}
                                                         onChange={fn_setFormData} name={'siteRepNm'}
                                                         value={data?.siteRepNm}/>
                                            </div>
                                        </td>
                                        <th className="bmc-bg-th">
                                            사업감리단
                                        </th>
                                        <td>
                                            <div className="d-flex align-items-center px-0">
                                                <BMCInput type="text" className={'form-control'}
                                                         onChange={fn_setFormData} name={'siteSprvNm'}
                                                         value={data?.siteSprvNm}/>
                                            </div>
                                        </td>
                                    </tr>
                                    <tr className="border-top align-middle">
                                        <th className="bmc-bg-th">
                                            안전관리자
                                        </th>
                                        <td>
                                            <div className="d-flex align-items-center px-0">
                                                <BMCInput type="text" className={'form-control'}
                                                         onChange={fn_setFormData} name={'sftyMngrNm'}
                                                         value={data?.sftyMngrNm}/>
                                            </div>
                                        </td>
                                    </tr>
                                    {/*<tr className="border-top align-middle">
                                        <th className="bmc-bg-th">
                                            공사내용
                                        </th>
                                        <td colSpan={3}>
                                            <div className="d-flex align-items-center px-0">
                                                <BMCTextarea className="form-control" name={'cnstrCntnts'}
                                                            onChange={fn_setFormData}
                                                            value={data?.cnstrCntnts} placeholder="공사내용을 입력해주세요."
                                                            rows={5}></BMCTextarea>
                                            </div>
                                        </td>
                                    </tr>
                                    */}
                                    <tr className="border-top align-middle">
                                        <th className="bmc-bg-th">
                                            현장사진
                                        </th>
                                        <td colSpan={3}>
                                            <BMCFile fileName={'photos'} fileList={fileList} setFileList={setFileList}
                                                     repFileSn={repFileSn} setRepFileSn={setRepFileSn}></BMCFile>

                                        </td>
                                    </tr>
                                    </tbody>

                                }


                            </Table>
                        </div>
                    </div>

                    {/*<div className="bmc-modal-div">
                            <div className="div-header">
                                <div>직영 정보</div>
                                <BMCIconButton kind={'add'}
                                              onClick={() => fn_cmpnyModalOpen('C001004')}>추가</BMCIconButton>
                            </div>
                            <div className="div-body px-3">
                                {
                                    drctDataList.length > 0 ?
                                        drctDataList.map((drctData: any, idx: number) => (

                                            <Container className='bmc-box-form py-2 mt-2' key={idx}>
                                                <Row className="font-semibold">
                                                    <div
                                                        className="d-flex justify-content-between align-items-center w-100 bmc-font-SD mb-2">
                                                        {'직영 ' + (idx + 1)}
                                                        <BMCIconButton kind={'delete'} onClick={() => fn_deleteCmpnyData(drctData.companySeq, 'C001004')}>삭제</BMCIconButton>
                                                    </div>
                                                </Row>
                                                <hr/>
                                                <Row className="mt-1 mb-2">
                                                    <Col>
                                                        <span className="bmc-fw-5">회사명</span>
                                                        <BMCInput className="bmc-form input text-center"
                                                                 value={drctData.companyName}
                                                                 disabled={true}/>
                                                    </Col>
                                                    <Col>
                                                        <span className="bmc-fw-5">대표전화</span>
                                                        <BMCInput className="bmc-form input text-center"
                                                                 value={drctData.contactPhoneNo}
                                                                 disabled={true}/>
                                                    </Col>
                                                </Row>
                                                <Row>
                                                    <div className="div-body">
                                                        <div
                                                            className='w-100 d-flex justify-content-end align-items-center mb-2'>
                                                            <BMCIconButton kind={'add'} onClick={() => fn_openMngrModal(drctData.companySeq, 'C001004')}>행추가</BMCIconButton>
                                                        </div>
                                                        <Table className="sub my-2 text-center">
                                                            <thead>
                                                            <tr className="table-secondary">
                                                                <th className="align-middle w-[10%]">No</th>
                                                                <th className="align-middle w-[20%]">직위</th>
                                                                <th className="align-middle w-[20%]">이름</th>
                                                                <th className="align-middle w-[20%]">핸드폰</th>
                                                                <th className="align-middle w-[20%]">이메일</th>
                                                                <th className="align-middle w-[10%]">삭제</th>
                                                            </tr>
                                                            </thead>
                                                            <tbody id="wrkDclrRgst_siteTBody">
                                                            {
                                                                siteChrgrDataList.filter((data: any) => (drctData.companySeq == data.blngCmpnySeq)).length > 0 ?
                                                                    siteChrgrDataList.map((data: any, idx: number) => (
                                                                        drctData.companySeq == data.blngCmpnySeq &&
                                                                        <tr key={idx}>
                                                                            <td>{idx + 1}</td>
                                                                            <td className={'align-content-start'}>
                                                                                {data.position}
                                                                            </td>
                                                                            <td className={'align-content-start'}>
                                                                                {data.userName}
                                                                            </td>
                                                                            <td className={'align-middle'}>
                                                                                {data.userPhoneReg}
                                                                            </td>
                                                                            <td className={'align-middle'}>
                                                                                {data.userEmail}
                                                                            </td>
                                                                            <td>
                                                                                <img
                                                                                    className={'w-[25px] cursor-pointer inline-block'}
                                                                                    src={IconRemove} onClick={() => {
                                                                                    fn_delSiteChrgrDataRow(data.userSeq);
                                                                                }}/>
                                                                            </td>
                                                                        </tr>
                                                                    ))


                                                                    : <tr>
                                                                        <td colSpan={5}>
                                                                            <div className="text-center">
                                                                                등록된 담당자가 없습니다.
                                                                            </div>
                                                                        </td>
                                                                    </tr>
                                                            }

                                                            </tbody>
                                                        </Table>
                                                    </div>
                                                </Row>
                                            </Container>)
                                        ) : <div
                                            className="w-100 justify-content-center d-flex flex-column align-items-center py-2">
                                            <img src={iconNodata} alt="이미지" style={{width: '70px'}}/>
                                            <span className="bmc-text-font_secondary02 bmc-fw-5 mt-2">등록된 직영 업체가 없습니다.</span>
                                        </div>

                                }
                            </div>
                        </div>*/}

                    <div className="bmc-modal-div">
                        <div className="div-header">

                            <div>{data?.isSmSite ? '센터업체 정보' : '시공사 정보'}</div>
                            {/* <BMCIconButton kind={'add'}
                                              onClick={() => fn_cmpnyModalOpen('C001002')}>추가</BMCIconButton>*/}
                        </div>
                        <div className="div-body px-3">
                            {
                                bldrDataList.length > 0 ?
                                    bldrDataList.map((bldrData: any, idx: number) => (

                                        <Container className='bmc-box-form py-2 mt-2' key={idx}>
                                            <Row className="font-semibold">
                                                <div
                                                    className="d-flex justify-content-between align-items-center w-100 bmc-font-SD mb-2">
                                                    {'시공사 ' + (idx + 1)}
                                                    {/*<BMCIconButton kind={'delete'}
                                                                      onClick={() => fn_deleteCmpnyData(bldrData.companySeq, 'C001002')}>삭제</BMCIconButton>*/}
                                                </div>
                                            </Row>
                                            <hr/>
                                            <Row className="mt-1 mb-2">
                                                <Col>
                                                    <span className="bmc-fw-5">회사명</span>
                                                    <BMCInput className="bmc-form input text-center"
                                                              value={bldrData.companyName}
                                                              disabled={true}/>
                                                </Col>
                                                <Col>
                                                    <span className="bmc-fw-5">대표전화</span>
                                                    <BMCInput className="bmc-form input text-center"
                                                              value={bldrData.contactPhoneNo}
                                                              disabled={true}/>
                                                </Col>
                                            </Row>
                                            <Row>
                                                <div className="div-body-borderless border-none">
                                                    <div
                                                        className='w-100 d-flex justify-content-end align-items-center mb-2'>
                                                        {/*<BMCIconButton kind={'add'}
                                                                          onClick={() => fn_openMngrModal(bldrData.companySeq, 'C001002')}>행추가</BMCIconButton>*/}
                                                    </div>

                                                    <Table className="sub my-2 text-center">
                                                        <thead>
                                                        <tr className="table-secondary">
                                                            <th className="align-middle w-[10%]">No</th>
                                                            <th className="align-middle w-[20%]">직위</th>
                                                            <th className="align-middle w-[20%]">이름</th>
                                                            <th className="align-middle w-[20%]">핸드폰</th>
                                                            <th className="align-middle w-[20%]">이메일</th>
                                                            {/* <th className="align-middle w-[10%]">삭제</th>*/}
                                                        </tr>
                                                        </thead>
                                                        <tbody>
                                                        {

                                                            siteChrgrDataList.filter((data: any) => (bldrData.companySeq == data.blngCmpnySeq)).length > 0 ?
                                                                siteChrgrDataList.map((data: any, idx: number) => (
                                                                    bldrData.companySeq == data.blngCmpnySeq &&
                                                                    <tr key={idx}>
                                                                        <td>{idx + 1}</td>
                                                                        <td className={'align-content-start'}>
                                                                            {data.position}
                                                                        </td>
                                                                        <td className={'align-content-start'}>
                                                                            {data.userName}
                                                                        </td>
                                                                        <td className={'align-middle'}>
                                                                            {data.userPhoneReg}
                                                                        </td>
                                                                        <td className={'align-middle'}>
                                                                            {data.userEmail}
                                                                        </td>
                                                                        {/*<td>
                                                                                <img
                                                                                    className={'w-[25px] cursor-pointer inline-block'}
                                                                                    src={IconRemove} onClick={() => {
                                                                                    fn_delSiteChrgrDataRow(data.userSeq);
                                                                                }}/>
                                                                            </td>*/}
                                                                    </tr>
                                                                ))


                                                                : <tr>
                                                                    <td colSpan={5}>
                                                                        <div className="text-center">
                                                                            등록된 담당자가 없습니다.
                                                                        </div>
                                                                    </td>
                                                                </tr>
                                                        }

                                                        </tbody>
                                                    </Table>
                                                </div>
                                            </Row>
                                        </Container>)
                                    ) : <div
                                        className="w-100 justify-content-center d-flex flex-column align-items-center py-2">
                                        <img src={iconNodata} alt="이미지" style={{width: '70px'}}/>
                                        <span className="bmc-text-font_secondary02 bmc-fw-5 mt-2">등록된 시공사가 없습니다.</span>
                                    </div>

                            }
                        </div>
                    </div>
                    {!data?.isSmSite &&
                        <div className="bmc-modal-div">
                            <div className="div-header">
                                <div>사업감리단 정보</div>
                                {/*<BMCIconButton kind={'add'}
                                                  onClick={() => fn_cmpnyModalOpen('C001003')}>추가</BMCIconButton>*/}
                            </div>
                            <div className="div-body px-3">
                                {
                                    sprvDataList.length > 0 ?
                                        sprvDataList.map((sprvData: any, idx: number) => (

                                            <Container className='bmc-box-form py-2 mt-2' key={idx}>
                                                <Row className="font-semibold">
                                                    <div
                                                        className="d-flex justify-content-between align-items-center w-100 bmc-font-SD mb-2">
                                                        {'감리 ' + (idx + 1)}
                                                        {/* <BMCIconButton kind={'delete'}
                                                                          onClick={() => fn_deleteCmpnyData(sprvData.companySeq, 'C001003')}>삭제</BMCIconButton>*/}
                                                    </div>
                                                </Row>
                                                <hr/>
                                                <Row className="mt-1 mb-2">
                                                    <Col>
                                                        <span className="bmc-fw-5">회사명</span>
                                                        <BMCInput className="bmc-form input text-center"
                                                                  value={sprvData.companyName}
                                                                  disabled={true}/>
                                                    </Col>
                                                    <Col>
                                                        <span className="bmc-fw-5">대표전화</span>
                                                        <BMCInput className="bmc-form input text-center"
                                                                  value={sprvData.contactPhoneNo}
                                                                  disabled={true}/>
                                                    </Col>
                                                </Row>
                                                <Row>
                                                    <div className="div-body">
                                                        <div
                                                            className='w-100 d-flex justify-content-end align-items-center mb-2'>
                                                            {/* <BMCIconButton kind={'add'}
                                                                              onClick={() => fn_openMngrModal(sprvData.companySeq, 'C001003')}>행추가</BMCIconButton>*/}
                                                        </div>
                                                        <Table className="sub my-2 text-center">
                                                            <thead>
                                                            <tr className="table-secondary">
                                                                <th className="align-middle w-[10%]">No</th>
                                                                <th className="align-middle w-[20%]">직위</th>
                                                                <th className="align-middle w-[20%]">이름</th>
                                                                <th className="align-middle w-[20%]">핸드폰</th>
                                                                <th className="align-middle w-[20%]">이메일</th>
                                                                {/*  <th className="align-middle w-[10%]">삭제</th>*/}
                                                            </tr>
                                                            </thead>
                                                            <tbody id="wrkDclrRgst_siteTBody">
                                                            {
                                                                siteChrgrDataList.filter((data: any) => (sprvData.companySeq == data.blngCmpnySeq)).length > 0 ?
                                                                    siteChrgrDataList.map((data: any, idx: number) => (
                                                                        sprvData.companySeq == data.blngCmpnySeq &&
                                                                        <tr key={idx}>
                                                                            <td>{idx + 1}</td>
                                                                            <td className={'align-content-start'}>
                                                                                {data.position}
                                                                            </td>
                                                                            <td className={'align-content-start'}>
                                                                                {data.userName}
                                                                            </td>
                                                                            <td className={'align-middle'}>
                                                                                {data.userPhoneReg}
                                                                            </td>
                                                                            <td className={'align-middle'}>
                                                                                {data.userEmail}
                                                                            </td>
                                                                            {/*<td>
                                                                                    <img
                                                                                        className={'w-[25px] cursor-pointer inline-block'}
                                                                                        src={IconRemove} onClick={() => {
                                                                                        fn_delSiteChrgrDataRow(data.userSeq);
                                                                                    }}/>
                                                                                </td>*/}
                                                                        </tr>
                                                                    ))


                                                                    : <tr>
                                                                        <td colSpan={5}>
                                                                            <div className="text-center">
                                                                                등록된 담당자가 없습니다.
                                                                            </div>
                                                                        </td>
                                                                    </tr>
                                                            }

                                                            </tbody>
                                                        </Table>
                                                    </div>
                                                </Row>
                                            </Container>)
                                        ) : <div
                                            className="w-100 justify-content-center d-flex flex-column align-items-center py-2">
                                            <img src={iconNodata} alt="이미지" style={{width: '70px'}}/>
                                            <span className="bmc-text-font_secondary02 bmc-fw-5 mt-2">등록된 감리단이 없습니다.</span>
                                        </div>

                                }
                            </div>
                        </div>
                    }

                    <div className="bmc-modal-div">
                        <div className="div-header">
                            <div>협력업체 정보</div>
                            <BMCIconButton kind={'add'}
                                          onClick={() => fn_cmpnyModalOpen('C001005')}>추가</BMCIconButton>
                        </div>
                        <div className="div-body px-3">
                            {
                                coprDataList.length > 0 ?
                                    coprDataList.map((corpData: any, idx: number) => (

                                        <Container fluid className='bmc-box-form py-2 mt-2' key={idx}>
                                            <Row className="font-semibold">
                                                <div
                                                    className="d-flex justify-content-between align-items-center w-100 bmc-font-SD mb-2">
                                                    {'협력업체 ' + (idx + 1)}
                                                    <BMCIconButton kind={'delete'} onClick={() => fn_deleteCmpnyData(corpData.companySeq, 'C001005')}>삭제</BMCIconButton>
                                                </div>
                                            </Row>
                                            <hr/>
                                            <Row className="mt-1 mb-2">
                                                <Col>
                                                    <span className="bmc-fw-5">회사명</span>
                                                    <BMCInput className="bmc-form input text-center"
                                                              value={corpData.companyName}
                                                              disabled={true}/>
                                                </Col>
                                                <Col>
                                                    <span className="bmc-fw-5">대표전화</span>
                                                    <BMCInput className="bmc-form input text-center"
                                                              value={corpData.contactPhoneNo}
                                                              disabled={true}/>
                                                </Col>
                                            </Row>
                                            <Row>
                                                <div>
                                                    <div
                                                        className='w-100 d-flex justify-content-end align-items-center mb-2'>
                                                        <BMCIconButton kind={'add'} onClick={() => fn_openMngrModal(corpData.companySeq, 'C001005')}>행추가</BMCIconButton>
                                                    </div>
                                                    <Table className="sub my-2 text-center">
                                                        <thead>
                                                        <tr className="table-secondary">
                                                            <th className="align-middle w-[10%]">No</th>
                                                            <th className="align-middle w-[20%]">직위</th>
                                                            <th className="align-middle w-[20%]">이름</th>
                                                            <th className="align-middle w-[20%]">핸드폰</th>
                                                            <th className="align-middle w-[20%]">이메일</th>
                                                            <th className="align-middle w-[10%]">삭제</th>
                                                        </tr>
                                                        </thead>
                                                        <tbody id="wrkDclrRgst_siteTBody">
                                                        {
                                                            siteChrgrDataList.filter((data: any) => (corpData.companySeq == data.blngCmpnySeq)).length > 0 ?
                                                                siteChrgrDataList.map((data: any, idx: number) => (
                                                                    corpData.companySeq == data.blngCmpnySeq &&
                                                                    <tr key={idx}>
                                                                        <td>{idx + 1}</td>
                                                                        <td className={'align-content-start'}>
                                                                            {data.position}
                                                                        </td>
                                                                        <td className={'align-content-start'}>
                                                                            {data.userName}
                                                                        </td>
                                                                        <td className={'align-middle'}>
                                                                            {data.userPhoneReg}
                                                                        </td>
                                                                        <td className={'align-middle'}>
                                                                            {data.userEmail}
                                                                        </td>
                                                                        <td>
                                                                            <img
                                                                                className={'w-[25px] cursor-pointer inline-block'}
                                                                                src={IconRemove} onClick={() => {
                                                                                fn_delSiteChrgrDataRow(data.userSeq);
                                                                            }}/>
                                                                        </td>
                                                                    </tr>
                                                                ))


                                                                : <tr>
                                                                    <td colSpan={5}>
                                                                        <div className="text-center">
                                                                            등록된 담당자가 없습니다.
                                                                        </div>
                                                                    </td>
                                                                </tr>
                                                        }

                                                        </tbody>
                                                    </Table>
                                                </div>
                                            </Row>
                                        </Container>)
                                    ) : <div
                                        className="w-100 justify-content-center d-flex flex-column align-items-center py-2">
                                        <img src={iconNodata} alt="이미지" style={{width: '70px'}}/>
                                        <span className="bmc-text-font_secondary02 bmc-fw-5 mt-2">등록된 감리단이 없습니다.</span>
                                    </div>

                            }
                        </div>
                    </div>


                    <div className="bmc-modal-div">
                        <div className="div-header">
                            작성정보
                        </div>
                        <div className="div-body px-3">
                            <Table className="my-2 align-middle">
                                <tbody>
                                <tr>
                                    <th style={{width: '10%'}}>
                                        작성일
                                    </th>
                                    <td style={{width: '40%'}}>
                                        <BMCInput value={currentDate} name={'rgstDt'}
                                                  className={"form-control all-white"} disabled={true}/>
                                    </td>
                                    <th style={{width: '10%'}}>
                                        작성자
                                    </th>
                                    <td style={{width: '40%'}}>
                                        <BMCInput className={"form-control all-white"} name={'rgstrNm'}
                                                  value={data?.rgstrNm || defaultUser.userName} disabled={true}/>
                                    </td>
                                </tr>
                                </tbody>
                            </Table>
                        </div>
                    </div>
                </form>
            </div>
            </Container>

    )
}

export default MainSiteInfoManagePage;

