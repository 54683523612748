import React, {Dispatch, SetStateAction, useEffect, useRef, useState} from "react";
import {COMMON_MODAL_STYLE} from "../../../helper/StyleHelper";
import {Col, Modal, Row, Table} from "react-bootstrap";
import {IoMdClose} from "react-icons/io";
import Datatables, {getCheckedItems} from "../../component/Datatables";
import axios from "axios";
import {toast} from "react-toastify";
import messageCodeDic from "../../../util/messageCodeDic";
import BMCSelect from "../../../components/common/BMCSelect";
import {getLoginUser} from "../../../helper/SecurityHelper";
import SHSearch from "../../component/BMCSearch";
import BMCInput from "../../../components/common/BMCInput";
import restapi from "../../../helper/restAPIHelper";
import EqmntRgstModal from "../../siteEquipmentManagement/modal/EqmntRgstModal";

interface IProps {
    setModalIsOpen: Dispatch<SetStateAction<boolean>>;
    modalIsOpen: boolean;

    siteId: number;
    eqmntCd: any;


}

const $ = require("jquery")

const EqmntInfoListModal = (props: IProps) => {
    const [datatable, setDatatable] = useState<any>();

    const [isCodeDataLoaded, setIsCodeDataLoaded] = useState<any>(null);
    const searchFormRef = useRef<any>();
    const [dataList, setDataList] = useState<any>([]);

    const [modalIsOpen, setModalIsOpen] = React.useState(false);
    const [detailData, setDetailData] = useState(null);


    useEffect(() => {
        if (isCodeDataLoaded) {
            fn_search();
        }
    }, [isCodeDataLoaded]);

    function fn_closeModal() {
        props.setModalIsOpen(false);
    }

    const fn_search = (e?: any) => {
        e?.preventDefault()
        datatable?.draw()
    }

    const fn_onShow = () => {
        console.log('eqmntCd: ', props.eqmntCd);
        restapi.retrieveEqmntDataTotList({siteId: props.siteId, eqmntCd: props.eqmntCd}).then((rsltData: any) => {
            console.log('rsltData eqmnt: ', rsltData);
            setDataList(rsltData);
        });

    }

    const fn_retrieveDataList = () => {

        const obj = new FormData(searchFormRef.current);
        const cmpnySeq = obj.get('cmpnySeq');

        restapi.retrieveEqmntDataTotList({siteId: props.siteId, eqmntCd: props.eqmntCd, cmpnySeq:cmpnySeq}).then((rsltData: any) => {
            console.log('rsltData eqmnt: ', rsltData);
            setDataList(rsltData);
        });

    }

    return (
        <div>
            <EqmntRgstModal setModalIsOpen={setModalIsOpen} modalIsOpen={modalIsOpen} siteId={props.siteId}
                            detailData={detailData} />
            <Modal
                className="bmc-modal" size="xl"
                show={props.modalIsOpen}
                onShow={() => {
                    fn_onShow()

                }}
                onHide={fn_closeModal}
            >


                <Modal.Header>
                    <div className="d-flex justify-content-between align-items-center w-100 bmc-font-SD">
                        {'장비 목록 조회'}
                        <IoMdClose className='hover:cursor-pointer' onClick={fn_closeModal}/>
                    </div>
                </Modal.Header>

                <Modal.Body className="px-4">
                    <SHSearch formRef={searchFormRef} srchFnc={fn_retrieveDataList} btnType={'p'}>
                        <Row>
                            <Col>
                                <Table className="bmc-font-SP bmc-fs-b2 my-3">
                                    <tbody>
                                    <tr className="border-top align-middle border-bottom">
                                        <th className="bmc-bg-th">업체</th>
                                        <td>
                                            <BMCInput type={'hidden'} name={'siteId'} value={props.siteId}></BMCInput>
                                            <BMCSelect initOptionGb={'all'}
                                                      codeGb={'siteCmpny'}
                                                      className={'form-select'}
                                                      name={'cmpnySeq'}
                                                      selParam={{siteId: props.siteId}}
                                                      ></BMCSelect>
                                        </td>
                                    </tr>
                                    </tbody>
                                </Table>
                            </Col>
                        </Row>

                    </SHSearch>

                    <div className={'ge-result'}>
                        <Datatables
                            datas={dataList}
                            scrollY="550px"
                            className="bmc-fs-b4"
                            parameterFormRef={searchFormRef}
                            columns={[
                                {data: "cmpnyNm", title: "업체"},
                                {data: "eqmntNm", title: "장비명"},
                                {data: "eqmntRgstNo", title: "장비등록번호"},
                                {data: "driverNm", title: "운전원"},
                                {data: "rgstrNm", title: "작성자"}

                            ]}
                            checked={false}
                            onLoad={(dt: any) => {
                                setDatatable(dt)
                            }}

                            onClick={(data) => {
                                setDetailData(data)
                                setModalIsOpen(true);
                            }
                            }
                        />
                    </div>
                </Modal.Body>
                <Modal.Footer className="py-2">
                    <div className='flex justify-center w-100'>
                        <button
                            className='bmc-btn pill l white me-3'
                            onClick={fn_closeModal}>닫기
                        </button>

                    </div>
                </Modal.Footer>


            </Modal>
        </div>
    )
}

export default EqmntInfoListModal;
