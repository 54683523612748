import React, {Dispatch, SetStateAction, useContext, useEffect, useRef, useState} from "react";
import {ProgressContext} from "../../../context/Progress";
import {getLoginUser} from "../../../helper/SecurityHelper";
import {Col, Modal, Row, Table} from "react-bootstrap";
import {IoMdClose} from "react-icons/io";
import BMCInput from "../../../components/common/BMCInput";
import BMCSelect from "../../../components/common/BMCSelect";
import {toast} from "react-toastify";
import axios from "axios";
import messageCodeDic from "../../../util/messageCodeDic";
import errorCodeDic from "../../../util/errorCodeDic";
import IvstSiteListModal from "./IvstSiteListModal";
import IvstSiteSearchModal from "./IvstSiteSearchModal";
import BMCDatePicker from "../../../components/common/BMCDatePicker";
import CustomDatePicker from "../../component/ui/DatePicker";
import BMCDatePicker2 from "../../../components/common/BMCDatePicker2";
import {DateHelper} from "../../../helper/DateHelper";
import moment from "moment/moment";
import MngrSrchModal from "../../safetyWorkReportManagement/modal/MngrSrchModal";
import IvstSftyChkRgstrSrchModal from "./IvstSftyChkRgstrSrchModal";
import BMCIconButton from "../../../components/common/BMCIconButton";
import {Container} from "@mui/material";
import iconNodata from "../../../images/icon_nodocdata.png";
import {debug} from "node:util";
import restapi from "../../../helper/restAPIHelper";
import BMCTextarea from "../../../components/common/BMCTextarea";
import BMCFile from "../../../components/common/BMCFile";
import BMCPicModal from "../../../components/common/BMCPicModal";
import {CmmnFn} from "../../../helper/CmmnHelper";

interface IProps {
    setModalIsOpen: Dispatch<SetStateAction<boolean>>;
    modalIsOpen: boolean;
    detailData: any;
}

export default function IvstSftyChkRsltModal(props:IProps){

    const [data, setData] = useState<any>();
    const formRef = React.createRef<HTMLFormElement>();
    const {spinner} = useContext(ProgressContext);
    const user: any = getLoginUser();
    const [isIvstSitePopOpen, setIsIvstSitePopOpen] = useState<any>(false);
    const [blngSite, setBlngSite] = useState<object>()
    const [sftyChkDt, setSftyChkDt] = useState<Date|null>();
    const [sftyChkRsltRgstDt, setSftyChkRsltRgstDt] = useState<Date|null>();
    const [hourList, setHourList] = useState<any>([]);
    const [minList, setMinList] = useState<any>([]);
    const [chkRgstrModalIsOpen, setChkRgstrModalIsOpen] = React.useState(false);
    const [chkRgstrData, setChkRgstrData] = useState<any>(null);

    const [siteSftyMeasuresDataList, setSiteSftyMeasuresDataList] = useState<any>([]);
    const [siteSftyMeasuresData, setSiteSftyMeasuresData] = useState<any>();
    const [imgTarget, setImgTarget] = useState<any>(null);
    const fileRef = useRef() as React.MutableRefObject<any>;

    useEffect(()=>{
        if(blngSite){
            setData(Object.assign({}, data, blngSite));
        }
    },[blngSite]);

    useEffect(()=>{
        let tempData = {};
        if(chkRgstrData){
            tempData = {
                sftyChkRsltRgstrNm : chkRgstrData?.userName,
                sftyChkRsltRgstrSeq : chkRgstrData?.userSeq
            }
        }
        setData(Object.assign({}, data, tempData))
    }, [chkRgstrData])

    const fn_updateData = (e: any) => {
        if (e.target.type == 'checkbox') {
            setData({
                ...data,
                [e.target.name]: e.target.checked
            })
            console.log(e.target.checked)
        } else {
            setData({
                ...data,
                [e.target.name]: e.target.value
            })
        }
    }

    const fn_openModal = () => {
        if(!props.detailData){
            setData({sftyChkHour: '', sftyChkMin: ''});
            setSftyChkDt(null)
            setSftyChkRsltRgstDt(null)
            setSiteSftyMeasuresDataList([]);
            setChkRgstrData({});
        }else{
            setData(props.detailData);
            setSftyChkDt(moment(props.detailData?.sftyChkDt,'YYYY-MM-DD').toDate());
            if(props.detailData.sftyChkRsltRgstDt != null){
                setSftyChkRsltRgstDt(moment(props.detailData?.sftyChkRsltRgstDt,'YYYY-MM-DD').toDate())
            }else{
                setSftyChkRsltRgstDt(new Date());
            }

            if(!props.detailData?.sftyChkRsltRgstrSeq){
                console.log("user?.userName",user?.userName);
                setChkRgstrData({userName : user?.userName, userSeq : user?.userSeq})
            }

        }

        fn_genHour();
        fn_genMin();
    }

    const fn_closeModal = () => {
        props.setModalIsOpen(false);
    }

    const submit = (e: any) => {
        e.preventDefault()

    }

    const fn_registData = () => {

        if (!data) {
            toast.warn('데이터를 입력해 주십시오.')
            return;
        }

        if (!data.ivstPrblmType){
            toast.warn('이상유형을 선택해 주십시오.')
            return;
        }
        if (!data.ivstSprvDprtGb){
            toast.warn('점검결과를 입력해 주십시오.')
            return;
        }
        if (!data.ivstExprtOpnn){
            toast.warn('점검의견을 입력해 주십시오.')
            return;
        }

        let param = data;
        param.rgstrSeq = user.userSeq;
        param.updtrSeq = user.userSeq;

        // @ts-ignore
        var formData: any = new FormData(formRef.current);

        formData.set('ivstSftyChkData', new Blob([JSON.stringify(param)], {type: "application/json"}))

        if(!window.confirm('저장 하시겠습니까?')){
            return;
        }
        spinner.start();
        axios.post('/admin/api/siteSftyManage/saveIvstSftyChkData', formData).then((result) => {
            if (result.data > 0) {
                toast.success(messageCodeDic['001'])
                props.setModalIsOpen(false);
            } else {
                toast.error(errorCodeDic['003']);
            }
        }).catch(error => {
            toast.error(errorCodeDic['003']);
            console.log('error: ', error);
        })
        .finally(() => {
            spinner.stop();
        });
    }

    const fn_selectSite = ()=>{
        setIsIvstSitePopOpen(true)
    }

    const fn_genHour = () => {
        let hours = [];
        let hr;

        for (var i = 1; i <= 24; i++) {
            if (i < 10) {
                hr = '0' + i;
            } else {
                hr = i;
            }
            hours.push(hr);
        }
        setHourList(hours);
    }

    const fn_genMin = () => {
        let min = [];
        let mi;

        for (var i = 0; i <= 60; i = i + 5) {
            if (i < 10) {
                mi = '0' + i;
            } else {
                mi = i;
            }
            min.push(mi);
        }
        setMinList(min);
    }

    const fn_openMngrPop = () => {
        setChkRgstrModalIsOpen(true);
    }

    const fn_onfileChange = (e: any) => {
        console.log('e.target.files: ', e.target.files);
        let cstmSiteSftyMeasuresDataList = siteSftyMeasuresDataList;

        if (e.target.files.length > 0) {
            const file = e.target.files[0];
            const imgSrc = URL.createObjectURL(file);
            if (imgTarget.gb == 'bf') {
                cstmSiteSftyMeasuresDataList[imgTarget.idx].fileTmpSrc = imgSrc;
                cstmSiteSftyMeasuresDataList[imgTarget.idx].bfActFile = file;
                cstmSiteSftyMeasuresDataList[imgTarget.idx].isPhotoUpload = true;
                setSiteSftyMeasuresDataList([].concat(cstmSiteSftyMeasuresDataList));
            } else if (imgTarget.gb == 'af') {
                cstmSiteSftyMeasuresDataList[imgTarget.idx].fileAfTmpSrc = imgSrc;
                cstmSiteSftyMeasuresDataList[imgTarget.idx].afActFile = file;
                cstmSiteSftyMeasuresDataList[imgTarget.idx].isPhotoUpload = true;

                setSiteSftyMeasuresDataList([].concat(cstmSiteSftyMeasuresDataList));

                console.log('cstmSiteSftyMeasuresDataList: ', cstmSiteSftyMeasuresDataList);
            }
        }

    }


    return(
        <div>
            <Modal
                className="bmc-modal" size="xl"
                show={props.modalIsOpen}
                onShow={fn_openModal}
                onHide={fn_closeModal}
            >

                <Modal.Header>
                    <div className="d-flex justify-content-between align-items-center w-100 bmc-font-SD">
                        출자토지 전문가 점검 결과 등록
                        <IoMdClose className='hover:cursor-pointer' onClick={fn_closeModal}/>
                    </div>
                </Modal.Header>
                <Modal.Body className="px-4">
                    <input hidden type={'file'} ref={fileRef} onChange={fn_onfileChange}/>
                    <form name={'detailForm'} ref={formRef} encType="multipart/form-data" method={"post"} onSubmit={submit}>
                        <BMCInput type={'hidden'} value={data?.atchFileId} name={'atchFileId'}></BMCInput>
                        <BMCInput type={'hidden'} value={data?.sftyMngBrdId} name={'sftyMngBrdId'}></BMCInput>
                        <div className="bmc-modal-div">
                            <div className="div-header justify-content-between">
                                <span>현장기본정보</span>
                                { !data?.sftyChkSeq ? <button onClick={fn_selectSite} className='bmc-btn mr-5 btn-secondary'>점검현장 추가</button> : null}
                            </div>
                            <div className="div-body px-3">
                                <Table className="my-2 align-middle">
                                    <tbody>
                                    <tr className="border-top align-middle">
                                        <th className="bmc-bg-th">
                                            주소
                                        </th>
                                        <td colSpan={3}>
                                            <div
                                                className="d-flex align-items-center justify-content-between w-100 p-0 bmc-fs-b3">
                                                <BMCInput type="text" name={'siteAddress'} value={data?.siteAddress} disabled={true}
                                                          className="form-control" readOnly/>

                                            </div>
                                        </td>
                                    </tr>
                                    <tr className="border-top align-middle">
                                        <th className="bmc-bg-th" style={{width: '15%'}}>구</th>
                                        <td style={{width: '35%'}}>
                                            <BMCInput type="text" className="form-control" name={'guNm'} onChange={fn_updateData} value={data?.guNm ? data.guNm.substring(data.guNm.lastIndexOf(' ')) : ''} disabled={true}/>
                                        </td>
                                        <th className="bmc-bg-th" style={{width: '15%'}}>동</th>
                                        <td style={{width: '35%'}}>
                                            <BMCInput type="text" className="form-control" name={'dongNm'} onChange={fn_updateData} value={data?.dongNm ? data.dongNm.substring(data.dongNm.lastIndexOf(' ')) : ''}  disabled={true}/>
                                        </td>
                                    </tr>
                                    <tr className="border-top align-middle">
                                        <th className="bmc-bg-th" style={{width: '15%'}}>지번</th>
                                        <td style={{width: '35%'}}>
                                            <BMCInput type="text" className="form-control" name={'jibun'} onChange={fn_updateData} value={data?.jibun}  disabled={true}/>
                                        </td>
                                        <th className="bmc-bg-th" style={{width: '15%'}}>공부면적(m<sup>2</sup>)</th>
                                        <td style={{width: '35%'}}>
                                            <BMCInput type="number" className="form-control" name={'areaRange'} onChange={fn_updateData} value={data?.areaRange}  disabled={true}/>
                                        </td>
                                    </tr>
                                    <tr className="border-top align-middle">
                                        <th className="bmc-bg-th" style={{width: '15%'}}>지목</th>
                                        <td style={{width: '35%'}}>
                                            <BMCInput type="text" className="form-control" name={'landTypeNm'} onChange={fn_updateData} value={data?.landTypeNm}  disabled={true}/>
                                        </td>
                                        <th className="bmc-bg-th" style={{width: '15%'}}>현황</th>
                                        <td style={{width: '35%'}}>
                                            <BMCInput type="text" className="form-control" name={'landStatusNm'} onChange={fn_updateData} value={data?.landStatusNm}  disabled={true}/>
                                        </td>
                                    </tr>
                                    </tbody>
                                </Table>
                            </div>
                        </div>

                        <div className="bmc-modal-div">
                            <div className="div-header">
                                점검현장정보
                            </div>
                            <div className="div-body px-3">
                                <Table className="my-2 align-middle">
                                    <tbody>
                                    <tr className="border-top align-middle">
                                        <th className="bmc-bg-th" style={{width: '15%'}}>점검 예정일</th>
                                        <td style={{width: '35%'}}>
                                            <div className="d-flex align-items-center w-100 gap-2">
                                                <div className="d-flex gap-1 w-100">
                                                    <div className="d-flex">
                                                        <BMCDatePicker2 name={'sftyChkDt'} readOnly={true} defaultValue={sftyChkDt} onChange={fn_updateData} />
                                                    </div>
                                                    <div className="d-flex w-[80px]">
                                                        <select className={'bmc-form select h-[41px] w-100'} value={data?.sftyChkHour}
                                                                onChange={fn_updateData} name={'sftyChkHour'}
                                                                placeholder={'시'}>
                                                            <option className={'text-center'} value={''}>시</option>
                                                            {
                                                                hourList.map((data: string, idx: number) => (
                                                                    <option className={'text-center'} key={idx}
                                                                            value={data}>{data}</option>
                                                                ))
                                                            }
                                                        </select>
                                                    </div>
                                                    <div className="d-flex w-[80px]">
                                                        <select className={'bmc-form select h-[41px] w-100'} name={'sftyChkMin'}
                                                                onChange={fn_updateData} value={data?.sftyChkMin}
                                                                placeholder={'분'}>
                                                            <option className={'text-center'} value={''}>분</option>
                                                            {
                                                                minList.map((data: string, idx: number) => (
                                                                    <option className={'text-center'} key={idx}
                                                                            value={data}>{data}</option>
                                                                ))
                                                            }
                                                        </select>
                                                    </div>
                                                </div>
                                            </div>
                                        </td>
                                        <th className="bmc-bg-th" style={{width: '15%'}}></th>
                                        <td style={{width: '35%'}}>

                                        </td>
                                    </tr>
                                    </tbody>
                                </Table>
                            </div>
                        </div>
                        <div className="bmc-modal-div">
                            <div className="div-header">
                                전문가 점검결과
                            </div>
                            <div className="div-body px-3">
                                <Table className="my-2 align-middle">
                                    <tbody>
                                    <tr className="border-top align-middle">
                                        <th className="bmc-bg-th" style={{width: '15%'}}>이상유형</th>
                                        <td colSpan={3} style={{width: '35%'}}>
                                            <BMCSelect initOptionGb={'all'} className={'bmc-select'} codeGb={'cmmn'} codeGrpId={'S017'} name={'ivstPrblmType'} value={data?.ivstPrblmType} onChange={fn_updateData} ></BMCSelect>
                                        </td>
                                    </tr>
                                    <tr className="border-top align-middle">
                                        <th className="bmc-bg-th" style={{width: '15%'}}>이전점검 의견</th>
                                        <td colSpan={3} style={{width: '85%'}}>{data?.ivstExprtOpnnBf ? data?.ivstExprtOpnnBf : '-'}</td>
                                    </tr>
                                    <tr className="border-top align-middle">
                                        <th className="bmc-bg-th" style={{width: '15%'}}>점검결과</th>
                                        <td colSpan={3} style={{width: '85%'}}>
                                            <BMCTextarea rows={5} onChange={fn_updateData} name={'ivstExprtRslt'}
                                                         value={data?.ivstExprtRslt}
                                                         className={"form-control"}/>
                                        </td>
                                    </tr>
                                    <tr className="border-top align-middle">
                                        <th className="bmc-bg-th" style={{width: '15%'}}>점검의견</th>
                                        <td colSpan={3} style={{width: '85%'}}>
                                            <BMCTextarea rows={5} onChange={fn_updateData} name={'ivstExprtOpnn'}
                                                         value={data?.ivstExprtOpnn}
                                                         className={"form-control"}/>
                                        </td>
                                    </tr>
                                    <tr className="border-top align-middle">
                                        <th className="bmc-bg-th" style={{width: '15%'}}>작성자</th>
                                        <td style={{width: '35%'}}>{data?.ivstExprtUserNm || user.userName}</td>
                                        <th className="bmc-bg-th" style={{width: '15%'}}>점검일</th>
                                        <td style={{width: '35%'}}>{data?.ivstExprtChkDt || CmmnFn.fn_getCurrentDate()}</td>
                                    </tr>
                                    </tbody>
                                </Table>
                            </div>
                        </div>

                    </form>
                </Modal.Body>

                <Modal.Footer className="py-2">
                    <div className='flex justify-center w-100'>
                        <button
                            className='bmc-btn pill l white me-3'
                            onClick={fn_closeModal}>닫기
                        </button>
                        {!data?.ivstExprtUserSeq &&
                            <button onClick={fn_registData}
                                    className='bmc-btn pill p'>등록
                            </button>
                        }

                    </div>
                </Modal.Footer>

            </Modal>
        </div>
    )
}
