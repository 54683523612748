import {Modal, Table} from "react-bootstrap";
import {IoMdClose} from "react-icons/io";
import React, {Dispatch, SetStateAction, useContext, useEffect, useState} from "react";
import {fn_getCurrentDate} from "../../../helper/CmmnHelper";
import {getLoginUser} from "../../../helper/SecurityHelper";
import BMCInput from "../../../components/common/BMCInput";
import axios from "axios";
import {toast} from "react-toastify";
import messageCodeDic from "../../../util/messageCodeDic";
import errorCodeDic from "../../../util/errorCodeDic";
import BMCSelect from "../../../components/common/BMCSelect";
import {NumberFormatBase} from "react-number-format";
import {Formatter} from "../../../helper/FormatHelper";
import SlctAuthChrgModal from "./SlctAuthChrgModal";
import restapi from "../../../helper/restAPIHelper";
import {ProgressContext} from "../../../context/Progress";
import BMCFile from "../../../components/common/BMCFile";


interface IProps {
    setModalIsOpen: Dispatch<SetStateAction<boolean>>;
    modalIsOpen: boolean;
    detailData: any;
    setIsSaveSuccess: Dispatch<SetStateAction<boolean>>;
    siteId: number;
}

const HeadMngrRgstModal = (props: IProps) => {

    const currentDate = fn_getCurrentDate();
    const user = getLoginUser();
    const [isAuthChrgPopOpend, setIsAuthChrgPopOpend] = React.useState<boolean>(false);

    const [data, setData] = useState<any>()
    const [slctdAuthChrgData, setSlctdAuthChrgData] = useState<any>();
    const [authChrgDataList, setAuthChrgDataList] = useState<[]>([]);
    const {spinner, setProcess} = useContext(ProgressContext);

    const [fileList, setFileList] = useState([]);
    const formRef = React.createRef<HTMLFormElement>();
    const [isDuplChCked, setIsDuplChCked] = useState<boolean>(false);

    const fn_setFormData = (e: any) => {

        setData({
            ...data,
            [e.target.name]: e.target.value
        })

    }

    useEffect(() => {
        if (slctdAuthChrgData) {
            let isDupl = false;
            authChrgDataList.map((data: any) => {
                if (data.siteId == slctdAuthChrgData.siteId) {
                    isDupl = true;
                }
            })
            if (isDupl) {
                toast.warn('중복된 현장 입니다.');
            } else {
                let cstmAuthChrgDataList: any = authChrgDataList;
                if (cstmAuthChrgDataList.length == 0) {
                    slctdAuthChrgData.isRep = true
                }

                cstmAuthChrgDataList.push(slctdAuthChrgData);
                setAuthChrgDataList(cstmAuthChrgDataList);
            }

            setSlctdAuthChrgData(null);
        }
    }, [slctdAuthChrgData])

    const fn_onAfterOpen = () => {
        // 신규
        if (!props.detailData) {
            setData(null)
            setAuthChrgDataList([]);
            setFileList([]);
            setIsDuplChCked(false)
            // 상세
        } else {
            setData(props.detailData);
            console.log(props.detailData);
            restapi.retrieveUserSiteAuthDataList(props.detailData.userSeq).then((rsltData) => {
                setAuthChrgDataList(rsltData);
            });

            if(props.detailData.atchFileId){
                restapi.retrieveAttachInfoList({attachSeq: props.detailData.atchFileId}).then((rsltData: any) => {
                    setFileList([].concat(rsltData));
                })
            }else{
                setFileList([]);
            }
            setIsDuplChCked(true)
        }
        setSlctdAuthChrgData(null)
    }


    function fn_closeModal() {
        props.setModalIsOpen(false);
    }

    const submit = (e: any) => {
        e.preventDefault()

    }


    const fn_openSlctAuthChrgPop = () => {

        if (!data || !data.blngCmpnySeq) {
            toast.warn('소속업체를 선택해 주십시오.')
            return;
        }
        setIsAuthChrgPopOpend(true);
    }


    const fn_registData = () => {
        console.log('data: ', data);

        if (!data) {
            toast.warn('데이터를 입력해 주십시오.')
            return;
        }

        if (!data.userName) {
            toast.warn('성명을 입력해 주십시오.')
            return;
        }


        if (!data.userPhone) {
            toast.warn('휴대전화 번호를 입력해 주십시오.');
            return;
        }

        if (!data.userId) {
            toast.warn('아이디를 입력해 주십시오.');
            return;
        }

        if (!isDuplChCked) {
            toast.warn('사용자 ID 중복확인을 해 주십시오.');
            return;
        }

        if (!props.detailData) {
            if (!data.userPassword) {
                toast.warn('비밀번호를 입력해 주십시오');
                return;
            }

            if (!data.userPasswordCnfrm) {
                toast.warn('비밀번호를 확인해 주십시오');
                return;
            }

            if (data.userPassword != data.userPasswordCnfrm) {
                toast.warn('설정한 비밀번호가 일치하지 않습니다')
                return;
            }
        }

        if(!data.dprtCd) {
            toast.warn('부서를 선택해 주십시오.');
            return;
        }

        if(!data.authCode) {
            toast.warn('권한을 선택해 주십시오.');
            return;
        }

        let param = data;
        param.userPhone = param.userPhone.replaceAll('-', '');
        param.authChrgDataList = authChrgDataList;
        param.rgstrSeq = user.userSeq;
        param.updtrSeq = user.userSeq;

        // @ts-ignore
        var formData: any = new FormData(formRef.current)

        if(fileList.length > 0){
            for(var i =0;i<fileList.length;i++){
                formData.append('atchFileList',fileList[i]);
            }
        }

        formData.set('userVO', new Blob([JSON.stringify(param)], {type: "application/json"}))

        if (!window.confirm('저장 하시겠습니까?')) {
            return;
        }

        console.log('param: ', param);

        axios.post('/admin/api/user/saveHeadMngrData', formData).then((result) => {
                if (result.data > 0) {
                    toast.success(messageCodeDic['001'])
                    props.setModalIsOpen(false);
                    props.setIsSaveSuccess(true);
                } else {
                    if (result.data == -12) {
                        toast.error('이미 존재하는 아이디 입니다.');
                    } else {
                        toast.error(errorCodeDic['003']);
                    }
                    props.setIsSaveSuccess(false);
                }
            }
        )


    }

    const fn_delRow = (idx: number) => {
        let cstmAuthChrgDataList: any = [].concat(authChrgDataList);

        cstmAuthChrgDataList.splice(idx, 1);
        setAuthChrgDataList(cstmAuthChrgDataList);
    }

    const fn_chkIsRep = (idx: number) => {
        let cstmAuthChrgDataList: any = [].concat(authChrgDataList);
        cstmAuthChrgDataList.map((data: any, dataIdx: number) => {
            if (idx == dataIdx) {
                data.isRep = true
            } else {
                data.isRep = false
            }
        })
        setAuthChrgDataList(cstmAuthChrgDataList);

    }

    const fn_initPassword = () => {
        if(!window.confirm('해당 사용자의 비밀번호를 초기화(\'0000\') 하시겠습니까?')){
            return;
        }

        axios.post('/admin/api/user/updateInitPassword', {userSeq:props.detailData.userSeq}).then((rsltData: any) => {
            if(rsltData.data > 0){
                toast.success(messageCodeDic['006']);
            }else{
                toast.error(errorCodeDic['003']);
            }
        }).catch(error => {
            toast.error(errorCodeDic['003']);
            console.log('error: ', error);
        })
            .finally(() => {
                spinner.stop();
            });
    }

    const fn_chkDupl = () => {

        if (!data || !data.userId) {
            toast.warn('사용자 ID를 입력해 주십시오.');
            return;
        }

        if (data.userId) {
            restapi.retrieveUserInfoDtl({userId: data.userId}).then((rsltData: any) => {
                console.log('rsltData: ', rsltData);
                if (rsltData) {
                    toast.warn('이미 존재하는 사용자 ID 입니다.')
                } else {
                    toast.success('사용 가능한 사용자 ID 입니다.')
                    setIsDuplChCked(true);
                }

            })

        }

    }

    return (
        <div>
            <SlctAuthChrgModal setModalIsOpen={setIsAuthChrgPopOpend} modalIsOpen={isAuthChrgPopOpend}
                               slctdAuthChrgData={slctdAuthChrgData}
                               setSlctdAuthChrgData={setSlctdAuthChrgData} blngCmpnySeq={data?.blngCmpnySeq}/>

            <Modal
                className="bmc-modal" size="xl"
                show={props.modalIsOpen}
                onShow={fn_onAfterOpen}
                onHide={fn_closeModal}

            >
                <Modal.Header>
                    <div className="d-flex justify-content-between align-items-center w-100 bmc-font-SD">
                        본사 관리자 등록/수정
                        <IoMdClose className='hover:cursor-pointer' onClick={fn_closeModal}/>
                    </div>
                </Modal.Header>
                <Modal.Body className="px-4">
                    <form name={'form'} ref={formRef} encType="multipart/form-data" method={"post"}
                          onSubmit={submit}>
                        <div className="bmc-modal-div">
                            <div className="div-header">
                                관리자 정보
                                {props.detailData?.userSeq?
                                <button className="bmc-btn btn-secondary btn-sm" type="button"
                                        onClick={fn_initPassword}>비밀번호 초기화
                                </button> :''
                                }
                            </div>
                            <div className="div-body px-3">
                                <Table className="my-2 align-middle">
                                    <tbody>
                                    <tr className="border-top align-middle">
                                        <th className="bmc-bg-th" style={{width: '15%'}}>성명</th>
                                        <td style={{width: '35%'}}>
                                            <BMCInput type="text" className="form-control" name={'userName'}
                                                     onChange={fn_setFormData} value={data?.userName}/>
                                        </td>
                                        <th className="bmc-bg-th" style={{width: '15%'}}>휴대전화</th>
                                        <td style={{width: '35%'}}>
                                            {
                                                //@ts-ignore
                                                <NumberFormatBase onChange={fn_setFormData} name={'userPhone'}
                                                                  className={'form-control'}
                                                                  value={data?.userPhone || ''}
                                                                  format={Formatter.phoneNumFormat}
                                                />
                                            }
                                        </td>
                                    </tr>
                                    <tr className="border-top align-middle">
                                        <th className="bmc-bg-th" style={{width: '15%'}}>직위</th>
                                        <td style={{width: '35%'}}>
                                            <BMCInput type="text" className="form-control" name={'position'}
                                                     onChange={fn_setFormData} value={data?.position}/>
                                        </td>
                                        <th className="bmc-bg-th" style={{width: '15%'}}>소속업체</th>
                                        <td style={{width: '35%'}}>
                                            <span>부산도시공사</span>
                                        </td>
                                    </tr>
                                    <tr className="border-top align-middle">
                                        <th className="bmc-bg-th" style={{width: '15%'}}>아이디</th>
                                        <td style={{width: '35%'}}>
                                            <div className="d-flex flex-row gap-2">
                                                <div className="d-flex align-items-center flex-grow-1">
                                                    <BMCInput type="text" className="form-control flex-grow-1 d-flex" name={'userId'}
                                                             onChange={fn_setFormData} value={data?.userId} disabled={props.detailData?.userId?true:false}/>
                                                </div>
                                                {!props.detailData &&
                                                    <div className="d-flex align-items-center w-[90px]">

                                                        <button className="bmc-btn btn-secondary ml-5 d-flex text-nowrap"
                                                                type="button" onClick={fn_chkDupl}>중복확인
                                                        </button>

                                                    </div>
                                                }
                                            </div>
                                        </td>
                                        {!props.detailData &&
                                            <>
                                                <th className="bmc-bg-th" style={{width: '15%'}}>비밀번호</th>
                                                <td style={{width: '35%'}}>
                                                    <BMCInput type="password" className="form-control"
                                                             name={'userPassword'}
                                                             onChange={fn_setFormData} value={data?.userPassword}/>
                                                </td>
                                            </>
                                        }
                                    </tr>
                                    <tr className="border-top align-middle">
                                        <th className="bmc-bg-th" style={{width: '15%'}}>이메일</th>
                                        <td style={{width: '35%'}}>
                                            <BMCInput type="text" className="form-control" name={'userEmail'}
                                                     onChange={fn_setFormData} value={data?.userEmail}/>
                                        </td>
                                        {!props.detailData && <>
                                            <th className="bmc-bg-th" style={{width: '15%'}}>비밀번호 확인</th>
                                            <td style={{width: '35%'}}>
                                                <BMCInput type="password" className="form-control"
                                                         name={'userPasswordCnfrm'}
                                                         onChange={fn_setFormData} value={data?.userPasswordCnfrm}/>
                                            </td>
                                        </>
                                        }
                                    </tr>
                                    <tr className="border-top align-middle">
                                        <th className="bmc-bg-th" style={{width: '15%'}}>부서</th>
                                        <td style={{width: '35%'}}>
                                            <BMCSelect initOptionGb={'sel'} name={'dprtCd'} codeGrpId={'D001'} className={"w-100"} codeGb={'cmmn'}
                                                      onChange={fn_setFormData} value={data?.dprtCd}></BMCSelect>
                                        </td>
                                        <th className="bmc-bg-th" style={{width: '15%'}}>권한</th>
                                        <td style={{width: '35%'}}>
                                            <BMCSelect initOptionGb={'sel'} name={'authCode'} selParam={{authGrpId:'HEAD'}} className={"w-100"} codeGb={'auth'}
                                                      onChange={fn_setFormData} value={data?.authCode}></BMCSelect>
                                        </td>
                                    </tr>
                                    <tr className="border-top align-middle">
                                        <th className="bmc-bg-th">첨부파일</th>
                                        <td colSpan={3}>
                                            <BMCFile fileName={'fileList'} fileList={fileList} setFileList={setFileList}></BMCFile>
                                        </td>
                                    </tr>
                                    </tbody>
                                </Table>

                            </div>

                        </div>

                        <div className="bmc-modal-div">
                            <div className="div-header">
                                작성정보
                            </div>
                            <div className="div-body px-3">
                                <Table className="my-2 align-middle">
                                    <tbody>
                                    <tr className="border-top align-middle">
                                        <th className="bmc-bg-th" style={{width: '15%'}}>작성일</th>
                                        <td style={{width: '35%'}}>
                                            <BMCInput value={data?.regDate || currentDate} name={'rgstDt'} onChange={fn_setFormData}
                                                     className={"form-control"}
                                                     disabled={true}/>
                                        </td>
                                        <th className="bmc-bg-th" style={{width: '15%'}}>작성자</th>
                                        <td style={{width: '35%'}}>
                                            <BMCInput className={"form-control"} name={'rgstrNm'}
                                                     value={data?.rgstrNm || user.userName}
                                                     onChange={fn_setFormData}
                                                     disabled={true}/>
                                        </td>
                                    </tr>
                                    </tbody>
                                </Table>
                            </div>
                        </div>

                    </form>
                </Modal.Body>
                <Modal.Footer className="py-2">
                    <div className='flex justify-center w-100'>
                        <button
                            className='bmc-btn pill l white me-3'
                            onClick={fn_closeModal}>닫기
                        </button>
                        {user.authCode != 'headRead' &&
                            <button onClick={fn_registData}
                            className='bmc-btn pill p'>등록/수정
                            </button>
                        }

                    </div>
                </Modal.Footer>

            </Modal>
        </div>
    )
}

export default HeadMngrRgstModal;

