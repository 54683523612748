import React, {Dispatch, SetStateAction, useContext, useEffect, useRef, useState} from "react";
import {IoMdClose} from "react-icons/io";
import BMCSelect from "../../../components/common/BMCSelect";
import {Col, Modal, Row, Table} from "react-bootstrap";
import BMCTreeView from "../../../components/common/BMCTreeView";
import restapi from "../../../helper/restAPIHelper";
import Datatables, {getCheckedItems} from "../../component/Datatables";
import BMCSearch from "../../component/BMCSearch";
import BMCInput from "../../../components/common/BMCInput";
import axios from "axios";
import {toast} from "react-toastify";
import BMCDatePicker2 from "../../../components/common/BMCDatePicker2";
import {getLoginUser} from "../../../helper/SecurityHelper";
import {UserContext} from "../../../context/User";
import errorCodeDic from "../../../util/errorCodeDic";
import {ProgressContext} from "../../../context/Progress";
import moment from "moment/moment";

interface IProps {
    setModalIsOpen: Dispatch<SetStateAction<boolean>>;
    modalIsOpen: boolean;

    siteId: number;
    wrkDt: string;

}

const DprtDtlStatModal = (props: IProps) => {
    const [dataList, setDataList] = useState<any>([])
    const [datatable, setDatatable] = useState<any>();
    const searchFormRef = useRef<any>();
    const [srchData, setSrchData] = useState<any>({siteId:props.siteId, wrkDt:props.wrkDt});
    const defaultUser = getLoginUser();
    const {user} = useContext<any>(UserContext);
    const {spinner} = useContext(ProgressContext);

    useEffect(() => {
        fn_srchData();
    }, [srchData]);

    const fn_afterOpenEvnt = () => {
        setSrchData({siteId:props.siteId, wrkDt:props.wrkDt});
    }


    function fn_closeModal() {
        props.setModalIsOpen(false);
    }
    const fn_setFormData = (e: any) => {
        setSrchData({
            ...srchData,
            [e.target.name]: e.target.value
        })

    }

    const fn_srchData = () => {
        if(srchData.wrkDt){
            spinner.start();
            restapi.retrieveWrkrMoveHistDataList(srchData).then((result) => {
                console.log('result: ', result);
                setDataList(result);
            }).catch(error => {
                toast.error(errorCodeDic['003']);
                console.log('error: ', error);
            })
                .finally(() => {
                    spinner.stop();
                });
        }
    }


    return (
        <div>
            <Modal
                className="bmc-modal" size={'xl'}
                show={props.modalIsOpen}
                onShow={fn_afterOpenEvnt}
                onHide={fn_closeModal}
            >
                <Modal.Header>
                    <div className="d-flex justify-content-between align-items-center w-100 bmc-font-SD">
                        근로자 이동기록 현황
                        <IoMdClose className='hover:cursor-pointer' onClick={fn_closeModal}/>
                    </div>
                </Modal.Header>

                <Modal.Body className="px-4 bmc-modal-table-w100">
                    <BMCSearch datatable={datatable} btnType={'p'} srchFnc={fn_srchData}>
                        <Row>
                            <Col>
                                <Table className="bmc-font-SP bmc-fs-b2 my-3">
                                    <tbody>
                                    <tr className="border-top align-middle">

                                        <th className="bmc-bg-th" style={{width: '10%'}}>업체</th>
                                        <td style={{width: '23%'}}>
                                            <BMCSelect initOptionGb={'all'} name={'cmpnySeq'}
                                                      selParam={{siteId: user?.blngSiteId || defaultUser.blngSiteId}}
                                                      codeGb={'siteCmpny'}
                                                      className="bmc-form select w-100"
                                                      onChange={fn_setFormData}
                                                      value={srchData?.cmpnySeq}></BMCSelect>
                                        </td>
                                        <th className="bmc-bg-th" style={{width: '10%'}}>공종</th>
                                        <td style={{width: '23%'}}>
                                            <BMCSelect initOptionGb={'all'} name={'wrkTypeCd'}
                                                      codeGb={'siteMngCd'} className={'form-select'}
                                                      onChange={fn_setFormData}
                                                      selParam={{
                                                          siteId: user?.blngSiteId || defaultUser.blngSiteId,
                                                          manageCode: 'M001001'
                                                      }}
                                                      value={srchData?.wrkTypeCd}></BMCSelect>
                                        </td>
                                        <th className="bmc-bg-th" style={{width: '10%'}}>직종</th>
                                        <td style={{width: '23%'}}>
                                            <BMCSelect initOptionGb={'all'} name={'ocptCd'}
                                                      codeGb={'siteMngCd'} className={'form-select'}
                                                      onChange={fn_setFormData}
                                                      selParam={{
                                                          siteId: user?.blngSiteId || defaultUser.blngSiteId,
                                                          manageCode: 'M001002'
                                                      }}
                                                      value={srchData?.ocptCd}></BMCSelect>
                                        </td>

                                    </tr>

                                    </tbody>
                                </Table>
                            </Col>
                        </Row>
                    </BMCSearch>

                    <div className={'ge-result'}>
                        <Datatables
                            parameterFormRef={searchFormRef}
                            scrollY="550px"
                            className="bmc-fs-b4"
                            columns={[
                                {data: "authGb", title: "구분", textAlign: 'center'},
                                {data: "userName", title: "이름", textAlign: 'center'},
                                {data: "siteWrkTpNm", title: "공종", textAlign: 'center'},
                                {data: "siteOcptNm", title: "직종", textAlign: 'center'},
                                {data: "moveStat", title: "내용", textAlign: 'left'},
                                {data: "wrkEndCmnt", title: "작업종료 코멘트", textAlign: 'left'},
                                {data: "", title: '보호구 착용', textAlign: 'center', render: function (data: any, type: any, row: any, meta: any) {
                                        let isCapChkd = row.isCapChkd;
                                        let isHarnessChkd = row.isHarnessChkd;
                                        let isGakbanChkd = row.isGakbanChkd;
                                        let isShoeChkd = row.isShoeChkd;
                                        isCapChkd ? isCapChkd = 'text-blue-500' : isCapChkd = 'text-red-500';
                                        isHarnessChkd ? isHarnessChkd = 'text-blue-500' : isHarnessChkd = 'text-red-500';
                                        isGakbanChkd ? isGakbanChkd = 'text-blue-500' : isGakbanChkd = 'text-red-500';
                                        isShoeChkd ? isShoeChkd = 'text-blue-500' : isShoeChkd = 'text-red-500';


                                        return "<div><span class='" + isCapChkd + "'>모</span><span class='ml-5 " + isHarnessChkd + "'>벨</span><span class='ml-5 " + isGakbanChkd + "'>각</span><span class='ml-5 " + isShoeChkd + "'>화</span></div>"
                                }}
                            ]}
                            checked={false}
                            index={true}
                            onLoad={(dt: any) => {
                                setDatatable(dt);
                            }}
                            isNotPaging={true}
                            isNotOrdering={true}
                            datas={dataList}
                            onClick={(data) => {

                            }}
                        />
                    </div>

                </Modal.Body>
                <Modal.Footer className="py-2">
                    <div className='flex justify-center w-100'>
                        <button
                            className='bmc-btn pill l white me-3'
                            onClick={fn_closeModal}>닫기
                        </button>
                    </div>
                </Modal.Footer>
            </Modal>
        </div>
    )
}

export default DprtDtlStatModal;
