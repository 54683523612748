import React, {useContext, useEffect, useState} from "react";
import {Container, Row, Col} from "react-bootstrap";
import {ReactComponent as IconRight} from "../../assets/images/ico/icon-right.svg";
import {ReactComponent as IconWait} from "../../assets/images/icon/icon-ClockCountdown.svg";
import {ReactComponent as IconIng} from "../../assets/images/icon/icon-Barricade.svg";
import {ReactComponent as IconEnd} from "../../assets/images/icon/icon-CheckSquareOffset.svg";
import {UserContext} from "../../context/User";
import restapi from "../../helper/restAPIHelper";
import {getLoginUser} from "../../helper/SecurityHelper";
import {CmmnFn} from "../../helper/CmmnHelper";
import WrkPrmtInfoListModal from "./modal/WrkPrmtInfoListModal";
import {useNavigate} from "react-router-dom";

const DashWorkPermit = () => {
    const {user} = useContext<any>(UserContext);
    const defaultUser = getLoginUser();
    const [wrkStatCnt, setWrkStatCnt] = useState<any>({wrkWaitCnt:0, wrkIngCnt:0, wrkCmpltdCnt: 0});
    const [isWrkPrmtInfoModalOpen, setIsWrkPrmtInfoModalOpen] = useState<boolean>(false);
    const [wrkStatCd, setWrkStatCd] = useState<string>('');
    const navigate = useNavigate();

    useEffect(() => {
        const siteId = user?.blngSiteId || defaultUser.blngSiteId;
        const wrkDt = CmmnFn.fn_getCurrentDate();
        let wrkWaitCnt = 0;
        let wrkIngCnt = 0;
        let wrkCmpltdCnt = 0;
        restapi.retrieveWrkPrmtDataTotList({prcsStatCd:'S001004', siteId: siteId, wrkDt:wrkDt}).then((rsltData: any) => {
            rsltData.map((data: any) => {
                if (data.totWrkrCnt == data.tbmCmpltCnt) {
                    if(data.isWrkCmpltd) {
                        wrkCmpltdCnt++;
                    }else{
                        wrkIngCnt++;
                    }

                } else {
                    return wrkWaitCnt++;
                }
            })

            const cntData = {wrkCmpltdCnt:wrkCmpltdCnt, wrkIngCnt:wrkIngCnt, wrkWaitCnt:wrkWaitCnt};

            setWrkStatCnt(cntData);
        })
    }, [user])

    const fn_openWrkPrmtInfoModal = (wrkStat: string) => {
        setWrkStatCd(wrkStat);
        setIsWrkPrmtInfoModalOpen(true);

    }

    return(
        <>
            <Container fluid className="bmc-bg-pane rounded-[8px] h-100">
                <WrkPrmtInfoListModal wrkStatCd={wrkStatCd} setModalIsOpen={setIsWrkPrmtInfoModalOpen} modalIsOpen={isWrkPrmtInfoModalOpen} siteId={user?.blngSiteId || defaultUser.blngSiteId} />
                <Row className="px-0">
                    <Col bsPrefix="col-7" className="w-100">
                        <div className="w-100 d-flex align-items-center justify-content-between pb-2 border-bottom">
                            <span className="pe-2 bmc-txt-size-h4">작업허가서</span>
                            <div className={'cursor-pointer'} onClick={() => navigate('/siteSafetyManagement/WorkPermitManagement')}>
                                <IconRight style={{width: '20px', marginBottom: '1px', stroke: '#1d1b39'}} />
                            </div>
                        </div>
                    </Col>
                </Row>
                <Row className="px-0 pt-3" style={{height:"calc(100% - 32px)"}}>
                    <Col className={"h-100"}>
                        <div onClick={() => fn_openWrkPrmtInfoModal('S004002')} className="h-100 cursor-pointer rounded-[10px] p-3 bmc-bg-color-01">
                            <div>
                                <span className="bmc-txt-size-b3">{wrkStatCnt.wrkWaitCnt}</span>
                            </div>
                            <div>
                                <span className="bmc-txt-size-b6 bmc-txt-weight-4 bmc-txt-color-gray-01">작업대기</span>
                            </div>
                            <div className="mt-3 w-100 d-flex justify-content-end align-items-center">
                                <IconWait style={{width: '30px'}} />
                            </div>
                        </div>
                    </Col>
                    <Col className="ps-0 pe-0 h-100">
                        <div onClick={() => fn_openWrkPrmtInfoModal('S004003')} className="h-100 cursor-pointer rounded-[10px] p-3 bmc-bg-color-02">
                            <div>
                                <span className="bmc-txt-size-b3">{wrkStatCnt.wrkIngCnt}</span>
                            </div>
                            <div>
                                <span className="bmc-txt-size-b6 bmc-txt-weight-4 bmc-txt-color-gray-01">작업중</span>
                            </div>
                            <div className="mt-3 w-100 d-flex justify-content-end align-items-center">
                                <IconIng style={{width: '30px'}} />
                            </div>
                        </div>
                    </Col>
                    <Col className={"h-100"}>
                        <div onClick={() => fn_openWrkPrmtInfoModal('S004004')} className="h-100 cursor-pointer rounded-[10px] p-3 bmc-bg-color-03">
                            <div>
                                <span className="bmc-txt-size-b3">{wrkStatCnt.wrkCmpltdCnt}</span>
                            </div>
                            <div>
                                <span className="bmc-txt-size-b6 bmc-txt-weight-4 bmc-txt-color-gray-01">작업완료</span>
                            </div>
                            <div className="mt-3 w-100 d-flex justify-content-end align-items-center">
                                <IconEnd style={{width: '30px'}} />
                            </div>
                        </div>
                    </Col>
                </Row>
            </Container>
        </>
    );
};

export default DashWorkPermit;

