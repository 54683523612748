import React, {Dispatch, SetStateAction} from "react";
import RskAsmntRgstModal from "../pages/siteSafetyManagement/modal/RskAsmntRgstModal";
import {Modal} from "react-bootstrap";
import {IoMdClose} from "react-icons/io";

const $ = require("jquery")

interface IProps {
    setModalIsOpen: Dispatch<SetStateAction<boolean>>;
    modalIsOpen: boolean;
    title: string;
    iframeSrc: string;
}

const IFrameModal = (props: IProps) => {

    function fn_closeModal() {
        props.setModalIsOpen(false);
    }

    const fn_onAfterOpen = () => {

    }

    return (
        <Modal className={"sh-modal"} size="xl"
               show={props.modalIsOpen}
               onShow={fn_onAfterOpen}
               onHide={fn_closeModal}

        >
            <Modal.Header>
                <div className="d-flex justify-content-between align-items-center w-100 sh-font-SD">
                    {props.title}
                    <IoMdClose className='hover:cursor-pointer' onClick={fn_closeModal}/>
                </div>
            </Modal.Header>
            <Modal.Body className="px-4">
                <iframe src={props.iframeSrc} className='w-100 min-h-[800px]'/>
            </Modal.Body>
        </Modal>
    )
}

export default IFrameModal;
