import React, {useContext, useEffect, useRef, useState} from "react";
import {Container, Tab, Table, Tabs} from "react-bootstrap";
import SubMenuTitle from "../component/SubMenuTitle";
import SHSearch from "../component/BMCSearch";
import BMCInput from "../../components/common/BMCInput";
import BMCSelect from "../../components/common/BMCSelect";
import Datatables from "../component/Datatables";
import {getLoginUser} from "../../helper/SecurityHelper";
import {UserContext} from "../../context/User";
import axios from "axios";
import WrkPrmtRgstModal from "../siteSafetyManagement/modal/WrkPrmtRgstModal";
import RskAsmntRgstModal from "../siteSafetyManagement/modal/RskAsmntRgstModal";
import BMCDatePicker2 from "../../components/common/BMCDatePicker2";
import {DateHelper} from "../../helper/DateHelper";
import moment from "moment/moment";
import PTWRgstModal from "../siteSafetyManagement/modal/PTWRgstModal";

type Props = {};

const ApprWaitPage = (props: Props) => {

    const searchFormRef = useRef<any>();
    const cmpnySeqRef = useRef<any>();
    const [data, setData] = useState<any>();
    const defaultUser = getLoginUser();
    const {user} = useContext<any>(UserContext);
    const [gridTab, setGridTab] = useState<any>('wrkPrmt')
    const [datatable, setDatatable] = useState<any>();
    const [cmpnyCodeList, setCmpnyCodeList] = useState<any>([]);
    const [detailData, setDetailData] = useState<any>(null);
    const [wrkModalIsOpen, setWrkModalIsOpen] = React.useState(false);
    const [rskModalIsOpen, setRskModalIsOpen] = React.useState(false);    const [modalRgstIsOpen, setModalRgstIsOpen] = React.useState(false);
    const [ptwIsOpen, setPtwIsOpen] = React.useState(false);
    const [startDate, setStartDate] = useState<Date>(DateHelper.dateByMonth(-3));
    const [endDate, setEndDate] = useState<Date>(DateHelper.dateByMonth(0));


    const prodRef = useRef<any>();
    const fn_setSrchData = (e: any) => {

        if (e.target.name == 'state') {
            setData({
                ...data,
                ['state']: e.target.value,
                ['prcsGbCd']: e.target.value,
                ['prcsStatCd']: e.target.value
            })
            console.log(data);
        }else{
            setData({
                ...data,
                [e.target.name]: e.target.value
            })
        }
    }

    useEffect(() => {
        fn_retrieveSrchCond(user?.blngSiteId || defaultUser.blngSiteId)
    }, [user])

    useEffect(() => {
        fn_search()
    },[gridTab])

    const fn_retrieveSrchCond = (siteId: number) => {
        fn_retrieveCmpnySiteDataList(defaultUser.blngSiteId).then((rsltData) => {
            setCmpnyCodeList(rsltData);
        });
    }

    const fn_retrieveCmpnySiteDataList = async (siteId: string) => {
        return new Promise(function (resolve, reject) {
            axios.post('/admin/api/company/retrieveCmpnySiteDataList', {
                siteId: siteId
            }).then((result) => {
                resolve(result.data);
            })
        });
    }

    const fn_search = () =>{
        datatable?.draw()
    }

    const onGridClick = (rData:any) =>{
        if(rData.apprDcmntType == "wrkPrmt") {
            axios.post('/admin/api/wrkPrmt/retrieveWrkPrmtDataList', {
                siteId: rData.siteId, wrkPrmtId: rData.pk
            }).then((result) => {
                setDetailData(result.data[0])
                setWrkModalIsOpen(true);
            })

        }else if(rData.apprDcmntType == "riskAsmnt") {
            axios.post('/admin/api/rskAsmnt/retrieveRskAsmntDataList', {
                siteId: rData.siteId, riskAsmntId: rData.pk
            }).then((result) => {
                setDetailData(result.data[0])
                setRskModalIsOpen(true);
            })
        }else if(rData.apprDcmntType == "ptw") {
            axios.post('/admin/api/ptw/retrievePTWDataList', {
                siteId: rData.siteId, ptwId: rData.pk
            }).then((result) => {
                setDetailData(result.data[0])
                setPtwIsOpen(true);
            })
        }
    }

    return (
        <Container fluid className="bg-white px-0 pb-4 bmc-text-font_primary">
            <WrkPrmtRgstModal setModalIsOpen={setWrkModalIsOpen} modalIsOpen={wrkModalIsOpen}
                              siteId={user?.blngSiteId || defaultUser.blngSiteId}
                              detailData={detailData}/>
            <RskAsmntRgstModal setModalIsOpen={setRskModalIsOpen} modalIsOpen={rskModalIsOpen} siteId={user?.blngSiteId || defaultUser.blngSiteId}
                               detailData={detailData}/>
            <PTWRgstModal setModalIsOpen={setPtwIsOpen} modalIsOpen={ptwIsOpen}
                          siteId={user?.blngSiteId || defaultUser.blngSiteId}
                          detailData={detailData} isCopy={false}/>

            <SubMenuTitle title={'결재대기함'} parentMenu={'전자결재'}/>
            <div className="p-4 overflow-y-auto" style={{height: 'calc(100vh - 180px)'}}>
                <SHSearch onSearch={() => fn_search()} formRef={searchFormRef} btnType={'p'}>
                    <BMCInput type={'hidden'} name={'siteId'} value={user?.blngSiteId || defaultUser.blngSiteId}></BMCInput>
                    <BMCInput type={'hidden'} name={'userSeq'} value={defaultUser.userSeq}></BMCInput>
                    <BMCInput type={'hidden'} name={'prcsStatCd'} value={'S001002'}></BMCInput>

                    <Table className="bmc-font-SP bmc-fs-b2 my-3">
                        <tbody>
                        <tr className="border-top border-bottom align-middle">
                            <th className="bmc-bg-th" style={{width:"15%"}}>기안일자</th>
                            <td style={{width:'35%'}}>
                                <div className="d-flex flex-row gap-2">
                                    <div className="d-flex align-items-center" ref={prodRef}>
                                        <BMCDatePicker2 name={'startApprRgstDt'} defaultValue={startDate} onChange={fn_setSrchData} />
                                        <span>&nbsp;~&nbsp;</span>
                                        <BMCDatePicker2 name={'endApprRgstDt'} defaultValue={endDate} onChange={fn_setSrchData} />
                                    </div>
                                    <div className="d-flex align-items-center gap-1" ref={prodRef}>
                                        <input className="form-check-input bmc-check" id={'isAll'} name={'isAll'} type="checkbox" onChange={fn_setSrchData}/>
                                        <label className={'form-check-label text-black'} htmlFor={'isAll'}>전체기간</label>
                                    </div>
                                </div>
                            </td>
                            <th className="bmc-bg-th" style={{width:"15%"}}>업체명</th>
                            <td style={{width:"35%"}}>
                                <select className='form-select' name={'cmpnySeq'} ref={cmpnySeqRef}
                                        onChange={fn_setSrchData}>
                                    <option value=''>전체</option>
                                    {cmpnyCodeList.map((data: any, idx: number) => (
                                        <option key={idx} value={data.companySeq}>{data.companyName}</option>
                                    ))}
                                </select>
                            </td>
                        </tr>

                        <tr className="border-top border-bottom align-middle">
                            <th className="bmc-bg-th" style={{width:"15%"}}>기안자</th>
                            <td style={{width:"35%"}}>
                                <input type={'text'} name={'drftrNm'} className='form-control'/>
                            </td>
                            <th className="bmc-bg-th" style={{width:"15%"}}></th>
                            <td style={{width:"35%"}}></td>
                        </tr>
                        </tbody>
                    </Table>
                </SHSearch>
                <div className={'ge-result'}>
                    <Datatables
                        dataUrl={'/admin/api/appr/retrieveApprDataList'}
                        parameterFormRef={searchFormRef}
                        columns={
                            [
                                {data: "apprDcmntType", title: "서식함", textAlign: 'center',render: (data: any) => {
                                        switch (data) {
                                            case 'wrkPrmt':
                                                return "작업허가서";
                                            case 'riskAsmnt':
                                                return "위험성평가";
                                            case 'ptw':
                                                return "PTW";
                                            default:
                                                return "알수없음";
                                        }
                                    }},
                                {data: "cmpnyNm", title: "업체명", textAlign: 'center'},
                                {data: "drftrNm", title: "기안자", textAlign: 'center'},
                                {data: "apprDt", title: "기안일", textAlign: 'center',render: function (data: any, type: any, row: any, meta: any) {
                                        if(data){
                                            return moment(data,'YYYYMMDDHHmmss').format("YYYY-MM-DD")
                                        }else{
                                            return "-"
                                        }
                                    }},
                            ]
                        }
                        checked={false}
                        index={true}

                        onLoad={(dt: any) => {
                            setDatatable(dt)
                        }}
                        onClick={(rData) => {
                            onGridClick(rData);
                        }}
                    />
                </div>
            </div>
        </Container>
    );
};

export default ApprWaitPage;
