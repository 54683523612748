import React, {Dispatch, SetStateAction, useContext, useEffect, useRef, useState} from "react";
import {COMMON_MODAL_STYLE} from "../../../helper/StyleHelper";
import {Col, Modal, Row, Table} from "react-bootstrap";
import {IoMdClose} from "react-icons/io";
import Datatables, {getCheckedItems} from "../../component/Datatables";
import SHSearch from "../../component/BMCSearch";
import BMCInput from "../../../components/common/BMCInput";
import restapi from "../../../helper/restAPIHelper";
import {toast} from "react-toastify";
import errorCodeDic from "../../../util/errorCodeDic";
import {ProgressContext} from "../../../context/Progress";
import BMCSelect from "../../../components/common/BMCSelect";
import DailySftyRprtRgstModal from "./DailySftyRprtRgstModal";
import BMCIconButton from "../../../components/common/BMCIconButton";
import axios from "axios";
import NoSbmtSiteListModal from "./NoSbmtSiteListModal";

interface IProps {
    setModalIsOpen: Dispatch<SetStateAction<boolean>>;
    modalIsOpen: boolean;
    tabGb: string;
    chkDt: string;
    dprtCd: string;
    selData: any;
}

const $ = require("jquery")

const DailySftyRprtChkListModal = (props: IProps) => {
    const [datatable, setDatatable] = useState<any>();

    const [isCodeDataLoaded, setIsCodeDataLoaded] = useState<any>(null);
    const searchFormRef = useRef<any>();
    const [dataList, setDataList] = useState<any>([]);
    const {spinner} = useContext(ProgressContext);
    const [srchData, setSrchData] = useState<any>({});
    const [isModalOpen, setIsModalOpen] = useState<boolean>(false);
    const [dtlData, setDtlData] = useState<any>({});
    const [noSbmtSiteDataList, setNoSbmtSiteDataList] = useState<any>([]);
    const [isNoSbmtModalOpen, setIsNoSbmtModalOpen] = useState<boolean>(false);
    function fn_closeModal() {
        props.setModalIsOpen(false);
    }

    useEffect(() => {
        restapi.retrieveSiteDataListNoPaging({chkDt:props.chkDt, isDailyRprtNoSbmt:true, dprtCd:srchData.dprtCd, siteTypeList: ['T002001', 'T002002', 'T002004', 'T002005']}).then((rsltData: any) => {
            setNoSbmtSiteDataList(rsltData);
        })
        fn_retrieveDailyRprtChkStatDataList();
    }, [srchData]);

    const fn_onShow = () => {

        if(props.tabGb == 'dailyList'){
            setSrchData(Object.assign({}, {dprtCd:''}))
        }else if(props.tabGb == 'mngDprtList'){
            setSrchData(Object.assign({}, {dprtCd:props.dprtCd}))
        }
    }

    const fn_retrieveDailyRprtChkStatDataList = () => {
        let param = {chkDt:props.chkDt, dprtCd:srchData.dprtCd};
        spinner.start();
        restapi.retrieveDailyRprtChkStatDataList(param).then((rsltData: any) => {
            setDataList(rsltData);
        }).catch(error => {
            toast.error(errorCodeDic['003']);
            console.log('error: ', error);
        })
        .finally(() => {
            spinner.stop();
        });
    }

    const fn_print = (e: any) =>{
        console.log("selData", props.selData)
        let param ={
            chkDt:props.selData.chkDt,
            chkCnt: props.selData.chkCnt,
            sbmtSiteCnt: props.selData.sbmtSiteCnt,
            title:props.selData.title,
            targetSiteCnt:props.selData.targetSiteCnt,
            dprtCd:srchData.dprtCd
        }
        axios.post('/admin/api/dailySftyRprt/createDailySftyRprtPrint', param).then(rsltData => {
                window.open(process.env.REACT_APP_FILE_URL + "/bmcss-data/fileDownloadByFileName?fileName="+rsltData.data, "_blank")
            }
        ).catch(() => {
                toast.error(errorCodeDic['003'])
            }
        )

    }

    const fn_openNoSbmtSitePop = () => {
        if(noSbmtSiteDataList.length > 0){
            setIsNoSbmtModalOpen(true);
        }
    }


    return (
        <div>
            <NoSbmtSiteListModal setModalIsOpen={setIsNoSbmtModalOpen} modalIsOpen={isNoSbmtModalOpen} dataList={noSbmtSiteDataList} />
            <DailySftyRprtRgstModal setModalIsOpen={setIsModalOpen} modalIsOpen={isModalOpen} detailData={dtlData} siteId={dtlData?.siteId} />
            <Modal
                className="bmc-modal" size="xl"
                show={props.modalIsOpen}
                onShow={() => {
                    fn_onShow()

                }}
                onHide={fn_closeModal}
            >


                <Modal.Header>
                    <div className="d-flex justify-content-between align-items-center w-100 bmc-font-SD">
                        {props.chkDt} 일일안전일보 체크리스트 전체 현황
                        <IoMdClose className='hover:cursor-pointer' onClick={fn_closeModal}/>
                    </div>
                </Modal.Header>

                <Modal.Body className="px-4">
                    <SHSearch formRef={searchFormRef} srchFnc={fn_retrieveDailyRprtChkStatDataList} btnType={'p'}>
                        <Row>
                            <Col>
                                <Table className="bmc-font-SP bmc-fs-b2 my-3">
                                    <tbody>
                                    <tr className="border-top align-middle border-bottom">
                                        <th className="bmc-bg-th">관리부서</th>
                                        <td>
                                            <BMCSelect initOptionGb={'all'} codeGb={'cmmn'} value={srchData.dprtCd} onChange={(e: any) => setSrchData({dprtCd: e.target.value})} codeGrpId={'D001'} name={'dprtCd'} />
                                        </td>

                                    </tr>
                                    </tbody>
                                </Table>
                            </Col>
                        </Row>

                    </SHSearch>

                    <div className='w-100 d-flex justify-content-between align-items-center mb-2'>
                        <span className={'text-[#3561eb] underline cursor-pointer'} onClick={fn_openNoSbmtSitePop}>{noSbmtSiteDataList.length > 0 ? '미제출 사업장 (' + noSbmtSiteDataList.length + '건)' : '미제출 사업장 없음'}</span>
                        <BMCIconButton kind={'normal'} marginRight={'0'} onClick={fn_print}> 보고서 출력
                        </BMCIconButton>
                    </div>
                    <div className={'ge-result'}>
                        <Datatables
                            datas={dataList}
                            scrollY="550px"
                            className="bmc-fs-b4"
                            parameterFormRef={searchFormRef}
                            columns={[
                                {data: "dprtNm", title: "관리부서", textAlign: 'center'},
                                {data: "siteName", title: "공사명", textAlign: 'center'},
                                {data: "cntrctrNm", title: "계약상대자<br/>(관리자)", textAlign: 'center'},
                                {data: "prplWrk", title: "추진공종<br/>(추진업무)", textAlign: 'center'},
                                {data: 'prblmYn', title: "특이사항<br/>(재해 유무)", textAlign: 'center'}
                            ]}
                            checked={false}
                            onLoad={(dt: any) => {
                                setDatatable(dt)
                            }}

                            onClick={(data) => {
                                console.log('data: ', data);
                                restapi.retrieveDailySftyRprtTotDataList({sftyRprtSeq:data.sftyRprtSeq}).then((rsltData: any) => {
                                    console.log('rsltData: ', rsltData);
                                    setDtlData(rsltData[0]);
                                    setIsModalOpen(true);
                                })
                            }
                            }
                        />
                    </div>
                </Modal.Body>
                <Modal.Footer className="py-2">
                    <div className='flex justify-center w-100'>
                        <button
                            className='bmc-btn pill l white me-3'
                            onClick={fn_closeModal}>닫기
                        </button>

                    </div>
                </Modal.Footer>


            </Modal>
        </div>
    )
}

export default DailySftyRprtChkListModal;
