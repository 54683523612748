import React, {Dispatch, SetStateAction, useContext, useEffect, useRef, useState} from "react";
import {COMMON_MODAL_STYLE} from "../../../helper/StyleHelper";
import {Col, Modal, Row, Table} from "react-bootstrap";
import {IoMdClose} from "react-icons/io";
import Datatables, {getCheckedItems} from "../../component/Datatables";
import axios from "axios";
import {toast} from "react-toastify";
import messageCodeDic from "../../../util/messageCodeDic";
import BMCSelect from "../../../components/common/BMCSelect";
import {getLoginUser} from "../../../helper/SecurityHelper";
import BMCSearch from "../../component/BMCSearch";
import BMCInput from "../../../components/common/BMCInput";
import restapi from "../../../helper/restAPIHelper";
import errorCodeDic from "../../../util/errorCodeDic";
import {ProgressContext} from "../../../context/Progress";

interface IProps {
    setModalIsOpen: Dispatch<SetStateAction<boolean>>;
    modalIsOpen: boolean;
    paramData: any;
}

const $ = require("jquery")

const RiskEvalChkHistModal = (props: IProps) => {
    const [datatable, setDatatable] = useState<any>();

    const searchFormRef = useRef<any>();
    const [dataList, setDataList] = useState<any>([]);
    const [cmpnyDataList, setCmpnyDataList] = useState<any>([]);
    const {spinner} = useContext(ProgressContext);

    function fn_closeModal() {
        props.setModalIsOpen(false);
    }

    const fn_search = (e?: any) => {
        e?.preventDefault()
        datatable?.draw()
    }

    const fn_onShow = () => {
        console.log('props.paramData: ', props.paramData);

        spinner.start();
        restapi.retrieveChkHistDataList(props.paramData).then((rsltData: any) => {
            setDataList(rsltData);
        }).catch(error => {
            toast.error(errorCodeDic['003']);
            console.log('error: ', error);
        })
        .finally(() => {
            spinner.stop();
        });

    }


    return (
        <div>

            <Modal
                className="bmc-modal" size="xl"
                show={props.modalIsOpen}
                onShow={() => {
                    fn_onShow()

                }}
                onHide={fn_closeModal}
            >


                <Modal.Header>
                    <div className="d-flex justify-content-between align-items-center w-100 bmc-font-SD">
                        점검이력
                        <IoMdClose className='hover:cursor-pointer' onClick={fn_closeModal}/>
                    </div>
                </Modal.Header>

                <Modal.Body className="px-4">
                    <div className={'ge-result'}>
                        <Datatables
                            datas={dataList}
                            scrollY="550px"
                            className="bmc-fs-b4"
                            parameterFormRef={searchFormRef}
                            columns={
                            [{data: "chkDt", title: "점검일자", textAlign: 'center'},
                            {data: "chckrNm", title: "점검자", textAlign: 'center'},
                            {data: "chkRsltNm", title: "점검결과", textAlign: 'center', render: (data: any) => {
                                    if(data == '양호') {
                                        return '<span class="text-green-500">' + data + '</span>';
                                    }else if(data == '불량') {
                                        return '<span class="text-red-500">' + data + '</span>';
                                    }else{
                                        return '<span>' + data + '</span>';
                                    }
                                }},
                            {data: "chkCntnts", title: "점검내용", textAlign: 'center'},
                            {data: "actStatNm", title: "조치상태", textAlign: 'center', render: (data: any) => {
                                    if(data == '조치완료') {
                                        return '<span class="text-blue-500">' + data + '</span>';
                                    }else{
                                        return '<span>' + data + '</span>';
                                    }
                                }},
                            ]}
                            checked={false}
                            onLoad={(dt: any) => {
                                setDatatable(dt)
                            }}
                            onClick={(data) => {

                            }
                            }
                        />
                    </div>
                </Modal.Body>
                <Modal.Footer className="py-2">
                    <div className='flex justify-center w-100'>
                        <button
                            className='bmc-btn pill l white me-3'
                            onClick={fn_closeModal}>닫기
                        </button>

                    </div>
                </Modal.Footer>


            </Modal>
        </div>
    )
}

export default RiskEvalChkHistModal;
