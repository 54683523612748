import React, {useContext, useEffect, useRef, useState} from "react";
import {Col, Container, Image, Row} from "react-bootstrap";
import HOCRMap from "./HOCRMap";
import DashWeather from "../home/DashWeather";
import HOCRTBMStatus from "./HOCRTBMStatus";
import HOCRInOut from "./HOCRInOut";
import HOCRRiskStatus from "./HOCRRiskStatus";
import HOCRTrafficLight from "./HOCRTrafficLight";
import HOLocalFieldStatus from "./HOLocalFieldStatus";
import HOConstructionSummary from "./HOConstructionSummary";
import HOTopMenuBar from "./HOTopMenuBar";
import {Box, IconButton, Typography} from "@mui/material";
import {getLoginUser, logout} from "../../helper/SecurityHelper";
import BMCDropdown from "../../components/common/BMCDropdown";
import {ReactComponent as IconLogo} from '../../assets/images/logo/logo-bmc-main-vertical.svg';
import {ReactComponent as IconAlarm} from '../../images/icon_alarm.svg';
import {ReactComponent as IconLink} from '../../images/icon_link.svg';
import {ReactComponent as IconLogout} from '../../assets/images/ico/icon-logout.svg';
import {useNavigate} from "react-router-dom";
import IconUnknownUser from "../../assets/images/icon/icon-Oval.png";
import HeadOfficeSiteCCTV from "./HOSiteCCTV";
import IconClock from "../../assets/images/icon/icon_ho_time.png";
import IconCalendar from "../../assets/images/icon/icon_ho_date.png";
import {ReactComponent as IconWthrShiny} from "../../assets/images/icon/icon_wthr_sky_1.svg";
import {ReactComponent as IconWthrCldy} from "../../assets/images/icon/icon_wthr_sky_3.svg";
import {ReactComponent as IconWthrCld} from "../../assets/images/icon/icon_wthr_sky_4.svg";
import {ReactComponent as IconWthrRain} from "../../assets/images/icon/icon_wthr_pty_1.svg";
import {ReactComponent as IconWthrSnow} from "../../assets/images/icon/icon_wthr_pty_3.svg";
import {SiteContext} from "../../context/Site";
import restapi from "../../helper/restAPIHelper";
import {Constatnts} from "../../util/Constants";

export default function MainControl() {
    const [isRcvNtfyModal, setIsRcvNtfyModal] = useState<boolean>(false);
    const [isNewNtfy, setIsNewNtfy] = useState<boolean>(false);
    const user = getLoginUser();
    const {site} = useContext<any>(SiteContext);
    const [wthrInfo, setWthrInfo] = useState<any>({});
    const [pm10Grade, setPm10Grade] = useState<string|null>(null);
    const [pm10Color, setPm10Color] = useState<any|null>(null);
    const [pm25Grade, setPm25Grade] = useState<string|null>(null);
    const [pm25Color, setPm25Color] = useState<any|null>(null);

    const navigate = useNavigate();

    useEffect(() => {
        console.log('main site: ', site);
        if(site){
            // 날씨 정보 가져오기
            restapi.retrieveWtherInfoData(site.siteId).then((rsltData) => {
                console.log('whthr head: ', rsltData);
                setWthrInfo(rsltData);
            })
        } else {
            // 날씨 정보 가져오기
            restapi.retrieveWtherInfoData().then((rsltData) => {
                console.log('whthr head: ', rsltData);
                setWthrInfo(rsltData);
            })
        }

    }, [site])

    useEffect(() => {
        var pm10Value = wthrInfo.pm10
        if(10 <= pm10Value && pm10Value <= 30){
            setPm10Grade("좋음")
            setPm10Color("#1c67d7")
        }else if(31 <= pm10Value && pm10Value <= 80){
            setPm10Grade("보통")
            setPm10Color("#01b56e")
        }else if(81 <= pm10Value && pm10Value <= 150){
            setPm10Grade("나쁨")
            setPm10Color("#f9c700")
        }else if(151 <= pm10Value){
            setPm10Grade("매우나쁨")
            setPm10Color("#e53030")
        }
        var pm25Value = wthrInfo.pm25
        if(10 <= pm25Value && pm25Value <= 15){
            setPm25Grade("좋음")
            setPm25Color("#1c67d7")
        }else if(16 <= pm25Value && pm25Value <= 35){
            setPm25Grade("보통")
            setPm25Color("#01b56e")
        }else if(36 <= pm25Value && pm25Value <= 75){
            setPm25Grade("나쁨")
            setPm25Color("#f9c700")
        }else if(75 <= pm25Value){
            setPm25Grade("매우나쁨")
            setPm25Color("#e53030")
        }
    }, [wthrInfo])


    const fn_openRcvNtfyPop = () => {
        setIsRcvNtfyModal(true);
    }

    const fn_onMainLogoClick = () => {

    }
    const fn_onTopMenuChg = (e: any) => {
        console.log('e.target.value: ', e.target.value);
        if(e.target.value == 'headWeb'){
            navigate('/');
        }
    }

    const [date, setDate] = useState(() => new Date());
    const week = ['일요일', '월요일', '화요일', '수요일', '목요일', '금요일', '토요일'];
    let dayOfWeek = week[date.getDay()];

    const tick = () => {
        setDate(new Date())
    }

    useEffect(() => {
        //const timeId = setInterval(() => tick(), 1000);
        return () => {
            //clearInterval(timeId)
        }
    }, []);

    return(
        <div style={{backgroundColor: '#f8f9fe'}}>
            <Container fluid style={{background: "url('/bg_wave_back.png') no-repeat right top #0e254c", zIndex: 1, height: 300}} >
                <Row className="px-4 py-3">
                    <Col bsPrefix="col-4">
                        <div className="d-flex h-100 align-items-center cursor-pointer" onClick={() => fn_onMainLogoClick()}>
                            <IconLogo height={60} />
                            <span className="bmc-txt-size-b4" style={{marginLeft: '-26px', marginTop: '6px', fontWeight: '500', letterSpacing: '-0.025rem'}}>
                                · 안전관리통합시스템
                            </span>
                        </div>
                    </Col>
                    <Col bsPrefix="col-4">
                        <div className="d-flex h-100 align-items-center cursor-pointer w-100 justify-content-center">
                            <select className='bmc-menu-site-header-title text-center'
                                    onChange={fn_onTopMenuChg} >
                                <option value={'mainControl'} style={{color:"black"}}>본사 종합 관제 상황실ㅤ</option>
                                <option value={'headWeb'} style={{color:"black"}}>본사 업무용</option>
                            </select>
                        </div>
                    </Col>
                    <Col bsPrefix="col-4">
                        <div className="d-flex justify-content-end align-items-center">

                            <div className="d-flex align-items-center">
                                <div className="bmc-mctrl-alarm me-3 position-relative cursor-pointer" onClick={fn_openRcvNtfyPop}>
                                    <IconAlarm style={{width: '20px'}}/>

                                    {isNewNtfy && <span className="position-absolute top-5 start-100 translate-middle p-1 bg-danger border border-light rounded-circle">
                                        <span className="visually-hidden">New alerts</span>
                                      </span>}
                                </div>

                                <div className="bmc-mctrl-user">
                                    <div className="me-2">
                                        <Image style={{width: '40px', height: '40px'}} src={IconUnknownUser} roundedCircle />
                                    </div>
                                    <div className="me-2">
                                        <div style={{color: '#fff', fontSize: '14px', fontWeight: '600'}}>{user?.userName}</div>
                                        <div style={{color: '#8F95B2', fontSize: '12px'}}>{user?.dprtNm}</div>
                                    </div>
                                </div>

                                {/*<IconButton
                                    size="large"
                                    aria-label="account of current user"
                                    aria-controls="menu-appbar"
                                    aria-haspopup="true"
                                    color="inherit"
                                    onClick={logout}
                                >
                                    <IconLogout  style={{width: '20px'}}/>
                                </IconButton>*/}
                            </div>
                        </div>
                    </Col>
                </Row>
                {/*<Row>
                    <div className={'pt-1'}>
                        <Box className="d-flex align-items-center justify-content-end w-100 h-100">

                            <Box className="d-flex align-items-center px-4 border-start">
                                <div className="me-2">
                                    <span className="bmc-menu-site-head-header-time">{'현재날씨'}</span>
                                </div>
                                {wthrInfo &&
                                    <img className="me-1" src={wthrInfo.sky == 1 ? IconWthrShiny : wthrInfo.sky == 3 ? IconWthrCld : wthrInfo.sky == 4 ? IconWthrCldy : ''} style={{width: '30px', height: '30px'}} alt="시계"/>
                                }
                                <span className="bmc-menu-site-head-header-weeks ml-3">
                                 {wthrInfo.t1h ? wthrInfo.t1h + '°' : '미설정'}
                                </span>
                            </Box>
                            <Box className="d-flex align-items-center px-4 border-start">
                                <div className="me-2">
                                    <span className="bmc-menu-site-head-header-time">{'풍속'}</span>
                                </div>
                                <span className="bmc-menu-site-head-header-weeks">
                                    {wthrInfo.wsd ? wthrInfo.wsd + 'm/s' : '미설정'}
                                </span>
                            </Box>
                            <Box className="d-flex align-items-center px-4 border-start">
                                <div className="me-2">
                                    <span className="bmc-menu-site-head-header-time">{'미세먼지'}</span>
                                </div>
                                <span className="bmc-menu-site-head-header-weeks">
                                        {pm10Grade}
                                    </span>
                            </Box>
                        </Box>
                    </div>
                </Row>*/}
                <Row className="pb-5">
                    <div className="ps-4 pe-3 bmc-txt-size-b4 d-flex justify-content-between align-items-center">
                        <div>
                            <div className="mb-1 ps-2">
                                안녕하세요. {user?.userName}님, 안전관리 본사 종합 관제 상황실에 오신 것을 환영합니다.
                            </div>
                        </div>

                        <div>
                            <div className={'me-3'}>
                                <Box className="d-flex align-items-center justify-content-end w-100 h-100">
                                    <div className="d-flex align-items-center me-3">
                                        <img className="me-2" src={IconCalendar} style={{width: '25px', height: '25px'}} alt="달력"/>
                                        <span className="bmc-text-white bmc-txt-size-h5">{date.toLocaleDateString()} {dayOfWeek}</span>
                                    </div>
                                    <div className="d-flex align-items-center">
                                        <img className="me-2" src={IconClock} style={{width: '25px', height: '25px'}} alt="시계"/>
                                        <span className="bmc-text-white bmc-txt-size-h5" style={{width: 90}}>{date.toLocaleTimeString('en-GB')}</span>
                                    </div>

                                    <Box className="d-flex align-items-center mb-1">
                                        <div className="me-3">
                                            <span className="bmc-txt-size-b6" style={{color: 'rgba(255,255,255,0.7)'}}>풍속</span>
                                        </div>
                                        <span className="bmc-txt-size-h4">
                                            {wthrInfo.wsd ? wthrInfo.wsd + 'm/s' : '-'}
                                        </span>

                                    </Box>
                                    <span className="px-3 mb-1" style={{color: 'rgba(255,255,255,0.1)'}}>|</span>
                                    <Box className="d-flex align-items-center mb-1">
                                        <div style={{marginLeft: 5, marginRight: 5, marginTop: 4}}>
                                            {
                                                wthrInfo.hasOwnProperty('sky') && (<>
                                                    { wthrInfo.pty === 1 ? <IconWthrRain fill='#fff' width={30} /> : wthrInfo.pty === 3 ? <IconWthrSnow fill='#fff' width={30} />
                                                        : wthrInfo.sky === 1 ? <IconWthrShiny fill='#fff' width={30} /> : wthrInfo.sky === 3 ? <IconWthrCldy fill='#fff' width={30} /> : wthrInfo.sky === 1 ? <IconWthrCld fill='#fff' width={30} /> : ''}
                                                </>)
                                            }
                                        </div>
                                        <div className="bmc-txt-size-h4 me-3">
                                            {wthrInfo.t1h ? wthrInfo.t1h + '℃' : '-'}
                                        </div>
                                    </Box>
                                    <span className="px-3 mb-1" style={{color: 'rgba(255,255,255,0.1)'}}>|</span>
                                    <Box className="d-flex align-items-center mb-1">
                                        <div className="me-3">
                                            <span className="bmc-txt-size-b6" style={{color: 'rgba(255,255,255,0.7)'}}>{'미세먼지'}</span>
                                        </div>
                                        {/*<span className="bmc-txt-size-h4" style={{color: pm10Color}}>
                                            {pm10Grade}
                                        </span>*/}
                                        <span className="bmc-txt-size-h4">
                                            {pm10Grade ? pm10Grade : '-'}
                                        </span>
                                    </Box>
                                </Box>
                            </div>
                        </div>
                    </div>
                </Row>
            </Container>
            <Container fluid style={{marginTop:"-140px"}}>
                <Row className={"ps-2 pe-2"}>
                    <div style={{height: 'calc(100vh - 180px)', overflowY:'auto', width : '400px'}}>
                        <HOCRTrafficLight /><br/>
                        <HOCRTBMStatus /><br/>
                        <HOCRRiskStatus /><br/>
                    </div>
                    <div style={{height: 'calc(100vh - 180px)', flex : '1 0 0%'}}>
                        <HOCRMap />
                    </div>
                    <div style={{height: 'calc(100vh - 180px)', overflowY:'auto', width : '450px'}}>
                        <HOCRInOut /><br/>
                        <HeadOfficeSiteCCTV /><br/>
                    </div>
                </Row>
            </Container>
        </div>
    )
}
