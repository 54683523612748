import React, {useContext, useEffect, useRef, useState} from 'react';
import axios from "axios";
import Datatables, {getCheckedItems} from "../component/Datatables";
import Modal from 'react-modal';

import {MultiSelect} from '@grapecity/wijmo.react.input';
import {getLoginUser} from "../../helper/SecurityHelper";
import {toast} from "react-toastify";
import messageCodeDic from "../../util/messageCodeDic";
import errorCodeDic from "../../util/errorCodeDic";
import SHSearch from '../component/BMCSearch';
import {Col, Container, Row, Table} from 'react-bootstrap';
import SubMenuTitle from "../component/SubMenuTitle";
import BMCIconButton from "../../components/common/BMCIconButton";
import BMCSelect from "../../components/common/BMCSelect";
import BMCInput from "../../components/common/BMCInput";
import BMCDatePicker from "../../components/common/BMCDatePicker";
import CustomDatePickerRange from "../component/ui/DatePickerRange";
import SHSearchButton from "../../components/common/BMCSearchButton";
import {UserContext} from "../../context/User";
import restapi from "../../helper/restAPIHelper";
import SiteDocsRgstModal from '../safetyWorkReportManagement/modal/SiteDocsRgstModal';


type Props = {};


Modal.setAppElement('#root');
const HeadSiteDocsPage = (props: Props) => {
    const [modalIsOpen, setModalIsOpen] = React.useState(false);
    const defaultUser = getLoginUser();
    const srchTxtRef = useRef<any>();
    const searchFormRef = useRef<any>();
    const [datatable, setDatatable] = useState<any>();
    const {user} = useContext<any>(UserContext);
    const [dtlData, setDtlData] = useState<any>({});

    useEffect(()=>{

        if(datatable){
            datatable.draw();
        }

    }, [user, datatable])

    useEffect(() => {

        datatable?.draw();

    }, [modalIsOpen])


    const fn_delete = () => {
        let checkedData = getCheckedItems(datatable, "docsId")

        if (checkedData.length == 0) {
            toast.warn(messageCodeDic['005']);
            return;
        }

        if (!window.confirm(messageCodeDic['004'])) {
            return;
        }

        axios.post('/admin/api/siteDocs/deleteSiteDocsData', {delList: checkedData}).then(rsltData => {
                if (rsltData.data > 0) {
                    toast.success(messageCodeDic['002'])
                    datatable?.draw();
                } else {
                    toast.error(errorCodeDic['003'])
                }

            }
        ).catch(() => {
                toast.error(errorCodeDic['003'])
            }
        )
    }



    const fn_registPopOpen = () => {
        setDtlData({});
        setModalIsOpen(true);
    }



    return (
        <Container fluid className="bg-white px-0 bmc-txt-color-primary">
            <SiteDocsRgstModal siteId={user?.blngSiteId || defaultUser.blngSiteId} setModalIsOpen={setModalIsOpen} modalIsOpen={modalIsOpen} dtlData={dtlData}></SiteDocsRgstModal>
            <SubMenuTitle title={'현장문서함'} parentMenu={'안전 정보 관리'}/>

            <div className="p-4 overflow-y-auto" style={{height: 'calc(100vh - 197px)'}}>
                <SHSearch datatable={datatable} formRef={searchFormRef} btnType={'p'}>
                    <Table className="bmc-font-SP bmc-fs-b2 my-3">
                        <tbody>
                        <tr className="border-top align-middle border-bottom">
                            <th className="bmc-bg-th" style={{width:'15%'}}>현장</th>
                            <td style={{width:'35%'}}>
                                <BMCSelect initOptionGb={'all'} name={'siteId'} codeGb={'site'}/>
                            </td>
                            <th className="bmc-bg-th" style={{width:'15%'}}>검색어</th>
                            <td style={{width:'35%'}}>
                                <input type={'text'} name={'srchTxt'} className='form-control' ref={srchTxtRef}/>
                            </td>
                        </tr>
                        </tbody>
                    </Table>
                </SHSearch>


                <div className='w-100 d-flex justify-content-end align-items-center mb-2'>
                    <BMCIconButton kind={'delete'} marginRight={'0'} onClick={fn_delete}>삭제</BMCIconButton>
                </div>

                <div className={'ge-result'}>
                    <Datatables
                        dataUrl={'/admin/api/siteDocs/retrieveSiteDocsDataList'}
                        parameterFormRef={searchFormRef}
                        columns={[

                            {data: "siteName", title: "현장명"},
                            {data: "fileNm", title: "파일명"},
                            {data: "rgstrNm", title: "작성자"},
                            {data: "rgstDt", title: "등록일"},
                        ]}
                        checked={true}
                        index={true}

                        onLoad={(dt: any) => {
                            setDatatable(dt)
                        }}
                        onClick={(data) => {
                            setDtlData(data)
                            setModalIsOpen(true);
                        }
                        }
                    />
                </div>
            </div>
        </Container>


    );
};

export default HeadSiteDocsPage;
