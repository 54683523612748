import React, {Dispatch, SetStateAction, useContext, useEffect, useRef, useState} from 'react';
import restapi from "../../../helper/restAPIHelper";
import {get, transform, transformExtent} from "ol/proj";
import {Feature, Overlay} from "ol";
import {Point, Polygon} from "ol/geom";
import VectorLayer from "ol/layer/Vector";
import {Cluster, XYZ} from "ol/source";
import VectorSource from "ol/source/Vector";
import {Circle, Fill, Icon, Stroke, Style, Text} from "ol/style";
import TileLayer from "ol/layer/Tile";
import {GeoJSON} from "ol/format";
import proj4 from "proj4";
import {register} from "ol/proj/proj4";
import Map from "ol/Map";
import View from "ol/View";
import SiteInfoListModal from "../modal/SiteInfoListModal";
import {CmmnFn} from "../../../helper/CmmnHelper";
import pointOnFeature from '@turf/point-on-feature'

import {getArea, getLength} from 'ol/sphere.js';
import {Col, Container, Row} from "react-bootstrap";
import Marker00 from "../../../images/map/icon_marker_idx_00.png";
import Marker01 from "../../../images/map/icon_marker_idx_01.png";
import Marker02 from "../../../images/map/icon_marker_idx_02.png";
import Marker03 from "../../../images/map/icon_marker_idx_03.png";
import Marker04 from "../../../images/map/icon_marker_idx_04.png";
import {ReactComponent as IconSosCall} from "../../assets/images/icon/icon-map-label-construct.svg";

import {ReactComponent as IconMapLabelEtc} from "../../../assets/images/icon/icon-map-label-etc.svg";
import {ReactComponent as IconMapLabelFacility} from "../../../assets/images/icon/icon-map-label-facility.svg";
import {ReactComponent as IconMapLabelConstruct} from "../../../assets/images/icon/icon-map-label-construct.svg";
import {ReactComponent as IconMapLabelLand} from "../../../assets/images/icon/icon-map-label-land.svg";
import {ReactComponent as IconMapLabelRepair} from "../../../assets/images/icon/icon-map-label-repair.svg";
import {getLoginUser, setItemWithExpireTime, USER_SESSION} from "../../../helper/SecurityHelper";
import {toast} from "react-toastify";
import errorCodeDic from "../../../util/errorCodeDic";
import {ProgressContext} from "../../../context/Progress";
import {UserContext} from "../../../context/User";
import {useNavigate} from "react-router-dom";
import {getCenter} from "ol/extent";
import SmSiteWrkListModal from "../modal/SmSiteWrkListModal";
const $ = require("jquery")


interface IProps {
    siteType?: string;
    height?: string;
}

export default function InfoMap(props: IProps) {
    const mapRef = useRef<HTMLDivElement>(null);
    const overlayRef = useRef<HTMLDivElement>(null);
    const [total, setTotal] = useState<number>();
    const [countT002001, setCountT002001] = useState<number>();
    const [countT002002, setCountT002002] = useState<number>();
    const [countT002003, setCountT002003] = useState<number>();
    const [countT002004, setCountT002004] = useState<number>();
    const [countT002005, setCountT002005] = useState<number>();
    const [modalIsOpen, setModalIsOpen] = useState<boolean>(false);
    const button01Ref = useRef<any>();
    const button02Ref = useRef<any>();
    const button03Ref = useRef<any>();
    const button04Ref = useRef<any>();
    const button05Ref = useRef<any>();
    const [activeButtonIdx, setActiveButtonIdx] = useState<number>();
    const [activeButton, setActiveButton] = useState<any>();
    const [siteData, setSiteData] = useState<any>([]);
    const [siteDataFilter, setSiteDataFilter] = useState<any>([]);
    const [siteDataAll, setSiteDataAll] = useState<any>([]);
    const [dataList, setDataList] = useState<any>([]);
    const [siteLayer, setSiteLayer] = useState<any>();
    const [siteType, setSiteType] = useState<string>('');
    const {spinner} = useContext(ProgressContext);
    const defaultUser: any = getLoginUser();
    const {user,setUser} = useContext<any>(UserContext);
    const navigate = useNavigate();
    const [smWrkDataList, setSmWrkDataList] = useState<any>([]);
    const [isSmWrkModalOpen, setIsSmWrkModalOpen] = useState<boolean>(false);
    const [smSiteId, setSmSiteId] = useState<number>(0);
    const [bubbleColor, setBubbleColor] = useState<string>('#0DD7A6');

    function open_site_list(siteIds: string[]){
        if(siteIds.length === 0 && siteType != ''){
            setDataList([]);
            setModalIsOpen(true);
        }else{
            restapi.retrieveSiteDataListForMap({siteIds:siteIds, siteType: props.siteType}).then((rsltData: any) => {

                setDataList(rsltData);
                setModalIsOpen(true);


            })


        }
    }
    function load_site(){
        restapi.retrieveSiteDataListForMap({isSmSite: false, yyyymmdd: CmmnFn.fn_getCurrentDate().replace(/-/gi, ""), siteType: props.siteType}).then((rsltData: any) => {
            console.log('map rslt: ', rsltData);
            restapi.retrieveSmSiteInfoData().then((smSiteInfo: any) => {
                let param: any = {};
                const siteId = smSiteInfo.siteId;
                setSmSiteId(siteId);
                param.prcsStatCd = 'S001004';
                param.siteId = siteId;
                param.wrkDt = CmmnFn.fn_getCurrentDate();

                restapi.retrieveWrkPrmtDataTotList(param).then((smRsltData: any) => {

                    smRsltData.map((data: any, idx: number) => {
                        data.siteType = 'T002002'
                        data.isShowOnMap = true

                    })

                    rsltData = rsltData.concat(smRsltData);
                    setActiveButtonIdx(-1)
                    // @ts-ignore
                    window.___setSiteDataAll = rsltData
                    setSiteDataAll(rsltData)
                    var lst = []
                    for(var i in rsltData){
                        if(rsltData[i].isShowOnMap === true){
                            if(rsltData[i].latitude && rsltData[i].longitude){
                                lst.push(rsltData[i])
                            }
                        }
                    }

                    console.log('lst: ', lst);
                    setSiteData(lst)
                    setSiteDataFilter(lst)

                })
            })



        })
    }


    useEffect(() => {
        console.log(111, siteDataAll)
        if(siteDataAll.length > 0){
            fn_select(0);
            var count1 = 0
            var count2 = 0
            var count3 = 0
            var count4 = 0
            var count5 = 0
            for(var i in siteDataAll){
                var item : any = siteDataAll[i]
                var typeCode = item.siteType
                if(typeCode === 'T002001'){
                    // 택지조성공사
                    count1++
                }else if(typeCode === 'T002002'){
                    // 기반시설공사
                    count2++
                }else if(typeCode === 'T002003'){
                    // 공동주택건축공사
                    count3++
                }else if(typeCode === 'T002004'){
                    // 소규모공사
                    count4++
                }else if(typeCode === 'T002005'){
                    // 해체공사
                    count5++
                }
            }
            setTotal(siteDataAll.length)
            setCountT002001(count1)

            setCountT002003(count3)
            setCountT002004(count4)
            setCountT002005(count5)

            restapi.retrieveSmSiteInfoData().then((smSiteInfo: any) => {
                let param: any = {};
                const siteId = smSiteInfo.siteId;
                setSmSiteId(siteId);
                param.prcsStatCd = 'S001004';
                param.siteId = siteId;
                param.wrkDt = CmmnFn.fn_getCurrentDate();

                restapi.retrieveWrkPrmtDataTotList(param).then((rsltData: any) => {
                    console.log('smSiteData: ', rsltData);
                    setCountT002002(rsltData.length);
                    setSmWrkDataList(rsltData);
                })
            })




        }
    },[siteData])
    useEffect(() => {
        if(siteDataFilter.length > -1){
            if(siteLayer){
                var features = siteLayer.getSource().getFeatures();
                var values = {}
                for(var i in siteDataFilter){
                    var site = siteDataFilter[i]
                    var bjdCode = site.bjdCode
                    if(bjdCode){
                        bjdCode = bjdCode.substring(0,5)
                    }
                    for(var f in features){
                        var feature = features[f]
                        var sigCd = feature.get("SIG_CD")
                        if(bjdCode === sigCd){
                            // @ts-ignore
                            if(values[sigCd] === undefined){
                                // @ts-ignore
                                values[sigCd] = 0
                            }
                            // @ts-ignore
                            values[sigCd]++
                            break
                        }
                    }
                }

                var color = bubbleColor + "aa"
                for(var f in features){
                    var feature = features[f]
                    var sigCd = feature.get("SIG_CD")
                    // @ts-ignore
                    var count = values[sigCd];
                    // @ts-ignore
                    feature.set("COUNT", count)

                    var geometry: any = feature.getGeometry();
                    var ps = geometry.getPolygons();
                    var idx: any = 0
                    var area = 0
                    for(var i in ps){
                        if(ps[i].getArea() > area){
                            area = ps[i].getArea()
                            idx = i
                        }
                    }
                    var g = geometry.getPolygon(+idx).getInteriorPoint()
                    g.setCoordinates([g.getCoordinates()[0],g.getCoordinates()[1] + 500])

                    var radius = 14 + (2 * (count));
                    if(radius > 40){
                        radius = 40
                    }

                    feature.setStyle(new Style({
                        image: new Circle({
                            radius: radius, // Adjust as needed
                            fill: new Fill({
                                color: color
                            }),
                            stroke: new Stroke({
                                color: "#ffffffaa",
                                width: 4
                            })
                        }),
                        text: new Text({
                            text: count + "",
                            font:'Bold 14px Verdana',
                            fill: new Fill({
                                color: '#ffffff'
                            })
                        }),
                        geometry: g,
                    }));
                }
            }
        }
    },[siteDataFilter])
    useEffect(() => {
        var statisLayer = new VectorLayer({
            source: new VectorSource({
                url: '/geojson/busan.geojson',
                format: new GeoJSON(),
                wrapX: false
            }),
            style: function(feature, resolution) {
                //var label = feature.get("SIG_KOR_NM");
                var count = feature.get("COUNT") ? +feature.get("COUNT") : 0;
                if(count === 0){
                    return [new Style({
                        fill : new Fill({color : "#ffffff11"})
                    })]
                }
                var radius = 14 + (2 * (count));
                if(radius > 40){
                    radius = 40
                }
                var color = '#0DD7A6'
                var circleStyle = new Circle({
                    radius: radius,
                    stroke: new Stroke({
                        color: "#ffffffaa",
                        width: 4
                    }),
                    fill: new Fill({
                        color: color + "aa"
                    })
                })
                var geometry: any = feature.getGeometry();
                var ps = geometry.getPolygons();
                var idx: any = 0
                var area = 0
                for(var i in ps){
                    if(ps[i].getArea() > area){
                        area = ps[i].getArea()
                        idx = i
                    }
                }
                var g = geometry.getPolygon(+idx).getInteriorPoint()
                g.setCoordinates([g.getCoordinates()[0],g.getCoordinates()[1] + 500])
                return [new Style({
                    image: circleStyle,
                    text: new Text({
                        text: count + "",
                        font:'Bold 14px Verdana',
                        fill: new Fill({
                            color: '#ffffff'
                        })
                    }),
                    geometry: g,
                })]
            },
        });
        statisLayer.setVisible(true)
        var BASE1_LAYER = new TileLayer({visible : true, opacity: 0.1})
        BASE1_LAYER.setSource(new XYZ({
            projection: "EPSG:900913",
            tileUrlFunction : function(tileCoord, pixelRatio, projection){
                var z = tileCoord[0]
                var x = tileCoord[1]
                var y = tileCoord[2]
                //
                var type = "2D영상"
                var path = null
                var ext = 'png'
                if(type == '2D지도'){
                    path = '2d/Base/service'
                }else if(type == '2D회색'){
                    path = '2d/gray/service'
                }else if(type == '2D야간'){
                    path = '2d/midnight/service'
                }else if(type == '2D하이브리드'){
                    path = '2d/Hybrid/service'
                }else if(type == '2D영상'){
                    path = '2d/Satellite/service'
                    ext = 'jpeg'
                }
                return 'https://xdworld.vworld.kr/' + path + '/' + z + '/' + x + '/' + y + '.' + ext
            },
            crossOrigin: "Anonymous"
        }))
        var boundryLayer = new VectorLayer({
            source: new VectorSource({
                url: '/geojson/busan_boundry.geojson',
                format: new GeoJSON(),
                wrapX: false
            }),
            style: function(feature, resolution) {
                return [new Style({
                    stroke : new Stroke({color : '#b9babacc',width : 4})
                })]
            },
            opacity: 0.2
        });
        var busanBackLayer = new VectorLayer({
            source: new VectorSource({
                url: '/geojson/busan.geojson',
                format: new GeoJSON(),
                wrapX: false
            }),
            style: function(feature, resolution) {
                return [new Style({
                    stroke : new Stroke({color : '#b9baba88',width : 2}),
                    fill : new Fill({color : '#ffffff'})
                })]
            },
            declutter: true
        });
        var busanLayer = new VectorLayer({
            source: new VectorSource({
                url: '/geojson/busan.geojson',
                format: new GeoJSON(),
                wrapX: false
            }),
            style: function(feature, resolution) {
                var label = feature.get("SIG_KOR_NM");
                var geometry = feature.getGeometry();
                var ps = geometry.getPolygons();
                var idx = 0
                var area = 0
                for(var i in ps){
                    if(ps[i].getArea() > area){
                        area = ps[i].getArea()
                        idx = i
                    }
                }
                var g = geometry.getPolygon(+idx)
                return [new Style({
                    geometry: g,
                    text :  new Text({
                        text: label,
                        overflow: true,
                        fill: new Fill({color: '#000000'}),
                        stroke: new Stroke({
                            color: 'rgba(255,255,255,1)',
                            width: 2
                        }),
                        font: "bold 17px Arial", offsetX: 0,offsetY: 10
                    })
                })]
            },
            declutter: true,
            opacity: 0.6
        });
        proj4.defs('EPSG:5179', '+title=Korea 2000 / Unified CS +proj=tmerc +lat_0=38 +lon_0=127.5 +k=0.9996 +x_0=1000000 +y_0=2000000 +ellps=GRS80 +units=m +no_defs')
        register(proj4)
        get('EPSG:5179')?.setExtent([90112, 1192896, 1990673, 2761664]);
        // @ts-ignore
        window.___dangerLayer = new VectorLayer({
            source: new VectorSource({wrapX: false}),
            style: function(){
                // @ts-ignore
                if(window.gifCtx){
                    return new Style({
                        image: new Icon({
                            // @ts-ignore
                            img: window.gifCtx.canvas,
                            // @ts-ignore
                            imgSize: [window.gifFrame.width, window.gifFrame.height],
                            anchor: [0.5, 1.2],
                            offset: [0,0],
                        })
                    })
                }
            }
        });
        // @ts-ignore
        window.___overlay = new Overlay({
            // @ts-ignore
            element: overlayRef.current,
            autoPan: false,
            positioning: "bottom-left",
            offset: [5,-10],
            autoPanAnimation: {
                duration: 250
            }
        })
        const map = new Map({
            // @ts-ignore
            target: mapRef.current,
            layers: [
                // @ts-ignore
                busanBackLayer, boundryLayer, busanLayer, statisLayer, window.___dangerLayer
            ],
            // @ts-ignore
            overlays: [window.___overlay],
            view: new View({
                center: [0,0],
                maxZoom : 19.4,
                minZoom : 7
            }),
        });
        map.once('postrender', function(e) {
            var map = e.map
            // @ts-ignore
            window.___dashboard_map = map
            var extent3857
            // @ts-ignore
            if(window.___last_map_extent){
                // @ts-ignore
                extent3857 = window.___last_map_extent
            }else{
                var extent = [128.8, 35.02, 129.3, 35.36]
                extent3857 = transformExtent(extent, 'EPSG:4326', 'EPSG:3857')
            }
            // @ts-ignore
            map.getView().fit(extent3857 , map.getSize())
            setTimeout(function(){
                map.updateSize()
            },200)
            setSiteLayer(statisLayer)
            load_site()
        })
        map.on('singleclick', function(e) {
            var map = e.map
            var finds: any = []
            map.forEachFeatureAtPixel(e.pixel,function(feature) {
                finds.push(feature)
            },{hitTolerance: 2, layerFilter: function(layer) {
                    // @ts-ignore
                    return layer === statisLayer || layer === window.___dangerLayer;
            }})
            if(finds.length > 0) {
                var feature = finds[0]
                var featureType: string = feature.get("FEATURE_TYPE")
                if(featureType === 'DANGER'){
                    if(window.confirm('선택한 경고 현장으로 이동 하시겠습니까?')){
                        var siteId: string = feature.get("SITE_ID")
                        fn_moveToSiteWeb(siteId);
                    }
                }else{
                    var sigCd = feature.get("SIG_CD")
                    var siteIds: string[] = []
                    // @ts-ignore
                    for(var i in window.___setSiteDataAll) {
                        // @ts-ignore
                        var site = window.___setSiteDataAll[i]
                        var bjdCode = site.bjdCode
                        if (bjdCode) {
                            bjdCode = bjdCode.substring(0, 5)
                        }
                        if(sigCd === bjdCode){
                            siteIds.push(site.siteId)
                        }
                    }
                    //console.warn(siteIds)
                    if(siteIds.length > 0){
                        open_site_list(siteIds)
                    }
                }
            }
        })
        map.on('pointermove', function(e) {
            var map = e.map
            var finds: any = []
            map.forEachFeatureAtPixel(e.pixel,function(feature) {
                finds.push(feature)
            },{hitTolerance: 2, layerFilter: function(layer) {
                    // @ts-ignore
                    return layer === window.___dangerLayer;
                }})
            if(finds.length > 0) {
                var feature = finds[0]
                var siteName: string = feature.get("SITE_NAME")
                var point = feature.getGeometry().getCoordinates()
                // @ts-ignore
                $(window.___overlay.element).find("#bmc-ol-modal-layer").html("<div class='layer-wrap'>" + siteName + "</div>")
                // @ts-ignore
                window.___overlay.setPosition(point)
            }else{
                // @ts-ignore
                $(window.___overlay.element).find("#bmc-ol-modal-layer").empty();
            }
        })
        map.on("moveend", function(e){
            var extent = e.map.getView().getViewStateAndExtent().extent
            console.log(extent)
            // @ts-ignore
            window.___last_map_extent = extent
        });

        return () => {
            // @ts-ignore
            if(mapRef.current){
                mapRef.current.innerHTML = ''
            }
            // @ts-ignore
            if(overlayRef.current){
                overlayRef.current.innerHTML = ''
            }
        }
    }, [mapRef.current]);
    const fn_moveToSiteWeb = (siteId: any) => {
        /* nhk temp */
        // if(![449,437,131].includes(siteId)){
        //     return;
        // }
        spinner.start();
        restapi.changeUserSiteHeadWebStat({blngSiteId: siteId, userSeq: defaultUser.userSeq}).then((rsltCnt: any) => {
            if (rsltCnt > 0) {
                restapi.getUserSession(defaultUser.userSeq).then((data: any) => {
                    setUser(data);
                    setItemWithExpireTime(USER_SESSION, data);
                    navigate('/')
                });
            }else{
                toast.error(errorCodeDic['003']);
            }
        }).catch(error => {
            toast.error(errorCodeDic['003']);
            console.log('error: ', error);
        })
            .finally(() => {
                spinner.stop();
            });
    }
    function fn_select(idx: number){
        setActiveButtonIdx(idx)
        var typeCode = '';
        var color = '';
        var ref = null
        if(idx === 0){
            typeCode = 'T002001'
            color = '#0075FF'
            ref = button01Ref
        }else if(idx === 1){
            typeCode = 'T002002'
            color = '#45BBFF'
            ref = button02Ref
        }else if(idx === 2){
            typeCode = 'T002003'
            color = '#FF9466'
            ref = button03Ref
        }else if(idx === 3){
            typeCode = 'T002004'
            color = '#9D4BEF'
            ref = button04Ref
        }else if(idx === 4){
            typeCode = 'T002005'
            color = '#78D62E'
            ref = button05Ref
        }else{
            typeCode = ''
            color = '#0DD7A6'
        }

        console.log('siteData: ', siteData);

        setSiteType(typeCode);
        var filter = []
        for(var i in siteData){
            var item: any = siteData[i]
            if(typeCode){
                if(typeCode === item.siteType){
                    filter.push(item)
                }
            }else{
                filter.push(item)
            }
        }
        console.log('filter: ', filter);

        setBubbleColor(color);
        setSiteDataFilter(filter)
    }

    const fn_openSiteInfoList = (idx: number) => {
        var typeCode = ''
        if(idx === 0){
            typeCode = 'T002001'
        }else if(idx === 1){
            typeCode = 'T002002'
        }else if(idx === 2){
            typeCode = 'T002003'
        }else if(idx === 3){
            typeCode = 'T002004'
        }else if(idx === 4){
            typeCode = 'T002005'
        }


        setSiteType(typeCode);
        var filter = []
        for(var i in siteDataAll){
            var item: any = siteDataAll[i]
            if(typeCode){
                if(typeCode === item.siteType){
                    filter.push(item)
                }
            }else{
                filter.push(item)
            }
        }

        if(item.siteType != 'T002003'){
            setSiteDataFilter(filter);
        }
        if(filter.length > 0){
            setDataList(filter);
            setModalIsOpen(true);
        }

    }
    return (
        <>
            <SmSiteWrkListModal setModalIsOpen={setIsSmWrkModalOpen} modalIsOpen={isSmWrkModalOpen} dataList={smWrkDataList} siteId={smSiteId} />
            <SiteInfoListModal setModalIsOpen={setModalIsOpen} modalIsOpen={modalIsOpen} dataList={dataList} siteType={siteType}></SiteInfoListModal>

                <div className="rounded-3 border-1 w-100 overflow-hidden position-relative">
                    {!props.siteType &&
                    <div className="d-flex position-absolute z-[1000]" style={{left: "10px", top:"10px"}}>
                        <div className="d-flex flex-column gap-2">
                            <div className="in-map-label-v2 text-nowrap p-2 gap-2" onClick={() => fn_select(-1)}>
                                <span>전체</span>&nbsp;<span className={'cursor-pointer small'} onClick={() => fn_openSiteInfoList(-1)}>({total ? total : '-'}개 현장)</span>
                            </div>
                            <div className="in-map-label-v2 d-flex flex-row text-nowrap p-2 gap-2 align-items-center" style={{borderWidth:"4px", borderColor: activeButtonIdx == 0 ? '#00ccff' : '#ffffff'}}>
                                <IconMapLabelConstruct width={30} height={30} onClick={() => fn_select(0)}/>
                                <span className="w-[60px]" onClick={() => fn_select(0)}>일반공사</span>
                                <span>|</span>
                                <span className={'cursor-pointer'} onClick={() => fn_openSiteInfoList(0)}>{countT002001}</span>
                            </div>
                            <div className="in-map-label-v2 d-flex flex-row text-nowrap p-2 gap-2 align-items-center" style={{borderWidth:"4px", borderColor: activeButtonIdx == 1 ? '#00ccff' : '#ffffff'}}>
                                <IconMapLabelRepair width={30} height={30} onClick={() => fn_select(1)}/>
                                <span className="w-[60px]" onClick={() => fn_select(1)}>유지보수</span>
                                <span>|</span>
                                <span className={'cursor-pointer'}  onClick={() => setIsSmWrkModalOpen(true)}>{countT002002}</span>
                            </div>
                            <div className="in-map-label-v2 d-flex flex-row text-nowrap p-2 gap-2 align-items-center" style={{borderWidth:"4px", borderColor: activeButtonIdx == 2 ? '#00ccff' : '#ffffff'}}>
                                <IconMapLabelLand width={30} height={30} onClick={() => fn_select(2)}/>
                                <span className="w-[60px]" onClick={() => fn_select(2)}>출자토지</span>
                                <span>|</span>
                                <span className={'cursor-pointer'} onClick={() => fn_openSiteInfoList(2)}>{countT002003}</span>
                            </div>
                            <div className="in-map-label-v2 d-flex flex-row text-nowrap p-2 gap-2 align-items-center" style={{borderWidth:"4px", borderColor: activeButtonIdx == 3 ? '#00ccff' : '#ffffff'}}>
                                <IconMapLabelFacility width={30} height={30} onClick={() => fn_select(3)}/>
                                <span className="w-[60px]" onClick={() => fn_select(3)}>관리시설</span>
                                <span>|</span>
                                <span className={'cursor-pointer'} onClick={() => fn_openSiteInfoList(3)}>{countT002004}</span>
                            </div>
                            <div className="in-map-label-v2 d-flex flex-row text-nowrap p-2 gap-2 align-items-center" style={{borderWidth:"4px", borderColor: activeButtonIdx == 4 ? '#00ccff' : '#ffffff'}}>
                                <IconMapLabelEtc width={30} height={30} onClick={() => fn_select(4)}/>
                                <span className="w-[60px]" onClick={() => fn_select(4)}>기타</span>
                                <span>|</span>
                                <span className={'cursor-pointer'} onClick={() => fn_openSiteInfoList(4)}>{countT002005}</span>
                            </div>
                        </div>
                    </div>
                    }
                    <div id={"map"} ref={mapRef} style={{width: '100%', height: props.height ? props.height : 'calc(100vh - 227px)', backgroundColor:'rgb(14, 24, 65)'}}></div>
                    <div id="bmc-ol-modal-layer" ref={overlayRef}>
                        <div className="layer-wrap" style={{zIndex:"999999"}}></div>
                    </div>
                </div>


        </>
    );
}
