import SubMenuTitle from "../component/SubMenuTitle";
import SafetyManagementBoardModal from "../safetyManagementBoard/modal/SafetyManagementBoardModal";
import SHSearch from "../component/BMCSearch";
import {Button, Container, ListGroup, Overlay, Table} from "react-bootstrap";
import BMCDatePicker2 from "../../components/common/BMCDatePicker2";
import BMCIconButton from "../../components/common/BMCIconButton";
import Datatables, {getCheckedItems} from "../component/Datatables";
import React, {useEffect, useRef, useState} from "react";
import {DateHelper} from "../../helper/DateHelper";
import axios from "axios";
import BMCSelect from "../../components/common/BMCSelect";
import {COMMON_MULTISEL_STRINGS} from "../../helper/StyleHelper";
import {MultiSelect} from "react-multi-select-component";
import SafetyNotificationManagementModal from "./modal/SafetyNotificationManagementModal";
import {toast} from "react-toastify";
import messageCodeDic from "../../util/messageCodeDic";
import errorCodeDic from "../../util/errorCodeDic";
import BMCCheckbox from "../../components/common/BMCCheckbox";
import restapi from "../../helper/restAPIHelper";
import BMCInput from "../../components/common/BMCInput";
import {getLoginUser} from "../../helper/SecurityHelper";


type Props = {};

const SafetyNotificationManagementPage = (props: Props) =>{

    const [data, setData] = useState<any>();
    const user = getLoginUser();
    const searchFormRef = useRef<any>();
    const [datatable, setDatatable] = useState<any>();
    const prodRef = useRef<any>();
    const [startDate, setStartDate] = useState<Date>(DateHelper.dateByMonth(-3));
    const [endDate, setEndDate] = useState<Date>(DateHelper.dateByMonth(0));
    const srchTxtRef = useRef<any>();
    const [modalIsOpen, setModalIsOpen] = React.useState(false);
    const [detailData, setDetailData] = useState(null);

    useEffect(() => {
        datatable?.draw();
    }, [modalIsOpen])

    const fn_setSearchData = (e: any) => {
        if (e.target.type == 'checkbox') {
            setData((item: any) => {
                return {...item, [e.target.name]: e.target.checked}
            });
        }else{
            setData((item: any) => {
                return {...item, [e.target.name]: e.target.value}
            });
        }
    }

    const fn_registPopOpen = () => {
        setDetailData(null);
        setModalIsOpen(true);
    }

    const fn_delete = () => {
        let checkedData = getCheckedItems(datatable, "sftyNtfctnMngId")

        if (checkedData.length == 0) {
            toast.warn(messageCodeDic['005']);
            return;
        }

        if (!window.confirm(messageCodeDic['004'])) {
            return;
        }

        axios.post('/admin/api/sftyNtfctnMng/deleteSftyNtfctnMngData', {delList: checkedData}).then(rsltData => {
                if (rsltData.data > 0) {
                    toast.success(messageCodeDic['002'])
                    datatable?.draw();
                } else {
                    toast.error(errorCodeDic['003'])
                }

            }
        ).catch(() => {
                toast.error(errorCodeDic['003'])
            }
        )
    }

    const fn_updateViewCnt = (param: object) => {
        axios.post('/admin/api/sftyNtfctnMng/updateViewCnt', param)
    }
    return (
        <Container fluid className="bg-white px-0 bmc-txt-color-primary">
            <SubMenuTitle title={'안전 공지 관리'} parentMenu={'안전 공지 관리'}/>
            <SafetyNotificationManagementModal setModalIsOpen={setModalIsOpen} modalIsOpen={modalIsOpen} detailData={detailData} />

            <div className="p-4 overflow-y-auto" style={{height: 'calc(100vh - 197px)'}}>
                <SHSearch datatable={datatable} formRef={searchFormRef} btnType={'p'}>
                    <Table className="bmc-font-SP bmc-fs-b2 my-3">
                        <tbody>
                        <tr className="border-top border-bottom align-middle">
                            <th className="bmc-bg-th" style={{width:'15%'}}>수신대상</th>
                            <td colSpan={3} style={{width:'85%'}}>
                                <div className="d-flex flex-row gap-2">
                                    <div className="d-flex align-items-center w-[310px]">
                                        <BMCSelect initOptionGb={'all'} className={'w-100'} name={'receiveTypeCd'} codeGrpId={'S006'}
                                                   onChange={fn_setSearchData}
                                                   value={data?.receiveTypeCd}></BMCSelect>
                                    </div>
                                    <div className="d-flex align-items-center flex-grow-1">
                                        {
                                            data?.receiveTypeCd == 'S006002' || data?.receiveTypeCd == 'S006003'?
                                                <input type={'text'} name={'receiveLabel'} className='form-control'/>
                                                :''
                                        }

                                    </div>
                                </div>
                            </td>
                        </tr>
                        <tr className="border-top border-bottom align-middle">
                            <th className="bmc-bg-th" style={{width:'15%'}}>기간</th>
                            <td style={{width:'35%'}}>
                                <div className="d-flex flex-row gap-2">
                                    <div className="d-flex align-items-center" ref={prodRef}>
                                        <BMCDatePicker2 name={'startRgstDt'} defaultValue={startDate} onChange={fn_setSearchData} />
                                        <span>&nbsp;~&nbsp;</span>
                                        <BMCDatePicker2 name={'endRgstDt'} defaultValue={endDate} onChange={fn_setSearchData} />
                                    </div>
                                    <div className="d-flex align-items-center gap-1" ref={prodRef}>
                                        <BMCCheckbox name={'isAll'} onChange={fn_setSearchData}
                                                     checked={data?.isAll} className={'ml-5 w-[110px]'}>전체기간</BMCCheckbox>
                                    </div>
                                </div>
                            </td>
                            <th className="bmc-bg-th" style={{width:'15%'}}>검색어</th>
                            <td style={{width:'35%'}}>
                                <input type={'text'} name={'srchTxt'} className='form-control' ref={srchTxtRef}/>
                            </td>
                        </tr>
                        </tbody>
                    </Table>
                </SHSearch>
                {user.authCode != 'headRead' &&
                    <div className='w-100 d-flex justify-content-end align-items-center mb-2'>

                        <BMCIconButton kind={'submit'} marginRight={'5px'} onClick={fn_registPopOpen}>등록</BMCIconButton>

                        <BMCIconButton kind={'delete'} marginRight={'0'} onClick={fn_delete}>삭제</BMCIconButton>
                    </div>
                }

                <div className={'ge-result'}>
                    <Datatables
                        dataUrl={'/admin/api/sftyNtfctnMng/retrieveSftyNtfctnMngDataList'}
                        parameterFormRef={searchFormRef}
                        columns={[
                            {data: "receiveTypeNm", title: "수신자 구분", textAlign: 'center'},
                            {data: "mainLabel", title: "수신자", textAlign: 'center', render: (data: any, type: any, row: any) => {
                                    if(row.labelCnt == null){
                                        return '전체'
                                    }else{
                                        return row.mainLabel +" 등 " + row.labelCnt + "곳";
                                    }

                                }},
                            {data: "title", title: "제목", textAlign: 'center'},
                            {data: "rgstrNm", title: "작성자", textAlign: 'center'},
                            {data: "rgstDt", title: "작성일시", textAlign: 'center'},
                            {data: "viewCount", title: "조회수", textAlign: 'center'}
                        ]}
                        fixedTop={"isFixedTop"}
                        checked={true}
                        onLoad={(dt: any) => {
                            setDatatable(dt)
                        }}
                        onClick={(data) => {
                            setDetailData(data)
                            setModalIsOpen(true);
                            fn_updateViewCnt(data);
                        }}
                    />
                </div>
            </div>
        </Container>
    )
}

export default SafetyNotificationManagementPage;
