import React, {useContext, useEffect, useState} from 'react';
import {Col, Container, Row} from "react-bootstrap";
import {ReactComponent as IconTeam} from "../../assets/images/ico/icon-team.svg";
import restapi from "../../helper/restAPIHelper";
import {CmmnFn} from "../../helper/CmmnHelper";
import {UserContext} from "../../context/User";
import {getLoginUser} from "../../helper/SecurityHelper";
import {ReactComponent as IconRight} from "../../assets/images/ico/icon-right.svg";
import {useNavigate} from "react-router-dom";
import WrkrInfoListModal from "../home/modal/WrkrInfoListModal";
import {IntervalContext} from "../../context/Interval";

const SSDashInOut = () => {
    const navigate = useNavigate();
    const {user} = useContext<any>(UserContext);
    const defaultUser = getLoginUser();
    const [dataList, setDataList] = useState<any>([]);
    const [modalIsOpen, setModalIsOpen] = React.useState(false);
    const {interval} = useContext<any>(IntervalContext);

    useEffect(() => {
        const siteId = user?.blngSiteId || defaultUser.blngSiteId;
        restapi.retrievePrmtWrkrDataList({
            siteId: siteId, wrkDt: CmmnFn.fn_getCurrentDate(), tbmYn: 'Y'
        }).then((rsltData: any) => {
            console.log('sm wrkr rsltData: ', rsltData);
            setDataList(rsltData);
        })



    }, [user, interval])

    return(
      <>
          <WrkrInfoListModal setModalIsOpen={setModalIsOpen} modalIsOpen={modalIsOpen}  wrkrDataList={dataList} siteId={user?.blngSiteId || defaultUser.blngSiteId} title={'총 출역'} tabGb={'smSite'} ></WrkrInfoListModal>
          <Container fluid className="bmc-site-bg-pane">
              <Row className={"mb-2"}>
                  <Col>
                      <div className="d-flex flex-column justify-content-between">
                          <div className="w-100 d-flex align-items-center justify-content-between pb-1 border-bottom-v2">
                              <span className="bmc-site-area-title">총 출역 인원</span>
                          </div>
                      </div>
                  </Col>
              </Row>
              <Row className="mb-2">
                  <Col>
                      <div className="d-flex flex-column justify-content-between p-1 h-[160px] cursor-pointer" style={{backgroundColor: '#ECF8FF', borderRadius: 16}} onClick={() => setModalIsOpen(true)} >
                          <div className="bmc-txt-color-primary w-100 p-2">
                              <div className="d-flex flex-column">
                                  <span className="bmc-txt-size-h2">{dataList.length}</span>
                                  <span className="bmc-txt-size-b5">전체</span>
                              </div>
                          </div>
                          <div className="d-flex justify-content-end align-items-center m-2">
                              <IconTeam width={35} height={35} style={{fill: '#35C1FF'}}/>
                          </div>
                      </div>
                  </Col>
              </Row>
          </Container>
      </>
    );
}

export default SSDashInOut;
