import {Container, Modal, Table} from "react-bootstrap";
import {IoMdClose} from "react-icons/io";
import iconCalendar from "../../../images/icon-calendar-black.svg";
import React, {Dispatch, SetStateAction, useContext, useEffect, useRef, useState} from "react";
import {Col, Row} from "react-bootstrap";
import axios from "axios";
import {toast} from "react-toastify";
import {ChangeMeta, NumberFormatBase} from "react-number-format";
import {fn_getCurrentDate} from "../../helper/CmmnHelper";
import {getLoginUser} from "../../helper/SecurityHelper";
import {UserContext} from "../../context/User";
import {ProgressContext} from "../../context/Progress";
import messageCodeDic from "../../util/messageCodeDic";
import errorCodeDic from "../../util/errorCodeDic";
import SubMenuTitle from "../component/SubMenuTitle";
import BMCIconButton from "../../components/common/BMCIconButton";
import BMCSelect from "../../components/common/BMCSelect";
import BMCInput from "../../components/common/BMCInput";
import {Formatter} from "../../helper/FormatHelper";
import SiteCardList from "../../components/site/SiteCardList";
import restapi from "../../helper/restAPIHelper";

interface IProps {
}

const CompanyManagementPage = (props: IProps) => {

    const currentDate = fn_getCurrentDate();
    const user = getLoginUser();
    const [isSitePopOpend, setIsSitePopOpend] = React.useState<boolean>(false);

    const [data, setData] = useState<any>()
    const [blngSiteList, setBlngSiteList] = useState<object[]>([])
    const [isDupleChked, setIsDupleChked] = useState<boolean>(false);
    const {spinner} = useContext(ProgressContext);

    useEffect(() => {

        restapi.retrieveCmpnyDataTotList({companySeq: user.blngCmpnySeq}).then((rsltData: any) => {
            const dtlData = rsltData[0];

            setData(dtlData);
            setIsDupleChked(true);
            fn_retrieveCmpnyBlngSiteList(dtlData?.companySeq).then((result: any) => {
                setBlngSiteList(result);
            })
        })

    }, [])


    const fn_setFormData = (e: any) => {

        if (e.target.name == 'cnstrPrice') {
            e.target.value = parseInt(e.target.value.replaceAll(',', '')).toLocaleString()
        }
        setData({
            ...data,
            [e.target.name]: e.target.value
        })

    }

    function fn_retrieveCmpnyBlngSiteList(companySeq: string) {
        return new Promise((resolve, reject) => {
            axios.post('/admin/api/company/retrieveCmpnyBlngSiteList', {companySeq: companySeq}).then((result) => {
                resolve(result.data);
            })
        })

    }


    const submit = (e: any) => {
        e.preventDefault()

    }

    const fn_chkDupl = () => {

        if (!data) {
            toast.warn('사업자 등록번호를 입력해 주십시오.');
            return;
        }

        if (data.companyRegNum) {
            const companyRegNum = data.companyRegNum.replaceAll('-', '');
            if (companyRegNum.length < 10) {
                toast.warn('사업자 등록번호가 형식에 어긋납니다.')
                return;
            }

            axios.post('/admin/api/company/retrieveCmpnyRegNumCnt', {companyRegNum: companyRegNum}).then((result) => {
                if (result.data > 0) {
                    toast.warn('이미 존재하는 사업자 등록번호 입니다.')

                } else {
                    toast.success('사용 가능한 사업자 등록번호 입니다.')
                    setIsDupleChked(true);
                }
            })
        }

    }

    const fn_openAddSitePop = () => {
        setIsSitePopOpend(true);
    }


    const fn_registData = () => {

        if (!data) {
            toast.warn('데이터를 입력해 주십시오.')
            return;
        }

        if (!data.companyGbCd) {
            toast.warn('업체구분을 선택해 주십시오.')
            return;
        }

        if (data.companyRegNum && !isDupleChked) {
            toast.warn('사업자등록번호 중복확인을 해주십시오.');
            return;
        }

        if (!data.representativeName) {
            toast.warn('대표자를 입력해 주십시오.');
            return;
        }

        if (!data.sectorCd) {
            toast.warn('대표공종을 입력해 주십시오.')
            return;
        }

        /*if (blngSiteList.length == 0) {
            toast.warn('소속현장을 입력해 주십시오.')
            return;
        }*/

        console.log('data: ', data);

        let param = data;
        param.regSeq = user.userSeq;
        param.updateSeq = user.userSeq;
        if (param.companyRegNum) {
            param.companyRegNum = param.companyRegNum.replaceAll('-', '');
        }
        let blngSiteIdArray: number[] = []

        blngSiteList.map((data: any, idx) => {
            blngSiteIdArray.push(data.siteId)
        })

        param.blngSiteIdArray = blngSiteIdArray;
        console.log('param: ', param);

        if (!window.confirm('저장 하시겠습니까?')) {
            return;
        }

        axios.post('/admin/api/company/saveData', param).then((result) => {
                if (result.data > 0) {
                    toast.success(messageCodeDic['001'])
                } else {
                    toast.error(errorCodeDic['003']);
                }
            }
        )


    }


    return (
        <Container fluid className="bg-white px-0 bmc-txt-color-primary">
            <SubMenuTitle title={'소속 업체 관리'} parentMenu={'My Page'}/>

            <div className="p-4 overflow-y-auto" style={{height: 'calc(100vh - 180px)'}}>
                <div className='w-100 d-flex justify-content-end align-items-center mb-2'>
                    <BMCIconButton kind={'submit'} onClick={fn_registData}>저장</BMCIconButton>
                </div>

                <form name={'form'}
                      onSubmit={submit}>
                    <div className="bmc-modal-div">
                        <div className="div-header">
                            업체정보
                        </div>
                        <div className="div-body px-3">
                            <Table className="my-2 align-middle">
                                <tbody>
                                <tr className="border-top align-middle">
                                    <th className="bmc-bg-th" style={{width: '15%'}}>업체구분</th>
                                    <td style={{width: '35%'}}>
                                        <BMCSelect initOptionGb={'sel'} name={'companyGbCd'} className={"w-100"}
                                                   codeGrpId={'C001'} codes={['C001002', 'C001003', 'C001004', 'C001005']}
                                                   onChange={fn_setFormData} value={data?.companyGbCd}></BMCSelect>
                                    </td>
                                    <th className="bmc-bg-th" style={{width: '15%'}}>업체명</th>
                                    <td style={{width: '35%'}}>
                                        <BMCInput type="text" className="form-control" name={'companyName'}
                                                  onChange={fn_setFormData} value={data?.companyName}/>
                                    </td>
                                </tr>
                                <tr className="border-top align-middle">
                                    <th className="bmc-bg-th" style={{width: '15%'}}>사업자등록번호</th>
                                    <td style={{width: '35%'}}>
                                        <div className="d-flex align-items-center w-100">
                                            {
                                                //@ts-ignore
                                                <NumberFormatBase onChange={fn_setFormData} name={'companyRegNum'}
                                                                  disabled={isDupleChked ? true : false}
                                                                  className={'form-control w-75'}
                                                                  value={data?.companyRegNum || ''}
                                                                  format={Formatter.regNumFormat}
                                                />
                                            }
                                            <button className="bmc-btn btn-secondary min-w-[70px] ml-5" type="button"
                                                    onClick={fn_chkDupl}>중복확인
                                            </button>
                                        </div>
                                    </td>
                                    <th className="bmc-bg-th" style={{width: '15%'}}>대표자</th>
                                    <td style={{width: '35%'}}>
                                        <BMCInput type="text" name={'representativeName'} value={data?.representativeName}
                                                  onChange={fn_setFormData} className="form-control"
                                        />
                                    </td>
                                </tr>
                                <tr className="border-top align-middle">
                                    <th className="bmc-bg-th" style={{width: '15%'}}>대표공종</th>
                                    <td style={{width: '35%'}}>
                                        <BMCSelect initOptionGb={'sel'} className={"w-100"} name={'sectorCd'}
                                                   codeGrpId={'C002'}
                                                   onChange={fn_setFormData} value={data?.sectorCd}></BMCSelect>
                                    </td>
                                    <th className="bmc-bg-th" style={{width: '15%'}}>연락처</th>
                                    <td style={{width: '35%'}}>
                                        {
                                            //@ts-ignore
                                            <NumberFormatBase onChange={fn_setFormData} name={'contactPhoneNo'}
                                                              className={'form-control'} value={data?.contactPhoneNo || ''}
                                                              format={Formatter.phoneNumFormat}
                                            />
                                        }
                                    </td>
                                </tr>
                                </tbody>
                            </Table>
                        </div>
                    </div>

                    <div className="bmc-modal-div">
                        <div className="div-header">
                            <div className='flex justify-content-between w-100'>
                                <span>소속 현장 리스트</span>
                            </div>

                        </div>
                        <div className="div-body px-3">
                            <div className={'row'}>
                                <div className="col-md-12">
                                    <SiteCardList items={blngSiteList} setItems={setBlngSiteList} isReadOnly={true}/>
                                </div>

                            </div>
                        </div>
                    </div>


                    <div className="bmc-modal-div">
                        <div className="div-header">
                            작성정보
                        </div>
                        <div className="div-body px-3">
                            <Table className="my-2 align-middle">
                                <tbody>
                                <tr className="border-top align-middle">
                                    <th className="bmc-bg-th" style={{width: '15%'}}>작성일</th>
                                    <td style={{width: '35%'}}>
                                        <BMCInput value={currentDate} name={'rgstDt'} onChange={fn_setFormData}
                                                  className={"form-control"}
                                                  disabled={true}/>
                                    </td>
                                    <th className="bmc-bg-th" style={{width: '15%'}}>작성자</th>
                                    <td style={{width: '35%'}}>
                                        <BMCInput className={"form-control"} name={'rgstrNm'}
                                                  value={data?.rgstrNm}
                                                  onChange={fn_setFormData}
                                                  disabled={true}/>
                                    </td>
                                </tr>
                                </tbody>
                            </Table>
                        </div>
                    </div>


                </form>
            </div>
        </Container>


    )
}

export default CompanyManagementPage;

