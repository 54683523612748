import React, {useContext, useEffect, useRef, useState} from 'react';
import axios from "axios";
import Modal from 'react-modal';
import {getLoginUser} from "../../helper/SecurityHelper";
import {toast} from "react-toastify";
import errorCodeDic from "../../util/errorCodeDic";
import {ProgressContext} from "../../context/Progress";
import restapi from "../../helper/restAPIHelper";

import {Col, Container, Row, Tab, Table, Tabs} from 'react-bootstrap';
import SubMenuTitle from "../component/SubMenuTitle";

import {CmmnFn} from "../../helper/CmmnHelper";

import WrkrInfoListModal from "../home/modal/WrkrInfoListModal";
import DailySftyRprtByDatePage from "./subPage/DailySftyRprtByDatePage";
import {DateHelper} from "../../helper/DateHelper";
import DailySftyRprtByDprtPage from "./subPage/DailySftyRprtByDprtPage";
import DailySftyRprtChkListModal from "./modal/DailySftyRprtChkListModal";

type Props = {};

Modal.setAppElement('#root');
const DailySftyRprtHeadPage = (props: Props) => {

    const user = getLoginUser();
    const {spinner} = useContext(ProgressContext);
    const [dataList, setDataList] = useState<any>([]);
    const [srchData, setSrchData] = useState<any>({chkDt: CmmnFn.fn_getCurrentDate(), strtDt:CmmnFn.getAmonthAgoDate(), endDt:CmmnFn.fn_getCurrentDate(), tabGb: 'dailyList'});
    const [statData, setStatData] = useState<any>({totSiteCnt: 0, noSiteCnt: 0, totWrkrCnt: 0, noWrkrCnt: 0});
    const [modalIsOpen, setModalIsOpen] = useState<boolean>(false);
    const [wrkrDataList, setWrkrDataList] = useState<any>([]);
    const [selData, setSelData] = useState<any>(null);

    const [isDatePopupOpen, setIsDatePopupOpen] = useState<boolean>(false);
    const prodRef = useRef<any>();

    useEffect(() => {
        fn_search();
    }, [srchData])

    useEffect(() => {
        if(selData){
            setModalIsOpen(true);
        }

    }, [selData]);

    const fn_search = () => {

        spinner.start();
        restapi.retrieveDailySftyRprtStatDataList(srchData).then((rsltData: any) => {
            console.log('rsltData: ', rsltData);
            setDataList(rsltData)
        }).catch(error => {
            toast.error(errorCodeDic['003']);
            console.log('error: ', error);
        })
        .finally(() => {
            spinner.stop();
        });
    }

    return (
        <Container fluid className="bg-white px-0 pb-4 bmc-text-font_primary">
            <DailySftyRprtChkListModal setModalIsOpen={setModalIsOpen} modalIsOpen={modalIsOpen} tabGb={srchData.tabGb} chkDt={selData?.chkDt} dprtCd={selData?.dprtCd} selData={selData} />
            <SubMenuTitle title={'일일안전일보'} parentMenu={'안전점검 관리'}/>
            <div className="p-4 overflow-y-auto" style={{height: 'calc(100vh - 180px)'}}>
            <Tabs
                transition={true}
                activeKey={srchData.tabGb}
                onSelect={(k) => setSrchData({
                    ...srchData,
                    'tabGb': k
                })}
                id="tabGb"
                className="mb-3 mt-2"
            >
            <Tab eventKey="dailyList" title="일자별 목록">
                <DailySftyRprtByDatePage dataList={dataList} srchData={srchData} setSrchData={setSrchData} setSelData={setSelData} isDatePopupOpen={isDatePopupOpen} setIsDatePopupOpen={setIsDatePopupOpen} onSearch={fn_search}/>
            </Tab>
            <Tab eventKey="mngDprtList" title="관리부서별 목록">
                <DailySftyRprtByDprtPage dataList={dataList} srchData={srchData} setSrchData={setSrchData} setSelData={setSelData} isDatePopupOpen={isDatePopupOpen} setIsDatePopupOpen={setIsDatePopupOpen} onSearch={fn_search} />
            </Tab>

            </Tabs>
            </div>
        </Container>


    );



};

export default DailySftyRprtHeadPage;
