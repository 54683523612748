import {IoMdClose} from "react-icons/io";
import React, {Dispatch, SetStateAction, useContext, useEffect, useRef, useState} from "react";

import {Col, Row, Table, Modal} from "react-bootstrap";

import axios from "axios";
import {toast} from "react-toastify";


import {MultiSelect} from "react-multi-select-component";

import IconRemove from '../../../images/icon_remove.png';

import IconView from "../../../images/icon_view.png";
import IconNotView from "../../../images/icon_notview.png";

import {useReactToPrint} from "react-to-print";

import {NumberFormatBase} from "react-number-format";
import {getLoginUser} from "../../helper/SecurityHelper";
import {ProgressContext} from "../../context/Progress";
import {CmmnFn} from "../../helper/CmmnHelper";
import BMCInput from "../../components/common/BMCInput";
import {Formatter} from "../../helper/FormatHelper";
import restapi from "../../helper/restAPIHelper";
import errorCodeDic from "../../util/errorCodeDic";

const $ = require("jquery")

interface IProps {
    setModalIsOpen: Dispatch<SetStateAction<boolean>>;
    modalIsOpen: boolean;


}

const FindIdModal = (props: IProps) => {

    const user = getLoginUser();
    const [data, setData] = useState<any>()
    const formRef = React.createRef<HTMLFormElement>();
    const {spinner} = useContext(ProgressContext);
    const [userId, setUserId] = useState<any>(null);

    useEffect(() => {

    }, [])


    const fn_setFormData = (e: any) => {

        if (e.target.type == 'checkbox') {
            setData({
                ...data,
                [e.target.name]: e.target.checked
            })
        } else {
            setData({
                ...data,
                [e.target.name]: e.target.value
            })
        }

    }

    const fn_onAfterOpen = () => {
        setData({})
        setUserId(null);
    }

    function fn_closeModal() {
        props.setModalIsOpen(false);
    }

    const submit = (e: any) => {
        e.preventDefault()

    }

    const fn_findId = () => {
        if (!data) {
            toast.warn('데이터를 입력해 주십시오.')
            return;
        }

        if(!data.userName){
            toast.warn('이름을 입력해 주십시오.');
            return;
        }

        if(!data.userPhone){
            toast.warn('전화번호를 입력해 주십시오.');
            return;
        }

        data.userPhone = data.userPhone.replaceAll('-', '');
        console.log('data: ', data);
        restapi.findUserId(data).then((rsltData) => {
            if(rsltData){
                setUserId(rsltData);
            }else{
                setUserId('');
            }
            console.log('rsltData: ', rsltData);
        }).catch(error => {
            toast.error(errorCodeDic['003']);
            console.log('error: ', error);
        })
            .finally(() => {
                spinner.stop();
            });
    }

    return (
        <div>
            <Modal className="bmc-modal" size="lg" show={props.modalIsOpen} onHide={fn_closeModal}
                   onShow={fn_onAfterOpen}>
                <Modal.Header>
                    <div className="d-flex justify-content-between align-items-center w-100 bmc-font-SD">
                        아이디 찾기
                        <IoMdClose className='hover:cursor-pointer' onClick={fn_closeModal}/>
                    </div>
                </Modal.Header>
                <Modal.Body className="px-4">

                    <form name={'rgstForm'} id={'rgstForm'} encType="multipart/form-data" method={"post"}
                          onSubmit={submit} ref={formRef}>

                        <div className="bmc-modal-div">
                            <div className="div-body px-3">
                                <Table className="my-2 align-middle">
                                    <tbody>
                                    <tr className="border-top align-middle">
                                        <th className="bmc-bg-th" style={{width: '15%'}}>이름</th>
                                        <td style={{width: '85%'}}>
                                            <BMCInput className={'form-control'} name={'userName'}
                                                      onChange={fn_setFormData} value={data?.userName}/>
                                        </td>

                                    </tr>
                                    <tr className="border-top align-middle">
                                        <th className="bmc-bg-th" style={{width: '15%'}}>전화번호</th>
                                        <td style={{width: '85%'}}>
                                            <div
                                                className="d-flex align-items-center w-100 justify-content-between">
                                                {
                                                    //@ts-ignore
                                                    <NumberFormatBase onChange={fn_setFormData} name={'userPhone'}
                                                                      className={'form-control'}
                                                                      value={data?.userPhone || ''}
                                                                      format={Formatter.phoneNumFormat}
                                                    />
                                                }
                                                <button className="bmc-btn btn-secondary min-w-[70px] ml-5 text-nowrap"
                                                        type="button" onClick={fn_findId}>찾기
                                                </button>
                                            </div>
                                        </td>

                                    </tr>
                                    </tbody>
                                </Table>
                                <div className={'p-2 text-[16px]'}>
                                    {userId != null && (
                                        userId === ''
                                            ? '등록된 아이디가 존재하지 않습니다.'
                                            : <span>{data.userName}님의 아이디는 <span className="font-bold text-[#3B459B]">{userId}</span>입니다</span>
                                    )}
                                </div>

                            </div>
                            <div className={'mt-3'}>
                                ※ 비밀번호 찾기/변경을 원하시는 분은 관리자에게 문의해 주세요.
                            </div>
                        </div>


                    </form>
                </Modal.Body>
                <Modal.Footer className="py-2">
                <div className='flex justify-center w-100'>
                        <button
                            className='bmc-btn pill l white me-3'
                            onClick={fn_closeModal}>닫기
                        </button>

                    </div>
                </Modal.Footer>
            </Modal>
        </div>
    )
}

export default FindIdModal;

