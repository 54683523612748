import React, {useContext, useEffect, useRef, useState} from 'react';

import SHSearch from '../component/BMCSearch';
import SubMenuTitle from "../component/SubMenuTitle";
import {Card, Col, Container, Row, Table} from "react-bootstrap";

import BMCSelect from "../../components/common/BMCSelect";
import axios from "axios";
import BMCInput from "../../components/common/BMCInput";
import CctvViewerModal from "../headoffice/modal/CctvViewerModal";
import {TopMenuContext} from "../../context/TopMenu";


type Props = {};

const CctvHeadManagePage = (props: Props) => {
    const {param} = useContext<any>(TopMenuContext);
    const [modalIsOpen, setModalIsOpen] = React.useState(false);
    const searchFormRef = useRef<any>();
    const [guCd, setGuCd] = useState<string>('');
    const [siteId, setSiteId] = useState<string>('');
    const [query, setQuery] = useState<string>('');
    const [result, setResult] = useState<any>([]);
    const [select, setSelect] = useState<any>({});
    useEffect(() => {
        if(param && param.siteId){
            setSiteId(param.siteId)
            fn_search(guCd, param.siteId,query)
        }else{
            fn_search(guCd, siteId,query)
        }
    }, [])
    function openModal(data: any){
        setSelect(data)
        setModalIsOpen(true)
    }
    function fn_search(guCd: any, siteId: any, query: any){
        axios.post('/admin/api/cctv/retrieveCctvList', {guCd:guCd, siteId:siteId, query: query})
            .then(r => {
                if(r.status === 200){
                    setResult(r.data)
                }
            })
    }
    return (
        <Container fluid className="bg-white px-0 bmc-txt-color-primary">
            <SubMenuTitle title={'CCTV 관리'} parentMenu={'CCTV 관리'} />
            <CctvViewerModal setModalIsOpen={setModalIsOpen} modalIsOpen={modalIsOpen} detailData={select}/>

            <div className="p-4 overflow-y-auto" style={{height: 'calc(100vh - 197px)'}}>
                <SHSearch formRef={searchFormRef} btnType={'p'} onSearch={() => fn_search(guCd, siteId,query)}>
                    <Row>
                        <Col>
                            <Table className="bmc-font-SP bmc-fs-b2 my-3">
                                <colgroup>
                                    <col width="10%" />
                                    <col width="20%" />
                                    <col width="10%" />
                                    <col width="20%" />
                                    <col width="10%" />
                                    <col/>
                                </colgroup>
                                <tbody>
                                <tr className="border-top border-bottom align-middle">
                                    <th className="bmc-bg-th">지역</th>
                                    <td>
                                        <BMCSelect initOptionGb={'all'} className={'form-select'} name={'guCd'} codeGb={'bjd'} codeGrpId={'BUSAN'} value={guCd} onChange={e=> setGuCd(e.target.value)} selParam={{isCctv:true}}></BMCSelect>
                                    </td>
                                    <th className="bmc-bg-th">현장</th>
                                    <td>
                                        <BMCSelect initOptionGb={'all'} className={'form-control'} name={'siteId'} codeGb={'site'} value={siteId} onChange={e=> setSiteId(e.target.value)} selParam={{guCd:guCd, isCctv:true}} ></BMCSelect>
                                    </td>
                                    <th className="bmc-bg-th">검색어</th>
                                    <td>
                                        <BMCInput type="text" className="form-control" name={'query'} value={query} onChange={e=> setQuery(e.target.value)}/>
                                    </td>
                                </tr>
                                </tbody>
                            </Table>
                        </Col>
                    </Row>
                </SHSearch>
                <hr/>
                <div className={'ge-result mt-3'}>
                    <Container fluid={true} className={"px-0"}>
                        <Row>
                            {result.length === 0 ?
                                <Col className={"text-center fs-3 mt-5"}>등록된 CCTV가 존재하지 않습니다.</Col>
                                :
                                result.map((data: any, idx: number) => (
                                    <Col key={idx} className={"col-sm-6 col-md-4 col-lg-3 mb-4"} onClick={() => openModal(data)}>
                                        <Card>
                                            <img src={process.env.REACT_APP_CCTV_URL + "/cctv-image?url=" + encodeURIComponent(data.rtspUrl)} className="card-img-top"/>
                                            <div className="card-body">
                                                <h5 className="card-title">{data.siteName}</h5>
                                                <p className="card-text mb-2">{data.name} / {data.location}</p>
                                                <div className={"text-right"}>
                                                    <a href="#" className="btn btn-sm btn-secondary">확인</a>
                                                </div>
                                            </div>
                                        </Card>
                                    </Col>))
                            }
                        </Row>
                    </Container>
                </div>
            </div>
    </Container>
    );
};

export default CctvHeadManagePage;
