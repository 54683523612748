import {RouteType} from "./config";
import NoticeManagementPageLayout from "../pages/noticeManagement/NoticeManagementPageLayout";
import MyInformationManagementPage from "../pages/myPage/MyInformationManagementPage";

/* SVG Icon */
import {ReactComponent as IconMonitoring} from '../assets/images/icon/icon-Union.svg';
import {ReactComponent as IconSiteConstruction} from '../assets/images/icon/icon-Browsers.svg';
import {ReactComponent as IconNotice} from '../assets/images/icon/icon-Microphone.svg';
import {ReactComponent as IconSiteSafety} from '../assets/images/icon/icon-corn.svg';
import {ReactComponent as IconFieldEquipment} from '../assets/images/icon/icon-truck.svg';
import {ReactComponent as IconSafetyWork} from '../assets/images/icon/icon-folders.svg';
import {ReactComponent as IconSiteManagement} from '../assets/images/icon/icon-setting.svg';
import SiteSftyChkPrvntPage from "../pages/safetyWorkReportManagement/SiteSftyChkPrvntPage";
import HeadMngrManagementPage from "../pages/manpowerManagement/HeadMngrManagementPage";
import React, {useEffect} from "react";
import {useNavigate} from "react-router-dom";
import HeadCompanyManagementPage from "../pages/headoffice/HeadCompanyManagementPage";
import HOTotalSafetyStatus from "../pages/headoffice/HOTotalSafetyStatus";
import HOTotalSafetyStatusV2 from "../pages/headoffice/HOTotalSafetyStatusV2";
import SiteManagePage from "../pages/headManage/SiteManagePage";
import SiteSftyChkBsnsPage from "../pages/safetyWorkReportManagement/SiteSftyChkBsnsPage";
import CctvHeadManagePage from "../pages/headManage/CctvHeadManagePage";
import SafetyNotificationManagementPage from "../pages/safetyNotificationManagement/SafetyNotificationManagementPage";
import SafetyManagementBoardPage from "../pages/safetyManagementBoard/SafetyManagementBoardPage";
import SafetyNewspaperPage from "../pages/safetyNewspaper/SafetyNewspaperPage";
import SiteWrkrMngPage from "../pages/safetyNewspaper/SiteWrkrMngPage";
import HeadSiteDocsPage from "../pages/safetyInformationBoard/HeadSiteDocsPage";
import SiteMngrManagementPage from "../pages/manpowerManagement/SiteMngrManagementPage";
import HeadManagementAdminPage from "../pages/headoffice/HeadManagementAdminPage";
import MntncSiteMngPage from "../pages/siteSftyMng/MntncSiteMngPage";
import IvstLandSitePage from "../pages/siteSftyMng/IvstLandSitePage";
import FcltySftyMngPage from "../pages/siteSftyMng/FcltySftyMngPage";
import HeadApprStatPage from "../pages/headoffice/HeadApprStatPage";
import DailySftyRprtHeadPage from "../pages/safetyWorkReportManagement/DailySftyRprtHeadPage";
import SvrAcdntPnshmntMngHeadPage from "../pages/safetyWorkReportManagement/SvrAcdntPnshmntMngHeadPage";
import CnstrctnSiteMngPage from "../pages/siteSftyMng/CnstrctnSiteMngPage";
import IvstMonitoring from "../pages/ivst/IvstMonitoringPage";
import FcltyMngDtlPage from "../pages/fclty/FcltyMngDtlPage";
import FcltyMonitoringPage from "../pages/fclty/FcltyMonitoringPage";
import IvstSftyChkPage from "../pages/ivst/IvstSftyChkPage";
import FcltySftyChkPage from "../pages/fclty/FcltySftyChkPage";
import SvrAcdntPnshmntMngPage from "../pages/siteSafetyManagement/SvrAcdntPnshmntMngPage";
import DailySftyRprtPage from "../pages/safetyWorkReportManagement/DailySftyRprtPage";
import FcltySvrAcdntPnshmntMngPage from "../pages/fclty/FcltySvrAcdntPnshmntMngPage";
import FcltyDailySftyRprtPage from "../pages/fclty/FcltyDailySftyRprtPage";


const ToPage = ({ link }: any) => {
    const navigate = useNavigate();
    useEffect(()=>{
        navigate(link)
    },[])
    return (
        <>...</>
    );
};
const appRoutesFclty: RouteType[] = [
    {
        index: true,
        element: <ToPage link={"/fclty/monitoring"} />,
        state: "home"
    },
    {
        path: "/fclty/monitoring",
        element: <FcltyMonitoringPage />,
        state: "fclty.monitoring",
        sidebarProps: {
            displayText: "시설물안전관리 현황",
            icon: <IconMonitoring />
        }
    },
    {
        path: "/fclty/totSiteStat",
        element: <FcltyMngDtlPage />,
        state: "fclty.totSiteStat",
        sidebarProps: {
            displayText: "시설물 관리 상세",
            icon: <IconSiteConstruction />
        },
    },
    {
        path: "/fclty/fcltySftyChk",
        element: <FcltySftyChkPage />,
        state: "fclty.fcltySftyChk",
        sidebarProps: {
            displayText: "안전점검 및 정밀안전 진단 관리",
            icon: <IconSiteConstruction />
        },
    },
    {
        path: "/fclty/svrAcdntPnshmntMng",
        element: <FcltySvrAcdntPnshmntMngPage />,
        state: "fclty.svrAcdntPnshmntMng",
        sidebarProps: {
            displayText: "중대재해처벌법관리",
            icon: <IconSiteConstruction />
        },
    },
    {
        path: "/fclty/dailySftyRprt",
        element: <FcltyDailySftyRprtPage />,
        state: "fclty.dailySftyRprt",
        sidebarProps: {
            displayText: "일일안전일보",
            icon: <IconSiteConstruction />
        },
    },
];

export default appRoutesFclty;
