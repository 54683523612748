import React, {useContext, useEffect, useState} from 'react';
import {Col, Container, Row} from "react-bootstrap";
import {ReactComponent as IconRight} from "../../assets/images/ico/icon-right.svg";
import {ReactComponent as IconNoData} from "../../assets/images/icon/icon-folder.svg";
import restapi from "../../helper/restAPIHelper";
import {UserContext} from "../../context/User";
import {getLoginUser} from "../../helper/SecurityHelper";
import CctvViewerModal from "../headoffice/modal/CctvViewerModal";
import {Autocomplete, TextField} from "@mui/material";
import BMCSelect from "../../components/common/BMCSelect";
import {ReactComponent as IconLogo} from '../../assets/images/icon/icon-bmc-logo.svg';

const HeadOfficeSiteCCTV = () => {
    const {user} = useContext<any>(UserContext);
    const defaultUser = getLoginUser();
    const [cctvDataList, setCctvDataList] = useState<any>([]);
    const [cctvData, setCctvData] = useState<any>({});
    const [modalIsOpen, setModalIsOpen] = useState<boolean>(false);
    const [guCd, setGuCd] = useState<string>('');
    const [siteId, setSiteId] = useState<string>();
    useEffect(() => {
        fn_retrieveCctvDataList(siteId);
    }, [siteId])

    const fn_retrieveCctvDataList = (siteId: any) => {
        restapi.retrieveCctvList({siteId: siteId}). then((rsltData: any) => {
            console.log('cctv rsltData: ', rsltData);
            setCctvDataList(rsltData);
            if(rsltData.length > 0){
                rsltData[0].idx = 0;
                setCctvData(rsltData[0]);
            }else{
                setCctvData([]);
            }
        })
    }

    const fn_nextCctv = (direction: string) => {
        let cctvIdx;

        if(direction == 'left'){
            if(cctvData.idx == 0){
                cctvIdx = cctvDataList.length - 1;
            }else{
                cctvIdx = cctvData.idx - 1;
            }
        }else if(direction == 'right'){
            if(cctvData.idx == cctvDataList.length -1){
                cctvIdx = 0;
            }else{
                cctvIdx = cctvData.idx + 1;
            }
        }
        cctvDataList[cctvIdx].idx = cctvIdx;
        let cstmCctvData = cctvDataList[cctvIdx];
        setCctvData(cstmCctvData);
    }

    return(
        <>
            <CctvViewerModal setModalIsOpen={setModalIsOpen} modalIsOpen={modalIsOpen} dataList={cctvDataList} detailData={cctvData}/>
            <Container fluid className="bmc-bg-pane">
                <Row>
                    <Col>
                        <div className="d-flex flex-column justify-content-between">
                            <div className="w-100 d-flex align-items-center justify-content-between pb-2 border-bottom">
                                <span className="bmc-txt-size-h4">현장 CCTV {siteId ? '' : '(전체현장)'}</span>
                            </div>
                        </div>
                    </Col>
                </Row>
                <Row className="px-0 pt-2">
                    <Col bsPrefix="col-4" className="pb-2">
                        <label htmlFor="form-label ho_sel_emb" className="bmc-txt-size-b6">지역</label>
                        <BMCSelect initOptionGb={'sel'} className={'form-select'} name={'guCd'} codeGb={'bjd'} codeGrpId={'BUSAN'} value={guCd} onChange={e=> setGuCd(e.target.value)}></BMCSelect>
                    </Col>
                    <Col bsPrefix="col-8">
                        <label htmlFor="form-label ho_sel_field" className="bmc-txt-size-b6">현장</label>
                        <BMCSelect  initOptionGb={'sel'} className={'form-control'} name={'siteId'} codeGb={'site'} value={siteId} onChange={e=> setSiteId(e.target.value)} selParam={{guCd:guCd, isSmSite: false}} ></BMCSelect>
                    </Col>
                </Row>
                <Row className="px-0 pt-2">
                    <Col>
                        <div style={{position:"relative"}} className="rounded-3 border-1">
                            {cctvDataList.length > 0 ?
                                <>
                                    <div className="position-relative cursor-pointer" onClick={() => setModalIsOpen(true)}>
                                        <img className="rounded-[5px] object-cover w-100" src={process.env.REACT_APP_CCTV_URL + "/cctv-image?url=" + encodeURIComponent(cctvData.rtspUrl)} alt="cctv" style={{height:"255px"}} />
                                    </div>
                                    <div className="rounded-br-[5px] rounded-bl-[5px] px-3 py-2 " style={{backgroundColor:"#00000088", position: "absolute", bottom:0, right:0, width: '100%', }}>
                                        <div className="d-flex justify-content-end align-items-center">
                                            <span className={"d-inline-block pe-2 cursor-pointer"} onClick={() => fn_nextCctv('left')} style={{borderRight:"solid 1px #36363A"}}><i className="bmc-arrow left"></i></span>
                                            <span className={"d-inline-block ps-2 cursor-pointer"} onClick={() => fn_nextCctv('right')} ><i className="bmc-arrow right"></i></span>
                                        </div>
                                    </div>
                                </>
                                :
                                (
                                    <div className="d-flex flex-column justify-content-center align-items-center">
                                        <div className="bmc-txt-color-gray-01 bmc-txt-size-b6 py-5"><IconLogo style={{width: '98px', height:'100px'}} /></div>
                                    </div>
                                )
                            }
                        </div>

                        <div className="text-end mt-2">
                            {
                                cctvData && (
                                    <>
                                        <span className="d-flex" style={{fontSize:"13px",fontWeight:"400"}}>{cctvData?.name}</span>
                                    </>

                                )
                            }
                        </div>
                    </Col>
                </Row>
            </Container>
        </>
    );
}

export default HeadOfficeSiteCCTV;
