import React, {Dispatch, SetStateAction, useEffect, useRef, useState} from "react";
import {Col, Modal, Row, Table} from "react-bootstrap";
import {IoMdClose} from "react-icons/io";
import Datatables, {getCheckedItems} from "../../component/Datatables";
import {toast} from "react-toastify";
import SHSearch from "../../component/BMCSearch";
import BMCInput from "../../../components/common/BMCInput";

interface IProps {
    setModalIsOpen: Dispatch<SetStateAction<boolean>>;
    modalIsOpen: boolean;
    detailData?: any;
    blngSiteList: object[];
    setBlngSiteList: Dispatch<SetStateAction<object[]>>;
    isSingleCheck?: boolean
    siteNm?: string
    blngCmpnySeq?: number;
}

const SiteSearchModal = (props: IProps) => {
    const [datatable, setDatatable] = useState<any>();
    const searchFormRef = useRef<any>();
    const [siteSeqList, setSiteSeqList] = useState<number[]>([]);
    const [blngSiteList, setBlngSiteList] = useState<any>([props.blngSiteList]);

    function fn_closeModal() {
        props.setModalIsOpen(false);
    }

    const fn_onShow = () => {

        let siteSeqList:any = []
        props.blngSiteList.map((data: any) => {
            siteSeqList.push(data.siteId);
        })
        setBlngSiteList(props.blngSiteList);
        setSiteSeqList(siteSeqList);

        fn_search()
    }

    const fn_search = (e?: any) => {
        e?.preventDefault()
        datatable?.draw()
    }
    const fn_select = () => {

        if (blngSiteList.length == 0) {
            toast.warn('현장을 선택해 주십시오.');
            return;
        }
        props.setBlngSiteList(blngSiteList);
        props.setModalIsOpen(false);
    }
    return (
        <div>
            <Modal
                className="bmc-modal sub" size="xl"
                show={props.modalIsOpen}
                onShow={() => fn_onShow()}
                onHide={fn_closeModal}
            >

                <Modal.Header>
                    <div className="d-flex justify-content-between align-items-center w-100 bmc-font-SD">
                        현장 검색
                        <IoMdClose className='hover:cursor-pointer' onClick={fn_closeModal}/>
                    </div>
                </Modal.Header>

                <Modal.Body className="px-4">
                        <SHSearch datatable={datatable} formRef={searchFormRef} btnType={'p'}>
                            <BMCInput type={'hidden'} name={'blngCmpnySeq'} value={props.blngCmpnySeq}></BMCInput>
                            <Row>
                                <Col>
                                    <Table className="bmc-font-SP bmc-fs-b2 my-3">
                                        <tbody>
                                        <tr className="border-top align-middle">
                                            <th className="bmc-bg-th" style={{width: '6%'}}>현장</th>
                                            <td style={{width: '19%'}}>
                                                <input type={'text'} name={"siteName"} value={props.siteNm}
                                                       className='form-control'/>
                                            </td>
                                        </tr>
                                        </tbody>
                                    </Table>
                                </Col>
                            </Row>
                        </SHSearch>


                    <div className={'ge-result'}>
                        <Datatables
                            dataUrl={'/admin/api/site/retrieveSiteDataList'}
                            parameterFormRef={searchFormRef}
                            checkedDataList={siteSeqList}
                            pk={'siteId'}
                            columns={[
                                {data: "siteName", title: "현장명", textAlign: 'left'},
                            ]}
                            checked={true}
                            isSingleCheck={props.isSingleCheck}
                            onLoad={(dt: any) => {
                                setDatatable(dt)
                            }}
                            isNotCheckedAll={true}
                            onClick={(data) => {
                                let cstmSiteSeqList = siteSeqList;
                                let cstmBlngSiteList = blngSiteList;
                                const idx = cstmSiteSeqList.indexOf(data.siteId);

                                if(data.isChecked){
                                    if(idx == -1){
                                        cstmSiteSeqList.push(data.siteId)
                                        cstmBlngSiteList.push(data);

                                    }
                                }else{
                                    if(idx > -1){
                                        cstmSiteSeqList.splice(idx, 1);
                                        cstmBlngSiteList.splice(idx, 1);
                                    }
                                }
                                // @ts-ignore
                                setSiteSeqList([].concat(cstmSiteSeqList));
                                setBlngSiteList([].concat(cstmBlngSiteList));
                            }}
                        />
                    </div>
                </Modal.Body>
                <Modal.Footer className="py-2">
                    <div className='flex justify-center w-100'>

                        <button
                            className='bmc-btn cancel p white me-3'
                            onClick={fn_closeModal}>닫기
                        </button>

                        <button onClick={fn_select}
                                className='bmc-btn confirm p'>선택

                        </button>
                    </div>
                </Modal.Footer>


            </Modal>
        </div>
    )
}

export default SiteSearchModal;
