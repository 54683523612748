export default function BMCCheckbox({ children, disabled, checked, onChange, name, className }: any) {
    return (
        <label className={className ? className + ' form-check-label bmc-input-label' : 'form-check-label bmc-input-label'}>
            <input
                type="checkbox"
                className={'form-check-input bmc-check me-1'}
                disabled={disabled}
                checked={checked || false}
                name={name}
                onChange={onChange}
            />
            {children}
        </label>
    );
}
