import React, {createContext, useState} from 'react';

const ProgressContext = createContext<any>({
  inProgress: false,
  spinner: () => {},
  setHideState: ({}) => {},
  process: null,
  setProcess: ({}: any) => {},
});

const ProgressProvider = ({children}: any) => {
  const [inProgress, setInProgress] = useState(false);
  const [isHide, setIsHide] = useState(false);
  const [process, setProcess] = useState(null);

  const spinner = {
    start: () => setInProgress(true),
    stop: () => setInProgress(false),
  };

  const setHideState = (hideState: any) => {
    setIsHide(hideState);
  };

  const value = {inProgress, spinner, setHideState, isHide, process, setProcess};

  return (
    // @ts-ignore
    <ProgressContext.Provider value={value}>
      {children}
    </ProgressContext.Provider>
  );
};

export {ProgressContext, ProgressProvider};
