import {IoMdClose} from "react-icons/io";
import iconCalendar from "../../../images/icon-calendar-solid.svg";
import CustomDatePickerRange from "../../component/ui/DatePickerRange";
import React, {Dispatch, SetStateAction, useContext, useEffect, useRef, useState} from "react";
import {CmmnFn, fn_getCurrentDate} from "../../../helper/CmmnHelper";
import {getLoginUser} from "../../../helper/SecurityHelper";
import {Container, Col, Row, Modal, Table} from "react-bootstrap";
import {COMMON_MODAL_STYLE, COMMON_MODAL_STYLE_SCROLL, COMMON_MULTISEL_STRINGS} from "../../../helper/StyleHelper";
import BMCInput from "../../../components/common/BMCInput";
import BMCTextarea from "../../../components/common/BMCTextarea";
import axios from "axios";
import {toast} from "react-toastify";
import messageCodeDic from "../../../util/messageCodeDic";
import errorCodeDic from "../../../util/errorCodeDic";
import BMCSelect from "../../../components/common/BMCSelect";

import restapi from "../../../helper/restAPIHelper";
import iconNodata from "../../../images/icon_nodocdata.png";

import RskAsmntCrtModal from "./RskAsmntCrtModal";
import BMCDatePicker from "../../../components/common/BMCDatePicker";
import BMCIconButton from "../../../components/common/BMCIconButton";
import {disableForm, readonlyForm, validFormData} from "../../../helper/FormHelper";
import ApprLineModal from "./ApprLineModal";
import CmpnyCardList from "../../../components/cmpny/CmpnyCardList";
import ApprCardList from "../../../components/appr/ApprCardList";
import {MultiSelect} from "react-multi-select-component";
import RskAsmntFileUploadModal from "./RskAsmntFileUploadModal";
import {ProgressContext} from "../../../context/Progress";
import RjctRsnModal from "./RjctRsnModal";


const $ = require("jquery")

interface IProps {
    setModalIsOpen: Dispatch<SetStateAction<boolean>>;
    modalIsOpen: boolean;
    detailData: any;
    siteId: number;
    isReadOnly?: boolean;
    isSub?: boolean;
}

const RskAsmntRgstModal = (props: IProps) => {

    const user = getLoginUser();

    const prodRef = useRef<any>();
    const raProdRef = useRef<any>();

    const [isDatePopupOpen, setIsDatePopupOpen] = useState(false);
    const [isRaDatePopupOpen, setIsRaDatePopupOpen] = useState(false);
    const [data, setData] = useState<any>()
    const [startDate, setStartDate] = useState<string>('');
    const [endDate, setEndDate] = useState<string>('');
    const [raStartDate, setRaStartDate] = useState<string>('');
    const [raEndDate, setRaEndDate] = useState<string>('');

    const [isDngrEvlCrtModalOpen, setIsDngrEvlCrtModalOpen] = useState<boolean>(false);
    const [isRskAsmntFileUploadModalOpen, setIsRskAsmntFileUploadModalOpen] = useState(false);
    const [isApprLineModalOpen, setIsApprLineModalOpen] = useState(false);
    const [dngrFctrDataList, setDngrFctrDataList] = useState<any>([]);
    const [apprLineDataList, setApprLineDataList] = useState<any>([]);
    const [eqmntCntList, setEqmntCntList] = useState<any>([]);
    const [slctdRowCode, setSlctdRowCode] = useState<String>('');
    const [isCrtCmpltd, setIsCrtCmpltd] = useState<boolean>(false);
    const [dngrWrkActDataList, setDngrWrkActDataList] = useState<any>([]);
    const formRef = useRef<any>();
    const [selected, setSelected] = useState([])
    const [refUserList, setRefUserList] = useState<any>([]);
    const [mngrUserDataList, setMngrUserDataList] = useState<any>([]);
    const [allUserDataList, setAllUserDataList] = useState<any>([]);

    const [isFileUpload, setIsFileUpload] = useState<any>(null);
    const [riskAsmntFileSrc, setRiskAsmntFileSrc] = useState<any>(null);
    const [riskAsmntFile, setRiskAsmntFile] = useState<any>(null);
    const {spinner} = useContext(ProgressContext);
    const [isRjctRsnModalOpen, setIsRjctRsnModalOpen] = useState<boolean>(false);
    const [riskAsmntId, setRiskAsmntId] = useState<any>(null);
    const [isRjctd, setIsRjctd] = useState<boolean>(false);



    useEffect(() => {
        fn_genEqmntCnt();

    }, []);

    useEffect(() => {
        if(isRjctd){
            props.setModalIsOpen(false);
        }
    }, [isRjctd])

    useEffect(() => {
        if (riskAsmntFileSrc) {
            setIsFileUpload(true)
            setDngrWrkActDataList([]);
            setDngrFctrDataList([]);
        }
    }, [riskAsmntFileSrc])

    useEffect(() => {

        let apprLinUserSeqList: number[] = [];
        apprLineDataList.map((data: any) => {
            apprLinUserSeqList.push(data.userSeq);
        });

        let cstmAllUserDataList = allUserDataList.filter((data: any) => (!apprLinUserSeqList.includes(data.userSeq)));
        let cstmData: object[] = [];

        cstmAllUserDataList = cstmAllUserDataList.filter((data: any) => (data.userSeq != props.detailData?.rgstrSeq))
        cstmAllUserDataList.map((data: any) => {
            cstmData.push({
                label: '[' + data.companyName + '] ' + data.userName + ' (' + data.chrgNm + ')',
                value: data.userSeq
            });
        })

        // @ts-ignore
        setRefUserList([].concat(cstmData));

    }, [apprLineDataList]);

    useEffect(() => {
        let selSeq: number[] = [];
        selected.map((data: any) => {
            selSeq.push(data.value);
        })

        let cstmAllUserDataList: any = allUserDataList.filter((data: any) => {
            return !selSeq.includes(data.userSeq);
        })

        setMngrUserDataList([].concat(cstmAllUserDataList));
        let showText = ''

        if (selected.length > 0 && selected.length == refUserList.length) {
            selected.map((data: any, idx: number) => {
                showText += data.label
                if (idx < selected.length - 1) {
                    showText += ', '
                }

            })

            $('#rskAsmntRgstModal_rgstForm').find('.dropdown-heading-value').find('span').html(showText);
        }

    }, [selected, refUserList])


    useEffect(() => {
        if (raStartDate && raEndDate) {

            if (raStartDate < startDate || raStartDate > endDate || raEndDate < startDate || raEndDate > endDate) {
                toast.warn('작업기간은 평가기간을 벗어날 수 없습니다.');
                return;
            }

            dngrWrkActDataList.map((data: any) => {
                if (data.wrkLvlCd == slctdRowCode) {
                    data['raStartDate'] = raStartDate;
                    data['raEndDate'] = raEndDate;
                }
            })

            setDngrWrkActDataList([].concat(dngrWrkActDataList))
            setRaStartDate('')
            setRaEndDate('')
        }

    }, [raStartDate, raEndDate])


    useEffect(() => {

        if (props.isReadOnly || props.detailData?.prcsStatCd == 'S001004') {
            disableForm(formRef);
        }


        if (dngrFctrDataList.length > 0 && isCrtCmpltd) {

            let codeList: string[] = [];
            dngrFctrDataList.map((fctrData: any) => {
                codeList.push(fctrData.code);
            })

            let codeLevelThreeArr: any = [];
            dngrWrkActDataList.map((data: any) => {
                codeLevelThreeArr.push(data.wrkLvlCd)
            })

            dngrFctrDataList.map((inData: any) => {
                if (!codeLevelThreeArr.includes(inData.code.substring(0, 5))) {
                    codeLevelThreeArr.push(inData.code.substring(0, 5))
                    dngrWrkActDataList.push({
                        wrkTpCd: inData.code.substring(0, 3)
                        , wrkTpNm: inData.wrkTpNm
                        , wrkLvlCd: inData.code.substring(0, 5)
                        , wrkLvlNm: inData.wrkLvlNm
                        , siteId: data.siteId
                        , raStartDate: startDate
                        , raEndDate: endDate
                    })
                }
            })

            setDngrWrkActDataList(dngrWrkActDataList);


            setIsCrtCmpltd(false)
            setIsFileUpload(false);
        }

    }, [dngrFctrDataList, isCrtCmpltd])


    const fn_retrieveApprUserDataList = (detailData: any) => {


        restapi.retrieveRiskAsmntApprInfo({riskAsmntId: detailData.riskAsmntId, rgstrSeq: detailData.rgstrSeq}).then((apprData) => {
            const apprUserDataList = apprData.apprUserDataList;
            const refUserDataList = apprData.refUserDataList;
            let cstmData: object[] = [];

            restapi.retrieveUserDataList({cmpnySeq: detailData.cmpnySeq, isApprMngr: true, siteId:props.siteId}).then((rsltData) => {
                setAllUserDataList(rsltData);
                if (apprUserDataList.length == 0 && refUserDataList.length == 0) {
                    setMngrUserDataList(rsltData);
                    rsltData = rsltData.filter((data: any) => (data.userSeq != detailData.rgstrSeq))
                    rsltData.map((data: any) => {
                        cstmData.push({
                            label: '[' + data.companyName + '] ' + data.userName + ' (' + data.chrgNm + ')',
                            value: data.userSeq
                        });
                    })

                    setRefUserList(cstmData);
                    setApprLineDataList([]);
                    setSelected([]);
                } else {
                    let refUserSeqList: any = [];
                    let apprUserSeqList: any = [];
                    refUserDataList.map((data: any) => {
                        refUserSeqList.push(data.userSeq)
                    })
                    apprUserDataList.map((data: any) => {
                        apprUserSeqList.push(data.userSeq)
                    })

                    setMngrUserDataList(rsltData.filter((data: any) => {
                        return !refUserSeqList.includes(data.userSeq);
                    }));

                    let cstmApprLineDataList = rsltData.filter((data: any) => {
                        return apprUserSeqList.includes(data.userSeq);
                    })

                    cstmApprLineDataList.map((cstmData: any) => {
                        apprUserDataList.map((apprData: any) => {
                            if (cstmData.userSeq == apprData.userSeq) {
                                cstmData.apprStat = apprData.apprStat;
                                cstmData.apprStatNm = apprData.apprStatNm;
                                cstmData.prcsDt = apprData.prcsDt;
                                cstmData.apprSn = apprData.apprSn;
                            }
                        })
                    })

                    // 오름차순 정렬
                    cstmApprLineDataList = cstmApprLineDataList.sort((a: any, b: any) => {
                        if (a.apprSn > b.apprSn) return 1;
                        if (a.apprSn < b.apprSn) return -1;
                        return 0;
                    });

                    setApprLineDataList(cstmApprLineDataList)

                    let csmtSelectData = rsltData.filter((data: any) => {
                        return refUserSeqList.includes(data.userSeq);
                    })


                    csmtSelectData.map((data: any) => {
                        data.label = '[' + data.companyName + '] ' + data.userName + ' (' + data.chrgNm + ')';
                        data.value = data.userSeq;
                    })


                    rsltData = rsltData.filter((data: any) => {
                        return !apprUserSeqList.includes(data.userSeq);
                    })

                    rsltData.map((data: any) => {
                        cstmData.push({
                            label: '[' + data.companyName + '] ' + data.userName + ' (' + data.chrgNm + ')',
                            value: data.userSeq
                        });
                    })

                    setRefUserList(cstmData);
                    setSelected(csmtSelectData);

                }

            })

        })


    }

    const fn_genEqmntCnt = () => {
        let cntList = [];

        for (let i = 1; i < 100; i++) {
            cntList.push(i);
        }
        setEqmntCntList(cntList);
    }

    const fn_setFormData = (e: any) => {
        CmmnFn.setFormData(e, data, setData);

    }

    const fn_onAfterOpen = () => {

        setRiskAsmntFile(null);
        setIsDatePopupOpen(false);
        setIsRaDatePopupOpen(false);
        // 신규
        if (!props.detailData) {

            const siteId = props.siteId;
            const cmpnySeq = user.blngCmpnySeq;
            const today = CmmnFn.fn_getCurrentDate();

            setData({
                siteId: siteId,
                cmpnySeq: cmpnySeq,
            })
            setStartDate(today);
            setEndDate(today);
            setDngrWrkActDataList([]);
            setDngrFctrDataList([]);
            setApprLineDataList([]);
            setSelected([]);
            setIsFileUpload(null);
            setRiskAsmntFileSrc(null);

            // 상세
        } else {
            console.log('props.detailData?.prcsStatCd: ', props.detailData?.prcsStatCd);

            setData(props.detailData);

            setIsFileUpload(props.detailData.isFileUpload);
            setStartDate(props.detailData.raStartDate);
            setEndDate(props.detailData.raEndDate);
            const riskAsmntFileId = props.detailData.riskAsmntFileId;
            if(riskAsmntFileId){
                setRiskAsmntFileSrc(process.env.REACT_APP_FILE_URL + "/bmcss-data/fileDownload?attachSeq=" + riskAsmntFileId + '&sn=0&isPdf=1');
            }else{
                setRiskAsmntFileSrc(null);
            }

            restapi.retrieveRiskEvlFctrDtlDataList(props.detailData.riskAsmntId, props.siteId).then((rsltData) => {
                const rskAsmntWrkActDataList = rsltData.rskAsmntWrkActDataList;
                const dngrFctrDataList = rsltData.dngrFctrDataList;
                setDngrWrkActDataList(rskAsmntWrkActDataList);
                rskAsmntWrkActDataList.map((data: any) => {
                    dngrFctrDataList.map((fctrData: any) => {
                        if (data.wrkLvlCd == fctrData.code.substring(0, 5)) {
                            fctrData.wrkTpNm = data.wrkTpNm;
                            fctrData.wrkLvlNm = data.wrkLvlNm;
                            fctrData.raStartDate = data.raStartDate;
                            fctrData.raEndDate = data.raEndDate;
                        }
                    })
                })


                setDngrFctrDataList(dngrFctrDataList);

            })
            fn_retrieveApprUserDataList(props.detailData);
        }


    }


    function fn_closeModal() {
        props.setModalIsOpen(false);
    }

    const submit = (e: any) => {
        e.preventDefault()

    }

    const fn_datePopupOpen = () => {
        setIsDatePopupOpen(!isDatePopupOpen);
    };

    const fn_raDatePopupOpen = () => {
        setIsRaDatePopupOpen(!isRaDatePopupOpen);
    }


    const fn_registData = () => {

        if (isFileUpload === null) {
            toast.warn('파일올리기 또는 항목추가 버튼을 선택해 주십시오')
            return;
        }

        if (!data) {
            toast.warn('데이터를 입력해 주십시오.');
            return;
        }

        if (!startDate) {
            toast.warn('평가 시작일을 선택해 주십시오.');
            return;
        }
        if (!endDate) {
            toast.warn('평가 종료일을 선택해 주십시오.');
            return;
        }

        if (!data.workTypeCd) {
            toast.warn('공종을 선택해 주십시오.');
            return;
        }

        if (!data.ocptCd) {
            toast.warn('직종을 선택해 주십시오.');
            return;
        }

        if(!data.rskAsmntGb) {
            toast.warn('구분을 선택해 주십시오.');
            return;
        }

        if(!data.evlCntnts) {
            toast.warn('평가내용을 입력해 주십시오.');
            return;
        }


        if (isFileUpload === false) {
            if (dngrWrkActDataList.length == 0) {
                toast.warn('위험성 평가 항목을 선택해 주십시오.');
                return;
            }

            let flag = false;
            let inData;

            for (let i = 0; i < dngrWrkActDataList.length; i++) {
                inData = dngrWrkActDataList[i];
                if (!inData.raStartDate || !inData.raEndDate) {
                    toast.warn(inData.wrkTpNm + '(' + inData.wrkLvlNm + ') 작업기간을 선택해 주십시오');
                    flag = true;
                    break;
                }

                if (inData.raStartDate < startDate || inData.raStartDate > endDate || inData.raEndDate < startDate || inData.raEndDate > endDate) {
                    toast.warn(inData.wrkTpNm + '(' + inData.wrkLvlNm + ') 작업기간은 평가기간을 벗어날 수 없습니다.');
                    flag = true;
                    break;
                }

                inData.riskAsmntCd = inData.code;

            }


            if (flag) {
                return;
            }
        }else if(isFileUpload === true){
            if(!riskAsmntFileSrc){
                toast.warn('파일을 첨부해 주십시오.');
                return;
            }
        }



        let param = data;
        var formData: any = validFormData(formRef, null, param);
        formData.append('rskAsmntFile',riskAsmntFile);
        param.raStartDate = startDate;
        param.raEndDate = endDate;
        param.rgstrSeq = user.userSeq;
        param.updtrSeq = user.userSeq;
        param.isFileUpload = isFileUpload;
        param.dngrFctrDataList = dngrFctrDataList;
        param.rskAsmntWrkActDataList = dngrWrkActDataList;

        if (apprLineDataList.length > 0) {
            let apprUserSeqList: number[] = [];

            apprLineDataList.map((data: any) => {
                apprUserSeqList.push(data.userSeq);
            })

            param.apprUserSeqList = apprUserSeqList;
        }

        if (selected.length > 0) {
            let refUserSeqList: number[] = [];

            selected.map((data: any) => {
                refUserSeqList.push(data.value);
            })

            param.refUserSeqList = refUserSeqList;
        }



        if (!window.confirm('저장 하시겠습니까?')) {
            return;
        }
        formData.set('rskAsmntInfo', new Blob([JSON.stringify(param)], {type: "application/json"}));
        spinner.start();
        axios.post('/admin/api/rskAsmnt/saveData', formData).then((result) => {
                if (result.data > 0) {
                    toast.success(messageCodeDic['001'])

                    props.setModalIsOpen(false);
                } else {
                    toast.error(errorCodeDic['003']);
                }
            }
        ).catch(error => {
            toast.error(errorCodeDic['003']);
            console.log('error: ', error);
        })
        .finally(() => {
            spinner.stop();
        });


    }

    const fn_delRow = (wrkLvlCd: number) => {


        dngrWrkActDataList.map((data: any) => {
            if (data.wrkLvlCd == wrkLvlCd) {

            }
        })

        const filteredDngrWrkActDataList = dngrWrkActDataList.filter((data: any) => data.wrkLvlCd != wrkLvlCd);
        const filteredDngrFctrDataList = dngrFctrDataList.filter((data: any) => data.code.substring(0, 5) != wrkLvlCd);


        setDngrWrkActDataList([].concat(filteredDngrWrkActDataList));
        setDngrFctrDataList([].concat(filteredDngrFctrDataList))
    }


    const fn_openDngrEvlCrtPop = () => {
        console.log('crt open')
        if (!startDate || !endDate) {
            toast.warn('평가기간을 선택해 주십시오.')
            return;
        }

        console.log('bf open')
        setIsDngrEvlCrtModalOpen(true);

    }

    const fn_updateDngrWrkActDataList = (e: any, rowCode: string) => {


        dngrWrkActDataList.map((data: any) => {
            if (data.wrkLvlCd == rowCode) {
                data[e.target.name] = e.target.value;
            }
        })

        setDngrWrkActDataList([].concat(dngrWrkActDataList))
    }

    const fn_updateDngrFctrDataList = (e: any, rowCode: string) => {
        dngrFctrDataList.map((data: any) => {
            if (data.code == rowCode) {
                data[e.target.name] = e.target.value;
            }
        })

        setDngrFctrDataList([].concat(dngrFctrDataList));
    }

    const fn_rqstAppr = () => {
        if (apprLineDataList.length == 0) {
            toast.warn('결재선을 선택해 주십시오.');
            return;
        }

        let apprUserSeqList: number[] = [];
        apprLineDataList.map((data: any, idx: number) => {
            apprUserSeqList.push(data.userSeq);
        })

        let refUserSeqList: any = [];
        selected.map((data: any) => {
            refUserSeqList.push(data.userSeq || data.value)
        })

        if (!window.confirm('결재요청 하시겠습니까?')) {
            return;
        }

        let prcsStatCd = '';

        if(apprUserSeqList.length == 1){
            prcsStatCd = 'S001004';
        }else{
            prcsStatCd = 'S001002';
        }

        axios.post('/admin/api/rskAsmnt/rqstAppr', {
            riskAsmntId: props.detailData.riskAsmntId,
            apprUserSeqList: apprUserSeqList,
            refUserSeqList: refUserSeqList,
            prcsStatCd: prcsStatCd,
        }).then((rslt) => {
            if (rslt.data > 0) {
                toast.success(messageCodeDic['006']);
                props.setModalIsOpen(false);
            } else {
                toast.warn(errorCodeDic['003']);
            }
        })

    }

    const fn_appr = (gb: string) => {
        let url = '';
        let param: any = {riskAsmntId: props.detailData.riskAsmntId};
        let msg = ''

        if ('appr' == gb) {
            url = '/admin/api/rskAsmnt/approve';
            let apprUserSeqList = [];
            const myInfo = apprLineDataList.filter((data: any) => (data.userSeq == user.userSeq))[0];
            const nextInfo = apprLineDataList.filter((data: any) => (data.apprSn == (myInfo.apprSn + 1)));

            apprUserSeqList.push(myInfo.userSeq);
            if (nextInfo.length > 0) {
                apprUserSeqList.push(nextInfo[0].userSeq);
            }

            param.apprUserSeqList = apprUserSeqList;
            msg = '승인 하시겠습니까?'

            if (!window.confirm(msg)) {
                return;
            }

            axios.post(url, param).then((rslt) => {
                if (rslt.data > 0) {
                    toast.success(messageCodeDic['006']);
                    props.setModalIsOpen(false);
                } else {
                    toast.warn(errorCodeDic['003']);
                }
            })

        } else if ('rjct' == gb) {

            setIsRjctRsnModalOpen(true);
            setRiskAsmntId(props.detailData.riskAsmntId);
        }


    }

    const fn_cancelRqst = () => {

        let apprUserSeqList: any = [];

        apprLineDataList.map((data: any) => {
            apprUserSeqList.push(data.userSeq)
        })
        if (!window.confirm('회수 하시겠습니까?')) {
            return;
        }

        axios.post('/admin/api/rskAsmnt/cancelAppr', {
            riskAsmntId: props.detailData.riskAsmntId,
            apprUserSeqList: apprUserSeqList
        }).then((rslt) => {
            if (rslt.data > 0) {
                toast.success(messageCodeDic['006']);
                props.setModalIsOpen(false);
            } else {
                toast.warn(errorCodeDic['003']);
            }
        })
    }

    const fn_deleteRiskAsmntData = () => {

        if (!window.confirm('삭제 하시겠습니까?')) {
            return;
        }
        axios.post('/admin/api/rskAsmnt/deleteRiskAsmntData', {riskAsmntId: props.detailData.riskAsmntId}).then((rslt) => {
            if (rslt.data > 0) {
                toast.success(messageCodeDic['006']);
                props.setModalIsOpen(false);
            } else {
                toast.warn(errorCodeDic['003']);
            }
        })
    }
    const fn_rgstComment = () => {
        if(!data.comment){
            toast.warn('등록할 코멘트를 입력해 주십시오.');
            return;
        }

        if(!window.confirm('코멘트를 등록 하시겠습니까?')){
            return;
        }

        axios.post('/admin/api/rskAsmnt/updateRskAsmntComment', {riskAsmntId: props.detailData.riskAsmntId, comment:data.comment}).then((rsltData: any) => {
            if(rsltData.data > 0){
                toast.success(messageCodeDic['006']);
            }else{
                toast.error(errorCodeDic['003']);
            }
        }).catch(error => {
            toast.error(errorCodeDic['003']);
            console.log('error: ', error);
        })
            .finally(() => {
                spinner.stop();
            });
    }

    return (
        <div>


            <RskAsmntCrtModal setModalIsOpen={setIsDngrEvlCrtModalOpen} modalIsOpen={isDngrEvlCrtModalOpen}
                              dngrFctrDataList={dngrFctrDataList} setIsCrtCmpltd={setIsCrtCmpltd}
                              setDngrFctrDataList={setDngrFctrDataList} siteId={props.siteId}/>

            <ApprLineModal setModalIsOpen={setIsApprLineModalOpen} modalIsOpen={isApprLineModalOpen}
                           mngrUserDataList={mngrUserDataList}
                           apprLineDataList={apprLineDataList} cmpnySeq={data?.cmpnySeq} rgstrSeq={data?.rgstrSeq}
                           setApprLineDataList={setApprLineDataList} siteId={props.siteId}/>
            <RjctRsnModal setModalIsOpen={setIsRjctRsnModalOpen} setIsRjctd={setIsRjctd} modalIsOpen={isRjctRsnModalOpen} riskAsmntId={riskAsmntId} />



            <Modal className={props.isSub ? "bmc-modal sub" : "bmc-modal"} size="xl"
                   show={props.modalIsOpen}
                   onShow={fn_onAfterOpen}
                   onHide={fn_closeModal}

            >
                {(isRaDatePopupOpen && props.detailData?.prcsStatCd != 'S001004')
                    ? <div className='absolute top-200 left-1/3'>
                        <CustomDatePickerRange setIsDatePopupOpen={setIsRaDatePopupOpen}
                                               prodRef={raProdRef}
                                               setStartDateView={setRaStartDate}
                                               setEndDateView={setRaEndDate}/>
                    </div>
                    : null}
                <Modal.Header>
                    <div className="d-flex justify-content-between align-items-center w-100">
                        위험성 평가 등록/수정
                        <IoMdClose className='hover:cursor-pointer' onClick={fn_closeModal}/>
                    </div>
                </Modal.Header>
                <Modal.Body className="px-4">

                    <form name={'rgstForm'} id={'rskAsmntRgstModal_rgstForm'} ref={formRef} onSubmit={submit}  encType="multipart/form-data" method={"post"}>
                        <RskAsmntFileUploadModal setModalIsOpen={setIsRskAsmntFileUploadModalOpen}
                                                 modalIsOpen={isRskAsmntFileUploadModalOpen}
                                                 data={data} setRiskAsmntFile={setRiskAsmntFile}
                                                 setData={setData}
                                                 setRiskAsmntFileSrc={setRiskAsmntFileSrc}
                        ></RskAsmntFileUploadModal>

                            {props.detailData &&
                                <div className="bmc-modal-div">
                                    <div className="div-header bmc-fw-5">
                                        <div className={'min-w-[100px] font-semibold'}>
                                            결재 정보
                                        </div>
                                        <div className='w-100 d-flex justify-content-end align-items-center mb-2'>

                                            {props.detailData.prcsStatCd == 'S001001' && props.detailData?.rgstrSeq == user.userSeq &&
                                                <>
                                                    <BMCIconButton kind={'normal'} marginRight={'5px'} onClick={() => {
                                                        setIsApprLineModalOpen(true)
                                                    }}> 결재선
                                                    </BMCIconButton>
                                                    <BMCIconButton kind={'normal'} marginRight={'5px'}
                                                                  onClick={fn_rqstAppr}> 결재요청
                                                    </BMCIconButton>
                                                </>
                                            }
                                            {
                                                apprLineDataList.filter((data: any) => (data.apprStat == 'S002001'))[0]?.userSeq == user.userSeq &&
                                                <>
                                                    <button className={'bmc-btn mr-5 btn-primary'} onClick={() => {
                                                        fn_appr('appr')
                                                    }}> 승인
                                                    </button>
                                                    <button className={'bmc-btn mr-5 btn-danger'} onClick={() => {
                                                        fn_appr('rjct')
                                                    }}> 반려
                                                    </button>
                                                </>
                                            }

                                            {(props.detailData.prcsStatCd == 'S001002' && props.detailData?.rgstrSeq == user.userSeq) &&
                                                <BMCIconButton kind={'normal'} marginRight={'5px'}
                                                              onClick={fn_cancelRqst}> 회수
                                                </BMCIconButton>
                                            }

                                            {(['S001001', 'S001002'].includes(props.detailData.prcsStatCd) && props.detailData?.rgstrSeq == user.userSeq) &&

                                                <BMCIconButton kind={'delete'} marginRight={'5px'}
                                                              onClick={fn_deleteRiskAsmntData}> 삭제
                                                </BMCIconButton>
                                            }


                                        </div>


                                    </div>

                                    <div className="div-body px-3">
                                        <div className='w-100 d-flex justify-content-end align-items-center mb-2'>

                                        </div>
                                        <div className="w-100">
                                            {apprLineDataList.length > 0 ? <ApprCardList items={apprLineDataList}/> :
                                                <span>※ 결재정보를 등록해 주십시오.</span>
                                            }

                                        </div>
                                        <div>
                                            <Table className="align-middle">
                                                <tbody>
                                                <tr>
                                                    <th className="bmc-bg-th" style={{width: '15%'}}>참조자</th>
                                                    <td style={{width: '85%'}} colSpan={3}>
                                                        <MultiSelect className={'form-control'}
                                                                     options={refUserList}
                                                                     value={selected}
                                                                     disabled={props.detailData.prcsStatCd == 'S001001' ? false : true}
                                                                     onChange={setSelected}
                                                                     labelledBy={"참조 선택"}
                                                                     isCreatable={false}
                                                                     overrideStrings={COMMON_MULTISEL_STRINGS}

                                                        />
                                                    </td>
                                                </tr>
                                                {['S001002', 'S001003', 'S001004'].includes(props.detailData.prcsStatCd) &&
                                                    <tr>
                                                        <th className="bmc-bg-th" style={{width: '15%'}}>코멘트</th>
                                                        <td style={{width: '85%'}} colSpan={3}>
                                                            <div className="d-flex align-items-center w-100">
                                                                <BMCInput className={'form-control'} name={'comment'} value={data?.comment} onChange={fn_setFormData}></BMCInput>

                                                                {
                                                                    (apprLineDataList.filter((data: any) => (user.userSeq == data.userSeq)).length > 0
                                                                    || selected.filter((data: any) => (user.userSeq == data.userSeq)).length > 0) &&
                                                                    <button className="bmc-btn btn-secondary min-w-[70px] ml-5" type="button" onClick={fn_rgstComment}>등록
                                                                    </button>
                                                                }

                                                            </div>
                                                        </td>
                                                    </tr>
                                                }

                                                {'S001005' === props.detailData.prcsStatCd &&
                                                    <tr>
                                                        <th className="bmc-bg-th" style={{width: '15%'}}>반려사유</th>
                                                        <td style={{width: '85%'}} colSpan={3}>
                                                            <div className="d-flex align-items-center w-100">
                                                                <BMCInput className={'form-control'} name={'rjctRsn'} disabled={true} value={data?.rjctRsn}></BMCInput>

                                                            </div>
                                                        </td>
                                                    </tr>
                                                }

                                                </tbody>
                                            </Table>


                                        </div>
                                    </div>
                                </div>
                            }
                        <div className="bmc-modal-div">
                            <div className="div-header">
                                기본 정보
                            </div>
                            <div className="div-body px-3">
                                <Table className="my-2 align-middle">
                                    <tbody>
                                    <tr>
                                        <th className="bmc-bg-th" style={{width: '15%'}}>업체</th>
                                        <td style={{width: '35%'}}>
                                            <BMCSelect initOptionGb={'none'} name={'cmpnySeq'} className={"w-100"}
                                                      selParam={{siteId: props.siteId}}
                                                      codeGb={'siteCmpny'}
                                                      onChange={fn_setFormData}
                                                      value={data?.cmpnySeq}></BMCSelect>
                                        </td>
                                        <th className="bmc-bg-th required" style={{width: '15%'}}>평가기간</th>
                                        <td style={{width: '35%'}}>
                                            <div className="d-flex align-items-center w-100" ref={prodRef}>
                                                <BMCDatePicker
                                                    className={"text-center"} readOnly={true}
                                                    value={startDate} name={'raStrtDate'} onClick={fn_datePopupOpen}
                                                    onChange={fn_setFormData}
                                                    calendar={fn_datePopupOpen}
                                                />

                                                <span>~</span>
                                                <BMCDatePicker readOnly={true}
                                                               className={"text-center"}
                                                               value={endDate} name={'raEndDate'}
                                                               onClick={fn_datePopupOpen}
                                                               onChange={fn_setFormData}
                                                               calendar={fn_datePopupOpen}
                                                />

                                                {(isDatePopupOpen && props.detailData?.prcsStatCd != 'S001004')
                                                    ? <div className='absolute top-115'>
                                                        <CustomDatePickerRange setIsDatePopupOpen={setIsDatePopupOpen}
                                                                               prodRef={prodRef}
                                                                               setStartDateView={setStartDate}
                                                                               setEndDateView={setEndDate}/>
                                                    </div>
                                                    : null}
                                            </div>
                                        </td>
                                    </tr>
                                    <tr>
                                        <th className="bmc-bg-th" style={{width: '15%'}}>공종</th>
                                        <td style={{width: '35%'}}>
                                            <BMCSelect initOptionGb={'sel'} name={'workTypeCd'} className={"w-100"}
                                                      selParam={{siteId: props.siteId, manageCode: 'M001001'}}
                                                      codeGb={'siteMngCd'}
                                                      onChange={fn_setFormData}
                                                      value={data?.workTypeCd}></BMCSelect>
                                        </td>
                                        <th className="bmc-bg-th" style={{width: '15%'}}>직종</th>
                                        <td style={{width: '35%'}}>
                                            <BMCSelect initOptionGb={'sel'} name={'ocptCd'} className={"w-100"}
                                                      selParam={{siteId: props.siteId, manageCode: 'M001002'}}
                                                      codeGb={'siteMngCd'}
                                                      onChange={fn_setFormData}
                                                      value={data?.ocptCd}></BMCSelect>
                                        </td>
                                    </tr>
                                    <tr>
                                        <th className="bmc-bg-th" style={{width: '15%'}}>구분</th>
                                        <td style={{width: '35%'}}>
                                            <BMCSelect initOptionGb={'sel'} name={'rskAsmntGb'} className={"w-100"}
                                                      codeGb={'cmmn'}
                                                      codeGrpId={'R001'}
                                                      onChange={fn_setFormData}
                                                      value={data?.rskAsmntGb}></BMCSelect>
                                        </td>
                                        <th className="bmc-bg-th" style={{width: '15%'}}>평가내용</th>
                                        <td style={{width: '35%'}} colSpan={3}>
                                            <BMCInput className="bmc-form input form-control" name={'evlCntnts'}
                                                     onChange={fn_setFormData} value={data?.evlCntnts}/>
                                        </td>
                                    </tr>
                                    </tbody>
                                </Table>
                            </div>
                        </div>

                        <div className="bmc-modal-div under mb-2">
                            <div className="div-header">
                                <div>
                                    평가서
                                </div>

                                <div className="d-flex align-items-center">


                                    <button className={"bmc-btn gray view mr-5 cursor-pointer"}
                                            onClick={() => {
                                                setIsRskAsmntFileUploadModalOpen(true);
                                            }}
                                    >
                                        파일올리기
                                    </button>
                                    {
                                        !props.detailData?.riskAsmntFileId && <BMCIconButton kind={'add'} onClick={fn_openDngrEvlCrtPop}>항목추가</BMCIconButton>
                                    }

                                </div>
                            </div>
                        </div>
                        <div>
                            {isFileUpload === true ? (riskAsmntFileSrc &&
                                    <div className="bmc-modal-div">
                                        <div className="div-header bmc-fw-5">
                                            <div>
                                                평가서 파일 <span
                                                className="bmc-text-font_secondary01 bmc-fs-b3">| {data?.wrkTp} ({data?.wrkLvl})</span>
                                            </div>
                                        </div>
                                        <div className="div-body p-3">
                                            <iframe src={riskAsmntFileSrc} className='w-100 min-h-[600px]'/>
                                        </div>

                                    </div>

                                )

                                : isFileUpload === false &&
                                (dngrWrkActDataList.length > 0 ?
                                        dngrWrkActDataList.map((wrkActData: any, idx: number) => (
                                            <div className="bmc-modal-div" key={idx}>
                                                <div className="div-header bmc-fw-5">
                                                    <div>
                                                        항목등록 <span
                                                        className="bmc-text-font_secondary01 bmc-fs-b3">| {wrkActData.wrkTpNm} ({wrkActData.wrkLvlNm})</span>
                                                    </div>
                                                    {!props.isReadOnly && <BMCIconButton kind={'delete'} onClick={() => {
                                                        fn_delRow(wrkActData.wrkLvlCd)
                                                    }}>삭제</BMCIconButton>}

                                                </div>
                                                <div className="div-body p-3">
                                                    <Container className='bmc-box-form gray py-2 mb-3'>

                                                        <Row>
                                                            <Col>
                                                                <span className="bmc-fw-5">세부공종</span>
                                                                <BMCInput className="bmc-form input"
                                                                         value={wrkActData.wrkTpNm}
                                                                         disabled={true}/>
                                                            </Col>
                                                            <Col>
                                                                <span className="bmc-fw-5">작업단계</span>
                                                                <BMCInput className="bmc-form input"
                                                                         value={wrkActData.wrkLvlNm}
                                                                         disabled={true}/>
                                                            </Col>
                                                            <Col>
                                                                <span className="bmc-fw-5">작업기간</span>
                                                                <div
                                                                    className="d-flex align-items-center justify-content-between"
                                                                    ref={raProdRef}>
                                                                    <BMCDatePicker width="45%"
                                                                                   className={"text-center"}
                                                                                   readOnly={true}
                                                                                   value={wrkActData.raStartDate || startDate}
                                                                                   name={'raStrtDate'}
                                                                                   onClick={() => {
                                                                                      fn_raDatePopupOpen();
                                                                                      setSlctdRowCode(wrkActData.wrkLvlCd)
                                                                                  }}
                                                                                   calendar={() => {
                                                                                      fn_raDatePopupOpen();
                                                                                      setSlctdRowCode(wrkActData.wrkLvlCd)
                                                                                  }}
                                                                    />
                                                                    <span>~</span>
                                                                    <BMCDatePicker readOnly={true} width="45%"
                                                                                   className={"text-center"}
                                                                                   value={wrkActData.raEndDate || endDate}
                                                                                   name={'raEndDate'}
                                                                                   onClick={() => {
                                                                                      fn_raDatePopupOpen();
                                                                                      setSlctdRowCode(wrkActData.wrkLvlCd)
                                                                                  }}
                                                                                   calendar={() => {
                                                                                      fn_raDatePopupOpen();
                                                                                      setSlctdRowCode(wrkActData.wrkLvlCd)
                                                                                  }}
                                                                    />
                                                                </div>
                                                            </Col>
                                                        </Row>

                                                    </Container>
                                                    {
                                                        dngrFctrDataList.map((fctrData: any, idx: number) => (
                                                            fctrData.code.substring(0, 5) == wrkActData.wrkLvlCd &&

                                                            <Container className='bmc-box-form py-2 mt-2' key={idx}>
                                                                <Row className=" ml-2 font-semibold">
                                                                    {'위험성 평가 ' + (idx + 1)}
                                                                </Row>
                                                                <hr/>
                                                                <Row className="mt-1 mb-2">
                                                                    <Col>
                                                                        <span className="bmc-fw-5">피해형태</span>
                                                                        <BMCInput className="bmc-form input text-center"
                                                                                 value={fctrData.damageForm}
                                                                                 disabled={true}/>
                                                                    </Col>
                                                                    <Col>
                                                                        <span className="bmc-fw-5">위험빈도</span>
                                                                        <select className={'bmc-form select w-100'}
                                                                                value={fctrData?.frequency || ''}
                                                                                onChange={(e) => {
                                                                                    fn_updateDngrFctrDataList(e, fctrData.code)
                                                                                }} name={'frequency'}>
                                                                            <option className={'text-center'}
                                                                                    value={1}>1
                                                                            </option>
                                                                            <option className={'text-center'}
                                                                                    value={2}>2
                                                                            </option>
                                                                            <option className={'text-center'}
                                                                                    value={3}>3
                                                                            </option>
                                                                        </select>
                                                                    </Col>
                                                                    <Col>
                                                                        <span className="bmc-fw-5">위험강도</span>
                                                                        <select className={'bmc-form select w-100'}
                                                                                value={fctrData?.strength || ''}
                                                                                onChange={(e) => {
                                                                                    fn_updateDngrFctrDataList(e, fctrData.code)
                                                                                }} name={'strength'}>
                                                                            <option className={'text-center'}
                                                                                    value={1}>1
                                                                            </option>
                                                                            <option className={'text-center'}
                                                                                    value={2}>2
                                                                            </option>
                                                                            <option className={'text-center'}
                                                                                    value={3}>3
                                                                            </option>
                                                                        </select>
                                                                    </Col>
                                                                    <Col>
                                                                        <span className="bmc-fw-5">위험등급</span>
                                                                        <BMCInput className="bmc-form input text-center"
                                                                                 value={fctrData.frequency * fctrData.strength <= 3 ? '하' :
                                                                                     fctrData.frequency * fctrData.strength == 9 ? '상' :
                                                                                         '중'
                                                                                 } disabled={true}/>
                                                                    </Col>
                                                                </Row>
                                                                <Row>
                                                                    <Col>
                                                                        <span className="bmc-fw-5">위험요인</span>
                                                                        <BMCTextarea name={"codeName"}
                                                                                    onChange={(e) => {
                                                                                        fn_updateDngrFctrDataList(e, fctrData.code)
                                                                                    }}
                                                                                    value={fctrData.codeName}
                                                                                    autoHeight={true}
                                                                        />
                                                                    </Col>
                                                                    <Col>
                                                                        <span className="bmc-fw-5">관리방안</span>
                                                                        <BMCTextarea name={"managementMethod"}
                                                                                    onChange={(e) => {
                                                                                        fn_updateDngrFctrDataList(e, fctrData.code)
                                                                                    }}
                                                                                    value={fctrData.managementMethod}
                                                                                    autoHeight={true}
                                                                        />
                                                                    </Col>
                                                                </Row>
                                                            </Container>)
                                                        )

                                                    }
                                                </div>
                                            </div>
                                        ))
                                        :
                                        <div
                                            className="w-100 justify-content-center d-flex flex-column align-items-center py-2">
                                            <img src={iconNodata} alt="이미지" style={{width: '70px'}}/>
                                            <span className="bmc-text-font_secondary02 bmc-fw-5 mt-2">데이터가 없습니다. 항목을 추가하세요.</span>
                                        </div>
                                )
                            }
                        </div>
                    </form>
                </Modal.Body>
                <Modal.Footer className="py-2">
                    <div className='flex justify-center w-100'>
                        <button
                            className='bmc-btn cancel p me-3'
                            onClick={fn_closeModal}>닫기
                        </button>
                        {!props.isReadOnly &&
                            ((['S001001', 'S001002'].includes(props.detailData?.prcsStatCd) && props.detailData?.rgstrSeq == user.userSeq)
                                || !props.detailData)
                            &&
                            <button
                                className='bmc-btn confirm p' onClick={fn_registData}>저장
                            </button>
                        }
                    </div>
                </Modal.Footer>

            </Modal>
        </div>
    )
}

export default RskAsmntRgstModal;

