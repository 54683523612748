import React, {useContext, useEffect, useRef, useState} from 'react';
import SubMenuTitle from "../component/SubMenuTitle";
import {Col, Container, Row, Table } from 'react-bootstrap';
import SHSearch from "../component/BMCSearch";
import BMCSelect from "../../components/common/BMCSelect";
import BMCDatePicker2 from "../../components/common/BMCDatePicker2";
import Datatables, {getCheckedItems} from "../component/Datatables";
import BMCIconButton from "../../components/common/BMCIconButton";
import {ProgressContext} from "../../context/Progress";
import {getLoginUser} from "../../helper/SecurityHelper";
import {DateHelper} from "../../helper/DateHelper";
import {toast} from "react-toastify";
import messageCodeDic from "../../util/messageCodeDic";
import restapi from "../../helper/restAPIHelper";
import axios from "axios";
import errorCodeDic from "../../util/errorCodeDic";
import IvstSftyChkModal from "./modal/IvstSftyChkModal";
import IvstSftyChkRsltModal from "./modal/IvstSftyChkRsltModal";
import BMCInput from "../../components/common/BMCInput";

type Props = {};

const IvstSftyChkPage = (props: Props) => {

    const [modalIsOpen, setModalIsOpen] = React.useState(false);
    const searchFormRef = useRef<any>();
    const [datatable, setDatatable] = useState<any>();
    const [detailData, setDetailData] = useState(null);
    const {spinner} = useContext(ProgressContext);
    const user = getLoginUser();
    const prodRef = useRef<any>();
    const [srchStrtDt, setSrchStrtDt] = useState<Date>(DateHelper.dateByMonth(-1));
    const [srchEndDate, setSrchEndDate] = useState<Date>(DateHelper.dateByMonth(1));
    const srchTxtRef = useRef<any>();
    const [data, setData] = useState<any>();
    const [isSaveSuccess, setIsSaveSuccess] = useState(false);
    const [isRsltModalOpen, setIsRsltModalOpen] = useState<boolean>(false);

    useEffect(() => {
        if(!modalIsOpen || !isRsltModalOpen){
            datatable?.draw();
        }
    }, [modalIsOpen, isRsltModalOpen]);

    const fn_search = (e?: any) => {
        if(e){
            e.preventDefault()
        }
        if(datatable){
            datatable.row( this ).remove().draw( false );
        }
    }

    const fn_setFormData = (e: any) => {
        setData({
            ...data,
            [e.target.name]: e.target.value
        })
    }

    const fn_registPopOpen = () => {
        setDetailData(null);
        setModalIsOpen(true);
    }

    const fn_delete = () => {
        let checkedData = getCheckedItems(datatable,"sftyChkSeq")
        if (checkedData.length == 0) {
            toast.warn(messageCodeDic['005']);
            return;
        }
        if(!window.confirm(messageCodeDic['004'])){
            return;
        }
        axios.post('/admin/api/siteSftyManage/deleteSftyWrkMetData', {delList: checkedData}).then(rsltData => {
            if (rsltData.data > 0) {
                toast.success(messageCodeDic['002'])
                fn_search();
            } else {
                toast.error(errorCodeDic['003'])
            }
        }).catch(() => {
            toast.error(errorCodeDic['003'])
        })
    }

    return (
        <Container fluid className="bg-white px-0 bmc-txt-color-primary">
            <SubMenuTitle title={'안전조치 대상 관리'} parentMenu={'안전조치 대상 관리'} />
            <IvstSftyChkModal title={'안전조치 대상 관리 상세'} btnNm={'조치완료'} setModalIsOpen={setModalIsOpen} modalIsOpen={modalIsOpen} detailData={detailData} setIsSaveSuccess={setIsSaveSuccess}/>
            <IvstSftyChkRsltModal setModalIsOpen={setIsRsltModalOpen} modalIsOpen={isRsltModalOpen} detailData={detailData} />
            <div className="p-4 overflow-y-auto" style={{height: 'calc(100vh - 197px)'}}>
                <SHSearch datatable={datatable} formRef={searchFormRef} btnType={'p'}>
                    <BMCInput type={'hidden'} name={'workTypeCd'} value={'T002003'} />
                    <BMCInput type={'hidden'} name={'isSftyAct'} value={'Y'} />

                    <Row>
                        <Col>
                            <Table className="bmc-font-SP bmc-fs-b2 mt-3 mb-3">
                                <tbody>
                                <tr className="border-top border-bottom align-middle">
                                    <th className="bmc-bg-th" style={{width:'10%'}}>이상유무</th>
                                    <td style={{width:'15%'}}>
                                        <BMCSelect initOptionGb={'all'} className={'bmc-select'} codeGb={'cmmn'} codeGrpId={'S017'} name={'ivstPrblmType'}></BMCSelect>
                                    </td>
                                    <th className="bmc-bg-th" style={{width:'10%'}}>기간검색</th>
                                    <td style={{width:'25%'}}>
                                        <div className="d-flex flex-row gap-2">
                                            <div className="d-flex align-items-center" ref={prodRef}>
                                                <BMCDatePicker2 name={'srchStrtDt'} readOnly={true} defaultValue={srchStrtDt} onChange={fn_setFormData} />
                                                <span>&nbsp;~&nbsp;</span>
                                                <BMCDatePicker2 name={'srchEndDt'} readOnly={true} defaultValue={srchEndDate} onChange={fn_setFormData} />
                                            </div>

                                        </div>
                                    </td>
                                    <th className="bmc-bg-th" style={{width:'10%'}}>검색어</th>
                                    <td style={{width:'30%'}}>
                                        <input type={'text'} name={'srchTxt'} className='form-control' ref={srchTxtRef}/>
                                    </td>
                                </tr>
                                </tbody>
                            </Table>
                        </Col>
                    </Row>
                </SHSearch>
                <div className={'ge-result'}>
                    <Datatables
                        dataUrl={'/admin/api/siteSftyManage/retrieveIvstSftyManageDataList'}
                        parameterFormRef={searchFormRef}
                        columns={[
                            {data: "guNm", title: "구", textAlign: 'center',render: (data: any, type: any, row: any) => {
                                    if(!data){
                                        return data;
                                    }else{
                                        let n = data.split(" ");
                                        return n[n.length - 1];
                                    }
                                }
                            },
                            {data: "dongNm", title:"동", textAlign: 'center',render: (data: any, type: any, row: any) => {
                                    if(!data){
                                        return data;
                                    }else{
                                        let n = data.split(" ");
                                        return n[n.length - 1];
                                    }
                                }
                            },
                            {data: "jibun", title: "지번", textAlign: 'center'},
                            {data: "landTypeNm", title: "지목", textAlign: 'center'},
                            {data: "landStatusNm", title: "현황", textAlign: 'center'},
                            {data: "areaRange", title: "공부면적(m<sup>2</sup>)", textAlign: 'center'},
                            {data: "sftyChkDt", title: "점검예정일시", textAlign: 'center', render: (data: any, type: any, row: any) => {
                                    return row.sftyChkDt + "&nbsp;&nbsp;" + row.sftyChkHour + ':' + row.sftyChkMin;
                                }},
                            {data: "ivstSprvDprtGbNm", title: "현장점검", textAlign: 'center'},
                            {data: '', title: '완료처리', textAlign: 'center', render: (data: any, type: any, row: any) => {
                                if(row.ivstSprvDprtGb == 'C007004'){
                                    return '<button class="bg-[#3561eb] hover:bg-blue-700 text-white font-bold py-2 px-4 rounded">결과등록</button>';
                                }else{
                                    return '<span class="text-[#3561eb] text-decoration-underline cursor-pointer">조치완료</span>';
                                }
                            }},
                            /*{data: 'ivstPrblmTypeNm', title: '이상유무', textAlign: 'center'},*/
                        ]}
                        index={true}
                        checked={false}
                        onLoad={(dt: any) => {
                            setDatatable(dt)
                        }}
                        onClick={(data, gb, e) => {
                            data.ivstSprvDprtGb = 'C007005';
                            setDetailData(Object.assign({},data))


                            setModalIsOpen(true);

                        }}
                    >
                    </Datatables>
                </div>
            </div>
        </Container>
    );
};

export default IvstSftyChkPage;
