import React, {useContext, useEffect, useRef, useState} from "react";
import sizeConfigs from "../configs/sizeConfigs";
import colorConfigs from "../configs/colorConfigs";
import {AppBar, Drawer, List, Toolbar} from "@mui/material";
import appRoutes from "../../routes/appRoutes";
import SidebarItemCollapse from "./SidebarItemCollapse";
import SidebarItem from "./SidebarItem";
import {getLoginUser, IS_SM_SITE, logout, setItemWithExpireTime, USER_SESSION} from "../../helper/SecurityHelper";
import {useNavigate} from "react-router-dom";
import {UserContext} from "../../context/User";
import {toast} from "react-toastify";
import axios from "axios";
import restapi from "../../helper/restAPIHelper";
import errorCodeDic from "../../util/errorCodeDic";
import {MenuOpenContext} from "../../context/MenuOpen";
import {Constatnts} from "../../util/Constants";
import RcvNtfyListModal from "../../pages/headoffice/modal/RcvNtfyListModal";
import {ReactComponent as LogoBmcSite} from "../../assets/images/logo/logo_bmc-site.svg";
import {ReactComponent as IconSample} from '../../assets/images/icon/icon-sample.svg';
import {ReactComponent as IconBell} from '../../assets/images/icon/icon-bell.svg';
import {ReactComponent as IconHeaderLine} from '../../assets/images/icon/icon-header-line-v2.svg';
import {ReactComponent as IconHeaderLogout} from '../../assets/images/icon/icon-header-logout-white.svg';
import {ReactComponent as LogoMenubarGsil} from '../../assets/images/logo/logo-menubar-gsil.svg';
import {ProgressContext} from "../../context/Progress";
import PushModal from "../../pages/headoffice/modal/PushModal";
import {PushContext} from "../../context/Push";
import BMCIconButton from "./BMCIconButton";

type Anchor = 'top' | 'left' | 'bottom' | 'right';






const Menubar = () => {
    const navigate = useNavigate();
    const defaultUser: any = getLoginUser();
    const [siteId, setSiteId] = useState<number>(defaultUser?.blngSiteId);
    const [siteCodeList, setSiteCodeList] = useState<any>([]);
    const siteIdRef = useRef<any>();
    const {user,setUser} = useContext<any>(UserContext);
    const {open, setOpen} = useContext(MenuOpenContext);
    const [rcvNtfyDataList, setRcvNtfyDataList] = useState<any>([]);
    const [isNewNtfy, setIsNewNtfy] = useState<boolean>(false);
    const [isRcvNtfyModal, setIsRcvNtfyModal] = useState<boolean>(false);
    const [siteData, setSiteData] = useState<any>({});
    const [wthrInfo, setWthrInfo] = useState<any>({});
    const [date, setDate] = useState(() => new Date());
    const week = ['일요일', '월요일', '화요일', '수요일', '목요일', '금요일', '토요일'];
    let dayOfWeek = week[date.getDay()];
    const [pm10Grade, setPm10Grade] = useState<string|null>(null);
    const {spinner} = useContext(ProgressContext);
    const {push, setPush} = useContext<any>(PushContext);
    const [msgCnt, setMsgCnt] = useState<number>(0);


    useEffect(() => {
        /*const pathname = window.location.pathname;
        if(pathname === '/' || pathname.indexOf("monitoring") > -1){
            setOpen(false)
        }else{
            setOpen(true)
        }*/
    }, [])

    useEffect(() => {
        restapi.retrieveWtherInfoData(siteId).then((rsltData) => {
            setWthrInfo(rsltData);
        })
        restapi.retrieveSiteDataListNoPaging({siteId: siteId}).then((rsltData: any) => {
            if(rsltData.length > 0){
                setSiteData(rsltData[0]);
                localStorage.setItem(IS_SM_SITE, rsltData[0].isSmSite);

                if(rsltData[0].isSmSite){
                    navigate('/smSite/monitoring');
                }else{
                    let pathname = window.location.pathname

                    if(pathname.indexOf('/smSite/') > -1){
                        pathname = pathname.substring(8);
                    }

                    navigate(pathname);
                }
            }


        })

    }, [siteId]);

    useEffect(() => {

        fn_retrieveSiteCodeList().then((siteCodeList: any) => {
            setSiteCodeList(siteCodeList);

            if (siteCodeList.length == 0) {
                toast.warn('배정된 현장이 없습니다.');
                return;
            }


        })
    }, [])

    useEffect(() => {
        fn_retrieveSiteRcvNtfyDataList(user?.blngSiteId || defaultUser.blngSiteId);
    }, [isRcvNtfyModal]);




    const fn_retrieveSiteRcvNtfyDataList = (siteId: number) => {
        restapi.retrieveSiteRcvNtfyDataList({userSeq: defaultUser.userSeq, blngCmpnySeq: defaultUser.blngCmpnySeq, siteId:siteId}).then((rsltData: any) => {
            setRcvNtfyDataList(rsltData);
            let flag = false;
            rsltData.map((data: any) => {
                if(!data.isRead){
                    flag = true;
                }
            })
            setIsNewNtfy(flag)

        })
    }

    const fn_onSiteChange = (e: any) => {
        console.log('siteChange');
        const siteId = e.target.value;
        fn_retrieveSiteRcvNtfyDataList(siteId);
        setSiteId(siteId);
        restapi.changeCurrentUserSite({userSeq:defaultUser.userSeq, siteId:siteId, isRepSiteChg:true}).then((rsltCnt: any) => {
            if (rsltCnt > 0) {
                restapi.getUserSession(defaultUser.userSeq).then((data: any) => {
                    setUser(data);
                    setItemWithExpireTime(USER_SESSION, data);
                });
            }else{
                toast.error(errorCodeDic['003']);
            }

        });
        console.log('siteId: ', siteId);

    }


    const fn_retrieveSiteCodeList = async () => {
        return new Promise(function (resolve, reject) {
            let param:any = {}
            if(defaultUser.authGrpId == 'SITE'){
                let siteIdList: any = [];
                const authChrgDataList = defaultUser.authChrgDataList;
                authChrgDataList.map((data: any) => {
                    siteIdList.push(data.siteId);
                })
                param.siteIdList = siteIdList;
            }

            if(param.siteIdList?.length > 0 || defaultUser.authGrpId == 'HEAD'){
                axios.post('/admin/api/site/retrieveSiteCodeList', param).then((result) => {
                    resolve(result.data);
                })
            }else{
                resolve([]);
            }

        });
    }

    const fn_openRcvNtfyPop = () => {
        setIsRcvNtfyModal(true);
    }

    const fn_moveToHeadWeb = () => {
        spinner.start();
        restapi.changeUserSiteHeadWebStat({blngSiteId: null, userSeq: defaultUser.userSeq}).then((rsltCnt: any) => {
            if (rsltCnt > 0) {
                restapi.getUserSession(defaultUser.userSeq).then((data: any) => {
                    setUser(data);
                    setItemWithExpireTime(USER_SESSION, data);
                    navigate('/')
                });
            }else{
                toast.error(errorCodeDic['003']);
            }
        }).catch(error => {
            toast.error(errorCodeDic['003']);
            console.log('error: ', error);
        })
            .finally(() => {
                spinner.stop();
            });
    }

    /*function fn_clickMenu(){
        setOpen(!open)
    }*/

    function fn_clickHome(){
        navigate("/")
    }

  return (
    <React.Fragment>
        <PushModal setModalIsOpen={setPush} modalIsOpen={push}></PushModal>
        <RcvNtfyListModal setModalIsOpen={setIsRcvNtfyModal} modalIsOpen={isRcvNtfyModal} dataList={rcvNtfyDataList} siteId={user?.blngSiteId || defaultUser.blngSiteId}/>
        <AppBar
            position="fixed"
            sx={{
                width: `100%`,
                pl: sizeConfigs.sidebar.width,
                boxShadow: "unset",
                backgroundColor: colorConfigs.topbar.bg,
                color: colorConfigs.topbar.color
            }}
        >
            <Toolbar className={"text-white"}>
                <div className="d-flex flex-row justify-content-between w-100">
                    <div className="d-flex">
                        <select className='bmc-menu-site-header-title-v2 site' name={'siteId'} disabled={defaultUser.authGrpId == 'HEAD'} ref={siteIdRef}
                                onChange={fn_onSiteChange} value={siteId}>
                            {
                                siteCodeList.length > 0 ? (
                                    siteCodeList.map((data: any, idx: number) => (
                                        <option key={idx} value={data.siteId}>{data.siteName}</option>
                                    ))
                                ) : (
                                    <option key={1} value={'test'}>배정된 현장 없음</option>
                                )
                            }
                        </select>
                        {
                            defaultUser.authGrpId == 'HEAD' &&
                                <BMCIconButton className={"ml-5"} kind={''} onClick={fn_moveToHeadWeb}>본사 웹</BMCIconButton>
                        }
                    </div>

                    <div className="d-flex">
                        <span className="toolbar-system-title">현장용 안전관리 플랫폼</span>
                    </div>
                    <div className="d-flex flex-row align-items-center gap-2 px-4">
                        <div className="d-flex align-items-center justify-content-center gap-2">
                            <IconSample width={20} height={20} />
                            <span className="site-header-user-name" style={{maxWidth:"100px",textOverflow:"ellipsis", overflow:"hidden", whiteSpace:"nowrap"}} title={defaultUser?.userName}>{defaultUser?.userName}</span>
                        </div>
                        <IconHeaderLine width={15} height={15} />
                        <div className="d-flex align-items-center justify-content-center relative cursor-pointer" onClick={fn_openRcvNtfyPop}>
                            <IconBell width={15} height={15} />
                            <span className="site-header-bell-count"></span>
                        </div>
                        <IconHeaderLine width={15} height={15} />
                        <div className="site-header-logout d-flex align-items-center justify-content-center cursor-pointer">
                            <IconHeaderLogout width={24} height={24} onClick={logout}/>
                        </div>
                    </div>
                </div>
            </Toolbar>
        </AppBar>
        <Drawer
            variant="permanent"
            anchor={"left"}
            style={(open ? {width:"220px"} : {width:"220px"})}
            sx={{
                overflow:"hidden",
                flexShrink: 0,
                zIndex: 9999,
                "& .MuiDrawer-paper": {
                    position: "relative",
                    width: sizeConfigs.sidebar.width,
                    boxSizing: "border-box",
                    borderRight: "0px",
                    backgroundColor: "#1F2771",
                    color: colorConfigs.sidebar.color
                }
            }}
        >
            <div className="d-flex px-15">
                <LogoBmcSite width={220} height={100} onClick={fn_clickHome} />
            </div>
            <div className={"mt-4"} style={{height:"calc(100vh - 170px)", overflowY:"auto"}}>
                <List className={"sh-menu-scroll shss-font-SD site-sidebar-menu "} onClick={()=>{setOpen(true)}}>
                    {defaultUser &&
                        appRoutes.map((route, index) => {
                            if(defaultUser.companyGbCd === 'C001005'){
                                if(route.child){
                                    route.child = route.child.filter((data: any) => (!Constatnts.bldrAuthMenuList.includes(data.state)))
                                }
                            }
                            return (
                                route.sidebarProps ? (
                                    route.child ? (
                                        <SidebarItemCollapse item={route} key={index} />
                                    ) : (
                                        <SidebarItem item={route} key={index} isTopLevel={true}/>
                                    )
                                ) : null
                            )})
                    }
                </List>
            </div>
            <div className="align-self-center">
                <LogoMenubarGsil height={28}/>
            </div>
        </Drawer>
    </React.Fragment>
  );
};

export default Menubar;
