import React, {useContext, useEffect, useRef, useState} from 'react';
import axios from "axios";
import messageCodeDic from "../../util/messageCodeDic";
import errorCodeDic from "../../util/errorCodeDic";
import {Col, Container, Row, Table} from 'react-bootstrap';
import Datatables, {getCheckedItems} from "../component/Datatables";
import {toast, ToastContainer} from "react-toastify";
import SHSearch from "../component/BMCSearch";
import SubMenuTitle from "../component/SubMenuTitle";
import BMCIconButton from '../../components/common/BMCIconButton';
import BMCSelect from "../../components/common/BMCSelect";
import Modal from "react-modal";
import EmployeeRgstModal from "./modal/EmployeeRgstModal";
import {getLoginUser} from "../../helper/SecurityHelper";
import {UserContext} from "../../context/User";
import BMCInput from "../../components/common/BMCInput";
import {ProgressContext} from "../../context/Progress";
import DocFormUploadModal from '../headoffice/modal/DocFormUploadModal';

type Props = {};

Modal.setAppElement('#root');

const EmployeeManagementPage = (props: Props) => {
    const [modalIsOpen, setModalIsOpen] = React.useState(false);
    const [datatable, setDatatable] = useState<any>();
    const [detailData, setDetailData] = useState(null);
    const [cmpnyCodeList, setCmpnyCodeList] = useState<any>([]);
    const [workTypeCdList, setwWrkTypeCdList] = useState<any>([]);
    const [ocptCdList, setOcptCdList] = useState<any>([]);
    const [commCodeData, setCommCodeData] = useState<any>()
    const [isSaveSuccess, setIsSaveSuccess] = useState(false);
    const searchFormRef = useRef<any>();
    const cmpnySeqRef = useRef<any>();
    const workTypeCdRef = useRef<any>();
    const ocptCdRef = useRef<any>();
    const srchTxtRef = useRef<any>();
    const defaultUser = getLoginUser();
    const [data, setData] = useState<any>({});
    const {user} = useContext<any>(UserContext);
    const {process} = useContext<any>(ProgressContext);
    const [isCodeDataLoaded, setIsCodeDataLoaded] = useState<boolean>(false);
    const [isExcelFormOpen, setIsExcelFormOpen] = useState<boolean>(false);
    const fileRef = useRef<any>();
    const {spinner} = useContext(ProgressContext);
    const formRef = React.createRef<HTMLFormElement>();

    const fn_setSrchData = (e: any) => {
        setData({
            ...data,
            [e.target.name]: e.target.value
        })
    }
    useEffect(()=>{

        if(datatable){
            fn_retrieveSrchCond(user?.blngSiteId || defaultUser.blngSiteId)
            datatable.draw();
        }

    }, [user, datatable])
    useEffect(() => {
        fn_search();
    }, [process])

    useEffect(() => {
        if(isCodeDataLoaded){
            datatable?.draw();
        }

    }, [isCodeDataLoaded])

    const fn_retrieveSrchCond = (siteId: number) => {
        //공종조회
        fn_retrievesetConstructionItemList(defaultUser.blngSiteId).then((rlstData) => {
            setwWrkTypeCdList(rlstData);
        })
        //직종조회
        fn_retrievesetOccupationItemList(defaultUser.blngSiteId).then((rlstData) => {
            setOcptCdList(rlstData);
        })
    }


    const fn_search = (e?: any) => {
        if (e) {
            e.preventDefault()
        }
        datatable?.draw()
    }

    /**
     * 등록 버튼 클릭
     */
    const fn_registPopOpen = () => {
        setDetailData(null);
        setModalIsOpen(true);
    }

    const fn_delete = () => {
        let checkedData = getCheckedItems(datatable, "userSeq")
        if (checkedData.length == 0) {
            toast.warn(messageCodeDic['005']);
            return;
        }
        if (!window.confirm(messageCodeDic['004'])) {
            return;
        }
        axios.post('/admin/api/employee/deleteEmployeeData', {delList: checkedData}).then(rsltData => {
            if (rsltData.data > 0) {
                toast.success(messageCodeDic['002'])
                fn_search();
            } else {
                toast.error(errorCodeDic['003'])
            }
        }).catch(() => {
            toast.error(errorCodeDic['003'])
        })
    }

    const fn_updateViewCnt = (param: object) => {
        axios.post('/admin/api/notice/updateViewCnt', param)
    }

    /**
     * 현장 Onclick event
     */
    const fn_onSiteChange = (e: any) => {
        //업체조회
        fn_retrieveCmpnySiteDataList(e.target.value).then((rsltData) => {
            setCmpnyCodeList(rsltData);
        });
        //공종조회
        fn_retrievesetConstructionItemList(e.target.value).then((rlstData) => {
            setwWrkTypeCdList(rlstData);
        })
        //직종조회
        fn_retrievesetOccupationItemList(e.target.value).then((rlstData) => {
            setOcptCdList(rlstData);
        })

        datatable.draw();
    }

    /**
     * 업체 Onclick event
     */
    const fn_onSearchCondChange = (e: any) => {
        datatable.draw();
    }

    /**
     * 현장 콤보박스 조회
     */
    const fn_retrieveSiteCodeList = async () => {
        return new Promise(function (resolve, reject) {
            let param: any = {}

            if (defaultUser.authGrpId == 'SITE') {
                let siteIdList: any = [];
                const authChrgDataList = defaultUser.authChrgDataList;
                authChrgDataList.map((data: any) => {
                    siteIdList.push(data.siteId);
                })
                param.siteIdList = siteIdList;
            }
            axios.post('/admin/api/site/retrieveSiteCodeList', param).then((result) => {
                resolve(result.data);
            })
        });
    }

    /**
     * 업체 콥보박스 조회
     */
    const fn_retrieveCmpnySiteDataList = async (siteId: string) => {
        return new Promise(function (resolve, reject) {
            axios.post('/admin/api/company/retrieveCmpnySiteDataList', {
                siteId: siteId
            }).then((result) => {
                resolve(result.data);
            })
        });
    }

    /**
     * 공종 콤보박스 조회
     */
    const fn_retrievesetConstructionItemList = (siteId: string) => {
        return new Promise(function (resolve, reject) {

            axios.post('/admin/api/manage/retrieveSiteManageItemList', {
                siteId: siteId,
                manageCode: 'M001001'
            }).then((result) => {
                resolve(result.data);
            })
        });
    }

    /**
     * 직종 콤보박스 조회
     */
    const fn_retrievesetOccupationItemList = (siteId: string) => {
        return new Promise(function (resolve, reject) {
            axios.post('/admin/api/manage/retrieveSiteManageItemList', {
                siteId: siteId,
                manageCode: 'M001002'
            }).then((result) => {
                resolve(result.data);
            })
        });
    }

    /**
     * 국가 공통코드 콤보박스 조회
     */
    const fn_setCommCodeData = (e: any) => {
        setCommCodeData({
            ...commCodeData,
            [e.target.name]: e.target.value
        })
    }

    const fn_addFile = (e: any) => {

        if(defaultUser.authGrpId !== 'SITE'){
            toast.warn('현장 관리자만 사용할 수 있는 기능 입니다.');
            return;
        }

        e.preventDefault();

        console.log('e.target.value: ', e.target.value);
        console.log('e.target.files: ', e.target.files);
        if (!e.target.files) {
            return;
        }

        const param = {siteId:defaultUser?.blngSiteId};
        // @ts-ignore
        var formData: any = new FormData(formRef.current);
        formData.set('excelFile', e.target.files[0]);
        formData.set('jsonData', new Blob([JSON.stringify(param)], {type: "application/json"}));

        spinner.start();
        axios.post('/admin/api/employee/uploadExcelData', formData).then((result) => {

            const rsltCnt = result.data.rsltCnt;
            const duplId = result.data.duplId;
            console.log('result: ', result);
            if (rsltCnt > 0) {
                datatable?.draw();
                toast.success(messageCodeDic['001'])

            } else {
                toast.error(result.data.errMsg);

            }
        }).catch(error => {
            toast.error(errorCodeDic['003']);
            console.log('error: ', error);
        })
            .finally(() => {
                spinner.stop();
            });


    }


    return (
        <Container fluid className="bg-white px-0 bmc-txt-color-primary">
            <SubMenuTitle title={'근로자 관리'} parentMenu={'인력 관리'}/>
            <EmployeeRgstModal setModalIsOpen={setModalIsOpen} modalIsOpen={modalIsOpen}
                               siteId={user?.blngSiteId || defaultUser.blngSiteId}
                               detailData={detailData} setIsSaveSuccess={setIsSaveSuccess}/>
            <DocFormUploadModal formCd={'D003003'} isReadOnly={true} setModalIsOpen={setIsExcelFormOpen} modalIsOpen={isExcelFormOpen} />
            <form name={'form'} ref={formRef} encType="multipart/form-data" method={"post"}></form>
            <div className="p-4 overflow-y-auto" style={{height: 'calc(100vh - 180px)'}}>
                <SHSearch datatable={datatable} formRef={searchFormRef} btnType={'p'}>
                    <Row>
                        <Col>
                            <Table className="bmc-font-SP bmc-fs-b2 my-3">
                                <tbody>
                                <tr className="border-top border-bottom align-middle">

                                    <th className="bmc-bg-th">업체</th>
                                    <td>
                                        <BMCInput type={'hidden'} name={'siteId'} value={user?.blngSiteId || defaultUser.blngSiteId}></BMCInput>
                                        <BMCSelect name={'blngCmpnySeq'} onChange={fn_setSrchData} value={data.blngCmpnySeq} setIsCodeDataLoaded={setIsCodeDataLoaded} initOptionGb={'all'} codeGb={'siteCmpny'} selParam={{siteId:user?.blngSiteId || defaultUser.blngSiteId}}></BMCSelect>
                                    </td>
                                    <th className="bmc-bg-th">공종</th>
                                    <td>
                                        <select className='form-select' name={'workTypeCd'} ref={workTypeCdRef}
                                                onChange={fn_setSrchData}>
                                            <option value=''>전체</option>
                                            {workTypeCdList.map((data: any, idx: number) => (
                                                <option key={idx} value={data.manageSn}>{data.manageName}</option>
                                            ))}
                                        </select>
                                    </td>
                                    <th className="bmc-bg-th">직종</th>
                                    <td>
                                        <select className='form-select' name={'ocptCd'} ref={ocptCdRef}
                                                onChange={fn_setSrchData}>
                                            <option value=''>전체</option>
                                            {ocptCdList.map((data: any, idx: number) => (
                                                <option key={idx} value={data.manageSn}>{data.manageName}</option>
                                            ))}
                                        </select>
                                    </td>
                                </tr>
                                <tr className="border-bottom align-middle">
                                    <th className="bmc-bg-th">국적</th>
                                    <td>
                                        <BMCSelect initOptionGb={'all'} name={'nationId'} codeGb={'nation'} value={data?.nationId}
                                                   onChange={fn_setSrchData}
                                        ></BMCSelect>
                                    </td>
                                    <th className="bmc-bg-th">특이사항</th>
                                    <td>
                                        <select className='form-select' name={'sgnfcntYn'}
                                                onChange={fn_setSrchData}>
                                            <option value=''>전체</option>
                                            <option value='N'>없음</option>
                                            <option value='Y'>있음</option>
                                        </select>
                                    </td>
                                    <th className="bmc-bg-th">검색어</th>
                                    <td colSpan={3}>
                                        <input type={'text'} name={'srchTxt'} className='form-control' ref={srchTxtRef}/>
                                    </td>
                                </tr>
                                </tbody>
                            </Table>
                        </Col>
                    </Row>
                </SHSearch>
                <div className={'ge-result'}>
                    <Datatables
                        dataUrl={'/admin/api/employee/retrieveEmployeeDataList'}
                        parameterFormRef={searchFormRef}
                        columns={[
                            {data: "userName", title: "성명", textAlign: 'center'},
                            {data: "userId", title: "ID", textAlign: 'center'},
                            {data: "userPhone", title: "전화번호", textAlign: 'center'},
                            {data: "companyName", title: "업체", textAlign: 'center'},
                            {data: "workTypeNm", title: "공종", textAlign: 'center'},
                            {data: "ocptNm", title: "직종", textAlign: 'center'},
                            {data: "nationNm", title: "국적", textAlign: 'center'},
                            {data: "regDate", title: "등록일", orderColumn: "REG_DATE", orderBase: true},
                            {data: "isSgnfcnt", title: "특이사항", render: (data: any, type: any, row: any) => {
                                    if(row.isSgnfcnt){
                                        return row.sgnfcnt
                                    }else{
                                        return 'N'
                                    }
                                }
                            },
                        ]}

                        checked={true}
                        index={true}

                        onLoad={(dt: any) => {
                            setDatatable(dt)
                        }}

                        onClick={(data) => {
                            setDetailData(data)
                            setModalIsOpen(true);
                            fn_updateViewCnt(data);
                        }}
                    >
                        <div className={'w-100 d-flex justify-content-between'}>
                            <div className='w-100 d-flex justify-content-left align-items-center mb-2'>
                                <input className={'hidden'}  id="input-file" type={"file"} ref={fileRef} onChange={fn_addFile} />
                                <label htmlFor="input-file" className={'bmc-btn normal p cursor-pointer'} onClick={(e: any) => {


                                    fileRef.current.value = null}}> 엑셀 업로드
                                </label>
                                <button className={'ml-5 bmc-btn normal p cursor-pointer'} onClick={() => setIsExcelFormOpen(true)} >업로드 양식 다운로드</button>

                            </div>
                            <div className='w-100 d-flex justify-content-end align-items-center mb-2'>
                                <BMCIconButton kind={'submit'} marginRight={'5px'} onClick={fn_registPopOpen}>등록</BMCIconButton>
                                <BMCIconButton kind={'delete'} marginRight={'0'} onClick={fn_delete}> 삭제
                                </BMCIconButton>
                            </div>
                        </div>
                    </Datatables>
                </div>
            </div>
        </Container>
    );
};

export default EmployeeManagementPage;
