import {Modal, Table} from "react-bootstrap";
import {IoMdClose} from "react-icons/io";
import iconCalendar from "../../../images/icon-calendar-black.svg";
import CustomDatePickerRange from "../../component/ui/DatePickerRange";
import React, {Dispatch, SetStateAction, useContext, useEffect, useRef, useState} from "react";
import {fn_getCurrentDate} from "../../../helper/CmmnHelper";
import {getLoginUser} from "../../../helper/SecurityHelper";
import {Col, Row} from "react-bootstrap";
import {Container} from "@mui/material";
import {COMMON_MODAL_STYLE, COMMON_MULTISEL_STRINGS} from "../../../helper/StyleHelper";
import Select from 'react-select'
import {MultiSelect} from "react-multi-select-component";
import axios from "axios";
import {toast} from "react-toastify";
import messageCodeDic from "../../../util/messageCodeDic";
import errorCodeDic from "../../../util/errorCodeDic";
import BMCInput from "../../../components/common/BMCInput";
import BMCTextarea from "../../../components/common/BMCTextarea";
import BMCSelect from "../../../components/common/BMCSelect";
import CustomDatePicker from "../../component/ui/DatePicker";
import SiteSearchModal from "../../companyManagement/modal/SiteSearchModal";
import DriverSrchModal from "./DriverSrchModal";
import BMCDatePicker from "../../../components/common/BMCDatePicker";
import restapi from "../../../helper/restAPIHelper";
import {ProgressContext} from "../../../context/Progress";


interface IProps {
    setModalIsOpen: Dispatch<SetStateAction<boolean>>;
    modalIsOpen: boolean;
    detailData: any;
    siteId: number;
}

const EqmntRgstModal = (props: IProps) => {

    const inspctProdRef = useRef<any>();
    const isrncProdRef = useRef<any>();
    const currentDate = fn_getCurrentDate();
    const user = getLoginUser();

    const [eqmntIspctStrtDt, setEqmntIspctStrtDt] = useState<string>('');
    const [eqmntIspctEndDt, setEqmntIspctEndDt] = useState<string>('');
    const [isIspctDatePopOpen, setIsIspctDatePopOpen] = useState(false);

    const [eqmntIsrncStrtDt, setEqmntIsrncStrtDt] = useState<string>('');
    const [eqmntIsrncEndDt, setEqmntIsrncEndDt] = useState<string>('');
    const [isIsrncDatePopOpen, setIsIsrncDatePopOpen] = useState(false);

    const [data, setData] = useState<any>()
    const [isDriverPopOpend, setIsDriverPopOpend] = React.useState<boolean>(false);
    const [driverData, setDriverData] = useState<any>({});
    const [isMainDriver, setIsMainDriver] = useState<boolean>(false);
    const [selected, setSelected] = useState([])
    const [eqmntChkList, setEqmntChkList] = useState<any>([]);
    const {spinner} = useContext(ProgressContext);
    const fn_setFormData = (e: any) => {
        setData({
            ...data,
            [e.target.name]: e.target.value
        })
    }

    useEffect(() => {
        if (driverData) {
            console.log('driverData:', driverData)
            if (isMainDriver) {
                setData({
                    ...data,
                    driverNm: driverData.userName,
                    driverSeq: driverData.userSeq,
                    driverPhone: driverData.userPhone,
                    driverLcncNo: driverData.driverLcncNo
                })
            } else {
                setData({
                    ...data,
                    rotDriverSeq: driverData.userSeq,
                    rotDriverNm: driverData.userName
                })
            }


        }
    }, [driverData])

    function afterOpenModal() {
        // 신규
        if (!props.detailData) {
            console.log('siteId: ', props.siteId);
            setData({siteId: props.siteId})
            setEqmntIspctStrtDt('');
            setEqmntIspctEndDt('');
            setEqmntIsrncStrtDt('');
            setEqmntIsrncEndDt('');
            setEqmntChkList([]);
            setSelected([]);

            // 상세
        } else {
            setData(props.detailData);
            console.log('props.detailData:', props.detailData);
            setEqmntIspctStrtDt(props.detailData.eqmntIspctStrtDt || '');
            setEqmntIspctEndDt(props.detailData.eqmntIspctEndDt || '');
            setEqmntIsrncStrtDt(props.detailData.eqmntIsrncStrtDt || '');
            setEqmntIsrncEndDt(props.detailData.eqmntIsrncEndDt || '');
            fn_retrieveChkDataList(props.detailData.eqmntCd);
            axios.post('/admin/api/eqmnt/retrieveEqmntChkData', {eqmntId: props.detailData.eqmntId}).then((result) => {

                let dataList: any = [];
                result.data.map((item: any) => {
                    dataList.push({label:item.chkCntnts, value: item.chkSeq})
                })
                setSelected(dataList);

                console.log('selRslt: ', result);
            })
        }
    }

    function fn_closeModal() {
        props.setModalIsOpen(false);
    }

    const fn_ispctDatePopupOpen = () => {
        setIsIspctDatePopOpen(!isIspctDatePopOpen);
    };

    const fn_isrncDatePopupOpen = () => {
        setIsIsrncDatePopOpen(!isIsrncDatePopOpen);
    };

    const submit = (e: any) => {
        e.preventDefault()

    }

    const fn_registData = () => {

        if (!data) {
            toast.warn('데이터를 입력해 주십시오.')
            return;
        }

        if (!data.cmpnySeq) {
            toast.warn('업체를 선택해 주십시오.')
            return;
        }

        if (!data.eqmntCd) {
            toast.warn('장비명을 선택해 주십시오.');
            return;
        }

        if (!data.rntCmpnyNm) {
            toast.warn('임대업체명을 입력해 주십시오.');
            return;
        }

        if (!data.eqmntRgstNo) {
            toast.warn('장비 등록번호를 입력해 주십시오.');
            return;
        }

        if(!data.driverSeq) {
            toast.warn('원전원을 선택해 주십시오.');
            return;
        }

        if(!data.rotDriverSeq){
            toast.warn('장비교대운전원을 선택해 주십시오.');
            return;
        }

        if(selected.length == 0){
            toast.warn('사용 전 장비점검 질문을 선택해 주십시오.');
            return;
        }



        let param = data;
        param.rgstrSeq = user.userSeq;
        param.updtrSeq = user.userSeq;
        param.eqmntIspctStrtDt = eqmntIspctStrtDt;
        param.eqmntIspctEndDt = eqmntIspctEndDt;
        param.eqmntIsrncStrtDt = eqmntIsrncStrtDt;
        param.eqmntIsrncEndDt = eqmntIsrncEndDt;

        let chkSeqList:number[] = [];

        selected.map((data: any) => {
            chkSeqList.push(data.value);
        })
        param.chkSeqList = chkSeqList;

        console.log('param: ', param);
        if(!window.confirm('저장 하시겠습니까?')){
            return;
        }

        spinner.start();
        axios.post('/admin/api/eqmnt/saveEqmntData', param).then((result) => {

            if (result.data > 0) {
                toast.success(messageCodeDic['001'])
                props.setModalIsOpen(false);
            } else {
                toast.error(errorCodeDic['003']);
            }
        }).catch(error => {
            toast.error(errorCodeDic['003']);
            console.log('error: ', error);
        })
        .finally(() => {
            spinner.stop();
        });

    }

    const fn_openDriverSrchPop = (flag: boolean) => {

        if (!data.cmpnySeq) {
            toast.warn('업체를 먼저 선택해 주십시오.');
            return;
        }
        setIsMainDriver(flag);
        setIsDriverPopOpend(true);
    }

    const fn_retrieveChkDataList = (eqmntCd:number) => {

        restapi.retrieveEqmntChkList({siteId:props.siteId, eqmntCd:eqmntCd}).then((rsltData) => {
            const rsltEqmntChkList: object[] = []
            rsltData.map((data: any) => {
                rsltEqmntChkList.push({label: data.chkCntnts, value:data.chkSeq})
            })

            setEqmntChkList(rsltEqmntChkList);
        })

    }

    return (
        <div>
            <DriverSrchModal setModalIsOpen={setIsDriverPopOpend} modalIsOpen={isDriverPopOpend} driverData={driverData}
                             isSingleCheck={true}
                             setDriverData={setDriverData} siteId={data?.siteId} cmpnySeq={data?.cmpnySeq}/>
            <Modal
                className="bmc-modal" size="xl"
                show={props.modalIsOpen}
                onShow={afterOpenModal}
                onHide={fn_closeModal}
            >
                <Modal.Header>
                    <div className="d-flex justify-content-between align-items-center w-100 bmc-font-SD">
                        반입 전 장비 등록/수정
                        <IoMdClose className='hover:cursor-pointer' onClick={fn_closeModal}/>
                    </div>
                </Modal.Header>
                <Modal.Body className="px-4">
                    <form name={'form'}
                          onSubmit={submit}>
                        <div className="bmc-modal-div">
                            <div className="div-header">
                                장비정보
                            </div>
                            <div className="div-body px-3">
                                <Table className="my-2 align-middle">
                                    <tbody>
                                    <tr className="border-top align-middle">
                                        <th className="bmc-bg-th" style={{width: '15%'}}>현장</th>
                                        <td style={{width: '35%'}}>
                                            <BMCSelect initOptionGb={'none'} disabled={true} codeGb={'site'}
                                                      onChange={fn_setFormData} className={'form-control'}
                                                      value={data?.siteId}></BMCSelect>
                                        </td>
                                        <th className="bmc-bg-th" style={{width: '15%'}}>업체</th>
                                        <td style={{width: '35%'}}>
                                            <BMCSelect initOptionGb={'sel'} disabled={data?.driverSeq ? true : false}
                                                      name={'cmpnySeq'} codeGb={'siteCmpny'} className={'w-100'}
                                                      onChange={fn_setFormData} selParam={{siteId: props.siteId}}
                                                      value={data?.cmpnySeq}></BMCSelect>
                                        </td>
                                    </tr>
                                    <tr className="border-top align-middle">
                                        <th className="bmc-bg-th" style={{width: '15%'}}>장비명</th>
                                        <td style={{width: '35%'}}>
                                            <BMCSelect initOptionGb={'sel'} name={'eqmntCd'} className={'w-100'} codeGb={'siteMngCd'}
                                                      onChange={(e) => {fn_setFormData(e); fn_retrieveChkDataList(e.target.value); setSelected([])}} selParam={{
                                                siteId: props.siteId,
                                                manageCode: 'M001005'
                                            }} value={data?.eqmntCd}></BMCSelect>
                                        </td>
                                        <th className="bmc-bg-th" style={{width: '15%'}}>임대 업체</th>
                                        <td style={{width: '35%'}}>
                                            <BMCInput type="text" className="form-control" name={'rntCmpnyNm'}
                                                     onChange={fn_setFormData} value={data?.rntCmpnyNm}/>
                                        </td>
                                    </tr>
                                    <tr className="border-top align-middle">
                                        <th className="bmc-bg-th" style={{width: '15%'}}>장비 등록번호</th>
                                        <td style={{width: '35%'}}>
                                            <BMCInput type="text" className="form-control" name={'eqmntRgstNo'}
                                                     onChange={fn_setFormData} value={data?.eqmntRgstNo}/>
                                        </td>
                                        <th className="bmc-bg-th" style={{width: '15%'}}>장비 규격</th>
                                        <td style={{width: '35%'}}>
                                            <div className="d-flex align-items-center w-100">
                                            <BMCInput type="number" className="form-control" name={'eqmntWght'}
                                                     onChange={fn_setFormData} value={data?.eqmntWght}/>
                                            <span className={'ml-5'}>t(톤)</span>
                                            </div>
                                        </td>
                                    </tr>
                                    <tr className="border-top align-middle">
                                        <th className="bmc-bg-th" style={{width: '15%'}}>장비 검사기간</th>
                                        <td style={{width: '35%'}}>
                                            <div className="d-flex align-items-center w-100" ref={inspctProdRef}>
                                                <BMCDatePicker
                                                    className={"form-control cursor-pointer"} onClick={fn_ispctDatePopupOpen}
                                                    value={eqmntIspctStrtDt} name={'noticeStartDate'} onChange={fn_setFormData}
                                                    calendar={fn_ispctDatePopupOpen}
                                                    readOnly={true}/>
                                                <span>&nbsp;~&nbsp;</span>
                                                <BMCDatePicker name={'noticeEndDate'}
                                                               className={"form-control cursor-pointer"} onClick={fn_ispctDatePopupOpen}
                                                               calendar={fn_ispctDatePopupOpen}
                                                               value={eqmntIspctEndDt} onChange={fn_setFormData} readOnly={true}/>


                                                {isIspctDatePopOpen
                                                    ? <div className='absolute top-33 left-80'>
                                                        <CustomDatePickerRange setIsDatePopupOpen={setIsIspctDatePopOpen}
                                                                               prodRef={inspctProdRef}
                                                                               setStartDateView={setEqmntIspctStrtDt}
                                                                               setEndDateView={setEqmntIspctEndDt}/>
                                                    </div>
                                                    : null}
                                            </div>
                                        </td>
                                        <th className="bmc-bg-th" style={{width: '15%'}}>보험기간</th>
                                        <td style={{width: '35%'}}>
                                            <div className="d-flex align-items-center w-100" ref={isrncProdRef}>
                                                <BMCDatePicker
                                                    className={"form-control cursor-pointer"} onClick={fn_isrncDatePopupOpen}
                                                    value={eqmntIsrncStrtDt} name={'noticeStartDate'} onChange={fn_setFormData}
                                                    calendar={fn_isrncDatePopupOpen}
                                                    readOnly={true}/>
                                                <span>&nbsp;~&nbsp;</span>
                                                <BMCDatePicker name={'noticeEndDate'}
                                                               className={"form-control cursor-pointer"} onClick={fn_isrncDatePopupOpen}
                                                               calendar={fn_isrncDatePopupOpen}
                                                               value={eqmntIsrncEndDt} onChange={fn_setFormData} readOnly={true}/>

                                                {isIsrncDatePopOpen
                                                    ? <div className='absolute top-33 left-75'>
                                                        <CustomDatePickerRange setIsDatePopupOpen={setIsIsrncDatePopOpen}
                                                                               prodRef={isrncProdRef}
                                                                               setStartDateView={setEqmntIsrncStrtDt}
                                                                               setEndDateView={setEqmntIsrncEndDt}/>
                                                    </div>
                                                    : null}
                                            </div>
                                        </td>
                                    </tr>
                                    <tr className="border-top align-middle">
                                        <th className="bmc-bg-th" style={{width: '15%'}}>운전원</th>
                                        <td style={{width: '35%'}}>
                                            <div className="d-flex align-items-center w-100">
                                                <BMCInput type="text" disabled={true} className="form-control" name={'driverNm'}
                                                         onChange={fn_setFormData} value={data?.driverNm}/>
                                                <BMCInput type="hidden" className="form-control" name={'driverSeq'}
                                                         onChange={fn_setFormData} value={data?.driverSeq}/>
                                                <button className="bmc-btn btn-secondary min-w-[70px] ml-5" type="button" onClick={() => fn_openDriverSrchPop(true)}>선택
                                                </button>

                                            </div>
                                        </td>
                                        <th className="bmc-bg-th" style={{width: '15%'}}>운전원 연락처</th>
                                        <td style={{width: '35%'}}>
                                            <BMCInput type="text" disabled={true} className="form-control" name={'driverPhone'}
                                                     onChange={fn_setFormData} value={data?.driverPhone}/>
                                        </td>
                                    </tr>
                                    <tr className="border-top align-middle">
                                        <th className="bmc-bg-th" style={{width: '15%'}}>운전원 면허번호</th>
                                        <td style={{width: '35%'}}>
                                            <BMCInput type="text" disabled={true} className="form-control" name={'driverLcncNo'}
                                                     onChange={fn_setFormData} value={data?.driverLcncNo}/>
                                        </td>
                                        <th className="bmc-bg-th" style={{width: '15%'}}>장비교대운전원</th>
                                        <td style={{width: '35%'}}>
                                            <div className="d-flex align-items-center w-100">
                                                <BMCInput type="text" disabled={true} className="form-control" name={'rotDriverNm'}
                                                         onChange={fn_setFormData} value={data?.rotDriverNm}/>
                                                <BMCInput type="hidden" disabled={true} className="form-control"
                                                         name={'rotDriverSeq'}
                                                         onChange={fn_setFormData} value={data?.rotDriverSeq}/>

                                                <button className="bmc-btn btn-secondary min-w-[70px] ml-5" type="button" onClick={() => fn_openDriverSrchPop(false)}>선택
                                                </button>
                                            </div>
                                        </td>
                                    </tr>
                                    <tr className="border-top align-middle">
                                        <th className="bmc-bg-th" style={{width: '15%'}}>사용 전 장비점검 질문</th>
                                        <td style={{width: '85%', maxWidth:'100px'}} colSpan={3}>
                                            <MultiSelect className={'form-control'}
                                                         options={eqmntChkList}
                                                         value={selected}
                                                         onChange={setSelected}
                                                         labelledBy={"사용 전 장비점검 질문"}
                                                         isCreatable={false}
                                                         overrideStrings={COMMON_MULTISEL_STRINGS}
                                            />
                                        </td>
                                    </tr>
                                    </tbody>
                                </Table>
                            </div>
                        </div>

                        <div className="bmc-modal-div">
                            <div className="div-header">
                                작성정보
                            </div>
                            <div className="div-body px-3">
                                <Table className="my-2 align-middle">
                                    <tbody>
                                    <tr className="border-top align-middle">
                                        <th className="bmc-bg-th" style={{width: '15%'}}>작성일</th>
                                        <td style={{width: '35%'}}>
                                            <input value={data?.rgstDt || currentDate}
                                                   className={"form-control"}
                                                   disabled={true}/>
                                        </td>
                                        <th className="bmc-bg-th" style={{width: '15%'}}>작성자</th>
                                        <td style={{width: '35%'}}>
                                            <input className={"form-control"}
                                                   value={data?.rgstrNm || user.userName} disabled={true}/>
                                        </td>
                                    </tr>
                                    </tbody>
                                </Table>
                            </div>
                        </div>

                    </form>
                </Modal.Body>
                <Modal.Footer className="py-2">
                    <div className='flex justify-center w-100'>
                        <button
                            className='bmc-btn pill l white me-3'
                            onClick={fn_closeModal}>닫기
                        </button>
                        <button onClick={fn_registData}
                                className='bmc-btn pill p'>등록/수정

                        </button>
                    </div>
                </Modal.Footer>

            </Modal>
        </div>
    )
}

export default EqmntRgstModal;

