import React, {Dispatch, SetStateAction, useContext, useEffect, useRef, useState} from "react";
import {Modal, Table} from "react-bootstrap";
import {IoMdClose} from "react-icons/io";
import BMCInput from "../../../components/common/BMCInput";
import BMCTextarea from "../../../components/common/BMCTextarea";
import BMCFile from "../../../components/common/BMCFile";
import restapi from "../../../helper/restAPIHelper";
import {ProgressContext} from "../../../context/Progress";
import {getLoginUser} from "../../../helper/SecurityHelper";
import BMCSelect from "../../../components/common/BMCSelect";
import BMCDatePicker from "../../../components/common/BMCDatePicker";
import CustomDatePickerRange from "../../component/ui/DatePickerRange";
import BMCCheckbox from "../../../components/common/BMCCheckbox";
import {toast} from "react-toastify";
import axios from "axios";
import messageCodeDic from "../../../util/messageCodeDic";
import errorCodeDic from "../../../util/errorCodeDic";
import {COMMON_MULTISEL_STRINGS} from "../../../helper/StyleHelper";
import {MultiSelect} from "react-multi-select-component";

interface IProps{
    setModalIsOpen: Dispatch<SetStateAction<boolean>>;
    modalIsOpen: boolean;
    detailData: any;
}

const SafetyNotificationManagementModal = (props: IProps) => {

    const [data, setData] = useState<any>();
    const [repFileSn, setRepFileSn] = useState<any>(null);
    const [fileList, setFileList] = useState([]);
    const formRef = React.createRef<HTMLFormElement>();
    const {spinner} = useContext(ProgressContext);
    const defaultUser: any = getLoginUser();
    const prodRef = useRef<any>();
    const [startDate, setStartDate] = useState<string>('');
    const [endDate, setEndDate] = useState<string>('');
    const [isDatePopupOpen, setIsDatePopupOpen] = useState(false);
    const [refSidoList, setRefSidoList] = useState<any>([]);
    const [selectedSidoList, setSelectedSidoList] = useState([]);
    const [refSiteList, setRefSiteList] = useState<any>([]);
    const [selectedSiteList, setSelectedSiteList] = useState([])
    const user = getLoginUser();

    useEffect(() =>{
        let pushSiteData: object[] = [];

        restapi.retrieveSiteDataListNoPaging({siteId:null, siteTypeList: ['T002001', 'T002002', 'T002004', 'T002005']}).then((rsltData) => {
            rsltData.map((data:any) =>{
                pushSiteData.push({
                    label:data.siteName, value: data.siteId
                });
            });
        });
        // @ts-ignore
        setRefSiteList(pushSiteData);

        let pushSidoData: object[] = [];
        restapi.retrieveBjdDataList('BUSAN').then((rsltData) => {
            rsltData.map((data:any) =>{
                pushSidoData.push({
                    label:data.codeNm, value: data.code
                });
            });
        });
        // @ts-ignore
        setRefSidoList(pushSidoData);

    },[])

    const fn_updateData = (e: any) => {
        if (e.target.type == 'checkbox') {
            setData((item: any) =>{
                return {...item, [e.target.name]: e.target.checked}
            });
        } else {
            setData((item: any) =>{
                return {...item, [e.target.name]: e.target.value}
            });
        }
    }
    const fn_openModal = () => {
        if(!props.detailData){
            setFileList([]);
            setData(null);
            setStartDate('');
            setEndDate('');
            setSelectedSidoList([]);
            setSelectedSiteList([]);
        }else{
            setData(props.detailData);
            setStartDate(props.detailData.noticeStartDate)
            setEndDate(props.detailData.noticeEndDate)
            if(props.detailData.atchFileId){
                restapi.retrieveAttachInfoList({attachSeq: props.detailData.atchFileId}).then((rsltData: any) => {
                    setFileList([].concat(rsltData));
                })
            }else{
                setFileList([]);
            }

            fn_getReceiveDataList(props.detailData.sftyNtfctnMngId).then((rsltData) => {
                let selectedlist : any = [];
                // @ts-ignore
                rsltData.map((data:any) => {
                    if(props.detailData.receiveTypeCd == 'S006002'){
                        selectedlist.push({label : data.label, value: parseInt(data.value)});
                    }else if(props.detailData.receiveTypeCd == 'S006003'){
                        selectedlist.push({label : data.label, value: data.value});
                    }
                })
                if(props.detailData.receiveTypeCd == 'S006002'){
                    setSelectedSiteList(selectedlist)
                }else if(props.detailData.receiveTypeCd == 'S006003'){
                    setSelectedSidoList(selectedlist)
                }
            })
        }
    }

    const fn_getReceiveDataList = async (sftyNtfctnMngId : number) =>{
        return new Promise(function (resolve, reject) {
            axios.post('/admin/api/sftyNtfctnMng/retrieveSftyNtfctnReceiveDataList', {
                sftyNtfctnMngId: sftyNtfctnMngId
            }).then((result) => {
                resolve(result.data);
            })
        });
    }

    const fn_closeModal = () => {
        props.setModalIsOpen(false);
    }

    const fn_saveData = () =>{
        if (!data) {
            toast.warn('데이터를 입력해 주십시오.')
            return;
        }

        if (!data.receiveTypeCd) {
            toast.warn('수신구분을 선택해 주십시오.')
            return;
        }

        if (data.receiveTypeCd == 'S006002') {//현장별 선택 확인
            if(selectedSiteList.length == 0){
                toast.warn('현장을 선택해 주십시오.')
                return;
            }
        }

        if (data.receiveTypeCd == 'S006003') {//지역별 선택 확인
            if(selectedSidoList.length == 0){
                toast.warn('지역을 선택해 주십시오.')
                return;
            }
        }

        if(!data.isAllProd){
            if (!startDate || !endDate) {
                toast.warn('공지기간을 입력해 주십시오.')
                return;
            }
        }

        if (!data.title) {
            toast.warn('제목을 입력해 주십시오.')
            return;
        }

        if (!data.contents) {
            toast.warn('내용을 입력해 주십시오.')
            return;
        }

        let param = data;
        // @ts-ignore
        var formData: any = new FormData(formRef.current)

        if(fileList.length > 0){
            for(var i =0;i<fileList.length;i++){
                formData.append('atchFileList',fileList[i]);
            }
        }


        let saveSelectedList: any[] = [];
        if(data.receiveTypeCd == 'S006002'){
            selectedSiteList.map((data: any) => {
                saveSelectedList.push({label:data.label, value:data.value});
            })
        }

        if (data.receiveTypeCd == 'S006003') {
            selectedSidoList.map((data: any) => {
                saveSelectedList.push({label:data.label, value:data.value});
            })
        }
        param["selectedList"] = saveSelectedList;

        param.noticeStartDate = startDate;
        param.noticeEndDate = endDate;
        param.rgstrSeq = defaultUser.userSeq;
        param.rgstCmpnySeq = defaultUser.blngCmpnySeq;
        param.updtrSeq = defaultUser.userSeq;
        formData.set('sftyNtfctnMngVO', new Blob([JSON.stringify(param)], {type: "application/json"}))

        if (formData) {
            if (!window.confirm('저장 하시겠습니까?')) {
                return;
            }

            spinner.start();
            axios.post('/admin/api/sftyNtfctnMng/saveSftyNtfctnMngData', formData).then((result) => {
                if (result.data > 0) {
                    toast.success(messageCodeDic['001'])
                    props.setModalIsOpen(false);
                } else {
                    toast.error(errorCodeDic['003']);
                }
            }).catch(error => {
                toast.error(errorCodeDic['003']);
                console.log('error: ', error);
            }).finally(() => {
                spinner.stop();
            });
        }
    }

    const submit = (e: any) => {
        e.preventDefault()
    }

    const fn_datePopupOpen = () => {
        setIsDatePopupOpen(!isDatePopupOpen);
    };

    const fn_isAllProdChg = (e: any) => {
        if(e.target.value){
            setStartDate('');
            setEndDate('');
        }
    }

    return (
        <div>
            <Modal
                className="bmc-modal" size="xl"
                show={props.modalIsOpen}
                onShow={fn_openModal}
                onHide={fn_closeModal}
            >

                <Modal.Header>
                    <div className="d-flex justify-content-between align-items-center w-100 bmc-font-SD">
                        안전공지관리 등록/수정
                        <IoMdClose className='hover:cursor-pointer' onClick={fn_closeModal}/>
                    </div>
                </Modal.Header>
                <Modal.Body className="px-4">
                    <form name={'detailForm'} ref={formRef} encType="multipart/form-data" method={"post"} onSubmit={submit}>
                        <BMCInput type={'hidden'} value={data?.atchFileId} name={'atchFileId'}></BMCInput>
                        <BMCInput type={'hidden'} value={data?.sftyMngBrdId} name={'sftyMngBrdId'}></BMCInput>
                        <div className="bmc-modal-div">
                            <div className="div-header">
                                수신자 정보
                            </div>
                            <div className="div-body px-3">
                                <Table className="my-2 align-middle" style={{tableLayout:"fixed"}}>
                                    <tbody>
                                    <tr className="border-top align-middle">
                                        <th className="bmc-bg-th" style={{width: '15%'}}>수신구분</th>
                                        <td style={{width: '35%'}}>
                                            <BMCSelect initOptionGb={'sel'} className={'w-100'} name={'receiveTypeCd'} codeGrpId={'S006'}
                                                      onChange={fn_updateData}
                                                      value={data?.receiveTypeCd}
                                                      disabled={data?.sftyNtfctnMngId?true:false}
                                            ></BMCSelect>
                                        </td>
                                        <td></td>
                                        <td></td>
                                    </tr>
                                    <tr>
                                        <th className="bmc-bg-th" style={{width: '15%'}}>수신대상</th>
                                        <td colSpan={3} style={{width: '85%'}}>
                                            {
                                                data?.receiveTypeCd == '' || data?.receiveTypeCd == null ? <span>수신구분을 선택해주세요.</span>
                                                    :data?.receiveTypeCd == 'S006001'?
                                                        <span>모든 대상에게 알림</span>
                                                        :data?.receiveTypeCd == 'S006002'?
                                                            <MultiSelect className={'form-control'}
                                                                         options={refSiteList}
                                                                         value={selectedSiteList}
                                                                         disabled={data?.sftyNtfctnMngId?true:false}
                                                                         onChange={setSelectedSiteList}
                                                                         labelledBy={"참조 선택"}
                                                                         isCreatable={false}
                                                                         overrideStrings={COMMON_MULTISEL_STRINGS}

                                                            />
                                                        :<MultiSelect className={'form-control'}
                                                                      options={refSidoList}
                                                                      value={selectedSidoList}
                                                                      disabled={data?.sftyNtfctnMngId?true:false}
                                                                      onChange={setSelectedSidoList}
                                                                      labelledBy={"참조 선택"}
                                                                      isCreatable={false}
                                                                      overrideStrings={COMMON_MULTISEL_STRINGS}

                                                            />
                                            }
                                        </td>
                                    </tr>
                                    </tbody>
                                </Table>
                            </div>
                        </div>
                        <div className="bmc-modal-div">
                            <div className="div-header">
                                안전공지 정보
                            </div>
                            <div className="div-body px-3">
                                <Table className="my-2 align-middle">
                                    <tbody>
                                    <tr className="border-top align-middle">
                                        <th className="bmc-bg-th" style={{width: '15%'}}>작성자</th>
                                        <td style={{width: '35%'}}>
                                            <BMCInput type="text" className="form-control" name={'userName'}
                                                     onChange={fn_updateData}
                                                     value={data?.userName ? data?.userName : defaultUser.userName}
                                                     disabled={true}/>
                                        </td>
                                        <th className="bmc-bg-th" style={{width: '15%'}}>작성일시</th>
                                        <td style={{width: '35%'}}>
                                            <BMCInput type="text" className="form-control" name={'rgstDt'}
                                                     onChange={fn_updateData} value={data?.rgstDt} disabled={true}/>
                                        </td>
                                    </tr>
                                    <tr className="border-top align-middle">
                                        <th className="bmc-bg-th" style={{width: '15%'}}>공지기간</th>
                                        <td style={{width: '35%'}}>
                                            <div className="d-flex align-items-center w-100" ref={prodRef}>
                                                <BMCDatePicker
                                                    className={"text-center"} onClick={fn_datePopupOpen} calendar={fn_datePopupOpen}
                                                    value={startDate} disabled={data?.isAllProd || false} name={'noticeStartDate'} onChange={fn_updateData}
                                                    readOnly={true}/>
                                                <span>&nbsp;~&nbsp;</span>
                                                <BMCDatePicker name={'noticeEndDate'} calendar={fn_datePopupOpen}
                                                               className={"text-center"}
                                                               onClick={fn_datePopupOpen}
                                                               value={endDate} disabled={data?.isAllProd || false} onChange={fn_updateData} readOnly={true}/>

                                                {(isDatePopupOpen && !data?.isAllProd)
                                                    ? <CustomDatePickerRange setIsDatePopupOpen={setIsDatePopupOpen}
                                                                             prodRef={prodRef}
                                                                             setStartDateView={setStartDate}
                                                                             setEndDateView={setEndDate}/>
                                                    : null}
                                                <BMCCheckbox name={'isAllProd'} onChange={(e: any) => {fn_updateData(e); fn_isAllProdChg(e);}}
                                                            checked={data?.isAllProd} className={'ml-5 w-[110px]'}>전체</BMCCheckbox>
                                            </div>
                                        </td>

                                    </tr>
                                    <tr className="border-top align-middle">
                                        <th className="bmc-bg-th" style={{width: '15%'}}>제목</th>
                                        <td colSpan={3}>
                                            <div className="d-flex gap-2">
                                                <div className="d-flex align-items-center flex-grow-1">
                                                    <BMCInput type="text" className="form-control" name={'title'}
                                                             onChange={fn_updateData} value={data?.title} placeholder={'제목을 입력해주세요.'}/>
                                                </div>
                                                <div className="d-flex align-items-center gap-1 w-[80px]">
                                                    <BMCCheckbox name={'isFixedTop'} onChange={fn_updateData}
                                                                checked={data?.isFixedTop} className={'ml-5 w-[110px]'}>상단고정</BMCCheckbox>
                                                </div>
                                            </div>
                                        </td>
                                    </tr>
                                    <tr className="border-top align-middle">
                                        <th className="bmc-bg-th" style={{width: '15%'}}>내용</th>
                                        <td style={{width: '85%'}} colSpan={3}>
                                            <BMCTextarea rows={5} onChange={fn_updateData} name={'contents'}
                                                        value={data?.contents}
                                                        className={"form-control"}
                                                        placeholder='내용을 입력해주세요.'/>
                                        </td>
                                    </tr>
                                    <tr className="align-middle" style={{width: '15%'}}>
                                        <th className="bmc-bg-th">첨부파일</th>
                                        <td colSpan={3}>
                                            <BMCFile fileName={'files'} fileList={fileList} setFileList={setFileList}></BMCFile>
                                        </td>
                                    </tr>
                                    </tbody>
                                </Table>
                            </div>
                        </div>
                    </form>
                </Modal.Body>

                <Modal.Footer className="py-2">
                    <div className='flex justify-center w-100'>
                        <button
                            className='bmc-btn pill l white me-3'
                            onClick={fn_closeModal}>닫기
                        </button>
                        {user.authCode != 'headRead' &&
                        <button onClick={fn_saveData}
                                className='bmc-btn pill p'>등록/수정
                        </button>
                        }
                    </div>
                </Modal.Footer>

            </Modal>
        </div>
    )
}

export default SafetyNotificationManagementModal;
