export const Formatter = {
    regNumFormat: (val: any) => {
        if (val === '') return '';
        const txtLeng = val.length;

        const prefix = val.substring(0, 3);
        const midfix = val.substring(3, 5);
        const suffix = val.substring(5,10);

        if(txtLeng < 4){
            return `${prefix}`;
        }else if(txtLeng >= 4 && txtLeng < 6){
            return `${prefix}-${midfix}`;
        }else{
            return `${prefix}-${midfix}-${suffix}`;
        }
    },

    phoneNumFormat: (val: any) => {
        if (val === '') return '';
        const txtLeng = val.length;

        const prefix = val.substring(0, 3);
        const midfix = val.substring(3, 7);
        const suffix = val.substring(7,11);

        if(txtLeng < 4){
            return `${prefix}`;
        }else if(txtLeng >= 4 && txtLeng < 8){
            return `${prefix}-${midfix}`;
        }else{
            return `${prefix}-${midfix}-${suffix}`;
        }
    }
}
