import React, {useContext, useEffect, useRef, useState} from 'react';
import axios from "axios";
import Datatables, {getCheckedItems} from "../component/Datatables";
import Modal from 'react-modal';
import {getLoginUser} from "../../helper/SecurityHelper";
import {toast} from "react-toastify";
import messageCodeDic from "../../util/messageCodeDic";
import errorCodeDic from "../../util/errorCodeDic";
import MSDSRgstModal from "./modal/MSDSRgstModal"
import {Col, Container, Row, Table} from 'react-bootstrap';
import SubMenuTitle from "../component/SubMenuTitle";
import SHSearch from '../component/BMCSearch';
import BMCSelect from "../../components/common/BMCSelect";
import BMCIconButton from '../../components/common/BMCIconButton';
import BMCDatePicker from "../../components/common/BMCDatePicker";
import CustomDatePickerRange from "../component/ui/DatePickerRange";
import {UserContext} from "../../context/User";
import BMCInput from "../../components/common/BMCInput";
type Props = {};

Modal.setAppElement('#root');
const MSDSManagementPage = (props: Props) => {
    const [modalIsOpen, setModalIsOpen] = React.useState(false);
    const [detailData, setDetailData] = useState(null);
    const [isSaveSuccess, setIsSaveSuccess] = useState(false);
    const defaultUser = getLoginUser();
    const srchTxtRef = useRef<any>();
    const [srchData, setSrchData] = useState<any>()
    const searchFormRef = useRef<any>();
    const [datatable, setDatatable] = useState<any>();
    const [isDatePopupOpen, setIsDatePopupOpen] = useState(false);
    const prodRef = useRef<any>();
    const [startDate, setStartDate] = useState<string>('');
    const [endDate, setEndDate] = useState<string>('');
    const [data, setData] = useState<any>();
    const [isCodeDataLoaded, setIsCodeDataLoaded] = useState<boolean>(false);
    const {user} = useContext<any>(UserContext);

    useEffect(()=>{

        if(datatable){
            datatable.draw();
        }

    }, [user, datatable])

    useEffect(() => {
        if (!modalIsOpen) {
            datatable?.draw();
        }
    }, [modalIsOpen])

    useEffect(() => {
        if(isCodeDataLoaded){
            datatable?.draw();
        }

    }, [isCodeDataLoaded])


    const fn_setSearchData = (e: any) => {
        setSrchData({
            ...srchData,
            [e.target.name]: e.target.value
        })
    }

    const fn_setFormData = (e: any) => {
        setData({
            ...data,
            [e.target.name]: e.target.value
        })
    }

    const fn_search = (e?: any) => {
        if (e) {
            e.preventDefault()
        }
        datatable.draw();
        setIsSaveSuccess(false);
    }

    const fn_delete = () => {
        let checkedData = getCheckedItems(datatable,"msdsId")
        if (checkedData.length == 0) {
            toast.warn(messageCodeDic['005']);
            return;
        }
        if(!window.confirm(messageCodeDic['004'])){
            return;
        }
        axios.post('/admin/api/msds/deleteMsdsData', {delList: checkedData}).then(rsltData => {
            if (rsltData.data > 0) {
                toast.success(messageCodeDic['002'])
                fn_search();
            } else {
                toast.error(errorCodeDic['003'])
            }
        }).catch(() => {
            toast.error(errorCodeDic['003'])
        })
    }

    const fn_registPopOpen = () => {
        setDetailData(null);
        setModalIsOpen(true);
    }

    const fn_datePopupOpen = () => {
        setIsDatePopupOpen(!isDatePopupOpen);
    };

    const fn_updateViewCnt = (param: object) => {
        axios.post('/admin/api/notice/updateViewCnt', param)
    }

    return (
        <Container fluid className="bg-white px-0 bmc-txt-color-primary">
            <SubMenuTitle title={'MSDS 관리'} parentMenu={'현장안전 관리'}/>
            <MSDSRgstModal setModalIsOpen={setModalIsOpen} modalIsOpen={modalIsOpen} siteId={user?.blngSiteId || defaultUser.blngSiteId}
                           detailData={detailData} setIsSaveSuccess={setIsSaveSuccess}/>
            <div className="p-4 overflow-y-auto" style={{height: 'calc(100vh - 180px)'}}>
                <SHSearch datatable={datatable} formRef={searchFormRef} btnType={'p'}>
                    <Row>
                        <Col>
                            <Table className="bmc-font-SP bmc-fs-b2 my-3">
                                <tbody>
                                <tr className="border-top border-bottom align-middle">
                                    <th className="bmc-bg-th" style={{width: '6%'}}>업체</th>
                                    <td style={{width: '19%'}}>
                                        <BMCInput type={'hidden'} name={'siteId'} value={user?.blngSiteId || defaultUser.blngSiteId}></BMCInput>
                                        <BMCSelect initOptionGb={'all'} className={"w-100"} name={'cmpnySeq'} codeGb={'siteCmpny'}
                                                   onChange={fn_setSearchData} setIsCodeDataLoaded={setIsCodeDataLoaded} selParam={{siteId: defaultUser.blngSiteId}}
                                                   value={srchData?.cmpnySeq}></BMCSelect>
                                    </td>
                                    <th className="bmc-bg-th" style={{width:'6%'}}>구분</th>
                                    <td style={{width:'19%'}}>
                                        <BMCSelect initOptionGb={'all'} name={'msdsGbCd'} codeGb={'cmmn'} codeGrpId={'M002'}
                                                   onChange={fn_setSearchData}
                                                   value={srchData?.msdsGbCd}></BMCSelect>
                                    </td>
                                </tr>
                                <tr className="border-top border-bottom align-middle">
                                    <th className="bmc-bg-th" style={{width:'6%'}}>기간</th>
                                    <td style={{width:'25%'}}>
                                        <div className="d-flex align-items-center w-100" ref={prodRef}>
                                            <BMCDatePicker readOnly={true}
                                                           className={"text-center"}
                                                           value={startDate} name={'startRgstDt'} onClick={fn_datePopupOpen}
                                                           onChange={fn_setFormData}
                                                           calendar={fn_datePopupOpen}
                                            />
                                            <span>&nbsp;~&nbsp;</span>
                                            <BMCDatePicker readOnly={true}
                                                           className={"text-center"}
                                                           value={endDate} name={'endRgstDt'} onClick={fn_datePopupOpen}
                                                           onChange={fn_setFormData}
                                                           calendar={fn_datePopupOpen}
                                            />
                                            {isDatePopupOpen
                                                ? <CustomDatePickerRange setIsDatePopupOpen={setIsDatePopupOpen}
                                                                         prodRef={prodRef}
                                                                         setStartDateView={setStartDate}
                                                                         setEndDateView={setEndDate}/>
                                                : null}
                                        </div>
                                    </td>
                                    <th className="bmc-bg-th" style={{width:'6%'}}>검색어</th>
                                    <td colSpan={3}>
                                        <input type={'text'} className='form-control' ref={srchTxtRef}/>
                                    </td>
                                </tr>
                                </tbody>
                            </Table>
                        </Col>
                    </Row>
                </SHSearch>
                <div className='w-100 d-flex justify-content-end align-items-center mb-2'>

                    <BMCIconButton kind={'submit'} marginRight={'5px'} onClick={fn_registPopOpen}>등록</BMCIconButton>

                    <BMCIconButton kind={'delete'} marginRight={'0'} onClick={fn_delete}>삭제</BMCIconButton>
                </div>
                <div className={'ge-result'}>
                    <Datatables
                        dataUrl={'/admin/api/msds/retrieveMsdsDataList'}
                        parameterFormRef={searchFormRef}
                        columns={[
                            {data: "msdsGbNm", title: "구분"},
                            {data: "productName", title: "제품명", textAlign: 'left'},
                            {data: "companyName", title: "업체", textAlign: 'left'},
                            {data: "rgstrNm", title: "작성자"},
                            {data: "rgstDt", title: "작성일", orderColumn:"REG_DATE", orderBase:true},
                        ]}
                        checked={true}
                        index={true}

                        onLoad={(dt: any) => {
                            setDatatable(dt)
                        }}

                        onClick={(data) => {
                            setDetailData(data)
                            setModalIsOpen(true);
                            fn_updateViewCnt(data);
                        }}
                    />
                </div>
            </div>
        </Container>
    );
};

export default MSDSManagementPage;
