import React, {useEffect, useState} from "react";
import {Container, Table} from "react-bootstrap";
import {ReactComponent as IconRight} from "../../assets/images/ico/icon-right.svg";
import restapi from "../../helper/restAPIHelper";
import FcltySftyChkModal from "../fclty/modal/FcltySftyChkModal";


export default function FcltyHistoryNotice(){
    const [dataList, setDataList] = useState<any>([]);
    const [isModalOpen, setIsModalOpen] = useState<boolean>(false);
    const [detailData, setDetailData] = useState<any>({});

    useEffect(() => {
        restapi.retrieveIvstSftyManageTotDataList({workTypeCd: 'T002004', isHist:true, isEfctAsc: true}).then((rsltData: any) => {
            console.log('thisWeek: ', rsltData);
            setDataList(rsltData);
        });
    }, []);

    const fn_openDtlModal = (data: any) => {
        setDetailData(data);
        setIsModalOpen(true);
    }

    return(
        <>
            <FcltySftyChkModal setModalIsOpen={setIsModalOpen} modalIsOpen={isModalOpen} detailData={detailData}/>
            <Container fluid className="bmc-bg-pane">
                <div className="d-flex align-items-center justify-content-between pb-2 border-bottom-v2 mb-2">
                    <span className="bmc-area-title-v3">안전 점검 및 정밀 안전 진단 이력</span>
                    <div className={'cursor-pointer'}>
                        <IconRight style={{width: '20px', marginBottom: '1px', stroke: '#1d1b39'}} />
                    </div>
                </div>
                <div className="d-flex px-0">
                    <Table className="w-full fclty-notice-table">
                        <thead className="text-center w-full bmc-fs-b2">
                        <tr className={'w-full'}>
                            <th className={'w-[7%] text-center fclty-notice-table-th-left'}>번호</th>
                            <th className={'w-[25%] text-center'}>구분</th>
                            <th className={'w-[15%] text-center'}>시행일</th>
                            <th className={'w-[25%] text-center'}>예산(천원)</th>
                            <th className={'w-[18%] text-center'}>진단점검자</th>
                        </tr>
                        </thead>
                        <tbody className="text-center w-full bmc-fs-b3 w-full">
                        {  dataList.length > 0 ?
                            dataList.map((data: any, idx: number) => (
                            idx < 5 &&
                            <tr className={'w-full cursor-pointer'} key={idx} onClick={() => fn_openDtlModal(data)}>
                                <td className={'text-center'}>{idx + 1}</td>
                                <td className={'text-center'}>{data.sftyDgnsGbNm}</td>
                                <td className={'text-center'}>{data.efctDt}</td>
                                <td className={'text-end'}>{data.budget.toLocaleString()}</td>
                                <td className={'text-center'}>{data.sftyChkRsltRgstrNm}</td>
                            </tr>
                        )) : <tr className={'w-full'}>
                                <td className={'text-center'} colSpan={5}>등록된 안전 점검 계획이 없습니다.</td>
                            </tr>

                        }

                        </tbody>
                    </Table>
                </div>
            </Container>
        </>
    )
}
