import React, {useContext, useEffect, useState} from "react";
import {Container, Row, Col, Table} from "react-bootstrap";
import {ReactComponent as IconRight} from "../../assets/images/ico/icon-right.svg";
import {ReactComponent as IconTeam} from "../../assets/images/ico/icon-team.svg";
import {ReactComponent as IconNoData} from "../../assets/images/icon/icon-folder.svg";
import PlanGongDuk from "../../assets/images/plan_gongduk.png";
import {UserContext} from "../../context/User";
import {getLoginUser} from "../../helper/SecurityHelper";
import {CmmnFn} from "../../helper/CmmnHelper";
import restapi from "../../helper/restAPIHelper";
import {useNavigate} from "react-router-dom";

const DashZoneInformation = () => {
    const {user} = useContext<any>(UserContext);
    const defaultUser = getLoginUser();
    const [siteData, setSiteData] = useState<any>({});
    const [totCnt, setTotCnt] = useState<number>(0);
    const [dataList, setDataList] = useState<any>([]);
    const navigate = useNavigate();

    useEffect(() => {
        const siteId = user?.blngSiteId || defaultUser.blngSiteId;
        // 현장 대표 사진 표출
        restapi.retrieveSiteDataListNoPaging({siteId:siteId}).then((rsltData: any) => {
            setSiteData(rsltData[0]);
            console.log('site rsltData: ', rsltData);
        })

        // 구역별 총인원 조회
        restapi.retrieveDprtWrkStatDataList({siteId:siteId}).then((rsltData: any) => {
            let totCnt = 0;
            console.log('dprt rsltData: ', rsltData);
            rsltData.map((data: any) => {
                totCnt += data.wrkrCnt;
            })

            setTotCnt(totCnt);
            setDataList(rsltData);

        })
    }, [user])

    return(
        <>
            <Container fluid className="pt-4 px-0 bmc-txt-color-primary">
                <Row className="px-0">
                    <Col className="w-100">
                        <div className="w-100 d-flex align-items-center justify-content-between pb-2">
                            <span className="pb-2 site-title-name">구역 세부 정보</span>
                            {/*<div className={'cursor-pointer'} onClick={() => navigate('/manPowerManagement/WorkSectionStatusManagement')}>
                                <IconRight style={{width: '20px', marginBottom: '1px', stroke: '#1d1b39'}} />
                            </div>*/}
                        </div>
                    </Col>
                </Row>
                <div className="rounded-[5px] text-white">
                    <Row>
                        <Col className="d-flex align-items-center">
                            <div className="position-relative w-100" style={{overflow:'hidden',height:'calc(50vh - 100px)', width: '100%'}}>


                                    <div className={'rounded-[5px]'}
                                         style={{width:"100%", height: "100%",
                                             backgroundSize: "cover",
                                             backgroundPosition: "center",
                                             backgroundRepeat:"no-repeat",
                                             opacity: siteData?.atchFileId ? 1 : 0.5,
                                             backgroundImage:"url("+(
                                                 siteData?.atchFileId ?
                                                 process.env.REACT_APP_FILE_URL + "/bmcss-data/fileDownload?attachSeq=" + siteData?.atchFileId + '&sn=' + siteData?.repPhotoSn + '&isPdf=0&time=' + new Date().getTime()
                                                     :'/bg/bg_login_01_.jpg'

                                             )+")"}}>
                                    </div>

                                <div className={"position-absolute rounded-[5px] p-2"+ (siteData?.atchFileId ? " d-block" : ' d-none')} style={{backgroundColor:'rgba(0,0,0,0.7)',top:'10px',right:'10px'}}>
                                    <div className="d-flex justify-content-between align-items-center p-2 border-bottom">
                                        <IconTeam className="bmc-fill-primary" style={{width:'30px'}} />
                                        <div className="text-end bmc-fs-b3 bmcss-fw-5 bmc-lh-b1">
                                            <div>구역별 총 인원</div>
                                            <div>
                                                <span className="bmc-text-primary bmc-fs-b1 bmc-fw-6 me-1">{totCnt}</span>
                                                <span className="bmc-fs-b3">명</span>
                                            </div>
                                        </div>
                                    </div>
                                    <Table className="m-0 bmc-text-white bmc-fs-b4" borderless={true}>
                                        <tbody>
                                        {dataList.length > 0 ? (
                                            dataList.map((data: any, idx: number) => (
                                                <tr key={idx}>
                                                    <td className="pt-1 pb-0">{data.wrkPlcNm}</td>
                                                    <td className="pt-1 pb-0 text-end bmc-text-primary">{data.wrkrCnt}</td>
                                                </tr>
                                            ))

                                        ) : (
                                            <tr>
                                                <td className="pt-1 pb-0">설정된 작업구역이 없습니다.</td>
                                            </tr>
                                        )

                                        }
                                        </tbody>
                                    </Table>
                                </div>
                            </div>
                        </Col>
                    </Row>
                </div>
            </Container>
        </>
    );
};

export default DashZoneInformation;

