import React, {Component, Dispatch, SetStateAction, useEffect} from 'react';
import {
    MDBContainer,
    MDBCol,
    MDBTreeview,
    MDBTreeviewList,
    MDBTreeviewItem
} from 'mdbreact';

interface IProps {
    treeData: any;
    setlvl3Code: any;
    setlvl3CodeNm: any;
}
export default function BMCTreeView (props: IProps){
    useEffect(() => {
        console.log('treeData: ', props.treeData);
    }, [props.treeData]);

        return (
            <MDBContainer header='Animated' className={'ml-0 pl-0 w-100 pe-0'}>
                <MDBCol>
                    <MDBTreeview
                        theme='animated'
                        className='max-h-[565px] bmc-text-font_secondary01 bmc-fs-b3 bmc-fw-5 overflow-y-auto bmc-tree-view border-0 bmc-font-SP'
                    >
                        {props.treeData && props.treeData.lvl2DataList.length > 0 &&
                            props.treeData.lvl2DataList.map((lvl2Data:any, parIdx:number) => (
                                <MDBTreeviewList className={'py-2 border-bottom'} key={parIdx} title={lvl2Data.code + '  (' + lvl2Data.codeName + ')'} far open>
                                    {
                                        props.treeData.lvl3DataList.map((lvl3Data:any, idx:number) => (
                                            lvl2Data.code == lvl3Data.code.substring(0,3) && <MDBTreeviewItem key={idx} className={'ps-5 py-1'} title={lvl3Data.code + '  (' + lvl3Data.codeName + ')'} onClick={() => {
                                                console.log('code: ', lvl3Data.code)
                                                props.setlvl3Code(lvl3Data.code);
                                                props.setlvl3CodeNm(lvl3Data.codeName);
                                            }} far/>

                                        ))

                                    }

                                </MDBTreeviewList>
                            ))
                        }


                    </MDBTreeview>
                </MDBCol>
            </MDBContainer>
        );
}

