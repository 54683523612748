import React, {useContext, useEffect, useState} from 'react';
import {Container} from "react-bootstrap";
import {ReactComponent as IconIvstCards} from '../../assets/images/icon/icon-ivst-cards.svg';
import restapi from '../../helper/restAPIHelper';
import {toast} from "react-toastify";
import errorCodeDic from "../../util/errorCodeDic";
import {ProgressContext} from "../../context/Progress";


export default function IvstTtlChkSttsInfo(){
    const {spinner} = useContext(ProgressContext);
    const [ivstSiteDataList, setIvstSiteDataList] = useState<any>([]);
    const [mntncRqrdSiteDataList, setMntncRqrdSiteDataList] = useState<any>([]);


    useEffect(() => {
        restapi.retrieveIvstTotDataList({}).then((rsltData: any) => {
            setIvstSiteDataList(rsltData);
            const mntncRqrdSiteDataList = rsltData.filter((data: any) => data.mntncActBfCnt > 0);
            setMntncRqrdSiteDataList(mntncRqrdSiteDataList);

        }).catch(error => {
            toast.error(errorCodeDic['003']);
            console.log('error: ', error);
        })
        .finally(() => {
            spinner.stop();
        });
    }, []);

    return(
        <Container fluid className="bmc-bg-pane">
            <div className="d-flex align-items-center justify-content-between pb-2 border-bottom-v2 mb-2">
                <span className="bmc-area-title-v2">전체 점검 현황</span>
            </div>
            <div className="d-flex flex-column">
                <div className="d-flex justify-content-between ivst-ttl-item-area mb-2 py-2 px-3">
                    <div className="d-inline-flex align-items-center gap-2">
                        <IconIvstCards width={40}/>
                        <span>전체 현장수</span>
                    </div>
                    <div className="d-inline-flex align-items-center">
                        <span className="fs-4 fw-bold">{ivstSiteDataList.length}</span>
                    </div>
                </div>
                <div className="d-flex justify-content-between ivst-ttl-item-area mb-2 py-2 px-3">
                    <div className="d-inline-flex align-items-center gap-2">
                        <IconIvstCards width={40}/>
                        <span>보수 조치 필요 현장</span>
                    </div>
                    <div className="d-inline-flex align-items-center">
                        <span className="fs-4 fw-bold">{mntncRqrdSiteDataList.length}</span>
                    </div>
                </div>
            </div>
        </Container>
    );
}
