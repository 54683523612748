import React, {Dispatch, SetStateAction, useContext, useEffect, useRef, useState} from "react";
import {ProgressContext} from "../../../context/Progress";
import {getLoginUser} from "../../../helper/SecurityHelper";
import {Col, Modal, Row, Table} from "react-bootstrap";
import {IoMdClose} from "react-icons/io";
import BMCInput from "../../../components/common/BMCInput";
import BMCSelect from "../../../components/common/BMCSelect";
import {toast} from "react-toastify";
import axios from "axios";
import messageCodeDic from "../../../util/messageCodeDic";
import errorCodeDic from "../../../util/errorCodeDic";
import BMCDatePicker from "../../../components/common/BMCDatePicker";
import CustomDatePicker from "../../component/ui/DatePicker";
import BMCDatePicker2 from "../../../components/common/BMCDatePicker2";
import {DateHelper} from "../../../helper/DateHelper";
import moment from "moment/moment";
import MngrSrchModal from "../../safetyWorkReportManagement/modal/MngrSrchModal";
import BMCIconButton from "../../../components/common/BMCIconButton";
import {Container} from "@mui/material";
import iconNodata from "../../../images/icon_nodocdata.png";
import {debug} from "node:util";
import restapi from "../../../helper/restAPIHelper";
import BMCTextarea from "../../../components/common/BMCTextarea";
import BMCFile from "../../../components/common/BMCFile";
import BMCPicModal from "../../../components/common/BMCPicModal";
import IvstSftyChkRgstrSrchModal from "../../ivst/modal/IvstSftyChkRgstrSrchModal";
import IvstSiteSearchModal from "../../ivst/modal/IvstSiteSearchModal";
import FcltySiteSearchModal from "./FcltySiteSearchModal";
import {CmmnFn} from "../../../helper/CmmnHelper";

interface IProps {
    setModalIsOpen: Dispatch<SetStateAction<boolean>>;
    modalIsOpen: boolean;
    detailData: any;
}

export default function FcltySftyChkModal(props:IProps){

    const [data, setData] = useState<any>();
    const formRef = React.createRef<HTMLFormElement>();
    const {spinner} = useContext(ProgressContext);
    const user: any = getLoginUser();
    const [isIvstSitePopOpen, setIsIvstSitePopOpen] = useState<any>(false);
    const [blngSite, setBlngSite] = useState<object>()
    const [sftyChkDt, setSftyChkDt] = useState<Date|null>();
    const [sftyChkRsltRgstDt, setSftyChkRsltRgstDt] = useState<Date|null>();
    const [sftyChkRsltEndDt, setSftyChkRsltEndDt] = useState<Date|null>();
    const [hourList, setHourList] = useState<any>([]);
    const [minList, setMinList] = useState<any>([]);
    const [chkRgstrModalIsOpen, setChkRgstrModalIsOpen] = React.useState(false);
    const [chkRgstrData, setChkRgstrData] = useState<any>(null);

    const [siteSftyMeasuresDataList, setSiteSftyMeasuresDataList] = useState<any>([]);
    const [siteSftyMeasuresData, setSiteSftyMeasuresData] = useState<any>();
    const [imgTarget, setImgTarget] = useState<any>(null);
    const fileRef = useRef() as React.MutableRefObject<any>;
    const [chkFileDataList, setChkFileDataList] = useState<any>([]);
    const [actFileDataList, setActFileDataList] = useState<any>([]);
    const [photoData, setPhotoData] = useState<any>({});
    const [isPhotoModalOpen, setIsPhotoModalOpen] = useState<boolean>(false);
    const [picModalTitle, setPicModalTitle] = useState<string>('');
    const [efctDt, setEfctDt] = useState<Date|null>();
    const [isDatePopupOpen, setIsDatePopupOpen] = useState(false);
    const prodRef = useRef<any>();


    useEffect(()=>{
        if(blngSite){
            setData(Object.assign({}, data, blngSite));
        }
    },[blngSite]);

    useEffect(()=>{
        let tempData = {};
        if(chkRgstrData){
            tempData = {
                sftyChkRsltRgstrNm : chkRgstrData?.userName,
                sftyChkRsltRgstrSeq : chkRgstrData?.userSeq
            }
        }
        setData(Object.assign({}, data, tempData))
    }, [chkRgstrData])

    const fn_updateData = (e: any) => {
        if (e.target.type == 'checkbox') {
            setData({
                ...data,
                [e.target.name]: e.target.checked
            })
            console.log(e.target.checked)
        } else {
            if (e.target.name == 'budget') {
                if (e.target.value) {
                    e.target.value = parseInt(e.target.value.replaceAll(',', ''))?.toLocaleString()
                }

            }


            setData({
                ...data,
                [e.target.name]: e.target.value
            })
        }
    }

    const fn_openModal = () => {
        if(!props.detailData){
            setData({efctDt: new Date()})
            setSftyChkRsltRgstDt(null)
            setSftyChkRsltEndDt(null);
            setSiteSftyMeasuresDataList([]);
            setChkRgstrData({});
            setEfctDt(new Date());
        }else{
            let param = props.detailData;

            setEfctDt(moment(props.detailData?.efctDt,'YYYY-MM-DD').toDate());
            console.log('props.detailData: ', props.detailData);
            if(props.detailData.sftyChkRsltRgstDt != null){
                param.sftyChkRsltRgstDt = moment(props.detailData?.sftyChkRsltRgstDt,'YYYY-MM-DD').toDate();
                param.sftyChkRsltEndDt = moment(props.detailData?.sftyChkRsltEndDt,'YYYY-MM-DD').toDate();

            }else{
                param.sftyChkRsltRgstDt = new Date();
                param.sftyChkRsltEndDt = new Date();
            }

            setData(param);


            if(!props.detailData?.sftyChkRsltRgstrSeq){
                console.log("user?.userName",user?.userName);
                setChkRgstrData({userName : user?.userName, userSeq : user?.userSeq})
            }

            const chkFileId = props.detailData.chkFileId;
            const actFileId = props.detailData.actFileId;

            if (chkFileId) {
                restapi.retrieveAttachInfoList({attachSeq: chkFileId}).then((rsltData: any) => {
                    setChkFileDataList([].concat(rsltData))
                })
            } else {
                setChkFileDataList([]);
            }

            if(actFileId){
                restapi.retrieveAttachInfoList({attachSeq: actFileId}).then((rsltData: any) => {
                    setActFileDataList([].concat(rsltData))
                })
            }else{
                setActFileDataList([]);
            }

        }

        fn_genHour();
        fn_genMin();
    }

    const fn_closeModal = () => {
        props.setModalIsOpen(false);
    }

    const submit = (e: any) => {
        e.preventDefault()

    }

    const fn_registData = () => {

        if (!data) {
            toast.warn('데이터를 입력해 주십시오.')
            return;
        }

        if (!data.siteId) {
            toast.warn('점검시설을 추가해 주십시오.')
            return;
        }

        if (!data.efctDt){
            toast.warn('시행일을 입력해 주십시오.')
            return;
        }

        if(!data.sftyDgnsGb){
            toast.warn('안전진단구분을 선택해 주십시오.');
            return;
        }

        if(!data.budget){
            toast.warn('예산을 입력해 주십시오.');
            return;
        }

        if (typeof data.budget != 'number') {
            data.budget = data.budget.replace(/,/g, "");
        }

        if(data.sftyChkSeq){
            if(!data.ivstSprvDprtGb){
                toast.warn('점검결과를 입력해 주십시오.');
                return;
            }

            if(!data.sftyChkRsltRgstrSeq){
                toast.warn('점검자를 입력해 주십시오.');
                return;
            }

            if(!data.sftyChkRsltRgstDt){
                toast.warn('점검 시작일을 입력해 주십시오.');
                return;
            }

            if(!data.sftyChkRsltEndDt){
                toast.warn('점검 종료일을 입력해 주십시오.');
                return;
            }

            if(chkFileDataList.length == 0){
                toast.warn('점검사진을 등록해 주십시오.');
                return;
            }

            if(data.ivstSprvDprtGb == 'C007005'){
                if(actFileDataList.length == 0){
                    toast.warn('조치사진을 등록해 주십시오.')
                    return;
                }


            }
        }

        let param = data;
        param.rgstrSeq = user.userSeq;
        param.updtrSeq = user.userSeq;
        param.workTypeCd = 'T002004';

        // @ts-ignore
        var formData: any = new FormData(formRef.current);

        if (chkFileDataList.length > 0) {
            for (var i = 0; i < chkFileDataList.length; i++) {
                formData.append('bfActFileList', chkFileDataList[i]);
            }

        }
        if (actFileDataList.length > 0) {
            for (var i = 0; i < actFileDataList.length; i++) {
                formData.append('afActFileList', actFileDataList[i]);
            }

        }

        formData.set('ivstSftyChkData', new Blob([JSON.stringify(param)], {type: "application/json"}))

        if(!window.confirm('저장 하시겠습니까?')){
            return;
        }
        spinner.start();
        axios.post('/admin/api/siteSftyManage/saveIvstSftyChkData', formData).then((result) => {
            if (result.data > 0) {
                toast.success(messageCodeDic['001'])
                props.setModalIsOpen(false);
            } else {
                toast.error(errorCodeDic['003']);
            }
        }).catch(error => {
            toast.error(errorCodeDic['003']);
            console.log('error: ', error);
        })
        .finally(() => {
            spinner.stop();
        });
    }

    const fn_selectSite = ()=>{
        setIsIvstSitePopOpen(true)
    }

    const fn_genHour = () => {
        let hours = [];
        let hr;

        for (var i = 1; i <= 24; i++) {
            if (i < 10) {
                hr = '0' + i;
            } else {
                hr = i;
            }
            hours.push(hr);
        }
        setHourList(hours);
    }

    const fn_genMin = () => {
        let min = [];
        let mi;

        for (var i = 0; i <= 60; i = i + 5) {
            if (i < 10) {
                mi = '0' + i;
            } else {
                mi = i;
            }
            min.push(mi);
        }
        setMinList(min);
    }

    const fn_openMngrPop = () => {
        setChkRgstrModalIsOpen(true);
    }

    const fn_onfileChange = (e: any) => {
        console.log('e.target.files: ', e.target.files);
        let cstmSiteSftyMeasuresDataList = siteSftyMeasuresDataList;

        if (e.target.files.length > 0) {
            const file = e.target.files[0];
            const imgSrc = URL.createObjectURL(file);
            if (imgTarget.gb == 'bf') {
                cstmSiteSftyMeasuresDataList[imgTarget.idx].fileTmpSrc = imgSrc;
                cstmSiteSftyMeasuresDataList[imgTarget.idx].bfActFile = file;
                cstmSiteSftyMeasuresDataList[imgTarget.idx].isPhotoUpload = true;
                setSiteSftyMeasuresDataList([].concat(cstmSiteSftyMeasuresDataList));
            } else if (imgTarget.gb == 'af') {
                cstmSiteSftyMeasuresDataList[imgTarget.idx].fileAfTmpSrc = imgSrc;
                cstmSiteSftyMeasuresDataList[imgTarget.idx].afActFile = file;
                cstmSiteSftyMeasuresDataList[imgTarget.idx].isPhotoUpload = true;

                setSiteSftyMeasuresDataList([].concat(cstmSiteSftyMeasuresDataList));

                console.log('cstmSiteSftyMeasuresDataList: ', cstmSiteSftyMeasuresDataList);
            }
        }

    }

    const fn_openPhotoModal = (param: any, title: string) => {
        setPhotoData(param);
        setPicModalTitle(title);
        setIsPhotoModalOpen(true);

    }

    const fn_datePopupOpen = () => {
        setIsDatePopupOpen(!isDatePopupOpen);
    };

    return(
        <div>
            <IvstSftyChkRgstrSrchModal setModalIsOpen={setChkRgstrModalIsOpen} modalIsOpen={chkRgstrModalIsOpen} setChkRgstrData={setChkRgstrData}></IvstSftyChkRgstrSrchModal>
            <FcltySiteSearchModal setModalIsOpen={setIsIvstSitePopOpen} modalIsOpen={isIvstSitePopOpen} setBlngSite={setBlngSite}/>
            <BMCPicModal detailData={photoData} setModalIsOpen={setIsPhotoModalOpen} modalIsOpen={isPhotoModalOpen} title={picModalTitle} />
            <Modal
                className="bmc-modal" size="xl"
                show={props.modalIsOpen}
                onShow={fn_openModal}
                onHide={fn_closeModal}
            >
                <Modal.Header>
                    <div className="d-flex justify-content-between align-items-center w-100 bmc-font-SD">
                        안전점검 및 정밀안전 계획 등록/수정
                        <IoMdClose className='hover:cursor-pointer' onClick={fn_closeModal}/>
                    </div>
                </Modal.Header>
                <Modal.Body className="px-4">

                    <input hidden type={'file'} ref={fileRef} onChange={fn_onfileChange}/>
                    <form name={'detailForm'} ref={formRef} encType="multipart/form-data" method={"post"} onSubmit={submit}>
                        <BMCInput type={'hidden'} value={data?.atchFileId} name={'atchFileId'}></BMCInput>
                        <BMCInput type={'hidden'} value={data?.sftyMngBrdId} name={'sftyMngBrdId'}></BMCInput>
                        <div className="bmc-modal-div">
                            <div className="div-header justify-content-between">
                                <span>시설물 기본정보</span>
                                { !data?.sftyChkSeq ? <button onClick={fn_selectSite} className='bmc-btn mr-5 btn-secondary'>점검시설 추가</button> : null}
                            </div>
                            <div className="div-body px-3">
                                <Table className="my-2 align-middle">
                                    <tbody>
                                    <tr className="border-top align-middle">
                                        <th className="bmc-bg-th" style={{width: '15%'}}>시설물 번호</th>
                                        <td style={{width: '35%'}}>
                                            <BMCInput type="text" className="form-control" name={'fcltyNo'} onChange={fn_updateData} value={data?.fcltyNo}  disabled={true}/>
                                        </td>
                                        <th className="bmc-bg-th" style={{width: '15%'}}>시행일</th>
                                        <td style={{width: '35%'}}>
                                            <BMCDatePicker2 name={'efctDt'} readOnly={true} defaultValue={data?.efctDt || new Date()} onChange={fn_updateData} />

                                        </td>
                                    </tr>
                                    <tr className="border-top align-middle">
                                        <th className="bmc-bg-th">
                                            시설물 명
                                        </th>
                                        <td colSpan={3}>
                                            <BMCInput type="text" className="form-control" name={'siteName'} onChange={fn_updateData} value={data?.siteName}  disabled={true}/>
                                        </td>
                                    </tr>
                                    <tr className="border-top align-middle">
                                        <th className="bmc-bg-th">
                                            주소
                                        </th>
                                        <td colSpan={3}>
                                            <div
                                                className="d-flex align-items-center justify-content-between w-100 p-0 bmc-fs-b3">
                                                <BMCInput type="text" name={'siteAddress'} value={data?.siteAddress} disabled={true}
                                                          className="form-control" readOnly/>

                                            </div>
                                        </td>
                                    </tr>
                                    <tr className="border-top align-middle">
                                        <th className="bmc-bg-th" style={{width: '15%'}}>구</th>
                                        <td style={{width: '35%'}}>
                                            <BMCInput type="text" className="form-control" name={'guNm'} onChange={fn_updateData} value={data?.guNm ? data.guNm.substring(data.guNm.lastIndexOf(' ')) : ''} disabled={true}/>
                                        </td>
                                        <th className="bmc-bg-th" style={{width: '15%'}}>안전진단구분</th>
                                        <td style={{width: '35%'}}>
                                            <BMCSelect initOptionGb={'sel'} className={'bmc-select'} codeGb={'cmmn'} codeGrpId={'S018'} name={'sftyDgnsGb'} value={data?.sftyDgnsGb} onChange={fn_updateData} ></BMCSelect>
                                        </td>
                                    </tr>
                                    <tr className="border-top align-middle">
                                        <th className="bmc-bg-th" style={{width: '15%'}}>예산</th>
                                        <td style={{width: '35%'}}>
                                            <div className="d-flex align-items-center px-0 gap-1">
                                                <BMCInput type="text" className="text-right form-control"
                                                          name={'budget'} onChange={fn_updateData}
                                                          value={data?.budget?.toLocaleString()}/>
                                                <span style={{width: '50px'}}>천원</span>
                                            </div>
                                        </td>
                                        <th className="bmc-bg-th" style={{width: '15%'}}>점검진단자</th>
                                        <td style={{width: '35%'}}>
                                            <BMCInput type="text" className="form-control" name={'rgstrNm'} onChange={fn_updateData} value={data?.rgstrNm || user.userName} disabled={true}/>
                                        </td>
                                    </tr>

                                    </tbody>
                                </Table>
                            </div>
                        </div>

                        {
                            data?.sftyChkSeq ?
                            <div className="bmc-modal-div">
                                <div className="div-header">
                                    조치내용
                                </div>
                                <div className="div-body px-3">
                                    <Table className="my-2 align-middle">
                                        <tbody>
                                        <tr className="border-top align-middle">
                                            <th className="bmc-bg-th" style={{width: '10%'}}>점검결과</th>
                                            <td style={{width: '20%'}}>
                                                <BMCSelect initOptionGb={'none'} disabled={!((!data?.actFileId && data?.ivstSprvDprtGb == 'C007005') || (!data?.chkFileId && data?.ivstSprvDprtGb != 'C007005' )  || ['C007001','C007004'].includes(data?.ivstSprvDprtGb))} className={'bmc-select'} codeGb={'cmmn'} codeGrpId={'C007'} name={'ivstSprvDprtGb'} value={data?.ivstSprvDprtGb} onChange={fn_updateData} ></BMCSelect>
                                            </td>
                                            <th className="bmc-bg-th" style={{width: '10%'}}>점검자</th>
                                            <td style={{width: '20%'}}>
                                                <div className="d-inline-flex gap-1">
                                                    <div className="d-flex">
                                                        <BMCInput type={"text"} className="form-control" name={'sftyChkRsltRgstrNm'} onChange={fn_updateData} value={data?.sftyChkRsltRgstrNm} readOnly={true}/>
                                                        <BMCInput type={'hidden'} name={'sftyChkRsltRgstrSeq'} onChange={fn_updateData} value={data?.sftyChkRsltRgstrSeq}/>
                                                    </div>
                                                    <div className="d-flex">
                                                        <button className="bmc-btn btn-secondary min-w-[70px]"
                                                                onClick={() => fn_openMngrPop()} type="button">검색</button>
                                                    </div>
                                                </div>
                                            </td>
                                            <th className="bmc-bg-th" style={{width: '10%'}}>점검일</th>
                                            <td style={{width: '20%'}}>
                                                <div className="d-flex align-items-center w-100">
                                                <BMCDatePicker2 name={'sftyChkRsltRgstDt'} readOnly={true} defaultValue={data?.sftyChkRsltRgstDt || new Date()} onChange={fn_updateData} />
                                                    <span className="flex-grow-1 text-center">&nbsp;~&nbsp;</span>
                                                <BMCDatePicker2 name={'sftyChkRsltEndDt'} readOnly={true} defaultValue={data?.sftyChkRsltEndDt || new Date()} onChange={fn_updateData} />
                                                </div>
                                            </td>
                                        </tr>
                                        <tr className="border-top align-middle">
                                            <th className="bmc-bg-th" style={{width: '10%'}}>점검 메모사항</th>
                                            <td colSpan={5} style={{width: '90%'}}>
                                                <BMCInput type="text" className="form-control" name={'sftyChkComments'} onChange={fn_updateData} value={data?.sftyChkComments}  disabled={false}/>
                                            </td>
                                        </tr>
                                        {data?.sftyChkSeq &&
                                            <>
                                            <tr className="border-top align-middle">
                                                <th className="bmc-bg-th" style={{width: '15%'}}>점검사진</th>
                                                <td colSpan={5} style={{width: '85%'}}>
                                                    {data.chkFileId ?
                                                        <div className="filebox w-100 d-flex ">
                                                            {
                                                                chkFileDataList.map((data: any, idx: number) => (
                                                                    <img key={idx} className={'max-h-[200px] mr-10 cursor-pointer'} onClick={() => fn_openPhotoModal(data, '점검사진')}
                                                                         src={process.env.REACT_APP_FILE_URL + "/bmcss-data/fileDownload?attachSeq=" + data.attachSeq + '&sn=' + data.sn + '&isPdf=0&time=' + new Date().getTime()}/>
                                                                ))
                                                            }
                                                        </div>

                                                        :
                                                        <BMCFile maxFileCnt={5}  fileName={'chkFileDataList'} fileId={'chkFileDataList'} fileList={chkFileDataList} setFileList={setChkFileDataList}></BMCFile>
                                                    }
                                                </td>

                                            </tr>
                                            {data?.ivstSprvDprtGb == 'C007005' &&
                                                <>
                                                <tr className="border-top align-middle">
                                                    <th className="bmc-bg-th" style={{width: '10%'}}>조치 후 메모사항</th>
                                                    <td colSpan={5} style={{width: '90%'}}>
                                                        <BMCInput type="text" className="form-control" name={'actComments'} onChange={fn_updateData} value={data?.actComments}  disabled={false}/>
                                                    </td>
                                                </tr>
                                                <tr className="border-top align-middle">
                                                    <th className="bmc-bg-th" style={{width: '15%'}}>조치사진</th>
                                                    <td colSpan={5} style={{width: '85%'}}>
                                                        {data.actFileId ?
                                                            <div className="filebox w-100 d-flex ">
                                                                {
                                                                    actFileDataList.map((data: any, idx: number) => (
                                                                        <img key={idx} className={'max-h-[200px] mr-10 cursor-pointer'} onClick={() => fn_openPhotoModal(data, '조치사진')}
                                                                             src={process.env.REACT_APP_FILE_URL + "/bmcss-data/fileDownload?attachSeq=" + data.attachSeq + '&sn=' + data.sn + '&isPdf=0&time=' + new Date().getTime()}/>
                                                                    ))
                                                                }
                                                            </div>
                                                            :
                                                            <BMCFile maxFileCnt={5}  fileName={'actFileDataList'} fileId={'actFileDataList'} fileList={actFileDataList} setFileList={setActFileDataList}></BMCFile>
                                                        }
                                                    </td>
                                                </tr>
                                                </>
                                            }

                                            </>
                                        }

                                        </tbody>
                                    </Table>
                                </div>
                            </div>
                            : null
                        }


                    </form>
                </Modal.Body>

                <Modal.Footer className="py-2">
                    <div className='flex justify-center w-100'>
                        <button
                            className='bmc-btn pill l white me-3'
                            onClick={fn_closeModal}>닫기
                        </button>
                        {
                            ((!data?.actFileId && data?.ivstSprvDprtGb == 'C007005') || (!data?.chkFileId && data?.ivstSprvDprtGb != 'C007005') || ['C007001','C007004'].includes(data?.ivstSprvDprtGb)) && <button onClick={fn_registData}
                                                        className='bmc-btn pill p'>등록/수정
                            </button>
                        }

                    </div>
                </Modal.Footer>

            </Modal>
        </div>
    )
}
