import React, {useContext, useEffect, useState} from 'react';
import {Col, Container, Row, Table, ProgressBar} from "react-bootstrap";
import {ReactComponent as IconRight} from "../../assets/images/ico/icon-right.svg";
import {ReactComponent as IconNoData} from "../../assets/images/icon/icon-folder.svg";
import {useNavigate} from "react-router-dom";
import {UserContext} from "../../context/User";
import {getLoginUser} from "../../helper/SecurityHelper";
import restapi from "../../helper/restAPIHelper";
import EqmntInfoListModal from "./modal/EqmntInfoListModal";

const DashboardEquipmentStatus = () => {
    const {user} = useContext<any>(UserContext);
    const defaultUser = getLoginUser();
    const [dataList, setDataList] = useState<any>([]);
    const [modalIsOpen, setModalIsOpen] = React.useState(false);
    const [eqmntCd, setEqmntCd] = useState<any>();

    const navigate = useNavigate();
    const fn_openEqmntInfoListModal = (cd: number) => {
        setEqmntCd(cd);
        setModalIsOpen(true);
    }

    useEffect(() => {
        const siteId = user?.blngSiteId || defaultUser.blngSiteId;
        restapi.retrieveEqmmntStatDataList({siteId:siteId}).then((rsltData: any) => {
            setDataList(rsltData)
            console.log('eqmnt rsltData: ', rsltData);
        })

    }, [user])
    return(
        <>
            <Container fluid className="bmc-bg-pane rounded-[8px]">
                <EqmntInfoListModal setModalIsOpen={setModalIsOpen} modalIsOpen={modalIsOpen} siteId={user?.blngSiteId || defaultUser.blngSiteId} eqmntCd={eqmntCd}></EqmntInfoListModal>
                <Row className="px-0">
                    <Col bsPrefix="col-7" className="w-100">
                        <div className="w-100 d-flex align-items-center justify-content-between pb-2 border-bottom">
                            <span className="pe-2 bmc-txt-size-h4">장비 입고 현황</span>
                            <div className={'cursor-pointer'} onClick={() => navigate('/siteEquipmentManagement/equipmentManagementBeforeArrival')}>
                                <IconRight style={{width: '20px', marginBottom: '1px', stroke: '#1d1b39'}} />
                            </div>
                        </div>
                    </Col>
                </Row>
                <Row className="px-0 h-100" style={{minHeight:"170px"}}>
                    <Col>
                        {
                            dataList.length > 0 ? (
                                <Table className="my-2 pt-3" borderless={true}>
                                    <thead className="text-center">
                                    <tr>
                                        <th className="bmc-fw-5 py-1 bg-black bg-opacity-50" style={{borderTopLeftRadius:'5px',borderBottomLeftRadius:'5px'}}>장비명</th>
                                        <th className="bmc-fw-5 py-1 bg-black bg-opacity-50" style={{borderTopRightRadius:'5px',borderBottomRightRadius:'5px'}}>입고</th>
                                    </tr>
                                    </thead>
                                    <tbody className="bmc-fw-4">
                                        {
                                            dataList.map((data: any, idx: number) => (
                                                <tr className={'cursor-pointer'} onClick={() => fn_openEqmntInfoListModal(data.eqmntCd)} key={idx} style={{borderBottom:'dotted 1px rgba(255,255,255,0.2)'}}>
                                                    <td>{data.eqmntNm}</td>
                                                    <td className="text-center">{data.eqmntCnt}건</td>
                                                </tr>
                                            ))
                                        }
                                    </tbody>
                                </Table>
                            ) : (
                                <div className="d-flex flex-column justify-content-center align-items-center" style={{height: 'calc(100% - 20px)'}}>
                                    <IconNoData style={{width: '40px', height: '40px', marginBottom: '10px'}} />
                                    <div className="bmc-txt-color-gray-01 bmc-txt-size-b6">등록된 데이터가 없습니다.</div>
                                </div>
                            )
                        }
                    </Col>
                </Row>
            </Container>
        </>
    );
}

export default DashboardEquipmentStatus;
