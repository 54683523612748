import React, {useContext, useEffect, useRef, useState} from 'react';
import axios from "axios";
import messageCodeDic from "../../util/messageCodeDic";
import errorCodeDic from "../../util/errorCodeDic";
import {Col, Container, Row, Table} from 'react-bootstrap';
import Datatables, {getCheckedItems} from "../component/Datatables";
import {toast, ToastContainer} from "react-toastify";
import SHSearch from "../component/BMCSearch";
import SubMenuTitle from "../component/SubMenuTitle";
import BMCIconButton from '../../components/common/BMCIconButton';
import BMCSelect from "../../components/common/BMCSelect";
import Modal from "react-modal";
import {getLoginUser} from "../../helper/SecurityHelper";
import NfcQrRgstModal from "./modal/NfcQrRgstModal";
import {UserContext} from "../../context/User";
import BMCInput from "../../components/common/BMCInput";
import QrCodeModal from "./modal/QrCodeModal";

type Props = {};

Modal.setAppElement('#root');

const NfcQrManagePage = (props: Props) => {
    const [modalIsOpen, setModalIsOpen] = React.useState(false);
    const [datatable, setDatatable] = useState<any>();
    const [detailData, setDetailData] = useState(null);
    const searchFormRef = useRef<any>();
    const defaultUser = getLoginUser();
    const [data, setData] = useState<any>();
    const {user} = useContext<any>(UserContext);
    const [isQrCodeModalOpen, setIsQrCodeModalOpen] = useState<boolean>(false);

    useEffect(()=>{

        if(datatable){
            datatable.draw();
        }

    }, [user, datatable])

    useEffect(() => {
        if (!modalIsOpen) {
            datatable?.draw();
        }
    }, [modalIsOpen])


    const fn_search = (e?: any) => {
        if (e) {
            e.preventDefault()
        }
        datatable?.draw()
    }

    /**
     * 등록 버튼 클릭
     */
    const fn_registPopOpen = () => {
        setDetailData(null);
        setModalIsOpen(true);
    }

    const fn_delete = () => {
        let checkedData = getCheckedItems(datatable, "qrNfcId")
        if (checkedData.length == 0) {
            toast.warn(messageCodeDic['005']);
            return;
        }
        if (!window.confirm(messageCodeDic['004'])) {
            return;
        }
        axios.post('/admin/api/qrnfc/deleteQrNfcData', {delList: checkedData}).then(rsltData => {
            if (rsltData.data > 0) {
                toast.success(messageCodeDic['002'])
                fn_search();
            } else {
                toast.error(errorCodeDic['003'])
            }
        }).catch(() => {
            toast.error(errorCodeDic['003'])
        })
    }

    const fn_updateViewCnt = (param: object) => {
        axios.post('/admin/api/notice/updateViewCnt', param)
    }

    const fn_setFormData = (e: any) => {

        setData({
            ...data,
            [e.target.name]: e.target.value
        })

    }

    return (
        <Container fluid className="bg-white px-0 bmc-txt-color-primary">
            <SubMenuTitle title={'NFC/QR 관리'} parentMenu={'현장 Admin'}/>
            <QrCodeModal setModalIsOpen={setIsQrCodeModalOpen} modalIsOpen={isQrCodeModalOpen} dtlData={detailData}  />
            <NfcQrRgstModal setModalIsOpen={setModalIsOpen} modalIsOpen={modalIsOpen} detailData={detailData} siteId={user?.blngSiteId || defaultUser.blngSiteId}></NfcQrRgstModal>
            <div className="p-4 overflow-y-auto" style={{height: 'calc(100vh - 180px)'}}>
                <SHSearch datatable={datatable} formRef={searchFormRef} btnType={'p'}>
                    <Row>
                        <Col>
                            <Table className="bmc-font-SP bmc-fs-b2 my-3">
                                <tbody>
                                <tr className="border-top border-bottom align-middle">

                                    <th className="bmc-bg-th w-25">타입</th>
                                    <td className="w-25">
                                        <BMCInput type={'hidden'} name={'siteId'} value={user?.blngSiteId || defaultUser.blngSiteId}></BMCInput>
                                        <BMCSelect className={"form-select bmc-fs-b3"} initOptionGb={'all'}
                                                   name={'qrNfcTpCd'} codeGb={'cmmn'} codeGrpId={'T003'}
                                                   onChange={(e) => {
                                                       fn_setFormData(e);
                                                       fn_search();
                                                   }}
                                                   value={data?.qrNfcTpCd}/>
                                    </td>
                                    <th className="w-25"></th>
                                    <td className="w-25"></td>
                                </tr>
                                </tbody>
                            </Table>
                        </Col>
                    </Row>
                </SHSearch>
                <div className={'ge-result'}>
                    <Datatables
                        dataUrl={'/admin/api/qrnfc/retrieveQrNfcDataList'}
                        parameterFormRef={searchFormRef}
                        columns={[
                            {data: "qrNfcTpNm", title: "타입", textAlign: 'center'},
                            {data: "inOutGbNm", title: "구분", textAlign: 'center'},
                            {data: "nfc", title: "NFC/QR", textAlign: 'center'},
                            {data: "wrkPlcNm", title: "작업위치", textAlign: 'center'},
                            {data: "eduTpNm", title: "교육구분", textAlign: 'center'},
                            {
                                title: "기능",
                                type: "button",
                                className: "text-center",
                                buttons: [{
                                    label: "<img style='height: 20px' class='cursor-pointer' src='/icon_qr_code.svg' />",
                                    onClick: (v: any) => {
                                        setDetailData(v);
                                        setIsQrCodeModalOpen(true);
                                    },
                                }],
                            }
                        ]}

                        checked={true}
                        index={true}

                        onLoad={(dt: any) => {
                            setDatatable(dt)
                        }}

                        onClick={(data) => {
                            setDetailData(data)
                            setModalIsOpen(true);
                            fn_updateViewCnt(data);
                        }}
                    >
                        <div className='w-100 d-flex justify-content-end align-items-center mb-2'>
                            <BMCIconButton kind={'submit'} marginRight={'5px'} onClick={fn_registPopOpen}>등록</BMCIconButton>
                            <BMCIconButton kind={'delete'} marginRight={'0'} onClick={fn_delete}> 삭제
                            </BMCIconButton>
                        </div>
                    </Datatables>
                </div>
            </div>
        </Container>
    );
};

export default NfcQrManagePage;
