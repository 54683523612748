import {Col, Modal, Row, Table} from "react-bootstrap";
import {IoMdClose} from "react-icons/io";

import React, {Dispatch, SetStateAction, useContext, useEffect, useRef, useState} from "react";
import {getLoginUser} from "../../../helper/SecurityHelper";

import axios from "axios";
import {toast} from "react-toastify";
import errorCodeDic from "../../../util/errorCodeDic";

import restapi from "../../../helper/restAPIHelper";
import BMCFile from "../../../components/common/BMCFile";
import {ProgressContext} from "../../../context/Progress";
import {UserContext} from "../../../context/User";
import BMCInput from "../../../components/common/BMCInput";
import BMCCheckbox from "../../../components/common/BMCCheckbox";



interface IProps {
    setModalIsOpen: Dispatch<SetStateAction<boolean>>;
    modalIsOpen: boolean;
    fileList: any;
}

const CnstrPrgsFileRgstModal = (props: IProps) => {

    const [fileList, setFileList] = useState([]);
    const formRef = React.createRef<HTMLFormElement>();
    const defaultUser = getLoginUser();
    const {user} = useContext<any>(UserContext);
    const {spinner} = useContext(ProgressContext);
    const siteId = user?.blngSiteId || defaultUser.blngSiteId
    const [data, setData] = useState<any>({})
    const [repFileSn, setRepFileSn] = useState<any>(null);

    function afterOpenModal() {

        if(props.fileList.length == 0){
            setRepFileSn(null);
            setFileList([]);
            spinner.start();
            restapi.retrieveSiteDataListNoPaging({siteId:siteId}).then((rsltData: any) => {
                setData(rsltData[0]);
            }).catch(error => {
                toast.error(errorCodeDic['003']);
                console.log('error: ', error);
            })
                .finally(() => {
                    spinner.stop();
                });
        }else{
            spinner.start();
            restapi.retrieveSiteDataListNoPaging({siteId:siteId}).then((rsltData: any) => {
                setData(rsltData[0]);
                const dtlData = rsltData[0];
                if (dtlData?.repPhotoSn !== null && dtlData?.repPhotoSn !== undefined) {
                    props.fileList.map((data: any, idx: number) => {
                        if (data.sn == dtlData.repPhotoSn) {
                            data.isRep = true
                            setRepFileSn(idx);

                        }
                    })
                }

                console.log('props.fileList: ', props.fileList);

                setFileList(props.fileList);
            }).catch(error => {
                toast.error(errorCodeDic['003']);
                console.log('error: ', error);
            })
                .finally(() => {
                    spinner.stop();
                });
        }


    }

    function fn_closeModal() {
        props.setModalIsOpen(false);
    }

    const fn_setFormData = (e: any) => {
        setData({
            ...data,
            [e.target.name]: e.target.value
        })
    }

    const fn_registData = () => {

        if(fileList.length == 0){
            toast.warn('파일을 업로드해 주십시오.');
            return;
        }

        if (fileList.length > 0 && repFileSn == null) {
            toast.warn('대표 현장사진을 선택해 주십시오')
            return;
        }

        if(!window.confirm('저장 하시겠습니까?')){
            return;
        }

        // @ts-ignore
        var formData: any = new FormData(formRef.current)

        if(fileList.length > 0){
            for(var i =0;i<fileList.length;i++){
                formData.append('atchFileList',fileList[i]);
            }
        }
        let param = data;
        param.rgstrSeq = defaultUser.userSeq;
        param.updtrSeq = defaultUser.userSeq;
        param.updateSeq = defaultUser.userSeq;
        param.siteId = siteId;
        param.attachVOList = fileList;
        if (fileList.length > 0) {
            param.repFileSn = repFileSn;
        }



        formData.set('dtlData', new Blob([JSON.stringify(param)], {type: "application/json"}))

        spinner.start();
        axios.post('/admin/api/site/saveSitePhotoData', formData).then((rsltData: any) => {
            if(rsltData.data > 0){
                toast.success('저장 되었습니다.');
                props.setModalIsOpen(false);
            }else{
                toast.error(errorCodeDic['003']);
            }

        }).catch(error => {
            toast.error(errorCodeDic['003']);
            console.log('error: ', error);
        })
            .finally(() => {
                spinner.stop();
            });
    }


    return (
        <div>

            <Modal
                className="bmc-modal" size="xl"
                show={props.modalIsOpen}
                onShow={afterOpenModal}
                onHide={fn_closeModal}

            >
                <Modal.Header>
                    <div className="d-flex justify-content-between align-items-center w-100 bmc-font-SD">
                        {'공사공정진행 정보 등록'}
                        <IoMdClose className='hover:cursor-pointer' onClick={fn_closeModal}/>
                    </div>
                </Modal.Header>

                <Modal.Body className="px-4">
                    <div className="bmc-modal-div">
                        <div className="div-header">
                            공사공정 진행율
                        </div>
                        <div className="div-body px-3">
                            <Table className="my-2 align-middle">
                                <colgroup>
                                    <col width='15%'/>
                                    <col width='35%'/>
                                    <col width='15%'/>
                                    <col width='35%'/>
                                </colgroup>
                                <tbody>
                                    <tr className="border-top align-middle">
                                        <th className="bmc-bg-th" style={{width: '15%'}}>공사공정 진행율</th>
                                        <td colSpan={3} style={{width: '85%'}}>
                                            <div
                                                className="d-flex align-items-center  w-100 p-0 bmc-fs-b3">
                                                <BMCInput type="text" className="form-control w-95 me-2"
                                                          name={'cnstrctPrgsRate'}
                                                          onChange={fn_setFormData}
                                                          value={data.cnstrctPrgsRate}/>
                                                <span>%</span>
                                            </div>
                                        </td>


                                    </tr>
                                </tbody>
                            </Table>
                        </div>
                    </div>

                    <form name={'form'} ref={formRef} encType="multipart/form-data" method={"post"}>
                    <BMCFile maxFileCnt={20} fileName={'initRskEvlFileList'} repFileSn={repFileSn} isDesc={true} setRepFileSn={setRepFileSn} fileList={fileList} setFileList={setFileList}></BMCFile>
                    </form>
                </Modal.Body>
                <Modal.Footer className="py-2">
                    <div className='flex justify-center w-100'>
                        <button
                            className='bmc-btn pill l me-3'
                            onClick={fn_closeModal}>닫기
                        </button>
                        <button
                            className='bmc-btn pill p' onClick={fn_registData}>저장
                        </button>
                    </div>
                </Modal.Footer>


            </Modal>
        </div>
    )
}

export default CnstrPrgsFileRgstModal;

