import React, {useContext, useEffect, useRef, useState} from "react";
import {Container, Row, Col, Table} from "react-bootstrap";
import {ReactComponent as IconRight} from "../../assets/images/ico/icon-right.svg";
import {
    Chart as ChartJS,
    CategoryScale,
    LinearScale,
    BarElement,
    Title,
    Tooltip,
    Legend, Chart,
} from 'chart.js';
import { Bar } from 'react-chartjs-2';
import {UserContext} from "../../context/User";
import {getLoginUser} from "../../helper/SecurityHelper";
import restapi from "../../helper/restAPIHelper";
import WrkrInfoListModal from "./modal/WrkrInfoListModal";
import {MenuOpenContext} from "../../context/MenuOpen";
import {ChartJSOrUndefined} from "react-chartjs-2/dist/types";
import {IntervalContext} from "../../context/Interval";

ChartJS.register(
    CategoryScale,
    LinearScale,
    BarElement,
    Title,
    Tooltip
);

const DashPartDepartureStatus = () => {
    const {open} = useContext(MenuOpenContext);
    const {user} = useContext<any>(UserContext);
    const defaultUser = getLoginUser();
    const chartRef = useRef<any>();
    const labels = ['금농토건', '대아이엔씨', '대림건설', '신한에스엔지', '신보', '상원전기', '대아이앤씨'];
    const [modalIsOpen, setModalIsOpen] = React.useState(false);
    const [wrkrDataList, setWrkrDataList] = useState<any[]>([]);
    const {interval} = useContext<any>(IntervalContext);
    const [copData, setCopData] = useState<any>({
        labels,
        datasets: [
            {
                label: '현재인원',
                data: [100, 60, 70, 30, 80, 10, 20],
                backgroundColor: [
                    'rgba(48, 228, 199, 1)',
                ],
                borderColor: [
                    'rgba(30, 31, 35, 1)',
                ],
                borderWidth: 1,
            },
            {
                label: 'OUT인원',
                data: [90, 50, 60, 60, 40, 20, 10],
                backgroundColor: [
                    'rgba(86, 133, 247, 1)',
                ],
                borderColor: [
                    'rgba(30, 31, 35, 1)',
                ],
                borderWidth: 1,
            },
        ]
    });
    useEffect(() => {
        chartRef.current.resize()
    },[open])
    useEffect(() => {
        const siteId = user?.blngSiteId || defaultUser.blngSiteId;

        console.log('headSite: ', siteId);
        restapi.retrieveTotEtrncStatInfo({siteId: siteId, qrNfcTpCd: 'T003001'}).then((rsltData: any) => {
            setWrkrDataList(rsltData.totWrkrList);
        })

        restapi.retrieveEtrncStatByCmpny({siteId:siteId}).then((rsltData: any) => {
            const inWrkrCmpnyDataList = rsltData.inWrkrCmpnyDataList;
            let labels:any = [];
            let inWrkrCntArr: any = [];
            let outWrkrCntArr: any = [];
            console.log('part dept rsltData: ', rsltData);


            inWrkrCmpnyDataList.map((inData: any) => {
                labels.push(inData.cmpnyNm);
                inWrkrCntArr.push(inData.remainCnt);
                outWrkrCntArr.push(inData.outCnt);
            })

            console.log('cmpny stat data: ', rsltData);

            console.log('inWrkrCmpnyDataList: ', inWrkrCmpnyDataList);
            console.log('inWrkrCntArr: ', inWrkrCntArr);
            console.log('outWrkrCntArr: ', outWrkrCntArr);

            const copData = {
                labels,
                datasets: [
                    {
                        label: '현재인원',
                        data: inWrkrCntArr,
                        backgroundColor: [
                            'rgba(48, 228, 199, 1)',
                        ],
                        borderColor: [
                            'rgba(30, 31, 35, 1)',
                        ],
                        borderWidth: 1,
                        minBarLength: 5,
                    },
                    {
                        label: 'OUT인원',
                        data: outWrkrCntArr,
                        backgroundColor: [
                            'rgba(86, 133, 247, 1)',
                        ],
                        borderColor: [
                            'rgba(30, 31, 35, 1)',
                        ],
                        borderWidth: 1,
                        minBarLength: 5,
                    },
                ]
            };
            setCopData(copData);




        })

    }, [user, interval])



    const options = {
        responsive: true,
        scales: {
            x: {
                ticks: {
                    font: {
                        family: "'NanumSquareNeo','Arial",
                        size: 8,
                    },
                }
            },
            y: {
                ticks: {
                    color: 'rgba(31, 27, 57, 1)',
                    font: {
                        family: "'NanumSquareNeo','Arial",
                        size: 8,
                    },
                }
            }
        },
        plugins: {
            legend: {
                display: false,
            }
        },
        elements: {
            bar: {
                borderRadius: 10,
            }
        },
        rotation: -90,
        circumference: 180,
        cutout: '70%',
    };

    const fn_openWrkrInfoModal = () => {
        setModalIsOpen(true);
    }

    return(
        <>
            <Container fluid className="bmc-bg-pane rounded-[8px] h-100">
                <WrkrInfoListModal setModalIsOpen={setModalIsOpen} modalIsOpen={modalIsOpen}  wrkrDataList={wrkrDataList} siteId={user?.blngSiteId || defaultUser.blngSiteId} title={'업체별 출역현황'} ></WrkrInfoListModal>
                <Row className="px-0">
                    <Col bsPrefix="col-7" className="w-100">
                        <div className="w-100 d-flex align-items-center justify-content-between pb-2 border-bottom">
                            <span className="pe-2 bmc-txt-size-h4">업체별 출역현황</span>
                            <div className={'cursor-pointer'} onClick={fn_openWrkrInfoModal}>
                                <IconRight style={{width: '20px', marginBottom: '1px', stroke: '#1d1b39'}} />
                            </div>
                        </div>
                    </Col>
                </Row>

                <Row className="px-0 pt-3">
                    <Col className="px-0">
                        <Container fluid>
                            <Row>
                                <Col>
                                    <Bar options={options} data={copData} height="175px" ref={chartRef} />
                                </Col>
                            </Row>
                            <Row className="py-2">
                                <Col className="d-flex justify-content-center w-100 bmc-fs-b4">
                                    <div>
                                        <span className="pe-1" style={{color:'#30E4C7'}}>●</span>
                                        <span className="pe-1">현재인원</span>
                                    </div>
                                    <div className="ps-2">
                                        <span className="pe-1" style={{color:'#5685F7'}}>●</span>
                                        <span className="pe-1">OUT인원</span>
                                    </div>
                                </Col>
                            </Row>
                        </Container>
                    </Col>
                </Row>
            </Container>
        </>
    );
};

export default DashPartDepartureStatus;

