import React, {useContext, useEffect, useRef, useState} from "react";
import colorConfigs from "../configs/colorConfigs";
import {Box} from "@mui/material";
import {getLoginUser, logout} from "../../helper/SecurityHelper";
import {useNavigate} from "react-router-dom";
import {ReactComponent as IconLogo} from '../../assets/images/logo/logo_header.svg';
import {ReactComponent as IconHeaderLine} from '../../assets/images/icon/icon-header-line-v2.svg';
import {ReactComponent as IconHeaderLogout} from '../../assets/images/icon/icon-header-logout-white.svg';
import {ReactComponent as IconSample} from '../../assets/images/icon/icon-sample.svg';
import {ReactComponent as IconBell} from '../../assets/images/icon/icon-bell.svg';
import {UserContext} from "../../context/User";
import axios from "axios";
import restapi from "../../helper/restAPIHelper";
import {MenuOpenContext} from "../../context/MenuOpen";
import RcvNtfyListModal from "../../pages/headoffice/modal/RcvNtfyListModal";
import {SiteContext} from "../../context/Site";
import {ReactComponent as IconMenubarLine} from '../../assets/images/icon/icon-Menubar-line.svg';

import Dropdown from "react-bootstrap/Dropdown";
import {PushContext} from "../../context/Push";
import PushModal from "../../pages/headoffice/modal/PushModal";
import appRoutesFclty from "../../routes/appRoutesFclty";
import BMCIconButton from "./BMCIconButton";

type Anchor = 'top' | 'left' | 'bottom' | 'right';







export default function FcltySiteMenubar(){
    const navigate = useNavigate();
    const defaultUser: any = getLoginUser();
    const [siteId, setSiteId] = useState<number>(defaultUser?.blngSiteId);
    const [siteCodeList, setSiteCodeList] = useState<any>([]);
    const siteIdRef = useRef<any>();
    const {user,setUser} = useContext<any>(UserContext);
    const {open, setOpen} = useContext(MenuOpenContext);
    const [rcvNtfyDataList, setRcvNtfyDataList] = useState<any>([]);
    const [isNewNtfy, setIsNewNtfy] = useState<boolean>(false);
    const [isRcvNtfyModal, setIsRcvNtfyModal] = useState<boolean>(false);
    const {site} = useContext<any>(SiteContext);
    const [wthrInfo, setWthrInfo] = useState<any>({});
    const [pm10Grade, setPm10Grade] = useState<string|null>(null);
    const [pm10Color, setPm10Color] = useState<any|null>(null);
    const [pm25Grade, setPm25Grade] = useState<string|null>(null);
    const [pm25Color, setPm25Color] = useState<any|null>(null);
    const [msgCnt, setMsgCnt] = useState<number>(0);
    const {push, setPush} = useContext<any>(PushContext);

    useEffect(() => {
        const pathname = window.location.pathname;
        if(pathname === '/' || pathname.indexOf("monitoring") > -1){
            setOpen(false)
        }else{
            setOpen(true)
        }
    }, [])

    useEffect(() => {
        if(site){
            // 날씨 정보 가져오기
            restapi.retrieveWtherInfoData(site.siteId).then((rsltData) => {
                console.log('whthr head: ', rsltData);
                setWthrInfo(rsltData);
            })
        } else {
            restapi.retrieveWtherInfoData().then((rsltData) => {
                console.log('whthr head: ', rsltData);
                setWthrInfo(rsltData);
            })
        }

    }, [site])

    useEffect(() => {
        var pm10Value = wthrInfo.pm10
        if(10 <= pm10Value && pm10Value <= 30){
            setPm10Grade("좋음")
            setPm10Color("#1c67d7")
        }else if(31 <= pm10Value && pm10Value <= 80){
            setPm10Grade("보통")
            setPm10Color("#01b56e")
        }else if(81 <= pm10Value && pm10Value <= 150){
            setPm10Grade("나쁨")
            setPm10Color("#f9c700")
        }else if(151 <= pm10Value){
            setPm10Grade("매우나쁨")
            setPm10Color("#e53030")
        }
        var pm25Value = wthrInfo.pm25
        if(10 <= pm25Value && pm25Value <= 15){
            setPm25Grade("좋음")
            setPm25Color("#1c67d7")
        }else if(16 <= pm25Value && pm25Value <= 35){
            setPm25Grade("보통")
            setPm25Color("#01b56e")
        }else if(36 <= pm25Value && pm25Value <= 75){
            setPm25Grade("나쁨")
            setPm25Color("#f9c700")
        }else if(75 <= pm25Value){
            setPm25Grade("매우나쁨")
            setPm25Color("#e53030")
        }
    }, [wthrInfo])


    useEffect(() => {
        console.log('defaultUser: ', defaultUser);
    }, [])

    useEffect(() => {
        fn_retrieveSiteRcvNtfyDataList(user?.blngSiteId || defaultUser.blngSiteId);
    }, [isRcvNtfyModal]);




    const fn_retrieveSiteRcvNtfyDataList = (siteId: number) => {
        restapi.retrieveSiteRcvNtfyDataList({userSeq: defaultUser.userSeq, blngCmpnySeq: defaultUser.blngCmpnySeq, siteId:siteId}).then((rsltData: any) => {
            console.log('rcvInfo Data: ', rsltData);
            setRcvNtfyDataList(rsltData);
            let flag = false;
            let cnt = 0;

            rsltData.map((data: any) => {
                if(!data.isRead){
                    flag = true;
                    cnt ++;
                }
            })
            setIsNewNtfy(flag)
            setMsgCnt(cnt);

        })
    }

    const fn_onTopMenuChg = (e: any) => {
        console.log('e.target.value: ', e.target.value);
        if(e.target.value == 'mainControl'){
            navigate('/mainControl');
        }
    }


    const fn_retrieveSiteCodeList = async () => {
        return new Promise(function (resolve, reject) {
            let param:any = {}
            if(defaultUser.authGrpId == 'SITE'){
                let siteIdList: any = [];
                const authChrgDataList = defaultUser.authChrgDataList;
                authChrgDataList.map((data: any) => {
                    siteIdList.push(data.siteId);
                })
                param.siteIdList = siteIdList;
            }

            if(param.siteIdList.length > 0){
                axios.post('/admin/api/site/retrieveSiteCodeList', param).then((result) => {
                    resolve(result.data);
                })
            }else{
                resolve([]);
            }

        });
    }

    const fn_openRcvNtfyPop = () => {
        setIsRcvNtfyModal(true);
    }

    const [date, setDate] = useState(() => new Date());
    const week = ['일요일', '월요일', '화요일', '수요일', '목요일', '금요일', '토요일'];
    let dayOfWeek = week[date.getDay()];

    const tick = () => {
        setDate(new Date())
    }

    useEffect(() => {
        //const timeId = setInterval(() => tick(), 1000);
        return () => {
            //clearInterval(timeId)
        }
    }, [])

    return (
        <React.Fragment>
            <PushModal setModalIsOpen={setPush} modalIsOpen={push}></PushModal>
            <RcvNtfyListModal setModalIsOpen={setIsRcvNtfyModal} modalIsOpen={isRcvNtfyModal} dataList={rcvNtfyDataList} siteId={user?.blngSiteId || defaultUser.blngSiteId}/>
            <Box position="fixed"
                 sx={{
                     width: '100vw',
                     height: '100px',
                     boxShadow: "unset",
                     backgroundColor: "#2B358B",
                     color: colorConfigs.topbar.color,
                     /*alignItems: 'center',*/
                     display: 'flex',
                     zIndex: 1
                 }}
                 className="d-flex flex-column"
            >
                <div className="bmc-manager-main-header-v2 d-flex flex-row justify-content-between w-100 h-100">
                    <div className="d-flex main-header-logo-v2 gap-4">
                        <IconLogo width={270} height={60} className="align-self-center" onClick={(e)=>{
                            navigate("/")
                        }}/>
                        <IconMenubarLine className="align-self-center "/>
                        <span className="fs-4 text-white fw-bold align-self-center">시설물 안전 관리</span>
                    </div>
                    <div className="d-flex flex-column">
                        <div className="d-flex justify-content-end flex-grow-1 pt-3">
                            <div className="d-flex flex-row align-items-center gap-2 px-4">
                                <div className="d-flex align-items-center justify-content-center mr-3">
                                    <BMCIconButton className="btn-sm" kind={'normal'} onClick={() => {navigate('/monitoring')}}>본사 웹</BMCIconButton>
                                </div>
                                <div className="d-flex align-items-center justify-content-center gap-2">
                                    <IconSample width={30} height={30} />
                                    <span className="header-user-name-v2" style={{maxWidth:"100px",textOverflow:"ellipsis", overflow:"hidden", whiteSpace:"nowrap"}} title={defaultUser?.userName}>{defaultUser?.userName}</span>
                                </div>
                                <IconHeaderLine width={22} height={22} />
                                <div className="d-flex align-items-center justify-content-center relative cursor-pointer" onClick={fn_openRcvNtfyPop}>
                                    <IconBell width={22} height={22} />
                                    {isNewNtfy && <span className="header-bell-count-v2">{msgCnt}</span>}
                                </div>
                                <IconHeaderLine width={22} height={22} />
                                <div className="header-logout-v2 d-flex align-items-center justify-content-center cursor-pointer">
                                    <IconHeaderLogout width={28} height={28} onClick={logout}/>
                                </div>
                            </div>
                        </div>
                        <div className="d-flex justify-content-between w-100 h-100">
                            <div className="d-flex flex-grow-1 align-self-end pe-5">
                                {defaultUser &&
                                    appRoutesFclty.map((route, index) => {
                                        return (
                                            route.sidebarProps ? (
                                                <div key={index} className="bmc-manager-header-menu-v2 align-self-end">
                                                    <Dropdown className={!route?.child ? "dropdown-arrow-none" : ""} onClick={(e) => {
                                                        if(!route?.child) {
                                                            // @ts-ignore
                                                            window.location.href = route?.path;
                                                        }
                                                    }}>
                                                        <Dropdown.Toggle variant="success" id="mainHeaderMenu" className="bmc-manager-header-menu-v2 mainHeaderMenu-v2 top-menu-v2">
                                                            {route?.sidebarProps?.displayText}
                                                        </Dropdown.Toggle>
                                                        {
                                                            route?.child ? <Dropdown.Menu>
                                                                    {
                                                                        route?.child.map((item:any, idx:number)=>(
                                                                            <Dropdown.Item key={idx} href={item.path}><div className="dropdown-item-div-v2 w-100">{item.sidebarProps.displayText}</div></Dropdown.Item>
                                                                        ))
                                                                    }
                                                                </Dropdown.Menu>
                                                                :null
                                                        }
                                                    </Dropdown>
                                                </div>
                                            ) : null
                                        )})
                                }
                            </div>
                        </div>
                    </div>
                </div>
            </Box>
        </React.Fragment>
    );
};
