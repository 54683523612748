import React, {Dispatch, MutableRefObject, SetStateAction, useEffect, useRef, useState} from "react";
import {CmmnFn} from "../../helper/CmmnHelper";
import axios from "axios/index";
import restapi from "../../helper/restAPIHelper";
import {getLoginUser} from "../../helper/SecurityHelper";

interface IProps {
    codeGrpId?: any;
    name?:string;
    value?: any;
    onChange?: Dispatch<SetStateAction<any>>;
    initOptionGb: string;
    codeGb?: string;
    selParam?: any;
    disabled?: boolean;
    className? : string;
    selectRef? : any;
    codes? : string[];
    id? : string;
    setIsCodeDataLoaded?: Dispatch<SetStateAction<boolean>>;
    isCodeDataLoaded?: boolean
    isForDtl? : boolean
    notBindValue?: boolean
}



export default function BMCSelect(props: IProps) {


    const [codeList, setCodeList] = useState<any>([]);
    const emptyRef = useRef<any>();
    const [defaultValue, setDefaultValue] = useState('');
    const user = getLoginUser();

    const fn_setDefaultValue = (e: any) => {
        setDefaultValue(e.target.value);
    }

    useEffect(() => {
        if(codeList.length > 0) {
            if (props.setIsCodeDataLoaded) {
                console.log('re re')
                props.setIsCodeDataLoaded(true);
            }
        }
    }, [codeList])


    useEffect(() => {
        if(!props.codeGb){
            CmmnFn.loadCmmnCdData(props.codeGrpId, props.codes).then((resultData: any) => {
                setCodeList(resultData);
            })
        }else{
           let cstmCodeList:any = [];
           // 현장 코드
           if(props.codeGb == 'site'){
               let param: any = null;
               if(user.authGrpId == 'SITE' && !props.isForDtl){
                   let siteIdList: any = [];
                   const authChrgDataList = user.authChrgDataList;
                   authChrgDataList.map((data: any) => {
                       siteIdList.push(data.siteId);
                   })
                   param = siteIdList;
               }
               restapi.retrieveSiteCodeList(param, props.selParam).then((rlstData: any) => {
                   rlstData.map((data: any) => {
                       cstmCodeList.push({code:data.siteId, codeNm:data.siteName})
                   })
                   setCodeList(cstmCodeList);
               })
           // 권한 코드
           }else if(props.codeGb == 'auth'){
               restapi.retrieveAuthCodeList(props.selParam.authGrpId, props.selParam.authCode).then((rsltData) => {
                   rsltData.map((data: any) => {
                       cstmCodeList.push({code:data.authCode, codeNm:data.authCodeNm})
                   })
                   setCodeList(cstmCodeList);
               })
           // 현장 관리 코드
           }else if(props.codeGb == 'siteMngCd'){
               restapi.retrieveSiteManageItemList(props.selParam.siteId, props.selParam.manageCode).then((rsltData) => {
                   rsltData.map((data: any) => {
                       cstmCodeList.push({code:data.manageSn, codeNm:data.manageName})
                   })
                   setCodeList(cstmCodeList);
               })
           // 현장별 업체 코드
           }else if(props.codeGb == 'siteCmpny'){
               let companySeq = props.selParam.cmpnySeq;
               if(user.companyGbCd === 'C001005'){
                   companySeq = user.blngCmpnySeq;
               }

               console.log('props.selParam.siteId: ', props.selParam.siteId);
               console.log('companySeq: ', companySeq);

               restapi.retrieveCmpnySiteDataList({siteId:props.selParam.siteId, companySeq:companySeq}).then((rsltData) => {
                   console.log('site rsltData: ', rsltData);
                   rsltData.map((data: any) => {
                       cstmCodeList.push({code:data.companySeq, codeNm:data.companyName})
                   })
                   setCodeList(cstmCodeList);
               })
           }else if(props.codeGb == 'cmmn'){
               CmmnFn.loadCmmnCdData(props.codeGrpId, props.codes).then((resultData: any) => {
                   if(props.codeGrpId == 'T002'){
                       resultData = resultData.filter((data: any) => data.code != 'T002002')
                   }
                   setCodeList([].concat(resultData));
               })
           }else if(props.codeGb == 'dngrEval'){
               restapi.retrieveRskAsmntCodeDataList(props.selParam).then((rsltData: any) => {
                   rsltData.map((data: any) => {
                       cstmCodeList.push({code:data.code, codeNm:data.codeName})
                   })
                   setCodeList(cstmCodeList);
               })
           } else if (props.codeGb == 'nation') {
               restapi.retrieveNationDataList().then((rsltData: any) => {
                   rsltData.map((data: any) => {
                       cstmCodeList.push({code: data.nationId, codeNm: data.nationNm});
                   });
                   setCodeList(cstmCodeList);
               });
           } else if (props.codeGb == 'bjd') {
               restapi.retrieveBjdDataList(props.codeGrpId, props.selParam).then((rsltData: any) => {
                   rsltData.map((data: any) => {
                       cstmCodeList.push({code: data.code, codeNm: data.codeNm});
                   });
                   setCodeList(cstmCodeList);
               });
           } else if (props.codeGb == 'bjd-dong') {
               if(props.selParam.guCd){
                   restapi.retrieveBjdDongDataList(props.codeGrpId, props.selParam.guCd).then((rsltData: any) => {
                       rsltData.map((data: any) => {
                           cstmCodeList.push({code: data.code, codeNm: data.codeNm});
                       });
                       setCodeList(cstmCodeList);
                   });
               }else{
                   setCodeList([]);
               }
           } else if (props.codeGb == 'cmpny') {
               restapi.retrieveCmpnyDataTotList({companySeq:props.selParam.companySeq}).then((rsltData) => {
                   rsltData.map((data: any) => {
                       cstmCodeList.push({code:data.companySeq, codeNm:data.companyName})
                   })
                   setCodeList(cstmCodeList);
               })
           }


        }

    }, [props])



    return (
        <React.Fragment>
        {props.notBindValue !== true ?
            <select ref={props.selectRef || emptyRef} id={props.id} name={props.name} disabled={props.disabled || false} className={"form-select "+(props.className ? props.className : '')}
                    value={props.value || defaultValue}
                    onChange={props.onChange || fn_setDefaultValue} >
                {(user.companyGbCd === 'C001005' && props.codeGb == 'siteCmpny') ? '' :
                    props.initOptionGb == 'sel' ? (<option value={''}>선택</option>) : (props.initOptionGb == 'all' ? <option value={''}>전체</option> : '')}
                {codeList.map((item: any, idx: number) => {
                    return (<option key={idx} value={item.code}>{item.codeNm}</option>)
                })}
            </select>
            :
            <select ref={props.selectRef || emptyRef} id={props.id} name={props.name} disabled={props.disabled || false} className={"form-select "+(props.className ? props.className : '')}
                    onChange={props.onChange || fn_setDefaultValue} >
                {props.initOptionGb == 'sel' ? <option value={''}>선택</option> : props.initOptionGb == 'all' ? <option value={''}>전체</option> : ''}
                {codeList.map((item: any, idx: number) => {
                    return (<option key={idx} value={item.code}>{item.codeNm}</option>)
                })}
            </select>
        }
        </React.Fragment>
    )
}
