import React, {Dispatch, SetStateAction, useEffect, useRef, useState} from "react";
import {IoMdClose} from "react-icons/io";
import BMCSelect from "../../../components/common/BMCSelect";
import {Modal, Table} from "react-bootstrap";
import BMCTreeView from "../../../components/common/BMCTreeView";
import restapi from "../../../helper/restAPIHelper";
import Datatables, {getCheckedItems} from "../../component/Datatables";
import SHSearch from "../../component/BMCSearch";
import BMCInput from "../../../components/common/BMCInput";
import axios from "axios";
import {toast} from "react-toastify";

interface IProps {
    setModalIsOpen: Dispatch<SetStateAction<boolean>>;
    modalIsOpen: boolean;
    dngrFctrDataList: any[];
    setDngrFctrDataList: any;

    lvl3Code: string;
    lvl3CodeNm: string;
    siteId: number;

}

const RskAsmntSlctModal = (props: IProps) => {
    const [dataList, setDataList] = useState<any>([])
    const [datatable, setDatatable] = useState<any>();


    const fn_afterOpenEvnt = () => {
        fn_retrieveSiteManageItemList();
    }


    function fn_closeModal() {
        props.setModalIsOpen(false);
    }


    const fn_select = () => {
        var checkedData = getCheckedItems(datatable);

        if(checkedData.length == 0){
            toast.warn('위험요인을 선택해 주십시오.');
            return;
        }

        // @ts-ignore
        checkedData = [].concat(checkedData);
        // @ts-ignore
        props.setDngrFctrDataList(props.dngrFctrDataList.concat(checkedData))

        props.setModalIsOpen(false);


    }

    const fn_retrieveSiteManageItemList = () => {

        let slctdCodeList = null;
        console.log('props.dngrFctrDataList: ', props.dngrFctrDataList);

        if(props.dngrFctrDataList.length > 0){
            slctdCodeList = [];
            props.dngrFctrDataList.map((data) => {
                slctdCodeList.push(data.code);
            })
        }

        console.log('props.siteId: ', props.siteId);

        const param = {siteId:props.siteId, code:props.lvl3Code,codeLevel:4, slctdCodeList:slctdCodeList }

        restapi.retrieveRskAsmntCodeDataList(param).then((result) => {
            setDataList(result);
        })
    }

    return (
        <div>
            <Modal
                className="bmc-modal sub" size="xl"
                show={props.modalIsOpen}
                onShow={fn_afterOpenEvnt}
                onHide={fn_closeModal}
            >

                <Modal.Header>
                    <div className="d-flex justify-content-between align-items-center w-100 bmc-font-SD">
                        [ {props.lvl3CodeNm} ] 위험요인
                        <IoMdClose className='hover:cursor-pointer' onClick={fn_closeModal}/>
                    </div>
                </Modal.Header>

                <Modal.Body className="px-4 bmc-modal-table-w100">
                    <div style={{height:'600px'}}>
                        <Datatables
                            scrollY="550px"
                            className="bmc-fs-b4"
                            columns={[
                                {data: "code", title: "코드", textAlign: 'center'},
                                {data: "codeName", title: "위험요인", textAlign: 'left'},
                                {data: "managementMethod", title: "관리방안", textAlign: 'left'},
                            ]}
                            checked={true}
                            onLoad={(dt: any) => {
                                setDatatable(dt);
                            }}
                            isNotPaging={true}
                            isNotOrdering={true}
                            datas={dataList}
                            onClick={(data) => {

                            }}
                        />
                    </div>

                </Modal.Body>
                <Modal.Footer className="py-2">
                    <div className='flex justify-center w-100'>
                        <button
                            className='bmc-btn pill l white me-3'
                            onClick={fn_closeModal}>취소
                        </button>
                        <button onClick={fn_select}
                            className='bmc-btn pill p'>선택등록
                        </button>
                    </div>
                </Modal.Footer>
            </Modal>
        </div>
    )
}

export default RskAsmntSlctModal;
