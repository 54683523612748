import React, {useEffect, useState} from "react";
import {Col, Container, Row} from "react-bootstrap";
import {ReactComponent as IconIvstTdy01} from "../../assets/images/icon/icon-ivst-tdy-01.svg";
import {ReactComponent as IconIvstTdy02} from "../../assets/images/icon/icon-ivst-tdy-05.svg";
import {ReactComponent as IconIvstTdy03} from "../../assets/images/icon/icon-ivst-tdy-04.svg";
import {ReactComponent as IconIvstTdy04} from "../../assets/images/icon/icon-ivst-tdy-03.svg";
import {ReactComponent as IconIvstTdy05} from "../../assets/images/icon/icon-ivst-tdy-02.svg";
import restapi from "../../helper/restAPIHelper";

export default function FcltyTtlChkSttsInfo(){

    const [fcltyDataList, setFcltyDataList] = useState<any>([]);
    const [fcltyChkDataList, setFcltyChkDataList] = useState<any>([]);
    const [mntncFcltyChkDataList, setMntncFcltyChkDataList] = useState<any>([]);

    useEffect(() => {
        restapi.retrieveFcltyTotDataList({}).then((rsltData: any) => {
          setFcltyDataList(rsltData);
        })

        restapi.retrieveIvstSftyManageTotDataList({workTypeCd: 'T002004'}).then((rsltData: any)=> {
            setFcltyChkDataList(rsltData);
            const mntncFcltyChkDataList = rsltData.filter((data: any) => data.ivstSprvDprtGb == 'C007004');
            setMntncFcltyChkDataList(mntncFcltyChkDataList);
        })
    }, []);


    return(
        <Container fluid className="bmc-bg-pane">
            <div className="d-flex align-items-center justify-content-between pb-2 border-bottom-v2 mb-2">
                <span className="bmc-area-title-v3">전체 점검 현황</span>
            </div>
            <div className="d-flex gap-2 w-100">
                <div style={{width: '32%'}}>
                    <div className="cursor-pointer d-flex flex-column justify-content-between p-3 h-[147px]"
                         style={{backgroundColor: '#E1EAFF', borderRadius: 16}}
                    >
                        <div className="bmc-txt-color-primary">
                            <div className="bmc-txt-color-gray-02 fs-cstm-16 fw-cstm-400">전체 관리 대상</div>
                        </div>
                        <div className="d-flex justify-content-end align-items-center">
                            <span className="bmc-txt-size-b3 fw-cstm-550">{fcltyDataList.length}</span>
                            <span className="fs-cstm-17 fw-cstm-500">건</span>
                        </div>
                    </div>
                </div>
                <div style={{width: '32%'}}>
                    <div className="cursor-pointer d-flex flex-column justify-content-between p-3 h-[147px]"
                         style={{backgroundColor: '#DBF2FF', borderRadius: 16}}
                    >
                        <div className="bmc-txt-color-primary">
                            <div className="bmc-txt-color-gray-02 fs-cstm-16 fw-cstm-400">안전점검 및 <br/>정밀 안전 진단 계획</div>
                        </div>
                        <div className="d-flex justify-content-end align-items-center">
                            <span className="bmc-txt-size-b3 fw-cstm-550">{fcltyChkDataList.length}</span>
                            <span className="fs-cstm-17 fw-cstm-500">건</span>
                        </div>
                    </div>
                </div>
                <div style={{width: '32%'}}>
                    <div className="cursor-pointer d-flex flex-column justify-content-between p-3 h-[147px]"
                         style={{backgroundColor: '#FBEAEC', borderRadius: 16}}
                    >
                        <div className="bmc-txt-color-primary">
                            <div className="bmc-txt-color-gray-02 fs-cstm-16 fw-cstm-400">보수 공사<br/>진행 상황</div>
                        </div>
                        <div className="d-flex justify-content-end align-items-center">
                            <span className="bmc-txt-size-b3 fw-cstm-550">{mntncFcltyChkDataList.length}</span>
                            <span className="fs-cstm-17 fw-cstm-500">건</span>
                        </div>
                    </div>
                </div>
            </div>
        </Container>
    )
}
