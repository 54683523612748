import React, {useContext, useEffect, useState} from 'react';
import {Col, Container, Row, Table} from "react-bootstrap";
import {ReactComponent as IconTeam} from "../../assets/images/ico/icon-team.svg";
import {TableHead} from "@mui/material";
import BMCSelect from "../../components/common/BMCSelect";
import BMCInput from "../../components/common/BMCInput";
import SHSearchButton from "../../components/common/BMCSearchButton";
import {UserContext} from "../../context/User";
import {getLoginUser} from "../../helper/SecurityHelper";
import restapi from "../../helper/restAPIHelper";
import WrkPrmtRgstModal from "../siteSafetyManagement/modal/WrkPrmtRgstModal";
import {CmmnFn} from "../../helper/CmmnHelper";
import WrkPrmtProdRgstModal from '../siteSafetyManagement/modal/WrkPrmtProdRgstModal';
import {ReactComponent as IconRight} from "../../assets/images/ico/icon-right.svg";
import {ReactComponent as IconDashSiteLine} from "../../assets/images/icon/icon-dash-site-line.svg";

import {useNavigate} from "react-router-dom";



const SSDashSiteStatus = () => {
    const {user} = useContext<any>(UserContext);
    const defaultUser = getLoginUser();
    const [dataList, setDataList] = useState<any>([]);
    const [data, setData] = useState<any>({})
    const [modalRgstIsOpen, setModalRgstIsOpen] = React.useState(false);
    const [detailData, setDetailData] = useState<any>(null);
    const navigate = useNavigate();


    useEffect(() => {
        fn_search();

    }, [user])

    const fn_setData = (e: any) => {

        setData({
            ...data,
            [e.target.name]: e.target.value
        })

    }

    const fn_retrieveWrkPrmtDataTotList = (param: any) => {
        restapi.retrieveWrkPrmtDataTotList(param).then((rsltData: any) => {
            setDataList(rsltData);
        })
    }

    const fn_search = () => {

        let param = data;
        const siteId = user?.blngSiteId || defaultUser.blngSiteId;
        param.prcsStatCd = 'S001004';
        param.siteId = siteId;
        param.wrkDt = CmmnFn.fn_getCurrentDate();
        param.isForMonitoring = true;
        param.isOrderByWrkStrtDt = true;
        fn_retrieveWrkPrmtDataTotList(param);
    }

    const fn_openDtlModal = (data: any) => {
        setDetailData(data)
        setModalRgstIsOpen(true);
    }

    return (
        <>
            <Container fluid className="bmc-bg-pane">
                <WrkPrmtProdRgstModal setModalIsOpen={setModalRgstIsOpen} modalIsOpen={modalRgstIsOpen}
                                  siteId={user?.blngSiteId || defaultUser.blngSiteId}
                                  detailData={detailData}/>
                <Row className="px-0">
                    <Col bsPrefix="col-7" className="w-100">
                        <div className="w-100 d-flex align-items-center justify-content-between pb-2 border-bottom">
                            <span className="pe-2 bmc-site-area-title">작업현장 현황</span>
                            <div className={'cursor-pointer'} onClick={() => navigate('/')}>
                                <IconRight style={{width: '20px', marginBottom: '1px', stroke: '#1d1b39'}} />
                            </div>
                        </div>
                    </Col>
                </Row>
                <Row className="mb-3">
                    <Col>
                        <div className="d-flex rounded-3 align-items-center px-3 py-3 mt-3"
                             style={{backgroundColor: '#F9F9F9'}}>
                            <div className="d-flex">
                                <div className="d-flex me-2">
                                    <div className="d-inline-flex me-2 text-nowrap gap-2" style={{fontWeight:450}}>
                                        <IconDashSiteLine className="align-self-center"/>
                                        <span className="align-self-center">업체</span>
                                    </div>
                                    <BMCSelect codeGb={'siteCmpny'} name={'cmpnySeq'} value={data.cmpnySeq} onChange={fn_setData}
                                               selParam={{siteId: user?.blngSiteId || defaultUser.blngSiteId}} className="bmc-select w-[200px] me-2 fs-7" initOptionGb="all"/>
                                </div>

                                <div className="d-flex me-2">
                                    <div className="d-inline-flex me-2 text-nowrap gap-2" style={{fontWeight:450}}>
                                        <IconDashSiteLine className="align-self-center"/>
                                        <span className="align-self-center">작업 진행 현황</span>
                                    </div>
                                    <select name={'wrkStatNm'} onChange={fn_setData} value={data.wrkStatNm} className={"text-center form-select bmc-select w-[120px] me-2 fs-7"}>
                                        <option value={''}>전체</option>
                                        <option>작업대기</option>
                                        <option>작업중</option>
                                        <option>작업완료</option>
                                    </select>

                                    <BMCInput placeholder="작업현장을 입력해주세요" name={'wrkSiteNm'} value={data.wrkSiteNm} onChange={fn_setData}
                                              className="form-control w-[300px] me-2 h-[39px] fs-7"/>
                                </div>
                            </div>
                            <div className="d-flex justify-content-end flex-grow-1">
                                <SHSearchButton className="w-[90px] h-[38px] py-2 ms-2" btnType={'p'} onClick={fn_search} />
                            </div>
                        </div>
                    </Col>
                </Row>
                <Row>
                    <Col style={{height: "calc(100vh - 560px)"}}>
                        <div data-te-perfect-scrollbar-init
                             className="relative h-[400px] overflow-auto">
                            <Table className="w-full ss-dash-site-table">
                                <thead className="text-center w-full bmc-fs-b2">
                                <tr className={'w-full'}>
                                    <th className={'w-[15%] ss-dash-site-table-th-left'}
                                        style={{backgroundColor: "#F9F9F9"}}>작업지시일
                                    </th>
                                    <th className={'w-[10%] ss-dash-site-table-th-left'}
                                        style={{backgroundColor: "#F9F9F9"}}>작업완료일
                                    </th>
                                    <th className={'w-[15%]'} style={{backgroundColor: "#F9F9F9"}}>업체</th>
                                    <th className={'w-[15%]'} style={{backgroundColor: "#F9F9F9"}}>작업현장</th>
                                    <th className={'w-[15%]'} style={{backgroundColor: "#F9F9F9"}}>작업사항</th>
                                    <th className={'w-[10%]'} style={{backgroundColor: "#F9F9F9"}}>작업인원</th>
                                    <th className={'w-[10%]'} style={{backgroundColor: "#F9F9F9"}}>작업진행현황</th>
                                    <th className={'w-[15%] ss-dash-site-table-th-right'}
                                        style={{backgroundColor: "#F9F9F9"}}>안전사고현황
                                    </th>
                                </tr>
                                </thead>
                                <tbody className="text-center w-full bmc-fs-b3 h-full">
                                {dataList.length > 0 ?
                                    dataList.map((data: any, idx: number) => (
                                        <tr className={'w-full'} key={idx} onClick={() => fn_openDtlModal(data)}>
                                            <td className={'py-3'} style={{fontSize: "12px"}}>{data.wrkStrtDt}</td>
                                            <td className={'py-3'}>{['작업완료'].includes(data.wrkStatNm) ? data.wrkDt : '-'}</td>
                                            <td className={'py-3'}>{data.companyName}</td>
                                            <td className={'py-3'}>{data.wrkSiteNm.length > 23 ? data.wrkSiteNm.substring(0, 23) + '...' : data.wrkSiteNm}</td>
                                            <td className={'py-3'}>{data.istrctns}</td>
                                            <td className={'py-3'}>{data.calTotWrkrCnt}</td>
                                            <td className={'py-3'}><span
                                                style={{color: data.wrkStatNm == '작업완료' ? '#00bf6d' : data.wrkStatNm == '작업중' ? '#5685f7': '#ffc555'}}>{data.wrkStatNm}</span>
                                            </td>
                                            <td className={'py-3'}>-</td>
                                        </tr>
                                    ))
                                    : <tr className={'w-full'}>
                                        <td className={'p-3 w-100'} colSpan={7}>금일 등록된 작업이 없습니다.</td>
                                    </tr>
                                }
                                </tbody>
                            </Table>
                        </div>
                    </Col>
                </Row>
            </Container>
        </>
    );
}

export default SSDashSiteStatus;
