import {Col, Modal, Row, Table} from "react-bootstrap";
import {IoMdClose} from "react-icons/io";

import React, {Dispatch, SetStateAction, useContext, useEffect, useRef, useState} from "react";
import {CmmnFn, fn_getCurrentDate} from "../../../helper/CmmnHelper";
import {getLoginUser} from "../../../helper/SecurityHelper";

import axios from "axios";
import {toast} from "react-toastify";
import messageCodeDic from "../../../util/messageCodeDic";
import errorCodeDic from "../../../util/errorCodeDic";
import BMCInput from "../../../components/common/BMCInput";
import BMCTextarea from "../../../components/common/BMCTextarea";
import BMCSelect from "../../../components/common/BMCSelect";
import iconTrashBean from "../../../images/icon_remove.png";
import restapi from "../../../helper/restAPIHelper";
import BMCFile from "../../../components/common/BMCFile";
import {ProgressContext} from "../../../context/Progress";
import BMCIconButton from "../../../components/common/BMCIconButton";
import {Container} from "@mui/material";
import BMCDatePicker2 from "../../../components/common/BMCDatePicker2";
import {DateHelper} from "../../../helper/DateHelper";
import BMCRadio from "../../../components/common/BMCRadio";
import BMCRadioGroup from "../../../components/common/BMCRadioGroup";
import {validFormData} from "../../../helper/FormHelper";
import BMCDatePicker from "../../../components/common/BMCDatePicker";
import CustomDatePicker from "../../component/ui/DatePicker";



interface IProps {
    setModalIsOpen: Dispatch<SetStateAction<boolean>>;
    modalIsOpen: boolean;
    detailData: any;
    setIsSaveSuccess?: Dispatch<SetStateAction<boolean>>;
    siteId: number;
}

const RiskEvalChkDtlModal = (props: IProps) => {

    const currentDate = fn_getCurrentDate();
    const user = getLoginUser();

    const [data, setData] = useState<any>({})

    const formRef = React.createRef<HTMLFormElement>();
    const {spinner} = useContext(ProgressContext);
    const fileRef = useRef() as React.MutableRefObject<any>;
    const [imgTarget, setImgTarget] = useState<any>(null);
    const prodRef = useRef<any>();
    const [isDatePopupOpen, setIsDatePopupOpen] = useState(false);
    const [chkFileDataList, setChkFileDataList] = useState<any>([]);
    const [actFileDataList, setActFileDataList] = useState<any>([]);
    const [isActDatePopupOpen, setIsActDatePopupOpen] = useState(false);
    const actProdRef = useRef<any>();
    const [actDt, setActDt] = useState<string>('');

    const fn_setFormData = (e: any) => {
        setData({
            ...data,
            [e.target.name]: e.target.value
        })
    }


    useEffect(() => {
        if(actDt){
            setData({
                ...data,
                ['actDt']: actDt
            })
        }
    }, [actDt]);


    function afterOpenModal() {



        setData(props.detailData);
        const chkFileId = props.detailData.chkFileId;
        const actFileId = props.detailData.actFileId;
        if (chkFileId) {
            restapi.retrieveAttachInfoList({attachSeq: chkFileId}).then((rsltData: any) => {
                setChkFileDataList([].concat(rsltData))
            })
        } else {
            setChkFileDataList([]);
        }

        if(actFileId){
            restapi.retrieveAttachInfoList({attachSeq: actFileId}).then((rsltData: any) => {
                setActFileDataList([].concat(rsltData))
            })
        }else{
            setActFileDataList([]);
        }


    }

    function fn_closeModal() {
        setActDt('');
        props.setModalIsOpen(false);
    }

    const submit = (e: any) => {
        e.preventDefault()

    }

    const fn_registData = () => {
        var option: any = {};
        var formData: any;
        var param: any = data;
        var cnfrmMsg = '';

        // 점검 전
        if(!data.chckrSeq) {
            console.log('chkRsltCd: ', data.chkRsltCd);
            if(!data.chkRsltCd) {
                toast.warn('점검결과를 입력해 주십시오.');
                return;
            }

            if(data.chkRsltCd == 'R005002' && !data.chkCntnts){
                toast.warn('점검내용을 입력해 주십시오.');
                return;
            }

            if(data.chkRsltCd == 'R005002' && chkFileDataList.length == 0){
                toast.warn('점검사진을 등록해 주십시오.');
                return;
            }



            // @ts-ignore
            var formData: any = new FormData(formRef.current)

            if(chkFileDataList.length > 0){
                for(var i = 0; i < chkFileDataList.length; i++){
                    formData.append('chkFileList',chkFileDataList[i]);
                }
            }
            param.chckrSeq = user.userSeq
            param.chkDt = CmmnFn.fn_getCurrentDate('empty');
            formData.set('jsonData', new Blob([JSON.stringify(param)], {type: "application/json"}))
            cnfrmMsg = '점검 등록 하시겠습니까?';

        }else{
            if(!data.actDt){
                toast.warn('조치 일자를 선택해 주십시오.')
                return;
            }

            if(!data.actOpin){
                toast.warn('조치의견을 입력해 주십시오.')
                return;
            }

            if(actFileDataList.length == 0){
                toast.warn('조치사진을 등록해 주십시오.');
                return;
            }

            // @ts-ignore
            var formData: any = new FormData(formRef.current)

            if(actFileDataList.length > 0){
                for(var i = 0; i < actFileDataList.length; i++){
                    formData.append('actFileList',actFileDataList[i]);
                }
            }
            param.actrSeq = user.userSeq
            formData.set('jsonData', new Blob([JSON.stringify(param)], {type: "application/json"}))

            cnfrmMsg = '조치 등록 하시겠습니까?';
        }

        if(!window.confirm(cnfrmMsg)){
            return;
        }
        spinner.start();
        axios.post('/admin/api/rskAsmnt/saveRiskAsmntFctrChkData', formData).then((result) => {

            if (result.data > 0) {
                toast.success(messageCodeDic['001'])
                props.setModalIsOpen(false);
            } else {
                toast.error(errorCodeDic['003']);
            }
        }).catch(error => {
            toast.error(errorCodeDic['003']);
            console.log('error: ', error);
        })
            .finally(() => {
                spinner.stop();
            });


    }

    const fn_onfileChange = (e: any) => {
        console.log('e.target.files: ', e.target.files);
        let cstmData = data;
        if(cstmData === null){
            cstmData = {}
        }
        if (e.target.files.length > 0) {
            const file = e.target.files[0];
            const imgSrc = URL.createObjectURL(file);
            if (imgTarget == 'bf') {
                cstmData.fileTmpSrc = imgSrc;
                cstmData.bfAtchFile = file;
                cstmData.isPhotoUpload = true;
                setData(Object.assign({}, cstmData));
            } else if (imgTarget == 'af') {
                cstmData.fileAfTmpSrc = imgSrc;
                cstmData.afAtchFile = file;
                cstmData.isPhotoUpload = true;
                setData(Object.assign({}, cstmData));
            }
        }

    }


    return (
        <div>

            <Modal
                className="bmc-modal" size="xl"
                show={props.modalIsOpen}
                onShow={afterOpenModal}
                onHide={fn_closeModal}

            >
                {isActDatePopupOpen
                    ? <div className='absolute top-200 left-1/3'>
                        <CustomDatePicker setIsDatePopupOpen={setIsActDatePopupOpen}
                                          prodRef={actProdRef}
                                          setDateView={setActDt}
                        />
                    </div>
                    : null}
                <Modal.Header>
                    <div className="d-flex justify-content-between align-items-center w-100 bmc-font-SD">
                        위험성평가 점검 상세보기
                        <IoMdClose className='hover:cursor-pointer' onClick={fn_closeModal}/>
                    </div>
                </Modal.Header>

                <Modal.Body className="px-4">
                    <input hidden type={'file'} ref={fileRef} onChange={fn_onfileChange}/>
                    <form name={'form'} ref={formRef} encType="multipart/form-data" method={"post"}
                          onSubmit={submit}>
                        <div className="bmc-modal-div">
                            <div className="div-header">
                                기본 정보
                            </div>

                            <div className="div-body px-3">
                                <Table className="my-2 align-middle">
                                    <tbody>
                                    <tr className="border-top align-middle">
                                        <th className="bmc-bg-th" style={{width: '15%'}}>업체</th>
                                        <td style={{width: '35%'}}>
                                            <BMCInput type="text" className="form-control" name={'cmpnyNm'} readOnly={true}
                                                     onChange={fn_setFormData} value={data.cmpnyNm}/>
                                        </td>
                                        <th className="bmc-bg-th" style={{width: '15%'}}>작성자</th>
                                        <td style={{width: '35%'}}>
                                            <BMCInput type="text" className="form-control" name={'rgstrNm'} readOnly={true}
                                                     onChange={fn_setFormData} value={data.rgstrNm}/>
                                        </td>
                                    </tr>
                                    <tr className="border-top align-middle">
                                        <th className="bmc-bg-th" style={{width: '15%'}}>평가기간</th>
                                        <td style={{width: '35%'}}>
                                            <BMCInput type="text" className="form-control" name={'raProd'} readOnly={true}
                                                     onChange={fn_setFormData} value={data.raProd}/>
                                        </td>
                                        <th className="bmc-bg-th" style={{width: '15%'}}>세부공종</th>
                                        <td style={{width: '35%'}}>
                                            <BMCInput type="text" className="form-control" name={'wrkTpNm'} readOnly={true}
                                                     onChange={fn_setFormData} value={data.wrkTpNm}/>
                                        </td>
                                    </tr>

                                    <tr className="border-top align-middle">
                                        <th className="bmc-bg-th" style={{width: '15%'}}>작업단계</th>
                                        <td style={{width: '35%'}}>
                                            <BMCInput type="text" className="form-control" name={'wrkLvlNm'} readOnly={true}
                                                     onChange={fn_setFormData} value={data.wrkLvlNm}/>
                                        </td>
                                    </tr>
                                    </tbody>
                                </Table>
                            </div>
                        </div>

                        <div className="bmc-modal-div">
                            <div className="div-header">
                                위험 내용
                            </div>

                            <div className="div-body px-3">
                                <Table className="my-2 align-middle">
                                    <tbody>
                                    <tr className="border-top align-middle">
                                        <th className="bmc-bg-th" style={{width: '15%'}}>위험요인</th>
                                        <td colSpan={3} style={{width: '85%'}}>
                                            <BMCInput type="text" className="form-control" name={'dngrFctr'} readOnly={true}
                                                     onChange={fn_setFormData} value={data.dngrFctr}/>
                                        </td>
                                    </tr>
                                    <tr className="border-top align-middle">
                                        <th className="bmc-bg-th" style={{width: '15%'}}>관리방안</th>
                                        <td colSpan={3} style={{width: '85%'}}>
                                            <BMCInput type="text" className="form-control" name={'managementMethod'} readOnly={true}
                                                     onChange={fn_setFormData} value={data.managementMethod}/>
                                        </td>
                                    </tr>
                                    <tr className="border-top align-middle">
                                        <th className="bmc-bg-th" style={{width: '15%'}}>위험등급</th>
                                        <td style={{width: '35%'}}>
                                            <BMCInput type="text" className="form-control" name={'riskGrdNm'} readOnly={true}
                                                     onChange={fn_setFormData} value={data.riskGrdNm}/>
                                        </td>
                                        <th className="bmc-bg-th" style={{width: '15%'}}>피해형태</th>
                                        <td style={{width: '35%'}}>
                                            <BMCInput type="text" className="form-control" name={'damageForm'} readOnly={true}
                                                     onChange={fn_setFormData} value={data.damageForm}/>
                                        </td>

                                    </tr>
                                    </tbody>
                                </Table>
                            </div>
                        </div>


                        <div className="bmc-modal-div under mb-2">
                            <div className="div-header">
                                <div>
                                    점검 내용
                                </div>
                            </div>
                            <div className="div-body px-3">
                                <Table className="my-2 align-middle">
                                    <tbody>
                                    <tr className="border-top align-middle">
                                        <th className="bmc-bg-th" style={{width: '15%'}}>점검자</th>
                                        <td style={{width: '35%'}}>
                                            <BMCInput type="text" className="form-control" name={'chckrNm'} readOnly={true}
                                                     onChange={fn_setFormData} value={data.chckrNm || user.userName}/>
                                        </td>
                                        <th className="bmc-bg-th" style={{width: '15%'}}>점검결과</th>
                                        <td style={{width: '35%'}}>
                                            <BMCRadioGroup>
                                                <BMCRadio name="chkRsltCd" value={'R005001'} checked={data && data.chkRsltCd === 'R005001'} onChange={(e: any) => {!data.chckrSeq && fn_setFormData(e)}} >
                                                   <span className={'text-green-500 font-semibold'}>양호</span>
                                                </BMCRadio>
                                                <BMCRadio name="chkRsltCd" className={'ml-10'} value={'R005002'} checked={data&& data.chkRsltCd === 'R005002'} onChange={(e: any) => {!data.chckrSeq && fn_setFormData(e)}}>
                                                    <span className={'text-red-500 font-semibold'}>불량</span>
                                                </BMCRadio>
                                            </BMCRadioGroup>
                                        </td>
                                    </tr>

                                    {data.chkRsltCd === 'R005002' &&
                                        <>
                                        <tr className="border-top align-middle">
                                            <th className="bmc-bg-th" style={{width: '15%'}}>점검내용</th>
                                            <td colSpan={3} style={{width: '85%'}}>
                                                <BMCInput name={'chkCntnts'} onChange={fn_setFormData}
                                                     className={'form-control'}
                                                     value={data.chkCntnts}></BMCInput>
                                            </td>

                                        </tr>

                                        <tr className="border-top align-middle">
                                            <th className="bmc-bg-th" style={{width: '15%'}}>점검사진</th>
                                            <td colSpan={3} style={{width: '85%'}}>
                                                {data.chckrSeq ?
                                                    <div className="filebox w-100 d-flex ">
                                                        {
                                                    chkFileDataList.map((data: any, idx: number) => (
                                                        <img key={idx} className={'max-h-[200px] mr-10'}
                                                             src={process.env.REACT_APP_FILE_URL + "/bmcss-data/fileDownload?attachSeq=" + data.attachSeq + '&sn=' + data.sn + '&isPdf=0&time=' + new Date().getTime()}/>
                                                    ))
                                                        }
                                                    </div>

                                                    :
                                                    <BMCFile maxFileCnt={5}  fileName={'chkFileDataList'} fileList={chkFileDataList} setFileList={setChkFileDataList}></BMCFile>
                                                }
                                            </td>

                                        </tr>

                                        </>

                                    }




                                    </tbody>
                                </Table>
                            </div>
                        </div>


                        {(data.chkRsltCd === 'R005002' && data.chckrSeq) &&
                            <div className="bmc-modal-div under mb-2">
                                <div className="div-header">
                                    <div>
                                        조치 내용
                                    </div>
                                </div>
                                <div className="div-body px-3">
                                    <Table className="my-2 align-middle">
                                        <tbody>
                                        <tr className="border-top align-middle">
                                            <th className="bmc-bg-th" style={{width: '15%'}}>조치 완료</th>
                                            <td style={{width: '35%'}} ref={actProdRef}>
                                                <BMCDatePicker
                                                    className={"text-center"}
                                                    onClick={() => setIsActDatePopupOpen(true)}
                                                    calendar={() => setIsActDatePopupOpen(true)}
                                                    value={data.actDt} name={'actDt'}
                                                    onChange={fn_setFormData}
                                                    readOnly={true}/>
                                            </td>
                                            <th className="bmc-bg-th" style={{width: '15%'}}>조치담당자</th>
                                            <td style={{width: '35%'}}>
                                                <input className={"form-control"}
                                                       value={data.actrNm || user.userName} disabled={true}/>
                                            </td>
                                        </tr>
                                        <tr className="border-top align-middle">
                                            <th className="bmc-bg-th" style={{width: '15%'}}>조치의견</th>
                                            <td colSpan={3} style={{width: '85%'}}>
                                                <BMCInput name={'actOpin'} onChange={fn_setFormData}
                                                         className={'form-control'}
                                                         value={data.actOpin}></BMCInput>
                                            </td>
                                        </tr>
                                        <tr className="border-top align-middle">
                                            <th className="bmc-bg-th" style={{width: '15%'}}>조치사진</th>
                                            <td colSpan={3} style={{width: '85%'}}>
                                                {data.actrSeq ?
                                                    <div className="filebox w-100 d-flex ">
                                                        {
                                                            actFileDataList.map((data: any, idx: number) => (
                                                                <img key={idx} className={'max-h-[200px] mr-10'}
                                                                     src={process.env.REACT_APP_FILE_URL + "/bmcss-data/fileDownload?attachSeq=" + data.attachSeq + '&sn=' + data.sn + '&isPdf=0&time=' + new Date().getTime()}/>
                                                            ))
                                                        }
                                                    </div>
                                                    :
                                                    <BMCFile maxFileCnt={5}  fileName={'chkFileDataList'} fileList={actFileDataList} setFileList={setActFileDataList}></BMCFile>
                                                }
                                            </td>

                                        </tr>
                                        </tbody>
                                    </Table>
                                </div>
                            </div>

                        }


                    </form>
                </Modal.Body>
                <Modal.Footer className="py-2">
                    <div className='flex justify-center w-100'>
                        <button
                            className='bmc-btn pill l me-3'
                            onClick={fn_closeModal}>닫기
                        </button>
                        {(!data.chckrSeq || (data.chckrSeq &&data.chkRsltCd == 'R005002') && !data.actrSeq) &&
                        <button onClick={fn_registData}
                                className='bmc-btn pill p white'>{!data.chckrSeq ? '점검등록' : '조치등록'}
                        </button>
                        }

                    </div>
                </Modal.Footer>


            </Modal>
        </div>
    )
}

export default RiskEvalChkDtlModal;

