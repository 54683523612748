import React, {useContext, useEffect, useRef, useState} from 'react';
import axios from "axios";
import Datatables, {getCheckedItems} from "../component/Datatables";
import Modal from 'react-modal';
import {getLoginUser} from "../../helper/SecurityHelper";
import {toast} from "react-toastify";
import errorCodeDic from "../../util/errorCodeDic";
import {Col, Container, Row, Tab, Table, Tabs} from 'react-bootstrap';
import SubMenuTitle from "../component/SubMenuTitle";
import restapi from "../../helper/restAPIHelper";
import {ProgressContext} from "../../context/Progress";
import {CmmnFn} from "../../helper/CmmnHelper";
import IncgrtyPicModal from "../siteSafetyManagement/modal/IncgrtyPicModal";
import AlrtMngStatWrkStopPage from "./subPage/AlrtMngStatWrkStopPage";
import AlrtMngStatSosPage from "./subPage/AlrtMngStatSosPage";
import WrkDisruptRqstHisModal from "../noticeManagement/modal/WrkDisruptRqstHisModal";
import SosRqstHisModal from "../noticeManagement/modal/SosRqstHisModal";


type Props = {};


Modal.setAppElement('#root');
const AlrtMngStatPage = (props: Props) => {

    const user = getLoginUser();
    const {spinner} = useContext(ProgressContext);
    const [dataList, setDataList] = useState<any>([]);
    const [srchData, setSrchData] = useState<any>({workTypeCd: 'T002004', strtDt:CmmnFn.getAmonthAgoDate(), endDt:CmmnFn.fn_getCurrentDate(), tabGb: 'wrkStop'});
    const [statData, setStatData] = useState<any>({totSiteCnt: 0, noSiteCnt: 0, totWrkrCnt: 0, noWrkrCnt: 0});
    const [wrkModalIsOpen, setWrkModalIsOpen] = useState<boolean>(false);
    const [sosModalIsOpen, setSosModalIsOpen] = useState<boolean>(false);

    const [wrkrDataList, setWrkrDataList] = useState<any>([]);
    const [selData, setSelData] = useState<any>(null);
    const [picDtlData, setPicDtlData] = useState<any>({});
    const [isPicModalOpen, setIsPicModalOpen] = useState<boolean>(false);
    const [isDatePopupOpen, setIsDatePopupOpen] = useState<boolean>(false);

    const prodRef = useRef<any>();

    useEffect(() => {
        if(!wrkModalIsOpen && !sosModalIsOpen){
            fn_search();
        }
    }, [srchData,wrkModalIsOpen, sosModalIsOpen])

    const fn_search = () => {

        spinner.start();

        if(srchData.tabGb == 'wrkStop'){
            restapi.retrieveWrkDisruptHisTotDataList(srchData).then((rsltData: any) => {
                setDataList(rsltData)
            }).catch(error => {
                toast.error(errorCodeDic['003']);
                console.log('error: ', error);
            })
                .finally(() => {
                    spinner.stop();
                });
        }else if(srchData.tabGb == 'sos'){
            restapi.retrieveSosHisTotDataList(srchData).then((rsltData: any) => {
                setDataList(rsltData)
            }).catch(error => {
                toast.error(errorCodeDic['003']);
                console.log('error: ', error);
            })
                .finally(() => {
                    spinner.stop();
                });
        }


    }

    return (
        <Container fluid className="bg-white px-0 pb-4 bmc-text-font_primary">
            <WrkDisruptRqstHisModal setModalIsOpen={setWrkModalIsOpen} modalIsOpen={wrkModalIsOpen} detailData={selData}/>
            <SosRqstHisModal setModalIsOpen={setSosModalIsOpen} modalIsOpen={sosModalIsOpen} detailData={selData}/>
            <IncgrtyPicModal detailData={picDtlData} setModalIsOpen={setIsPicModalOpen} modalIsOpen={isPicModalOpen} />
            <SubMenuTitle title={'비상알림 관리 현황'} parentMenu={'안전정보관리'}/>
            <div className="p-4 overflow-y-auto" style={{height: 'calc(100vh - 180px)'}}>
                <Tabs
                    transition={true}
                    activeKey={srchData.tabGb}
                    onSelect={(k) =>{

                        setSrchData({
                            ...srchData,
                            'tabGb': k,
                        })}}
                    id="tabGb"
                    className="mb-3 mt-2"
                >

                    <Tab eventKey="wrkStop" title="작업 중지 요청">
                        <AlrtMngStatWrkStopPage dataList={dataList} setSelData={setSelData} srchData={srchData} setSrchData={setSrchData} isDatePopupOpen={isDatePopupOpen} setIsDatePopupOpen={setIsDatePopupOpen} onSearch={fn_search} setModalIsOpen={setWrkModalIsOpen} setPicDtlData={setPicDtlData} setIsPicModalOpen={setIsPicModalOpen} />
                    </Tab>
                    <Tab eventKey="sos" title="SOS 응급호출">
                        <AlrtMngStatSosPage dataList={dataList} setSelData={setSelData} srchData={srchData} setSrchData={setSrchData} isDatePopupOpen={isDatePopupOpen} setIsDatePopupOpen={setIsDatePopupOpen} onSearch={fn_search} setModalIsOpen={setSosModalIsOpen} setPicDtlData={setPicDtlData} setIsPicModalOpen={setIsPicModalOpen} />
                    </Tab>

                </Tabs>
            </div>
        </Container>


    );



};

export default AlrtMngStatPage;
