import React, {createContext, useContext, useEffect, useRef, useState} from 'react';
import {fn_loginHandlerCallback} from "../helper/CmmnHelper";
import {getLoginUser} from "../helper/SecurityHelper";
import {transform} from "ol/proj";
import {Feature} from "ol";
import {Point} from "ol/geom";
import {UserContext} from "./User";
import {IntervalContext} from "./Interval";

const PushContext = createContext({
  push: null,
  setPush: ({}: any) => {},
  removeMessage: ({}: any) => {},
  getMessages: () => {},
});

const PushProvider = ({children}: any) => {
  const [socketConnected, setSocketConnected] = useState(false);
  const [items, setItems] = useState([]);
  const webSocketUrl = process.env.REACT_APP_SOCKET_URL;
  const {setInterval} = useContext<any>(IntervalContext);
  let ws = useRef(null);
  const defaultUser: any = getLoginUser();
  function wsconnect(){
    try{
      // @ts-ignore
      ws.current = new WebSocket(webSocketUrl);
      // @ts-ignore
      ws.current.onopen = () => {
        setSocketConnected(true);
      };
      // @ts-ignore
      ws.current.onclose = (error: any) => {
        console.log("disconnect from " + webSocketUrl);
        console.log(error);
        setTimeout(function() {
          wsconnect();
        }, 1000);
      };
      // @ts-ignore
      ws.current.onerror = (error: any) => {
        console.log("connection error " + webSocketUrl);
        console.log(error);
      };
      // @ts-ignore
      ws.current.onmessage = (evt: any) => {
        const data = JSON.parse(evt.data);
        //setItems([data]);
          if(data){
            setInterval(Object.assign({}, data));
            if(data.make === 'BMC_ALERT'){
              // @ts-ignore
              setItems((prevItems: []) => [...prevItems, data]);
            }else if(data.make === 'BMC_DANGER'){
              // @ts-ignore
              var map = window.___dashboard_map
              // @ts-ignore
              var layer = window.___dangerLayer
              if(layer){
                layer.getSource().clear()
                var points = data.points
                var features = []
                for(var i in points){
                  var p = points[i]
                  var center = transform([p.longitude, p.latitude], 'EPSG:4326', 'EPSG:3857')
                  var feature = new Feature({
                    geometry: new Point(center),
                  })
                  feature.set("FEATURE_TYPE", "DANGER")
                  feature.set("FEATURE_GB", p.markGb)
                  feature.set("SITE_ID", p.siteId)
                  feature.set("SITE_NAME", p.siteName)
                  features.push(feature)
                }
                layer.getSource().addFeatures(features)
              }
            }
          }
      };
    }catch (e){
      console.error(e)
      setTimeout(function() {
        wsconnect();
      }, 3000);
    }
  }
  useEffect(() => {
    wsconnect()
    return () => {
      console.log("clean up");
      // @ts-ignore
      ws.current.close();
    };
  }, []);

  useEffect(() => {
    if(items.length > 0){
      // @ts-ignore
      setPush({isOpen: true, messages:items})
    }
    // @ts-ignore
  },[items])
  function removeMessage(target: any){
    var r = items.filter((item: any) => item.uuid !== target.uuid)
    setItems(r);
    return r;
  }
  function getMessages(){
    return items;
  }
  const [push, setPush] = useState(null);
  const value: any = {push, setPush, items, removeMessage, getMessages};
  return <PushContext.Provider value={value}>{children}</PushContext.Provider>;
};

export {PushContext, PushProvider};
