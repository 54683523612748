import Modal from 'react-bootstrap/Modal';
import {IoMdClose} from "react-icons/io";
import React, {Dispatch, MutableRefObject, SetStateAction, useContext, useEffect, useRef, useState} from "react";

import {Col, Container, ModalBody, Row, Table} from "react-bootstrap";

import axios from "axios";
import {toast} from "react-toastify";
import {fn_getCurrentDate} from '../../helper/CmmnHelper';
import {getLoginUser} from '../../helper/SecurityHelper';
import {ProgressContext} from '../../context/Progress';
import restapi from "../../helper/restAPIHelper";
import {UserContext} from "../../context/User";
import errorCodeDic from "../../util/errorCodeDic";
import SubMenuTitle from "../component/SubMenuTitle";
import CmpnyCardList from "../../components/cmpny/CmpnyCardList";
import BMCFile from "../../components/common/BMCFile";
import BMCInput from "../../components/common/BMCInput";
import messageCodeDic from "../../util/messageCodeDic";
import BMCTextarea from "../../components/common/BMCTextarea";
import {NUMBER, validFormData} from "../../helper/FormHelper";
import BMCSelect from "../../components/common/BMCSelect";
import CustomDatePickerRange from "../component/ui/DatePickerRange";
import BMCIconButton from "../../components/common/BMCIconButton";
import BMCDatePicker from '../../components/common/BMCDatePicker';
import MainSiteInfoManagePage from "./MainSiteInfoManagePage";
import SubSiteInfoManagePage from "./SubSiteInfoManagePage";


type IProps = {};


const ConstructionBaseInfoManagementPage = (props: IProps) => {
    const defaultUser = getLoginUser();

    return (
        (defaultUser.companyGbCd === 'C001002' || defaultUser.authGrpId == 'HEAD') ? <MainSiteInfoManagePage />
            : <SubSiteInfoManagePage />

    )
}

export default ConstructionBaseInfoManagementPage;

