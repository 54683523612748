import React, {useContext, useEffect, useState} from "react";
import {Container, Row, Col} from "react-bootstrap";
import {UserContext} from "../../context/User";
import {getLoginUser} from "../../helper/SecurityHelper";
import restapi from "../../helper/restAPIHelper";
import {CmmnFn} from "../../helper/CmmnHelper";
import {ReactComponent as IconSosCall} from "../../assets/images/icon/icon-sos-alert.svg";
import {ReactComponent as IconWrkStop} from "../../assets/images/icon/icon-wrk-stop.svg";
import {ReactComponent as IconExclamationMark} from "../../assets/images/icon/icon-exclamation-mark.svg";

import {ReactComponent as IconTrafficLightGreen} from "../../assets/images/icon/icon-traffic-light-green.svg";
import {ReactComponent as IconTrafficLightRed} from "../../assets/images/icon/icon-traffic-light-red.svg";
import {ReactComponent as IconTrafficLightYello} from "../../assets/images/icon/icon-traffic-light-yellow.svg";
import WrkStopInfoListModal from "../headoffice/modal/WrkStopInfoListModal";
import SosInfoListModal from "../headoffice/modal/SosInfoListModal";
import {PushContext} from "../../context/Push";
import {IntervalContext} from "../../context/Interval";
import DngrTrfcLgndModal from "./modal/DngrTrfcLgndModal";



const SiteRiskStatus = () => {
    const [sosDataList, setSosDataList] = useState<any>([]);
    const [wrkStopDataList, setWrkStopDataList] = useState<any>([]);
    const [sosfltrdDataList, setSosfltrdDataList] = useState<any>([]);
    const [wrkStopFltrdDataList, setWrkStopFltrdDataList] = useState<any>([]);
    const {user} = useContext<any>(UserContext);
    const defaultUser = getLoginUser();
    const [sosModalIsOpen, setSosModalIsOpen] = useState<boolean>(false);
    const [sosStat, setSosStat] = useState<any>({clear: 0, total: 0});
    const [wrkStopStat, setWrkStopStat] = useState<any>({clear: 0, total: 0});
    const [wrkStopModalIsOpen, setWrkStopModalIsOpen] = useState<boolean>(false);
    const {push} = useContext<any>(PushContext);
    const {interval} = useContext<any>(IntervalContext);
    const [isLgndModalOpen, setIsLgndModalOpen] = useState<boolean>(false);

    useEffect(() => {
        console.log('interval ..')
        fn_retrieveData();
    }, [user, interval])



    const fn_retrieveData = () => {
        restapi.retrieveSosHisTotDataList({rgstDt: CmmnFn.fn_getCurrentDate()}).then((rsltData: any) => {
            console.log('sos rsltData: ', rsltData);

            setSosStat({clear: rsltData.filter((data: any) => (['P001002','P001003'].includes(data.statCd))).length, total: rsltData.length})
            setSosDataList(rsltData);
        })

        restapi.retrieveWrkDisruptHisTotDataList({rgstDt: CmmnFn.fn_getCurrentDate()}).then((rsltData: any) => {
            console.log('stop rsltData: ', rsltData);

            setWrkStopStat({clear: rsltData.filter((data: any) => (['P001002','P001003'].includes(data.statCd))).length, total: rsltData.length})
            setWrkStopDataList(rsltData);
        })
    }

    const fn_openSosModal = (statCd?:string) => {
        let cstmSosDataList = sosDataList;
        if(statCd){
            cstmSosDataList = cstmSosDataList.filter((data: any) => (['P001002','P001003'].includes(data.statCd)));
        }

        setSosfltrdDataList(cstmSosDataList);

        setSosModalIsOpen(true);
    }
    const fn_openWrkStopInfoListModal = (statCd?:string) => {
        let cstmWrkStopDataList = wrkStopDataList;
        if(statCd){
            cstmWrkStopDataList = cstmWrkStopDataList.filter((data: any) => (['P001002','P001003'].includes(data.statCd)));
        }
        setWrkStopFltrdDataList(cstmWrkStopDataList);

        setWrkStopModalIsOpen(true);
    }

    return(
        <>
            <DngrTrfcLgndModal setModalIsOpen={setIsLgndModalOpen} modalIsOpen={isLgndModalOpen} />
            <WrkStopInfoListModal setModalIsOpen={setWrkStopModalIsOpen} modalIsOpen={wrkStopModalIsOpen} dataList={wrkStopFltrdDataList} />
            <SosInfoListModal setModalIsOpen={setSosModalIsOpen} modalIsOpen={sosModalIsOpen} dataList={sosfltrdDataList} />
            <Container fluid className="bmc-site-bg-pane">
                <Row className={"mb-2"}>
                    <Col>
                        <div className="d-flex flex-column justify-content-between">
                            <div className="w-100 d-flex align-items-center justify-content-between pb-1 border-bottom-v2">
                                <span className="bmc-site-area-title">현장 위험도 현황(위험신호등)</span>
                                <IconExclamationMark width={22} height={22} className="align-self-center cursor-pointer"  onClick={() => setIsLgndModalOpen(true)}/>
                            </div>
                        </div>
                    </Col>
                </Row>
                <Row className="mb-2">
                    <Col>
                        <div className="d-flex flex-row w-100 gap-2">
                            <div className="d-flex flex-column site-monitoring-traffic-light-area align-items-center w-[40%] pt-3">
                                <div className="rounded-pill d-flex justify-content-between align-items-center site-monitoring-traffic-light">


                                    <div className="ms-2 rounded-circle d-flex justify-content-center align-items-center" style={{width: '33px', height: '33px', border: '1px solid #f2f1f1'}} >
                                        <div className="rounded-circle" style={{width: '28px', height: '28px', backgroundColor: ((wrkStopStat.total - wrkStopStat.clear) > 0 || (sosStat.total - sosStat.clear) > 0) ? '#E0E0E0' : '#0DD7A6', boxShadow: 'inset 0 10px 0 rgba(0,0,0,0.1)'}} />
                                    </div>
                                    <div className="rounded-circle d-flex justify-content-center align-items-center" style={{width: '33px', height: '33px', border: '1px solid #f2f1f1'}} >
                                        <div className="rounded-circle" style={{width: '28px', height: '28px', backgroundColor: (wrkStopStat.total - wrkStopStat.clear) > 0 ? '#FF8F51' : '#E0E0E0', boxShadow: 'inset 0 10px 0 rgba(0,0,0,0.1)'}} />
                                    </div>
                                    <div className="me-2 rounded-circle d-flex justify-content-center align-items-center" style={{width: '33px', height: '33px', border: '1px solid #f2f1f1'}} >
                                        <div className="rounded-circle" style={{width: '28px', height: '28px', backgroundColor: (sosStat.total - sosStat.clear) > 0 ? '#FF5656' : '#E0E0E0', boxShadow: 'inset 0 10px 0 rgba(0,0,0,0.1)'}} />
                                    </div>
                                </div>
                                <div onClick={() => setIsLgndModalOpen(true)} className="cursor-pointer site-traffic-light-explanation d-flex flex-column ps-3 pt-3">
                                    <div className="d-flex">
                                        <IconTrafficLightGreen />
                                        <span>안전작업중</span>
                                    </div>
                                    <div className="d-flex">
                                        <IconTrafficLightYello />
                                        <span>작업중지 요청 발생</span>
                                    </div>
                                    <div className="d-flex">
                                        <IconTrafficLightRed />
                                        <span>SOS 응급 호출</span>
                                    </div>
                                </div>
                            </div>
                            <div className="d-flex flex-column w-[60%] gap-2">
                                <div className="d-flex flex-column site-sos-wrk-stop-info p-2 w-100">
                                    <div className="d-flex mb-1">
                                        <IconWrkStop width={30} height={30} className="align-self-center"/>
                                        <span className="d-flex align-self-center mb-1 ms-2">작업중지요청</span>
                                    </div>
                                    <div className="d-flex w-100 justify-content-between sos-wrk-stop-total">
                                        <div className="d-flex w-100 justify-content-between">
                                            <span className="d-flex align-self-center">해제</span>
                                            <span className="d-flex fs-6 cursor-pointer" onClick={() => fn_openWrkStopInfoListModal('P001002')}>{wrkStopStat.clear}</span>
                                        </div>
                                        <span className="px-3">|</span>
                                        <div className="d-flex justify-content-between w-100">
                                            <span className="d-flex align-self-center">전체</span>
                                            <span className="d-flex fs-6 cursor-pointer" onClick={() => fn_openWrkStopInfoListModal()}>{wrkStopStat.total}</span>
                                        </div>
                                    </div>
                                </div>
                                <div className="d-flex flex-column site-sos-wrk-stop-info p-2 w-100">
                                    <div className="d-flex mb-1">
                                        <IconSosCall width={30} height={30} className="align-self-center"/>
                                        <span className="d-flex align-self-center mb-1 ms-2">긴급 위험 알람 호출</span>
                                    </div>
                                    <div className="d-flex w-100 justify-content-between sos-wrk-stop-total">
                                        <div className="d-flex w-100 justify-content-between">
                                            <span className="d-flex align-self-center">해제</span>
                                            <span className="d-flex fs-6 cursor-pointer" onClick={() => fn_openSosModal('P001002')}>{sosStat.clear}</span>
                                        </div>
                                        <span className="px-3">|</span>
                                        <div className="d-flex justify-content-between w-100">
                                            <span className="d-flex align-self-center">전체</span>
                                            <span className="d-flex fs-6 cursor-pointer" onClick={() => fn_openSosModal()}>{sosStat.total}</span>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </Col>
                </Row>
            </Container>
        </>
    );
};

export default SiteRiskStatus;

