import React, {useEffect, useRef, useState} from 'react';
import Datatables, {getCheckedItems} from "../component/Datatables";
import {Button, Container, ListGroup, Overlay, Table} from 'react-bootstrap';
import SubMenuTitle from "../component/SubMenuTitle";
import BMCIconButton from "../../components/common/BMCIconButton";
import SHSearchButton from "../../components/common/BMCSearchButton";
import BMCDatePicker2 from "../../components/common/BMCDatePicker2";
import {DateHelper} from "../../helper/DateHelper";
import {toast} from "react-toastify";
import messageCodeDic from "../../util/messageCodeDic";
import axios from "axios";
import errorCodeDic from "../../util/errorCodeDic";
import SHSearch from "../component/BMCSearch";
import {getLoginUser} from "../../helper/SecurityHelper";
import SafetyManagementBoardModal from '../safetyManagementBoard/modal/SafetyManagementBoardModal';
import restapi from '../../helper/restAPIHelper';
import EnvrntCnfgModal from "./modal/EnvrntCnfgModal";

type Props = {};

const EnvrntCnfgPage = (props: Props) => {
    const [modalIsOpen, setModalIsOpen] = React.useState(false);
    const [detailData, setDetailData] = useState({});
    const [dataList, setDataList] = useState<any>([]);

    useEffect(() => {
        if(!modalIsOpen){
            restapi.retrieveSystemInfoList({}).then((rsltData: any) => {
                setDataList(rsltData);
            })
        }

    }, [modalIsOpen])


    return (
        <Container fluid className="bg-white px-0 bmc-txt-color-primary">
            <SubMenuTitle title={'환경설정'} parentMenu={'설정'}/>
            <EnvrntCnfgModal setModalIsOpen={setModalIsOpen} modalIsOpen={modalIsOpen} detailData={detailData} />

            <div className="p-4 overflow-y-auto" style={{height: 'calc(100vh - 197px)'}}>

                <div className={'ge-result'}>
                    <Datatables
                        datas={dataList}
                        scrollY="550px"
                        className="bmc-fs-b4"
                        columns={[
                            {data: "key", title: "키"},
                            {data: "value", title: "값"},
                            {data: "description", title: "설명"},

                        ]}
                        index={true}
                        checked={false}
                        onLoad={(dt: any) => {
                        }}

                        onClick={(data) => {
                            setDetailData(Object.assign({}, data));
                            setModalIsOpen(true);
                        }
                        }
                    />
                </div>
            </div>
        </Container>
    );
};

export default EnvrntCnfgPage;
