import React, {useContext, useEffect, useState} from 'react';
import {Col, Container, Row} from "react-bootstrap";
import {ReactComponent as IconRight} from "../../assets/images/ico/icon-right.svg";
import {ReactComponent as IconNoData} from "../../assets/images/icon/icon-folder.svg";
import restapi from "../../helper/restAPIHelper";
import {UserContext} from "../../context/User";
import {getLoginUser} from "../../helper/SecurityHelper";
import CctvViewerModal from "../headoffice/modal/CctvViewerModal";
import MagnifyingGlassPlus from '../../images/icon/MagnifyingGlassPlus.png';

const DashboardSiteCCTV = () => {
    const {user} = useContext<any>(UserContext);
    const defaultUser = getLoginUser();
    const [cctvDataList, setCctvDataList] = useState<any>([]);
    const [cctvData, setCctvData] = useState<any>({});
    const [modalIsOpen, setModalIsOpen] = useState<boolean>(false);
    const [siteData, setSiteData] = useState<any>({});

    useEffect(() => {
        let siteId = user?.blngSiteId || defaultUser.blngSiteId
        fn_retrieveCctvDataList(siteId);
        restapi.retrieveSiteDataListNoPaging({siteId: siteId}).then((rsltData: any) => {
            if(rsltData.length > 0){
                setSiteData(rsltData[0]);
            }
        })
    }, [user])

    const fn_retrieveCctvDataList = (siteId: string) => {
        restapi.retrieveCctvList({siteId: siteId}). then((rsltData: any) => {
            console.log('cctv rsltData: ', rsltData);
            setCctvDataList(rsltData);
            if(rsltData.length > 0){
                rsltData[0].idx = 0;
                setCctvData(rsltData[0]);
            }else{
                setCctvData([]);
            }
        })
    }

    const fn_nextCctv = (direction: string) => {
        let cctvIdx;

        if(direction == 'left'){
            if(cctvData.idx == 0){
                cctvIdx = cctvDataList.length - 1;
            }else{
                cctvIdx = cctvData.idx - 1;
            }
        }else if(direction == 'right'){
            if(cctvData.idx == cctvDataList.length -1){
                cctvIdx = 0;
            }else{
                cctvIdx = cctvData.idx + 1;
            }
        }
        cctvDataList[cctvIdx].idx = cctvIdx;
        let cstmCctvData = cctvDataList[cctvIdx];
        setCctvData(cstmCctvData);
    }
    return(
        <>
            <CctvViewerModal setModalIsOpen={setModalIsOpen} modalIsOpen={modalIsOpen} dataList={cctvDataList} detailData={cctvData}/>
            <Container fluid className="py-4 px-0 bmc-txt-color-primary">
                <Row className="px-0">
                    <Col className="w-100">
                        <div className="w-100 d-flex align-items-center justify-content-between">
                            <span className="pb-2 site-title-name">현장 CCTV</span>
                        </div>
                    </Col>
                </Row>
                <div style={{height: 'calc(50% - 20px)'}}>
                    <Row className="mb-1">
                                {cctvDataList?.map((data: any, idx: number) => (
                                    <Col bsPrefix={"col-6 pb-3"} key={idx}>
                                        <div className="d-flex justify-content-between align-items-center">
                                            <div className="d-flex flex-column justify-content-center align-items-center rounded-[5px] text-white border-1">
                                                <div className="position-relative cursor-pointer" onClick={() => {setCctvData(data); setModalIsOpen(true)}}>
                                                    <img className="rounded-[5px] object-cover w-100" src={process.env.REACT_APP_CCTV_URL + "/cctv-image?url=" + encodeURIComponent(data.rtspUrl)} alt="cctv"  />
                                                </div>
                                            </div>
                                        </div>
                                        <div className={"mt-1 d-flex justify-content-between align-items-center bmc-vm-01"}>
                                            <span style={{fontWeight:"bold", fontSize:"0.8em"}}>{siteData.siteName} {data.location}</span>
                                            <img src={MagnifyingGlassPlus} width={"20px"} onClick={() => {setCctvData(data); setModalIsOpen(true);}}/>
                                        </div>

                                    </Col>))}
                                {cctvDataList.length === 0 ?
                                    (
                                        <Col bsPrefix={"col-12"}><div className="d-flex justify-content-between align-items-center">
                                            <div className="d-flex flex-column justify-content-center align-items-center rounded-[5px] text-white border-1 p-2"
                                                 style={{width: '48%', height: 'calc(100% - 20px)'}}>
                                                <IconNoData style={{width: '40px', height: '40px', marginBottom: '10px'}} />
                                                <div className="bmc-txt-color-gray-01 bmc-txt-size-b6">등록된 CCTV가 없습니다.</div>
                                            </div>
                                            <div className="d-flex flex-column justify-content-center align-items-center rounded-[5px] text-white border-1 p-2"
                                                 style={{width: '48%', height: 'calc(100% - 20px)'}}>
                                                <IconNoData style={{width: '40px', height: '40px', marginBottom: '10px'}} />
                                                <div className="bmc-txt-color-gray-01 bmc-txt-size-b6">등록된 CCTV가 없습니다.</div>
                                            </div>
                                        </div></Col>
                                    ) : ''}
                    </Row>
                </div>
            </Container>
        </>
    );
}

export default DashboardSiteCCTV;
