import { ListItemButton, ListItemIcon } from "@mui/material";
import { useSelector } from "react-redux";
import { Link } from "react-router-dom";
import colorConfigs from "../configs/colorConfigs";
import { RootState } from "../../redux/store";
import { RouteType } from "../../routes/config";

type Props = {
    item: RouteType;
    isTopLevel : boolean;
};

const SidebarItem = ({ item, isTopLevel }: Props) => {
    const { appState } = useSelector((state: RootState) => state.appState);

    return (
        item.sidebarProps && item.path ? (
            <ListItemButton className={isTopLevel ? "site-sidebar-btn-top" : "site-sidebar-btn-child"}
                            component={Link}
                            to={item.path}
            >
                <span></span>
                <ListItemIcon
                    className={"bmc-left-menu-icon"}
                >

                    {item.sidebarProps.icon && item.sidebarProps.icon}
                </ListItemIcon>
                {isTopLevel ? <p>{item.sidebarProps.displayText}</p> : <p className={"bmc-vm-01"}>
                    <span className="sidebar-btn-child-forward">⦁</span>
                    <span className={"ms-1"}>{item.sidebarProps.displayText}</span>
                </p>}
            </ListItemButton>
        ) : null
    );
};

export default SidebarItem;
